export const initialState = {
  order: "id,desc",
  city: 0,
  page: 1,
  limit: 10,
  search: "",
  course_preferred: new Set(),
  required_skills: new Set(),
  selected: 0,
  industry_sector: new Set(),
  charge1: 0,
  charge2: 0,
  location: "",
  gender: "",
  course_name: "",
  company_ids: new Set(),
  address: "",
  keywords: localStorage.getItem("keywords") ?  JSON.parse(localStorage.getItem("keywords"))  :  [],
  tab:"",
  filters: {}

};

export const reducer = (state, action) => {
  try {
    const { payload, type } = action;
    switch (type) {
      case 'SET_FILTERS':
      return {
        ...state,
        ...action.payload,
      };
      case "change_value":
        return { ...state, ...payload };
      case "add_key":
        if (state[payload.name] instanceof Set) {
          return {
            ...state,
            [payload.name]: state[payload.name].add(payload.value),
          };
        } else return state;
      case "remove_key":
        if (state[payload.name] instanceof Set) {
          return {
            ...state,
            [payload.name]: state[payload.name].delete(payload.value),
          };
        } else return state;
      case "reset":
        return initialState;
      default:
        throw new Error();
    }
  } catch (err) {}
};
