import React, { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const CustomYearPicker = ({
  label,
  id,
  className,
  className1,
  required = false,
  onYearChange,
  register = () => {},
  options = {},
  setValue,
  getValues = () => {},
  value,

}) => {
  const [selectedYear, setSelectedYear] = useState(null)
  const [year ,setYear]=useState(value)

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`
    return <span title={tooltipText}>{year}</span>
  }

  const handleYearChange = (date) => {
    const year = date.getFullYear()
    console.log("year", year)
    setSelectedYear(date)
    setYear(date)
    onYearChange(year)
  }
  //   console.log("selected",selectedYear) 

  return (
    <div className="form-group mb-3">
      <div className="d-flex flex-column">
        <label htmlFor={id} className="text_label">
          {label}
          {required && <span className={`${className1}`}>*</span>}
        </label>
        <DatePicker
          selected={selectedYear}
          renderYearContent={renderYearContent}
          showYearPicker
          dateFormat="yyyy"
          className={`form-control mt-2 ${className}`}
          id={id}
          value={year}
          onChange={(date) => handleYearChange(date)}
        />
      </div>
    </div>
  )
}

export default CustomYearPicker
