import axios from "axios";
import { toast } from "react-toastify";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const bookSession = async (params, navigate) => {
  // // console.log(params);
  const {
    mentor_id,
    amount_rs,
    start_time,
    end_time,
    enrollment_id,
    coupon_name,
    user
  } = params;
  // 1: method, 2: Subscription
  // const amount_rs = 10000;
  try {
    // console.log(coupon_name, "coupon_name");
    if (isNaN(amount_rs) || amount_rs < 10 || amount_rs > 50000)
      return toast.error("Please enter a valid amount between 10 and 50000");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post("/v1/transaction/create/paymentOrder", {
      amount: amount_rs,
    });

    if (!result) {
      toast?.error("Server error. Are you online?");
      return;
    }
    // console.log(result, "Result");

    const { amount, id: order_id, currency } = result.data?.data;

    // console.log("orderId", order_id, amount);

    const options = {
      // key: 'rzp_test_QzUPaRfy6pEXS2', // Enter the Key ID generated from the Dashboard
      // key: "rzp_test_RmVa0PsRBAVR8x", // Enter the Key ID generated from the Dashboard
      key: "rzp_live_R36sbTMr61BiTI", // Live key
      amount: amount.toString(),
      currency,
      name: "Qollabb Mentoring Plan",
      description: "Get personalized mentorship sessions with industry experts to kickstart your career.",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const postData = {
          mentor_id,
          razorpaySignature: response.razorpay_signature,
          razorpayOrderId: response.razorpay_order_id,
          amount: amount_rs,
          razorpayPaymentId: response.razorpay_payment_id,
          orderCreationId: order_id,
          start_time,
          end_time,
          enrollment_id,
          coupon_name,
        };

        // toast.success('Wallet Recharged Successfully');

        const result = await axios.post("/v1/student/bookSession", postData);

        if (result) {
          toast.success("Mentor Booked Successful");
          navigate();
          // navigate('/student/dashboard', { replace: true });
        }
        return result;
      },
      prefill: {
        name: `${user?.first_name} ${user?.last_name}` ?? "Sheo Shankar",
        email:  `${user?.email_address}` ?? "Share.sheo@gmail.com",
        contact: `${user?.phone_number}` ?? "9999999999",
      },
      notes: {
        address: `${user?.location}` ?? "Default",
      },
      theme: {
        color: "#6149cd",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      toast.error("Payment failed! Please try again!");
    });
    paymentObject.open();
  } catch (err) {
    // onClose();
  } finally {
    // setShowRecharge(false);
  }
};

export const subscribeSubscription = async (params) => {
  // console.log(params);
  const { subscription_id, amount_rs, navigate, coupon_name, user } = params;

  const map = {
    2: "employer", 
    3: "educator",
    4: "student"
  }
  try {
    // console.log(typeof amount_rs, amount_rs);
    if (isNaN(amount_rs) || amount_rs < 10 || amount_rs > 80000)
      return toast.error("Invalid Subscrition...Please reload the page");
    
    // calculate gst
     const gstAmount = amount_rs * 1.18
    
      const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post("/v1/transaction/create/paymentOrder", {
      amount: gstAmount,
    });

    if (!result) {
      toast?.error("Server error. Are you online?");
      return;
    }
    // console.log(result, "Result");

    const { amount, id: order_id, currency } = result.data?.data;

    const options = {
      // key: "rzp_test_yizrkXTjrHQIM7", // test keyyy 
      key: "rzp_live_R36sbTMr61BiTI", // Live key
      amount: amount.toString(),
      currency,
      name: "Qollabb Subscription Plan",
      description: "Subscribe to Qollabb for exclusive project, internship & job opportunities",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const postData = {
          subscription_id,
          razorpaySignature: response.razorpay_signature,
          razorpayOrderId: response.razorpay_order_id,
          amount: gstAmount,
          razorpayPaymentId: response.razorpay_payment_id,
          orderCreationId: order_id,
          coupon_name: coupon_name
        };

        // toast.success('Wallet Recharged Successfully');

        const result = await axios.post(
          "/v1/subscription/subscribeSubscription",
          postData
        );

        if (result) {
          toast.success("Payment Successful");
          navigate(`/${map[user?.role]}/dashboard`, { replace: true });
          window.location.reload();
        }
        return result;
      },
      prefill: {
        name: `${user?.first_name} ${user?.last_name}` ?? "Sheo Shankar",
        email:  `${user?.email_address}` ?? "Share.sheo@gmail.com",
        contact: `${user?.phone_number}` ?? "9999999999",
      },
      notes: {
        address: `${user?.location}` ?? "Default",
      },
      theme: {
        color: "#6149cd",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      toast.error("Payment failed! Please try again!");
    });
    paymentObject.open();
  } catch (err) {
    // onClose();
  } finally {
    // setShowRecharge(false);
  }
};
