import React, { useState } from "react";
import "./employerList.scss";
import { Link } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import { downloadCSV } from "components/dataTable/Export";
import StatusButton from "../StatusButton";
import { useNavigate } from "react-router-dom";

const EmployerList = ({
  type,
  data,
  search,
  setSearch,
  isPopupOpen,
  setIsPopupOpen,
}) => {
  // const [search, setSearch] = useState(() => "")
  // setSearch()
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };
  const navigate = useNavigate();

  const map = {
    1: "Draft",
    2: "Published",
    3: "Closed",
  };

  const rows = data
    ?.filter((post) => {
      return post?.title?.toLowerCase()?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      return {
        id: post?.id,
        date: new Date(post?.createdAt).toLocaleDateString(),
        title: <span>{post?.title || "No title"}</span>,
        enrolled: post?.enrollments[0]?.applications || 0,
        submitted: post?.enrollments[0]?.applications || 0,
        rated: post?.enrollments[0]?.applications || 0,
        status: <StatusButton status={map[post?.status]} />,
      };
    });
  // console.log(type);
  return (
    <div className="employerList_container">
      <div className="employerList_btns">
        <div className="list_btn">
          <h3
            className="text-uppercase mb-0"
            style={{
              fontWeight: "600",
              fontSize: "17.5px",
              color: "#6149cd",
            }}
          >
            {type} List
            <br />
            <span
              className="light"
              style={{
                fontSize: "12px",
              }}
            >
              {data["length"] || 0} records
            </span>
          </h3>
        </div>
        <div className="">
          <div
            onClick={handleClick}
            className="upload_btn_icon btn btn-primary text-white add_button_popup"
          >
            Add<i className="fa-solid fa-plus"></i>
            <div className={`popover ${clicked ? "visible" : "d-none"}`}>
              <div className="popover_container d-flex flex-column flex-xl-row justify-content-center align-items-start gap-3">
                <Link to={`/employer/${type}/add`} className="p-0 m-0 w-100">
                  <button className="button-scratch" type="button">Create from Scratch</button>
                </Link>
                <div
                  onClick={() => {
                    setIsPopupOpen(!isPopupOpen);
                  }}
                  className="p-0 m-0 w-100"
                >
                  <button type="button">Create With AI</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list_upload_btn">
          <button
            className="upload_btn_icon btn btn-primary"
            onClick={() => downloadCSV(rows)}
          >
            <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
          </button>
        </div>
        <div className="list_search_btn">
          <input
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
    </div>
  );
};

export default EmployerList;
