import React, { useEffect, useState } from "react"
// import downloadIcon from "../../../../images/download1.png"
// import rahulImg from "../../../../images/rahul.png"
// import "../sessionRequest.scss"
// import "./studentDetail.scss"
import axios from "axios"
import Loader from "components/employer/Loader"
import { useParams } from "react-router-dom"
import StatusProgress from "view/dashboard/student/application/StatusProgress"
import WeekDetail from "view/dashboard/student/application/WeekDetail"

const EduStudentProfile = () => {
  const [getData, setGetData] = useState([]);
  const [getSessionData, setGetSessionData] = useState([]);
  const [today, setToday] = useState("");
  const [status,setStatus] = useState()
  const [loading, setLoading] = useState(false);

  const [studentDetails, setStudentDetails] = useState({})
  
  const {id} = useParams();

  // console.log("educatorid", id)

  // console.log(status, "status");

  // const booking_date = "04-27-2023";
   
  useEffect(() => {
    const date = new Date();
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options).replace(/\//g, "-");
    setToday(formattedDate);

    async function getStudent(){
      setLoading(true);
      const data = await axios.get(`/v1/educator/getStudentDetails/${id}`)

      // console.log("student", data);

      setStudentDetails(data?.data?.data)

      setLoading(false);
    }
    getStudent();


  }, []);


  // console.log(today, "today");
  

  // calculate age
  let age;
  if (studentDetails?.date_of_birth) {
    const dob = studentDetails?.date_of_birth;
    const dobDate = new Date(dob);
    const diffMs = Date.now() - dobDate.getTime();
    age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  }

  // formate experience
  const DateType = (value) => {
    // return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ");
    const dateObject = new Date(value);
    const year = dateObject.getFullYear();
    const monthAndDate = dateObject.toDateString().split(" ").slice(1, 3).join(" ");
  
    return `${monthAndDate}, ${year}`;
  
  };

  // calculate realtive time
  const calculateRelativeTime = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };
  
  // console.log("details..", studentDetails)

  if(studentDetails === undefined) return <Loader />

  return (
    <section className="student-detail">
      <div className="mx-0 mx-sm-4 py-0 py-sm-4">
        <div className="w-100 m-0 row">

        {
          studentDetails && (

          <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="job-description">
              <i
                class="fa fa-arrow-left d-flex d-sm-none fs-3"
                aria-hidden="true"
              ></i>
              <div className="firsttab flex d-none d-sm-flex">
                <h2>{""}</h2>
                <div>
                  {/* <img
                    style={{
                      width: "16px",
                      height: "20px",
                      marginRight: "25px",
                    }}
                    src={downloadIcon}
                    alt="download icon"
                  /> */}
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </div>
              </div>

              <div className="secondtab">
                <div className="flex">
                  <div className="student_resume_data">
                    <div
                      style={{ width: "64px", height: "64px" }}
                      className="img_div"
                    >
                      {/* {data?.student?.first_name} */}
                      <img src={studentDetails?.profile_photo} alt="Image" />
                    </div>
                    <div className="student_content">
                      <div className="heading_content">
                        <span className=" h2">
                          {studentDetails?.first_name} {studentDetails?.last_name}
                        </span>
                        <span className="light">{calculateRelativeTime(studentDetails?.createdAt)}</span>
                      </div>

                      <p className="add">{studentDetails?.location}</p>
                      <p className="star">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <p>5.00</p>
                      </p>
                    </div>
                    {/* <div className="New_student">
                      <p>New</p>
                    </div> */}
                    {Date.now() - new Date(studentDetails?.data?.createdAt).getTime() <
                      1000 * 60 * 60 * 24 ? (
                        <div  className="New_student">
                          <p>New</p>
                        </div>
                      ) : (
                        <></>
                      )}
                  </div>

                </div>
                <div className="tab-btn">
                  {/* <div className="tags">
              {
                studentDetails?.data?.student?.user_skills ? (
                  studentDetails?.data?.student?.user_skills?.map((item, i)=> {
                    return (
                      <span key={i} >
                        {item}
                      </span>
                    )
                  })
                ) : (
                  <span>No Skills</span>
                )
              }   
                  </div> */}
                  <div className="bio">
                    {/* <p>
                      <i className="fa-solid fa-graduation-cap"></i>{" "}
                      <span>BCA</span>
                    </p> */}
                    <p>
                      <i className="fa-solid fa-cake-candles"></i>
                      <span>{age} Years Old</span>
                    </p>
                  </div>
                  <p className="text">{studentDetails?.data?.student?.bio}</p>
                  {/* data?.student?.bio || */}
                  {/* <button className="btn3 chatbtn">
            <i className="fa-regular fa-comment-dots"></i>
            Start Chat
          </button>
          <button className="btn3">
            <i className="fa-regular fa-calendar"></i> Interview
          </button> */}
                </div>
              </div>
              <div className="thirdtab">
                <div className="row py-2">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="education" >
                    <h3>Experience</h3>
                {
                  studentDetails?.experiences.length !== 0 ? (

                    studentDetails?.experiences.map((item, i)=> {

                      return(
                        <div className="education-detail" key={i}>
                          <h4>
                            <span>
                              <strong>{item?.designation}</strong> - {item?.company_name}{" "}
                            </span>{" "}
                          </h4>
                          <p>Project | {DateType(item?.start_date)} - {DateType(item?.end_date)}</p>
                        </div>

                     
                      )
                    })

                  ) : (
                    <p>No Experience</p>
                  )

                }

               </div>
                  </div>

                </div>
              </div>

              <div className=" progress_top_content">
                <p>Project Details</p>
                <h2>{studentDetails?.project_enrollments[0]?.project?.project_title} </h2>
              </div>

              <div className="student_progress col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <StatusProgress
                status={studentDetails?.project_enrollments[0]?.status}
                reports={studentDetails?.project_enrollments[0]?.weekly_submissions ? JSON.parse(studentDetails?.project_enrollments[0]?.weekly_submissions) : []}
                />

                <div className="weekly-report-accordian">
                  <h2>Weekly Reports</h2>
                  { studentDetails?.project_enrollments[0]?.weekly_submissions && JSON.parse(studentDetails?.project_enrollments[0]?.weekly_submissions).map((item) => (
                  <WeekDetail
                    key={item?.week}
                    report={item?.report}
                    index={item?.week}
                    title={`Week ${item?.week}`}
                    description={item?.description}
                    text="uploaded"
                    midTitle="submitted"
                  />
                ))}
                </div>
              </div>
            </div>
          </div>
          )
        }


        </div>
      </div>
    </section>
  )
}

export default EduStudentProfile;
