import React, { useEffect, useState } from "react";
import CompaniesCard from "../../components/CompanyCard/CompaniesCard/CompaniesCard";
import CompaniesDetailCard from "../../components/CompanyCard/CompaniesDetailCard/CompaniesDetailCard";
import TextInput from "components/formComponents/TextInput";
import CompanyTop from "../../components/companyTop/CompanyTop";
import EducatorFilters from "../../components/EducatorFilter/EducatorFilters";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { set } from "react-hook-form";

const InvitationRecieved = ({ company, setRefresh }) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  // const [company, setCompany] = useState([]);
  const [employer, setEmployer] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const [search, setSearch] = useState("");

  const [emploayerDataDetails, setEmployerDataDetails] = useState();
  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/company/single/${company[employer]?.sender_emp.id}`
      );

      setEmployerDataDetails(res?.data?.data);
    };
    fetctEductatorData();
  }, [employer, company]);

  const style1 = {
    backgroundColor: "#47D065",
  };
  const style2 = {
    display: "none",
  };
  const style = {
    borderBottom: " 2px solid #6149CD",
  };

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get(
  //       `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}&sender_id=${user?.id}`
  //     );
  //     setCompany(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  const handleToggle = () => {
    setOpen((toggle) => !toggle);
  };

  const handleFilterToggle = () => {
    setFilter((toggle) => !toggle);
  };

  //style1={style1} style2={style2}  text1="Accept Invitation"
  const rejectInvite = (e, id) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 2,
      })
      .then((res) => {
        setRefresh((prevState) => !prevState);
        toast.success("Invitation Rejected");
      })
      .catch((err) => {
        toast.error("Error Rejecting Invitation");
      });
  };

  const acceptInvite = (e, id) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 1,
      })
      .then((res) => {
        // console.log(res, "res");
        setRefresh((prevState) => !prevState);
        toast.success("Invitation Accepted");
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Invitation Rejected");
      });
  };

  return (
    <div className="companies-component">
      {/* <CompanyTop reciveLength={company?.length} active4={true} /> */}

      {/* Mobile Search Toggle  */}

      {/* Filter Toggle Close Button  */}

      {company?.length > 0 ? (
        <div className="companies-left">
          <div className="companies_left_container">
            <div className="searchbox w-100 hidesearchbox">
              <label>
                {" "}
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search candidate"
                />
                <i className="fa-solid fa-magnifying-glass text-black"></i>
              </label>
              <i
                onClick={() => handleFilterToggle()}
                className="filter_mobo fa fa-sliders d-flex d-sm-none"
                aria-hidden="true"
                style={{ color: "#6149CD" }}
              ></i>
            </div>
            {/* mobile view search box and filter */}

            <div className="searchbox-container d-md-none gap-2  pl-1 pr-1">

       <div className="searchbox w-100">
              <label>
                {" "}
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search candidate"
                />
                <i className="fa-solid fa-magnifying-glass text-black"></i>
              </label>
              {/* <i
                onClick={() => handleFilterToggle()}
                className="filter_mobo fa fa-sliders d-flex d-sm-none"
                aria-hidden="true"
                style={{ color: "#6149CD" }}
              ></i> */}
            </div>

<div className="">
  <i
     className="fa-solid fa-sliders fa-lg filter_mobo"
     onClick={() => handleFilterToggle()}
   style={{ color: "#6149CD" }}
   ></i>
    </div>
             </div>     
 
                    {/* ---------------- */}



            <div className="scroll_components">
              {company
                ?.filter((item) => {
                  return (
                    item?.sender_emp?.company?.company_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    (
                      item?.sender_emp?.first_name +
                      " " +
                      item?.sender_emp?.last_name
                    )
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  );
                })
                ?.map((company, index) => {
                  const collegesFunction = () => {
                    setEmployer(index);
                    handleToggle();
                    console.log("function working");
                  };
                  return (
                    <div className="w-100" onClick={collegesFunction}>
                      <CompaniesCard college={company?.sender_emp} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="companies_mid_container">
            <CompaniesDetailCard
              text1="Reject Invitation"
              text2="Invitation Accepted"
              invitationId={company[employer]?.id}
              emploayerDataDetails={company[employer]?.sender_emp}
              cancelInvite={rejectInvite}
              cancel={true}
              setPage={setEmployer}
              currentPage={employer}
              pageLength={company?.length}
              changeStatus={acceptInvite}
              change={true}
              setRefresh={setRefresh}
            />
          </div>

          {/* {open && (
            <div className="companies_mid_container_mobo d-flex d-sm-none">
              <i
                class="cross_icon fa fa-times"
                onClick={() => handleToggle()}
                aria-hidden="true"
              ></i>
              <CompaniesDetailCard
                style1={style1}
                style2={style2}
                text1="Accept Invitation"
                emploayerDataDetails={company}
              />
            </div>
          )} */}

          <div className="company_right_container">
            <EducatorFilters />
          </div>
          {filter && (
            <div className="company_right_container_mobo d-block d-sm-none">
              <i
                class="cross_icon fa fa-times text-end m-3"
                onClick={() => handleFilterToggle()}
                aria-hidden="true"
              ></i>
              <EducatorFilters />
            </div>
          )}
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>
                  <b>No Invitations Recieved</b>
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitationRecieved;
