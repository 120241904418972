import axios from "axios";
import Loader from "components/employer/Loader";
import React, { useEffect, useState } from "react";
import EvaluationsEvaluated from "./tables/EvaluationsEvaluated";
import EvaluationsOverdue from "./tables/EvaluationsOverdue";
import EvaluationsRecieved from "./tables/EvaluationsRecieved";
import "../home.scss"

const AdminEvaluations = () => {
  const tabs = ["Recieved", "Overdue", "Evaluated"];
  const [activeTab, setActiveTab] = useState(() => 0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(() => true);

  useEffect(() => {
    const getAll = async () => {
      try {
        setLoading(true); 
        const res = await axios.post("/v1/evaluation/getEvaluations");
  
        
        // console.log(res?.data?.data, "res");
        setData(res?.data?.data);
        setLoading(false); 
      } catch (err) {
        throw err;
      }
    };
    getAll();

    return () => {};
  }, []);

  if (loading) return <Loader/>;
  return (
    <div
      className="content-container p-1"
      // className="content-container py-5 pt-2"
      // style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="table-tabs">
            <nav>
              <div
                className="nav_tabs nav nav-tabs"
                id="nav-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <button
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className={`nav-link ${
                      activeTab === index ? "active" : ""
                    }`}
                    id={`nav-${tab}-tab`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${tab}`}
                    aria-selected="true"
                    onClick={() => setActiveTab(index)}
                    key={tab}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </nav>

            <div
              className="tab-pane fade show active"
              id={`nav-${activeTab}`}
              role="tabpanel"
              aria-labelledby={`nav-${activeTab}-tab`}
            >
              <LoadTable activeTab={activeTab} data={data}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEvaluations;

const LoadTable = ({ activeTab, data}) => {
  switch (activeTab) {
    case 0:
      return <EvaluationsRecieved data={data} />;
    case 1:
      return <EvaluationsOverdue />;
      // return <EvaluationsOverdue data={data["Overdue"]} />;
    case 2:
      return <EvaluationsEvaluated />;
      // return <EvaluationsEvaluated data={data["Evaluated"]} />;
    default:
      return <EvaluationsRecieved data={data} />;
  }
};
