import { downloadCSV } from "components/dataTable/Export";
import StatusButton from "components/employer/StatusButton";
import React, { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import AdminDashCard from "../table-card";
import Datatable from "components/datatable-new/DatatableNew";
import "../style.scss";
import moment, { months } from "moment";
import axios from "axios";
import { toast } from "react-toastify";


import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
import AdminBarGraph from "../table-card/bargraph";
import StudentMobileViewTable from "./studentMobileViewTable";
import "../index.scss"
import StudentPopup from "./StudentPopup";

const AdminDashStudents = ({ data, setActivate, counts,date,setdates }) => {
  const [search, setSearch] = useState(() => "");
  // const sortedData = data.sort(
  //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  // );
// console.log("studentdata",counts);
  
console.log("data...", data);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [temporaryDates, setTemporaryDates] = useState([date[0]]);

  const handleToggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // popup start

  const [isModalOpen, setIsModalOpen] = useState(false);
  const[enrollType,setEnrollType]=useState("")
  const[enrollid,setEnrollId]=useState("")
  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  
  };

  const handleStudentClick = (id, type) => {
    setEnrollId(id);
    setEnrollType(type);
    setIsModalOpen(prev => !prev);
  };

//  popup end


  const handleDateChange = (ranges) => {
    setTemporaryDates([ranges.selection]);
  };

  const handleApply = () => {

    setdates([...temporaryDates]);
    setCalendarVisible(false);
  };



  const datePickerRef= useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        // Clicked outside the date picker, close the picker
        setCalendarVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 

  



  const cardsData = [
    {
      primaryText: 'Students',
      secondaryText: `+${data.length}`,
      value: `${data.length}`,
      date: `${data.map((item) => item?.createdAt)}`,
     
    },
    
  ];
   // Separate the date values
   const dateStrings = cardsData[0].date.split(',');
  //  console.log("datestring",dateStrings)
   // Create an object to store the total companies added for each day
  const dayCounts = {};
  
  // Process each date string and count the number of companies added on that day
  dateStrings.forEach((dateStr) => {
    const date = new Date(dateStr);
    // const fullDate = date.toISOString().split('T')[0]; // Extract the day part
    const fullDate = date.toLocaleDateString( undefined,{ month: 'short', day: 'numeric',year:'numeric' })
    // .split('T')[0]; // Extract the day part
    // console.log("fuldate",fullDate)
    const day = fullDate

    // console.log("dayyyy",day)

    if (dayCounts[day]) {
      dayCounts[day]++;
    } else {
      dayCounts[day] = day === "Invalid Date" ? 0 : (dayCounts[day] ?? 0) + 1;
      // dayCounts[day] = 1;
    }
  });

   // Create an array with objects for the chart data
   const formattedData = Object.keys(dayCounts).map((day) => {
    return {
      day,
      total: dayCounts[day],
    };
  });
 // Sort the formattedData array by date in ascending order
//  formattedData.sort((a, b) => parseInt(a.day, 10) - parseInt(b.day, 10));
 formattedData.sort((a, b) => new Date(a.day) - new Date(b.day));
  // console.log("formattedData", formattedData);

  const studentDataset = {
    label: 'students',
    data: formattedData.map((item)=>{return item.total}),
    borderColor: '#6149CD',
        backgroundColor: '#6149CD',
  };


  // date
  // console.log("datedefault",date[0].startDate)
   const currentdate=date[0].startDate
   const defaultdate=new Date(currentdate)


 
  const chartData = {
  
    labels: formattedData.map((item)=> {
      const date = item && item.day ? new Date(item.day) : new Date(defaultdate);

  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    // If invalid, replace with today's date
    return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }

  return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    }),
    datasets: [studentDataset ]
  };

  

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display:true // Change 'linear' to 'bottom' or 'top'
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'day', // X-axis title
      },
      
      
    },
    y: {
      title: {
        display: true,
        text: 'students', // Y-axis title
      },
      beginAtZero: true, // Start the y-axis from 0
      
      ticks: {
        stepSize: 2,
      },
    },
    
   
  },
};


// b2b and b2c graph

  // Function to group and count registrations by date
  const groupAndCountByDate_b2c = (students) => {
    // console.log("b2c...", students)
    const result = {};
    students.forEach((student) => {
      const date = new Date(student?.createdAt).toLocaleDateString( undefined,{ month: 'short', day: 'numeric',year:'numeric' });

      
      result[date] = (result[date] || 0) + 1;
    });

   
  return result;
  };

  // Function to group and count registrations by date
  const groupAndCountByDate_b2b = (students) => {
    // console.log("b2b...", students)
    const result = {};
    students.forEach((student) => {
      const date = new Date(student?.student?.createdAt).toLocaleDateString( undefined,{ month: 'short', day: 'numeric',year:'numeric' });
      result[date] = (result[date] || 0) + 1;
    });
    return result;
  };


const b2b_stud = counts?.students?.b2bstudents;
const b2c_stud = data;

// console.log("b2b_b2c", {b2b_stud, b2c_stud})

// Extract email addresses from b2bStudents
const b2bEmails = new Set(b2b_stud.map(student => student?.student?.email_address));

// Filter b2cStudents to remove those with email addresses in b2bEmails
const filteredB2cStudents = b2c_stud.filter(student => !b2bEmails.has(student?.email_address));

// console.log("filterb2c", filteredB2cStudents)

  const b2cDataByDate = groupAndCountByDate_b2c(filteredB2cStudents);
const b2bDataByDate = groupAndCountByDate_b2b(counts?.students?.b2bstudents);

// console.log('b2cStud..',b2cDataByDate )
// console.log('b2bStud..',b2bDataByDate )

// Combine unique dates from both B2C and B2B datasets
const combinedDates = new Set([...Object.keys(b2cDataByDate), ...Object.keys(b2bDataByDate)]);
// sort them
const sortedDates = Array.from(combinedDates).sort((a, b) => new Date(a) - new Date(b));
const studentformattedDates = sortedDates.map(date => new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }));


const studentchartData = {
 
  labels:studentformattedDates,
  // labels: Array.from(combinedDates),

  // labels: Object.keys(b2cDataByDate),
  datasets: [
    {
      label: 'B2C Students',
      data: sortedDates.map(date => b2cDataByDate[date] || 0),
      // data: Array.from(combinedDates).map(date => b2cDataByDate[date] || 0),
      // data: Object.values(b2cDataByDate),
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 2,
      fill: false,
    },
    {
      label: 'B2B Students',
      // data: Array.from(combinedDates).map(date => b2bDataByDate[date] || 0),
      data: sortedDates.map(date => b2bDataByDate[date] || 0),
      // data: Object.values(b2bDataByDate),
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 2,
      fill: false,
    },
  ],
};

// console.log("sChart", studentchartData)

// add type filed in data
// Add a 'type' field to each B2C student
  data = data.map((b2cstudent) => {
  // Check if the email_address exists in b2bstudents
  const isB2B = b2b_stud.some(
    (b2bstudent) => b2bstudent?.student?.email_address === b2cstudent?.email_address
  );

  // Set the 'type' field based on the check
  return {
    ...b2cstudent,
    type: isB2B ? 'B2B' : 'B2C',
  };
});

  // console.log("typestudent", b2cstudentsWithType)
  const handleAccountActivation = async (id) => {
    setActivate((prev) => !prev);
    const data = await axios.delete(`/v1/student/student/${id}`);
    // console.log(data, "data");
    if (!data.data?.error) {
      toast.info(data?.data?.message);
    } else {
      toast.error(data?.data?.error);
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "15%",
      textalign: "start",
      sortType: (rowA, rowB) => {
        return rowA?.original?.name?.toLowerCase() <
          rowB?.original?.name?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700", textTransform: "capitalize" }}>
            {data.row.original.name}
            <p>{data.row.original.location}</p>
          </strong>
        );
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.phone_number}
            <p>{data.row.original.email_address}</p>
          </strong>
        );
      },
    },
    {
      Header: "Signup Date",
      accessor: "signup_date",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.signup_date}
          </strong>
        );
      },
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {moment(data?.row?.original?.last_login).format("DD/MM/YYYY")}
            <strong className="d-block">{data.row.original.last_time}</strong>
          </strong>
        );
      },
    },
    {
      Header: "Projects",
      accessor: "projects",
      width: "6%",
      textalign: "center",
      Cell: (data) => {

        if(data.row.original.projects==0){
             return (
          <strong style={{ fontWeight: "700"}}
        
          >
            {data.row.original.projects}
          </strong>
        );

        }else{
           return (
          <strong className="opportunity_active_model"
          onClick={() => handleStudentClick(data?.row?.original?.id, 'Projects')}
          >
            {data.row.original.projects}
          </strong>
        );

        }

        // return (
        //   <strong style={{ fontWeight: "700",cursor:'pointer'}}
        //   onClick={() => handleStudentClick(data?.row?.original?.id, 'Projects')}
        //   >
        //     {data.row.original.projects}
        //   </strong>
        // );
      }, 
    },
    {
      Header: "Interships",
      accessor: "internships",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        if(data.row.original.internships==0){
           return (
          <strong style={{ fontWeight: "700"}}
          
          >
            {data.row.original.internships}
          </strong>
        );

        }
        else{
          return (
          <strong className="opportunity_active_model"
          onClick={() => handleStudentClick(data?.row?.original?.id, 'Internships')}
          >
            {data.row.original.internships}
          </strong>
        );

        }

        // return (
        //   <strong style={{ fontWeight: "700",cursor:'pointer'}}
        //   onClick={() => handleStudentClick(data?.row?.original?.id, 'Internships')}
        //   >
        //     {data.row.original.internships}
        //   </strong>
        // );
      },
    },
    {
      Header: "Jobs",
      accessor: "jobs",
      width: "6%",
      textalign: "center",
      Cell: (data) => {

        if(data.row.original.jobs==0){
          return (
              <strong style={{ fontWeight: "700"}}>
         
          
            {data.row.original.jobs}
          </strong>
          );

        }
        else{
          return (
          <strong className="opportunity_active_model"
          onClick={() => handleStudentClick(data?.row?.original?.id, 'Jobs')}
          >
            {data.row.original.jobs}
          </strong>
        );

        }

        // return (
        //   <strong style={{ fontWeight: "700",cursor:'pointer'}}
        //   onClick={() => handleStudentClick(data?.row?.original?.id, 'Jobs')}
        //   >
        //     {data.row.original.jobs}
        //   </strong>
        // );
      },
    },
    {
      Header: "Subscription",
      accessor: "subscription",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB) => {
        return rowA?.original?.subscription?.toLowerCase() <
          rowB?.original?.subscription?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.subscription}
          </strong>
        );
      },
    },
    {
      Header: "Valid Upto",
      accessor: "subscription_validity",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.subscription_validity
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.subscription_validity
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.subscription_validity}
          </strong>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.type}
          </strong>
        );
      },
    },
    {
      Header: "Action",
      accessor: "status",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <button
            onClick={() => handleAccountActivation(data.row.original.id)}
            className={`deactivate-btn ${
              data.row.original.deletedAt === "Activate"
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {data.row.original.deletedAt}
          </button>
        );
      },
    },
  ];

  const rows = data

    ?.filter((post) => {
      if (
        post?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.phone_number?.toLowerCase().includes(search.toLowerCase()) ||
        post?.account?.subscription_subscriber?.subscription_database?.subscript
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        post?.phone_number?.toLowerCase().includes(search.toLowerCase()) ||
        post?.email_address?.toLowerCase().includes(search.toLowerCase())
      ) {
        return post;
      } else {
        return null;
      }
    })
    ?.map((post) => {
      return {
        id: post?.id,
        name: post?.first_name + " " + post?.last_name,
        phone_number: post?.phone_number || "N/A",
        signup_date: moment(post?.createdAt).format("DD/MM/YYYY"),
        last_login: post?.lastLogin,
        email_address: post?.email_address || "N/A",
        last_time: moment(post?.lastLogin).tz("Asia/Kolkata").format("hh:mm A"),
        projects: post?.project_count,
        internships: post?.internship_count,
        jobs: post?.job_count,
        subscription:
          post?.account?.subscription_subscriber?.subscription_database
            ?.subscript || "Beginner",
        subscription_validity:
          post?.account?.subscription_subscriber?.valid_upto !== null
            ? moment(
                post?.account?.subscription_subscriber?.valid_upto
              ).format("DD/MM/YYYY")
            : "N/A",

        type: post?.type || "B2C",
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
        location: post?.location ? `${post?.location?.split(",")[0]}, ${post?.location?.split(",")[1]}` : ""
      };
    });

  return (
    <div className="admin-dash-companies">
      <div className="row flex-nowrap overflow-auto graphcardgap">
        {cardsData.map((card, index) => {
          return (
            <div className="col-12 col-md-6" key={index}>
              <AdminDashCard
                img={card.imgUrl}
                data={chartData}
                option={options}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          );
        })}
       

       {/* {studentCardsData.map((card, index) => {
          return ( */}
            <div className="col-12 col-md-6">
              <AdminDashCard
                data={studentchartData}
                option={options}
                primaryText={"B2C Students"}
                b2bstudents={"B2B Students"}
              
                value= {`${filteredB2cStudents.length}`}
                b2bvalue={`${b2b_stud.length}`}
              />
            </div>
          {/* );
        })} */}
      </div>

      <div className="datatable-container mt-4">
        <div className="table_head flex">
          <div className="mobo_upload">
            <h3 className="new-class-active">
              <div>
                <h3>Students</h3>
                <span className="light new-span-count">
                  {data.length || 0} records{" "}
                  {
                    <div className="new-type">
                      <div className="active">
                        Active - {counts?.students?.activeStudent}
                      </div>
                      <div className="inactive">
                        Inactive - {counts?.students?.inactive}
                      </div>
                    </div>
                  }
                </span>
              </div>
            </h3>

            <Link to="" className="upload_btn_icon_mobo btn btn-primary">
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non"ref={datePickerRef}>
            {/* date range */}
            <div  >
      <span className="light new-span-count" style={{color: '#7a86a1'}}>select date range</span>

      <div className="date-input-container" >

        <input
           className="myinput"
        // style={{ width: 'auto',
        //   padding: '5px 10px',
        //   alignSelf: 'baseline',
        //   border: '1px solid #6149cd',
        //   borderRadius: '8px',
        // cursor:'pointer'}}
       
          type="text"
          // value={`${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`}
          value={
            date[0]?.startDate && date[0]?.endDate
              ? `${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`
              : 'Select Date Range'
          }
          onClick={handleToggleCalendar}
          readOnly
        />
         {calendarVisible && (
        <div className="mydiv"   >
          {/* <DateRangePicker
            ranges={date}
            showPreview={false}
            onChange={handleDateChange}

            
          /> */}
           <DateRangePicker ranges={temporaryDates} showPreview={false} onChange={handleDateChange}
           />
           <div className=" applybtn-div">
           <button className="rdrApplyButton" onClick={handleApply}>Apply</button>
           </div>

        </div>
      )}
        
      </div>
     
        <style>
        {`
          /* Place the CSS here */
         
          .rdrStaticRangeLabel {
            color:#000000

          }
          


          /* Add the rest of the CSS styles here */
        `}
      </style>
              </div>
         


            
            {/*  End date Rage */}
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>

        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <StudentMobileViewTable columns={columns} data={rows} handleAccountActivation={handleAccountActivation}/>
      </div>

           {
            isModalOpen && 
              <StudentPopup  closeModal={closeModal} enrollType={enrollType} enrollId={enrollid} />
                    
              }



      
      </div>
    </div>
  );
};

export default AdminDashStudents;
