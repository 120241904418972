import React, { useState } from "react";
import "./projectReportTable.scss";
import Checkbox from "components/formComponents/Checkbox";

const ProjectReportTableCheckbox = ({
  checkbox,
  title,
  subTitle,
  text1,
  text2,
  text3,
  text4,
  text5,
  setRating3,
  setRating4,
  setRating5,
  rating3,
  rating4,
  rating5,
}) => {
  const register = () => {};

  const handleRatingChange3 = (e) => {
    const selectedValue = e.target.value;
    console.log(selectedValue);
    setRating3(parseInt(selectedValue));
  };

  // console.log(rating1, "rating")

  return (
    <div className="projectReportTable">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 25%)</small>
      </h4>
      <table>
        <tr>
          <th className="table_title">{subTitle}</th>
          <th>Rating</th>
          <th>Mentor Rating</th>
        </tr>
        <tr>
          <td className="table_title">{text1}</td>
          <td>05</td>
          <td>
            <div>
              <label htmlFor="radio1">
                <input
                  id="radio1"
                  type="radio"
                  name="myRadioGroup"
                  value="5"
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange3}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text2}</td>
          <td>04</td>
          <td>
            <div>
              <label htmlFor="radio2">
                <input
                  id="radio2"
                  type="radio"
                  name="myRadioGroup"
                  value="4"
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange3}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text3}</td>
          <td>03</td>
          <td>
            <div>
              <label htmlFor="radio3">
                <input
                  id="radio3"
                  type="radio"
                  name="myRadioGroup"
                  value="3"
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange3}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text4}</td>
          <td>02</td>
          <td>
            <div>
              <label htmlFor="radio4">
                <input
                  id="radio4"
                  type="radio"
                  name="myRadioGroup"
                  value="2"
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange3}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">
            Neatly written, no language or grammer mistakes
          </td>
          <td>01</td>
          <td>
            <div>
              <label htmlFor="radio5">
                <input
                  id="radio5"
                  type="radio"
                  name="myRadioGroup"
                  value="1"
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange3}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">Maximum (5)</td>
          <td></td>
          <td>
            <h5>Score: {rating3}</h5>
            <p>weighted Score: {(rating3 * 0.25).toFixed(2)}</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ProjectReportTableCheckbox;
