import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import template1 from "../../../../../images/ImageTemplates/16.png"
import template2 from "../../../../../images/ImageTemplates/2.png"
import scanme from "../../../../../images/ImageTemplates/scanme5.webp"
import "./projectTemplate.scss"
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';

const templateConfigurations = {
    [template1]: {
        companyImgPosition: { top: "7%", right: "15%" },
        templateItemsPosition: { top: "23%", left: "12%", gap: "16px" },
        qrSectionPosition: { position: "absolute", bottom: "9%", right: "31%" },
        qollabbLinkPosition: { position: "absolute", bottom: "2%", right: "72%" },
        pHeadStyle: { marginBottom: "10%", marginLeft: "2%", color: "#000" }
        // Add other CSS properties as needed
    },
    [template2]: {
        companyImgPosition: { top: "4%", right: "2%" },
        templateItemsPosition: { top: "19%", left: "20%", gap: "30px" },
        qrSectionPosition: { position: "absolute", bottom: "17%", right: "21%" },
        qollabbLinkPosition: { position: "absolute", bottom: "12%", right: "41%" },
        pHeadStyle: { marginBottom: "12%", marginLeft: "2%", color: "#fff" }
        // Add other CSS properties as needed
    },
    // Add configurations for other templates
};

const ProjectImageTemplate = () => {
    const targetElement = useRef(null);
    const location = useLocation();
    const data = location?.state?.data;
    const [selectedImage, setSelectedImage] = useState(template1);
    const [templateStyle, setTemplateStyle] = useState(templateConfigurations[template1]);

    const handleImageChange = (event) => {
        const selectedTemplate = event.target.value;
        setSelectedImage(selectedTemplate);
        setTemplateStyle(templateConfigurations[selectedTemplate]);
    };

    const handleGenerateImage = () => {
        const width = 1060;
        const height = 1060;
        html2canvas(targetElement.current, { width, height }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'generated_image.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };


    const [img, setImg] = useState("");

    const convertImageToBase64 = async () => {
      try {
    
    const timestamp = new Date().getTime();
    const cacheClearedUrl = `${`${data?.project_global_fkey?.employer_account?.company_logo}`}?_=${timestamp}`;
    
    const response = await fetch(cacheClearedUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          const newImageUrl = URL.createObjectURL(blob);
          setImg(newImageUrl);
        }, 'image/png');
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    // const qrRef = useRef(null);
    // const downloadQRCode = () => {
    //     const canvas = qrRef.current.querySelector('canvas');
    //     const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = pngUrl;
    //     downloadLink.download = 'qrcode.png';
    //     document.body.appendChild(downloadLink);
    //     downloadLink.click();
    //     document.body.removeChild(downloadLink);
    //   };

    useEffect(()=> {
        convertImageToBase64();
    }, [])

    return (
        <div className='template-img'>
            <div ref={targetElement} className='template-body'>
                <div className='template-content'>
                    <img src={selectedImage} alt="bellicon" className='temp-img'/>
                    <div className='company-img' style={templateStyle.companyImgPosition}>
                        <img src={img} alt="Image" />
                        {/* <img src={data?.project_global_fkey?.employer_account?.company_logo} alt="Image" /> */}
                    </div>
                    <div className='template-items' style={templateStyle.templateItemsPosition}>
                        <h2 className='p-head' style={templateStyle.pHeadStyle}>Project opportunity for Graduates</h2>
                        <div className='template-item'>
                            <div>Company:</div>
                            <div>{data?.project_global_fkey?.employer_account?.company?.company_name}</div>
                        </div>
                        <div className='template-item'>
                            <div>Project Title:</div>
                            <div>{data?.project_title}</div>
                        </div>
                        <div className='template-item'>
                            <div>Job Type:</div>
                            <div>{data?.location !== null ? "Office" : "Remote/virtual"}</div>
                        </div>
                        <div className='template-item'>
                            <div>Location:</div>
                            <div>{data?.location !== null ? data?.location?.city_name + ", " + data?.location?.state?.state_name : "Remote"}</div>
                        </div>
                        <div className='template-item'>
                            <div>Duration:</div>
                            <div>{data?.duration_from + " - " + data?.duration_to} weeks</div>
                        </div>
                    </div>
                    <div className='qr-section' style={templateStyle.qrSectionPosition}>
                        <div className='scanme-container'>
                            <img src={scanme} alt="scanme" width={120} style={{ filter: selectedImage === template1 ? "hue-rotate(1deg)" : "hue-rotate(55deg)" }} />
                            <div className='qrcode' style={{position: "absolute", top: "17.4%", left: "24.4%"}}>
                                <QRCode value={`https://qollabb.com/project/${data?.id}`} size={62} />
                            </div>
                        </div>
                    </div>
                    <div className="qollabb-link" style={templateStyle.qollabbLinkPosition}>
                        <a href={"https://qollabb.com/"} >https://qollabb.com</a>
                    </div>
                </div>
            </div>
            <div className='btn-section'>
                <select value={selectedImage} onChange={handleImageChange} className='select-img'>
                    <option value={template1}>Template 1</option>
                    <option value={template2}>Template 2</option>
                    {/* Add other template options */}
                </select>
                <button onClick={handleGenerateImage}>Download Template</button>
            </div>
        </div>
    );
};

export default ProjectImageTemplate;
