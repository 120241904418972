import { useParams } from 'react-router-dom';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const useURLParams = (filterMap, industries, courses, company,skill) => {

  const params = useParams();
  const type = params['*']?.split("-")[1];

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const urltab = getKeyByValue(filterMap, type);
  const getUrlTab = parseInt(urltab) ?? 0;


    // parsed url
  const parseFiltersFromUrl = (url) => {
    const filters = {
      companies: [],
      qualifications: [],
      locations: [],
      industries: [],
      skills:[]
    };

    const segments = url.split('-');

    // console.log("segmentsssbefore", segments);

    segments.forEach((segment, index) => {
      // console.log("segngggggggg", segments[index+1]);
      if (segment === 'by' && segments[index + 1]) {
        filters.companies = segments[index + 1].split(',').map(name => name.replace(/_/g, ' ').toLowerCase());
      }
      if (segment === 'for' && segments[index + 1]) {
        filters.qualifications = segments[index + 1].split(',').map(name => name.replace(/_/g, ' ').toLowerCase());
      }
      if (segment === 'in' && segments[index + 1]) {
        filters.locations = segments[index + 1].split(',').map(name => name.replace(/_/g, ' ').toLowerCase() );
      }
      if (segment === 'sector' && segments[index + 1]) {
        filters.industries = segments[index + 1].split(',').map(name => name.replace(/_/g, ' ').toLowerCase());
      }
      if (segment === 'skill' && segments[index + 1]) {
        filters.skills = segments[index + 1].split(',').map(name => name.replace(/_/g, ' ').toLowerCase());
      }
    });

    // console.log("segmentsssafter", segments);

    

    const mapAndFilter = (filterArray, sourceArray, sourceKey) => {

      return filterArray.map(filterItem => {
          const match = sourceArray.find(item => item[sourceKey].toLowerCase() === filterItem.toLowerCase());
          return match ? match.id : null;
        }).filter(id => id !== null);

    };

   const filtersIds = {
      companies: mapAndFilter(filters.companies, company, 'company_name'),
      industries: mapAndFilter(filters.industries, industries, 'industry_name'),
      locations: filters.locations,
      qualifications: mapAndFilter(filters.qualifications, courses, 'course_name'),
      skills:mapAndFilter(filters.skills, skill, 'name')
    };

    // console.log("parsedfiltersss", filters, filtersIds);



    return {
      filters: filters,
      filtersIds: filtersIds

    };
  };


  return {getUrlTab, parseFiltersFromUrl};
}

export default useURLParams;