import axios from "axios";
import Resume from "components/student/Resume";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InternshipDetails from "../post/FetchInternshipDetails";
import Screening from "./Screening";

const EnrollInternship = ({ defaultStep = 0 }) => {
  const [step, setStep] = useState(() => defaultStep);
  const { id = 0 } = useParams();
  const [enrollmentId, setEnrollmentId] = useState(() => 0);
  const user = useSelector((state) => state.auth.user);
  // let newId = id;
  const { state } = useLocation();
  const screening_questions = state?.screening_questions || [];

  const onApply = async (screening_questions = "") => {
    const data = await axios.post("/v1/enrollments/internship", {
      internship_id: id,
      screening_questions,
    });
    if (!data?.data?.error) {
      toast.success(data?.data?.message);
      // console.log(data?.data?.data?.id, data?.data, "dataEnrollment");
      setEnrollmentId(data?.data?.data?.id);
      setStep(2);
    } else {
      toast.error(data?.data?.message);
    }
  };

  const dispatch = useDispatch();

  const onProceedToApply = () => {
    if (step === -1) return setStep(0);
    if (!screening_questions?.length) return onApply();
    else setStep(1);
  };

  if (!user?.role) {
    localStorage.setItem("path", window.location.pathname);
  }

  switch (step) {
    case -1:
      return <InternshipDetails id={id} onApply={onProceedToApply} />;
    case 0:
      return <Resume setStep={setStep} onAction={onProceedToApply} />;
    case 1:
      return <Screening screening={screening_questions} onApply={onApply} />;
    default:
      return (
        <Navigate
          to="/student/explore/*"
          state={{ enrolled: "internship", id: enrollmentId }}
        />
      );
  }
};

export default EnrollInternship;
