import axios from 'axios'
import { generateInvoicePDF } from 'components/GenerateInvoice'
import React, { useEffect, useState } from 'react'
import DownloadIcon from './DownloadIcon'
import PrintIcon from './PrintIcon'
import styles from "./subscriptionPlan.module.scss"


const SubscriptionMentoring = () => {
    
    const [mentoringSessions, setMentoringSessions] = useState([]);
    

useEffect(()=> {

    const getMentoring = async () => {

        try {
            const res = await axios.get("/v1/student/get-my-sessions")
    
            // console.log("ressss", res?.data)

            setMentoringSessions(res?.data?.data);
            
        } catch (error) {
            console.log(error);
        }

    }

    getMentoring()


}, []);

const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return dateObject.toLocaleDateString('en-US', options);
  };

// console.log("stttt dataa", mentoringSessions);

    return (

        <div class={`${styles.SubscriptionPlan} container my-5`}>
            <div className="row mb-2">
                <h2>Subscription "Mentoring"</h2>
            </div>
            <div className="row">
                <div class={`${styles.planBox}`}>
                
                {
                    mentoringSessions.length > 0 ? (
                    <table className="table-container">
                        <tbody>
                            <tr className="">
                                <th className={styles.dateHeader} width="10%">Date</th>
                                <th className={styles.dateHeader} width="12%">Mentor</th>
                                <th className={styles.descriptionHeader} width="35%">Project Name</th>
                                {/* <th className={`${styles.sessionCompleted_Header} text-center`} width="15%">Sessions Completed</th>
                                <th className={`${styles.sessionPending_Header} text-center`} width="15%">Sessions Pending</th> */}
                                <th className={`${styles.amountHeader} text-center`} width="15%">Total Amount</th>
                                <th className={styles.invoiceHeader} width="7%">Invoice</th>
                            </tr>
                        {
                            mentoringSessions.map((item, i)=> {
                                return (
                                    <tr className="" key={i}>
                                        <td data-th="Date" className={`${styles.date} table-content`}>{formatDate(item?.createdAt)}</td>
                                        <td data-th="Mentor" className={`${styles.date} table-content`}>{item?.mentor?.first_name + " " + item?.mentor?.last_name}</td>
                                        <td data-th="Description" className={`${styles.description} table-content`}>{  item?.enrollment ? item?.enrollment?.project?.project_title : "Project Exit"} </td>
                                        {/* <td data-th="Description" className={`${styles.sessionCompleted} table-content`}>05</td>
                                        <td data-th="Description" className={`${styles.sessionPending} table-content`}>03</td> */}
                                        <td data-th="Total Amount" className={`${styles.amount} table-content`}>₹{item?.mentor_charges} </td>
                                        <td data-th="Invoice" className="table-content">
                                            <div className='d-flex gap-2 align-items-center'>
                                                <span onClick={()=> generateInvoicePDF({
                                                    name: item?.student?.first_name + " "+ item?.student?.last_name,
                                                    email: item?.student?.email_address,
                                                    id: item?.student?.id,
                                                    amount: item?.mentor_charges,
                                                    desc: "Student Mentoring Subscription"
                                                })}>
                                                    <DownloadIcon />
                                                </span>
                                                <span>
                                                    <PrintIcon />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }

                            {/* <tr className="">
                                <td data-th="Date" className={`${styles.date} table-content`}>02 Oct 2022</td>
                                <td data-th="Description" className={`${styles.description} table-content`}>Mentoring - Shrikant Sharma</td>
                                <td data-th="Description" className={`${styles.sessionCompleted} table-content`}>05</td>
                                <td data-th="Description" className={`${styles.sessionPending} table-content`}>03</td>
                                <td data-th="Total Amount" className={`${styles.amount} table-content`}>₹ 1000</td>
                                <td data-th="Invoice" className="table-content">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <span>
                                            <DownloadIcon />
                                        </span>
                                        <span>
                                            <PrintIcon />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td data-th="Date" className={`${styles.date} table-content`}>02 Oct 2022</td>
                                <td data-th="Description" className={`${styles.description} table-content`}>Mentoring - Shrikant Sharma</td>
                                <td data-th="Description" className={`${styles.sessionCompleted} table-content`}>05</td>
                                <td data-th="Description" className={`${styles.sessionPending} table-content`}>03</td>
                                <td data-th="Total Amount" className={`${styles.amount} table-content`}>₹ 1000</td>
                                <td data-th="Invoice" className="table-content">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <span>
                                            <DownloadIcon />
                                        </span>
                                        <span>
                                            <PrintIcon />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td data-th="Date" className={`${styles.date} table-content`}>02 Oct 2022</td>
                                <td data-th="Description" className={`${styles.description} table-content`}>Mentoring - Shrikant Sharma</td>
                                <td data-th="Description" className={`${styles.sessionCompleted} table-content`}>05</td>
                                <td data-th="Description" className={`${styles.sessionPending} table-content`}>03</td>
                                <td data-th="Total Amount" className={`${styles.amount} table-content`}>₹ 1000</td>
                                <td data-th="Invoice" className="table-content">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <span>
                                            <DownloadIcon />
                                        </span>
                                        <span>
                                            <PrintIcon />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td data-th="Date" className={`${styles.date} table-content`}>02 Oct 2022</td>
                                <td data-th="Description" className={`${styles.description} table-content`}>Mentoring - Shrikant Sharma</td>
                                <td data-th="Description" className={`${styles.sessionCompleted} table-content`}>05</td>
                                <td data-th="Description" className={`${styles.sessionPending} table-content`}>03</td>
                                <td data-th="Total Amount" className={`${styles.amount} table-content`}>₹ 1000</td>
                                <td data-th="Invoice" className="table-content">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <span>
                                            <DownloadIcon />
                                        </span>
                                        <span>
                                            <PrintIcon />
                                        </span>
                                    </div>
                                </td>
                            </tr> */}


                        </tbody>
                    </table>
                    
                    ): (
                        <div style={{padding: "10px"}}> No Mentoring Sessions Available</div>
                    )
                }


                </div>
            </div>
        </div>

    )
}

export default SubscriptionMentoring