import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import StatusButton from "./StatusButton";

import axios from "axios";
import "./internshipTable.scss";
// import SendOpportunity from "../opportunity/SendOpportunity"
// import InternshipDataTable from "components/opportunity/internshipDataTable/InternshipDataTable"
// import Datatable from "components/datatable-new/DatatableNew";
import Datatable from "../../../../components/datatable-new/DatatableNew";
// import DeactivatePost from "./DeactivatePost";
// import MobileDataTable from "components/datatable-new/MobileDataTable";
// import MobileDataTable from "../../../../components/datatable-new/MobileDataTable";

import moment from "moment";
import MobileDataTable from "./MobileDataTable";
import StatusButton from "./StatusButton";

const InternshipTable = ({
  // data,
  setType,
  isPopupOpen,
  setIsPopupOpen,
  setRefreshTable,
  refreshTable,
}) => {

    // // console.log("internship table", data);

//   const [search, setSearch] = useState(() => "");
//   const [rowData, setRowData] = useState(
//     data?.filter((item) => item.status === 2)
//   );

//   useEffect(() => {
//     setRowData(data?.filter((item) => item.status === 2));
//   }, [data, refreshTable]);

//   const [clicked, setClicked] = useState(() => false);
//   const handleClick = () => {
//     setClicked((p) => !p);
//     // console.log(clicked);
//   };


    // return;
//   const closePost = async (id, status) => {
//     if (status < 1 || status > 2) return toast.error("Invalid status");
//     const { error, message } = await axios.patch("/v1/internship/status", {
//       id,
//       status: 3,
//     });

    // if (error) {
    //   toast.error(message);
    // } else {
    //   setRefreshTable(!refreshTable);
    //   toast.success("Internship closed successfully");
    // }
    // handleBlur()
//   };

const {id} = useParams();

// console.log("itable id", id)

const [internship, setInternship] = useState([]);

useEffect(()=> {
  const InternshipDetails = async () =>{
    const internship = await axios.get(`/v1/educator/studentInternships/${id}`)

    // console.log("itable internship", internship)
    setInternship(internship?.data?.data)
  }
  InternshipDetails();
},[]);



// console.log("itable intenship", internship)
const columns = [
    {
      Header: "Date Assigned",
      accessor: "date",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        // console.log("date", data)
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date}
          </strong>
        );
      },
    },
    {
      Header: "Assigned Opportunity",
      accessor: "opportunity",
      width: "17%",
      textalign: "start",
    },
    {
      Header: "Company Name",
      accessor: "company",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.company}
          </strong>
        ); 
      },
    },
    {
      Header: "Employer Name",
      accessor: "employer",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.employer}
          </strong>
        );
      },
    },
    // {
    //   Header: "Progress Status",
    //   accessor: "progress",
    //   width: "13.5%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.progress}
    //       </strong>
    //     );
    //   },
    // },
    
    // {
    //   Header: "Send Invite",
    //   accessor: "invites",
    //   width: "13.5%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.student_invites}
    //         {data.row.original.educator_invites}
    //       </strong>
    //     );
    //   },
    // },

    // /employer/project/students/invite/5
    {
      Header: "Status",
      accessor: "status",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return <strong>{data.row.original.status}</strong>;
      },
    },
    // {
    //   Header: " ",
    //   width: "1%",
    //   sortable: false,
    //   Cell: (data) => {
    //     return (
    //       <DeactivatePost
    //         status={data.row.original.statusCode}
    //         projectid={data.row.original.id}
    //         method={"project"}
    //         closePost={() =>
    //           closePost(data.row.original.id, data.row.original.statusCode)
    //         }
    //         // activatePost={() =>
    //         //   activatePost(data.row.original.id, data.row.original.statusCode)
    //         // }
    //       />
    //     );
    //   },
    // },
  ];



  // all internships
  // if(internship?.student !== null){
    
  //   internship?.internship_invites?.forEach(invite => {
  //   // Check if the project_id is not present in project_enrollments
  //   const internshipAlreadyEnrolled = internship?.student?.internship_enrollments?.some(enrollment => enrollment.internship_id === invite.internship_id);
  
  //   // If the project is not already enrolled, add it to project_enrollments
  //   if (!internshipAlreadyEnrolled) {
  //       // Move the project object inside project_enrollments
  //       internship?.student?.internship_enrollments?.push({
  //           // Include necessary fields from invite
  //           id: invite.internship_id,
  //           // ... (other fields you want to include)
  //           internship: invite.internship
  //       });
  //   }
  //   });
  //   delete internship.internship_invites;
  // }
  
  
  //  const studentEnrolled = internship?.student === null ? internship.internship_invites : internship?.student?.internship_enrollments
  
  



  const rows = internship
    ?.map((post) => {
      // const internshipEnrollments = post?.educator_students_internship?.student?.internship_enrollments?.length > 0 ? post?.educator_students_internship?.student?.internship_enrollments[0] : {};

      const internshipEnrollment = post?.educator_students_internship?.student?.internship_enrollments?.find(
        enrollment => enrollment.internship_id === post?.internship_id
      );

      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        opportunity: (
          <Link to={`/employer/internship/${post?.id}`}
            key={post?.internship?.internship_title || "No title"}
          >
            {post?.internship?.internship_title || "No title"}
          </Link>
        ),
        company:  post?.internship?.global?.employer?.company?.company_name ?? post?.internship?.global?.employer?.company?.company_n,
        employer: post?.internship?.global?.employer?.first_name + " " + post?.internship?.global?.employer?.last_name || "",
        status: (
          <StatusButton 
            status={internshipEnrollment?.status ?? 9}
            type={"internship"}
          />
          
          ),
      };
    });

  return (
    <div className="internshipTable_main">

    {
      rows ? (
        <>
        <div className="d-none d-sm-block">
          <Datatable columns={columns} data={rows} />
        </div>
        <div className="d-block d-sm-none">
          <MobileDataTable columns={columns} data={rows} type={"internship"} />
        </div>
        </>

      ): (
        <p>Loading...</p>
      )
    }
    
      {/* <InternshipDataTable data={ rows} columns={columns} /> */}
      {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
    </div>
  );
};

export default InternshipTable;
