import React from "react"
import "../mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link } from "react-router-dom"
import moment from "moment"

const MobileViewMentorTable = ({ columns, data,handleAccountActivation}) => {
  // console.log(columns, data,"mentor")
 
  return (
    <>
      {data?.map((item, i) => {

       

        return (
          <div className="mobileDataTable mt-4" key={i}>
            <div className="data_container">
              <div className="table_data">
                <p>Name</p>
                <span className="Total_enrolled">{item?.name}<br/>{item?.address}</span>
              </div>
              
              <div className="table_data">
                <p>Industry Sector</p>
                <span className="Total_enrolled">{item?.industry_name}</span>
              </div>
              

              <div className="table_data">
                <p>Signup Date</p>
                <span className="Total_enrolled">{item?.signup_date}</span>
              </div>

              <div className="table_data">
              <p>Last Login</p>
                <span className="Total_enrolled">{item?.last_login}</span>
              </div>

              <div className="table_data">
                <p>Mentoring Sessions</p>
                <span className="Total_enrolled">{item?.mentoring_sessions}</span>
              </div>

              <div className="table_data">
                <p>Evaluations</p>
                <span className="Total_enrolled">{item?.EvaluationCount}</span>
              </div>
              <div className="table_data">
                <p>Mentoring Earnings</p>
                <span className="Total_enrolled">{item?.mentoring_earnings}</span>
              </div>
              <div className="table_data">
                <p>Evaluation Earnings</p>
                <span className="Total_enrolled">{"-"}</span>
              </div>

            
              <div className="Status_data">
              <p>Action</p>
              <button
       onClick={() => handleAccountActivation(item?.id)}
         className={`deactivate-btn ${
          item?.deletedAt === "Activate" ? "bg-success" : "bg-danger"
          }`}
                    >
          {item?.deletedAt}
            </button>
           
              
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewMentorTable
