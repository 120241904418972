import axios from "axios";
import Loader from "components/employer/Loader";
import { duplicateJob } from "components/employer/duplicate/job";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "styles/employer/jobContent.scss";
import "styles/employer/mentorjob.scss";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";
import "./id.scss";
import ShowSelecetdTab from "./sections";

const JobPost = () => {
  const { id = 5 } = useParams();
  // const { data, loading } = useFetch(`/v1/enrollments/job/${id}`);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, reduxDispatch] = useReducer(reducer, initialState);

  const [enrollments, setEnrollments] = useState(() => ({
    count: 0,
    data: [],
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filters = Object?.keys(state).reduce((acc, curr) => {
          if (curr === "selected") return acc;
          else if (state[curr] instanceof Set) {
            if (!state[curr].size) return acc;
            const set = [...new Set(state[curr])];
            return { ...acc, [curr]: set };
          } else if (state[curr]) {
            return { ...acc, [curr]: state[curr] };
          }
          return acc;
        }, {});
        const response = await axios.get(`/v1/enrollments/job/${id}`, {
          params: { ...filters },
        });

        const { error, message, data } = response?.data;
        setData(data);
        if (error) {
          toast.error(message);
        }
        if (data) {
          setEnrollments(() => ({
            count: {
              ...data?.count,
              applied: data?.enrollments?.length,
            },
            data: data?.enrollments,
          }));
        }
      } catch (err) {
        // console.log(err);
      }
    };
    fetchData();
  }, [state]);

  // // console.log("data", data);
  useEffect(() => {
    if (data?.data) {
      setEnrollments(() => ({
        count: {
          ...data?.data?.count,
          applied: data?.data?.enrollments?.length,
        },
        data: data?.data?.enrollments,
      }));
    }
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  // // console.log(data?.data?.post, "hello world");
  if (loading || !data) {
    return <Loader />;
  }

  // // console.log(data.post);

  const onDuplicate = () => {
    duplicateJob({ data: data?.post, dispatch });
    navigate("/employer/job/add");
  };

  const handleDetails = () => {
    navigate(`/employer/job/details/${id}`);
  };

  const tabMap = {
    "Applications received": "applied",
    "Project Submitted": "submitted",
    Shortlisted: "shortlisted",
    Rejected: "rejected",
    "Interviews Scheduled": "hired",
  };

  const handleStatusChange = ({ status, oldStatus, id }) => {
    // console.log({ status, oldStatus, id });
    // status
    // 0: Applied
    // 1: Left
    // 2: Seen
    // 3: Under Review
    // 4: Shortlisted
    // 5: In touch
    // 6: Hired
    // 7: Rejected

    const tab = {
      0: "applied",
      2: "submitted",
      4: "shortlisted",
      6: "hired",
      7: "rejected",
    };

    setEnrollments(() => ({
      count: {
        ...enrollments?.count,
        [tab[status]]: Number.parseInt(enrollments?.count[tab[status]]) + 1,
        [tab[oldStatus]]:
          Number.parseInt(enrollments?.count[tab[oldStatus]]) - 1,
      },
      data: enrollments?.data?.map((item) => {
        if (item.id === id) {
          item.status = status;
        }
        return item;
      }),
    }));
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/job/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Job closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };

  const CTC_incentive_from = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(data?.post?.ctc_from);
  const CTC_incentive_to = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(data?.post?.ctc_to);

  // // console.log(data?.post);

  return (
    <section className="job-content mobileView p-1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content-creation">
              <div className="row flex">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h2>{data?.post?.job_title}</h2>
                  <p className="light">
                    {data?.post?.job_category === 0
                      ? "Office Based Job"
                      : "work from home"}
                  </p>
                  <p className="txt5">
                    {data?.post?.roles_and_responsibilities?.slice(0, 450)}
                  </p>
                  {data?.post?.job_city?.length > 0 && (
                    <p className="light">
                      <p className="light">Preferred cities for the job</p>
                      <i className="fa-solid fa-map-marker-alt"></i>
                      {data?.post?.job_city.map((item, index) => (
                        <span className="text-capitalize" key={index}>
                          {item}&nbsp;
                        </span>
                      ))}
                    </p>
                  )}
                </div>
                {/* <div className="col-1 non"></div> */}
                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    //  className="flex"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="box1">
                      <p>No. of Openings</p>
                      <h3 className="text">{data?.post?.vacancies}</h3>
                      <p className="light">Employees</p>
                    </div>
                    {/* <div class="duration"><h6>CTC</h6><h3>{data?.data?.post?.ctc_from}-{data?.data?.post?.ctc_to}</h3><span>LPA</span></div> */}
                    <div className="box">
                      <p>Salary</p>
                      <div className="d-flex">
                        <h3 className="text">{CTC_incentive_from}</h3>
                        <h3 className="text">-{CTC_incentive_to}</h3>
                      </div>
                      <p className="light">
                        {data?.post?.ctc_type === 0 ? "LPA" : "INR"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 d-none d-sm-block">
                  <div className="btn-boxs">
                    <button onClick={handleDetails}>
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                    <button type="button" onClick={onDuplicate}>
                      Duplicate<i className="fa-regular fa-copy"></i>
                    </button>
                    <button className="close" onClick={handleClose}>
                      Close Opening <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="job-tabs">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {Object.keys(tabMap)?.map((item, index) => (
                    <button
                      key={index}
                      className={`nav-link ${
                        index === selectedTab ? "active" : ""
                      }`}
                      onClick={() => setSelectedTab(index)}
                    >
                      {item}
                      <span> ({enrollments?.count?.[tabMap[item]] || 0}) </span>
                    </button>
                  ))}
                </div>
              </nav>
              <ShowSelecetdTab
                tab={selectedTab}
                post={data?.post}
                enrollments={enrollments}
                handleStatusChange={handleStatusChange}
                dispatch={reduxDispatch}
              />
            </div>
          </div>
        </div>
        <div className="mobo_btn_container d-flex d-sm-none">
          <div className="mobo_btn_left">
            <i
              onClick={onDuplicate}
              className="fa-regular fa-copy bg-success"
            ></i>
            <i
              onClick={handleClose}
              className="fa-solid fa-xmark bg-danger"
            ></i>
          </div>
          <button onClick={handleDetails}>
            View Details <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default JobPost;
