import axios from "axios";
import { duplicateProject } from "components/employer/duplicate/project";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SelectedTabs from "./SelectedTabs";

const StudentInvite = ({ method }) => {
  const { id = 0 } = useParams();
  const { data, loading } = useFetch(`/v1/enrollments/project/${id}`);

  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [sentStudent, setSentStudent] = useState([]);
  const [rejectedStudent, setRejectedStudent] = useState([]);

  const [dataLength, setDataLength] = useState({
    1: 0,
    2: 0,
  });

  const [new1, setNew1] = useState(() => false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `/v1/enrollments/project/all-enrolled-students/${id}`
      );

      // for sent student
      const response2 = await axios.get(
        `/v1/invitations/get_student_invites?status=1&method=${method}&opportunity_id=${id}`
      );
      // for reject student
      const response3 = await axios.get(
        `/v1/invitations/get_student_invites?status=2&method=${method}&opportunity_id=${id}`
      );

      setEnrolledStudents(response?.data?.data);
      setSentStudent(response2?.data?.data);
      setRejectedStudent(response3?.data?.data);

      const responseId1 = response2?.data?.data?.map(
        (item) => item?.receiver_id
      );
      const responseId2 = response3?.data?.data?.map(
        (item) => item?.receiver_id
      );

      const allId = [...responseId1, ...responseId2];

      const filterData = response?.data?.data?.filter(
        (item) => !allId?.includes(item?.student?.id)
      );

      setEnrolledStudents((prev) =>
        prev?.filter((item) => !allId?.includes(item?.student?.id))
      );
      setDataLength(() => {
        return {
          0: filterData?.length,
          1: response2?.data?.data?.length,
        };
      });
    };

    fetchData();
  }, [id, method, new1]);

  useEffect(() => {}, [new1]);

  const [enrollments, setEnrollments] = useState(() => ({
    count: 0,
    data: [],
  }));
  const [state, setState] = useState(true);

  // useEffect(() => {
  //   if (data?.data) {
  //     setEnrollments(() => ({
  //       count: {
  //         ...data?.data?.count,
  //         applied: data?.data?.enrollments?.length,
  //       },
  //       data: data?.data?.enrollments,
  //     }));
  //   }
  // }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  // if (loading || !data?.data) return <Loader />;

  const onDuplicate = () => {
    duplicateProject({ data: data?.data?.post, dispatch });
    navigate("/employer/project/add");
  };

  const handleDetails = () => {
    navigate(`/employer/project/details/${id}`);
  };

  const tabMap = {
    "invite Students": "applied",
    "Invitations sent": "Left",
  };

  const handleStatusChange = ({ status, oldStatus, id }) => {
    // console.log({ status, oldStatus, id });
    // console.log("status", status);
    // status
    // 0: Applied
    // 1: Left
    // 2: Seen
    // 3: Under Review
    // 4: Shortlisted
    // 5: In touch
    // 6: Hired
    // 7: Rejected

    const tab = {
      0: "Applied",
      1: "Left",
      2: "Seen",
      3: "Under Review",
      4: "Shortlisted",
      5: " In touch",
      6: "Hired",
      7: "List",
    };
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Project closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };

  return (
    <section className="job-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content-creation">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h2>{data?.data?.post?.project_title}</h2>
                  <p className="light">project</p>
                  <p className="txt5">{data?.data?.post?.project_goal}</p>
                </div>
                <div className="col-1 non"></div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="flex">
                    <div className="box1">
                      <p>Duration</p>
                      <h3>
                        {data?.data?.post?.duration_from} -{" "}
                        {data?.data?.post?.duration_to}
                      </h3>
                      <p className="light">Weeks</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 d-none d-sm-block">
                  <div className="btn-boxs">
                    <button onClick={handleDetails}>
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                    <button type="button" onClick={onDuplicate}>
                      Duplicate<i className="fa-regular fa-copy"></i>
                    </button>
                    <button className="close" onClick={handleClose}>
                      Close Opening <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-tabs">
              <nav className="flex">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {Object.keys(tabMap)?.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className={`nav-link ${
                          index === selectedTab ? "active" : ""
                        }`}
                        onClick={() => setSelectedTab(index)}
                      >
                        {item}
                        <span className="px-1">({dataLength[index]})</span>
                      </button>
                    );
                  })}
                </div>
                <button
                  onClick={() => setState((p) => !p)}
                  className="bg-primary"
                  style={{ width: "247px", height: "48px" }}
                >
                  {state ? "View List " : "Detail List"}
                </button>
              </nav>
              <SelectedTabs
                tab={selectedTab}
                post={data?.data?.post}
                enrollments={enrollments}
                handleStatusChange={handleStatusChange}
                state={state}
                method={method}
                new1={new1}
                setNew1={setNew1}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mobo_btn_container d-flex d-sm-none">
        <div className="mobo_btn_left">
          <i
            onClick={onDuplicate}
            className="fa-regular fa-copy bg-success"
          ></i>
          <i onClick={handleClose} className="fa-solid fa-xmark bg-danger"></i>
        </div>
        <button onClick={handleDetails}>
          View Details <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      </div>
    </section>
  );
};

export default StudentInvite;
