import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "./popup.scss";

const TextField = ({
  label,
  id,
  placeholder,
  required = false,
  register,
  options = {},
  setValue = () => {},
  isPopupOpen,
  updatePopup,
  disabled = false,
  value = "",
  method,
  secondaryLabel = false,
  getValues = () => {},
  maxLength,
}) => {
  const [data, setData] = useState(() => []);
  const [inputValue, setInputValue] = useState(() => "");
  const [focus, setFocus] = useState(() => 0);
  const inputRef = useRef();
  const [isPlace] = useState(() => id === "place_name");
  const [requests, setRequests] = useState({});

  useEffect(() => {
    if (!isPlace || !inputValue) return;

    const getCompanies = async () => {
      const companies = await axios.get(`/v1/${method}/${method}_name`, {
        params: { query: inputValue },
      });
      setRequests((prev) => ({
        ...prev,
        [inputValue]: companies?.data?.data || [],
      }));
      setData(companies?.data?.data || []);
    };

    if (!requests[inputValue]) {
      if (
        requests[inputValue.slice(0, -1)] &&
        !requests[inputValue.slice(0, -1)].length
      ) {
        setRequests((prev) => ({ ...prev, [inputValue]: [] }));
        return;
      } else getCompanies();
    } else setData(requests[inputValue]);
  }, [inputValue, isPlace, method, requests]);

  const onChange = (e) => {
    if (!isPlace) return;

    const { value } = e.target;
    setInputValue(value);
    setValue("place_name", value);
    setValue("place_id", 0);

    if (value.length > 0) {
      setFocus(1);
      updatePopup(true);
      const filteredData = [...data].filter((item) =>
        (item?.company_name || item?.institution_name).startsWith(value)
      );
      setData(filteredData);
      if (filteredData?.length && filteredData[0][`${method}_name`] === value) {
        setValue("place_id", filteredData[0].id);
      }
    } else {
      updatePopup(false);
      setData([]);
    }
  };

  const closePopup = () => {
    updatePopup(false);
  };

  const handleKey = (e) => {
    if (!isPlace || !data.length) return;
    setValue("place_id", 0);
    let focusCounter = focus;
    if (e.key === "ArrowDown") {
      if (focusCounter < data.length) focusCounter++;
    } else if (e.key === "ArrowUp") {
      focusCounter > 0 && focusCounter--;
    } else if (e.key === "Enter") {
      const value =
        data[focusCounter - 1].company_name ||
        data[focusCounter - 1].institution_name;
      e.preventDefault();
      inputRef.current.value = value;
      setInputValue(value);
      updatePopup(false);
      setValue("place_name", value);
      setValue("place_id", data[focusCounter - 1].id);
      setFocus(0);
    } else {
      return;
    }
    setFocus(focusCounter);
  };

  const updateValue = (item) => {
    inputRef.current.value = item.itemText;
    setValue("place_name", item.itemText);
    setValue("place_id", item.id);
    setInputValue(item.itemText);
    updatePopup(false);
    closePopup();
  };

  return (
    <div
      className={`form-group mb-3 ${isPlace ? "place_container" : ""}`}
      onKeyDown={handleKey}
    >
      <label htmlFor={id}>
        {label}
        {required && <span>*</span>}
        {secondaryLabel && <small>{secondaryLabel}</small>}
      </label>

      {!isPlace ? (
        <input
          className="form-control mt-2"
          type="text"
          name={id}
          id={id}
          placeholder={placeholder}
          {...register(id, { required, ...options })}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      ) : (
        <>
          <input
            className="form-control mt-2"
            type="text"
            name={id}
            id={id}
            placeholder={placeholder}
            {...register(id, { required, ...options })}
            onChange={onChange}
            ref={inputRef}
            autoComplete={"off"}
            defaultValue={value}
            disabled={disabled}
            maxLength={maxLength}
            // value={getValues(id)}
          />
          {isPopupOpen && (
            <Popup
              data={data}
              input_value={inputValue}
              focus={focus}
              updateValue={updateValue}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TextField;

const Popup = ({
  data = [],
  input_value = "",
  updateValue = () => {},
  focus = 0,
}) => {
  const onclick = (_, item) => {
    updateValue({
      itemText: item?.company_name || item?.institution_name,
      id: item?.id,
    });
  };

  return data.length ? (
    <div className="place_items">
      {data?.map((item, index) => {
        const entity_name = item?.company_name || item?.institution_name;
        return (
          <div
            onClick={(e) => onclick(e, item)}
            key={entity_name + index}
            className={`place_item ${focus === index + 1 ? "focus" : ""}`}
          >
            <strong>{entity_name.substr(0, input_value.length)}</strong>
            <span>{entity_name.substr(input_value.length)}</span>
            <input type="hidden" value={entity_name} />
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};
