import ChipsFieldWithPopup from 'components/formComponents/ChipsFieldWithPopup'
import TextFieldWithPopup from 'components/formComponents/TextFieldWithPopup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import "./educatorFilter.scss"

const EducatorFilters = ({dispatch,state}) => {
  const [industry_sector, setIndustrySector] = useState(() => [])
  const [qualification, setQualification] = useState(() => [])
  const { handleSubmit, register, setValue } = useForm()
  const [isPopupOpen, updatePopup] = useState(() => false);
  const deleteIndustrySector = (item) => {
    // console.log("chipss delete", item)
    setIndustrySector((prev) => prev.filter((skill) => skill.industry_name !== item));
    // console.log("industru", industry_sector)
  }
  const deleteQualification = (item) => {
    setQualification((prev) =>
      prev.filter((skill) => skill.course_name !== item)
    )
  }


  const onSubmit = () => {
    // const required_skills = skills.map((item) => item?.id);
    // const course_preferred = qualification.map((item) => item?.id);
    const industry = industry_sector.map((item) => item?.id);
   // console.log("indusss",industry)
    dispatch({
      type: "change_value",
      payload: {  industry_sector: industry },
    });
  };

  const updateLocation = (_name, value) => {
    // console.log("location1..",value)
    // console.log("location2..",_name)
    // const id = value?.id || ""; 
    // const id = value || ""; 

  const id = typeof value === 'object' ? value?.id : value;
  // console.log("iddd", id);
   
    dispatch({
      type: "change_value",
      payload: { location: value},
    });

  }

  const onReset = () => {
    // setSkills(() => []);
    // setQualification(() => []);
    setIndustrySector(() => []);
    // setLocation(()=>[]);
    dispatch({ type: "reset" });
  };



  return (
    <div className="educator_filter">
       <div className="filter_tag d-flex justify-content-start align-items-center gap-2 ">
                    <h4 className="m-0" style={{ color: "#6149CD" }}>
                      Filters
                    </h4>
                    <span className=" d-block ">
                      <i
                        class="fa-solid fa-sliders fa-lg"
                        style={{ color: "#6149CD" }}
                      ></i>
                    </span>
                  </div>
      
      
      <form action={""}>
        {/* <div class="form_country form-group">
        <label htmlFor="city">Country</label>
              <input
                type="Search"
                id="city"
                list="search-data"
                placeholder="Search City"
        />
        </div> */}
     
        {/* <div class="form_country form-group">
        <label htmlFor="city">State</label>
              <input
                type="Search"
                id="city"
                list="search-data"
                placeholder="Search State"
              />
        </div>
        <div class="form_country form-group">
        <label htmlFor="city">City</label>
              <input
                type="Search"
                id="city"
                list="search-data"
                placeholder="Search City"
        />
        </div> */}

        <TextFieldWithPopup
            required={false}
            label="Location"
            id="location"
            isPopupOpen={isPopupOpen}
            updatePopup={updatePopup}
            path="/v1/location/city"
            key_name="location"
            setValue={updateLocation}
            // withId={state?.city}
            placeholder="Noida, U.P, Iindia "
            className="custom-textfield"
            
          />
          <style>
            {`
              .custom-textfield {
                border-radius: 20px;
              
              }
            `}
          </style>


        
        <ChipsFieldWithPopup
          register={register}
          label="Industry Sector"
          id="industry_sector"
          items={industry_sector}
          setItems={setIndustrySector}
          deleteItem={deleteIndustrySector}
          setValue={setValue}
          path="/v1/filter/industry_sector"
          placeholder="Search Industry Sector"
          key_name="industry_name"
        />
        {/* <ChipsFieldWithPopup
          register={register}
          label="Industry area"
          id="industry_sector"
          items={industry_sector}
          setItems={setIndustrySector}
          deleteItem={deleteIndustrySector}
          setValue={setValue}
          path="/v1/filter/industry_sector"
          placeholder="Search Industry Sector"
          key_name="industry_name"
        /> */}



        
        <div className="flex gap-4">
          <button className="clear_btn btn " onClick={onReset} type="reset">Clear</button>
          <button className="apply_btn btn bg-primary" onClick={handleSubmit(onSubmit)} type="submit">Apply</button>
        </div>
      </form>
    </div>
  )
}

export default EducatorFilters