import React, { useState } from "react"
import { downloadCSV } from "components/dataTable/Export"
import { Link } from "react-router-dom"
import Datatable from "components/datatable-new/DatatableNew"

const AdminCouponUsageData = () => {
  const [search, setSearch] = useState(() => "")
  const rows = [
    {
      date_used: "2/11/2022",
      coupon_name: "QOLLABB20",
      user_name: "Adarsh Pathak",
      user_type: "Student",
    },
    {
      date_used: "2/11/2022",
      coupon_name: "QOLLABB20",
      user_name: "Adarsh Pathak",
      user_type: "Student",
    },
    {
      date_used: "2/11/2022",
      coupon_name: "QOLLABB20",
      user_name: "Adarsh Pathak",
      user_type: "Student",
    },
    {
      date_used: "2/11/2022",
      coupon_name: "QOLLABB20",
      user_name: "Adarsh Pathak",
      user_type: "Student",
    },
    {
      date_used: "2/11/2022",
      coupon_name: "QOLLABB20",
      user_name: "Adarsh Pathak",
      user_type: "Student",
    },
  ]
  const columns = [
    {
      Header: "Coupon Name",
      accessor: "coupon_name",
      width: "20%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.coupon_name}
          </strong>
        )
      },
    },
    {
      Header: "User Name",
      accessor: "user_name",
      width: "20%",
      textalign: "center",
    },
    {
      Header: "User Type",
      accessor: "user_type",
      width: "20%",
      textalign: "center",
    },
    {
      Header: "Date Used",
      accessor: "date_used",
      width: "20%",
      textalign: "center",
    },
    {
      Header: " ",
      accessor: " ",
      width: "20%",
      textalign: "center",
    },
  ]
  return (
    <div className="datatable-container data-usage p-3">
      <div className="table_head flex mb-3">
        <div className="mobo_upload">
          <h3 className="mb-0">Courses</h3>

          <Link
            to="/employer/internship/add"
            className="upload_btn_icon_mobo btn btn-primary"
          >
            <i
              className="fa-solid fa-cloud-arrow-down post-icons"
              onClick={() => downloadCSV(rows)}
            ></i>
          </Link>
        </div>

        <div className="add_icon non">
          <button
            type="button"
            className="upload_btn_icon btn btn-primary text-white px-3"
            data-toggle="modal"
            data-target="#addModal"
          >
            Add <i className="fa-solid fa-plus"></i>
          </button>
          <button
            className="upload_btn_icon btn btn-primary"
            onClick={() => downloadCSV(rows)}
          >
            <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
          </button>

          <label className="search_label">
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
            <i className="fa-solid fa-magnifying-glass"></i>
          </label>
        </div>
      </div>
      <Datatable data={rows} columns={columns}/>
    </div>
  )
}

export default AdminCouponUsageData
