import React, { useState } from "react"
import "./selectCompany.scss"
import { toast } from "react-toastify"
import Heading1 from "../../../components/typography/Heading1.js"
import axios from "axios"
import { updateBranch } from "../../../services/actions/auth.js"
import { useDispatch } from "react-redux"
import AuthNavbar from "../../../components/navbar/AuthNavbar.jsx"

const SelectCompanyContainer = ({
  data = false,
  changeStep = () => {},
  entityName = "",
  mode,
}) => {
  // console.log(data)
  const [selectedCompany, setSelectedCompany] = useState(() =>
    data?.length ? 0 : -1
  )
  const dispatch = useDispatch()

  const onClick = (index) => {
    if (selectedCompany === index) {
      setSelectedCompany(-1)
    } else {
      setSelectedCompany(index)
    }
  }

  const onNext = async () => {
    if (isNaN(selectedCompany)) {
      toast.error(`Please select a ${mode}`, {
        toastId: selectedCompany,
        theme: "colored",
      })
    } else {
      if (selectedCompany === -1) changeStep(1)
      else {
        const { data: axiosData } = await axios.post(
          "/v1/auth/branch/add_to_user",
          { branch_id: data[selectedCompany].id },
          { withCredentials: true, validateStatus: () => true }
        )

        if (axiosData?.error)
          toast.error(axiosData?.message, {
            toastId: selectedCompany,
            theme: "colored",
          })
        else {
          dispatch(updateBranch({ branch_id: data[selectedCompany].id }))
          changeStep(3)
        }
      }
    }
  }

  

  return (
    <div className="select-company">
      <AuthNavbar />
      <div className="container">
        <div className="company_main row outer mx-0">
          <div className="company_left col-lg-6 d-flex flex-direction-column justify-content-start align-items-center">
            <div className="obs-left">
              <Heading1
                text={`Select your ${
                  mode === "company" ? "Company" : "Institution"
                }`}
              />
              <p>
                Looks like we have multiple companies with similar names, please
                choose which one you belong to.
              </p>
              {selectedCompany !== -1 && (
                <button
                  type="button"
                  className="btn btn-labeled btn-light btn-action-main btn-white"
                  onClick={onNext}
                >
                  <strong>Continue </strong>
                  <span className="btn-label">
                    {" "}
                    <i className="fa fa-angle-right"></i>
                  </span>
                </button>
              )}
              {selectedCompany === -1 && (
                <button
                  type="button"
                  className="btn btn-labeled btn-light btn-action-main btn-white"
                  onClick={onNext}
                >
                  <strong>Create New </strong>
                  <span className="btn-label">
                    {" "}
                    <i className="fa fa-plus"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className="company_right col-lg-6 obs-right-outer">
            <div className="obs_company_right obs_right obs-right">
              <div className="all_company_card w-100  row overflow-auto m-0">
                {data?.map((item, index) => {
                  return (
                    <div
                      className="company_right_card col-sm-6 col-6 "
                      key={item?.id}
                    >
                      <div
                        className={`cards ${
                          selectedCompany === index ? "actives" : ""
                        }`}
                      >
                        <div className="company_content row gx-0">
                          <div
                            className="flex col-3"
                            style={{ width: "100px" }}
                          >
                            <img
                              src={item?.company_logo}
                              className="card-img p-0 m-0"
                              alt="..."
                              style={{ width: "100px" }}
                            />
                          </div>

                          <div className="company_card_text col-7">
                            <h5 className="company_text card-title">
                              {entityName}
                            </h5>
                            <p
                              className="card-text"
                              style={{ marginBottom: "0px" }}
                            >
                              <small className="text-muted">
                                {" "}
                                {item?.address} {item?.pincode}
                              </small>
                            </p>
                            {selectedCompany === index ? (
                              <button
                                className="mt-2 active-btn"
                                onClick={() => onClick(index)}
                              >
                                Selected
                              </button>
                            ) : (
                              <button
                                className="mt-2"
                                onClick={() => onClick(index)}
                              >
                                Select
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectCompanyContainer
