import axios from 'axios';
import Loader from 'components/employer/Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./mentorcalender.scss";



// const EventModal = ({ event, onClose }) => {
//   return (
//     <div className="overlay">
//       <div className="event-modal">
//         <h3>{event.title}</h3>
//         <p>{`Date: ${event.start.toLocaleDateString()}`}</p>
//         <p>{`Student: ${event.studentName}`}</p>
//         <p>{`Start Time: ${event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</p>
//         <p>{`End Time: ${event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</p>
//         <button onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// };

const EventModal = ({ events, onClose, joinSession }) => {


  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };


  return (
    <div className="overlay">
      <div className="event-modal">
        <div className='events-top'>
          <h4>Events for {events[0]?.start.toLocaleDateString()}</h4>
          <i class="fa fa-times" aria-hidden="true" onClick={onClose}></i>
        </div>
        <div className='model-content'>
        {events.map((event, i) => (
            <div key={i}  className='model-detail'>
              {/* <p className='event-title'>{`Title: ${event.title}`}</p> */}
              <p>{`${event.studentName}: ${event.title} `}</p>
              <p className='event-time'>{`${event.start.toLocaleTimeString([], {hour: '2-digit',minute: '2-digit', })} - ${event.end.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`}</p>
              {/* <p>{`End Time: ${event.end.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`}</p> */}
              <button className={`${isToday(event.start) ? "btngreen" : "btngrey" }`}
                onClick={(e) => { joinSession(e, event) }} 
                disabled={!isToday(event.start)}>
                Join
              </button>
            </div>
        ))}
        </div>
        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};


const MentorCalender = () => {

  const [sessionData, setSessionData] = useState([])
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState('month');

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate()

  

  // const handleEventClick = (event) => {
  //   setSelectedEvent(event);
  //   setIsModalOpen(true);
  // };

  async function joinSession(e, item) {

    // console.log("event item", item)
    e.preventDefault();
    // if (new Date(item?.startTime).getTime() === new Date().getTime()) {
    if (1) {
      navigate(`/mentor/session/${item?.id}/${item?.roomId}`);
    } else {
      toast.warning("Your Session Haven't Started Now")
    }
  // }
  }

  
  const handleEventClick = (slotInfo) => {
    const selectedDate = slotInfo.start;
    const eventsForDate = mentorSessions.filter(
      (event) =>
        event.start.getDate() === selectedDate.getDate() &&
        event.start.getMonth() === selectedDate.getMonth() &&
        event.start.getFullYear() === selectedDate.getFullYear()
    );
    setSelectedEvent(eventsForDate);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    axios.post("/v1/mentor/getSessions")
      .then(response => {
        setSessionData(response?.data?.data);
        setLoading(false)
      })
      .catch(error => {
        console.error(error);
        setLoading(false)

      });

  }, []);



  // new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })
  // console.log("session", sessionData);


  // // console.log("sess", mentorSessions1)
  const mentorSessions = sessionData.map(session => ({
    title: session?.enrollment?.project?.project_title,
    start: new Date(session?.startTime),
    end: new Date(session?.endTime),
    studentName: `${session?.student?.first_name} ${session?.student?.last_name}`,
    id: session?.id,
    roomId: session?.roomId
  }));

  // console.log("mentor", mentorSessions)
  const localizer = momentLocalizer(moment);

  if(loading) return <Loader />
  return (
    <div className="mentor-calender">
      <div className="container">
        <div className='mentor-container'>

      <div className="row">
        <div className="col-12">
            <h2>Calender</h2>
            <div className="calender-box">
              <Calendar
                localizer={localizer}
                events={mentorSessions}
                startAccessor="start"
                endAccessor="end"
                views={['month', 'day']}
                style={{ height: 500 }}
                onView={(view) => setCurrentView(view)}
                onSelectEvent={handleEventClick}
                // You can customize the event rendering here
                eventPropGetter={(event, start, end, isSelected) => {
                  // Customize event style based on your requirements
                  const backgroundColor = isSelected ? '#FFA826' : '#FFD699';
                  const borderColor = '#FFA826';
                  // console.log("events",{event, isSelected})
                  
                  return {
                    style: {
                      backgroundColor,
                      borderColor,
                      color: 'black',
                    },
                  };
                }}
                // Customize event content
                components={{
                  event: ({event}) => ( 
                    <div>
                      {/* {// console.log("viewww",view)} */}
                      <div>{`Student: ${event?.studentName}`} </div>
    
                      {currentView === 'month' && ( // Conditionally render start and end time only in 'month' view
                          <div>{`${event?.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${event?.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</div>
                        )}
                    </div>
                  ),
                }}
              />
            </div>
        </div>
      </div>

        </div>
      </div>
      {isModalOpen && (
        <EventModal
        events={selectedEvent}
          onClose={closeModal}
          joinSession={joinSession}
        />
      )}

    
    </div>

  )
}

export default MentorCalender