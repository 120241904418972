import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const GroupSessions = ({user}) => {

      const [getData, setGetData] = useState([]);
      const [sessionData, setSessionData] = useState([]);

      const [timeZones, setTimeZones] = useState();
      const [selectedDate, setSelectedDate] = useState();
      const [selectedTime, setSelectedTime] = useState();
      const [Schedule, setSchedule] = useState({});
      const navigate = useNavigate();


      const minDate = new Date().toISOString().split("T")[0]; // get current date

      const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const dayOfWeek = daysOfWeek[date.getDay()]; // get the day of the week in string format
        setSelectedDate(date.toISOString().split("T")[0]);
        // console.log(date, user, "Filtered");
        const selectedTimezones = user.timings.find(
          (item) => item?.day === dayOfWeek.toLocaleUpperCase()
        );
        // console.log("timezones",selectedTimezones);
        const timeslots = convertTimeSlotsToDateSlots(
          date,
          selectedTimezones?.duration
        );
        // console.log(timeslots, "timeslots");
        setTimeZones(timeslots);
      };

      function convertTimeSlotsToDateSlots(date, timeSlots) {
        const dateObj = new Date(date);
        const dateSlots = {};
    
        timeSlots?.forEach((timeSlot) => {
          const [startTimeStr, endTimeStr] = timeSlot.split("-");
          const startTime = new Date(dateObj.toDateString() + " " + startTimeStr);
          const endTime = new Date(dateObj.toDateString() + " " + endTimeStr);
          const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); // duration in minutes
    
          let slotStartTime = startTime;
          while (slotStartTime < endTime) {
            const slotEndTime = new Date(slotStartTime.getTime() + 30 * 60 * 1000); // add 30 minutes
            const slotKey = `${slotStartTime.getHours()}:${slotStartTime.getMinutes()}-${slotEndTime.getHours()}:${slotEndTime.getMinutes()}`;
            dateSlots[slotKey] = { startTime: slotStartTime, endTime: slotEndTime };
            slotStartTime = slotEndTime;
          }
        });
    
        return dateSlots;
      }
    
      const handleKeyClick = (key) => {
        setSelectedTime(key);
      };


    useEffect(  ()=> {



      const fetchSessions = async()=> {
        try {
            const res = await axios.post(`/v1/mentor/getGroupSessions`);

            // console.log("sess", res?.data)
            setSessionData(res?.data?.data);
        } catch (error) {
            console.log("err", error)
        }
      }


        const fetchData = async()=> {
          // console.log("ingetchhhh", sessionData);
            try {
                const res = await axios.get(`/v1/mentor/get-projects/${user?.id}`);

                // console.log("ress", res?.data)
                setGetData(res?.data?.data);
            } catch (error) {
                console.log("err", error)
            }
        }


      
        fetchSessions();
        // fetchData();
    }, [Schedule]);

    useEffect(()=> {
      const fetchData = async()=> {
        // console.log("ingetchhhh", sessionData);
          try {
              const res = await axios.get(`/v1/mentor/get-projects/${user?.id}`);

              // console.log("ress", res?.data);
                const updatedData = res?.data?.data.map((item) => {
                    const session = sessionData.find((sessionItem) => sessionItem.project_id === item.project_id);
                    if (session) {
                        return { ...item, id: session?.id, startTime: session.startTime, endTime: session.endTime, roomId: session?.roomId, status: session?.status };
                    }
                    return item;
                });

              setGetData(updatedData);
          } catch (error) {
              console.log("err", error)
          }
      }

      fetchData();
    }, [Schedule,sessionData])

  //   useEffect(() => {
  //     const updatedData = getData.map((item) => {
  //         const session = sessionData.find((sessionItem) => sessionItem.project_id === item.project_id);
  //         if (session) {
  //             return { ...item, startTime: session.startTime, endTime: session.endTime };
  //         }
  //         return item;
  //     });
  //     setGetData(updatedData);
  // }, []);


    const HandleSchedule = async (id)=> {
    
        const time = {
          startTime: timeZones[selectedTime]?.startTime,
          endTime: timeZones[selectedTime]?.endTime,
        }

        // console.log("idsss", id);
    
        const ids = {
          project_id: id.project_id,
          mentor_id: id.mentor_id,
        }
        const res = await axios.put("/v1/mentor/sessionSchedule", {
          ids,
          status: 1,
          time: time,
        })
    
        // console.log("update", res)
        // setStatus("2");
        if(!res?.data?.error){
          toast.success(res?.data?.message)
          setSchedule({status: "1"})
        }
        else{
          toast.error(res?.data?.message)
          setSchedule({status: "1"})
        }
        
      }

      async function joinSession(e, item) {
        e.preventDefault();
        // if (new Date(item?.startTime).getTime() === new Date().getTime()) {
        if (1) {
          navigate(`/mentor/session/${item?.project_id}/${item?.roomId}`, {state: {studentsCount: item?.students.length, isGroupSession: true}});
        } else {
          toast.warning("Your Session Haven't Started Now")
        }
      // }
      }


    // console.log("getDataa", getData);
    // console.log("userr", user);
    // console.log("session", sessionData);


  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div className="session-request-table">
      <div className="flex">
        <h3>Group Sessions</h3>{" "}
        <p className=' d-none'>
          See all <i className="fa-solid fa-arrow-right-long"></i>
        </p>
      </div>
      <div className="table-responsive d-none d-sm-flex" style={{paddingLeft: "20px"}}>
       {getData?.length > 0 ? 
        <table className="table" >
          <thead>
            <tr>
              <th scope="col" style={{textAlign: "left"}}>Project Name</th>
              <th scope="col">Students</th>
              <th scope="col">Date</th>
              <th scope="col">Timing</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
         
          <tbody>
            {getData?.map((item, i) => {
                function formatTime(timeString) {
                    const date = new Date(timeString);
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    const ampm = hours >= 12 ? 'pm' : 'am';

                    hours = hours % 12;
                    hours = hours ? hours : 12; // Handle midnight (0 hours)

                    // Add leading zero to minutes if needed
                    minutes = minutes < 10 ? '0' + minutes : minutes;

                    const formattedTime = hours + ':' + minutes + ampm;
                    return formattedTime;
                }
                  
              return (
            < tr key={i}>
              {/* <Link to={`/mentor/project-detail/${item?.project_id}`}> */}
                <td onClick={()=> navigate(`/mentor/project-detail/${item?.project_id}`, {state: { students: item?.students }})} style={{cursor: "pointer", textAlign: "left"}}>{item?.project_title}</td>      
              {/* </Link>   */}
              <td className="">{item?.students.length}</td>

              {
                              Schedule?.id === item?.project_id ? (
                              // status === "1" ? (
                                <>
                                <td>
                                  <input
                                    className="date_style form-control"
                                    style={{
                                      marginTop: "5px",
                                      maxWidth: "300px"
                                    }}
                                    id="dateInput"
                                    placeholder="dd-mm-yyyy"
                                    value={selectedDate}
                                    type="date"
                                    min={minDate}
                                    onChange={handleDateChange}
                                  />
                                  </td>
                                  {
                                    !selectedDate ?
                                    <td>{""}</td> : ""

                                  }
                                  {selectedDate && (
                                    <td>
                                    <div className="time_dropdown time-row py-2 px-2 my-2 mx-auto"
                                    style={{
                                      maxWidth: "350px"
                                    }} >
                                      {!Object.keys(timeZones).length == 0 ? (
                                        <>
                                          {Object.keys(timeZones).map((key) => {
                                            return (
                                              <div key={key} className="col-3 gap-1">
                                                <span
                                                  style={{
                                                    backgroundColor:
                                                      selectedTime === key ? "#6149cd" : "",
                                                    color: selectedTime === key ? "#fff" : "",
                                                    cursor: "pointer",
                                                  }}
                                                  className="duration text-center w-100 my-6 p-1 gap-4 rounded fw-light"
                                                  onClick={() => handleKeyClick(key)}
                                                >
                                                  {key}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <p>Mentor is not available on this day</p>
                                      )}
                                    </div>
                                    </td>
                                  )}

                                  {/* <td>
                                    {""}
                                  </td> */}

                                <td>
                                  <button onClick={() => HandleSchedule({project_id: item.project_id, mentor_id: user?.id})} className="btnorg">Update</button>
                                </td>                                
                                </>
                              )
                              :
                              (<>
                                <td>{item?.startTime ? new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) :"-"}</td>
                                {/* <td>{new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}</td> */}
                                <td className="red">{item?.startTime ?formatTime(item?.startTime) :"-"}</td>
                                {/* <td className="red">{formatTime(item?.startTime)}</td> */}
                                { 
                                  item?.startTime  ? (
                                   item?.status === 1  && 
                                    <td>
                                      <button className="btngreen" onClick={(e) => { joinSession(e, item) }}>Join</button>{" "}
                                    </td>
                                  ): (

                                  <td>
                                  <button onClick={() => setSchedule({id: item?.project_id})} className="btnred">Schedule</button>
                                  </td>)
                                  
                                }
                                {
                                  item?.status === 3 && 
                                    <td>
                                      <button className="grey" >Completed</button>{" "}
                                    </td>
                                }
                              </>
                              )
                            }


              {/* <td className="">{"-"}</td>
              <td className="">{"-"}</td>
                  
              <td>
                <button className="btngreen" onClick={() => setSchedule({id: item?.project_id})} >Schedule</button>{" "}
              </td> */}
            </tr>
              )
            })}
           
          </tbody>
        </table>:<div className="w-100 text-center pb-2">No session today</div>}
      </div>
      <div className="student_mobo_table1 d-block d-sm-none">
      {/* {getData.length>0 ? <MentorMobileDataTable data={getData} type={"todaySession"} joinSession={joinSession} />:<>No session today</>} */}
      </div>
    </div>
  </div>
  )
}

export default GroupSessions