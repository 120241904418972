import axios from "axios"
import { useEffect } from "react"

const useHandleSearch = (dispatch, search, setLoading, state, currentPage, ITEMS_PER_PAGE, setData, setTotalItems, setTotalPages, setCurrentPage, debounce)=> {

    const map = {
        0: "project",
        1: "internship",
        2: "job",
      }

      useEffect(() => {
        setCurrentPage(1)
      }, [state])


    useEffect(() => {
        dispatch({
          type: "change_value",
          payload: { search: search || "" },
        })
        handleSearch()
      }, [search])


    
      const handleSearch = async () => {
        try {
          setLoading(true)
          const filters = Object?.keys(state).reduce((acc, curr) => {
            if (curr === "selected") return acc
            else if (state[curr] instanceof Set) {
              if (!state[curr].size) return acc
              const set = [...new Set(state[curr])]
              return { ...acc, [curr]: set }
            } else if (state[curr]) {
              return { ...acc, [curr]: state[curr] }
            }
            return acc
          }, {})
    
          const { data } = await axios.post(`/v1/filter/${map[state.selected]}`, {
            ...filters,
            page: currentPage,
            limit: ITEMS_PER_PAGE,
          })
          // // console.log("pages", data?.data);
          if (!data?.error) {
            setData(data?.data?.items || [])
            setTotalPages(data?.data?.pages?.total || 1)
            setTotalItems(data?.data?.pages?.items)
            // setCurrentPage(data?.data?.pages?.current)
          }
    
          setLoading(false)
        } catch (err) {}
      }


      const updateLocation = (_name) => {

        dispatch({
          type: "change_value",
          payload: { address: _name },
        })
      }
    
      const decouncedUpdateLocation = debounce(updateLocation, 500)


      return {
        decouncedUpdateLocation
      }

}

export default useHandleSearch;