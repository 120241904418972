import React, { useState } from "react";
import "./studentAccordian.scss";

const StudentAccordian = ({
  children = <></>,
  title = "",
  defaultOpen = false,
  Component = <></>,
  className = "",
  report,
  description = "",
}) => {
  const [show, setShow] = useState(() => defaultOpen);

  return (
    <div className={`student_accordian_card mt-3  accordian ${className}`}>
      <div
        className="card-header d-flex student_accordian_card_header"
        onClick={() => setShow((p) => !p)}
      >
        <h2 className="mb-0 w-50">
          <button className="btn p-0 accordian_title" type="button">
            {title}
            {description && <i className="fa fa-check mx-2"></i>}
          </button>
        </h2>
        <p className="w-50">{description ? "summitted" : "pending"}</p>
        <div className="student_accordian">
          {Component}
          <i
            className={`fa-sharp fa-solid fa-caret-down dropdown_icon ${
              show ? "rotate" : ""
            }`}
          ></i>
        </div>
      </div>

      <div className={`card-body ${show ? "show" : "collapse"}`}>
        {children}
      </div>
    </div>
  );
};

export default StudentAccordian;
