import React, { useEffect, useState } from "react";
import "./projectReportTable.scss";
import Checkbox from "components/formComponents/Checkbox";
import { set } from "react-hook-form";

const ProjectReportTable = ({
  checkbox,
  title,
  subTitle,
  text1,
  text2,
  text3,
  text4,
  text5,
  setRating1,
  setRating2,
  setRating3,
  setRating4,
  setRating5,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
}) => {
  const register = () => {};

  const [rating, setRating] = useState({});
  const [totalRating, setTotalRating] = useState(0);

  const handleRatingChange = (index, e) => {
    // console.log(e.target.id, "id");
    const selectedValue = e.target.value;
    const newRating = [...rating1];
    if (selectedValue) {
      newRating[index] = parseInt(selectedValue);
    } else {
      newRating[index] = 0;
    }

    setRating((prevPair) => {
      return {
        ...prevPair,
        [e.target.id]: e.target.value,
      };
    });

    setRating1(newRating);
  };

  useEffect(() => {
    if (rating) {
      let sum = 0;
      for (let key in rating) {
        sum += parseInt(rating[key]);
      }
      setTotalRating(sum);
    }
  }, [rating]);

  const handleRatingChange2 = (index, e) => {
    const selectedValue = e.target.value;
    const newRating = [...rating2];
    if (selectedValue) {
      newRating[index] = parseInt(selectedValue);
    } else {
      newRating[index] = "0";
    }

    setRating((prevPair) => {
      return {
        ...prevPair,
        [e.target.id]: e.target.value,
      };
    });

    setRating2(newRating);
  };

  // console.log(rating1, "rating")

  // console.log(rating, "rating1234");

  return (
    <div className="projectReportTable">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 15%)</small>
      </h4>
      <table>
        <tr>
          <th className="table_title">{subTitle}</th>
          <th>Max Rating</th>
          <th>Mentor Rating</th>
        </tr>
        <tr>
          <td className="table_title">{text1}</td>
          <td>0-1</td>
          <td>
            <>
              {rating1 && (
                <select
                  name="first"
                  id="1"
                  onChange={(e) => handleRatingChange(0, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
              {rating2 && (
                <select
                  name=""
                  id="1"
                  onChange={(e) => handleRatingChange2(0, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
            </>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text2}</td>
          <td>0-1</td>
          <td>
            <>
              {rating1 && (
                <select
                  name=""
                  id="2"
                  onChange={(e) => handleRatingChange(1, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
              {rating2 && (
                <select
                  name=""
                  id="2"
                  onChange={(e) => handleRatingChange2(1, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
            </>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text3}</td>
          <td>0-2</td>
          <td>
            <>
              {rating1 && (
                <select
                  name=""
                  id="3"
                  onChange={(e) => handleRatingChange(2, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              )}
              {rating2 && (
                <select
                  name=""
                  id="3"
                  onChange={(e) => handleRatingChange2(2, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              )}
            </>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text4}</td>
          <td>0-1</td>
          <td>
            <>
              {rating1 && (
                <select
                  name=""
                  id="4"
                  onChange={(e) => handleRatingChange(3, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
              {rating2 && (
                <select
                  name=""
                  id="4"
                  onChange={(e) => handleRatingChange2(3, e)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              )}
            </>
          </td>
        </tr>

        <tr>
          <td className="table_title">Maximum (5)</td>
          <td></td>
          <td>
            <h5>Score: {totalRating}</h5>
            <p>weighted Score: {(totalRating * 0.15).toFixed(2)}</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ProjectReportTable;
