import React from 'react'
import AboutSection from './aboutSection'
import "./aboutSection.scss"

const StartJourney = ({student,Simg,title1,title2,title3,subTitle1,subTitle2,subTitle3}) => {
  return (
      <div className="start_journey d-flex flex-column align-items-center" style={{paddingBottom:student==="student" && '0px'}}>
          <h2>How to  <span className="down-mark-line"> Start</span> Your Journey</h2>
          <div className='menu_item '>
              {/* <div className="menu d-flex align-items-center justify-content-center ">
                  <p>For Student </p>
                  <p>For Companies </p>
                  <p>For Colleges/Universities </p>

              </div> */}
        <AboutSection Simg={Simg}
          title1={title1}
          title2={title2}
          title3={title3}
          subTitle1={subTitle1}
          subTitle2={subTitle2}
          subTitle3={subTitle3}
        />
          </div>
    </div>
  )
}

export default StartJourney