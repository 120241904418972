import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { subscribeSubscription } from "services/factories/razorpay"
import PaymentHistory from "./PaymentHistory"
import star from "./Star.svg"
import styles from "./subscriptions.module.scss"


const Subscription = ({ Beginner, Intermediate, Expert }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();

  const user = useSelector((state)=> state?.auth?.user);

// console.log("educator", user);

  async function initiatePayment(e, id, amount) {
    e.preventDefault()


    const session = await subscribeSubscription({
      subscription_id: id,
      amount_rs: parseInt(amount),
      navigate:navigate,
      user: user
    })
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={`sub_dev ${styles.subscriptionPage} container mt-5 mb-3`}>
      {/*--------------------- Row  1  -------------------*/}

      <div className={`${styles.mainRow} ${styles.row} row`}>
        {/* heading */}
        <div className={` ${styles.subscription} col-md-5 col-12`}>
          <h2 className={styles.title}>Upgrade Your Plan</h2>
        </div>

        {/* Beginner */}
        <div className={`${styles.subscription_type} col-5 col-md-4 `}>
          <div>
            <h3>{Beginner?.subscription_name}</h3>
            <h4>Free</h4>
          </div>
          <button className={styles.subscriptions_btn} onClick={(e) => {
            initiatePayment(e, Beginner.id, Beginner.price)
          }} 
          style={{ backgroundColor: user?.subscriptionStatus === null && '#07bc0c', color: user?.subscriptionStatus === null && '#fff' }}
          
          >
            {user?.subscriptionStatus === null ? "Active" : "Upgrade"}
          </button>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.subscription_type} col-4 col-md-3 `}>
          <div>
            <div className="d-flex justify-content-center gap-1">
              <h3>{Intermediate?.subscription_name}</h3>
              <div className={styles.star_off_container}>

              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(7000)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Intermediate?.price)}
                <span>/month</span> <span>+ (GST@18%)</span>
              </h4>
            </div>
          </div>
          <button className={styles.subscriptions_btn} onClick={(e) => {
            initiatePayment(e, Intermediate.id, Intermediate.price)
          }}
          disabled={ user?.subscriptionStatus?.subscription_id === 5}
          style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 5 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 5 && '#fff' }}
          >
            {user?.subscriptionStatus?.subscription_id === 5 ? "Active" : "Upgrade"}
          </button>
        </div> */}
        {/* Expert */}
        <div className={`${styles.subscription_type} col-5 col-md-4 `}>
          <div>
            <div className="d-flex justify-content-between gap-4">
              <h3>{Expert?.subscription_name}</h3>
              <div className={styles.star_off_container}>
              <img src={star} alt="Image" className={styles.star_off_img1} />
              <span className={styles.off_span}>
                  36% <br /> OFF
                </span>

              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(97656)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Expert?.price)}
                <span>/year</span> <span>+ (GST@18%)</span>
              </h4>
            </div>
          </div>
          <button className={styles.subscriptions_btn} onClick={(e) => {
            initiatePayment(e, Expert.id, Expert.price)
          }}
          disabled={ user?.subscriptionStatus?.subscription_id === 6}
          style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 6 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 6 && '#fff' }}
          >
            {user?.subscriptionStatus?.subscription_id === 6 ? "Active" : "Upgrade"}
          </button>
        </div>
      </div>

      {/*--------------------- Row  2  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Invite companies directly
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>10 Invitations per month</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>50 Invitations per month</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  3  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Partner with companies
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Maximum 10 companies</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Maximum 50 companies</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  4  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Upload your student lists and send project work,Internship and Job opportunities to your students
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Maximum 100 student</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Unlimited</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  5  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Track student progress during project work period
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      {/*--------------------- Row 7  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Get preferentials notifications related to newly posted project,internships and job by companies
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>
      {/*--------------------- Row 8  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Promoting your university/college among companies
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>
    
    <div className="container">
      <PaymentHistory />
    </div>

    </div>
  )
}

export default Subscription
