import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CollegesCards from "view/dashboard/employer/components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "view/dashboard/employer/components/collegesCards/collegesDetailCards/CollegesDetailCards";
import ListView from "view/dashboard/employer/components/list/ListView";
import SectionSideFilter from "../../id/sections/SectionSideFilter";
import "./suggestedCard.scss";
const Details = ({
  state,
  enrollments = [],
  post = {},
  handleStatusChange,
  filteredSuggesteData,
  setRefresh,
  reduxDispatch,
}) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const [educators, setEducators] = useState(0);
  const [inviteId, setInviteId] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // // console.log({ enrollments, post });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // // console.log(filteredSuggesteData, "filteredSuggesteData");

  // const weekly_submissions = (
  //   getParsed(enrollments?.data[selectedStudent]?.weekly_submissions) || []
  // )?.filter((item) => item?.report)

  // const final_submission_report = getParsed(
  //   enrollments?.data[selectedStudent]?.final_submission_report
  // )

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get("/v1/institution");
  //     // console.log(res, "res");
  //     setInstitutions(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  const [educatorDataDetails, setEducatorDataDetails] = useState();
  useEffect(() => {
    if (filteredSuggesteData?.length) {
      const fetctEductatorData = async () => {
        const res = await axios.get(
          `/v1/institution/single/${filteredSuggesteData[educators]?.id}`
        );
        // // console.log(res, "educatordata");
        setEducatorDataDetails(res?.data?.data);
      };
      fetctEductatorData();
    }
    // // console.log("function 2 working")
  }, [educators, institutions, filteredSuggesteData]);
  // // console.log(institutions, educators, educatorDataDetails, "institutions");

  useEffect(() => {
    const filterData = (searchTerm) => {
      const filteredResults = filteredSuggesteData?.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.institute &&
            item.institute.institution_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.institute_branch &&
            item.institute_branch.address
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.designation &&
            item.designation.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredData(filteredResults);
    };
    filterData(searchTerm);
  }, [institutions, searchTerm, filteredSuggesteData]);

  // // console.log(educatorDataDetails, "educatorDataDetails");

  const cancelInvite = async (id = 0) => {
    try {
      if (id) {
        const res = await axios.post("/v1/invitations/send_invite", {
          receiver_id: [id],
          status: 2,
        });
        setRefresh((prev) => !prev);
        // // console.log(res);
        toast.success("invitation Cancel");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {filteredSuggesteData?.length ? (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                {filteredSuggesteData?.length ? (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="searchbox">
                        <label>
                          {" "}
                          <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {filteredData?.map((institution, index) => {
                          // // console.log(institution, "institution");
                          const collegesFunction = () => {
                            setEducators(index);
                            setShow(true);
                          };
                          return (
                            <div onClick={collegesFunction}>
                              <CollegesCards institution={institution} />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                      <CollegesDetailCards
                        educatorDataDetails={educatorDataDetails}
                        action2={1}
                        text1="Not Interested"
                        text2="Send Invitation"
                        pageLength={filteredSuggesteData?.length}
                        setPage={setEducators}
                        currentPage={educators}
                        sendInvite={true}
                        setRefresh={setRefresh}
                        cancelInvite={cancelInvite}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
                      <SectionSideFilter
                        employer={false}
                        dispatch={reduxDispatch}
                        cancelInvite={cancelInvite}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="new-post">
                      <div className="flex">
                        <div>
                          <h3>No Available Institution Found</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {show && (
                  <div className="position-fixed col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobo_main_college_datail d-flex d-md-none ">
                    <i
                      class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                      aria-hidden="true"
                      onClick={() => setShow(false)}
                    >
                      {" "}
                      Back
                    </i>
                    <CollegesDetailCards
                      educatorDataDetails={educatorDataDetails}
                      action2={1}
                      text1="Not Interested"
                      text2="Send Invitation"
                      pageLength={institutions?.length}
                      setPage={setEducators}
                      currentPage={educators}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                {show && (
                  <div className="position-fixed col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobo_main_college_datail d-flex d-md-none ">
                    <i
                      class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                      aria-hidden="true"
                      onClick={() => setShow(false)}
                    >
                      {" "}
                      Back
                    </i>
                    <CollegesDetailCards
                      educatorDataDetails={educatorDataDetails}
                      action2={1}
                      text1="Not Interested"
                      text2="Send Invitation"
                      pageLength={institutions?.length}
                      setPage={setEducators}
                      currentPage={educators}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SuggestedCollege = ({
  filteredSuggesteData,
  state,
  setRefresh,
  refresh,
  reduxDispatch,
  ...props
}) =>
  state ? (
    <Details
      {...props}
      setRefresh={setRefresh}
      filteredSuggesteData={filteredSuggesteData}
      reduxDispatch={reduxDispatch}
      refresh={refresh}
    />
  ) : (
    <List
      {...props}
      setRefresh={setRefresh}
      filteredSuggesteData={filteredSuggesteData}
      reduxDispatch={reduxDispatch}
      refresh={refresh}
    />
  );
const style1 = {
  display: "none",
};
const style2 = {
  background: "#47D065",
  borderRadius: "8px",
};
const style_i = {
  color: "white",
};
const List = ({
  state,
  enrollments = [],
  post = {},
  handleStatusChange,
  filteredSuggesteData,
  setRefresh,
  reduxDispatch,
}) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get("/v1/institution");
  //     setInstitutions(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  return (
    <>
      {filteredSuggesteData?.length ? (
        <div className="d-flex gap-4">
          <ListView
            text2="Send Invitation"
            style1={style1}
            style2={style2}
            style_i={style_i}
            data={filteredSuggesteData}
            setRefresh={setRefresh}
          />
          <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-xl-flex">
            <SectionSideFilter employer={false} dispatch={reduxDispatch} />
          </div>
        </div>
      ) : (
        <>
          <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="new-post">
                <div className="flex">
                  <div>
                    <h3>No Data Found</h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            ></div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
          <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
            <SectionSideFilter dispatch={reduxDispatch} employer={false} />
          </div>
        </>
      )}
    </>
  );
};

export default SuggestedCollege;
