import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import chatImg from './chat.png'
import ExploreFilterBox from "./exploreFilterBox/ExploreFilterBox"
import "./studentChat.scss"

const EducatorChat = () => {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState("")
  const [show, setShow] = useState(false)
  const [filterShow, setFilterShow] = useState(false)
  const [chatUserData, setChatUserData] = useState([])

  const [activeChatRoom, setActiveChatRoom] = useState("")
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);
  const isLoading = useRef(false);
  const scrollDirection = useRef('none');
  const [loadingMessages, setLoadingMessages] = useState(false);

  const userinfo = useSelector((state)=> state?.auth?.user);


  const clickHandler = () => {
    setFilterShow((prev) => !prev)
  }

  useEffect(() => {
    const chatApi = async () => {
      try {
        const chatRoomId = await axios.get("/v1/chat/fetch-chat-rooms")
        const { data, error, message } = chatRoomId;
        setChatUserData(data?.data)
      } catch (error) {
        throw new Error()
      }
    }
    chatApi()
  }, [])

  useEffect(() => {
    const fetchConversation = async (pageNum) => {
      // console.log("API Calling");
      setLoadingMessages(true);
      try {
        const conversation = await axios.get(`/v1/chat/fetch-conversation/${activeChatRoom?.roomId}?page=${pageNum}&limit=20`);
        const { data, error, message } = conversation;
        // console.log(data?.data);
        setMessages((prevMessages) => {
          if (pageNum === 1) {
            return data?.data;
          } else {
            return [ ...data?.data];
          }
        });
      } catch (error) {
        throw new Error();
      } finally {
        setLoadingMessages(false);
      }
    };

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      if (scrollTop === 0 && !loadingMessages) {
        setPage((prevPage) => prevPage + 1);
      } else if (scrollHeight - (scrollTop + clientHeight) <= 1 && !loadingMessages) {
        setPage((prevPage) => (prevPage - 1 >= 1 ? prevPage - 1 : 1));
      }
    };

    if (activeChatRoom) {
      fetchConversation(page);
      containerRef.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [activeChatRoom, page,newMessage]);



const handleChange = (e, userId, role) => {  
  e.preventDefault()
  const messageApi = async () => {
      // console.log("Api Calling")
      try {
        const newConversation = await axios.post(`/v1/chat/send-conversation/${activeChatRoom?.roomId}`,{message:newMessage} )
        // console.log(newMessage);
        const { data, error, message } = newConversation;
        setMessages([data?.data,...messages ])
        setNewMessage("");
      } catch (error) {
        throw new Error()
      }
  }

  const sendNotification = async () => {
    // console.log("notificatinonn");
    // console.log("userId, role", userId, role);

    const notificationData = {
      userId,
      role,
      notification_title: `New message from college/university`,
      notification_description: `${userinfo?.first_name} ${userinfo?.last_name} send a new message`,
      notification_type: "chat",
      notification_payload: {
        role: 2,
        message: newMessage,
        roomId: activeChatRoom?.roomId
      },
    }

    const res = axios.post("/v1/notification/", {
      notificationData
    });

    // console.log("resss", res?.data);
  }

    messageApi();
    sendNotification();
  }

  // // console.log(chatUserData, "chatUserData")

  const userData = () => { }
  
  const UserRole = {
    2: 'employer_account',
    3: 'educator_account',
    4: 'student_account',
  }

  // const enrollmentTypes1={
  //   0:'project',
  //   1: "job",
  //   2:'internship',
  // }
  const enrollmentTypes={
    0:'project',
    1: "job",
    2:'internship',
  }

  const statusMap = {
    0: "Enrolled",
    2: "Project Submitted",
    4: "Shortlisted",
    6: "Hired",
    7: "Rejected",
  }

  // console.log("messages", messages);

  // console.log("chatuserdata", chatUserData)

  return (
  <>
    { chatUserData?.length> 0 ?
      <section className="StudentChat_main">
      <div className=" chat_profiles">
        <div className="search_bar d-flex align-items-center">
          <div className="left_search flex ">
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" placeholder="Search..." className="w-100 px-2" />
          </div>
          <i
            class="fa fa-sliders slider_icon"
            aria-hidden="true"
            onClick={clickHandler}
          ></i>
        </div>
        <div className="user_info bg-white w-100 " >
          {chatUserData?.map((item, index) => {
            const handleClick = () => {
              setShow(true)
              setActiveChatRoom(item)
            }
            return (
              <div
                className="flex align-items-start p-4"
                onClick={handleClick}
                style={{cursor: "pointer"}}
              >
                <div className="d-flex align-items-center">
                  <img width={70} src={item?.userOne[UserRole[item?.userOne?.role]].profile_pic || item?.userOne[UserRole[item?.userOne?.role]].company_logo} alt="UserImage" />
                  <div className="user_info_detail px-2">
                    <h4>{item?.userOne[UserRole[item?.userOne?.role]].first_name} {item?.userOne[UserRole[item?.userOne?.role]].last_name}</h4>

                    <span>{item?.[`${enrollmentTypes[item?.enrollment_type]}Enrollment`]?.[enrollmentTypes?.[item?.enrollment_type]]?.[`${enrollmentTypes?.[item?.enrollment_type]}_title`]}({enrollmentTypes?.[item?.enrollment_type] || "Employer"}) </span>

                    {/* <p>Hello! How are you?</p> */}
                    {
                      item?.enrollment_type && 
                    <h3>{statusMap[item[`${enrollmentTypes[item?.enrollment_type]}Enrollment`]?.status]}</h3>
                    }
                  </div>
                </div>
                <span className="time">{new Date().toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true })}</span>  
              </div>
            )
          })}
          {/* <div className="flex align-items-start p-4">
            <div className="d-flex align-items-center ">
              <img width={70} src={user} alt="Image" />
              <div className="user_info_detail px-2">
                <h4>Sarvesh Pal</h4>
                <span>Project Design</span>
                <p>Hello! How are you?</p>
                <h3>Shortlisted</h3>
              </div>
            </div>
            <span className="time">3:15PM</span>
          </div> */}
        </div>
      </div>

      {!activeChatRoom=="" && show ?
        <div className="chat_container p-0 d-none d-md-block">
        <div className="top_bar flex p-3">
          <div className="d-flex align-items-center gap-2">
          <i
                class="fa fa-arrow-left text-white"
                aria-hidden="true"
                onClick={() => setShow(false)}
                style={{cursor: "pointer"}}
              ></i>
            <img width={50} src={activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].profile_photo || activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].company_logo} alt="userImage" />
            <h4>{activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].first_name} {activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].last_name}</h4>
          </div>
          <i
            class="fa fa-ellipsis-v text-white d-none d-md block"
            aria-hidden="true"
          ></i>
        </div>
        <div className="chat_areaEmployer d-flex justify-content-between align-items-end">
          <div ref={containerRef} className="chat_area_inner py-2 d-flex justify-content-between align-items-end  flex-column gap-3" style={{ maxHeight: '71vh', overflowY: 'auto' }}>
          {messages.slice(0).reverse().map((message, index) => {
              const leftMsg=message?.sender?.role===3
                return (
                <div style={{width:!leftMsg && '100%'}}>
                   <h4 className="messeges_text" key={index} style={{textAlign:!leftMsg && 'start'}} >
                {message?.message}
                <p className="" style={{textAlign: !leftMsg && "start"}}>{new Date(message?.createdAt).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true })}</p>
              </h4>
                </div>
             
              
            )})}
              {loadingMessages && <div>Loading messages...</div>}
          </div>
        </div>
        <form className="bottom_input flex gap-4" onSubmit={ (e)=> handleChange(e, activeChatRoom?.userOne[ UserRole[activeChatRoom?.userOne?.role] ]?.id, activeChatRoom?.userOne?.role) }>
          <textarea
            type="text"
            className="w-100 py-2 px-3 "
            placeholder="Write a message.."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button type="submit" onClick={ (e)=> handleChange(e, activeChatRoom?.userOne[ UserRole[activeChatRoom?.userOne?.role] ]?.id, activeChatRoom?.userOne?.role) }>
            {" "}
            <i class="fa fa-paper-plane-o send_btn" aria-hidden="true"></i>
          </button>
          {/* <i class="fa fa-paperclip  paperclip_icon" aria-hidden="true"></i> */}
        </form>
        </div>
      :<div className="d-flex align-items-center justify-content-center" style={{width:'auto' ,height:'71vh'}}>
      <div className="d-flex align-items-center flex-column justify-content-center">
        <img src={chatImg} alt="Image" />
        <h3>Click on chat head to show</h3>
         </div>
        </div>
      }
      {show && (
        <div className="chat_container_mobo p-0 d-block d-md-none">
          <div className="top_bar flex p-3">
            <div className="d-flex align-items-center gap-2">
              <i
                class="fa fa-arrow-left text-white"
                aria-hidden="true"
                onClick={() => setShow(false)}
              ></i>
             <img width={50} src={activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].profile_photo} alt="userImage" />
            <h4>{activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].first_name} {activeChatRoom?.userOne[UserRole[activeChatRoom?.userOne?.role]].last_name}</h4>
          </div>
            <i
              class="fa fa-ellipsis-v text-white d-none d-md block"
              aria-hidden="true"
            ></i>
          </div>
          <div className="chat_area d-flex p-4     justify-content-between align-items-end">
            <div ref={containerRef} className="chat_area_inner d-flex justify-content-between align-items-end  flex-column gap-3" style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            {messages.slice(0).reverse().map((message, index) => {
              const leftMsg=message?.sender?.role===3
                return (
                <div style={{width:!leftMsg && '100%'}}>
                   <h4 className="messeges_text" key={index} style={{textAlign: !leftMsg && 'start'}} >
                {message?.message}
                <p className="">{new Date(message?.createdAt).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true })}</p>
              </h4>
                </div>
             
              
            )})}
            </div>
          </div>
          <form
            className="bottom_input flex gap-4"
            onSubmit={ (e)=> handleChange(e, activeChatRoom?.userOne[ UserRole[activeChatRoom?.userOne?.role] ]?.id, activeChatRoom?.userOne?.role) }
          >
            <input
              type="search"
              className="w-100 py-2 px-3 "
              placeholder="Write a message.."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button type="submit" style={{ background: "transparent" }} onClick={ (e)=> handleChange(e, activeChatRoom?.userOne[ UserRole[activeChatRoom?.userOne?.role] ]?.id, activeChatRoom?.userOne?.role) }>
              {" "}
              <i class="fa fa-paper-plane-o send_btn" aria-hidden="true"></i>
            </button>
            {/* <i class="fa fa-paperclip  paperclip_icon" aria-hidden="true"></i> */}
          </form>
        </div>
      )}

      {filterShow && (
        <div className="filter_main d-none d-md-block">
          <div
            className={`mobile_FilterBtn my-2 p-2 d-flex justify-content-between align-items-center gap-2 w-100  ${
              show ? "open" : ""
            } `}
            //   style={{paddingTop:'2rem'}}
          >
            <div className="d-flex justify-content-center align-items-center gap-2 ">
              <h4 className="m-0" style={{ color: "#6149CD" }}>
                Filters
              </h4>
              {/* <span className=" d-block mt-">
                <i
                  class="fa-solid fa-sliders fa-lg "
                  style={{ color: "#6149CD" }}
                ></i>
              </span> */}
            </div>
            <div className="toggle_box">
              <span className="d-block filter">
                <i
                  class="fa-solid fa-x fa-lg "
                  style={{ color: "#6149CD", cursor: "pointer" }}
                  onClick={clickHandler}
                ></i>
              </span>
            </div>
          </div>
          <ExploreFilterBox />
        </div>
      )}

      {filterShow && (
        <div className="flex_mobo d-block d-md-none">
          <div
            className={`mobile_FilterBtn my-2 p-2 d-flex justify-content-between align-items-center gap-2 w-100 d-md-none ${
              show ? "open" : ""
            } `}
            //   style={{paddingTop:'2rem'}}
          >
            <div className="d-flex justify-content-center align-items-center gap-2 ">
              <h4 className="m-0" style={{ color: "#6149CD" }}>
                Filters
              </h4>
              <span className=" d-block mt-">
                <i
                  class="fa-solid fa-sliders fa-lg "
                  style={{ color: "#6149CD" }}
                ></i>
              </span>
            </div>
            <div className="toggle_box">
              <span className="d-block filter">
                <i
                  class="fa-solid fa-x fa-lg "
                  style={{ color: "#6149CD" }}
                  onClick={() => setFilterShow(false)}
                ></i>
              </span>
            </div>
          </div>
          <ExploreFilterBox />
        </div>
      )}
      </section> :
      <div className="d-flex align-items-center justify-content-center" style={{ width: 'auto', height: '71vh' }}>
    <div className="d-flex align-items-center flex-column justify-content-center">
      <img src={chatImg} alt="Image" />
      <h3 className="fw-bold">No chats to show</h3>
       </div>
        </div>}
  </>
  )
}

export default EducatorChat
