import html2pdf from "html2pdf.js";
import moment from "moment";
// import { toPng, toJpeg } from 'image-conversion';
// import webpConverter from 'webp-converter';
function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

// Function to convert webp to base64
// async function convertWebpToBase64(url) {
//   const response = await fetch(url);
//   const blob = await response.blob();

//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.readAsDataURL(blob);
//   });
// }


async function convertWebpToBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

// "https://qollab-company-logo.s3.ap-south-1.amazonaws.com/1698904983016.webp"
export const generatePDF = async ({
  id,
  company_name,
  project_title,
  completion_date,
  rating,
  student_name,
  qollabbLogo,
  companyAddress,
  certificateSign,
  secondCompanyLogo,
}) => {

  // async function convertSecondCompanyLogo() {
  //   const base64Image = await convertWebpToBase64(secondCompanyLogo);
  //   return base64Image.replace('data:image/png;base64,', ''); // Remove the data URL prefix for HTML rendering
  // }

  function generateCertificateNo(){
    const pad = (number, length) => {
      return String(number).padStart(length, '0');
    };
    const formattedDate = new Date();
    const year = formattedDate.getFullYear();
    const month = formattedDate.getMonth() + 1; // Months are zero-indexed
    // const day = formattedDate.getDate();

    const certificateNo = pad(id, 5) + pad(month, 2) + pad(year, 4);
    
    return certificateNo;
  }

  // console.log("certificate no.", generateCertificateNo());



  const convertWebpToPng = async (url) => {
   
    try {
      const timestamp = new Date().getTime();
      const cacheClearedUrl = `${url}?_=${timestamp}`;
      
      const response = await fetch(cacheClearedUrl);
      const blob = await response.blob();
  
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const newImageUrl = URL.createObjectURL(blob);
            resolve(newImageUrl);
          }, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
      });
    } catch (error) {
      console.error('Error converting image:', error);
      throw error; // Re-throw the error for handling at higher level
    }
  };

  // Convert WebP to PNG
  const pngImage = await convertWebpToPng(secondCompanyLogo);
  console.log("PNG Image:", pngImage);


  // console.log("img",{secondCompanyLogo,completion_date, qollabbLogo, logoData})
  
  const content = `
    <div class="certi-wrapper" >
      <style>

      .certi-wrapper{
        width: 100%;
        height: 1050px;
        font-family: "Poppins", sans-serif;
        color: #000000;
        border: 10px solid #FFA826;
        padding: 30px;
        font-size: 16px;
        position: relative;
      }
      p{
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
      }
         
       h1{
            color: #000000;
            font-size: 25px;
            margin-top: 10px;
            font-weight: 700;
         
       }

       .proj-para{
        margin: 10px 0;
        margin-bottom: 25px;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
       }
       .list-p-tag{
        margin: 5px 0;
        color: #000000;
        font-size: 16px;
        font-weight: 700;
       }

       li{
        margin: 5px 0;
        font-size: 12px;
        font-weight: 600;
       }
       .flex-company-info{
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 20px;
        height: 100px;
        align-items: center;
       }

       ul li{
        line-height: 1
        font-size: 12px;
       }

       .company-logo{
        max-width: 100px;
        max-height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
       }
       .company-detail{
        position: relative;
       }
       .company-detail p{
        margin: 0;
        margin-left: 40px;
        font-size: 20px;
        font-weight: 700;
       }
      
       .border{
        position: absolute;
        top: 0;
        left: 16px;
        width: 3px;
        height: 100%;
        background-color: #000000;
       }
        .company-detail p:last-child{
            color: grey;
            font-weight: 500;
        }
            
      
        img{
          max-width:150px;
        }
        .img-cont{
          display:flex;
          justify-content:space-between;
          align-items:center;
        }
        .signature{
            margin-top: 10px;
        }
        .signature p{
            line-height: 0.2;
        }

        .sign-img{
            max-width: 85px;
            margin-top: 10px;
        }
        .heading-3{
            font-size: 10px;
        }
        .footer{
           
            position: absolute;
            bottom: 0;
        }
        .footer p{
        
            font-size: 9px;
            font-style: italic;
        }

      </style>
      <div class="img-cont">
      <img src=${qollabbLogo} alt="qollabb_logo" />
      </div>
      <h1>CERTIFICATE OF COMPLETION</h1>
      <p class="proj-para">This is to certify that <strong>${student_name}</strong> has successfully completed the following project:</p>

      <p class="list-p-tag">Project Details:</p>
      <ul>
        <li><strong>Project Title:</strong> ${project_title}</li>
        <li><strong>Company/Organization:</strong> ${company_name}</li>
        <li><strong>Project Completion Date:</strong> ${
          completion_date
        }</li>
        <li><strong>Rating:</strong> ${rating}</li>
      </ul>
      <p class="heading-3">This project work opportunity was offered on Qollabb platform by:<p>
      <div class="flex-company-info">
        <img class="company-logo"  src="${pngImage}" alt="company_logo" />
        <div class="company-detail">
           <p>${company_name}</p>
           <div class="border"></div>
           <p>${companyAddress}</p>
        </div>
      </div>
      <p>
      This certificate acknowledges the project intern's dedication and contribution to the project, showcasing their practical skills and industry knowledge. The ${rating} performance rating signifies the performance and cabaibilities demonstrated by the project intern throughout the project work period.
      </p>
      <p>Congratulations on a job well done!</p>
       <img class="sign-img" src=${certificateSign} />
       <div class="signature">
       <p>Pooja Banerjee</p>
       <p>Head - HR & Operations</p>
       <p>Date of issue: ${moment().format("Do MMMM YYYY")}</p>
       <p>Certificate No. ${generateCertificateNo()}</p>
       </div>

       <div class="footer">
         <p>
                
Disclaimer: Qollabb is an online platform for companies to post project work opportunities, internships and jobs and discover best performing students
through project work performance. By earning this certificate, the recipient acknowledges that the certificate represents the successful completion of
project work mentioned above and does not confer any employment entitlement, official certification or endorsement by the company offering the
project work to the recipient of this certificate.
         <p>
       </div>
        
    </div>
  `;

  const opt = {
    margin: 8,
    filename: "certificate.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };
  html2pdf().set(opt).from(content).save();
};
