import axios from "axios";
import TextInput from "components/formComponents/TextInput";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import houseImg from "../../../../../images/house.png";
import NumberInputField from "../../components/NumverInputField/NumberInputField";
import MonthlyStatement from "./MonthlyStatement";

const BillingAndPayments = () => {
  const user = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(true);
  const [billing, setBilling] = useState(true);
  const [showcard, setShowcard] = useState(false);
  const [billingDatas, setBillingDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  // // console.log(billingDatas.length, "billingDatas")
  const [bankDetails, setBankDetails] = useState({
    ...user?.billing[0],
  });
  // // console.log(bankDetails, "bankDetails");
  const [edit, setEdit] = useState(false);
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      full_name: bankDetails?.full_name,
      bank_name: bankDetails?.bank_name,
      bank_account_number: bankDetails?.bank_account_number,
      branch_name: bankDetails?.branch_name,
      ifsc_code: bankDetails?.ifsc_code,
      pan_number: bankDetails?.pan_number,
      gst_in: bankDetails?.gst_in,
    },
  });

  // // console.log(bankDetails, "bankDetails");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {};

      const { data: BillingData } = await axios.post(
        "/v1/mentor/update-billing-details",
        data
      );
      if (data) {
        setEdit(false);
        toast.success("Profile updated successfully");
        setBankDetails({});
      }
    } catch (err) {
      toast.error("Profile update failed");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const bankOptions = [
    { value: "HDFC", label: "HDFC" },
    { value: "ICICI", label: "ICICI" },
    { value: "Axis Bank", label: "Axis Bank" },
    // add more options as needed
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get("/v1/auth/session/getSessionUser");

      const billingData = response?.data?.data?.billing;
      setBillingDatas(billingData);
    } catch (error) {
      console.error(error);
    }
  };

  // fetchData();
  useEffect(() => {
    fetchData();
  }, [showcard, loading]);

  const [autoChecked, setAutoChecked] = useState(true);

  return (
    <div className="billing_payment_container">
      <div className="container-lg container-fluid">
        <div className="billing_buttons">
          <p
            className={`${billing ? "active" : ""} cur-pointer`}
            onClick={() => setBilling(true)}
          >
            Billing Method
          </p>
          <p
            className={`${!billing ? "active" : ""} cur-pointer`}
            onClick={() => setBilling(false)}
          >
            Monthly Statement
          </p>
        </div>
        {billing && (
          <div>
            <div
              className=""
              id="pills-bills"
              role="tabpanel"
              aria-labelledby="pills-bills-tab"
            >
              {!billingDatas && (
                <>
                  {" "}
                  {show && (
                    <div className="billing-payment">
                      <div className="billing_payment_div">
                        <h3>You have not set-up any billing method yet.</h3>
                        <p>
                          You will be transferred the payable amount only when
                          you add the payment details. Don’t worry, your bank
                          details are safe with us and will not be shared with
                          anyone.
                        </p>
                        <button onClick={() => setShow(false)}>Add Now</button>
                      </div>
                    </div>
                  )}
                </>
              )}

              {((show && billingDatas.length === 0) || edit) && (
                <div className="add-billing-method ">
                  <h3>Add a billing method</h3>
                  <div className="bill-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TextInput
                        label="Full Name as per Bank Account"
                        id="full_name"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />
                      <label htmlFor="bankname">Bank Name</label>
                      <select
                        {...register("bank_name")}
                        className="profileInputField"
                      >
                        {bankOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>

                      <NumberInputField
                        label="Bank Account Number"
                        id="bank_account_number"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />

                      {/* <NumberInputField
                        label="Confirm Bank Account Number"
                        id="Confirm_Bank_Account_Number"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                        placeholder=""
                      /> */}
                      <TextInput
                        label="Branch Name"
                        id="branch_name"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />
                      <TextInput
                        label="IFSC Code"
                        id="ifsc_code"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />
                      <TextInput
                        label="PAN Number"
                        id="pan_number"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />
                      <TextInput
                        label="GSTN"
                        id="gst_in"
                        labelClassName="fieldLabel"
                        register={register}
                        className="profileInputField"
                      />

                      <label>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={autoChecked}
                          onChange={(e) => setAutoChecked(e.target.checked)}
                        />
                        I agree to
                        <Link to="">Qollabb Payment Policies</Link>
                      </label>

                      <div className="btn-right">
                        <button
                          className="cancel"
                          type="button"
                          onClick={() => {
                            setEdit(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="save"
                          type="submit"
                          onClick={() => setShowcard(true)}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {show && billingDatas.length > 0 && !edit && (
                <div className="bill-detail">
                  <h3>Saved Billing Details</h3>
                  {billingDatas.map((bills) => {
                    return (
                      <div className="account-bill-details">
                        <div className="bx1">
                          <h4>{bills?.branch_name}</h4>
                          <p className="light">{bills?.full_name}</p>
                          <p className="pass-acno">
                            {"x".repeat(
                              bills?.bank_account_number?.length - 4
                            ) + bills?.bank_account_number.slice(-4)}
                          </p>
                          <button>Active</button>
                        </div>
                        <div className="bx2">
                          <img src={houseImg} alt="houseimg" /> <br />
                          <i
                            onClick={() => {
                              setEdit(true);
                            }}
                            className="fa-regular fa-pen-to-square"
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!billing && <MonthlyStatement />}
      {loading && (
        <div className="loading-container">
          <div className="text-center loading-wrapper">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingAndPayments;
