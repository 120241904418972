import React from "react"
import "./mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link } from "react-router-dom"
import moment from "moment"

const MobileViewEvaluationsRecievedTable = ({ columns, data,type}) => {
  // console.log(columns, data,"Project Evaluations")
 
  return (
    <>
      {data?.map((item) => {

        // const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4">
            <div className="data_container">
              <div className="table_data">
                <p>Date of Submission </p>
                <span className="Total_enrolled">{item?.date_of_submission}</span>
              </div>
              
              <div className="table_data">
                <p>Student Name</p>
                <span className="Total_enrolled">{item?.student_name}</span>
              </div>
              <div className="table_data">
                <p>Project Name</p>
                <span className="Total_enrolled">{item?.project_name}</span>

               

              </div>

              <div className="table_data">
                <p>Date of Request</p>
                <span className="Total_enrolled">{item?.date_of_request}</span>
              </div>

              <div className="table_data">
              <p>Subscription Plan</p>
                <span className="Total_enrolled">{item?.subscription_plan}</span>
              </div>

              <div className="table_data">
                <p>Mentor Name</p>
                <span className="Total_enrolled">{item?.mentor_name}</span>
              </div>
        

              <div className="Status_data">
              <p>Action</p>
              <Link
            to={
              type === "mentor-manager"
                      ? `/mentor-manager/mentors/${item?.id}`
                      :  item?.actions === "Assign" && `mentors/${item?.id}`
            }
            className={`deactivate-btn btn btn-primary text-white py-1 ${
              item?.actions === "Assigned" ? "disabled" : ""
            }`}
            style={{
              fontWeight: "500",
              fontSize: "13px",
            }}
          >
            {item.actions}
              </Link>
              
           
              
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewEvaluationsRecievedTable
