

import { useState } from "react";
import AddExperience from "./AddExperience";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUser } from "services/actions/auth";
import "./documents.scss";
import AddEducationDetails from "./AddEducationDetails";

const EducationDetails = ({ education, setEducation,}) => {



  const [showAddEducation, setShowAddEducation] = useState(
    () => !education.length
  );

  const [editedValues, setEditedValues] = useState({})
  const [index, setIndex] = useState(null);



  const dispatch = useDispatch();

  const deleteEducation = async (index) => {
    try {
      const id = education[index].id;
      let deleted = false;
      if (id) {
        const { data } = await axios.delete(`/v1/student/education/${id}`);
        if (!data?.error) deleted = true;
      } else deleted = true;
      if (deleted) {
        const newEducation = education.filter((_, i) => i !== index);
        setEducation(newEducation);
        dispatch(updateUser({ education: newEducation }));
      } else {
        toast.error("Error removing education!");
      }
    } catch (error) {
      toast.error("Error removing education!");
    }
  };
  

  const editEducation = async (item, index)=> {

    // console.log("itemmmmmm", item, index);
    setEditedValues(item)
    setShowAddEducation(true)
    setIndex(index);
    
  }
  // console.log("editedvlaues", editedValues)
  // console.log(education,"experiencessss");

 
 

  
  return (
    <>
      <div className="row">
      {education?.map((edu, index) => {
      return (
        <div className="col-md-6 col-sm-12 mt-2 mb-2" key={index}>
          <div className="doc_card card">
            <div className="card-body docCard_body">
              <div className="w-100">
                <h5 className="card-title">{edu?.education_type}</h5>

                <h6 className="card-title">{edu?.board_university}</h6>

                <p className="mb-2 card-text">
                  {( edu?.course_name || edu?.stream) ? (
                    <>
                      { edu?.course_name}

                      {edu?.stream?' (':''}
                      {edu?.stream}
                      {edu?.stream?')':''}
                    </>
                  ) : null}
                </p>

                <h6 className="card-subtitle mb-2 text-muted">
                  {edu?.percentage ?`${edu.percentage} %`:""} 
                </h6>

                <p className="mb-2 card-text">
                  {edu?.passing_year? edu?.passing_year:""}
                  {/* {edu?.passing_year? new Date(edu?.passing_year).toLocaleDateString():""} */}
                </p>
              </div>

              <div className="docButton">
                <button
                  className="btn text-danger doc_btn"
                  onClick={() => deleteEducation(index)}
                >
                  Delete
                </button>
                <button className="btn text-primary" onClick={() => editEducation(edu,index)}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    })}
      </div>

      {showAddEducation ? (
        <AddEducationDetails
        setEducation={setEducation}
        setShowAddEducation={setShowAddEducation}
        education={education}
        editedValues={editedValues}
        setEditedValues={setEditedValues}
        index={index }
        setIndex={setIndex}
       
  
        />
      ) : (
        <span className="btn-link"  onClick={() => setShowAddEducation(true)}>
          Add more
          <i className="fa fa-plus ml-2"></i>
        </span>
      )}
    </>
  );
};

export default EducationDetails ;