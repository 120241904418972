import Chips from "components/formComponents/Chips";

const PreviewField = ({
  label,
  items = [],
  text = "",
  key_name = "name",
  type = "text",
  labelClass = "",
}) => {
  // const [data,setData]=useState([])
  const screeningQ = (items) => {
    try {
      return JSON.parse(items);
    } catch (error) {
      // // console.log(error);
      return [];
    }
  };

  // console.log(items, screeningQ(items));
  return text || items?.length ? (
    <div className="col-12 place_container preview_field">
      <div className="form-group mb-4">
        <label className={labelClass}>{label}</label>
        <Preview
          method={type}
          text={text}
          items={items}
          key_name={key_name}
          screening={screeningQ(items)}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

const Preview = ({ method, text, items, key_name, screening }) => {
  switch (method) {
    case "text":
      return <p>{text}</p>;
    case "list":
      return (
        <ol>
          {items?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      );
    case "chips":
      return (
        <div className="col-12">
          <div className="row">
            <Chips items={items} key_name={key_name} hideDelete={true} />
          </div>
        </div>
      );
    case "faq":
      return (
        <div className="col-12">
          {items
            .filter((_i, i) => i % 2 === 0)
            ?.map((item, index) => (
              <div className="faq_item" key={index}>
                <b>{item}</b>
                <p>{items[index + 1]}</p>
              </div>
            ))}
        </div>
      );
    case "screening":
      return (
        <div className="col-12">
          {screening?.map((item, index) => (
            <div className="faq_item" key={index}>
              <b>{item}</b>
              <p className="screening_style p-3">{item[index]}</p>
            </div>
          ))}
        </div>
      );

    default:
      return <h1>Hmmm</h1>;
  }
};

export default PreviewField;
