import axios from "axios";
import InputFile from "components/fileUploader";
import TextInput from "components/formComponents/TextInput";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getParsed } from "services/factories/getParsed";
import "./finalReportModalBody.scss";

const FinalReportModalBody = ({
  onCancel,
  enrollment_id,
  project_id,
  setFetchData,
  enrollment_createdAt,
  project_date,
  project
}) => {
  const [report, setReport] = useState(null);
  const [presentation, setPresentation] = useState(null);
  const [other, setOther] = useState(null);
  const [url, setUrl] = useState(null);
  const [
    final_submission_report_date,
    setFinal_submission_report_date,
  ] = useState();

  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state?.auth?.user);
  // // console.log(enrollment_createdAt)
  // // console.log(project_date)
  useEffect(() => {
    const weeksToAdd = project_date;

    const submissionDate = new Date(enrollment_createdAt);

    submissionDate.setDate(submissionDate.getDate() + weeksToAdd * 7);

    const sd = moment(submissionDate).tz("Asia/Kolkata").format("DD/MM/YYYY");

    // const today = moment().tz("Asia/Kolkata").format("DD/MM/YYYY");

    // const diff = moment(sd, "DD/MM/YYYY").diff(
    //   moment(today, "DD/MM/YYYY"),
    //   "days"
    // );

    // if (diff >= 0) {
    //   setFinal_submission_report_check(true);
    // }

    setFinal_submission_report_date(new Date());
  }, [enrollment_createdAt]);


  // console.log("projecttt", project);
  // console.log("pddd", project_id)

  const onSubmit = async (e) => {
    setLoading(true);
    e?.preventDefault();
    if (!report && !presentation && !other && !url) {
      setLoading(false);
      return toast.error("Please upload a file or link to your project!", {
        toastId: "file",
      });
    }

    const parseURL = (url) => {
      try {
        const parsed = new URL(url);
        return parsed?.href;
      } catch (error) {
        toast.error("Please enter a valid URL", {
          toastId: "url",
        });
        return null;
      }
    };

    const formData = new FormData();
    if (report) formData.append("report", report);
    if (presentation) formData.append("presentation", presentation);
    if (other) formData.append("other", other);
    if (url) {
      const parsed = parseURL(url);
      if (parsed) formData.append("link", parsed);
      else return;
    }

    formData.append("enrollment_id", enrollment_id);
    formData.append("project_id", project_id);
    formData.append(
      "final_submission_report_date",
      final_submission_report_date
    );

// send notification
const sendNotification = async ()=> {
  try {

    const notificationData = {
      userId: project?.project_global_fkey?.employer_account?.id,
      role: project?.project_global_fkey?.role,
      notification_title: `Student submits the final report`,
      notification_description: `${user?.first_name} ${user?.last_name} submits the final report for ${project?.project_title}`,
      notification_type: `project submit`,
      notification_payload: {
        id: project?.project_global_fkey?.employer_account?.id,
        role: 2,
        post_id: project_id,
        post_title: project?.project_title,

      },

    }

    const res = await axios.post("/v1/notification/", {
      notificationData
    })

    // console.log("ress", res);
    
  } catch (error) {
    console.log("err", error?.message)
  }
}




    const { data } = await axios.post("/v1/student/final_report", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data?.error) {
      setLoading(false);
      return toast.error(data?.message, {
        toastId: "error",
      });
    } else {
      // await EvaluationRequest()
      setLoading(false);
      toast.success("Report uploaded successfully", {
        toastId: "success",
      });
      sendNotification();
    }

    const enrollment_data = data?.data;
    const weekly_submissions = getParsed(enrollment_data?.weekly_submissions);
    const final_submission_report = getParsed(
      enrollment_data?.final_submission_report
    );

    setFetchData((prev) => {
      console.log( "rrr", {
        prev,
        enrollment_data,
        final_submission_report,
        updated: {
          ...prev,
          data: {
            ...prev?.data,
            data: {
              ...prev?.data?.data,
              ...enrollment_data,
              weekly_submissions,
              final_submission_report,
            },
          },
        },
      });
      return {
        ...prev,
        data: {
          ...prev?.data,
          data: {
            ...prev?.data?.data,
            ...enrollment_data,
            weekly_submissions,
            final_submission_report,
          },
        },
      };
    });
    onCancel();
  };

  const handleURLChange = (e) => {
    setUrl(e?.target?.value);
  };

  // console.log("engggggggg", enrollment_id);


  // const EvaluationRequest = async () => {
  //   try {
  //     const res = await axios.post("/v1/evaluation/createEvaluationRequest", {
  //       enrollment_id: enrollment_id,
  //       mode: "2",
  //       requested_date: new Date(),
  //     });
  //     // console.log(res, res);
  //     toast.success("Evaluation Request sent successfully");
  //     // navigate("/student/dashboard", { replace: true });
  //   } catch (error) {
  //    console.log(error);
  //   }
  // };

  return (
    <form className="final_report_modal form-group" onSubmit={onSubmit}>
      <label className="row gap-3">
        Attach Final Project Report (doc, docx, PDF)*
        <InputFile file={report} setFile={setReport} />
      </label>
      <label className="row gap-3">
        Attach Final Presentation (PPT, PDF)*
        <InputFile file={presentation} setFile={setPresentation} />
      </label>
      <label className="row gap-3">
        Any Other File (mp4, avi, mkv)
        <InputFile file={other} setFile={setOther} />
      </label>

      <TextInput
        label="Attach Link of Your Work (github,behance, dribbble, any other)"
        handleChange={handleURLChange}
        placeholder="https://example.com"
      />
      {loading ? (
        <div className="wrapper1">
          <div className="circle1" />
          <div className="circle1" />
          <div className="circle1" />
          <div className="shadow" />
          <div className="shadow" />
          <div className="shadow" />
        </div>
      ) : (
        <div className="final_modal_btns d-flex justify-content-end align-items-center gap-2">
          <button className="cancel_btn" onClick={onCancel}>
            Cancel
          </button>
          <div className="Submit_btn py-3" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}
    </form>
  );
};

export default FinalReportModalBody;
