import React, { useState } from "react";

const InterviewSchedule = ({
  setShowDate,
  handleHired,
  setScheduleDate,
  setScheduleTime,
  loading,
}) => {
  return (
    <div className="date_time_picker">
      <div className="date_time_picker_container">
        <div className="date_time_picker_header">
          <h2>Schedule Interview</h2>
          <i
            onClick={() => setShowDate(false)}
            className="fa fa-times icon-one"
            aria-hidden="true"
          ></i>
        </div>
        <div className="date_time_picker_body">
          <div className="date_time_picker_body_container_left_date">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              placeholder="Select Date"
              disabled={loading}
              className="form-control"
              onChange={(e) => setScheduleDate(e.target.value)}
            />
          </div>
          <div className="date_time_picker_body_container_left_time">
            <label htmlFor="time">Time</label>
            <input
              type="time"
              id="time"
              name="time"
              disabled={loading}
              className="form-control"
              placeholder="Select Time"
              onChange={(e) => setScheduleTime(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <button className="w-100 mb-2" type="button">
            Loading...
          </button>
        ) : (
          <div className="date_time_picker_footer">
            <button onClick={() => setShowDate(false)} className="cancel short">
              Cancel
            </button>
            <button  onClick={handleHired} className="schedule short">
              Schedule
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewSchedule;
