import React, { useReducer } from "react";
import DateField from "components/formComponents/DateField";
import TextArea from "components/formComponents/TextArea";
import TextInput from "components/formComponents/TextInput";
import { toast } from "react-toastify";
import { checkValidURL } from "services/factories/checkValidURL";
import "./addProject.scss";

const AddProject = ({ setProjects, setShowAddProject }) => {
  const initialState = {
    name: "",
    link: "",
    description: "",
    start_date: "",
    end_date: "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const onSubmit = () => {
    let isValid = 0;
    Object.keys(state).forEach((key) => {
      if (state[key] === "") {
        toast.error(`${key.split("_").join(" ")} is required`, {
          delay: 300 * isValid,
          toastId: key,
        });
        isValid++;
      }
    });
    if (isValid > 0) return;
    const parsed = checkValidURL(state?.link);
    if (!parsed) {
      return toast.error("Please enter a valid URL", {
        toastId: "link",
      });
    } else {
      state.link = parsed;
    }

    setProjects((p) => [...p, state]);
    dispatch({ type: "reset" });
    setShowAddProject(false);
  };

  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };

  const dateBefore100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100)
  )
    .toISOString()
    .split("T")[0];

  const dateAfter100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() + 100)
  )
    .toISOString()
    .split("T")[0];

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="add_projects row">
      <div className="add_project_main buttons add_btn">
        <p className="add_btn_p fs-6 fs-md-5 fs-lg-4">Add Projects</p>
        <div>
          <button
            className="btn drop_btn_style btn-outline-primary m-2"
            onClick={() => setShowAddProject(false)}
          >
            Cancel
          </button>

          <button
            className="btn drop_btn_style btn-primary  m-2 text-white"
            onClick={() => onSubmit()}
          >
            Save
          </button>
        </div>
      </div>

      <div className="add_project col-lg-6 col-md-6 col-sm-12">
        <TextInput label="Name" id="name" handleChange={onChange} />
        {/* <TextField
          label="Company Name"
          id="place_name"
          placeholder="X Company"
          register={register}
          isPopupOpen={isPopupOpen}
          setValue={setValue}
          updatePopup={updatePopup}
          method="company"
        /> */}
      </div>

      <div className="add_project col-lg-6 col-md-6 col-sm-12">
        <TextInput label="Link" id="link" handleChange={onChange} />
      </div>

      <div className="add_project col-12">
        <TextArea
          label="Description"
          id="description"
          handleChange={onChange}
        />
      </div>
      <div className="add_project col-lg-6 col-md-6 col-sm-12">
        <DateField
          label="Start Date"
          id="start_date"
          handleChange={onChange}
          minDate={dateBefore100Years}
          maxDate={today}
        />
      </div>

      <div className="add_project col-lg-6 col-md-6 col-sm-12">
        <DateField
          label="End Date"
          id="end_date"
          handleChange={onChange}
          minDate={dateBefore100Years}
          maxDate={dateAfter100Years}
        />
      </div>
    </div>
  );
};

export default AddProject;
