import React from 'react'
import TopNavBar from './components/TopNavbar/TopNavBar'
import NavBar from './components/navbar/NavBar'
import Footer from 'view/dashboard/student/footer/Footer'
import GoToTop from 'GoToTop'
import PrivacyPolicy from './Policies/PrivacyPolicy'
import { Helmet } from 'react-helmet'

const PrivacyPolicyPage = () => {
  return (
    
    <div className="home_page row justify-content-center align-items-center">
        <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content={`At Qollabb, we prioritize your privacy and security. Our Privacy Policy explains how we collect, use, and protect your personal information on our platform. `}
        />
            <meta
              name="title"
              content={"Privacy Policy"}
            />
        <title>
          Privacy Policy
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
          <TopNavBar />
          <NavBar />
          <PrivacyPolicy/>
          <Footer />
      <GoToTop />
    </div>
  )
}

export default PrivacyPolicyPage