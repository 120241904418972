import * as React from "react"
import { memo } from "react"

const SvgComponent = () => (
    <svg width={29} height={27} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.26 3.822v1.5h-1a5 5 0 0 0-5 5v7.5a3.5 3.5 0 0 0 3.5 3.5h1.5v1.5a3.5 3.5 0 0 0 3.5 3.5h11a3.5 3.5 0 0 0 3.5-3.5v-1.5h1.5a3.5 3.5 0 0 0 3.5-3.5v-7.5a5 5 0 0 0-5-5h-1v-1.5a3.5 3.5 0 0 0-3.5-3.5h-9a3.5 3.5 0 0 0-3.5 3.5Zm3.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v1.5h-12v-1.5a1.5 1.5 0 0 1 1.5-1.5Zm-2.5 14.5a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-6Z"
            fill="#6149CD"
        />
    </svg>
)

const PrintIcon = memo(SvgComponent)
export default PrintIcon
