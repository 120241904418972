import React, { useState } from 'react'
import "./companies.scss"
import Vector from "./Vector.svg"
import TextInput from 'components/formComponents/TextInput'
import { Link } from 'react-router-dom'
import CompanyTop from '../components/companyTop/CompanyTop'
import EducatorFilters from '../components/EducatorFilter/EducatorFilters'

const Companies = () => {
  return (
      <div className='companies-components'>
          <CompanyTop/>
           {/* Mobile Search Toggle  */}
      <div className="mobile_search d-flex justify-content-between align-items-center gap-2 d-md-none">
        <div className="w-100 search_skills_mobile">
          <TextInput
            placeholder="Search by keyword, skill, or interest"
            className="search_skills"
          />
        </div>

        <div className="toggle_box">
          <span className="filter d-block  mb-2">
            <i
              className="fa-solid fa-sliders fa-lg"

            ></i>
          </span>
        </div>
      </div>

      {/* Filter Toggle Close Button  */}

          <div className='companies-left'>
              <div className='companies_left_content'>
                  <img src={Vector} alt="Image" />
                  <h4>Currently there are no matching companies for your institution.</h4>
                  <p>Qollabb will suggest companies based on your student’s prefrence for projects, internships, and Jobs</p>
                  
              </div>
              <div className="company_right">
              <EducatorFilters/>
              </div>
             

          </div>
    </div>
  )
}

export default Companies