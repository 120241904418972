import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import template1 from "../../../../../images/ImageTemplates/10.png"
import template2 from "../../../../../images/ImageTemplates/11.png"
import template3 from "../../../../../images/ImageTemplates/15.png"
import scanme from "../../../../../images/ImageTemplates/scanme5.webp"
import "./jobTemplate.scss"
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';

const templateConfigurations = {
    [template1]: {
        companyImgPosition: { top: "6%", right: "0%" },
        templateItemsPosition: { top: "17%", left: "29%", gap: "20px" },
        qrSectionPosition: {position: "absolute", bottom: "15%", right: "10%" },
        qollabbLinkPosition: { position: "absolute",  bottom: "2%", right: "20%" },
        pHeadStyle: { marginBottom: "16%", marginLeft: "10%", color: "#000" }
    },
    [template2]: {
        companyImgPosition: { top: "5%", right: "3%" },
        templateItemsPosition: { top: "17%", left: "20%", gap: "24px" },
        qrSectionPosition: { position: "absolute", bottom: "32%", right: "19%" },
        qollabbLinkPosition: {position: "absolute", bottom: "31%", right: "36%" },
        pHeadStyle: { marginBottom: "4%", marginLeft: "13%", color: "#000" }
    },
    [template3]: {
        companyImgPosition: { top: "5%", right: "3%" },
        templateItemsPosition: { top: "18%", left: "13%", gap: "28px" },
        qrSectionPosition: { position: "absolute", bottom: "16%", right: "37%" },
        qollabbLinkPosition: {display: "none", position: "absolute", bottom: "31%", right: "36%" },
        pHeadStyle: { marginBottom: "6%", marginLeft: "13%", color: "#000" }
    },
};



const JobTemplate = () => {
    const targetElement = useRef(null);
    const location = useLocation();
    const data = location?.state?.data;
    const [selectedImage, setSelectedImage] = useState(template1);
    const [templateStyle, setTemplateStyle] = useState(templateConfigurations[template1]);

    const handleImageChange = (event) => {
        const selectedTemplate = event.target.value;
        setSelectedImage(selectedTemplate);
        setTemplateStyle(templateConfigurations[selectedTemplate]);
    };

    const handleGenerateImage = () => {
        const width = 1060;
        const height = 1065;
        html2canvas(targetElement.current, { width, height }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'generated_image.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };


    const [img, setImg] = useState("");

    const convertImageToBase64 = async () => {
      try {
    
        const timestamp = new Date().getTime();
    const cacheClearedUrl = `${`${data?.global_job?.employer_account?.company_logo}`}?_=${timestamp}`;
    
    const response = await fetch(cacheClearedUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          const newImageUrl = URL.createObjectURL(blob);
          setImg(newImageUrl);
        }, 'image/png');
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    useEffect(()=> {
        convertImageToBase64();
    }, [])

    return (
        <div className='template-img'>
            <div ref={targetElement} className='template-body'>
                <div className='template-content'>
                    <img src={selectedImage} alt="bellicon" />
                    <div className='company-img' style={templateStyle.companyImgPosition}>
                        <img src={img} alt="Image" />
                        {/* <img src={data?.global_job?.employer_account?.company_logo} alt="Image" /> */}
                    </div>
                    <div className='template-items' style={templateStyle.templateItemsPosition}>
                        <h2 className='p-head' style={templateStyle.pHeadStyle}>Job opportunity for Freshers</h2>
                        <div className='template-item'>
                            <div>Company:</div>
                            <div>{data?.global_job?.employer_account?.company?.company_name}</div>
                        </div>
                        <div className='template-item'>
                            <div>Position:</div>
                            <div>{data?.job_title}</div>
                        </div>
                        <div className='template-item'>
                            <div>Job Type:</div>
                            <div>{data?.location !== null ? "Office" : "Remote/virtual"}</div>
                        </div>
                        <div className='template-item'>
                            <div>Location:</div>
                            <div>{data?.global_job?.employer_account?.location}</div>
                        </div>
                    </div>
                    <div className='qr-section' style={templateStyle.qrSectionPosition}>
                        <div className='scanme-container'>
                            <img src={scanme} alt="scanme" width={120} style={{ filter: selectedImage === template1 ? "hue-rotate(60deg)" : "hue-rotate(180deg)" }} />
                            <div className='qrcode' style={{position: "absolute", top: "17.4%", left: "24.4%"}}>
                                <QRCode value={`https://qollabb.com/job/${data?.id}`} size={62} />
                            </div>
                        </div>
                    </div>
                    <div className="qollabb-link" style={templateStyle.qollabbLinkPosition}>
                        <a href={"https://www.qollab.com/"} >https://qollabb.com</a>
                    </div>
                </div>
            </div>
            <div className='btn-section'>
                <select value={selectedImage} onChange={handleImageChange} className='select-img'>
                    <option value={template1}>Template 1</option>
                    <option value={template2}>Template 2</option>
                    <option value={template3}>Template 3</option>
                    {/* Add other template options */}
                </select>
                <button onClick={handleGenerateImage}>Download Template</button>
            </div>
        </div>
    );
};

export default JobTemplate;
