import React, { lazy } from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import HeroSection from "./components/heroSection/HeroSection";
import "./homepage.scss";
import StudentChooseSection from "./components/HowItWorks/studentChooseSection";
import HomeJourney from "./components/Journey/HomeJourney";
import TopCategorySection from "./components/TopCategories/topCategorySection";
import PartnerSection from "./components/partners/partnerSection";
import ZoomSection from "./components/zoom/zoomSection";
// import Footer from "view/dashboard/student/footer/Footer";
// import heroImg from "../../images/heroImg.png";
import heroImg from "../../images/heroImg.png";
import GoToTop from "GoToTop";
import ContactUs from "./components/contectUs/ContactUs";
import { Helmet } from "react-helmet";
import OurTeam from "./components/ourTeam/ourTeam";
const Footer = lazy(()=> import("view/dashboard/student/footer/Footer"))

const HomePage = () => {
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Qollabb Edutech Pvt. Ltd.",
    "url": "https://www.qollabb.com",
    "logo": "https://www.qollabb.com/logo-favicon.png",
    "sameAs": [
      "https://www.facebook.com/Qollabbedutechh",
      "https://www.linkedin.com/company/qollabb",
      "https://www.instagram.com/qollabb/",
      "https://www.youtube.com/@Qollabb"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-7428099546",
      "contactType": "Customer Support",
      "areaServed": "IN",
      "availableLanguage": "English"
    }
  };

  return (
    <section className="home_page row justify-content-center align-items-center mx-0 w-100">
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="Discover exciting internships, fresher jobs, industry projects, and summer internships with Qollabb. Kickstart your career with hands-on experience and real-world skills. Sign up today!"
        />
        <title>
          internships | fresher jobs | industry projects | summer internships
        </title>
        <link
          rel="canonical"
          href="https://qollabb.com/"
        />

      {/* Organization schema markup code for home page */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>

        
      </Helmet>

      <TopNavBar />
      <NavBar />
      <HeroSection
        hero={heroImg}
        topText="Discover your journey"
        buttonText="Let's Start!"
        title="Connecting Companies With Brilliant Minds in Campuses"
        semiTitle="Qollabb is revolutionizing student-industry collaboration by connecting students with real-world projects and providing companies access to top talent in campuses."
        card
      />
      <StudentChooseSection text1="Welcome to Qollabb" />
      <HomeJourney />
      <TopCategorySection />
      <OurTeam/>
      <PartnerSection />
      <ZoomSection />
      <ContactUs />
      <Footer />
      <GoToTop />
    </section>
  );
};

export default HomePage;
