import useOutsideClick from "hooks/useOutsideClick";
import { Fragment, useRef, useState } from "react";

const LinkProjectDropdown = ({
  projects,
  setSelectedProject,
  selectedProject,
}) => {
  const [isOpen, setOpen] = useState(() => false);
  const [searchProject, setSearchProject] = useState(() => "");
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpen(false), isOpen);
  const toggleDropdown = () => setOpen((prev) => !prev);
  // console.log(selectedProject, "selectedProject");
  return (
    <div className="dropdown" ref={ref}>
      <div
        className={`dropdown-header dropdown-header-top ${
          isOpen ? "open" : ""
        }`}
        onClick={toggleDropdown}
      >
        <span className="dropdown-header-title">
          {selectedProject?.title.slice(0, 35) || "Select Project"}
        </span>
        <i className={`fa fa-chevron-right icon ${isOpen ? "open" : ""}`}></i>
      </div>
      {/* make search input box */}
      <div
        className={`dropdown-body linked-project-dropdown  ${
          isOpen ? "open" : ""
        }`}
      >
        <input
          onChange={(e) => setSearchProject(e.target.value)}
          className="form-control"
          placeholder="Search Project"
        />
        {
          <Fragment>
            {projects
              .filter((project) =>
                project.title
                  .toLowerCase()
                  .includes(searchProject.toLowerCase())
              )
              .map((project) => (
                <div
                  key={project.id}
                  className="dropdown-item"
                  onClick={() => {
                    setSelectedProject(project);
                    toggleDropdown();
                  }}
                >
                  {project.title.slice(0, 35) +
                    (project.title.length > 35 ? "..." : "")}
                </div>
              ))}
          </Fragment>
        }
      </div>
    </div>
  );
};

export default LinkProjectDropdown;
