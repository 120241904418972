import React from "react"
import "./style.scss"
import img from "./userimg.png"
import ibmImg from "../../../../../images/ibm.png"
import PreviewField from "components/employer/Field"
import ProjectReportTable from "view/dashboard/mentor/components/ProjectReportTable"
import ProjectTableMobile from "view/dashboard/mentor/components/ProjectTableMobile"
import StatusProgress from "view/dashboard/student/application/StatusProgress"

const AdminEvaluationDetails = () => {
  const data = { keywords: ["it", "react"] }

  return (
    <section className="projectEvaluation_main py-4">
      <div className="mobo_padding container-lg container-fluid">
        <h3>Project Evaluation</h3>

        <div className="top_bar text-white">
          <div className=" left-content ">
            <i class="fa fa-arrow-left text-white fs-2" aria-hidden="true"></i>
            <div className="d-flex align-items-center gap-2">
              <img src={img} alt="Image" />
              <div>
                <h4>Rahul Narang</h4>
                <p>Digital Content Creation</p>
              </div>
            </div>
          </div>
          <div className="right-content">
            {/* <div>
              <p>Date Assigned</p>
              <h4>12/20/2023</h4>
            </div> */}
            <div>
              <p>Date Assigned</p>
              <h4>12/20/2023</h4>
            </div>
          </div>
        </div>

        <div className="mobo_padding_inner project-mainSection my-4 gap-3">
          <div>
            <div className="left_upper flex">
              <div className="w-100">
                <div className="text-white px-3 px-sm-0">
                  <h4 className="m-0">Product Design Project</h4>
                  <p>Remote/Virtual Project</p>
                </div>

                <div className="w-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex d-sm-block gap-3">
                  <div className="box2">
                    <img src={ibmImg} width={64} height={64} alt="ibm" />
                    <div className="box-cnt">
                      <h4>IBM India</h4>
                      <p className="thin">Noida, Uttar Pradesh, India</p>
                    </div>
                  </div>
                  <div className="box1_mobo d-flex d-sm-none w-100">
                    <p>Duration</p>
                    <div className="">
                      <h3>2-5</h3>
                      <p className="light">Weeks</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box1 d-none d-sm-block">
                <p>Duration</p>
                <h3>2-5</h3>
                <p className="light">Weeks</p>
              </div>
            </div>
            <div className="project_detail_content">
              <div className="">
                <PreviewField
                  label="Project Details"
                  items={data?.keywords}
                  type="chips"
                />
                <PreviewField
                  label="What is the main goal for the Project?"
                  text="We would like students to help us with creating content that is aligned with our vision, mission, and sector. We will ensure the student has a clear understanding of what these elements are for our organization.              "
                />
              </div>
              <button>Open Project Details</button>
            </div>
          </div>
          <div>
            <div className="student_progress col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <StatusProgress
              // status={enrollments?.data?.[selectedStudent]?.status}
              // reports={weekly_submissions}
              />

              <div className="weekly-report-accordian">
                <h2>Weekly Reports</h2>
                {/* {weekly_submissions?.map((item) => (
                  <WeekDetail
                    key={item?.week}
                    report={item?.report}
                    index={item?.week}
                    title={`Week ${item?.week}`}
                    description={item?.description}
                    text="uploaded"
                    midTitle="submitted"
                  />
                ))} */}
              </div>
            </div>
          </div>
        </div>

        <div className="evaluationForm">
          <h4>Evaluation Form</h4>

          <ProjectReportTable title="Project Report Quality" />
          <ProjectReportTable title="Project Presentation Quality" />
          <ProjectReportTable
            title="Usefullness of output/Fitment to purpose"
            checkbox
          />
          <ProjectReportTable
            title="Innovativeness of output of project"
            checkbox
          />
          <ProjectReportTable
            title="Timeliness/decipline in report submission"
            checkbox
          />
        </div>

        <div className="evaluationForm_mobile">
          <h4>Evaluation Form</h4>
          <ProjectTableMobile title="Project Report Quality" />
          <ProjectTableMobile title="Project Presentation Quality" />
          <ProjectTableMobile
            title="Usefullness of output/Fitment to purpose"
            checkbox
          />
          <ProjectTableMobile
            title="Innovativeness of output of project"
            checkbox
          />
          <ProjectTableMobile
            title="Timeliness/decipline in report submission"
            checkbox
          />
        </div>

        <div className="mobo_padding_inner bottom_section flex-revese my-4 gap-2">
          <button>Submit</button>
          <h4>Overall Rating: 4.38</h4>
        </div>
      </div>
    </section>
  )
}

export default AdminEvaluationDetails
