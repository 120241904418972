import React, { useEffect, useState } from "react";
import CompaniesCard from "../../components/CompanyCard/CompaniesCard/CompaniesCard";
import CompaniesDetailCard from "../../components/CompanyCard/CompaniesDetailCard/CompaniesDetailCard";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import EducatorFilters from "../../components/EducatorFilter/EducatorFilters";
import '../suggestCompanies/suggestCompanies.scss';

const InvitationSent = ({ colleges }) => {
  const [company, setCompany] = useState([]);
  // const [colleges, setColleges] = useState([]);
  const [employer, setEmployer] = useState(0);
  const [show, setShow] = useState(false);

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   const fetchColleges = async () => {
  //     const res = await axios.get("/v1/invitations/get_invites?status[]=0");
  //     // // console.log(res, "res");
  //     setColleges(res?.data?.data);
  //   };
  //   fetchColleges();
  // }, []);

  // console.log(colleges, "colleges");

  const [emploayerDataDetails, setEmployerDataDetails] = useState();
  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/company/single/${colleges[employer]?.receiver_emp.id}`
      );
      // // console.log(res, "educatordata");
      setEmployerDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // // console.log("function 2 working")
  }, [employer, colleges]);

  const style1 = {
    backgroundColor: "#F9B035",
  };
  const style2 = {
    display: "none",
  };
  const style = {
    borderBottom: " 2px solid #6149CD",
  };

  // console.log(emploayerDataDetails,"emploayerDataDetails");
  useEffect(() => {
    const fetchInstitutions = async () => {
      const res = await axios.get("/v1/employer");
      // // console.log(res, "res");
      setCompany(res?.data?.data);
    };
    fetchInstitutions();
  }, []);

  const handleToggle = () => {
    setOpen((toggle) => !toggle);
  };

  const handleFilterToggle = () => {
    setFilter((toggle) => !toggle);
  };

  return (
    <div className="companies-component">
      {/* <CompanyTop invitationLength={colleges?.length} active3={true} /> */}

      {/* Mobile Search Toggle  */}

      {/* Filter Toggle Close Button  */}

      {colleges.length > 0 ? (
        <div className="companies-left">
          <div className="companies_left_container">
            <div className="searchbox w-100 hidesearchbox">
              <label>
                {" "}
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search candidate"
                />
                <i className="fa-solid fa-magnifying-glass text-black"></i>
              </label>
              <i
                onClick={() => handleFilterToggle()}
                // className="filter_mobo fa fa-sliders d-flex "
                className="filter_mobo fa fa-sliders d-flex d-sm-none"
                aria-hidden="true"
                style={{ color: "#6149CD" }}
              ></i>
            </div>
{/* mobile view search box and filter */}

<div className="searchbox-container d-md-none gap-2  pl-1 pr-1">

<div className="searchbox w-100">
                 <label>
          <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
              placeholder="Search candidate"
                />
         <i className="fa-solid fa-magnifying-glass text-black"></i>
              </label>
   </div>

<div className="">
  <i
     className="fa-solid fa-sliders fa-lg filter_mobo"
     onClick={() => handleFilterToggle()}
   style={{ color: "#6149CD" }}
   ></i>
    </div>
</div>     
 
{/* ---------------- */}

            <div className="scroll_components scrollViewAllCompany mobileViewCompanyCard">
              {colleges
                ?.filter((item) => {
                  return (
                    item?.receiver_emp?.company?.company_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    (
                      item?.receiver_emp?.first_name +
                      " " +
                      item?.receiver_emp?.last_name
                    )
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  );
                })
                ?.map((company, index) => {
                  const collegesFunction = () => {
                    setEmployer(index);
                    handleToggle();
                    // console.log("function working");
                  };
                  return (
                    <div className="w-100" onClick={collegesFunction}>
                      <CompaniesCard college={company?.receiver_emp} />
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="companies_mid_container d-none d-sm-block"> */}
          <div className="companies_mid_container">
            <CompaniesDetailCard
              style1={style1}
              style2={style2}
              text1="Pending for acceptance"
              emploayerDataDetails={emploayerDataDetails}
              pageLength={colleges?.length}
              currentPage={employer}
              setPage={setEmployer}
            />
          </div>

          {/* {open && (
            <div className="companies_mid_container_mobo d-flex d-sm-none">
              <i
                class="cross_icon fa fa-times"
                onClick={() => handleToggle()}
                aria-hidden="true"
              ></i>
              <CompaniesDetailCard
                style1={style1}
                style2={style2}
                text1="Pending for acceptance"
                emploayerDataDetails={emploayerDataDetails}
              />
            </div>
          )} */}

          <div className="company_right_container">
            <EducatorFilters />
          </div>
          {filter && (
            <div className="company_right_container_mobo d-block d-sm-none">
              <i
                class="cross_icon fa fa-times text-end m-3"
                onClick={() => handleFilterToggle()}  
                aria-hidden="true"
              ></i>
              <EducatorFilters />
            </div>
          )}
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>
                  <b>No Invitations Sent</b>
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitationSent;
