import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Popup from "./Popup";

const TextFieldWithPopup = ({
  label,
  id,
  placeholder = "",
  required = false,
  register = () => {},
  options = {},
  setValue = () => {},
  isPopupOpen,
  updatePopup,
  disabled = false,
  path,
  secondaryLabel = false,
  key_name = "name",
  withId = false,
  value = "",
  defaultValue,
  className = "",
  className1,
  onInputChange = ()=> {},
}) => {
  const [data, setData] = useState(() => []);
  const [inputValue, setInputValue] = useState(() => value);
  const [focus, setFocus] = useState(() => 0);
  const inputRef = useRef();
  const [requests, setRequests] = useState({});

  // // console.log(value, "value");

  useEffect(() => {
    if (!inputValue) return;

    const getResponse = async () => {
      const reponse = await axios.get(path, {
        params: { query: inputValue },
      });
      setRequests((prev) => ({
        ...prev,
        [inputValue]: reponse?.data?.data || [],
      }));
      setData(reponse?.data?.data || []);
      // // console.log("datacity", data);
    };

    if (!requests[inputValue]) {
      if (
        requests[inputValue.slice(0, -1)] &&
        !requests[inputValue.slice(0, -1)].length
      ) {
        setRequests((prev) => ({ ...prev, [inputValue]: [] }));
        return;
      } else getResponse();
    } else setData(requests[inputValue]);
  }, [inputValue, requests, path]);

  const onChange = (e) => {
    const { value } = e.target;
    setInputValue(value);


    // console.log(value, "value");
    
    if (value.length > 0) {
      setFocus(1);
      updatePopup(true);
      // console.log("beforeddd", data);
      const filteredData = [...data].filter((item) =>
        item[key_name].toLowerCase().startsWith(value)
      );
      // console.log("filtered", filteredData)
      setData(filteredData);
      // console.log("dataaa",data)

      // if (filteredData?.length && filteredData[0][id] === value) {
      //   // console.log("filter",filteredData[0][id])
      //   setValue(id, filteredData[0].id);
        
      // }
      if (filteredData?.length > 0) {
        // console.log("filterdataa",filteredData)
        // console.log("First item ID:", filteredData[0][id]);
        // console.log("Input value:", value);
  
        // Check if the first item's ID matches the input value
        if (filteredData[0][id]?.toLowerCase()?.startsWith(value.toLowerCase())) {
          // console.log("Condition satisfied.....");
          setValue(id, filteredData[0]?.id);
          
        } else {
          // console.log("Condition not satisfied.");
        }
      }

    } 
    else {
      updatePopup(false);
      setData([]);
    }

    onInputChange(e.target.value);
  };

  const closePopup = () => {
    updatePopup(false);
  };

  const handleKey = (e) => {
    if (!data.length) return;
    setValue(id, 0);
    let focusCounter = focus;
    if (e.key === "ArrowDown") {
      if (focusCounter < data.length) focusCounter++;
    } else if (e.key === "ArrowUp") {
      focusCounter > 0 && focusCounter--;
    } else if (e.key === "Enter") {
      const value = data[focusCounter - 1][key_name];
      e.preventDefault();
      inputRef.current.value = value;
      setInputValue(value);
      updatePopup(false);
      setValue(id, value);
      if (withId) setValue(withId, data[focusCounter - 1]);
      setFocus(0);
    } else {
      return;
    }
    setFocus(focusCounter);
  };

  const updateValue = (item) => {
    inputRef.current.value = item[key_name];
    setValue(id, item[key_name]);
    setInputValue(item[key_name]);
    if (withId) setValue(withId, item);
    updatePopup(false);
    closePopup();
  };

  // // console.log(inputValue, "inputValue");

  return (
    <div className="form-group mb-2 place_container" onKeyDown={handleKey}>
      {label ? (
        <label htmlFor={id} className="">
          {label}
          {required && <span className={`${className1}`}>*</span>}
          {secondaryLabel && <small>{secondaryLabel}</small>}
        </label>
      ) : null}

      <input
        className={`form-control mt-2 enter_location bg-white ${className}`}
        type="text"
        name={id}
        id={id}
        placeholder={placeholder}
        {...register(id, { required, ...options })}
        onChange={onChange}
        ref={inputRef}
        autoComplete={"off"}
        disabled={disabled}
        value={defaultValue}
        defaultValue={inputValue}
      />
      {isPopupOpen && (
        <Popup
          data={data}
          input_value={inputValue}
          focus={focus}
          updateValue={updateValue}
          key_name={key_name}
          closePopup={closePopup}
        />
      )}
    </div>
  );
};

export default TextFieldWithPopup;
