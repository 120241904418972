import { memo } from "react"
import "./loading.scss"
import Logo from "./images/logos/Logo"

const Loading = ({ text = <></> }) => {
  return (
    <div className="loader">
      <Logo />
      <p className="loader_text">{text}</p>
    </div>
  )
}

export default memo(Loading)
