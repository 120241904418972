import axios from "axios";
// import Datatable from "components/datatable-new/DatatableNew";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Datatable from "../../../../components/datatable-new/DatatableNew";
// import StatusButton from "./StatusButton";
// import useOutsideClick from "hooks/useOutsideClick"
// import MobileDataTable from "components/datatable-new/MobileDataTable";
// import MobileDataTable from "../../../../components/datatable-new/MobileDataTable";
import moment from "moment";
import MobileDataTable from "./MobileDataTable";
import ProgressStatus from "./ProgressStatus";
import StatusButton from "./StatusButton";
// import DeactivatePost from "./DeactivatePost";


const ProjectTable = ({
  // data,
  // isPopupOpen,
  setIsPopupOpen,
  setType,
  // setRefreshTable,
  // refreshTable,
}) => {
  // const location = useLocation()
  // const name = location.pathname.split("/")[2];
  // // console.log(name);
//   const [search, setSearch] = useState(() => "");

// // console.log("Project table", data)

// return
//   const [rowData, setRowData] = useState(
//     data?.filter((item) => item.status === 2)
//   );

  // const [clicked, setClicked] = useState(() => false);
  // const handleClick = () => {
  //   setClicked((p) => !p);
  // };

  // useEffect(() => {
    // setRowData(data?.filter((item) => item.status === 2));
  // }, [data, refreshTable]);

  // const [toggleButtons, setToggleButtons] = useState({})
  // const toggleRef = useRef()

  // useEffect(() => {
  //   const toggle = {}
  //   data.map((key) => {
  //     // console.log(key)
  //     toggle[key.id] = false
  //   })
  //   setToggleButtons(toggle)
  // }, [data])

  // const handleToggle = (id) => {
  //   const toggle = toggleButtons
  //   const keys = Object.keys(toggle)
  //   keys.forEach((x) => {
  //     if (x != id) {
  //       toggle[x] = false
  //     }
  //   })
  //   toggle[id] = !toggle[id]
  //   setToggleButtons({ ...toggle })
  // }

  // const handleBlur = () => {
  //   const toggle = toggleButtons
  //   const keys = Object.keys(toggle)
  //   keys.forEach((x) => {
  //     toggle[x] = false
  //   })
  //   setToggleButtons({ ...toggle })
  // }

  // useOutsideClick(toggleRef, handleBlur)

//   const closePost = async (id, status) => {
//     if (status < 1 || status > 2) return toast.error("Invalid status");
//     const { error, message } = await axios.patch("/v1/project/status", {
//       id,
//       status: 3,
//     });

//     if (error) {
//       toast.error(message);
//     } else {
//       setRefreshTable(!refreshTable);
//       toast.success("Project closed successfully");
//     }
//     // handleBlur()
//   };

  // const activePost = async (id, status) => {
  //   if (status < 1 || status > 2) return toast.error("Invalid status");
  //   const { error, message } = await axios.patch("/v1/project/status", {
  //     id,
  //     status: 2,
  //   });

  //   if (error) {
  //     toast.error(message);
  //   } else {
  //     setRefreshTable(!refreshTable);
  //     toast.success("Project activated successfully");
  //   }
  //   // handleBlur()
  // };

  // // console.log(data, "projectData");
  
  const {id} = useParams();

  // console.log("ptable id", id)

  const [projects, setProjects] = useState([]);

  useEffect(()=> {
    // console.log("use effect caled")

    const ProjectDetails = async () =>{
      try {
        const data = await axios.get(`/v1/educator/studentProjects/${id}`)
  
        // console.log("ptable project", data)
        setProjects(data?.data?.data)
        
      } catch (error) {
        console.log("errr")
      }

    }

    ProjectDetails();
  },[]);

  

  const columns = [
    {
      Header: "Date Assigned",
      accessor: "date",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        // console.log("date", data)
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date}
          </strong>
        );
      },
    },
    {
      Header: "Assigned Opportunity",
      accessor: "opportunity",
      width: "17%",
      textalign: "start",
    },
    {
      Header: "Company Name",
      accessor: "company",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.company}
          </strong>
        ); 
      },
    },
    {
      Header: "Employer Name",
      accessor: "employer",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.employer}
          </strong>
        );
      },
    },
    {
      Header: "Progress Status",
      accessor: "progress",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.progress}
          </strong>
        );
      },
    },
    
    // {
    //   Header: "Send Invite",
    //   accessor: "invites",
    //   width: "13.5%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.student_invites}
    //         {data.row.original.educator_invites}
    //       </strong>
    //     );
    //   },
    // },

    // /employer/project/students/invite/5
    {
      Header: "Status",
      accessor: "status",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return <strong>{data.row.original.status}</strong>;
      },
    },
    // {
    //   Header: " ",
    //   width: "1%",
    //   sortable: false,
    //   Cell: (data) => {
    //     return (
    //       <DeactivatePost
    //         status={data.row.original.statusCode}
    //         projectid={data.row.original.id}
    //         method={"project"}
    //         closePost={() =>
    //           closePost(data.row.original.id, data.row.original.statusCode)
    //         }
    //         // activatePost={() =>
    //         //   activatePost(data.row.original.id, data.row.original.statusCode)
    //         // }
    //       />
    //     );
    //   },
    // },
  ];

  // const map = {
  //   1: "Ongoing",
  //   2: "Completed",
  //   // 3: "Closed",
  // };

  // console.log("ptable pp", projects)
  // const pdata = projects?.student?.project_enrollments

  // // console.log("eeeeee", pdata)
  
  // const filteredData = projects?.map(item => {
  //   const projectId = item.project_id;
    
  //   // Filter project_enrollments to keep only those with matching project_id
  //   const filteredEnrollments = item?.educator_students_project?.student?.project_enrollments.filter(enrollment => {
  //     return enrollment?.project_id === projectId;
  //   });
    
  //   // Create a new object with the filtered project_enrollments
  //   return {
  //     ...item,
  //     educator_students_project: {
  //       ...item?.educator_students_project,
  //       student: {
  //         ...item?.educator_students_project?.student,
  //         project_enrollments: filteredEnrollments,
  //       },
  //     },
  //   };
  // });
  
  // // console.log("filter projectsss...",filteredData);

  // const studentnotEnrolled = projects?.student === null ? projects?.project_invites : projects?.student?.project_enrollments.length > 0 ? projects?.student?.project_enrollments : projects?.project_invites

  // if(projects?.student !== null){
    
  //   projects?.project_invites?.forEach(invite => {
  //   // Check if the project_id is not present in project_enrollments
  //   const projectAlreadyEnrolled = projects?.student?.project_enrollments?.some(enrollment => enrollment.project_id === invite.project_id);
  
  //   // If the project is not already enrolled, add it to project_enrollments
  //   if (!projectAlreadyEnrolled) {
  //       // Move the project object inside project_enrollments
  //       projects?.student?.project_enrollments?.push({
  //           // Include necessary fields from invite
  //           id: invite.project_id,
  //           // ... (other fields you want to include)
  //           project: invite.project
  //       });
  //   }
  //   });
  //   delete projects.project_invites;
  // }

  
  //  const studentEnrolled = projects?.student === null ? projects.project_invites : projects?.student?.project_enrollments


// projects.project_invites = []


  // console.log("ppppp", projects);

  
  // console.log("notenroll", projects)

  const rows = projects
    ?.map((post) => {
      // const projectEnrollments = post?.educator_students_project?.student?.project_enrollments?.length > 0 ? post?.educator_students_project?.student?.project_enrollments[0] : {};
      // // console.log("enrollment...", projectEnrollments);

      const projectEnrollment = post?.educator_students_project?.student?.project_enrollments?.find(
        enrollment => enrollment.project_id === post?.project_id
      );

        // console.log("enrollment", projectEnrollment);

      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        opportunity: (
          <Link
            className="p-0"
            to={``}
            key={post?.project?.project_title}
          >
            {" "}
            {post?.project?.project_title.slice(0, 60) || "No title"}
          </Link>
        ),
        company: post?.project?.project_global_fkey?.employer?.company?.company_name ?? post?.project?.project_global_fkey?.employer?.company?.co,
        employer: post?.project?.project_global_fkey?.employer?.first_name + " " + post?.project?.project_global_fkey?.employer?.last_name,
        progress:  (
          <ProgressStatus
            status={projectEnrollment?.status ?? 0 }
            reports={JSON.parse(projectEnrollment?.weekly_submissions ?? "[]")}
            final_submission={projectEnrollment?.final_submission_report ?? null} 
           />
          
        ),
        status: (
          <StatusButton
            status={projectEnrollment?.status ?? 9}
            type={"project"}
           />
            
          ),
        // status: (
        //   <StatusButton status={map[post?.status]} key={map[post?.status]} />
        // ),
        // student_invites:<Link to={`/employer/project/students/invite/${post?.id}`}>Student invite</Link>,
        // educator_invites:<Link to={`/employer/project/invite/${post?.id}`}>Educator invite</Link>
      };
    });

  // console.log(rows, "rows");


  return (
    <div className="internshipTable_main">
      {/* <div className="table_head flex">
        <div className="mobo_upload">
        </div>
      </div> */}

      {
        rows ? (
          <>
            <div className="d-none d-sm-block">
              <Datatable columns={columns} data={rows} />
            </div>
            <div className="d-block d-sm-none">
              <MobileDataTable columns={columns} data={rows} type={"project"} />
            </div>
          
          </>
        ) : (
          <p>Loading...</p>
        )        
      }

      {/* <SendOpportunity data={rows} columns={columns} /> */}
      {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
    </div>
  );
};

export default ProjectTable;
