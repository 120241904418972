import React, { useEffect, useState } from "react";
import "./projectReportTable.scss";
import Checkbox from "components/formComponents/Checkbox";
import moment from "moment-timezone";
const ProjectReportTableCheckbox3 = ({
  checkbox,
  title,
  subTitle,
  text1,
  text2,
  text3,
  text4,
  text5,
  setRating4,
  rating4,
  setRating5,
  rating5,
  final_submission_report,
  weekly_submissions,
  enrollment_date,
  project_date,
}) => {
  const handleRatingChange5 = (e) => {
    const selectedValue = e.target.value;
    console.log(selectedValue);
    setRating5(parseInt(selectedValue));
  };

  const [weeklyDate, setWeeklyDate] = useState([]);

  const [reportRating, setReportRating] = useState({
    weekly_submissions1: 0,
    final_submission_report: 0,
  });

  // console.log(weekly_submissions, "hello world");

  // console.log(reportRating, "reportRating");
  // console.log(final_submission_report, "final_submission_report");

  // useEffect(() => {
  //   if (!final_submission_report) {
  //     setReportRating({
  //       ...reportRating,
  //       final_submission_report: 0,
  //     });
  //   }

  //   for (let x in weekly_submissions) {
  //     if (!weekly_submissions[x]?.report) {
  //       setReportRating({
  //         ...reportRating,
  //         weekly_submissions1: 0,
  //       });
  //       break;
  //     }
  //   }

  //   if (final_submission_report) {
  //     const weeksToAdd = project_date;
  //     const final_submission = moment(
  //       final_submission_report?.final_submission_report_date
  //     )
  //       .tz("Asia/Kolkata")
  //       .format("DD/MM/YYYY");

  //     const submissionDateDeadline = new Date(enrollment_date);

  //     submissionDateDeadline.setDate(
  //       submissionDateDeadline.getDate() + weeksToAdd * 7
  //     );

  //     const sd = moment(submissionDateDeadline)
  //       .tz("Asia/Kolkata")
  //       .format("DD/MM/YYYY");
  //     const diff = moment(sd, "DD/MM/YYYY").diff(
  //       moment(final_submission, "DD/MM/YYYY"),
  //       "days"
  //     );

  //     console.log(diff, "diff");

  //     if (diff >= 0) {
  //       setReportRating({
  //         ...reportRating,
  //         final_submission_report: 3,
  //       });
  //     } else {
  //       setReportRating({
  //         ...reportRating,
  //         final_submission_report: 2,
  //       });
  //     }
  //   }
  //   for (let x in weekly_submissions) {
  //     if (weekly_submissions[x]?.report) {
  //       const submissionDateDeadline = new Date(enrollment_date);
  //       const weeksTOAdd = weekly_submissions[x].week;
  //       submissionDateDeadline.setDate(
  //         submissionDateDeadline.getDate() + weeksTOAdd * 7
  //       );

  //       const weekReportDeadline = moment(
  //         weekly_submissions[x]?.weekly_report_date
  //       )
  //         .tz("Asia/Kolkata")
  //         .format("DD/MM/YYYY");

  //       console.log(weekReportDeadline, "weekReportDeadline");

  //       const sd = moment(submissionDateDeadline)
  //         .tz("Asia/Kolkata")
  //         .format("DD/MM/YYYY");

  //       const diff = moment(sd, "DD/MM/YYYY").diff(
  //         moment(weekReportDeadline, "DD/MM/YYYY"),
  //         "days"
  //       );

  //       if (diff >= 0) {
  //         setReportRating({
  //           ...reportRating,
  //           weekly_submissions1: 2,
  //         });
  //       } else {
  //         setReportRating({
  //           ...reportRating,
  //           weekly_submissions1: 1,
  //         });
  //       }
  //     } else {
  //       setReportRating({
  //         ...reportRating,
  //         weekly_submissions1: 0,
  //       });
  //     }
  //   }
  //   console.log(reportRating, "reportRating")
  //   const newRating5 =
  //     reportRating.weekly_submissions1 + reportRating.final_submission_report;

  //   setRating5(newRating5);
  // }, [reportRating.final_submission_report, reportRating.weekly_submissions1]);

  // setRating5(reportRating.weekly_submissions1 + reportRating.final_submission_report);

  // useEffect(() => {}, []);

  useEffect(() => {
    setRating5(
      reportRating.weekly_submissions1 + reportRating.final_submission_report
    );
  }, [reportRating]);

  useEffect(() => {
    setReportRating((prevReportRating) => {
      let updatedReportRating = { ...prevReportRating };

      if (!final_submission_report) {
        updatedReportRating.final_submission_report = 0;
      }

      for (let x in weekly_submissions) {
        if (!weekly_submissions[x]?.report) {
          updatedReportRating.weekly_submissions1 = 0;
          break;
        }
      }

      if (final_submission_report) {
        const weeksToAdd = project_date;
        const final_submission = moment(
          final_submission_report?.final_submission_report_date
        )
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY");

        const submissionDateDeadline = new Date(enrollment_date);

        submissionDateDeadline.setDate(
          submissionDateDeadline.getDate() + weeksToAdd * 7
        );

        const sd = moment(submissionDateDeadline)
          .tz("Asia/Kolkata")
          .format("DD/MM/YYYY");
        const diff = moment(sd, "DD/MM/YYYY").diff(
          moment(final_submission, "DD/MM/YYYY"),
          "days"
        );

        if (diff >= 0) {
          updatedReportRating.final_submission_report = 3;
        } else {
          updatedReportRating.final_submission_report = 2;
        }
      }

      for (let x in weekly_submissions) {
        if (weekly_submissions[x]?.report) {
          const submissionDateDeadline = new Date(enrollment_date);
          const weeksTOAdd = weekly_submissions[x].week;
          submissionDateDeadline.setDate(
            submissionDateDeadline.getDate() + weeksTOAdd * 7
          );

          const weekReportDeadline = moment(
            weekly_submissions[x]?.weekly_report_date
          )
            .tz("Asia/Kolkata")
            .format("DD/MM/YYYY");

          const sd = moment(submissionDateDeadline)
            .tz("Asia/Kolkata")
            .format("DD/MM/YYYY");

          const diff = moment(sd, "DD/MM/YYYY").diff(
            moment(weekReportDeadline, "DD/MM/YYYY"),
            "days"
          );

          if (diff >= 0) {
            updatedReportRating.weekly_submissions1 = 2;
          } else {
            updatedReportRating.weekly_submissions1 = 1;
          }
        } else {
          updatedReportRating.weekly_submissions1 = 0;
        }
      }

      return updatedReportRating;
    });
  }, [
    final_submission_report,
    weekly_submissions,
    enrollment_date,
    project_date,
  ]);

  return (
    <div className="projectReportTable">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 20%)</small>
      </h4>
      <table>
        <tr>
          <th className="table_title">{subTitle}</th>
          <th>Max Rating</th>
          <th>System generated rating</th>
        </tr>
        <tr>
          <td className="table_title">{text1}</td>
          <td>05</td>
          <td>0{rating5}</td>
        </tr>

        <tr>
          <td className="table_title">Maximum (5)</td>
          <td></td>
          <td>
            <h5>Score: {rating5}</h5>
            <p>weighted Score: {(rating5 * 0.2).toFixed(2)}</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ProjectReportTableCheckbox3;
