import React from 'react'
import "./addMore.scss"

const AddMore = ({onClick}) => {
 
    //onClick={() => setShowDocuments(true)}
  return (
    <span className="add_more btn-link" onClick={onClick} >
    Add more {"  "}
    <i className="fa fa-plus ml-2"></i>
  </span>
  )
}

export default AddMore