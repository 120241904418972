import { useState } from "react"
import "./accordian.scss"

const Accordian = ({
  children = <></>,
  title = "",
  defaultOpen = false,
  Component = <></>,
  className = "",
}) => {
  const [show, setShow] = useState(() => defaultOpen)

  return (
    <div className={`accordian_card mt-3  accordian ${className}`}>
    <div
      className="card-header d-flex accordian_card_header"
      onClick={() => setShow((p) => !p)}
    >
      <h2 className="mb-0">
        <button className="btn p-0 accordian_title" type="button">
          {title}
        </button>
      </h2>
      <div className="student_accordian">
        {Component}
        <i
          className={`fa-sharp fa-solid fa-caret-down dropdown_icon ${
            show ? "rotate" : ""
          }`}
        ></i>
      </div>
    </div>

    <div className={`card-body ${show ? "show" : "collapse"}`}>
      {children}
    </div>
  </div>
  )
}

export default Accordian
