import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css/bundle';

import p1 from '../../images/brand/college-01.png'
import p2 from '../../images/brand/college-02.png'
import p3 from '../../images/brand/college-03.png'
import p4 from '../../images/brand/college-04.png'
import p5 from '../../images/brand/college-05.png'
import p6 from '../../images/brand/college-06.png'
import p7 from '../../images/brand/college-07.png'
import p8 from '../../images/brand/college-08.png'
import p9 from '../../images/brand/college-09.png'
import p10 from '../../images/brand/college-10.png'
import p11 from '../../images/brand/college-11.png'
import p12 from '../../images/brand/college-12.png'
import p13 from '../../images/brand/college-13.png'
import p14 from '../../images/brand/college-14.png'
import p15 from '../../images/brand/college-15.png'
import p16 from '../../images/brand/college-16.png'
import p17 from '../../images/brand/college-17.png'
import p18 from '../../images/brand/college-18.png'
import p19 from '../../images/brand/college-19.png'
import p20 from '../../images/brand/college-20.png'
import p21 from '../../images/brand/college-21.png'
import p22 from '../../images/brand/college-22.png'
import p23 from '../../images/brand/college-23.png'
import p24 from '../../images/brand/college-24.png'
import p25 from '../../images/brand/college-25.png'
import p26 from '../../images/brand/college-26.png'
import p27 from '../../images/brand/college-27.png'
import p28 from '../../images/brand/college-28.png'
import p29 from '../../images/brand/college-29.png'
import p30 from '../../images/brand/college-30.png'
import p31 from '../../images/brand/college-31.png'








import partner from '../../images/partner.png'
import './partner.scss'





const CollegePartnerSection = () => {
    const partnerImages = [
        p1,
        p2,
        p3,
        p4,p5,
        p6,
        p7,
        p8,
        p9,p10,
        p11,
        p12,p13,p14,p15,p16,
        p17,p18,p19,p20,p21,p22,p23,p24,p25,p26,p27,p28,p29,p30,p31,
        
    ];

    return (
        <div className="patner-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="partner-box mb-30">
                            <div className="partner-thumb d-none d-sm-block">
                                <img src={partner} alt="partner-png" />
                            </div>
                            <div className="section-title mb-30 text-center">
                                <h2>Our <span className="down-mark-line-2">Academic</span> Partners
                                </h2>
                            </div>
                            <div className="Partner-content text-center">
                                <p>Global partnerships for global industry exposure</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={2}
                            slidesPerView={8} 
                            loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 3, 
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 4,
                                },
                                992: {
                                    slidesPerView: 5,
                                },
                                1200: {
                                    slidesPerView: 6,
                                },
                                1400: {
                                    slidesPerView: 7,
                                },
                                1600: {
                                    slidesPerView: 8,
                                },
                            }}
                            pagination={{
                                clickable: true,
                                el: '.partner-pagination',
                            }}
                           
                       
                            autoplay={{
                                delay: 1000,
                                disableOnInteraction: true
                            }}
                        >
                            {partnerImages.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className='partner-card'>
                                    <img width={110}  src={image} alt={`not-found`} className='partner-image' style={{background:'#fff'}} />
                                    </div>
                                   
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        
                    
                        {/* <div className="partner-pagination text-center pt-2 paginationhide"></div> */}
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollegePartnerSection;