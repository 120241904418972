import React from 'react'
import "./shareModel.scss"
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
const ShareButtonModel = ({isActive = false, data, type}) => {

    const projectMsg = `
      Exciting Project Opportunity Alert!   

     **Project Title**: ${data?.project_title}
     **Company**: ${data?.project_global_fkey?.employer_account?.company?.company_name}
     **Duration**: ${data?.duration_from} - ${data?.duration_to}
     **Mode**: ${data?.office_visit ? "Office" : "Remote"}

     Apply Now on Qollabb! 
     http://localhost:3000/project/${data?.id}

    Join Qollabb today to explore this opportunity and discover more exclusive projects tailored just for you! Don't miss out!
    https://qollabb.com
    `

    const internshipMsg = `
      Exciting Internship Opportunity Alert!   

     **Internship Title**: ${data?.internship_title}
     **Company**: ${data?.global?.employer_account?.company?.company_name}
     **Duration**: ${data?.duration_from} - ${data?.duration_to}
     **Mode**: ${data?.office_visit ? "Office" : "Remote"}
     **Stipend**: ${data?.stipend_from} - ${data?.stipend_to} /month
    
     Apply Now on Qollabb! 
     http://localhost:3000/internship/${data?.id}

    Join Qollabb today to explore this opportunity and discover more exclusive Internships tailored just for you! Don't miss out!
    https://qollabb.com
    `

    const jobMsg = `
      Exciting Job Opportunity Alert!   

     **Project Title**: ${data?.job_title}
     **Company**: ${data?.global_job?.employer_account?.company?.company_name}
     **Mode**: ${data?.office_visit ? "Office" : "Remote"}
     **Stipend**: ${data?.ctc_from} - ${data?.ctc_to} /year

     Apply Now on Qollabb! 
     https://qollabb.com/job/${data?.id}

    Join Qollabb today to explore this opportunity and discover more exclusive Jobs tailored just for you! Don't miss out!
    https://qollabb.com
    `

    const shareWhatsapp =async ()=> {
      const msgToSend = type === "project" ? projectMsg : type === "internship" ? internshipMsg : jobMsg;
        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(msgToSend)}`;
        window.open(whatsappLink, '_blank');
    }


    const facebookshare = () => {

        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(projectMsg)}`;
        window.open(facebookShareUrl, '_blank');
      // } else if (platform === 'linkedin') {
      //   const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(message)}`;
      //   window.open(linkedinShareUrl, '_blank');
      // }
    };
    const linkedinShare = () => {

        const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(projectMsg)}`;
        window.open(linkedinShareUrl, '_blank');
    };


    // console.log("dataaa", data[`${type}_title`]);
  

  return (
    <div  className={`share-button-model model-drop ${isActive ? "active" : ""}`}>
        <div className="triangle"></div> {/* Add the triangle element */}
      <div className="dropdown">
        <ul>

          <li >
          <FacebookShareButton url={`https://qollabb.com/${type}/${data[`${type}_title`]?.replace(/[\s#/]+/g, '-').toLowerCase()}/${data?.id}`}  title={`Exciting ${type} Opportunity Alert!`}  >
            <FacebookIcon size={25} round color='#5138c3' />
          </FacebookShareButton>
          {/* <FaFacebook color='#5138c3' size={20} /> */}
          </li>

          <li>

          <LinkedinShareButton url={`https://qollabb.com/${type}/${data[`${type}_title`]?.replace(/[\s#/]+/g, '-').toLowerCase()}/${data?.id}`} title={`Exciting ${type} Opportunity Alert!`} className="Demo__some-network__share-button">
            <LinkedinIcon size={25} round color='#5138c3' />
          </LinkedinShareButton>
          {/* <FaLinkedin color='#5138c3' size={20} /> */}
          </li>

        {/* <Link to=""> */}
          <li >
          <WhatsappShareButton
          url={`https://qollabb.com/${type}/${data[`${type}_title`]?.replace(/[\s#/]+/g, '-').toLowerCase()}/${data?.id}`}
          title={`Exciting ${type} Opportunity Alert!`}
          separator=":: "
        >
          <WhatsappIcon size={25} round  color='#5138c3'  />
        </WhatsappShareButton>
          {/* <FaSquareWhatsapp  color='#5138c3' size={20} /> */}
          </li>
        {/* </Link> */}
        </ul>
      </div>
        {/* } */}
    </div>
  )
}

export default ShareButtonModel
/* .replace(/[\s#/]+/g, '-').toLowerCase() */