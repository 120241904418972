
import axios from "axios";
import React, { useEffect, useState } from "react";
import Subscription from "../components/subscription/SubscriptionEducator";
import "./subscription.scss";

const EducatorSubscriptions = () => {
  const [allData, setAllData] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      try {

        const data = await axios.get('/v1/subscription/get_subscriptions');

        setAllData(data)


      } catch (error) {

      }
    }

    fetchData()
  }, [])
  // console.log(allData?.data?.data);

  const Beginner = allData?.data?.data.find(item => item.id === 4)
  const Intermediate = allData?.data?.data.find(item => item.id === 5)
  const Expert = allData?.data?.data.find(item => item.id === 6)


  return (
    <div className="subscription">
      <Subscription Beginner={Beginner} Intermediate={Intermediate} Expert={Expert} />
    </div>
  )
}

export default EducatorSubscriptions
