import "styles/employer/mentorjob.scss";
import "styles/employer/jobContent.scss";
import Freelancerimg from "images/Freelancer.png";
import { useParams, useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";
import Loader from "./Loader";
import { duplicatePost } from "./duplicatePost";
import { useDispatch } from "react-redux";
import MiniStudentApplication from "components/cards/MiniStudentResume";
import StudentJobDescription from "components/cards/StudentJobDescription";
import { useState } from "react";

const PostContent = ({ method = "project" }) => {
  const { id = 5 } = useParams();
  const { data, loading } = useFetch(`/v1/enrollments/${method}/${id}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  if (loading) return <Loader />;

  const onDuplicate = () => {
    duplicatePost({ data: data?.data?.post, method, dispatch });
    navigate(`/employer/${method}/add`);
  };

  const handleDetails = () => {
    navigate(`/employer/${method}/details/${id}`);
  };

  return (
    <section className="job-content">
      <div className="container-lg container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content-creation">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h2>{data?.data?.[`${method}_title`]}</h2>
                  <p className="light">{method}</p>
                  <p className="txt5">{data?.data?.[`${method}_goal`]}</p>
                </div>
                <div className="col-1 non"></div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="flex">
                    <div className="box1">
                      <p>Start Date</p>
                      <h3>{data?.data?.duration_from}</h3>
                      <p className="light">Aug</p>
                    </div>
                    <div className="box1">
                      <p>Stipend</p>
                      <h3>{data?.data?.stipend_from}</h3>
                      <p className="light">INR/Month</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="btn-boxs">
                    <button onClick={handleDetails}>
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                    <button type="button" onClick={onDuplicate}>
                      Duplicate<i className="fa-regular fa-copy"></i>
                    </button>
                    <button className="close">
                      Close Opening <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="job-tabs">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active">
                    Applications received
                    <span> ({data?.data?.count?.applied || 0}) </span>
                  </button>
                  <button className="nav-link">
                    New Applicants{" "}
                    <span> ({data?.data?.count?.applied || 0}) </span>
                  </button>
                  <button className="nav-link">
                    Shortlisted{" "}
                    <span> ({data?.data?.count?.shortlisted || 0}) </span>{" "}
                  </button>
                  <button className="nav-link">
                    Hired <span> ({data?.data?.count?.hired || 0}) </span>{" "}
                  </button>
                  <button className="nav-link">
                    Not interested <span> (22) </span>{" "}
                  </button>
                  <button className="nav-link">
                    Assignments <span> (22) </span>{" "}
                  </button>
                  <button className="nav-link">
                    Interviews <span> (22) </span>{" "}
                  </button>
                </div>
              </nav>
              {data?.data?.enrollments?.length ? (
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active">
                    <div className="content-tab">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="searchbox">
                            <label>
                              {" "}
                              <input
                                type="text"
                                placeholder="Search candidate"
                              />
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </label>
                          </div>
                          {data?.data?.enrollments?.map((item, index) => {
                            return (
                              <MiniStudentApplication
                                key={index}
                                item={item}
                                onClick={() => setSelected(index)}
                              />
                            );
                          })}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <StudentJobDescription
                            data={data?.data?.enrollments[selected]}
                            post={data?.data?.post}
                          />
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 mg">
                          <div className="row">
                            <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                              <div className="job-filter">
                                <label htmlFor="city">City</label>
                                <input
                                  type="Search"
                                  id="city"
                                  list="search-data"
                                  placeholder="Search City"
                                />
                                <datalist id="search-data">
                                  <option value="Delhi"></option>
                                  <option value="Noida"></option>
                                </datalist>
                                <label htmlFor="skill">Skills</label>
                                <input
                                  type="Search"
                                  id="skill"
                                  list="skills-data"
                                  placeholder="Search Skills"
                                />
                                <datalist id="skills-data">
                                  <option value="html"></option>
                                  <option value="css"></option>
                                </datalist>

                                <div className="spanbox">
                                  <span>
                                    IT <i className="fa-solid fa-xmark"></i>
                                  </span>
                                  <span>
                                    IT Students{" "}
                                    <i className="fa-solid fa-xmark"></i>
                                  </span>
                                  <span>
                                    React <i className="fa-solid fa-xmark"></i>
                                  </span>
                                  <span>
                                    B.Tech <i className="fa-solid fa-xmark"></i>
                                  </span>
                                </div>
                                <label htmlFor="qualification">
                                  Qualification
                                </label>
                                <input
                                  type="text"
                                  id="qualification"
                                  placeholder="Search qualification"
                                />
                                <button className="clear">Clear</button>
                                <button>Apply</button>
                              </div>
                            </div>
                            <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                              <div className="new-post">
                                <div className="flex">
                                  <div>
                                    <h3>Post New </h3>
                                    <h4>Internship</h4>
                                  </div>
                                  <div>
                                    {" "}
                                    <i className="fa-solid fa-right-long"></i>
                                  </div>
                                </div>
                                <img src={Freelancerimg} alt="Image" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostContent;
