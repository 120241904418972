import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "styles/employer/add-project.scss";
import AiTextField from "../AiTextField";
import axios from "axios";
const AiGenerator = ({
  isPopupOpen,
  setIsPopupOpen,
  type = "project",
  setShowAiTextPopup,
  setShowAiData,
}) => {
  const [showLoading, setShowLoading] = useState(() => false);
  const industry_name = useSelector(
    (state) => state?.auth?.user?.industry?.industry_name
  );
  const { register, setValue, getValues } = useForm();
  const [generatedData, setGeneratedData] = useState("");

  const headings = ["Project Title:", "Project Objectives:", "Student Tasks:"];

  const splitSentences = generatedData.split(
    new RegExp(headings.join("|"), "g")
  );

  // // console.log(splitSentences, "splitSentences");
  useEffect(() => {
    if (generatedData) {
      if (type === "project") {
        const headings = [
          "Project Title:",
          "Project Objectives:",
          "Student Tasks:",
        ];

        let result = generatedData.split(new RegExp(headings.join("|"), "g"));

        result = result.map((sentence) => sentence.replace(/\*/g, ""));
          // Check if the result array length is 1, meaning all data is in result[0]


          //  when array length is one
          if (result.length === 1) {
            // Split the single result into separate parts using headings
            const splitResult = generatedData.split(new RegExp(headings.join("|"), "gi")).map(section => section.trim());
            // console.log("splitResult",splitResult)
            result = [
                undefined, // To maintain the same structure as original indexing
                splitResult[1],
                splitResult[2],
                splitResult[3],
            ];
        }


     
        // console.log(generatedData, "generatedData");
        // console.log(result, "message");
        const specific = {};
        specific["project_title"] = result[1]?.trim();
        specific["project_goal"] = result[2]?.trim();
        specific["project_tasks"] = result[3]?.trim();
        setShowAiData(specific);
        // console.log(specific, "specific");
      } else {
        // // console.log(generatedData, "generatedData");
        const headings = ["Responsibilities:"];
        let result = generatedData.split(new RegExp(headings.join("|"), "g"));
        result = result.map((sentence) => sentence.replace(/\*/g, ""));
        // // console.log(generatedData, "generatedData");
        // // console.log(result, "message");
        const specific = {};
        specific[`${type}_title`] = getValues(`${type}Title`);
        specific["roles_and_responsibilities"] = result[1]?.trim();
        setShowAiData(specific);

        // // console.log(specific, "specific");
        // dispatch(addApplicationData({ method: type, step: 2, data: specific }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedData]);

  //      Please do not add any other symbol like '#' or '/n' at the end of project title.
  const handleGenerate = async (data = []) => {
    setShowLoading(true);
    // console.log(data, "data");


    try {
      const response = await axios.post("/v1/employer/createByAi", {
       data
      })

      // console.log("response",response?.data?.data?.data)
   
        setGeneratedData(response?.data?.data?.data);
        setShowLoading(false);
        setIsPopupOpen(false);
        setShowAiTextPopup(true);
        toast.success(`AI generated data Successfully for ${type} application`);


    } catch (error) {
      console.error("Error fetching answer:", error)
    } finally {
      setShowLoading(false)
      
    }







    // let aiPromt = "";
    // if (type === "project") {
    //   aiPromt = `please write a project for ${data[0]} students on ${data[1]} based on the ${industry_name}.
    //   The headings should be Project title, project objectives and student tasks.
    //   The project should not be of more than 200 words. 
    //   Please do not add any note or anything else other
    //   than these three headings.
    //   `;
    // } else {
    //   aiPromt = `Write a ${data[0]} job description based on the ${industry_name}. Include only responsibilities
    //    and requirements headings and nothing else. 
    //   keep it within 200 words.`;
    // }

    // const options = {
    //   method: "POST",
    //   headers: {
    //     accept: "application/json",
    //     "content-type": "application/json",
    //     "X-API-KEY": "cb0a5539-1794-40d4-a19f-d0f26ea0dd22",
    //   },
    //   body: JSON.stringify({
    //     enable_google_results: false,
    //     enable_memory: false,
    //     input_text: aiPromt,
    //   }),
    // };
    // fetch(
    //   "https://api.writesonic.com/v2/business/content/chatsonic?engine=premium&language=en",
    //   options
    // )
    //   .then((response) => response.json())
    //   .then((response) => {
    //     // console.log(response.messsage, { type });
    //     setGeneratedData(response.message);
    //     setShowLoading(false);
    //     setIsPopupOpen(false);
    //     setShowAiTextPopup(true);
    //     toast.success(`AI generated data Successfully for ${type} application`);
    //   })
    //   .catch((err) => console.error(err));

      
  };

  // console.log("generated", generatedData);
  return (
    isPopupOpen &&
    (() => {
      switch (type) {
        case "project":
          return (
            <div className="popup_container d-flex flex-column pointer p-4">
              <div className="no_projects d-flex flex-column col-12">
                <i
                  class="fa-solid fa-robot fa-xl mt-2"
                  style={{
                    color: "#6149cd",
                  }}
                ></i>
                <h6 className="mt-3 font-weight-bold">
                  Let us know the following
                </h6>
                <label className="my-3">
                  <AiTextField
                    id="courseName"
                    label="Course Name"
                    placeholder="B.Tech"
                    setValue={setValue}
                    register={register}
                    name="courseName"
                    disabled={showLoading}
                  />
                  <AiTextField
                    id="interest"
                    label="Interest Area"
                    placeholder="Artificial Intelligence"
                    setValue={setValue}
                    register={register}
                    name="interest"
                    disabled={showLoading}
                  />
                  {/* underline  */}

                  {showLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#6149cd",
                        width: "100%",
                        borderRadius: "15",
                        padding: "13px",
                      }}
                      onClick={() =>
                        handleGenerate([
                          getValues("courseName"),
                          getValues("interest"),
                          industry_name,
                          "project"
                        ])
                      }
                    >
                      Generate
                    </button>
                  )}
                </label>
              </div>
              <i
                className="close-btn fa-regular fa-times fa-x"
                onClick={() => setIsPopupOpen(false)}
              ></i>
            </div>
          );
        case "internship":
          return (
            <div className="popup_container d-flex flex-column pointer p-4">
              <div className="no_projects d-flex flex-column col-12">
                <i
                  class="fa-solid fa-robot fa-xl"
                  style={{
                    color: "#6149cd",
                  }}
                ></i>
                <h6 className="mt-3 font-weight-bold">
                  Let us know the following
                </h6>
                <label className="my-3">
                  {/* <h5>No {type} to link</h5> */}
                  <AiTextField
                    label={"Internship Title"}
                    placeholder={"Ai developer"}
                    id={"internshipTitle"}
                    setValue={setValue}
                    register={register}
                    disabled={showLoading}
                  />
                  {/* underline  */}

                  {showLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#6149cd",
                        width: "100%",
                        borderRadius: "15",
                        padding: "13px",
                      }}
                      onClick={() =>
                        handleGenerate([getValues("internshipTitle"),industry_name])
                      }
                    >
                      Generate
                    </button>
                  )}
                </label>
              </div>
              <i
                className="close-btn fa-regular fa-times fa-x"
                onClick={() => setIsPopupOpen(false)}
              ></i>
            </div>
          );
        case "job":
          return (
            <div className="popup_container d-flex flex-column pointer p-4">
              <div className="no_projects d-flex flex-column col-12">
                <i
                  class="fa-solid fa-robot fa-xl"
                  style={{
                    color: "#6149cd",
                  }}
                ></i>
                <h6 className="mt-3 font-weight-bold">
                  Let us know the following
                </h6>
                <label className="my-3">
                  {/* <h5>No {type} to link</h5> */}
                  <AiTextField
                    id={"jobTitle"}
                    setValue={setValue}
                    register={register}
                    label={"Job Title"}
                    placeholder={"Fullstack"}
                    disabled={showLoading}
                  />
                  {/* underline  */}

                  {showLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#6149cd",
                        width: "100%",
                        borderRadius: "15",
                        padding: "13px",
                      }}
                      onClick={() => handleGenerate([getValues("jobTitle"),industry_name])}
                    >
                      Generate
                    </button>
                  )}
                </label>
              </div>
              <i
                className="close-btn fa-regular fa-times fa-x"
                onClick={() => setIsPopupOpen(false)}
              ></i>
            </div>
          );
        default:
          return null;
      }
    })()
  );
};

export default AiGenerator;
