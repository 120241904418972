import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import helpimg from "./helpsvg2.jpg"

const HelpDesk = () => {
  const [query, setQuery] = useState("")
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
  }

  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault()
    if (query === "") {
      toast.error("Please enter a query before submitting the form")
      return
    }
    const requestBody = JSON.stringify({ query: query })

    axios
      .post("/v1/helpcenter/add-query", requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        setQuery("")
        setRefresh(false)
        setLoading(false)
        toast.success("Concern ticket raised")
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        toast.error("Failed to raised concern ticket")
      })
   
  }
  // // console.log(queryValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/v1/helpcenter/getMyQueries")
        // console.log(response,"res data");
        setData(response?.data?.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [query, refresh,loading])

  // console.log("dataaa", data)
  return (
    <section className="student_setting container">
      <div className="setting" style={{ background: "#fff" }}>
        <div className="row wrap-uper">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <h2>Qollabb Helpdesk</h2>
            <p>
              Hi there! Let’s know how can we help you. Please checkout the FAQs
              and if it can’t resolve your issue, then chat with us or raise a
              support ticket.
            </p>
            <div className="row">
              <div className="col-12">
                <form onSubmit={handleSubmit} className="comment">
                  <p className="comment-text">Your Concern*</p>
                  <textarea
                    type="text"
                    value={query}
                    onChange={handleQueryChange}
                  />
                  <button type="submit">
                    {loading ? (
                      <i className="text-white fa fa-circle-o-notch fa-spin"></i>
                    ) : (
                      "Raise a Ticket"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="man-image">
              <img src={helpimg} width={431} height={337} alt="Image" />
            </div>
          </div>
        </div>
      </div>

      {data?.length > 0 ? (
        <div className="previous-ticket" style={{ background: "#fff" }}>
          <h2>Previous Tickets</h2>
          <div className="row">
            {data?.map((item) => {
              return (
                <div
                  div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                >
                  <div className="ticket-info">
                    <div className="row">
                      <div className="col-6">
                        <p className="ticket-id">Ticket ID.</p>
                        <h3>{item?.id}</h3>
                      </div>
                      {!item?.comment ? (
                        <div className="col-6">
                          <button className="op-btn">Open</button>
                        </div>
                      ) : (
                        <div className="col-6">
                          <button>Closed</button>
                        </div>
                      )}
                      <p className="ticket-detail" style={{ fontSize: "17px" }}>
                        {item?.query}
                      </p>
                      <p
                        className="ticket-detail text-success"
                        style={{ fontSize: "20px" }}
                      >
                        {item?.comment}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  )
}

export default HelpDesk
