import React, { useState } from "react"
import AboutSection from "./aboutSection"
import "./aboutSection.scss"
import companyJimg from "../../../../images/companyJimg.png"
import collegeJimg from "../../../../images/collegeJimg.png"
import studentJimg from "../../../../images/studentJimg.jpg"

const HomeJourney = ({ Simg }) => {
  const [step, setStep] = useState("1")
  return (
    <div className="home_journey d-flex flex-column align-items-center">
      <h2>
        How to <span className="down-mark-line"> Start</span> Your Journey
      </h2>
      <div className="menu_item ">
        <div className="menu d-flex align-items-center justify-content-center ">
          <p
            style={{ borderBottom: step === "1" && "5px solid #2467EC" ,color: step==="1" && "#141517",cursor: "pointer" }}
            onClick={() => setStep("1")}
          >
            For Student{" "}
          </p>
          <p
            style={{ borderBottom: step === "2" && "5px solid #2467EC", color: step==="2" && "#141517" ,cursor: "pointer"}}
            onClick={() => setStep("2")}
          >
            For Companies{" "}
          </p>
          <p
            style={{ borderBottom: step === "3" && "5px solid #2467EC" ,color: step==="3" && "#141517",cursor: "pointer",wordBreak:'break-word'}}
            onClick={() => setStep("3")}
          >
            For Colleges & Universities{" "}
          </p>
        </div>
        {step === "1" && (
          <AboutSection
            Simg={studentJimg}
            title1="01. Create a Profile"
            subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
            title2="02. Explore and Work On Opportunities"
            subTitle2="Search, select and join from over hundreds of real industry projects OR simply apply for an internship or a job posted by employers."
            title3="03. Get Certified & Get Noticed By Employers"
            subTitle3="Submit your project work to showcase your capabilities and impress top employers, get noticed. Complete all project milestones, get your project work evaluated by senior experts & earn valuable certification."
          />
        )}
        {step === "2" && (
          <AboutSection
            Simg={companyJimg}
            title1="01. Create a Profile"
            subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
            title2="02. Drive Innovation & Build Bright Ideas"
            subTitle2="Outsource small projects to young bright students & allow them to comeback with innovative ideas & solutions for your challenges. Connect & collaborate with their colleges/universities to build a sustained pool of brightest candidates."
            title3="03. Simplify Hiring The Brightest"
            subTitle3="Hire only the graduates who have already contributed with their bright ideas & showcased their capabilities."
          />
        )}
        {step === "3" && (
          <AboutSection
            Simg={collegeJimg}
            title1="01. Create a Profile"
            subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
            title2="02. Connect Your Students With Real Opportunities"
            subTitle2="Assign industry project work, internships & jobs to your students and keep a track of their progress."
            subTitle3="Explore collaboration possibilities with industry directly assign industry projects to your students or faculty & build your industry network. "
            title3="03. Build Collaborations With Companies"
          />
        )}
      </div>
    </div>
  )
}

export default HomeJourney
