import axios from "axios";
import Subscription from "components/student/Subscription";
import React, { useEffect, useState } from "react";
import "./subscription.scss";

const StudentSubscriptions = () => {
  const [allData, setAllData] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      try {

        const data = await axios.get('/v1/subscription/get_subscriptions');

        setAllData(data)
      } catch (error) {

      }
    }

    fetchData()
  }, [])
  // console.log(allData?.data?.data);

  const Beginner = allData?.data?.data.find(item => item.id === 7)
  const Intermediate = allData?.data?.data.find(item => item.id === 8)
  const Expert = allData?.data?.data.find(item => item.id === 9)


  return (
    <div className="subscription">
      <Subscription Beginner={Beginner} Intermediate={Intermediate} Expert={Expert} />
    </div>
  )
}

export default StudentSubscriptions
