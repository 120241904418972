import React from "react"
import styles from "styles/confirmModal.module.scss"
import img from "./delete.png"
import TextField from "components/formComponents/TextField"
import TextInput from "components/formComponents/TextInput"
//onClick={onCancel}
const ConfirmModal = ({ onConfirm, onCancel, title = "", message = "", setDeleteAccount }) => {
  
  return (
    <div className={styles.modal_container}>
      <div className={styles.modal} role="dialog">
        <div className={styles.modal_content} role="document">
          <div className={styles.modal_header}>

            <img src={img} alt="Image" />
            <h5 className={styles.modal_title} id="confirmModalLabel">
              {/* {title} */}
              Confirm Deletion
            </h5>
            <div className={styles.modal_body}>
              {/* {message} */}
              To delete your account, type <b className="text-danger">delete</b> in <br></br>the given text box below
            </div>

            <input className={styles.modal_input} type="text" onChange={(e)=>setDeleteAccount(e.target.value)} />
            
          </div>
          
          <div className={styles.modal_footer}>
            <button
              type="button"
              className={styles.close_btn}
              data-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className={styles.delete_btn}
              onClick={onConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
