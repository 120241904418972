import React from 'react'
import image from "./circle.svg"

const PaymentSuccessModel = ({handleClose}) => {
  return (
      <div  style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
          <div  style={{
              background: "white",
              padding: "20px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "500px",
              height: "fit-content",
          }}>
                <i
                onClick={handleClose}
                class="fa fa-times fs-3 text-black fw-bold d-flex justify-content-end w-100 "
                aria-hidden="true"
              ></i>

              <div className="w-100 d-flex flex-column align-items-center">
                  <img src={image} width={80} alt="Image" className="mb-4" />
                  <h3 className="fw-bold fs-4">Purchase Successfull</h3>
                  <span className="fs-5 my-2">the Details are below</span> 
                  <span className="mb-2">
                      Start Date: 17th July 2022

                  </span>
                  <p>Check your mail for further details</p>
              </div>
              
          </div>
          
    </div>
  )
}

export default PaymentSuccessModel