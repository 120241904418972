import React from "react";
import PreviewField from "components/employer/PreviewField";
// import ProfileIcon from "components/employer/ProfileIcon"
import { useSelector } from "react-redux";
import "./projectDetail.scss";
import ListContainer from "components/employer/company_form/ListContainer";
// import ListItem from "components/employer/company_form/ListItem"
import { defaultValues as categories } from "components/formComponents/CategoryField";
import cmp from "./company.png";
import ListItem from "components/employer/company_form/ListItem";
import { Link, useNavigate } from "react-router-dom";
import PostDetailsSection from "./PostsHelper/PostDetailsSection";

const ProjectDetailsPage = ({
  ExploreButton,
  id,
  data,
  Action,
  children,
  onApply,
  MobileActionButtons,
}) => {
  const {
    website_url,
    year_of_incorporation,
    employee_strength,
    industry,
    company_logo,
    ceo_name,
    first_name,
    last_name,
    designation,
  } = data.project_global_fkey.employer_account;

  const navigate = useNavigate();
  const method = "project";
  // const company = useSelector((state) => state?.application?.company[1]);
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  const employees = {
    0: "1-50",
    1: "51-250",
    2: "251+",
  };
  const category_name = categories?.find(
    (item) => item?.id === map[data.project_category]
  )?.label;

  const path = window.location.pathname.split("/")[1];
  // console.log(path, "path");

  const faqs = JSON.parse(data?.faq);
  // console.log(faqs, "faqs");

  const handleApply = async (id, screening_questions = "") => {
    if (!method || !id) return;
    if (screening_questions) {
      try {
        screening_questions = JSON.parse(screening_questions);
      } catch (e) {
        screening_questions = [];
      }
    }

    navigate(`/student/explore/${method}/${id}/apply`, {
      state: {
        screening_questions,
      },
    });
    // toast.success("Your application has been submitted");
  };

  // console.log(data, "data");

  return (
    <div className="Project_preview_container">
      <div className="preview_box">
        <section className="post_container">
          

          <PostDetailsSection data={data} type={"project"} />



        </section>
        <section className="action_container">
          {children}
          {/* { ExploreButton} */}
          <div className="actions"></div>

          {/* {console.log(Action, "action", typeof Action)} */}
          <div className="actions">{Action}</div>
          <div className="actions ">{MobileActionButtons}</div>
          {/* <div className="company_container">
            <div className="company_header">
              <ListItem
                name={data.branch.company_database.company_name}
                details={data.branch.address}
                image={company_logo}
                isPreview={true}
                icon={<i className="fa-solid fa-location-dot"></i>}
              />
              <div className="company_body">
                <div className="body_item">
                  <h6>Website</h6>
                  <span className="url">{website_url}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Founding Year</h6>
                  <span>{year_of_incorporation}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Name of CEO</h6>
                  <span>{ceo_name}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>No. of Employees</h6>
                  <span>{employees[employee_strength]}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Industry Sector</h6>
                  <span>{industry.industry_name}</span>
                </div>
              </div>
            </div>
            {company?.services?.length ? (
              <div className="service-list">
                <h5 className="list_header">Products & Services</h5>
                <ListContainer Items={company.services} isPreview={true} />
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
