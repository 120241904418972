import React from "react";
import SuggestedCollege from "./sections/SuggestedCollege";
import InvitationsSent from "./sections/invitationsSent/InvitationsSent";
import InvitationRecieved from "./sections/invitationRecieved/InvitationRecieved";
import InvitationAccepted from "./sections/invitationAccepted/InvitationAccepted";
import ListOfPartnerInstitutions from "./sections/listOfPartnerInstitutions/ListOfPartnerInstitutions";

const SelectedTabs = ({
  tab = 0,
  post = {},
  enrollments = {},
  handleStatusChange,
  filteredSuggesteData,
  state,
  setRefresh,
  refresh,
  reduxDispatch,
}) => {
  const getFiltered = (status) => {
    const filtered = enrollments?.data?.filter(
      (item) => item?.status === status
    );
    return {
      count: enrollments?.count,
      data: filtered,
      length: filtered?.length,
    };
  };

  // console.log(filteredSuggesteData, "filteredSuggesteData");

  //<Shortlisted post={post} enrollments={getFiltered(4)} />

  //<Hired post={post} enrollments={getFiltered(6)} />

  //<Rejected post={post} enrollments={getFiltered(7)} />

  switch (tab) {
    case 0:
      return (
        <SuggestedCollege
          post={post}
          enrollments={enrollments}
          handleStatusChange={handleStatusChange}
          state={state}
          filteredSuggesteData={filteredSuggesteData}
          setRefresh={setRefresh}
          reduxDispatch={reduxDispatch}
          refresh={refresh}
        />
      );
    case 1:
      return (
        <InvitationsSent
          setRefresh={setRefresh}
          state={state}
          reduxDispatch={reduxDispatch}
        />
      );
    case 2:
      return (
        <InvitationRecieved
          refresh={refresh}
          setRefresh={setRefresh}
          state={state}
          reduxDispatch={reduxDispatch}
        />
      );
    case 3:
      return <InvitationAccepted state={state} reduxDispatch={reduxDispatch} />;
    // case 4:
    //   return <ListOfPartnerInstitutions/>
    case 5:
      return <div>Assignments</div>;
    case 6:
      return <div>Interviews</div>;
    case 7:
      return <div>List</div>;
    default:
      return <div>Applications</div>;
  }
};

export default SelectedTabs;
