/**
 It takes a string and tries to parse it as JSON. If it can't, it returns false
 * @param data - The data to parse.
 * @returns A function that takes in a data parameter and returns a parsed JSON object.
 */
export const getParsed = (data) => {
  try {
    const output = JSON.parse(data);
    if (!output || !Object.keys(output)?.length) return false;
    return output;
  } catch (_e) {
    return false;
  }
};

/**
 *
 * It tries to get the data from localStorage, if it fails, it returns false
 * @param key - The key of the localStorage item you want to retrieve.
 * @param isOject - if expected value is Object then returned parsed
 * @default isOject = true
 * @returns The value of the key in localStorage.
*/
export const getLocalStorage = (key, isObject = true) => {
  try {
    const data = localStorage.getItem(key) ?? false;
    if (!data) return false;
    if (isObject) return getParsed(data);
    return data;
  } catch (_e) {
    return false;
  }
};

/**
 * It converts an object to a string
 * @param data - The data to be converted to a string.
 * @returns A function that takes in a data parameter and returns a stringified version of the data.
 */
export const convertToString = (data) => {
  try {
    return JSON.stringify(data);
  } catch (_e) {
    return false;
  }
};
