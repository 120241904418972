import { useEffect, useReducer, useState } from "react";
// import Loading from "Loading.jsx"
import "styles/student/explore.scss";
// import Posts from "./Posts.jsx"
// import { reducer, initialState } from "../explore/handleFilters.js"
import { useNavigate } from "react-router-dom";
import left from "../icons/left.svg";
import right from "../icons/right.svg";

import axios from "axios";
import StatusDropdown from "components/StatusDropdwon.jsx";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup.jsx";
import ShortlistInternshipCard from "components/posts/applicationCards/ShortlistInternshipCard.jsx";
import ShortlistJobCard from "components/posts/applicationCards/ShortlistJobCard.jsx";
import ShortlistProjectCard from "components/posts/applicationCards/ShortlistProjectCard.jsx";
import { useLocation } from "react-router-dom";

const initialState = {
  search: "",
  type: "",
  // status: "Shortlisted", // applied and enrolled
  status: "All",
  city: 0,
  city_name: "",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  // console.log({ type, payload });
  switch (type) {
    case "change_value":
      return {
        ...state,
        [payload.name]: payload.value,
      };
    default:
      return state;
  }
};

const StudentApplication = () => {
  const [isPopupOpen, updatePopup] = useState(() => false);
  // const { state: location } = useLocation()
  // const [state, dispatch] = useReducer(reducer, initialState)
  // const [data, setData] = useState(() => [])
  // const [applied] = useState([])
  // const { enrolled = "", id = 0 } = location || {}
  // const [show, setShow] = useState(false)
  // // console.log("qwertyuiop", location?.checked)
  // const [checked, setChecked] = useState(location?.checked)
  // const [internship,setInternship] = useState('')
  // const [job,setJob] = useState('')

  //pagination

  //*********** */
  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();
  const location = useLocation();
  const checked = location?.state?.checked;

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(checked ? checked : "project");
  const [applied, setApplied] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(1);
  const ITEM_PER_PAGE = 10;
  const [total, setTotal] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // // console.log("1", "123456");
    const getActiveProjects = async () => {
      const methods = {
        internship: "applied",
        job: "applied",
        project: "enrolled",
      };

      const { data } = await axios.get(
        `/v1/${selected}/${methods[selected]}?page=${currentOffset}&limit=${ITEM_PER_PAGE}`,
        {
          params: Object.keys(state).reduce((acc, curr) => {
            // // console.log({ curr, state });
            if (state[curr]) {
              return {
                ...acc,
                [curr]: state[curr],
              };
            } else return acc;
          }, {}),
        }
      );
      // // console.log(data?.data, "data123")
      // // console.log(data,"123456")
      setApplied(data?.data?.items || data?.data || []);
      setTotal(data?.data?.pages?.totalPages);
      // // console.log(data?.data?.pages?.totalPages, "123456")
      // // console.log(total, "12345")
      setTotalItems(data?.data?.pages?.totalItems);
      setLoading(false);
      // // console.log("2", "123456");
      // setTotal(data?.pages?.total);
    };
    getActiveProjects();
    // // console.log("3", "123456");
  }, [state, selected, currentOffset]);

  useEffect(() => {
    setCurrentOffset(1);
  }, [selected]);

  // // console.log(total, "12345");
  // // console.log(applied, "12345");
  // useEffect(() => {
  //   // change the total pages
  //   setCurrentOffset(1);
  //   setTotal(

  //   );
  // }, [selected, total, applied]);

  // // console.log(checked, "4321");
  const [selectedType, setSelectedType] = useState(
    checked ? checked : "project"
  );

  useEffect(() => {
    if (checked) {
      setSelectedType(checked);
      setSelected(checked);
    }
  }, []);
  // // console.log(selectedType, "selected123");

  const updateSelected = (e) => {
    setSelectedType(e.target.value);
    setSelected(e.target.value);
  };

  const handleCurrentOffset = (offset) => {
    if (offset < 1 || offset > total) return;
    setCurrentOffset(offset);
  };

  const toggleOn = () => setShow((p) => !p);

  const toggleOFf = () => setShow(() => false);

  const map = {
    0: "project",
    1: "internship",
    2: "job",
  };

  const updateLocation = (_name, value) => {
    dispatch({
      type: "change_value",
      payload: { name: "city", value: value?.id || 0 },
    });
    dispatch({
      type: "change_value",
      payload: { name: "city_name", value: value?.name || "" },
    });
  };

  const updateStatus = (e) => {
    // console.log(e.target.value, "selectedType");
    dispatch({
      type: "change_value",
      payload: { name: "status", value: e.target.value },
    });
  };

  const scheduleMeeting = async (id) => {
    const { data } = await axios.post(`/v1/student/schedule_meeting`);
    // console.log(data);
  };

  // const handleSearch = async () => {
  //   try {
  //     // console.log("state", state);
  //     const filters = Object?.keys(state).reduce((acc, curr) => {
  //       if (curr === "selected") return acc;
  //       else if (state[curr] instanceof Set) {
  //         if (!state[curr].size) return acc;
  //         const set = [...new Set(state[curr])];
  //         return { ...acc, [curr]: set };
  //       } else if (state[curr]) {
  //         return { ...acc, [curr]: state[curr] };
  //       }
  //       return acc;
  //     }, {});

  //     // console.log("filters", filters)
  //     const { data } = await axios.post(`/v1/filter/${map[state.selected]}`, {
  //       ...filters,
  //       // userId: user?.id,
  //       // page: currentPage,
  //       // limit: ITEMS_PER_PAGE,
  //     });
  //     // console.log("pages", data?.data);
  //     if (!data?.error) {
  //       // setData(data?.data?.items || []);
  //       // setTotalPages(data?.data?.pages?.total || 1);
  //       // setTotalItems(data?.data?.pages?.items);
  //       // setLoading(false);
  //       // setCurrentPage(data?.data?.pages?.current)
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };


  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="container">
      {/* <div className={`dropdown_mobile ${show ? "open" : ""} `}> */}
        {/* Top Search Bar  */}
        {/* <div className="search d-none d-md-flex">
          <TopSearch
            dispatch={dispatch}
            handleSearch={handleSearch}
            state={state}
          />
        </div>
      </div> */}
      {applied?.length > 0 ? (
        <div className="flex mb-2 py-4">
          <h2 className="my-1">Applications {totalItems}</h2>
          <div className="align-items-center d-none d-md-flex">
            <img
              // onClick={() => handleCurrentOffset(currentOffset - 1)}
              onClick={() => handleCurrentOffset(currentOffset - 1)}
              src={left}
              width="32"
              alt="Image"
            />
            <p
              className="m-0 px-3"
              style={{ fontSize: "26px", fontWeight: "900" }}
            >
              <strong style={{ color: "black" }}>{currentOffset}</strong>/{" "}
              {total}
            </p>
            <img
              onClick={() => handleCurrentOffset(currentOffset + 1)}
              src={right}
              alt="Image"
              width="32"
            />
          </div>
        </div>
      ) : null}
      <div className="mobile_search d-flex justify-content-between align-items-center gap-2 d-md-none">
        <div className="w-100 search_skills_mobile">
          <input
            className={`form-control search_skills`}
            type="search"
            placeholder="Search by keyword..."
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: "change_value",
                payload: { name: "search", value: e.target.value },
              });
            }}
          />
        </div>

        <div
          className="toggle_box p-2"
          style={{
            backgroundColor: "rgba(97,73,205,0.05)",
            borderRadius: "8px",
          }}
          onClick={toggleOn}
        >
          <span className="filter d-block d-md-none p-1">
            <i
              class="fa-solid fa-sliders fa-lg "
              style={{ color: "#6149CD" }}
            ></i>
          </span>
        </div>
      </div>
      {/* <button type="button" onClick={scheduleMeeting}>
        Hello
      </button> */}
      {/* Filter Toggle Close Button  */}
      <div className="d-flex d-md-none justify-content-between align-items-center gap-2 my-3">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <div className="d-flex justify-content-center align-items-center gap-2">
            {Object.keys(map).map((item, index) => {
              return (
                <button
                  key={index}
                  className={`btn btn-sm ${
                    selected === map[item]
                      ? "btn-primary text-white"
                      : "btn-outline-primary"
                  }`}
                  onClick={()=>{
                    setSelected(map[item])
                    setSelectedType(map[item])
                  }}
                >
                  {capitalize(map[item])}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {show && (
        <div className="mobo_filter w-100 d-block d-md-none">
          <div
            className={`mobile_FilterBtn my-4 d-flex justify-content-between align-items-center gap-2 w-100 d-md-none ${
              show ? "open" : ""
            } `}
          >
            <div className="d-flex justify-content-center align-items-center gap-2 ">
              <h4 className="m-0" style={{ color: "#6149CD" }}>
                Filters
              </h4>
              <span className=" d-block mt-">
                <i
                  class="fa-solid fa-sliders fa-lg "
                  style={{ color: "#6149CD" }}
                ></i>
              </span>
            </div>
            <div className="toggle_box">
              <span className="d-block filter">
                <i
                  className="fa-solid fa-x fa-lg "
                  style={{ color: "#6149CD" }}
                  onClick={toggleOFf}
                ></i>
              </span>
            </div>
          </div>
          <div
            className={`col-md-4 col-lg-4  d-block d-md-none mobile_FilterBox `}
          >
            <div className="radius_normal d-none d-md-flex align-items-center w-100 justify-content-between p-1 border mt-2 mb-4">
              <input
                className={`form-control search_skills`}
                type="search"
                placeholder="Search"
                style={{ width: "85%" }}
                onChange={(e) => {
                  dispatch({
                    type: "change_value",
                    payload: { name: "search", value: e.target.value },
                  });
                }}
              />

              <i
                className="fa-solid fa-magnifying-glass"
                style={{ width: "10%" }}
              ></i>
            </div>
            <div className="filter_box radius_normal border-none border-md p-3 mt-2">
              {/* search City  */}
              <div>
                <label htmlFor="City">City</label>
                <TextFieldWithPopup
                  required={true}
                  id="location"
                  isPopupOpen={isPopupOpen}
                  updatePopup={updatePopup}
                  path="/v1/location/city"
                  key_name="location"
                  setValue={updateLocation}
                  withId="location"
                  placeholder="Search city"
                  className="enter_location application_city"
                />
              </div>

              {/* Type  */}
              <div>
                <label htmlFor="City">Type</label>
                {Object.keys(map).map((key, i) => {
                  // console.log(map[key], "map[key]");
                  return (
                    <div className="form-check d-flex align-items-center my-2" key={i}>
                      <input
                        className="form-check-input"
                        type="radio"
                        value={map[key]}
                        id={`radio${key}`}
                        name="typeSelect"
                        onChange={updateSelected}
                        defaultChecked={selectedType === map[key]}
                      />
                      <label
                        className="form-check-label"
                        // for={`radio${key}`}
                        htmlFor={`radio${key}`}
                        style={{ textTransform: "capitalize" }}
                      >
                        {map[key]}
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* Status  */}
              <StatusDropdown
                options={[
                  "Shortlisted",
                  selectedType === "project" ? "Applied" : "Enrolled",
                  "Rejected",
                ]}
                setValue={updateStatus}
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div
          className={`col-md-4 col-lg-4  d-none d-md-block mobile_FilterBox `}
        >
          {/* <div className="radius_normal d-flex align-items-center w-100 justify-content-between p-1 border mt-2 mb-4">
            <input
              className={`form-control search_skills`}
              type="search"
              placeholder="Search"
              style={{ width: "85%" }}
              onChange={(e) => {
                dispatch({
                  type: "change_value",
                  payload: { name: "search", value: e.target.value },
                });
              }}
            />

            <i
              className="fa-solid fa-magnifying-glass"
              style={{ width: "10%" }}
            ></i>
          </div> */}
          <div className="filter_box radius_normal border p-3 mb-4 mt-3">
          <div className="radius_normal d-flex align-items-center w-100 justify-content-between p-1 mt-2 mb-4">
            <input
              className={`form-control application_city`}
              type="search"
              withId="search"
              placeholder="Search opportunity"
              style={{ fontSize: "15px" }}
              onChange={(e) => {
                dispatch({
                  type: "change_value",
                  payload: { name: "search", value: e.target.value },
                });
              }}
            />

            {/* <i
              className="fa-solid fa-magnifying-glass"
              style={{ width: "10%" }}
            ></i> */}
          </div>
            {/* search City  */}
            <div>
              <label htmlFor="City">Location</label>
              <TextFieldWithPopup
                required={true}
                id="location"
                isPopupOpen={isPopupOpen}
                updatePopup={updatePopup}
                path="/v1/location/city"
                key_name="location"
                setValue={updateLocation}
                withId="city"
                placeholder="Search location"
                className="enter_location application_city"
              />
            </div>

            {/* Type  */}
            <div>
              <label htmlFor="City">Type</label>
              {Object.keys(map).map((key, i) => {
                // // console.log(map[key], "map[key]");
                return (
                  <div className="form-check d-flex align-items-center my-2" key={i}>
                    <input
                      className="form-check-input"
                      type="radio"
                      value={map[key]}
                      id={`radio${key}`}
                      name="typeSelect"
                      onChange={updateSelected}
                      defaultChecked={selectedType === map[key]}
                    />
                    <label
                      className="form-check-label"
                      for={`radio${key}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {map[key]}
                    </label>
                  </div>
                );
              })}
            </div>

            <StatusDropdown
              options={[
                "All",
                "Shortlisted",
                selectedType === "project" ? "Enrolled" : "Applied",
                "Rejected",
                "Hired",
                selectedType === "project" && "Project_Submitted",
              ]}
              setValue={updateStatus}
            />
            <div className="cta">
              <button
                // onClick={onReset}
                type="reset"
                className="clear"
              >
                Clear
              </button>
              <button
                // onClick={handleSubmit(onSubmit)}
                type="submit"
                className="apply"
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-8 col-lg-8 mt-3">
          {/* For Testing Purpose I Have Replaced <Shortlisted Card> With <ShortlistProject Card>  */}

          {/* <ShortlistedCards data={applied} method={selected} /> */}

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {applied.length > 0 ?  (
                <>
                  {selected === "project" && (
                    applied?.map((item,i)=> (

                      <ShortlistProjectCard item={item} method={selected} key={i} />
                    ))
                  )}

                  {selected === "internship" && (
                    applied?.map((item,i)=> (

                      <ShortlistInternshipCard item={item} method={selected} key={i}/>
                    ))
                  )}

                  {selected === "job" && (
                    applied?.map((item,i)=> (

                      <ShortlistJobCard key={i} item={item} method={selected} />
                    ))
                  )}
                </>
              ) 
              : (
                <div className="empty_card">
                  <div className="emptyCard_content">
                    <h4>No results found matching your criteria!</h4>
                    <button
                      onClick={() => {
                        navigate(`/student/explore/top-${selectedType}s`, {
                          state: {
                            selectedType: selectedType,
                          },
                        });
                      }}
                    >
                      Explore {selectedType}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentApplication;
