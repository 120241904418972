import React from "react"
import "../mobileViewTable.scss"


const MobileViewCollegeTable = ({ columns, data,handleAccountActivation}) => {
  // console.log(columns, data,"college")
 
  return (
    <>
      {data?.map((item, i) => {

        // const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4" key={i}>
            <div className="data_container">
              <div className="table_data">
                <p>College Name</p>
                <span className="Total_enrolled">{item?.college_name}<br/>{item?.address}</span>
              </div>
              
              <div className="table_data">
                <p>Contact Person</p>
                <span className="Total_enrolled">{item?.name}</span>
              </div>
              <div className="table_data">
                <p>Contact details</p>
                <span className="Total_enrolled">{item?.phone_number}<br/>{item?.email_address}</span>

               

              </div>

              <div className="table_data">
                <p>Signup Date</p>
                <span className="Total_enrolled">{item?.signup_date}</span>
              </div>

              <div className="table_data">
              <p>Last Login</p>
                <span className="Total_enrolled">{item?.last_login}<br/>{item?.last_time}</span>
              </div>

              <div className="table_data">
                <p>Projects</p>
                <span className="Total_enrolled">{item?.projects}</span>
              </div>
              <div className="table_data">
                <p>Internships</p>
                <span className="Total_enrolled">{item?.internships}</span>
              </div>
              <div className="table_data">
                <p>Jobs</p>
                <span className="Total_enrolled">{item?.jobs}</span>
              </div>

             

              
              <div className="table_data">
                <p>Subscription Validity</p>
                <span className="Total_enrolled">{item?.subscription_validity}</span>
              </div>

              <div className="Status_data">
              <p>Action</p>
              <button
       onClick={() => handleAccountActivation(item?.id)}
         className={`deactivate-btn ${
          item?.deletedAt === "Activate" ? "bg-success" : "bg-danger"
          }`}
                    >
          {item?.deletedAt}
            </button>
           
              
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewCollegeTable
