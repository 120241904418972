  import React, { useEffect, useState } from "react";
  import { Link, useParams } from "react-router-dom";
  import "./studentListTable.scss";
  import Datatable from "components/datatable-new/DatatableNew";
  import { downloadCSV } from "components/dataTable/Export";
  import axios from "axios";
  import { toast } from "react-toastify";
  import Papa from "papaparse";
  import moment from "moment";
import MobileStudentListTable from "./MobileStudentListTable";
import "./AddStudentModal.scss"
import Loader from "components/employer/Loader";
import ManualUploadPopup from "../StudentListPopups/ManualUploadPopup";


  // import { downloadCSV } from "components/dataTable/Export"

  const StudentModal = ({ onClose, setRefresh,setLoading}) => {
    
    const [addedStudentsCount, setAddedStudentsCount] = useState(1);
    
    const [students, setStudents] = useState([
      {
        studentname: '',
        course: '',
        specialization: '',
        semester: '',
        email: '',
      },
    ]);
  
    const handleAddStudent = () => {
      setStudents((prevStudents) => [
        ...prevStudents,
        {
          studentname: '',
          course: '',
          specialization: '',
          semester: '',
          email: '',
        },
      ]);
      setAddedStudentsCount((count) => count + 1);
    };
    const handleInputChange = (index, field, event) => {
      const newStudents = [...students];
      newStudents[index][field] = event.target.value;
      setStudents(newStudents);
    };
  
  
    const handleDeleteStudent = (index) => {
      setStudents((prevStudents) =>
        prevStudents.filter((_, i) => i !== index)
      );
      setAddedStudentsCount((count) => count > 0 ? count - 1 : 0);
    };
  
    const handleSubmit = async () => {
      try {
       
        // Check if any field is missing in any student entry
        const isAnyFieldMissing = students.some(
          (student) => !student.studentname || !student.course || !student.specialization || !student.semester || !student.email
        );
    
        if (isAnyFieldMissing) {
          toast.error('Please fill all fields');
          return;

        }

        // Check email format for each student
        const isAnyEmailInvalid = students.some(
          (student) =>
            !student.email.match(
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            )
        );
    
        if (isAnyEmailInvalid) {
          toast.error('Invalid email format. Please check the email format.');
          return;
        }
    
        // Check for duplicate emails
        const isAnyDuplicateEmail = students.some(
          (student, index) =>
            students.findIndex(
              (s) => s.email.toLowerCase() === student.email.toLowerCase()
            ) !== index
        );
    
        if (isAnyDuplicateEmail) {
          toast.error('Duplicate emails are not allowed.');
          return;
        }

    
        const studentsData = students.map((student) => [
          student.studentname,
          student.course,
          student.specialization,
          student.semester,
          student.email,
        ]);
        setLoading(true)
        // Make an Axios POST request to your API
        const response = await axios.post('/v1/educator/invite/upload_students', {
          invitee_ids: studentsData,
          // collection_name: "second collection"
        });
    
        // console.log('Response from the server:', response.data);
    
        onClose();
    
        // Display success message
        toast.success(`${addedStudentsCount} Students Successfully Uploaded`)
        // toast.success("Students added successfully!");
      
      
        setRefresh((prevRefresh) => !prevRefresh)
       setLoading((prevRefresh) => !prevRefresh)
    


      } catch (error) {
        console.error('Error sending data:', error);
        toast.error('Error submitting data');
      }
    };
    
  
    const semesterOptions = Array.from({ length: 8 }, (_, index) => index + 1);

    
  
    return (
      <div className="student-overlay">
        <div className="student-event-modal">
          <div className="student-events-top">
            <h4 className="add-student"><i class="fa fa-upload" aria-hidden="true" style={{color:'#120752'}}></i>Upload Students</h4>
         

            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={onClose}
              style={{ color: 'black' }}
            ></i>
          </div>
           <div> 
            <h6 className="upload-student">{`Student Added (${addedStudentsCount})`}</h6>
           </div>

          <div className="student-model-content">
            <div className="student-model-detail">
              {students.map((student, index) => (
                <div className="row modal-border" key={index}>

                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <label className="label-student">Student Name</label>
                    <input
                      type="text"
                      required
                      placeholder="Student Name"
                      className="student-form-control"
                      value={student.studentname}
                      onChange={(e) => handleInputChange(index, 'studentname', e)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <label className="label-student">Email</label>
                    <input
                      type="email"
                      required
                
                      placeholder="Email Id"
                      className="student-form-control"
                      value={student.email}
                      onChange={(e) => handleInputChange(index, 'email', e)}
                    />
                  </div>
  
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="label-student">Course</label>
                    <input
                      type="text"
                      required
                    
                      placeholder="Course"
                      className="student-form-control"
                      value={student.course}
                      onChange={(e) => handleInputChange(index, 'course', e)}
                    />
                  </div>
  
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="label-student">Specialization</label>
                    <input
                      type="text"
                      required
                      
                      placeholder="Specialization"
                      className="student-form-control"
                      value={student.specialization}
                      onChange={(e) => handleInputChange(index, 'specialization', e)}
                    />
                  </div>
  
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1">
                  <label className="label-student">Semester</label>
                    <select
                      className="student-form-control"
                      value={student.semester}
                     
                      onChange={(e) => handleInputChange(index, 'semester', e)}
                    >
                      <option value="" className="student-option">
                        Semester
                      </option>
                      {semesterOptions.map((option) => (
                        <option key={option} value={option} className="student-option">
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
  
                  
  
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 actionbtnmodal">
                  {index === students.length - 1 && (
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      onClick={handleAddStudent}
                      style={{ color: '#120752', cursor: 'pointer' }}
                    ></i>
                  )}
                   {students.length > 1 && (
                    <>
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        onClick={() => handleDeleteStudent(index)}
                        style={{ color: 'red', cursor: 'pointer' }}
                      ></i>
                    </>
                  )}
                  </div>

                  
                </div>
              ))}
            </div>
          </div>
          <button className="student-btn" onClick={handleSubmit}>
           Upload
          </button>
        </div>
      </div>
    );
  };
  
  

const StudentListTable = ({ data, flag = true , setRefresh}) => {
  // const { id = 0 } = useParams()
  const [search, setSearch] = useState(() => "");
  
  // student modal

  const [isModalOpen, setIsModalOpen] = useState(false);
  const[loading,setLoading]=useState(false)


  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
    // handleRefreshFromStudentListTable();
  };
  // End student modal
  // console.log("dtaaaa", data);

   data = data?.map(student => {
    const projectCount = parseInt(student.project_count) || 0;
    const internshipCount = parseInt(student.internship_count) || 0;
    const jobCount = parseInt(student.job_count) || 0;
  
    const totalOpportunities = projectCount + internshipCount + jobCount;
  
    return {
      ...student,
      totalOpportunities,
    };
  });

  // console.log("total", data)


  const columns = [
    // {
    //   Header: " ",
    //   width: "1%",
    //   sortable: false,
    //   Cell: (data) => {
    //     return (
    //       <div className="form-check">
    //         <input
    //           class="form-check-input"
    //           type="checkbox"
    //           value=""
    //           id="flexCheckDefault"
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "Date Posted",
      accessor: "date",
      width: "10%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.date}
          </strong>
        );
      },
    },
    {
      Header: "Student Name",
      accessor: "name",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (

          <strong style={{ fontWeight: "400" }}>
            {data.row.original.name}
          </strong>
        );
      },
    },
    {
      Header: "Course",
      accessor: "course",
      width: "9%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.course}
          </strong>
        );
      },
    },
    {
      Header: "Specialization",
      accessor: "specialization",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.specialization}
          </strong>
        );
      },
    },
    {
      Header: "Semester",
      accessor: "semester",
      width: "12.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.semester}
          </strong>
        );
      },
    },
    {
      Header: "Email Id",
      accessor: "email",
      width: "20%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.email}
          </strong>
        );
      },
    },
    // {
    //   Header: "Phone Number",
    //   accessor: "phone",
    //   width: "10%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "400" }}>
    //         {data.row.original.phone}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Assigned Opportunities",
      accessor: "oppotunities",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.opportunities}
          </strong>
        );
      },
    },
  ];
  // console.log(data, "data");
  const rows = data
    ?.filter((post) => {
      return post?.name?.toLowerCase()?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      // console.log(post, "post");
      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        name: (
          <Link to={`/educator/student-detail/${post?.id}`}>
          
            {post?.name}
          </Link>
          ),
        course: post?.course,
        specialization: post?.specialization,
        semester: post?.semester,
        email: post?.student_email,
        // phone: post?.phone_number || "0123456789",
        opportunities: post?.totalOpportunities,
      };
    });

    if (loading) return <Loader />;

    return (
      <div className="internshipTable_main">
        <div className="table_head flex">
          <div className="mobo_upload">
            <h3>
              Students <br />{" "}
              <span className="light">{data["length"] || 0} records</span>
            </h3>
{/* remove the file download option from mobile view */}
            {/* <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link> */}
            
            {/* mobile view student modal */}
                 {/* <div  className="upload_btn_icon_mobo btn btn-primary text-white" onClick={()=>setIsModalOpen(!isModalOpen)} >
                    Add <i className="fa-solid fa-plus "></i>
                  </div> */}
 <div className="mob_list_name" > {data[0]?.list?.list_name} </div>


            
          </div>

          <div className=" web_list_name" > {data[0]?.list?.list_name} </div>

          <div className="add_icon non">
            {flag && (
              <>
                <Link
                  to="/employer/internship/add"
                  className="upload_btn_icon btn btn-primary text-white"
                >
                  Add <i className="fa-solid fa-plus"></i>
                </Link>
                <button
                  className="upload_btn_icon btn btn-primary"
                  onClick={() => downloadCSV(rows)}
                >
                  <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
                </button>
              </>
            )}
               {/* add student model */}
            {/* <div  className="upload_btn_icon btn btn-primary text-white " onClick={()=>setIsModalOpen(!isModalOpen)} >
           
                  Add more <i className="fa-solid fa-plus"></i>
            </div>
               
                   {isModalOpen && (
                         <ManualUploadPopup existsName={data[0]?.list?.list_name} onClose={closeModal} setRefresh={setRefresh} setLoading={setLoading} loading={loading} />
                   )} */}

      


            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

    



   {/* Add Student Modal */}
   

        



   
        {data.length > 0 ? (

          // <Datatable columns={columns} data={rows} />
         
          <>
          <div className="d-none d-sm-block">
        <Datatable columns={columns} data={rows} />
      </div>
      <div className="d-block d-sm-none">
       <MobileStudentListTable columns={columns} data={rows}/>
      </div>
          </>

       
        ) : (
          <p>No data</p>
        )}
        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
          columns={columns}
          data={rows}
          pagination
          className="table-responsive"
        /> */}
      </div>
    );
  };

  export default StudentListTable;
