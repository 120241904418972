function convertArrayOfObjectsToCSV(array) {
  let result

  const columnDelimiter = ","
  const lineDelimiter = "\n"
  const keys = Object.keys(array[0])

  result = ""
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      if (typeof item[key] === "string") {
        item[key] = item[key].replace(/"/g, '""')
        // item[key] = item[key].replace(/\n\n###|\n\n##|\n###/g, '');
        item[key] = item[key].replace(/#+/g, '');

        if (item[key].search(/("|,|\n)/g) >= 0) item[key] = `"${item[key]}"`
      } else if (typeof item[key] === "number") {
        item[key] = item[key].toString()
      } else if (typeof item[key] === "boolean") {
        item[key] = item[key].toString()
      } else if (item[key] instanceof Date) {
        item[key] = item[key].toLocaleString()
      } else if (item[key] === "object") {
        item[key] = item[key]?.key || ""
      } else if (!item[key]) {
        item[key] = ""
      }
      result += item[key]

      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const downloadCSV = (array) => {
  const link = document.createElement("a")
  let csv = convertArrayOfObjectsToCSV(array)
  if (csv == null) return

  const filename = "export.csv"

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
   // console.log("csvdata",csv)

  link.setAttribute("href", encodeURI(csv))
  link.setAttribute("download", filename)
  link.click()
}

export const Export = ({ data }) => (
  <button onClick={() => downloadCSV(data)}>Export</button>
)
