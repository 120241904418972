import Loader from "components/employer/Loader"
import React, { useEffect, useReducer, useState } from "react"
import { FaRegPenToSquare } from "react-icons/fa6"
import { FiFilter } from "react-icons/fi"
import { RxCaretSort } from "react-icons/rx"
import { SlCloudUpload } from "react-icons/sl"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  initialState,
  reducer,
} from "view/dashboard/student/explore/handleFilters"
import left from "../dashboard/student/icons/left.svg"
import right from "../dashboard/student/icons/right.svg"
import FilterBox from "./FilterBox"
import OpportunityCardNew from "./OpportunityCardNew"
import NewMobSidebar from "./components/sidebar/NewMobSidebar"
import "./newExplore.scss"
import EducatorOpportunityCardNew from "view/dashboard/educator/explore/OpportunityDetailsPage"
import useURLParams from "./customHooks/useUrlParams"
import useMiscellaneous from "./customHooks/useMiscellaneous"
import useFetchFilters from "./customHooks/useFetchFilters"
import useHandleFilters from "./customHooks/useHandleFilters"
import useHandleUrl from "./customHooks/useHandleUrl"
import useHandleSearch from "./customHooks/useHandleSearch"
import useHandleClearFilters from "./customHooks/useHandleClearFilters"
import useFetchData from "./customHooks/useFetchData"
import { Helmet } from "react-helmet"

const debounce = (func, delay) => {
  let timeoutId
  return function (...args) {
    const context = this
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(context, args), delay)
  }
}

const ExplorePageNew = ({ isEducator = false }) => {

  const location = useLocation();
  // console.log("locaaaaaaaa", location.pathname);

  const filterMap = {
    0: "projects",
    1: "internships",
    2: "jobs",
  }

  const userRole={
    3:"educator",
    4:"student"
  }
  
  // const user = useSelector((state) => state?.auth?.user)
  const role = JSON.parse(localStorage.getItem("user"))?.role;
  // console.log("roleeeeeeeeeeee", role);
  const search = useSelector((state) => state?.location?.keyword)
  const company_id = useSelector((state) => state?.location?.company_id)

  const dispatch2 = useDispatch()

  const [data, setData] = useState(() => [])

  const [loading, setLoading] = useState(false)

  const [counts, setCounts] = useState({
    pCount: 0,
    iCount: 0,
    jCount: 0,
  })


  const map = {
    0: "project",
    1: "internship",
    2: "job",
  }

  const opportunity = {
    0: `projects (${counts?.pCount})`,
    1: `internships (${counts?.iCount})`,
    2: `jobs (${counts?.jCount})`,
  }

      ///----------- handle filters ------------------
      const { industries, courses, company, locations, suggestCompanies, city, skills} = useFetchFilters();
      const allFilters = {industries, courses, company, locations,skills}
  
    ///-------------- get tab from url----------------
    const {getUrlTab, parseFiltersFromUrl} = useURLParams(filterMap, industries, courses, company, skills);

    ///-----------parse filters from url ------------------
  const parsedFilters = parseFiltersFromUrl(location.pathname);

  // console.log("parseddddd", parsedFilters?.filters?.locations);

  const [state, dispatch] = useReducer(reducer, {
      ...initialState,
      selected: getUrlTab || 0,
      filters: parsedFilters?.filters,
  })

  // console.log("statestate",state)

  useEffect(() => {

    if (industries.length && courses.length && company.length && skills.length) {
      const parsedFilters = parseFiltersFromUrl(location.pathname);
      dispatch({
        type: 'SET_FILTERS',
        payload: {
          selected: getUrlTab || 0,
          filters: parsedFilters?.filters,
          address: parsedFilters?.filtersIds?.locations?.length !== 0 ? parsedFilters?.filtersIds?.locations : "",
          company_ids: parsedFilters?.filtersIds?.companies.length !== 0 ? parsedFilters?.filtersIds?.companies : new Set(),
          industry_sector: parsedFilters?.filtersIds?.industries?.length !== 0 ? parsedFilters?.filtersIds?.industries : new Set(),
          course_preferred: parsedFilters?.filtersIds?.qualifications?.length !== 0 ? parsedFilters?.filtersIds?.qualifications : new Set(),
          skills: parsedFilters?.filtersIds?.skills?.length !== 0 ? parsedFilters?.filtersIds?.skills : new Set(),
        },
      });
    }

    setSelectedItems({
      companies: parseFiltersFromUrl(location?.pathname)?.filters?.companies?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.companies : ["all"],
      industries: parseFiltersFromUrl(location?.pathname)?.filters?.industries?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.industries : ["all"],
      qualifications: parseFiltersFromUrl(location?.pathname)?.filters?.qualifications?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.qualifications : ["all"],
      locations: parseFiltersFromUrl(location?.pathname)?.filters?.locations?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.locations :  ["all"],
      skills: parseFiltersFromUrl(location?.pathname)?.filters?.skills?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.skills : ["all"],
      salaries: [],
    })

    // updateURL();


  }, [industries, courses, company,skills, location.pathname]);

    // const [state, dispatch] = useReducer(reducer, {
  //   ...initialState,
  //   selected: getUrlTab || 0,
  //   filters: parsedFilters?.filters,
  //   address: parsedFilters?.filtersIds?.locations?.length !== 0 ? parseFiltersFromUrl(location.pathname)?.filtersIds?.locations : "",
  //   company_ids: parsedFilters?.filtersIds?.companies.length !== 0 ? parsedFilters?.filtersIds?.companies : new Set(),
  //   // company_ids: parseFiltersFromUrl(location.pathname)?.filtersIds?.companies,
  //   // industry_sector: parseFiltersFromUrl(location.pathname)?.filtersIds?.industries?.length !== 0 ? parseFiltersFromUrl(location.pathname)?.filtersIds?.industries : new Set(),
  //   // course_preferred: parseFiltersFromUrl(location.pathname)?.filtersIds?.qualifications?.length !== 0 ? parseFiltersFromUrl(location.pathname)?.filtersIds?.qualifications : new Set(),
  // });


 

    ///----------handle url for filters-----------------
    const { updateURL } = useHandleUrl(state, role, userRole, filterMap, getUrlTab);

    // useEffect(() => {

    //   if (industries.length && courses.length && company.length) {
    //     // const parsedFilters = parseFiltersFromUrl(location.pathname);
    //     dispatch({
    //       type: 'SET_FILTERS',
    //       payload: {
    //         selected: getUrlTab || 0,
    //         filters: parsedFilters?.filters,
    //         address: parsedFilters?.filtersIds?.locations?.length !== 0 ? parsedFilters?.filtersIds?.locations : "",
    //         company_ids: parsedFilters?.filtersIds?.companies.length !== 0 ? parsedFilters?.filtersIds?.companies : new Set(),
    //         industry_sector: parsedFilters?.filtersIds?.industries?.length !== 0 ? parsedFilters?.filtersIds?.industries : new Set(),
    //         course_preferred: parsedFilters?.filtersIds?.qualifications?.length !== 0 ? parsedFilters?.filtersIds?.qualifications : new Set(),
    //       },
    //     });
    //   }

    //   setSelectedItems({
    //     companies: parseFiltersFromUrl(location?.pathname)?.filters?.companies?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.companies : ["all"],
    //     industries: parseFiltersFromUrl(location?.pathname)?.filters?.industries?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.industries : ["all"],
    //     qualifications: parseFiltersFromUrl(location?.pathname)?.filters?.qualifications?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.qualifications : ["all"],
    //     locations: parseFiltersFromUrl(location?.pathname)?.filters?.locations?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.locations :  ["all"],
    //     salaries: [],
    //   })
  
    //   // updateURL();
  
  
    // }, [industries, courses, company, skills, location.pathname]);


    // console.log("indusssssstr", {industries, company, courses});

  
  const items =
    state?.selected === 0
      ? ["Newest First", "Older First"]
      : state?.selected === 1
      ? ["Newest First", "Older First", "Stipend"]
      : ["Newest First", "Older First", "Salary"]
  


  //pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [leadEmail, setLeadEmail] = useState("")
  const ITEMS_PER_PAGE = 10

  // const pages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const [file, setFile] = useState(() =>
    JSON.parse(localStorage.getItem("resume"))
  )
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)

  useEffect(() => {
    setCurrentPage(1)
  }, [state])


  ///--------- Miscellaneous prev, next button & view resume ------------
  const {handleClickPrev, handleClickNext, viewResume, handleFileUpload, sendLead} = useMiscellaneous( setCurrentPage, totalPages, company_id, dispatch, dispatch2, setFile, leadEmail )


  ///-----------fetch data all opportunity-----------
  useFetchData(setLoading, state, currentPage, ITEMS_PER_PAGE, setData, setTotalItems, setTotalPages, setCounts, updateURL, file, getUrlTab, allFilters);


  ///--------------- handle search box--------------
  const {decouncedUpdateLocation} = useHandleSearch(dispatch, search, setLoading, state, currentPage, setCurrentPage, ITEMS_PER_PAGE, setData, setTotalItems, setTotalPages, debounce)



  ///----------Clear filters functions-------------
  const { selectedItems, setSelectedItems,
    suggestComp, handleCompanyFilter, 
    handleIndustryFilter, handleQualificationFilter,
    handleLocationFilter, setSuggestComp, handleCheckboxChange, handleChangeOpportunity, handleSortChange ,handleSkillFilter}  = useHandleFilters( industries, courses, company, locations, dispatch, setIsBottomSheetOpen, role, parseFiltersFromUrl,skills)
  


  ///----------Clear filters functions-------------
  const {
    clearFilterSelection,
    clearAllSelections, clearResumeSelection} = useHandleClearFilters(dispatch, setSelectedItems, setSuggestComp, role, filterMap, userRole, setFile);


  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)

  const toggleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
    // document.body.style.overflow = isFilterSidebarOpen ? 'unset' : 'hidden';
  }


  const toggleBottomSheet = () => {
    if (!file) {
      setIsBottomSheetOpen(!isBottomSheetOpen)
    }
  }


  if (
    data?.length <= 0 &&
    industries?.length <= 0 &&
    courses?.length <= 0 &&
    company?.length <= 0
  )
    return <Loader />

    // console.log("parseddd", parsedFilters?.filters);
    const changeTitleBar = ()=> {
      const locations = parsedFilters?.filters?.locations?.length ? parsedFilters?.filters?.locations?.join(", ") : "";
      const industries = parsedFilters?.filters?.industries?.length ? parsedFilters?.filters?.industries?.join(", ") : "";
      const qualifications = parsedFilters?.filters?.qualifications?.length ? parsedFilters?.filters?.qualifications?.join(", ") : "";
    
      const titleParts = [];
      if (locations) titleParts.push(locations);
      if (industries) titleParts.push(industries);
      if (qualifications) titleParts.push(qualifications);
    
      const title = titleParts.length ? `| ${filterMap[state?.selected]} in ${titleParts.join(", ")}` : "";
    
      return title
    }

  return (
    <section className="wholepage">
      <Helmet>
            
            <meta
              name="title"
              content={`Explore | Top-${filterMap[state?.selected]} ${changeTitleBar()} `}
            />

            <meta charSet="utf-8" name="description" content="Explore a wide range of projects, internships, and job opportunities for freshers on Qollabb. Connect with top companies, gain valuable experience, and kickstart your career. Discover opportunities tailored to your skills and aspirations today!" />

          <link rel="canonical" href={`https://qollabb.com/explore-page/Top-${filterMap[state?.selected]}`} />
          
        <title>
          {`Top-${filterMap[state?.selected]} ${changeTitleBar()}`}
        </title>
      </Helmet>
      <div className="container top-wrapper">
        <div className={isEducator?`educator-left-section`:`left-section`}>
          <div className="left-main">
            {/* filters head */}
            <div className="filter-top">
              <h4>Filters</h4>

              <p onClick={clearAllSelections} style={{ cursor: "pointer" }}>
                Clear All
              </p>
            </div>

            <div className="filter-main">
              <div>
                <FilterBox
                  headerText="Opportunity Type"
                  state={state}
                  handleChange={handleChangeOpportunity}
                  clearSelection={() => clearFilterSelection("opportunity")}
                  listItems={[...Object.values(opportunity)]}
                  isCheckBox={false}
                />
              </div>
              <div>
                <FilterBox
                  headerText="Company"
                  // handleChange={handleCompanyFilter}
                  selectedItems={selectedItems.companies}
                  handleCheckboxChange={handleCompanyFilter}
                  clearSelection={() => clearFilterSelection("companies")}
                  listItems={[
                    "all",
                    ...company?.map((item) => item?.company_name),
                  ]}
                />
              </div>
              <div>
                <FilterBox
                  headerText="Location"
                  // handleChange={handleLocationFilter}
                  selectedItems={selectedItems.locations}
                  handleCheckboxChange={handleLocationFilter}
                  updateLocation={decouncedUpdateLocation}
                  clearSelection={() => clearFilterSelection("locations")}
                  listItems={["all", ...locations?.map((item) => item?.city)]}
                />
              </div>
              <div>
                <FilterBox
                  headerText="Industries"
                  // handleChange={handleCompanyFilter}
                  selectedItems={selectedItems.industries}
                  handleCheckboxChange={handleIndustryFilter}
                  clearSelection={() => clearFilterSelection("industries")}
                  listItems={[
                    "all",
                    ...industries?.map((industry) => industry?.industry_name),
                  ]}
                />
              </div>
              <div>
                <FilterBox
                  headerText="Qualifications"
                  // handleChange={handleCompanyFilter}
                  selectedItems={selectedItems.qualifications}
                  handleCheckboxChange={handleQualificationFilter}
                  clearSelection={() => clearFilterSelection("qualifications")}
                  listItems={[
                    "all",
                    ...courses?.map((course) => course?.course_name),
                  ]}
                />
              </div>
              {/* {skills?.length === 10 && ( */}
              <div>
                        <FilterBox
                        headerText="Skills"
                        // handleChange={handleCompanyFilter}
                        selectedItems={selectedItems.skills}
                        handleCheckboxChange={handleSkillFilter}
                        clearSelection={() => clearFilterSelection("skills")}
                        listItems={[
                          "all",
                          ...skills?.map((skill)=>skill?.name)
                        ]}
                        />

                    </div>
              {/* )} */}
            </div>
          </div>
        </div>

        <div className={isEducator?`educator-mid-section`:`mid-section`}>
          <div className="mid-main">
            <div className="mid-main-top">
              {/* Resume section */}
              {!isEducator && (
                <>
                  <div htmlFor="resume" className="resume-section">
                    <div className="cloudicon">
                      {file ? (
                        <label onClick={clearResumeSelection}>
                          <FaRegPenToSquare size={30} color="#6149CD" />
                        </label>
                      ) : (
                        <SlCloudUpload size={35} color="#6149CD" />
                      )}
                    </div>

                    {file ? (
                      // <Link to={file} target="_blank">
                      <p onClick={viewResume} className="file-name">
                        {" "}
                        {"View your resume"}
                        {/* <MdOutlineCancel  style={{position: "absolute", color: "#6149CD", bottom: "5px"}} size={20} /> */}
                      </p>
                    ) : (
                      // </Link>
                      <label htmlFor="resume">
                        <h4>Upload Your Resume (Pdf or Docx)</h4>
                        <p>We'll match you with the relevant opportunities!</p>
                      </label>
                    )}

                    {/* {
                          file && 
                                <MdOutlineCancel  style={{ color: "#6149CD"}} size={20} />
                        } */}
                  </div>
                  <input
                    type="file"
                    name="resume"
                    id="resume"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </>
              )}

              {/* sort by section */}
              <div className="sortby-section">
                <p>{totalItems} results found</p>
                {!file && (
                  <div className="sory-by">
                    <p>Sort By: </p>
                    <span>
                      <select onChange={handleSortChange} className="dropdown">
                        {items?.map((item, i) => (
                          <option value={i} key={i}>
                            {item}
                          </option>
                        ))}
                        {/* <option value="desc">Newest First</option>
                              <option value="asc">Older First</option> */}
                        {/* Add more options as needed */}
                      </select>
                    </span>
                    {/* <span><MdOutlineKeyboardArrowDown /></span> */}
                  </div>
                )}

                <div className="mob-opportunity">
                  <p>Opportunity: </p>
                  <span>
                    <select
                    value={state.selected} 
                      onChange={(e) =>
                        handleChangeOpportunity(
                          e.target.value,
                          e.target.selectedIndex ,
                        )
                      }
                      className="dropdown"
                    >
                      {Object.values(map)?.map((item, i) => (
                        <option key={i} value={i}>
                          {item}
                        </option>
                      ))}
                      {/* <option value="desc">Newest First</option>
                              <option value="asc">Older First</option> */}
                      {/* Add more options as needed */}
                    </select>
                  </span>
                  {/* <span><MdOutlineKeyboardArrowDown /></span> */}
                </div>
              </div>
            </div>

            {/* Opportunity Cards */}
            {/* <div className="opportunities">
                {
                  loading ? (
                    <Loader />
                  ): (
                    <div>
                    {
                    data ? (
                      
                      data?.length > 0 ? data?.map((item)=> (

                        <OpportunityCardNew key={item?.id} item={item} type={map[state?.selected]}  />
                      ))
                        
                      : (
                        <p style={{textAlign: "center"}}>No Results Found</p>
                      )
                    ): (
                      <Loader />
                    )
                    }

                    </div>
                  )
                }
                </div> */}
            {/* educator opportunity details page */}
            <div className="opportunities">
              {loading ? (
                <Loader />
              ) : (
                <div>
                  {data ? (
                    data.length > 0 ? (
                      data.map((item) => (
                        <div key={item?.id}>
                          {isEducator ? (
                            <EducatorOpportunityCardNew
                              key={item?.id}
                              item={item}
                              type={map[state?.selected]}
                            />
                          ) : (
                            <OpportunityCardNew
                              key={item?.id}
                              item={item}
                              type={map[state?.selected]}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center" }}>No Results Found</p>
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
            </div>

            {/* end educator details page */}

            {loading ? (
              ""
            ) : data?.length > 0 ? (
              <div className=" d-flex align-items-center justify-content-end mt-4 bottom-pagination">
                <img src={left} alt="Image" onClick={handleClickPrev} width={30} />
                <p
                  className="m-0 px-3"
                  style={{ fontSize: "22px", fontWeight: "900" }}
                >
                  <strong style={{ color: "black" }}>{currentPage}</strong>/
                  {totalPages}
                </p>
                <img src={right} alt="Image" onClick={handleClickNext} width={30} />
              </div>
            ) : (
              ""
            )}
          </div>

          {/* Mob bottom buttons */}
          <div className="mob_bottom">
            <div className="bottom-main">
              <div className="bottom-left" onClick={toggleBottomSheet}>
                <RxCaretSort size={20} /> Sort
              </div>

              <div className="bottom-divider"></div>

              <div className="bottom-right" onClick={toggleFilterSidebar}>
                <FiFilter /> Filter
              </div>
            </div>
          </div>

          {/*  Bottom Sheet for sorting options */}
          <div className={`bottom-sheet ${isBottomSheetOpen ? "open" : ""}`}>
            <div className="sort-dropdown">
              <select onChange={handleSortChange} className="dropdown">
                {items?.map((item, i) => (
                  <option value={i} key={i}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Filter sidebar */}
          <div
            className={`filter-sidebar ${isFilterSidebarOpen ? "open" : ""}`}
          >
            <NewMobSidebar
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              company={company}
              locations={locations}
              industries={industries}
              courses={courses}
              skills={skills}
              handleCheckboxChange={handleCheckboxChange}
              dispatch={dispatch}
              state={state}
              decouncedUpdateLocation={decouncedUpdateLocation}
              toggleFilterSidebar={toggleFilterSidebar}
            />
          </div>
        </div>

        <div className={isEducator?`educator-right-section`:`right-section`}>
          <div className="right-main">
            {!isEducator && (
              <>
                <div className="right-head">
                  Be the first to see new Jobs in{" "}
                  <span>
                    {city !== "" || undefined ? `${city}!!` : "Your location"}{" "}
                  </span>
                </div>

                <div className="lead-section">
                  <input
                    type="text"
                    placeholder="Your Email"
                    onChange={(e) => setLeadEmail(e.target.value)}
                    value={leadEmail}
                  />

                  <div className="btn-section">
                    <button onClick={sendLead}>Subscribe</button>
                  </div>
                </div>
              </>
            )}

            {!isEducator &&
            (city !== "" || undefined) && (
              <div className="suggest-comp">
                <h3>
                  Popular companies in <span>{city} !!</span>
                </h3>
                <div className="suggest_comp_wrapper">
                  {suggestCompanies.length > 0 ? (
                    suggestCompanies?.map((item, i) => (
                      <div
                        className={`comp ${
                          suggestComp === item?.company?.company_name
                            ? "active_comp"
                            : ""
                        }`}
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleCompanyFilter(
                            item?.company?.company_name,
                            item?.company?.id,
                            true
                          )
                        }
                      >
                        <img src={item?.company_logo} alt="logo" />
                        <div className="comp-name">
                          {item?.company?.company_name}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No companies found in this location</p>
                  )}

                  {/* dataa for testing */}
                  {/* {
                              Array.from({length: 40}, (_, i) => i + 1).map((item)=> (

                                <div className={`comp ${ "active_comp" }`}  style={{cursor: "pointer"}} >
                                    <img src={CompLogo1} alt="logo" />
                                  <div className="comp-name">{"GlobalTech Solutions Creative Minds Co."}</div>
                                </div>
                              ))
                            } */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExplorePageNew;
