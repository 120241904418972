import axios from "axios"
import { downloadCSV } from "components/dataTable/Export"
import Datatable from "components/datatable-new/DatatableNew"
import Loader from "components/employer/Loader"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import MobileViewUserTable from "./MobileViewUserTable"
import { toast } from "react-toastify"
import moment from "moment"

const AdminUsers = () => {

  const navigate=useNavigate()
  const [search, setSearch] = useState(() => "")
  const [data,setData] = useState([])
  const [mentorDataCount,setMentorDataCount] = useState([])
  const [loading,setLoading]=useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axios.get("/v1/mentor");
      // console.log(response?.data,"mentor");
      setData(response?.data?.data)
      setMentorDataCount(response?.data)
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleEditClick = (data) => {
    navigate(`edit-user/${data?.row?.original.id}`, {
      state: {
        id:data?.row?.original.id,
        first_name: data?.row?.original.first_name,
        last_name: data?.row?.original.last_name,
        // email: data?.row?.original.email_address,
        phone_number: data?.row?.original.phone_number,
        // type: data?.row?.original.role === 6 ? "Reviewer" : "",
      },
    })
  }


  const handleActivateMentorAccount = async (id) => {
    try {
      // setRefresh(true)
      const response = await axios.delete(`/v1/mentor/mentor/${id}`);
      if (!response.data?.error) {
        toast.info(response?.data?.message);
        navigate('/admin/dashboard')
        // setRefresh(false)
        // onDelete(id);
      } else {
        toast.error(response?.data?.error);
        // setRefresh(false)
      }
    } catch (error) {
      console.error(error);
      // setRefresh(false)
      toast.error("An error occurred while deleting the mentor.");
    }


    }
 


  const columns = [
    {
      Header: "Date Created",
      accessor: "createdAt",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.createdAt}
          </strong>
        );
      },
    },
    {
      Header: "Mentor Name",
      accessor: "mentor_name",
      width: "17.5%",
      textalign: "center",
    },
    {
      Header: "Email",
      accessor: "email_address",
      width: "17.5%",
      textalign: "center",
    },
    {
      Header: "Industry Sector",
      accessor: "industry_sector",
      width: "22.5%",
      textalign: "center",
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.phone_number}
          </strong>
        );
      },
    },
    {
      Header: "Action",
      accessor: "a",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        if (data.row.original.deletedAt === "Activate") {
          return (
            <button
              onClick={() => handleActivateMentorAccount(data.row.original.id)}
              className="btn px-4 py-1 text-white  bg-success"
              style={{
                borderRadius: "22px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Activate
            </button>
          );
        }

        return (
        
          <button
            onClick={() => handleEditClick(data)}
            className="btn btn-primary px-5 py-1 text-white"
            style={{
              borderRadius: "20px",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            Edit
          </button>
        )
      },
    },
  ]

  // const rows = data
  //   ?.filter((post) => {
  //     return post?.first_name?.toLowerCase()?.includes(search?.toLowerCase())
  //   })
  //   ?.map((post) => {
  //     return {
  //       id: post?.id,
  //       company_name: "Pinsout Innovation Pvt.Ltd.",
  //       company_location: "Noida, India",
  //       name: post?.first_name + " " + post?.last_name,
  //       signup_date: new Date(post?.createdAt).toLocaleDateString(),
  //       last_login: new Date(post?.updatedAt).toLocaleDateString(),
  //       projects: post?.projects || 0,
  //       internships: post?.internships || 0,
  //       jobs: post?.jobs || 0,
  //       subscription_validity: new Date(post?.updatedAt).toLocaleDateString(),
  //       sector: post?.designation,
  //       designation: post?.designation,
  //     }
  //   })

  // // console.log(rows)

  // const rows = data

  
  const rows = data
    ?.filter((post) => {
      return post?.first_name?.includes(search?.toLowerCase()) || 
      post?.last_name?.includes(search?.toLowerCase()) ||
      post?.phone_number.includes(search?.toLowerCase()) ||
      post?.email_address.includes(search?.toLowerCase());
    //   post?.industry?.industry_name.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      // console.log(post, "post");
      return {
        id: post?.id,
        profile_photo: post?.profile_photo ,
        createdAt: moment(post?.createdAt)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
        mentor_name:
          post?.first_name + " " + post?.last_name,
          first_name:post?.first_name ||"",      
          last_name:post?.last_name ||"",      
          bio:post?.bio||"",      
          languages:post?.languages ||[],      
        phone_number: post?.phone_number || "-",
        designation: post?.designation || "",
        email_address:post?.email_address,
        mentor_charges:post?.mentor_charges || "-",
        industry_sector:post?.industry?.industry_name || "-",
        industry_id:post?.industry_sector || "-",
        industry_year_expreience:post?.industry_year_expreience || " ",
        linkedin:post?.linkedIn_link|| "",
        github:post?.github_link|| "",
        profile_link:post?.profile_link|| "",
        location: post?.location||"",
        gender: post?.gender||"",
        expreiences:post?.experience ||[],
        actions: "Update" ,
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
       
      };
    });




  if(loading) return <Loader/>
  return (
    <div
      className="admin-users-page"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh", background:'#EBEFF7' }}
    >
      <div className="datatable-container mt-4 p-3">
        <div className="table_head flex">
          <div className="mobo_upload">
               <h3 className="new-class-active">
                  <div>
                 Mentors <br />{" "}
                  <span className="light new-span-count">{data?.length || 0} records

                  {
                    <div className="new-type">
                      <div className="active">
                        Active - {mentorDataCount?.activeMentors || 0}
                      </div>
                      <div className="inactive">
                        Inactive - {mentorDataCount?.inactiveMentors || 0}
                      </div>
                    </div>
                  }
                  
                  </span>
                  </div>
                </h3>

            <Link to="" className="upload_btn_icon_mobo btn btn-primary">
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <Link
              to="add-user"
              className="upload_btn_icon btn btn-primary text-white px-3"
            >
              Add <i className="fa-solid fa-plus"></i>
            </Link>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewUserTable columns={columns} data={rows}/>
      </div>

      </div>
    </div>
  )
}

export default AdminUsers
