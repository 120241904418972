import React, { useState } from "react";

const PasswordField = ({
  label,
  id,
  placeholder,
  displayStrength = true,
  required = true,
  register,
  options = {},
  setValue = () => {},
}) => {
  const [Type, SetType] = useState("password");
  const [progress, setProgress] = useState(() => 0);
  const [password, setPassword] = useState(() => "");
  const strength = ["very-weak", "weak", "good", "strong", "very-strong"];

  const updateProgress = (password) => {
    if (!displayStrength) return;
    const numberOfConditions = () => {
      let num = 0;
      if (password?.length > 7) {
        num++;
      }
      if (/(?=.*[.@#$%])/.test(password)) {
        num++;
      }
      if (/(?=.*[A-Z])/.test(password)) {
        num++;
      }
      if (/(?=.*[a-z])/.test(password)) {
        num++;
      }
      if (/(?=.*[0-9])/.test(password)) {
        num++;
      }
      return num;
    };
    setProgress(() => numberOfConditions());
    // setValue("password", password);
  };

  const handleChange = (e) => {
    updateProgress(e.target.value);
    register(id, { ...options });
    setValue(id, e.target.value);
  };

  return (
    <div className="form-group mb-3">
      <label htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
      <div className="password-field">
        <input
          className="form-control mt-2"
          type={Type}
          name={id}
          id={id}
          placeholder={placeholder}
          {...register(id, { required, ...options })}
          required={required}
          onChange={handleChange}
        />
        {Type === "password" && (
          <i
            className="fa-solid fa-eye-slash"
            onClick={() => SetType("text")}
          ></i>
        )}
        {Type === "text" && (
          <i
            className="fa-solid fa-eye"
            onClick={() => SetType("password")}
          ></i>
        )}
      </div>
      {displayStrength ? (
        <div>
          <div className="msg">
            <span>{strength[progress - 1]}</span>
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress * 20}%` }}
              aria-valuenow={progress * 20}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PasswordField;
