import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Datatable from "components/datatable-new/DatatableNew";
import StatusButton from "components/employer/StatusButton";
import moment from "moment";
import React, { useEffect, useRef, useState, } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../index.scss";
import "../style.scss";
import AdminDashCard from "../table-card";


import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
import AdminBarGraph from "../table-card/bargraph";
import MobileViewOpportunitiesTable from "./mobileViewOpportunitiesTable";

const ActionForAdmin = ({ id, status, method, setActivate }) => {
  const activatePost = async (id, status) => {
    if (status !== 3) return toast.error("Invalid status");
    const { error, message } = await axios.patch(`/v1/${method}/status`, {
      id,
      status: 2,
      adminRole: "admin",
    });

    if (error) {
      toast.error(message);
    } else {
      setActivate((prev) => !prev);
      toast.success(`${method} activated successfully`);
    }
    // handleBlur()
  };
  const closePost = async (id, status) => {
    if (status < 1 || status > 2) return toast.error("Invalid status");
    try {
      const res = await axios.patch(`/v1/${method}/status`, {
        id,
        status: 3,
        adminRole: "admin",
      });

      if (res?.data?.error) {
        toast.error(res?.data?.message);
      } else {
        setActivate((prev) => !prev);
        toast.success(`${method} closed successfully`);
      }
    } catch (err) {
      toast.error(err.message);
    }
    // handleBlur()
  };
  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      <span
        className={`px-4 py-2 cur-pointer`}
        onClick={() => {
          navigate(`/admin/${method}/details/${id}`);
        }}
        style={{
          fontSize: "12px",
          background: "#F9B035",
          color: "#fff",
          fontWeight: "400",
          borderRadius: "20px",
        }}
      >
        Review
      </span>
      {status === 2 ? (
        <button
          className="btn bg-danger text-white py-1"
          onClick={() => closePost(id, status)}
        >
          Deactivate
        </button>
      ) : (
        <button
          onClick={() => activatePost(id, status)}
          className="btn bg-success text-white py-1"
        >
          Activate
        </button>
      )}
    </div>
  );
};

const AdminDashOpportunities = ({ data, setActivate, counts ,date,setdates}) => {
  // console.log("data77",data)
  const sortedData = data.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

 

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [temporaryDates, setTemporaryDates] = useState([date[0]]);

  const handleToggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // const handleDateChange = (ranges) => {
  //   setdates([ranges.selection])

   
  //   setCalendarVisible(false);
  // };

  const handleDateChange = (ranges) => {
    setTemporaryDates([ranges.selection]);
  };

  const handleApply = () => {

    setdates([...temporaryDates]);
    setCalendarVisible(false);
  };
  
  const datePickerRef= useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        // Clicked outside the date picker, close the picker
        setCalendarVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

 
  const navigate = useNavigate();

  const [search, setSearch] = useState(() => "");
 
  const cardsData = [
    {
      primaryText: "Opportunities",
      secondaryText: `+${data.length} this month`,
      data: data.map((item) => ({
        day: item.createdAt,
        total: data.length,
        category: item.type,
      })),
      value:data.length
    },
  ];

  // Extract unique categories
const uniqueCategories = [...new Set(cardsData[0].data.map((item) => item.category))];
// console.log('unique',uniqueCategories)

// // Create an object to store counts for each category
const categoryCounts = {};
uniqueCategories.forEach((category) => {
  categoryCounts[category] = {};
});
// Process each date string and count the number of items for each category on that day
cardsData[0].data?.forEach((item) => {
  const date = new Date(item.day);
  const fullDate = date.toLocaleDateString(undefined,{ month: 'short', day: 'numeric',year:'numeric' });
  const day = fullDate;
  // console.log("day",day)


  if (!categoryCounts[item.category][day]) {
    categoryCounts[item.category][day] = 1;
  } else {
   
    categoryCounts[item.category][day]++;
  }
});
// console.log("categorycount",categoryCounts)



// Create an array with objects for the chart data
const formattedData = Object.keys(categoryCounts).map((category) => {
  return {
    label: category === 'project' ? 'P' : (category === 'internship' ? 'I' : 'J'),
    data: Object.keys(categoryCounts[category]).map((day) => ({
      day,
      total: categoryCounts[category][day],
    })),
  };
});

// Sort the formattedData array by date in ascending order
formattedData.forEach((categoryData) => {

  categoryData.data.sort((a, b) => new Date(a.day) - new Date(b.day));

});

// console.log("formatteddata",formattedData)

const uniqueDates = Array.from(new Set(formattedData.flatMap((categoryData) => categoryData.data.map((day) => day.day))));

// Sort uniqueDates array in ascending order
uniqueDates.sort((a, b) => new Date(a) - new Date(b));

// console.log("uniquedate",uniqueDates)

// // Ensure all categories have the same dates
Object.keys(categoryCounts).forEach((category) => {
  uniqueDates.forEach((day) => {
    if (!categoryCounts[category][day]) {
      categoryCounts[category][day] = 0;
    }
  });
});

const OpportunitiesformattedDates = uniqueDates.map(date => new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }));


const chartData = {
  labels: OpportunitiesformattedDates,
  datasets: uniqueCategories.map((category, index) => ({
    label: formattedData[index].label,
    data: uniqueDates.map((day) => categoryCounts[category][day]),
    borderColor: index === 0 ? '#6149CD' : (index === 1 ? '#FF5733' : '#4CAF50'),
    backgroundColor: index === 0 ? '#6149CD' : (index === 1 ? '#FF5733' : '#4CAF50'),
    borderWidth: 2,  // Adjust the border width as needed for padding
    pointRadius: 2, // Adjust point radius for visibility
    pointHoverRadius: 4, // Adjust hover radius for visibility
    spanGaps: true,  // This will add padding by considering undefined/null values as gaps

    fill: false,
    yAxisID: 'y-axis',
  })),
};


// const chartData = {
//     labels: uniqueDates,
//     datasets: [
//       {
//         label: filterlabel[0] || 'P',
//         data: flattenedData[0],
//         borderColor: '#6149CD',
//         backgroundColor: '#6149CD',
//         fill: false,
//         yAxisID: 'y-axis', 
//       },
//       {
//         label:filterlabel[1] || 'I',
//         data: flattenedData[1],
//         borderColor: '#FF5733',
//         backgroundColor: '#FF5733',
//         fill: false,
//         yAxisID: 'y-axis', 
//       },
//      {
//         label: filterlabel[2] || 'J',
//         data: flattenedData[2],
//         borderColor: '#4CAF50',
//         backgroundColor: '#4CAF50',
//         fill: false,
//         yAxisID: 'y-axis', 
//       },
//     ],
//   };

  // console.log("chartdata",chartData)
  


  // // console.log("dataaa00",chartData.datasets)
  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: false,
          text: 'Day', // X-axis title
        },
      },
      y: [
        {
          id: 'y-axis',
          title: {
            display: false,
            text: 'Category', // Y-axis title
          },
          beginAtZero: true, // Start the y-axis from 0
        
        ticks: {
          stepSize: 2,
        },
        },
       

      ],
    },
   
   
  };



  // bar grpah

  


const topInternships = data
  .filter(data => data.enrollment_count && data.type === 'internship') 
  .sort((a, b) => parseInt(b.enrollment_count, 10) - parseInt(a.enrollment_count, 10))
  .slice(0, 5);

  // console.log("topdata",topInternships)


  const topProject=data.filter(data=>data.enrollment_count && data.type==="project").sort((a, b) => parseInt(b.enrollment_count, 10) - parseInt(a.enrollment_count, 10))
  .slice(0, 5);

  // console.log("topproject",topProject)

  const topJobs=data.filter(data=>data.total_applications && data.type==="job").sort((a, b) => parseInt(b.total_applications, 10) - parseInt(a.total_applications, 10))
  .slice(0, 5);

  // console.log("topJobs",topJobs)

  const jobdata = {
    labels: topJobs.map((account) => account.job_title
    ),
    datasets: [
      {
        label: 'Total student',
        data: topJobs.map((account) => parseInt(account.total_applications
          , 10)), //base-10 (decimal)
        borderColor: '#6149CD',
        backgroundColor: '#6149CD',
        borderWidth: 1,
        barPercentage:0.6, // Adjust the thickness of the bars
      //  categoryPercentage: 0.5, // Adjust the padding between the bars
      borderRadius: 10, // Set the radius for the top corners
      },
    ],
  };



  
  


const internshipdata = {
  labels: topInternships.map((account) => account.internship_title
  ),
  datasets: [
    {
      label: 'Total student',
      data: topInternships.map((account) => parseInt(account.enrollment_count
        , 10)), //base-10 (decimal)
      borderColor: '#6149CD',
      backgroundColor: '#6149CD',
      borderWidth: 1,
      barPercentage:0.6, // Adjust the thickness of the bars
    //  categoryPercentage: 0.5, // Adjust the padding between the bars
    borderRadius: 10, // Set the radius for the top corners
    },
  ],
};
// console.log("topintership",internshipdata)

const projectdata = {
  labels: topProject.map((account) => account.project_title),
  datasets: [
    {
      label: 'Total student',
      data: topProject.map((account) => parseInt(account.enrollment_count
        , 10)), //base-10 (decimal)
      borderColor: '#6149CD',
      backgroundColor: '#6149CD',
      borderWidth: 1,
      barPercentage:0.6, // Adjust the thickness of the bars
    //  categoryPercentage: 0.5, // Adjust the padding between the bars
    borderRadius: 10, // Set the radius for the top corners
    },
  ],
};
// console.log("toproject",projectdata)
const barOptions = {
  
  scales: {
    x: {
      display:false, // Hide x-axis labels
      position:'bottom'
    },
    y: {
      display:false, // Hide y-axis labels
      ticks: {
        autoSkip:false,
        // stepSize:2
      },
    },
  },
  indexAxis: 'x', // This controls the orientation of the bars
  plugins: {
    legend: {
      display: false, // Hide legend?
    },
   
  },
}
  


  




  const columns = [
    {
      Header: "Company Name",
      accessor: "companyName",
      width: "20%",
      textalign: "start",
      sortType: (rowA, rowB) => {
        return rowA?.original?.companyName?.toLowerCase() <
          rowB?.original?.companyName?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <div
            style={{
              textTransform: "capitalize",
            }}
            className="name-block"
          >
            <h6>{data.row.original.companyName}</h6>
            <p>{data.row.original.company_location}</p>
          </div>
        );
      },
    },
    {
      Header: "Contact Person",
      accessor: "contact_person",
      width: "15%",
      textalign: "center",
      sortType: (rowA, rowB) => {
        return rowA?.original?.contact_person?.toLowerCase() <
          rowB?.original?.contact_person?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <div
            style={{
              textTransform: "capitalize",
            }}
            className="name-block"
          >
            <h6 className="text-colored">{data.row.original.contact_person}</h6>
            <p>{data.row.original.designation}</p>
          </div>
        );
      },
    },
    {
      Header: "Contact details",
      accessor: "phone_number",
      width: "17%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.phone_number}
            <p>{data.row.original.email}</p>
          </strong>
        );
      },
    },
    // {
    //   Header: "Email",
    //   accessor: "email",
    //   width: "10%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.email}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Opportunity Name",
      accessor: "name",
      width: "20%",
      textalign: "center",
      sortType: (rowA, rowB) => {
        return rowA?.original?.name?.toLowerCase() <
          rowB?.original?.name?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <strong
            style={{
              fontWeight: "700",
            }}
          >
            {data.row.original?.name?.length > 20
              ? data.row.original.name.slice(0, 20) + "..."
              : data.row.original.name}
          </strong>
        );
      },
    },
    {
      Header: "Opportunity Type",
      accessor: "type",
      width: "8%",
      textalign: "center",
      sortType: (rowA, rowB) => {
        return rowA?.original?.type?.toLowerCase() <
          rowB?.original?.type?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700", textTransform: "capitalize" }}>
            {data.row.original.type}
          </strong>
        );
      },
    },
    {
      Header: "Date Posted",
      accessor: "date_posted",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        // also add time hh mm ss
        const dateA = rowA?.original?.date_posted
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.date_posted
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date_posted}
          </strong>
        );
      },
    },
    {
      Header: "No. of students",
      accessor: "students",
      width: "8px",
      textalign: "center",
      Cell: (data) => {
     
          // <strong style={{ fontWeight: "700" }}>
          //   {data.row.original.students}
          // </strong>
          //  if (data.row.original.type === 'project') {
            return (
            
                <strong style={{ fontWeight: "700", cursor: "pointer" }} >
                  <div onClick={()=>sendDataToProjectStudentList(data?.row?.original.id,data?.row.original.type)}>

                 {data.row.original.students}
                  </div>
                </strong>
            );
          // } else {
          //   return (
          //     <strong style={{ fontWeight: "700" }}>
          //       {data.row.original.students}
          //     </strong>
          //   );
          // }
        
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "12%",
      textalign: "center",
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <ActionForAdmin
            id={data.row.original.id}
            status={data.row.original.statusCode}
            method={data.row.original.postType}
            setActivate={setActivate}
          />
        );
      },
    },
  ];

  const map = {
    1: "Draft",
    2: "Active",
    3: "Closed",
  };

 
  const rows = sortedData
    ?.filter((post) => {
      return (
        post?.global?.employer_account?.first_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global_job?.employer_account?.first_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.project_global_fkey?.employer_account?.first_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global?.employer_account?.company?.company_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global_job?.employer_account?.company?.company_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.project_global_fkey?.employer_account?.company?.company_name
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global?.employer_account?.branch?.address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global_job?.employer_account?.branch?.address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.project_global_fkey?.employer_account?.branch?.address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global?.employer_account?.phone_number
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global_job?.employer_account?.phone_number
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.project_global_fkey?.employer_account?.phone_number
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global?.employer_account?.email_address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.global_job?.email_address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.project_global_fkey?.email_address
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        post?.type.toLowerCase()?.includes(search?.toLowerCase()) ||
        post?.[`${post?.type}_title`]
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        map[post?.status]?.toLowerCase()?.includes(search?.toLowerCase())
      );
    })
    .map((post) => {
      return {
        id: post?.id,
        companyName:
          post?.global?.employer_account?.company?.company_name ||
          post?.global_job?.employer_account?.company?.company_name ||
          post?.project_global_fkey?.employer_account?.company?.company_name,
        company_location:
          post?.global?.employer_account?.branch?.address ||
          post?.global_job?.employer_account?.branch?.address ||
          post?.project_global_fkey?.employer_account?.branch?.address,
        phone_number:
          post?.global?.employer_account?.phone_number ||
          post?.global_job?.employer_account?.phone_number ||
          post?.project_global_fkey?.employer_account?.phone_number,
        email:
          post?.global?.email_address ||
          post?.global_job?.email_address ||
          post?.project_global_fkey?.email_address ||
          "N/A",
        contact_person:
          post?.global?.employer_account?.first_name ||
          post?.global_job?.employer_account?.first_name ||
          post?.project_global_fkey?.employer_account?.first_name,
        name: post[`${post.type}_title`],
        designation:
          post?.global?.employer_account?.designation ||
          post?.global_job?.employer_account?.designation ||
          post?.project_global_fkey?.employer_account?.designation ||
          "N/A",
        type: post?.type,
        date_posted: moment(post?.createdAt).format("DD/MM/YYYY"),
        students: post?.enrollment_count|| post?.total_applications,
        status: <StatusButton status={map[post?.status]} />,
        statusCode: post?.status,
        postType: post?.type,
      };
    });


    const sendDataToProjectStudentList=async(Id,type)=>{
      
    navigate(`/admin/${type}/student-list/${Id}`);

    }

  return (
    <div className="admin-dash-companies">
      <div className="row flex-nowrap overflow-auto graphcardgap">

        {cardsData.map((card, index) => {
          return (
            <div className="col-12 col-md-3" key={index}>
              <AdminDashCard
                data={chartData}
                option={options}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
              
            </div>
          );
        })}
      
       
        <div className="col-12 col-md-3">
        <AdminBarGraph
         title={"Top 5 Internships"}
        data={internshipdata}
        option={barOptions}
        />

        </div>
        <div className="col-12 col-md-3">
        <AdminBarGraph
        title={"Top 5 Projects"}
        data={projectdata}
        option={barOptions}
        />

        </div>

        <div className="col-12 col-md-3 ">
        <AdminBarGraph
        title={"Top 5 jobs"}
        data={jobdata}
        option={barOptions}
        />

        </div>
   

      </div>

      <div className="datatable-container mt-4">
        <div className="table_head flex">
          <div className="mobo_upload">
            <h3 className="new-class-active">
              <div>
                <h3>Opportunities</h3>
                <span className="light new-span-count">
                  {sortedData.length || 0} records{" "}
                  <div className="new-type">
                    <div className="total-count">
                      Projects - {counts?.projects?.activeCount || 0} /{" "}
                      {counts?.projects?.totalCount || 0}
                    </div>
                    <div className="total-count">
                      Internships - {counts?.internships?.activeCount || 0} /{" "}
                      {counts?.internships?.totalCount || 0}
                    </div>
                    <div className="total-count">
                      Jobs - {counts?.jobs?.activeCount || 0} /{" "}
                      {counts?.jobs?.totalCount || 0}
                    </div>
                  </div>
                </span>
              </div>
            </h3>

            <Link to="" className="upload_btn_icon_mobo btn btn-primary">
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non"ref={datePickerRef}>
            {/* date range */}
            <div  >
      <span className="light new-span-count" style={{color: '#7a86a1'}}>select date range</span>

      <div className="date-input-container" >

        <input
           className="myinput"
        // style={{ width: 'auto',
        //   padding: '5px 10px',
        //   alignSelf: 'baseline',
        //   border: '1px solid #6149cd',
        //   borderRadius: '8px',
        // cursor:'pointer'}}
       
          type="text"
          // value={`${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`}
          value={
            date[0]?.startDate && date[0]?.endDate
              ? `${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`
              : 'Select Date Range'
          }
          onClick={handleToggleCalendar}
          readOnly
        />
         {calendarVisible && (
        <div className="mydiv">
          {/* <DateRangePicker
            ranges={date}
            showPreview={false}
            onChange={handleDateChange}

            
          /> */}
           <DateRangePicker ranges={temporaryDates} showPreview={false} onChange={handleDateChange}
           />
           <div className=" applybtn-div">
           <button className="rdrApplyButton" onClick={handleApply}>Apply</button>
           </div>
        </div>
      )}
        
      </div>
     
        <style>
        {`
          /* Place the CSS here */
         
          .rdrStaticRangeLabel {
            color:#000000

          }
          


          /* Add the rest of the CSS styles here */
        `}
      </style>
              </div>
         


            
            {/*  End date Rage */}
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>

        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewOpportunitiesTable columns={columns} data={rows} ActionForAdmin={ActionForAdmin} setActivate={setActivate}/>
      </div>


        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  );
};

export default AdminDashOpportunities;
