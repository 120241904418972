import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./services/store.js";
import "./worker.js";


// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";
import image from "./view/newWeb/qollabb_logo1.png"

import App from "./App";
import { Helmet } from "react-helmet";

import { fetchUser, removeUser } from "./services/actions/auth.js";
const user = !!localStorage.getItem("user");
if (user) {
  store.dispatch(fetchUser());
} else {
  store.dispatch(removeUser());
}

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <Helmet>
        <meta property="og:title" content={"Qollabb"} />
        <meta property="og:description" content={"Connecting Companies With Brilliant Minds in Campuses"} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={"https://qollabb.com/"} />
        <meta property="og:type" content="website" />

        <title>Qollabb</title>
      </Helmet>
      <App />
    </Provider>
  </StrictMode>
);
