import axios from "axios"
import { downloadCSV } from "components/dataTable/Export"
import Datatable from "components/datatable-new/DatatableNew"
import Loader from "components/employer/Loader"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { generatePDF } from "view/dashboard/student/application/GenerateProjectCertificate"
import certificateSign from "../../../../../images/certificate-sign.png"
import mainLogo from "../../../../../images/logos/logo-main.png"
import "../../tables/style.scss"
import MobileViewEvaluationsEvaluatedTable from "./MobileViewEvaluationEvaluated"

const EvaluationsEvaluated = () => {
  const [search, setSearch] = useState(() => "")
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(() => true);


  useEffect(() => {
    const getAll = async () => {
      try {
        setLoading(true); 
        const res = await axios.post("/v1/evaluation/getEvaluationsDetails",{status:3});
  
        
        // console.log(res?.data?.data, "res");
        // console.log(res?.data?.companydetails, "res2");
          setData(res?.data?.data);
          setCompanyData(res?.data?.companydetails)
        setLoading(false); 
   

      } catch (err) {
        throw err;
      }
    };
    getAll();

    return () => {};
  }, []);

// console.log("companydata",companyData)

    const handleDownload = (rowData) => {
  // console.log("pdf",rowData)
  const filteredCompanyData  = companyData.filter((company) => company.company_id === rowData.companyId);
  const companyDetails = filteredCompanyData[0];
   // console.log("logo",companyDetails)
      generatePDF({
        company_name: companyDetails?.company?.company_name,  
        project_title: rowData?.project_name,
        completion_date: new Date(rowData?.completion_date).toLocaleDateString('en', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
      }),
        rating: rowData?.rating,
        student_name: rowData?.student_name,
        qollabbLogo:mainLogo,
        companyAddress: companyDetails?.location,
        certificateSign: certificateSign,
        secondCompanyLogo: companyDetails?.company_logo,
      });
    };



  const columns = [
    {
      Header: "Date Evaluated",
      accessor: "date_Evaluated",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.date_Evaluated}
          </strong>
        );
      },
    },
    {
      Header: "Mentor Name",
      accessor: "mentor_name",
      width: "12.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.mentor_name}
          </strong>
        );
      },
    },
    {
      Header: "Student Name",
      accessor: "student_name",
      width: "12.5%",
      textalign: "start",
    },
    {
      Header: "Subscription Plan",
      accessor: "subscription_plan",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.subscription_plan}
          </strong>
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "project_name",
      width: "20%",
      textalign: "start",
    },
    {
      Header: "Rating",
      accessor: "rating",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.rating}
          </strong>
        )
      },
    },
    
    {
      Header: "Certificate",
      accessor: "certificate",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <button className="deactivate-btn" style={{ fontWeight: "500" }}
          onClick={() => handleDownload(data?.row.original)} >
            Download
          </button>
        )
      },
    },
  ];
  // // console.log(data, "hdh");
  const rows = data
    ?.filter((post) => {
      return post?.student?.first_name?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      // // console.log(post, "post");
      return {
        id: post?.id,
        date_Evaluated: moment(post?.mentor_evaluated_date)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
        student_name:
          post?.student?.first_name + " " + post?.student?.last_name,
        project_name: post?.enrollment?.project?.project_title,
        companyId:post?.enrollment?.project?.company_id,
        date_of_request: moment(post?.requested_date)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
          subscription_plan: post?.subscription?.subscription_database?.subscription_name || "-",
         rating: post?.overall_rating,
        mentor_name: post?.mentor
          ? post?.mentor?.first_name + " " + post?.mentor?.last_name
          : "Not Assigned",
        certificate: post,  
        completion_date:moment(post?.enrollment?.final_submission_report
        ?.final_submission_report_date)
      };
    });




 
    if (loading) return <Loader/>;

  return (
    <div className="admin-dash-companies p-2">
      <div className="datatable-container">
        <div className="table_head flex">
          <div className="mobo_upload p-1">
            <h3>
              Evaluated <br />{" "}
              <span className="light">{data?.length || 0} records</span>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <Link
              to="/employer/internship/add"
              className="upload_btn_icon btn btn-primary text-white px-3"
            >
              Add <i className="fa-solid fa-plus"></i>
            </Link>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

        {/* <Datatable columns={columns} data={rows} /> */}
        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewEvaluationsEvaluatedTable columns={columns} data={rows} handleDownload={handleDownload}/>
      </div>

        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  )
}

export default EvaluationsEvaluated
