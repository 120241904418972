import React from 'react'
import TopNavBar from './components/TopNavbar/TopNavBar'
import NavBar from './components/navbar/NavBar'
import Footer from 'view/dashboard/student/footer/Footer'
import GoToTop from 'GoToTop'
import TermsAndConditions from './Policies/TermsAndConditions'
import { Helmet } from 'react-helmet'

const TermsAndConditionPage = () => {
  return (
    <div className="home_page row justify-content-center align-items-center">
        <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content={`Qollabb's Terms and Conditions outline the rules and guidelines for using our platform and services. By accessing Qollabb, users agree to comply with these terms.`}
        />
            <meta
              name="title"
              content={"Term and Conditions"}
            />
        <title>
          Term and Conditions
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
          <TopNavBar />
          <NavBar />
          <TermsAndConditions/>
          <Footer />
      <GoToTop />
    </div>
  )
}

export default TermsAndConditionPage