import { useState } from "react";
import Checkbox from "./Checkbox";

export const defaultValues = [
  {
    id: "remote",
    label: "Remote/Virtual ",
  },
  {
    id: "office",
    label: "Office/Factory Based",
  },
  {
    id: "field",
    label: "Field Work Based",
  },
];

const CategoryField = ({
  items = defaultValues,
  register,
  id,
  label,
  subLable,
  secondaryLabel,
  isOffice = true,
  getValues,
  defaultCheck = "office",
  isRemote = false,
  setIsRemote = () => {},
  setFlag = () => {},
  remoteOfficeVisit = false,
  setRemoteOfficeVisit = () => {},
  setDefaultType,
}) => {
  const updateRemote = (id) => {
    setDefaultType(id);
    if (id === "remote") {
      setIsRemote(true);
    } else {
      setIsRemote(false);
    }
  };

  return (
    <div className="form-group mb-3">
      <label>
        {label}
        {items[0].required && <span>*</span>}
        <small>{secondaryLabel}</small>
      </label>
      <div className="row">
        {items?.map((item) => (
          <div className="radio col-2" key={item.id}>
            <input
              className="form-check-input me-2"
              type="radio"
              id={item.id}
              name={id}
              value={item.id}
              {...register(id, { ...item.options })}
              onChange={() => updateRemote(item.id)}
              defaultChecked={defaultCheck === item.id}
            />
            <label
              className="form-check-label"
              htmlFor={item.id}
              style={{ fontSize: "12px", fontWeight: "400" }}
            >
              {item.label}
            </label>
          </div>
        ))}
        {isRemote && isOffice ? (
          <Checkbox
            register={register}
            id="office_visit_remote"
            getValues={getValues}
            setFlag={setFlag}
            setIsRemote={setIsRemote}
            // onChange={{ ...register("office_visit", { ...items[1].options }) }}
            myClass="form-check mb-3 align-items-center d-flex ms-4 mt-2"
            label={subLable}
            isSmall={true}
            checked={remoteOfficeVisit}
            setChecked={setRemoteOfficeVisit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CategoryField;
