import { useState, useMemo, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "components/employer/Loader";
import { useSelector } from "react-redux";
import AdminDashCompanies from "./tables/companies";
import AdminDashOpportunities from "./tables/opportunities";
import AdminDashStudents from "./tables/students";
import AdminDashMentors from "./tables/mentors";
import AdminDashColleges from "./tables/colleges";
import axios, { get } from "axios";
import moment from "moment";

const AdminDashboardHome = () => {
  const user = useSelector((state) => state.auth.user);

  // const today = moment(); // Get the current date
  // const lastWeek = moment(today).subtract(6, 'month');
 
  // // console.log("timeeee",today,lastWeek)

  // const defaultStartDate = lastWeek.toDate(); // Set your default start date
  // const defaultEndDate = today.toDate(); // Set your default end date

  const [dateRange, setDateRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection',
    },
  ]);

  // console.log("hhh",dateRange)


  const [activate, setActivate] = useState(() => false);
  const [counts, setCounts] = useState(() => ({}));
  // const [posts, , loading] = useOutletContext();
  const [loading, setLoading] = useState(() => true);
  const tabs = [
    "Companies",
    "Opportunities",
    "Students",
    "Colleges/universities",
    "Mentors",
  ];
  const [activeTab, setActiveTab] = useState(() => 0);
  const [data, setData] = useState(() => ({
    Companies: [],
    Opportunities: [],
    Students: [],
    Colleges: [],
    Mentors: [],
  }));
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await axios.get("/v1/employer")
  //     setData({ ...data, Companies: res?.data?.data })
  //   }
  //   fetchData()
  // }, [])

  // i face issue maximum update depth exceeded here
  useEffect(() => {
   
    const getAll = async () => {

      setLoading(true); 
      const formattedDateRange = dateRange.map(({ startDate, endDate }) => ({
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
     
      }));
         // Check if there is a valid date range
    const isValidDateRange = formattedDateRange.some(({ startDate, endDate }) => startDate && endDate);
    // console.log("isValidDateRange",isValidDateRange)

    const params = isValidDateRange ? { params: { date: formattedDateRange } } : {};
    // console.log("isValidDateRangeparamsparamsparams",params)

      // const formattedDateRange = dateRange[0].startDate && dateRange[0].endDate ? dateRange.map(({ startDate, endDate }) => ({
      //   startDate: moment(startDate).format('YYYY-MM-DD'),
      //   endDate: moment(endDate).format('YYYY-MM-DD'),
      // })) : null; 

      // const internship = get("/v1/internship/all",{params: { date: formattedDateRange }});
      // const job = get("/v1/job/all",{params: { date: formattedDateRange }});
      // const project = get("/v1/project/all",{params: { date: formattedDateRange }});

     
      // const employer = get("/v1/employer",{params: { date: formattedDateRange }});
      // const student = get("/v1/student/all",{params: { date: formattedDateRange }});
      // const mentor = get("/v1/mentor",{params: { date: formattedDateRange }});
      // const educator = get("/v1/educator/all",{params: { date: formattedDateRange }});

      const internship = get("/v1/internship/all", params);
      const job = get("/v1/job/all", params);
      const project = get("/v1/project/all", params);
      const employer = get("/v1/employer", params);
      const student = get("/v1/student/all", params);
      const mentor = get("/v1/mentor", params);
      const educator = get("/v1/educator/all", params); 

      await axios
        .all([internship, job, project, employer, student, mentor, educator])
        .then(
          axios.spread((...responses) => {
            const internships =
              responses[0]?.data?.data?.map((x) => ({
                ...x,
                type: "internship",
              })) || [];
            const jobs =
              responses[1]?.data?.data?.map((x) => ({
                ...x,
                type: "job",
              })) || [];
            const projects =
              responses[2]?.data?.data?.map((x) => ({
                ...x,
                type: "project",
              })) || [];
            const employers = responses[3]?.data?.data || [];
            const students = responses[4]?.data?.data || [];
            const mentors = responses[5]?.data?.data || [];
            const educators = responses[6]?.data?.data || [];
            setCounts((prev) => {
              return {
                ...prev,
                projects: {
                  activeCount: responses[2]?.data?.activeProjects,
                  closedProjects: responses[2]?.data?.closedProjects,
                  totalCount: responses[2]?.data?.totalCount,
                },
                jobs: {
                  activeCount: responses[1]?.data?.activeJobs,
                  closedJobs: responses[1]?.data?.closedJobs,
                  totalCount: responses[1]?.data?.totalCount,
                },
                internships: {
                  activeCount: responses[0]?.data?.activeInternships,
                  closedInternships: responses[0]?.data?.closedInternships,
                  totalCount: responses[0]?.data?.totalCount,
                },
                students: {
                  activeStudent: responses[4]?.data?.activeStudent,
                  inactive: responses[4]?.data?.inactiveStudents,
                  b2bstudents: responses[4]?.data?.b2bstudents,
                },
                colleges: {
                  activeColleges: responses[6]?.data?.activeColleges,
                  inactive: responses[6]?.data?.inactiveColleges,
                  totalAssignStudents: responses[6]?.data?.totalAssignCount,
                  projectAssignStudents: responses[6]?.data?.projectAssignCount,
                  jobAssignStudents: responses[6]?.data?.jobAssignCount,
                  internshipAssignStudents:
                    responses[6]?.data?.internshipAssignCount,
                },
                mentors: {
                  activeMentors: responses[5]?.data?.activeMentors,
                  inactive: responses[5]?.data?.inactiveMentors,
                  projectevalution:responses[5]?.data?.evaluation,
                  totalMentorCounts:responses[5]?.data?.totalMentorCounts
                },
              };
            });

            setData((p) => ({
              ...p,
              Opportunities: [...projects, ...internships, ...jobs],
              Companies: employers,
              Students: students,
              Mentors: mentors,
              Colleges: educators,
            }));

            setLoading(false);
          })
        )
        .catch(() => {
          setLoading(false);
        });
    };
    // getDates()
    getAll();

  }, [activate,dateRange]);

  if (loading) return <Loader />;

  // console.log(counts, "counts");

  return (
    <div
      className="content-container p-2"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh", overflow:'hidden', background:'#EBEFF7'}}
    >
      <div className="row">
        <div className="col-12 ">
          <div className="table-tabs">
            <nav>
              <div
                className="nav_tabs nav nav-tabs tabview"
                id="nav-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <button
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className={`nav-link ${
                      activeTab === index ? "active" : ""
                    }`}
                    id={`nav-${tab}-tab`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${tab}`}
                    aria-selected="true"
                    onClick={() => setActiveTab(index)}
                    key={tab}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </nav>

            <div
              className="tab-pane fade show active"
              id={`nav-${activeTab}`}
              role="tabpanel"
              aria-labelledby={`nav-${activeTab}-tab`}
            >
              <LoadTable
                setActivate={setActivate}
                activeTab={activeTab}
                data={data}
                counts={counts}              
                date={dateRange}
                setdates={setDateRange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardHome;

const LoadTable = ({ setdates,date,activeTab, data, setActivate, counts }) => {

  switch (activeTab) {
    case 0:
      return (
        <AdminDashCompanies
          setActivate={setActivate}
          data={data["Companies"]}
          date={date}
          setdates={setdates}     
        />
      );
    case 1:
      return (
        <AdminDashOpportunities
          setActivate={setActivate}
          data={data["Opportunities"]}
          counts={counts}
          date={date}
          setdates={setdates}
        />
      );
    case 2:
      return (
        <AdminDashStudents
          counts={counts}
          setActivate={setActivate}
          data={data["Students"]}
          date={date}
          setdates={setdates}
        />
      );
    case 3:
      return (
        <AdminDashColleges
          setActivate={setActivate}
          counts={counts}
          data={data["Colleges"]}
          date={date}
          setdates={setdates}
        />
      );
    case 4:
      return (
        <AdminDashMentors
          counts={counts}
          setActivate={setActivate}
          data={data["Mentors"]}
          date={date}
          setdates={setdates}
          
        />
      );
    default:
      return <AdminDashCompanies data={data["Companies"]} />;
  }
};
