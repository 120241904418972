import Applications from "./Applications";
import ApplicationsSubmited from "./ApplicationsSubmited";
import Hired from "./Hired";
import Rejected from "./Rejected";
import Shortlisted from "./Shortlisted";

const ShowSelecetdTab = ({
  tab = 0,
  post = {},
  enrollments = {},
  handleStatusChange,
  dispatch,
}) => {
  // status
  // 0: Applied
  // 1: Left
  // 2: Seen
  // 3: Under Review
  // 4: Shortlisted
  // 5: In touch
  // 6: Hired
  // 7: Rejected

  const getFiltered = (status) => {
    const filtered = enrollments?.data?.filter(
      (item) => item.status === status
    );
    return {
      count: enrollments?.count,
      data: filtered,
      length: filtered?.length,
    };
  };

  switch (tab) {
    case 0:
      return (
        <Applications
          post={post}
          enrollments={enrollments}
          handleStatusChange={handleStatusChange}
          dispatch={dispatch}
        />
      );
    case 1:
      return (
        <ApplicationsSubmited
          post={post}
          enrollments={getFiltered(2)}
          handleStatusChange={handleStatusChange}
          dispatch={dispatch}
        />
      );
    case 2:
      return (
        <Shortlisted
          post={post}
          enrollments={getFiltered(4)}
          handleStatusChange={handleStatusChange}
          dispatch={dispatch}
        />
      );
    case 3:
      return (
        <Rejected
          post={post}
          enrollments={getFiltered(7)}
          handleStatusChange={handleStatusChange}
          dispatch={dispatch}
        />
      );
    case 4:
      return (
        <Hired
          post={post}
          enrollments={getFiltered(6)}
          handleStatusChange={handleStatusChange}
          dispatch={dispatch}
        />
      );
    case 5:
      return <div>Assignments</div>;
    case 6:
      return <div>Interviews</div>;
    default:
      return <div>Applications</div>;
  }
};

export default ShowSelecetdTab;
