import AppliedPostCard from "components/cards/AppliedPostCard";

import SuggestedProject from "./home_cards/sections/Suggested";
// import EducatorPostCard from "components/cards/EducatorPostCard"
// import useFetch from "hooks/useFetch"
import React from "react";
import { useNavigate } from "react-router-dom";

const SuggestedProjects = ({ data, method }) => {
  const navigate = useNavigate();
  // const pro = useFetch(`/v1/filter/project?page=1&limit=3`)
  // console.log(data, "4321");
  const project_data = data?.data?.items;
  // console.log(project_data, "4321");
  // // console.log("asdad", data)
  const button_color = {
    backgroundColor: "#6149CD",
  };

  return (
    <>
      {project_data?.length ? (
        <div className="suggested_project">
          <div className="sug_projects">
            <div className="suggested_project">
              <h2>Suggested Projects</h2>
              <div className="row">
                {project_data?.map((item) => {
                  return (
                    <AppliedPostCard
                      button_color={button_color}
                      item={item}
                      method={method}
                      btn="Enroll Now"
                    />
                  );
                })}
              </div>
            </div>
            <div className="row" id="explore">
              <div className="col-12">
                <button
                  onClick={() => {
                    navigate("/student/explore/*", {
                      state: {
                        activeTab: 0,
                      },
                    });
                  }}
                >
                  Explore More
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuggestedProject activeTab={0} method={method}></SuggestedProject>
      )}
    </>
  );
};

export default SuggestedProjects;
