import PreviewField from "components/employer/PreviewField";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import cmp from "./company.png";
import useFetch from "hooks/useFetch";
import "./applicationJobDetail.scss";
import ListContainer from "components/employer/company_form/ListContainer";
import PostDetailsSection from "components/posts/PostsHelper/PostDetailsSection";
import LinkedProjectCard from "components/posts/PostsHelper/LinkedProjectCard";
import RightCard from "components/posts/PostsHelper/RightCard";
import axios from "axios";

const ApplicationJobDetail = () => {
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const navigate = useNavigate();



  const [moreJobs, setMoreJobs] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);



  const handleDataNavigation = (data) => {
    if (!data?.data?.data?.enrollment) {
      navigate("/student/my-applications");
    }
    return <></>;
  };
  const { data, loading, error, setFetchData } = useFetch(
    `/v1/enrollments/job/getEnrollment/${id}`,
    {},
    [],
    handleDataNavigation
  );

  // console.log(data, "w123");
  const employees = {
    0: "1-50",
    1: "51-250",
    2: "251+",
  };

  const tab = {
    0: "Applied",
    4: "Shortlisted",
    6: "Hired",
    7: "Rejected",
    8: "Exit",
  };

  const ColorTab = {
    0: "#f9b035",
    4: "#6149cd",
    6: "#47d065",
    7: "#ff3737",
    8: "#ff3737",
  };

  const jobData = data?.data?.enrollment?.job;
  const jobEnroll = data?.data?.enrollment;



  useEffect(()=> {

    const fetchMoreJobs = async ()=> {
        try {
            const res = await axios.post(`/v1/filter/job/company/${jobData?.global_job?.id}`, {
                job_id: jobData?.id
            });

            // console.log("proojeeectt", res);

            setMoreJobs(res?.data?.data);
        } catch (error) {
            // console.log(error);
        }
    }

    const fetchSimilarJobs = async ()=> {
        try {
            const res = await axios.get(`/v1/filter/job/similar-jobs/${jobData?.id}`);

            // console.log("similarrrress", res?.data);

            setSimilarJobs(res?.data?.data?.similarJobs);
        } catch (error) {
            // console.log(error);
        }
    }

    if(jobData?.id){
      fetchMoreJobs();
    fetchSimilarJobs();

    }

    

}, [jobData?.id]);

  // console.log(data,"hello world")
  // console.log(jobData,"hello world1")
  // console.log(jobEnroll,"hello world22")

  // const company = useSelector((state) => state?.application?.company[1]);
  // if (!loading && !jobData) navigate("/student/my-applications");
  return (
    <div className="application_job_preview_container container">
      <div className="preview_box">
        <section className="post_container">

          <PostDetailsSection data={jobData} type={"job"} />



        </section>
        <section className="action_container">
          {/* {children} */}
          <div className="company_container">
            <div className="go_to_chat_btn">
              <button
                className="mobo_btn btn btn-success text-white w-100 py-2"
                style={{ backgroundColor: ColorTab[jobEnroll?.status],border:'none' }}
              >
                {tab[jobEnroll?.status]}
              </button>
            </div>

              <div className='right-container d-flex flex-column gap-4 mt-3'>
                    {
                        jobData?.project_linked > 0 &&(

                            <LinkedProjectCard project={jobData?.project} type={"Job"}  />

                        ) 
                        
                    }

                    <RightCard title={"More Jobs Offered By "} highlightedText={jobData?.global_job?.employer_account?.company?.company_name} data={moreJobs} type="job" companyId={jobData?.global_job?.employer_account?.company?.id} seeall={true} />

                    <RightCard title={"We Found More Similar "} highlightedText={"Jobs For You"} data={similarJobs} type="job" seeall={true} />

                </div>

            {/* <div className="right_company_data">
              <div className="upper_data">
                <div className="d-flex align-items-center my-2 gap-4">
                  <img
                    src={jobData?.branch?.company_logo}
                    width={60}
                    alt="Image"
                    className="rounded"
                  />
                  <h2 className="m-0 px-2">
                    {jobData?.branch?.company_database?.company_name}.
                  </h2>
                </div>
                <p className="d-flex align-items-center justify-content-end">
                  <i className="fa fa-location"></i>
                  {jobData?.branch?.address}
                </p>
              </div>

              <div className="company_body">
                <div className="body_item flex">
                  <h6>Website</h6>
                  <span className="web_url">
                    {jobData?.global_job?.employer_account?.website_url}
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Founding Year</h6>
                  <span>
                    {
                      jobData?.global_job?.employer_account
                        ?.year_of_incorporation
                    }
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Name of CEO</h6>
                  <span>{jobData?.global_job?.employer_account?.ceo_name}</span>
                </div>
                <div className="body_item flex">
                  <h6>No. of Employees</h6>
                  <span>
                    {
                      employees[
                        jobData?.global_job?.employer_account?.employee_strength
                      ]
                    }
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Industry Sector</h6>
                  <span>
                    {
                      jobData?.global_job?.employer_account?.industry
                        ?.industry_name
                    }
                  </span>
                </div>
              </div>
            </div>
            {company?.services?.length ? (
              <div className="service-list">
                <h5 className="list_header">Products & Services</h5>
                <ListContainer Items={company.services} isPreview={true} />
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ApplicationJobDetail;
