import axios from 'axios'
import useOutsideClick from 'hooks/useOutsideClick'
import React, { useEffect, useRef, useState } from 'react'
import "./NewProjectDetails.scss"
import LinkedProjectCard from './PostsHelper/LinkedProjectCard'
import NewPreviewField from './PostsHelper/NewPreviewField'
import RightCard from './PostsHelper/RightCard'
import TopCard from './PostsHelper/TopCard'
import PostDetailsSection from './PostsHelper/PostDetailsSection'

const NewJobDetailsPage = ({  
    data,
    Action,
    children,
    onApply,}) => {
    
    const [moreJobs, setMoreJobs] = useState([]);
    const [similarJobs, setSimilarJobs] = useState([]);

    useEffect(()=> {

        const fetchMoreJobs = async ()=> {
            try {
                const res = await axios.post(`/v1/filter/job/company/${data?.global_job?.id}`, {
                    job_id: data?.id
                });

                // console.log("proojeeectt", res);

                setMoreJobs(res?.data?.data);
            } catch (error) {
                // console.log(error);
            }
        }

        const fetchSimilarJobs = async ()=> {
            try {
                const res = await axios.get(`/v1/filter/job/similar-jobs/${data?.id}`);

                // console.log("similarrrress", res?.data);

                setSimilarJobs(res?.data?.data?.similarJobs);
            } catch (error) {
                // console.log(error);
            }
        }

        fetchMoreJobs();
        fetchSimilarJobs();

    }, []);

    const [show, setShow] = useState(false);

    const toggleDropdown = () => setShow((prev) => !prev);

    const ref = useRef(null)
    useOutsideClick(ref, ()=> setShow(false), show)

    // console.log("oouterr", moreJobs)

    // console.log("jjjjdataaa", data);
  return (
    <div className='details-wrapper pt-4'>
        <div className='container d-flex gap-4'>
            <div className="col-md-9">
                <PostDetailsSection data={data} children={children} type={"job"} />
            </div>

            <div className="right-section col-3">
                <div className='right-container d-flex flex-column gap-4'>
                    {
                        data?.project_linked > 0 &&(

                            <LinkedProjectCard project={data?.project} type={"Job"}  />

                        ) 
                        
                    }

                    <RightCard title={"More Jobs Offered By "} highlightedText={data?.global_job?.employer_account?.company?.company_name} data={moreJobs} type="job" companyId={data?.global_job?.employer_account?.company?.id} seeall={true} />

                    <RightCard title={"We Found More Similar "} highlightedText={"Jobs For You"} data={similarJobs} type="job" seeall={true} />

                </div>
            </div>

        </div>
    </div>
  )
}

export default NewJobDetailsPage;