import axios from "axios"
import MentorMobileDataTable from "components/datatable-new/mentorMobileDataTable/MentorMobileDataTable"
import Loader from "components/employer/Loader"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import StatusProgress from "view/dashboard/student/application/StatusProgress"
import WeekDetail from "view/dashboard/student/application/WeekDetail"
import downloadIcon from "../../../../images/download1.png"
import "../sessionRequest.scss"
import "./studentDetail.scss"

const StudentDetail = () => {
  const [getData, setGetData] = useState([]);
  const [getSessionData, setGetSessionData] = useState([]);
  const [today, setToday] = useState("");
  const [status,setStatus] = useState()
  const [loading, setLoading] = useState(false);

  const [studentDetails, setStudentDetails] = useState({})
  
  const {id} = useParams();

  // console.log("id", id)

  // console.log(status, "status");

  // const booking_date = "04-27-2023";
   
  useEffect(() => {
    const date = new Date();
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options).replace(/\//g, "-");
    setToday(formattedDate);

    async function getStudent(){
      setLoading(true);
      const data = await axios.get(`/v1/mentor/getStudents/${id}`)

      // console.log("student", data);

      setStudentDetails(data?.data)

      setLoading(false);
    }
    getStudent();


  }, [today]);

  // console.log("details..", studentDetails)
  // console.log(today, "today");
  

  // calculate age
  let age;
  if (studentDetails?.data?.student?.date_of_birth) {
    const dob = studentDetails?.data?.student?.date_of_birth;
    const dobDate = new Date(dob);
    const diffMs = Date.now() - dobDate.getTime();
    age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  }

  // formate experience
  const DateType = (value) => {
    // return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ");
    const dateObject = new Date(value);
    const year = dateObject.getFullYear();
    const monthAndDate = dateObject.toDateString().split(" ").slice(1, 3).join(" ");
  
    return `${monthAndDate}, ${year}`;
  
  };

  // calculate realtive time
  const calculateRelativeTime = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };
  
    useEffect(() => {
      axios.post("/v1/mentor/getSessions",{session_date:today})
        .then(response => {
          setGetData(response?.data?.data);
        })
        .catch(error => {
          console.error(error);
        });
    }, [today]);
  
    useEffect(() => {
      axios.post("/v1/mentor/getSessions",{status:status})
        .then(response => {
          setGetSessionData(response?.data?.data);
        })
        .catch(error => {
          console.error(error);
        });
    }, []);

  // console.log(getData,"getData",getSessionData,status);

  
  const data3 = [
    {
      innerData: [
        { columns: "Date Completed", date: "11 Oct2022" },
        {
          columns: "Time",
          date: "12:00 PM",
        },
        {
          columns: "Duration",
          date: "32 mins",
        },
      ],
    },
    {
      innerData: [
        { columns: "Date Completed", date: "11 Oct2022" },
        {
          columns: "Time",
          date: "12:00 PM",
        },
        {
          columns: "Duration",
          date: "32 mins",
        },
      ],
    },
  ]

  if(loading) return <Loader />

  return (
    <section className="student-detail">
      <div className="mx-0 mx-sm-4 py-0 py-sm-4">
        <div className="w-100 m-0 row">

        {
          studentDetails && (

          <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="job-description">
              <i
                class="fa fa-arrow-left d-flex d-sm-none fs-3"
                aria-hidden="true"
              ></i>
              <div className="firsttab flex d-none d-sm-flex">
                <h2>{""}</h2>
                <div>
                  <img
                    style={{
                      width: "16px",
                      height: "20px",
                      marginRight: "25px",
                    }}
                    src={downloadIcon}
                    alt="download icon"
                  />
                  <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </div>
              </div>

              <div className="secondtab">
                <div className="flex">
                  <div className="student_resume_data">
                    <div
                      style={{ width: "64px", height: "64px" }}
                      className="img_div"
                    >
                      {/* {data?.student?.first_name} */}
                      <img src={studentDetails?.data?.student?.profile_photo} alt="Image" />
                    </div>
                    <div className="student_content">
                      <div className="heading_content">
                        <span className=" h2">
                          {studentDetails?.data?.student?.first_name} {studentDetails?.data?.student?.last_name}
                        </span>
                        <span className="light">{calculateRelativeTime(studentDetails?.data?.createdAt)}</span>
                      </div>

                      <p className="add">{studentDetails?.data?.student?.location}</p>
                      <p className="star">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <p>5.00</p>
                      </p>
                    </div>
                    {/* <div className="New_student">
                      <p>New</p>
                    </div> */}
                    {Date.now() - new Date(studentDetails?.data?.createdAt).getTime() <
                      1000 * 60 * 60 * 24 ? (
                        <div  className="New_student">
                          <p>New</p>
                        </div>
                      ) : (
                        <></>
                      )}
                  </div>
                  {/* <div className="student_resume_data">
           
              <div style={{width: '64px',height:"64px"}} className="img_div">
                <img src={rahulImg} alt="Image" />
              </div>
            <div className="student_content">
              <div className="student_content">
                <span className="h2">
                  {data?.student?.first_name} {data?.student?.last_name}
                </span></div>
                <span className="light">{date}</span>
                <button className="new">New</button>
                <p className="add">Noida, UP, India</p>
                <p className="star">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </p>
              </div>
          </div> */}
                  {/* <button className="chatbtn interview">
            <i className="fa-regular fa-comment-dots"></i>
            Start Chat
          </button>
          <button className="interview">
            <i className="fa-regular fa-calendar"></i> Interview
          </button> */}
                </div>
                <div className="tab-btn">
                  <div className="tags">
              {
                studentDetails?.data?.student?.user_skills ? (
                  studentDetails?.data?.student?.user_skills?.map((item, i)=> {
                    return (
                      <span key={i} >
                        {item}
                      </span>
                    )
                  })
                ) : (
                  <span>No Skills</span>
                )
              }   
                    {/* <span>UI Designer</span>
                    <span>Figma</span>
                    <span>Landing Page</span> */}
                  </div>
                  <div className="bio">
                    {/* <p>
                      <i className="fa-solid fa-graduation-cap"></i>{" "}
                      <span>BCA</span>
                    </p> */}
                    <p>
                      <i className="fa-solid fa-cake-candles"></i>
                      <span>{age} Years Old</span>
                    </p>
                  </div>
                  <p className="text">{studentDetails?.data?.student?.bio}</p>
                  {/* data?.student?.bio || */}
                  {/* <button className="btn3 chatbtn">
            <i className="fa-regular fa-comment-dots"></i>
            Start Chat
          </button>
          <button className="btn3">
            <i className="fa-regular fa-calendar"></i> Interview
          </button> */}
                </div>
              </div>
              <div className="thirdtab">
                <div className="row py-2">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="education" >
                    <h3>Experience</h3>
                {
                  studentDetails?.data?.student?.experiences.length !== 0 ? (

                    studentDetails?.data?.student?.experiences.map((item, i)=> {

                      return(
                        <div className="education-detail" key={i}>
                          <h4>
                            <span>
                              <strong>{item?.designation}</strong> - {item?.company_name}{" "}
                            </span>{" "}
                          </h4>
                          <p>Project | {DateType(item?.start_date)} - {DateType(item?.end_date)}</p>
                        </div>
                        // {/* {data?.experiences?.map((item) => {
                        //     return (
                        //       <div className="education-detail">
                        //         <h4>
                        //           <span>
                        //             <strong>{item?.designation}</strong> -{" "}
                        //             {item?.company_name}
                        //           </span>{" "}
                        //         </h4>
                        //         <p>
                        //           Project |{DateType(item?.start_date)} -{" "}
                        //           {DateType(item?.end_date)}
                        //         </p>
                        //       </div>
                        //     )
                        //   })} */}
                     
                      )
                    })

                  ) : (
                    <p>No Experience</p>
                  )

                }

               </div>
                  </div>
                  {/* <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                    <div className="education pl">
                      <h3>Education</h3>
                      <div className="education-detail">
                        <h4>
                          <span>
                            <strong>Web Developer</strong> - Satnam Webtech{" "}
                          </span>{" "}
                        </h4>
                        <p>Project | May 2020 - August 2020</p>
                      </div>
                      <div className="education-detail">
                        <h4>
                          <span>
                            <strong>Site Manager</strong> - Rusk Webtech{" "}
                          </span>
                        </h4>
                        <p>Internship | August 2020 - November 2020</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className=" progress_top_content">
                <p>Project Details</p>
                <h2>{studentDetails?.data?.enrollment?.project?.project_title} </h2>
              </div>

              <div className="student_progress col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <StatusProgress
                status={studentDetails?.data?.enrollment?.status}
                reports={studentDetails?.data?.enrollment?.weekly_submissions ? JSON.parse(studentDetails?.data?.enrollment?.weekly_submissions) : []}
                />

                <div className="weekly-report-accordian">
                  <h2>Weekly Reports</h2>
                  { studentDetails?.data?.enrollment?.weekly_submissions && JSON.parse(studentDetails?.data?.enrollment?.weekly_submissions).map((item) => (
                  <WeekDetail
                    key={item?.week}
                    report={item?.report}
                    index={item?.week}
                    title={`Week ${item?.week}`}
                    description={item?.description}
                    text="uploaded"
                    midTitle="submitted"
                  />
                ))}
                </div>
              </div>
            </div>
          </div>
          )
        }

          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="session-request-table">
              <div className="flex m-2">
                <h3>Today's Sessions</h3>{" "}
                {/* <span>
                    See all <i className="fa-solid fa-arrow-right-long"></i>
                  </span> */}
              </div>
              <div className="table-responsive d-none d-sm-flex">
              {getData.length>0 ?  <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Project</th>
                      <th scope="col">Timings</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {getData?.map((item) => {
                      return (
                        < tr >
                      <td>{item?.student?.first_name} {item?.student?.first_last}</td>
                      <td className="">{item?.enrollment?.project?.project_title}</td>
                          <td className="red">{new Date(item?.startTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) }</td>
                      <td>
                      <button className="btngreen">Join</button>{" "}
                          </td>
                          </tr>
                      )
                    })}
                  </tbody>
                </table>:<div className="w-100 text-center pb-2">No session today</div>}
              </div>
              <div className="student_mobo_table1 d-block d-sm-none">
              {getData.length>0 ? <MentorMobileDataTable data={getData} todayData />:<>No session today</>}
              </div>
            </div>
            <div className="project-table">
              <div className="col-12">
                <div className="enrollment-table">
                  <div className="flex m-2">
                    <h3>Recent Session Requests</h3>{" "}
                    {/* <span>
                      See all <i className="fa-solid fa-arrow-right-long"></i>
                    </span> */}
                  </div>
                  <div className="table-responsive d-none d-sm-flex">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Timings</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {getSessionData?.map((item) => {
                      function formatTime(timeString) {
                        const date = new Date(timeString);
                        let hours = date.getHours();
                        let minutes = date.getMinutes();
                        const ampm = hours >= 12 ? 'pm' : 'am';
                      
                        hours = hours % 12;
                        hours = hours ? hours : 12; // Handle midnight (0 hours)
                      
                        // Add leading zero to minutes if needed
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                      
                        const formattedTime = hours + ':' + minutes + ampm;
                        return formattedTime;
                      }
                      return (
                        <tr>
                          <td>{ new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                          <td className="red">{formatTime(item?.startTime)}</td>
                    
                        <td>
                            <button onClick={()=>setStatus("1") } className="btnred">Reschedule</button>{" "}
                        </td>
                      </tr>
                    )
                   }) }
                        
                      </tbody>
                    </table>
                  </div>
                  <div className="student_mobo_table2 d-block d-sm-none">
                  <MentorMobileDataTable data={getSessionData}  />
                  </div>
                </div>
              </div>
              <div className="my-5">
                <div className="col-12">
                  <div className="enrollment-table_last">
                    <div className="flex m-2">
                      <h3>Completed Sessions </h3>{" "}
                      {/* <span>
                        See all <i className="fa-solid fa-arrow-right-long"></i>
                      </span> */}
                    </div>
                    <div className="table-responsive_last padding d-none d-sm-flex">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Date Completed</th>
                            <th scope="col">Timings</th>
                            <th scope="col">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 11 Oct2022</td>
                            <td>8:00 PM</td>
                            <td>60 mins</td>
                          </tr>
                          <tr>
                            <td>10 Oct2022</td>
                            <td>8:00 PM</td>
                            <td>32 mins</td>
                          </tr>
                          <tr>
                            <td> 10 Oct2022</td>
                            <td>8:00 PM</td>
                            <td>32 mins</td>
                          </tr>
                          <tr>
                            <td> 10 Oct2022</td>
                            <td>8:00 PM</td>
                            <td>32 mins</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="student_mobo_table2 d-block d-sm-none">
                      <MentorMobileDataTable data={data3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                   <div className="online-chat">
                        <div className="profile-section">
                             <div className="flex">
                                  <div className="profile flex">
                                       <img src={rahulImg} alt="rahul narang" />
                                       <h3>Rahul Narang</h3>

                                  </div>
                                  <p><i className="fa-solid fa-ellipsis-vertical"></i></p>
                             </div>
                        </div>

                        <div className="row">
                             <div className="chat-box">
                                  <div className=" col-9 sending">
                                       <div className='msg'>
                                            <p>Hello</p>
                                            <span className="time">12:36 PM</span>
                                       </div> <br />
                                       <div className='msg'>
                                            <p>Good afternoon</p>
                                            <span className="time">12:36 PM</span>
                                       </div>
                                       <div className='msg flex'>
                                            <p className="right"><i className="fa-solid fa-chevron-down"></i></p>
                                            <p>
                                                 It is a long established fact that a reader will be distracted by the readable content
                                                 of a page when looking at its layout. The point of using Lorem Ipsum is that
                                                 it has a more-or-less normal distribution of letters
                                            </p>
                                            <span className="time">12:36 PM</span>

                                       </div>
                                  </div>
                                  <div className="col-3"></div>
                                  <div className="col-12 receive">
                                       <div className='msg'>
                                            <p>Hello</p>
                                            <span className="time">12:36 PM <i className="fa-solid fa-check-double"></i></span>
                                       </div> <br />
                                       <div className='msg'>
                                            <p>Good Evening</p>
                                            <span className="time">12:36 PM <i className="fa-solid fa-check"></i></span>
                                       </div> <br />
                                  </div>
                                  <div className="massgae-write-bar">
                                       <div className="flex">
                                            <textarea rows={1} placeholder=" Write a message..."></textarea>
                                            <span><i className="fa-solid fa-paperclip"></i></span>
                                            <span className='im1'><img src={sendiconImg} alt="sending..." /></span>
                                       </div>
                                  </div>
                             </div>
                        </div>
                   </div>
              </div> */}
        </div>
      </div>
    </section>
  )
}

export default StudentDetail
