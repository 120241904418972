import {
  ADD_APPLICATION_DATA,
  INIT_APPLICATION_DATA,
  REMOVE_APPLICATION_DATA,
  ADD_SERVICE_DATA,
  REMOVE_SERVICE_DATA,
  ADD_PROJECT_ID,
  REMOVE_PROJECT_ID,
} from "../constants";

export const initApplication = (payload) => {
  return {
    type: INIT_APPLICATION_DATA,
    payload,
  };
};

export const addApplicationData = (payload) => {
  return {
    type: ADD_APPLICATION_DATA,
    payload,
  };
};

export const addProjectId = ({ id, mode }) => {
  return {
    type: ADD_PROJECT_ID,
    payload: {
      id,
      mode,
    },
  };
};

export const removeProjectId = () => {
  return {
    type: REMOVE_PROJECT_ID,
  };
};

export const addService = (payload) => {
  return {
    type: ADD_SERVICE_DATA,
    payload,
  };
};

export const removeService = (payload) => {
  return {
    type: REMOVE_SERVICE_DATA,
    payload,
  };
};

export const removeApplicationData = (payload) => {
  return {
    type: REMOVE_APPLICATION_DATA,
    payload,
  };
};
