import mentorimg from "images/mentor.png";
import React from "react";
import { Link } from "react-router-dom";

const arr = ["", "", ""];
const SuggestedMentors = ({ data }) => {
  // console.log(data, "data55");
  return data?.length < 1 ? (
    <></>
  ) : (
    <div className="suggested-mentor">
      <div className="mentor">
        <div className="suggested-mentor">
          <h2>Suggested Mentors</h2>
          <div className="mentor_margin row">
            {data?.slice(0, 3)?.map((item, index) => (
              <div
                className=" mentor_card col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-2"
                key={index}
              >
                <Link to={`/student/mentor/${item.id}`}>
                  <div className="mentor-info1">
                    <div className=" mentor_info_text row">
                      <div className="col-3">
                        <img
                          src={item.profile_photo || mentorimg}
                          alt="Image"
                          width={85}
                          height="88"
                        />
                      </div>
                      <div className="col-9">
                        <div className="mentor-text1">
                          <h3>
                            {item.first_name ?? "-"} {item.last_name ?? "-"}
                          </h3>
                          <p className="txt2">
                            {item?.industry?.industry_name ?? "-"}{" | "}
                            {parseInt(item.industry_year_expreience, 10) ?? 0}{"+ "}years
                          </p>
                          <p className="txt1">
                            {item?.designation} {item?.current_company}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mentor-btn1">
                      <div className="row">
                        <div className="col-5">
                          <div className="mentor-price">
                            <p>₹ {item.mentor_charges ?? "na"} / Session</p>
                          </div>
                        </div>
                        <div className="button_div col-7">
                          <button>Book Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="mentor_btn row" id="explore">
          <Link to="/student/mentor/all-mentor" className="col-12">
            <button>Explore More</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuggestedMentors;
