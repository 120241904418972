import axios from 'axios';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useRef, useState } from 'react'
import { FaEllipsisVertical } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import "./actionDropdown.scss";

const ActionDropDown = ({data, refresh, setRefresh}) => {

  // console.log("dddddddddd", data);
  const listId = data?.row?.original?.id

    const ref = useRef();
    const [toggle, setToggle] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [newName, setNewName] = useState(data?.row?.original?.listName ?? '');

    useOutsideClick(
      ref,
      () => {
        setToggle(false);
      },
      true
    );
  

    const handleToggle = () => {
        setToggle((toggle) => !toggle);
      };

      const toggleRename = () => {
        setShowRenameModal(true);
        handleToggle();
      };

      const handleRenameSubmit = async (e) => {
        e.preventDefault();

        try {
          // console.log("newnameeeeeeeee", newName);

          const res = await axios.put(`/v1/educator/invite/rename-list/${listId}`, {
            newName: newName
          })

          if(!res?.error){
            toast.success("List name updated successfully")
            setRefresh(!refresh)
            toggleRename()
          }else{
            setRefresh(!refresh);
            toggleRename()
          }
          
        } catch (error) {
          toast.error("Error occured")
          console.log('err', error);
          setRefresh(!refresh);
          toggleRename();
        }

      };



      const deleteList = async ()=> {
        try {

          const res = await axios.delete(`/v1/educator/invite/delete-list/${listId}`)

          if(!res?.error){
            toast.success("List deleted successfully")
            setRefresh(!refresh)
            handleToggle()
          }else{
            setRefresh(!refresh);
            handleToggle()
          }
          
        } catch (error) {
          toast.error("Error occured")
          console.log('err', error);
          setRefresh(!refresh);
          handleToggle();
        }
      }


  return (
    <div
    style={{
      position: "relative",
    }}
  >
      <FaEllipsisVertical onClick={() => handleToggle()}/>
    <div
      style={{
        position: "absolute",
        right: "100%",
        top: "135%",
        // border: "1px solid gray",
        // borderRadius: "5px"
      }}
      className={`${toggle ? "" : "d-none"}`}
      ref={ref}
    >
      <button
        className="p-2 px-4"
        style={{
          fontSize: "12.5px",
          color: "gray",
          border: "1px solid gray",
        //   borderBottom: '1px solid black',
          background: "white",
        }}
        onClick={toggleRename}
      >
        Rename
      </button>
      {/* {!flag && ( */}
        <button
          className="p-2 px-4 w-100 mt-1"
          style={{
            fontSize: "12.5px",
            color: "gray",
            border: "1px solid gray",
            background: "white",
          }}
          onClick={deleteList}
        >
          Delete
        </button>
      {/* )} */}
    </div>

    {showRenameModal && (
        <div className="create-list-popup-overlay">
          <div className="rename-list-popup">
            <div className='rename_section '>
              <label htmlFor="">List Name</label>
              <input
                className=''
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="Enter new name"
              />
            </div>

            <div className='rename_actions'>
              <button onClick={()=> setShowRenameModal(!showRenameModal)} className='cancle_btn'>Cancle</button>
              <button onClick={handleRenameSubmit } className='update_btn'>Update</button>
            </div>


          </div>
        </div>
      )}

  </div>
  )
}

export default ActionDropDown