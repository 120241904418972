import axios from "axios";
import { React, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import "./listView.scss";

import Datatable from "components/datatable-new/DatatableNew";
import { useParams } from "react-router-dom";

const ListView = ({
  data,
  text1,
  text2,
  style2,
  style1,
  text3,
  style_i,
  invite,
  type,
  received = false,
  setRefresh,
  method,
  tableFor,
  text4,
  setNew1,
}) => {
  const [search, setSearch] = useState(() => "");
  const [posts, , loading] = useOutletContext();
  const [institutions, setInstitutions] = useState([]);

  const [receiver_id, setReceiver_id] = useState(() => []);

  // // console.log(data, "data123");

  // // console.log(receiver_id, "receiver_id");

  const columns = [
    {
      Header: () => {
        return (
          <div className="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={(e) => {
                if (e.target.checked) {
                  setReceiver_id(data.map((item) => item.id));
                } else {
                  setReceiver_id([]);
                }
              }}
              checked={receiver_id.length === data.length}
            />
          </div>
        );
      },
      accessor: "id",
      width: "5%",
      sortable: false,
      Cell: (data) => {
        return (
          <div className="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={(e) => handleRowSelected(data?.row?.original?.id)}
              checked={receiver_id.includes(data?.row?.original?.id)}
            />
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <>
            {tableFor === "student" ? (
              <strong style={{ fontWeight: "400" }}>Course Name</strong>
            ) : (
              <strong style={{ fontWeight: "600" }}>
                College/universtity Name
              </strong>
            )}
          </>
        );
      },
      accessor: "college_name",
      width: "25%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.college_name || data.row.original.name}
          </strong>
        );
      },
    },
    {
      Header: "Contact Person",
      accessor: "name",
      width: "10%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.name} {data.row.original.last_name}
          </strong>
        );
      },
    },
    {
      Header: "Location",
      accessor: "location",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.location}
          </strong>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <>
            {invite && (
              <button
                className=""
                style={{
                  backgroundColor: `${style1.background}`,
                  fontSize: "10px",
                  borderRadius: "50px",
                }}
              >
                Pending for acceptance
              </button>
            )}
          </>
        );
      },
    },
  ];

  if (!invite) {
    // Remove the "Status" column if invite is present
    columns.splice(4, 1);
  }

  let type1 = "sender";

  const { id } = useParams();

  const opportunity_id = id;

  const rows = data
    ?.filter((item) => {
      let type1;
      if (type) {
        type1 = type;
        if (!item?.[type1] && type1 == "sender") {
          type1 = "receiver";
        } else if (!item?.[type1] && type1 == "receiver") {
          type1 = "sender";
        }
      }

      // // console.log(type, "type");

      return (
        item?.[type1]?.institute?.institution_name
          ?.toLowerCase()
          .includes(search?.toLowerCase()) ||
        item?.institute?.institution_name.toLowerCase().includes(search) ||
        item?.[type1]?.first_name
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.first_name?.toLowerCase().includes(search) ||
        item?.[type1]?.last_name
          ?.toLowerCase()
          .includes(search?.toLowerCase()) ||
        item?.last_name?.toLowerCase().includes(search) ||
        item?.[type1]?.location
          ?.toLowerCase()
          .includes(search?.toLowerCase()) ||
        item?.location?.toLowerCase().includes(search?.toLowerCase())
      );
    })
    .map((item) => {
      let type1;
      if (type) {
        type1 = type;
        if (!item?.[type1] && type1 == "sender") {
          type1 = "receiver";
        } else if (!item?.[type1] && type1 == "receiver") {
          type1 = "sender";
        }
      }

      return {
        id: item?.id,
        college_name:
          item?.[type1]?.institute?.institution_name ||
          item?.institute?.institution_name ||
          "N/A",
        name: item?.[type1]?.first_name || item?.first_name,
        last_name: item?.[type1]?.last_name || item?.last_name,
        location: item?.[type1]?.location || item?.location || "N/A",
        status: item?.status,
        course_name: item?.[type1]?.course_name || item?.course_name || "N/A",
      };
    });

  const map = {
    1: "Draft",
    2: "Published",
    3: "Closed",
  };

  const closePost = async (id, status) => {
    if (status < 1 || status > 2) return toast.error("Invalid status");
    const { error, message } = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    if (error) {
      toast.error(message);
    } else {
      toast.success("Project closed successfully");
    }
  };

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get("/v1/institution");
  //     setInstitutions(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  const handleRowSelected = (state) => {
    if (!receiver_id.includes(state)) {
      setReceiver_id([...receiver_id, state]);
    } else {
      setReceiver_id(receiver_id.filter((item) => item !== state));
    }
  };

  const acceptInvite = (e, id = []) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 1,
      })
      .then((res) => {
        // console.log(res, "res");
        toast.success("Invitation Accepted");
      })
      .catch((err) => {
        // console.log(err, "err");
        toast.error("Invitation Rejected");
      });
    setRefresh((prev) => !prev);
    setReceiver_id([]);
  };

  const rejectInvite = (e, id = []) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 2,
      })
      .then((res) => {
        // console.log(res, "res");
        toast.success("Invitation Rejected");
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Something went wrong");
      });
    setRefresh((prev) => !prev);
    setReceiver_id([]);
  };

  const sendInvitationFunc = async (id = []) => {
    try {
      // console.log(id);
      if (id) {
        const res = await axios.post("/v1/invitations/send_invite", {
          receiver_id: id,
        });
        setRefresh((prev) => !prev);
        // console.log(res);
        toast.success("invitation Send");
      }
    } catch (error) {
      console.log(error);
    }
    setReceiver_id([]);
  };

  const sendInvitationFuncToStudent = async (id) => {
    try {
      // console.log(id, method, opportunity_id);

      if (id) {
        const res = await axios.post("/v1/invitations/send_student_invite", {
          receiver_id: [id],
          status: 1,
          opportunity_id: parseInt(opportunity_id),
          method: method,
        });

        toast.success("invitation Send");
      }
    } catch (error) {
      console.log(error);
    }
    setRefresh((prev) => !prev);
    setNew1((prev) => !prev);
    setReceiver_id([]);
  };

  return (
    <>
      {data?.length > 0 ? (
        <>
          <section className="list_section w-100">
            <div className="top_barListView flex">
              <div className="heading_div d-flex gap-4 align-items-center">
                <h4 className="heading_text ">
                  {data?.length > 0 && (
                    <>
                      {data?.length}{" "}
                      {data?.length > 1 ? "Institutions" : "Institution"}
                    </>
                  )}
                </h4>
                <div className="buttons d-flex gap-3">
                  {text3 && (
                    <button className="btn Not_interested" style={style1}>
                      {text3}
                    </button>
                  )}
                  {text1 && (
                    <button
                      onClick={(e) => rejectInvite(e, receiver_id)}
                      className="btn Not_interested"
                      style={style1}
                    >
                      {text1}
                    </button>
                  )}

                  {received ? (
                    <button
                      onClick={(e) => acceptInvite(e, receiver_id)}
                      className="btn send_Invitation"
                      style={style2}
                    >
                      {text2}

                      <i className="fa fa-send" style={style_i}></i>
                    </button>
                  ) : tableFor === "student" ? (
                    <button
                      onClick={(e) => sendInvitationFuncToStudent(receiver_id)}
                      className="btn send_Invitation"
                      style={style2}
                    >
                      {text2}
                      <i className="fa fa-send" style={style_i}></i>
                    </button>
                  ) : (
                    <button
                      onClick={(e) => sendInvitationFunc(receiver_id)}
                      className="btn send_Invitation"
                      style={style2}
                    >
                      {text2}

                      <i className="fa fa-send" style={style_i}></i>
                    </button>
                  )}
                </div>
              </div>
              <label className="search_label">
                <input
                  type="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </label>
            </div>
            <div className="listView_listView">
              <div className="d-none d-sm-block">
                <Datatable columns={columns} data={rows} />
              </div>
              <div className="d-block d-sm-none">
                {/* <MobileDataTable columns={columns}  /> */}
              </div>
            </div>
          </section>
        </>
      ) : (
        <h1>hello</h1>
      )}
    </>
  );
};

export default ListView;
