import axios from "axios";
import Datatable from "components/datatable-new/DatatableNew";
import JobMobileViewTable from "components/datatable-new/newMobileDataTable/jobMobileViewTable";
import DeactivatePost from "components/employer/DeactivatePost";
import StatusButton from "components/employer/StatusButton";
import EmployerList from "components/employer/employerListTop/EmployerList";
import moment from "moment";
import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import AiGenerator from "../../components/aiGenerator/AiGenerator";
import AiTextModal from "../../components/aiTextModal/AiTextModal";
// import useOutsideClick from "hooks/useOutsideClick"

const JobList = () => {
  const [search, setSearch] = useState(() => "");
  const [posts, , loading, setRefreshTable, refreshTable] = useOutletContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showAiTextPopup, setShowAiTextPopup] = useState(false);
  const [showAiData, setShowAiData] = useState();
  const data = posts["Jobs"];

  const columns = [
    {
      Header: "Date Posted",
      accessor: "date",
      width: "10%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date}
          </strong>
        );
      },
    },
    {
      Header: "Job Title",
      accessor: "title",
      width: "30%",
      textalign: "start",
    },
    {
      Header: "Project Created",
      accessor: "project_linked",
      width: "12.5%",
      textalign: "center",
    },
    {
      Header: "Total applied",
      accessor: "enrolled",
      width: "12.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.enrolled}
          </strong>
        );
      },
    },
    {
      Header: "Project submitted",
      accessor: "submitted",
      width: "12.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.submitted}
          </strong>
        );
      },
    },
    {
      Header: "Total Shortlisted",
      accessor: "rated",
      width: "12.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.rated}
          </strong>
        );
      },
    },
    // {
    //   Header: "Send Invite",
    //   accessor: "invites",
    //   width: "13.5%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.student_invites}
    //         {data.row.original.educator_invites}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Status",
      accessor: "status",
      width: "9%",
      textalign: "center",
    },
    {
      Header: " ",
      width: "1%",
      sortable: false,
      Cell: (data) => {
        return (
          <DeactivatePost
            status={data.row.original.statusCode}
            projectid={data.row.original.id}
            method={"job"}
            closePost={() =>
              closePost(data.row.original.id, data.row.original.statusCode)
            }
            activatePost={() =>
              activatePost(data.row.original.id, data.row.original.statusCode)
            }
          />
        );
      },
    },
  ];

  const map = {
    1: "Draft",
    2: "Active",
    3: "Closed",
  };
  const activatePost = async (id, status) => {
    // console.log(id, status, "activatePost");
    if (status !== 3) return toast.error("Invalid status");
    const { error, message } = await axios.patch("/v1/job/status", {
      id,
      status: 2,
    });

    if (error) {
      toast.error(message);
    } else {
      setRefreshTable(!refreshTable);
      toast.success("Project activated successfully");
    }
    // handleBlur()
  };

  const closePost = async (id, status) => {
    if (status < 1 || status > 2) return toast.error("Invalid status");
    const { error, message } = await axios.patch("/v1/job/status", {
      id,
      status: 3,
    });

    if (error) {
      toast.error(message);
    } else {
      setRefreshTable(!refreshTable);
      toast.success("Job deactivated successfully");
    }
  };

  const rows = data
    ?.filter((post) => {
      return post?.title?.toLowerCase()?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      const getEnrollmentLength = (item) => {
        const enrollmentsWithApplicationsOne = item.enrollments.filter(
          (enrollment) => enrollment.applications === "1"
        );
        return enrollmentsWithApplicationsOne.length;
      };
      const getEnrolShortLength = (item) => {
        const enrollmentsWithApplicationsOne = item.enrollments.filter(
          (enrollment) => enrollment.shortlisted === "1"
        );
        return enrollmentsWithApplicationsOne.length;
      };
      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        title:
          post?.status === 2 || 3 ? (
            <Link to={`/employer/job/${post?.id}`}>
              {" "}
              {post?.title || "No title"}
            </Link>
          ) : (
            <>{post?.title || "No title"}</>
          ),
        enrolled: getEnrollmentLength(post),
        submitted: post?.enrollments[0]?.shortlisted || 0,
        rated: getEnrolShortLength(post) || 0,
        status: <StatusButton status={map[post?.status]} />,
        statusCode: post?.status,
        project_linked: post?.project_linked ? (
          <i
            class="fa-solid fa-circle-check"
            style={{
              color: "#198722",
            }}
          ></i>
        ) : (
          "No Project Linked"
        ),
        // student_invites:<Link to={`/employer/project/students/invite/${post?.id}`}>Student invite</Link>,
        // educator_invites:<Link to={`/employer/project/invite/${post?.id}`}>Educator invite</Link>
      };
    });

  // // console.log(posts["Jobs"]);

  const mainStyle = {
    background: "#FFFFFF",
    boxShadow: "0px 4px 44px rgba(0, 0, 0, 0.11)",
    borderRadius: "7px",
    padding: "24px",
  };

  return (
    <div className="my-4 mx-sm-4" style={mainStyle}>
      <AiGenerator
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        setShowAiTextPopup={setShowAiTextPopup}
        setShowAiData={setShowAiData}
        type={"job"}
      />
      <AiTextModal
        isPopupOpen={showAiTextPopup}
        setIsPopupOpen={setShowAiTextPopup}
        showAiData={showAiData}
        type={"job"}
      />
      <EmployerList
        type={"job"}
        data={data}
        search={search}
        setSearch={setSearch}
        setIsPopupOpen={setIsPopupOpen}
        isPopupOpen={isPopupOpen}
      />
      {/* <Datatable data={rows} columns={columns} /> */}

      <div className="d-none d-sm-block">
        <Datatable columns={columns} data={rows} />
      </div>
      <div className="d-block d-sm-none">
        <JobMobileViewTable columns={columns} data={rows} />
      </div>


    </div>
  );
};

export default JobList;
