import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./studentFilter.scss";
import Dropdown from "components/formComponents/post/Dropdown/Dropdown";

const StudentInviteFilter = ({ dispatch, state1, flag = true }) => {
  // for gender for location for course_name

  const [gender, setGender] = useState(0);
  const [location, setLocation] = useState("");
  const [course_name, setCourse_name] = useState([]);
  const [isPopupOpen, updatePopup] = useState(false);

  const { handleSubmit, register, setValue, getValues } = useForm();

  const deleteCourseName = (item) => {
    setCourse_name((prev) =>
      prev.filter((skill) => skill.course_name !== item)
    );
  };

  const deleteLocation = (item) => {
    setLocation((prev) => prev.filter((skill) => skill.course_name !== item));
  };

  const onSubmit = () => {
    let course_name1;
    if (course_name) {
      course_name1 = course_name?.map((item) => item?.course_name);
    }
    let location;
    if (getValues("location")) {
      location = getValues("location");
    }

    dispatch({
      type: "change_value",
      payload: {
        gender: gender === 1 ? "0" : gender === 2 ? "1" : "",
        location: location,
        course_name: course_name1,
      },
    });
  };

  const onReset = () => {
    setGender(0);
    setLocation("");
    setCourse_name([]);
    dispatch({ type: "reset" });
  };

  // const boxRef = useRef(null);

  // useEffect(() => {
  //   const box = boxRef.current;
  //   if (flag === true || box.classList.contains("start")) {
  //     box.classList.add("start");
  //   }
  // }, []);

  // const handleChange = () => {
  //   const box = boxRef.current;
  //   box.classList.remove("start");
  //   setGender((prev) =>
  //     (prev || state1.gender) === "male" ? "female" : "male"
  //   );
  // };

  // console.log(gender, "hello world");

  return (
    <>
      <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
        <div className="row ">
          <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="job-filter">
              <div className="col-12">
                <TextFieldWithPopup
                  label="Location"
                  id="location"
                  register={register}
                  isPopupOpen={isPopupOpen}
                  updatePopup={updatePopup}
                  path="/v1/location/city"
                  key_name="location"
                  setValue={setValue}
                  withId="city"
                  className="location"
                  placeholder="Search Location"
                />
              </div>

              {/* <div className="switch-container">
                <p
                  className={`${
                    (gender || state1.gender) === "male" && "active"
                  }`}
                >
                  Male
                </p>
                <div
                  ref={boxRef}
                  onClick={handleChange}
                  className={`switch-box ${
                    (gender || state1.gender) === "male"
                      ? "activeMonth"
                      : (gender || state1.gender) === "female" && "active"
                  }`}
                ></div>
                <p
                  className={`${
                    (gender || state1.gender) === "female" && "active"
                  }`}
                >
                  Female
                </p>
              </div> */}

              <div>
                <Dropdown
                  label="Gender"
                  items={["Select Gender", "Male", "Female"]}
                  setItem={setGender}
                  checked={gender}
                />
              </div>

              <div className="col-12">
                <ChipsFieldWithPopup
                  register={register}
                  label="Qualification"
                  id="qualification"
                  items={course_name}
                  setItems={setCourse_name}
                  deleteItem={deleteCourseName}
                  setValue={setValue}
                  path="/v1/filter/courses"
                  placeholder="Choose Qualification"
                  key_name="course_name"
                  className="location"
                />
              </div>
              <button onClick={onReset} className="clear">
                Clear
              </button>
              <button
                onClick={handleSubmit(onSubmit)}
                className="apply"
                type="submit"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentInviteFilter;
