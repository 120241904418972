import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "styles/employer/createPost.scss";
import { toast } from "react-toastify";
import OnSaveToDraft from "components/employer/OnSaveToDraft";
import ProjectStepsLabels from "components/employer/ProjectStepsLabels";
import ProjectSteps from "components/employer/ProjectSteps";
import axios from "axios";
import { REMOVE_APPLICATION_DATA, REMOVE_PROJECT_ID } from "services/constants";
import { useNavigate } from "react-router-dom";
import CreateProject1 from "./createProject";
// import { DevTool } from "@hookform/devtools";

const EditProject = () => {
  const user = useSelector((state) => state.auth.user);
  const step_project = localStorage.getItem("step_project");
  const [saveToDraft, setSaveToDraft] = useState(() => false);
  const [draftData, setDraftData] = useState(() => null);
  const navigate = useNavigate();

  const company1 = useSelector((state) => state.application.company);
  const project_id = useSelector(
    (state) => state?.application?.projectId || null
  );
  // console.log(project_id, "project_id");
  const dispatch = useDispatch();
  const [step, setStep] = useState(() =>
    step_project ? parseInt(step_project) : 1
  );
  const [refresh, setRefresh] = useState(() => false);

  // const [shouldValidate, setShouldValidate] = useState(() => [
  //   false,
  //   false,
  //   false,
  // ]);

  const [shouldValidate, setShouldValidate] = useState(() => false);

  // const onSaveDraft = async (method) => {
  //   if (JSON.parse(localStorage.getItem("application"))["company"]) {
  //     var company = JSON.parse(localStorage.getItem("application"))["company"];
  //     company[1]["branch_id"] =
  //       company[1]["branch_id"] || company1[1]["branch_id"];
  //     company[1]["company_id"] =
  //       company[1]["company_id"] || company1[1]["company_id"];
  //   } else {
  //     company = company1;
  //   }
  //   console.log(company, "company123");
  //   if (!company[1]["branch_id"] || !company[1]["company_id"]) {
  //     return toast.error("You cannot save to draft without company details!");
  //   }
  //   const state = JSON.parse(localStorage.getItem("application"))[method];

  //   const config = {
  //     headers: { "Content-Type": "multipart/form-data" },
  //     withCredentials: true,
  //     params: { state: 1 },
  //   };

  //   function buildFormData(formData, data, parentKey) {
  //     if (
  //       data &&
  //       typeof data === "object" &&
  //       !(data instanceof Date) &&
  //       !(data instanceof File)
  //     ) {
  //       Object.keys(data).forEach((key) => {
  //         buildFormData(
  //           formData,
  //           data[key],
  //           parentKey ? `${parentKey}[${key}]` : key
  //         );
  //       });
  //     } else {
  //       const value = data == null ? "" : data;
  //       formData.append(parentKey, value);
  //     }
  //   }

  //   const formData = new FormData();

  //   buildFormData(formData, state);
  //   buildFormData(formData, company);

  //   console.log(state, "else world");
  //   formData.append("save_project_id", project_id);
  //   const { data } = await axios.post(`/v1/${method}`, formData, config);
  //   if (data?.error) {
  //     setSaveToDraft(false);
  //     toast.error(data?.message || "Something went wrong :(");
  //   } else {
  //     toast.success("Saved to draft successfully!");
  //     setSaveToDraft(false);
  //     dispatch({ type: REMOVE_APPLICATION_DATA, payload: { method } });
  //     dispatch({ type: REMOVE_PROJECT_ID, payload: { method } });
  //     console.log(`step_${method}`, "hello");
  //     localStorage.removeItem(`step_${method}`);
  //     navigate("/employer/dashboard");
  //   }
  // };

  /**
   * It updates the state of the shouldValidate array, which is used to determine whether or not to
   * show the validation error messages
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateShouldValidate = useCallback((index, value, isNext = false) => {
    try {
      // const steps = shouldValidate.length;
      // if (index < 0 || index >= steps) throw new Error("Invalid index");
      // setShouldValidate((prev) => {
      //   prev[index] = value;
      //   return prev;
      // });
      setShouldValidate(value);
      setRefresh((prev) => !prev);

      // if (isNext) {
      //   index === step - 1 &&
      //     setStep((prev) => (prev < steps ? prev + 1 : prev));
      //   setRefresh((prev) => !prev);
      // }

      if (isNext) {
      }
    } catch (err) {
      toast.error(err?.message || err, { toastId: "updateShouldValidate" });
    }
  });

  useEffect(() => {}, [refresh]);

  // const handleEdit = () => {};

  const handleEdit = () => {
    setShouldValidate(true);
    setRefresh((prev) => !prev);
  };

  const onSavedToDraftClick = () => {
    setSaveToDraft(true);
    setShouldValidate((prev) => {
      prev[step - 1] = true;
      console.log(prev[step - 1], "shouldValidate[2]");
      return prev;
    });
    setRefresh((prev) => !prev);
  };

  return (
    <div className="create-post px-4 py-5">
      <div className="header d-flex justify-content-between align-items-center mb-4">
        <div className="mx-auto text-sm-left mx-sm-0">
          <h1 className="mb-0">Edit Project</h1>
        </div>

        <div>
          <button
            className="btn btn-main bg-primary"
            onClick={handleEdit}
            style={{ backgroundColor: "#FFB600" }}
          >
            Next
          </button>
        </div>
      </div>
      <div className="form-container p-5">
        <CreateProject1
          shouldValidate={shouldValidate}
          updateShouldValidate={updateShouldValidate}
          setShouldValidate={setShouldValidate}
        />

        <div className="header d-flex justify-content-end align-items-center">
          <div className="draft_save_container d-flex flex-column flex-md-row align-items-center gap-3 py-3">
            <div className="d-flex justify-content-center gap-3">
              <button className="btn-previous btn">Previous</button>
              <button
                className={`btn btn-main  ${
                  step === shouldValidate?.length ? "last-step" : ""
                }`}
                onClick={handleEdit}
              >
                {/* {step === shouldValidate?.length ? "Review & Publish" : "Next"} */}
                Next
              </button>
            </div>

            {/* <OnSaveToDraft method="project" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProject;
