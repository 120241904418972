import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "hooks/useOutsideClick";
import axios from "axios";
import { toast } from "react-toastify";

const MentorManagerAction = ({data,setRefresh}) => {

    // console.log("dataaa",data)
  const ref = useRef();
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };

  useOutsideClick(
    ref,
    () => {
      setToggle(false);
    },
    true
  );

  const updateProfile = () => {
    navigate("/mentor-manager/update-mentor-profile",{state:{mentorData:data}});
};

const changePassword = () => {
      navigate("/mentor-manager/update-mentor-password", {state:{mentorData:data}});
   
  };




  const deleteMentor = async (id) => {
    try {
      setRefresh(true)
      const response = await axios.delete(`/v1/mentor/mentor/${id}`);
      if (!response.data?.error) {
        setRefresh(false)
        toast.info(response?.data?.message);
        // onDelete(id);
      } else {
        setRefresh(false)
        toast.error(response?.data?.error);
      }
    } catch (error) {
      setRefresh(false)
      console.error(error);
      toast.error("An error occurred while deleting the mentor.");
    }


    }


    // if (isDeleted) {
    //   return (
    //     <button
    //       className="p-2 mt-1"
    //       style={{
    //         fontSize: "13px",
    //         color: "#ffffff",
    //         border: "1px solid gray",
    //         background: "green",
    //         width: "150px",
    //         textAlign: "center",
    //       }}
    //     >
    //       Activte Account
    //     </button>
    //   );
    // }




  return (
    <div style={{ position: "relative" }}>
      <i
        className="fa-solid fa-ellipsis-vertical toggle-dots"
        style={{
          display: "inline-block",
          width: "20px",
          color: "gray",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={handleToggle}
      ></i>
      <div
        style={{
          position: "absolute",
          right: "60%",
          top: "60%",
        }}
        className={`${toggle ? "" : "d-none"}`}
        ref={ref}
      >
        <button
          className="p-2"
          style={{
            fontSize: "13px",
            color: "#ffffff",
            border: "1px solid gray",
            background: "#6149CD",
            width: "150px", // Fixed width
            textAlign: "center",
          }}
          onClick={updateProfile}
        >
          Update Profile
        </button>
        <button
          className="p-2  mt-1"
          style={{
            fontSize: "13px",
            color: "#ffffff",
            border: "1px solid gray",
            background: "green",
            width: "150px", // Fixed width
            textAlign: "center",
          }}
          onClick={changePassword}
        >
          Change Password
        </button>
        <button
          className="p-2  mt-1"
          style={{
            fontSize: "13px",
            color: "#ffffff",
            border: "1px solid gray",
            background: "#FF3737",
            width: "150px", // Fixed width
            textAlign: "center",
          }}
          onClick={()=>deleteMentor(data.id)}
        >
          Delete Mentor
        </button>
      </div>
    </div>
  );
};

export default MentorManagerAction;
