import {
  ADD_USER,
  IS_INTEREST_ADDED,
  REMOVE_USER,
  UPDATE_BRANCH,
  UPDATE_VERIFIED_OTP,
  UPDATE_USER,
} from "../constants"

function isJsonString(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

const user = isJsonString(localStorage.getItem("user") ?? false)

const initialState = {
  isSemiAuthenticated: !!user,
  user: user
    ? user
    : {
        id: false,
        email_address: "",
        first_name: "",
        last_name: "",
        role: 0,
      },
  isAuthenticated: true,
}

export default function auth(state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case ADD_USER: {
      const newState = {
        ...state,
        isSemiAuthenticated:
          payload &&
          Object.keys(payload).length !== 0 &&
          payload.constructor === Object,
        user: payload,
        isAuthenticated: false,
      }

      if (newState?.isSemiAuthenticated) {
        newState.user.role = parseInt(newState?.user?.role, 10)
        if (!!newState?.user?.branch_id) newState.user.isInterestAdded = true
        if (
          !newState?.user?.is2FA &&
          newState?.user?.is_email_verified &&
          (!!newState?.user?.branch_id || newState?.user?.role === 4) &&
          !!newState?.user?.isInterestAdded
        )
          newState.isAuthenticated = true
          if (
            !newState?.user?.is2FA &&
            newState?.user?.is_email_verified && newState?.user?.role === 2)
            newState.isAuthenticated = true
          if (
              !newState?.user?.is2FA &&
              newState?.user?.is_email_verified && newState?.user?.role === 3)
              newState.isAuthenticated = true
        if (newState.user.role === 1 || newState.user.role === 5) {
          newState.isAuthenticated = true
        }
        if (newState.user.role === 1 || newState.user.role === 6) {
          newState.isAuthenticated = true
        }
        if (newState.user.role === 1 || newState.user.role === 7) {
          newState.isAuthenticated = true
        }
      }

      return newState
    }

    case REMOVE_USER:
      return {
        ...state,
        isAuthenticated: false,
        isSemiAuthenticated: false,
        user: {
          _id: false,
          email_address: "",
          first_name: "",
          last_name: "",
          role: 0,
        },
      }

    case UPDATE_BRANCH: {
      const newState = {
        ...state,
        user: { ...state?.user, ...payload },
        isSemiAuthenticated: true,
        isAuthenticated: false,
      }

      if (!!newState?.user?.isOTPVerified) {
        if (
          !!newState?.user?.branch_id &&
          !!newState?.user?.is_email_verified &&
          !!newState?.user?.branch_id &&
          !!newState?.user?.isInterestAdded
        )
          newState.isAuthenticated = true
      }

      localStorage.setItem("user", JSON.stringify(newState?.user))

      return newState
    }

    case UPDATE_VERIFIED_OTP: {
      const updatedState = {
        ...state,
        user: { ...state?.user, isOTPVerified: true, is_email_verified: true },
        isSemiAuthenticated: true,
        isAuthenticated: true,
      }

      if (
        (!!updatedState?.user?.branch_id || updatedState.user?.role === 4) &&
        (!!updatedState?.user?.isInterestAdded || false)
      )
        updatedState.isAuthenticated = true

      localStorage.setItem("user", JSON.stringify(updatedState?.user))

      return updatedState
    }

    case IS_INTEREST_ADDED: {
      const updatedState = {
        ...state,
        user: {
          ...state?.user,
          isOTPVerified: true,
          is_email_verified: true,
          isInterestAdded: true,
        },
        isSemiAuthenticated: true,
        isAuthenticated: true,
      }

      localStorage.setItem("user", JSON.stringify(updatedState?.user))

      return updatedState
    }
 
    case UPDATE_USER: {
      const updatedState = {
        ...state,
        user: {
          ...state?.user,
          ...payload,
        },
      }

      localStorage.setItem("user", JSON.stringify(updatedState?.user))

      return updatedState
    }

    default:
      return state
  }
}
