import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { bookSession } from "services/factories/razorpay";
import PaymentSuccessModel from "../model/PaymentSuccessModel";
import "./mentorDetail.scss";
import userPic from "./user.png";

const MentorDetail = () => {
  const user = useSelector((state) => state?.auth?.user);
  const [sgst, setSgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [timeZones, setTimeZones] = useState();
  const { id } = useParams();
  const [mentor, setMentor] = useState(() => []);
  const [showModal, setShowModal] = useState(false);
  const [enrolledProjects, setEnrolledProjects] = useState([]);
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState("");
  const navigate = useNavigate();
  // // console.log(selectedEnrollmentId, "selectedEnrollmentId");

  const fetchMentor = async () => {
    try {
      const { data: reqData } = await axios.get(`/v1/student/mentor/` + id, {
        validateStatus: () => true,
      });
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      // console.log("mentor", data)
      setMentor(data);
    } catch (error) {}
  };

  function convertTimeSlotsToDateSlots(date, timeSlots) {
    const dateObj = new Date(date);
    const dateSlots = {};

    timeSlots?.forEach((timeSlot) => {
      const [startTimeStr, endTimeStr] = timeSlot.split("-");
      const startTime = new Date(dateObj.toDateString() + " " + startTimeStr);
      const endTime = new Date(dateObj.toDateString() + " " + endTimeStr);
      const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); // duration in minutes

      let slotStartTime = startTime;
      while (slotStartTime < endTime) {
        const slotEndTime = new Date(slotStartTime.getTime() + 30 * 60 * 1000); // add 30 minutes
        const slotKey = `${slotStartTime.getHours()}:${slotStartTime.getMinutes()}-${slotEndTime.getHours()}:${slotEndTime.getMinutes()}`;
        dateSlots[slotKey] = { startTime: slotStartTime, endTime: slotEndTime };
        slotStartTime = slotEndTime;
      }
    });

    return dateSlots;
  }

  useEffect(() => {
    fetchMentor();
  }, []);

  useEffect(() => {
    fetch("/v1/project/enrolled")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "enrolment");
        setEnrolledProjects(data?.data?.items);
      })
      .catch((error) =>  console.log(error));
  }, []);

  useEffect(() => {
    if (mentor) {
      const cgstAmount = (mentor.mentor_charges * 9) / 100;
      const sgstAmount = (mentor.mentor_charges * 9) / 100;
      const baseprice = mentor.mentor_charges - (cgstAmount + sgstAmount);
      setCgst(cgstAmount);
      setSgst(sgstAmount);
      setBasePrice(baseprice);
    }
  }, [mentor]);

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()]; // get the day of the week in string format
    setSelectedDate(date.toISOString().split("T")[0]);
    // console.log(date, mentor, "Filtered");
    const selectedTimezones = mentor.timings.find(
      (item) => item?.day === dayOfWeek.toLocaleUpperCase()
    );
    // // console.log(selectedTimezones);
    const timeslots = convertTimeSlotsToDateSlots(
      date,
      selectedTimezones?.duration
    );
    // // console.log(timeslots, "timeslots");
    setTimeZones(timeslots);
  };
  // // console.log(mentor, "Filtered");
  const jumpToMyMentor = () => {
    navigate("/student/mentor");
  };
  async function initiatePayment(e) {
    e.preventDefault();
    try {
      const session = await bookSession(
        {
          mentor_id: id,
          amount_rs: parseInt(mentor_charges),
          start_time: timeZones[selectedTime]?.startTime,
          end_time: timeZones[selectedTime]?.endTime,
          enrollment_id: selectedEnrollmentId,
          coupon_name: applied ? coupon_name : null,
          user:  user
        },
        jumpToMyMentor
      );
      // console.log(session, "session");
      if (session) {
        setShowModal(true);
        // navigate("/student/mentor");
      }

      // console.log(session, "session");
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const handleKeyClick = (key) => {
    setSelectedTime(key);
  };

  // const handlePayment = (e) => {
  //   initiatePayment(e)
  // }
  const minDate = new Date().toISOString().split("T")[0]; // get current date

  // // console.log(selectedTime, timeZones, "selectedTime");
  // // console.log(enrolledProjects, "enrolledProjects");

  const [mentor_charges, setMentor_charges] = useState();

  useEffect(() => {
    setMentor_charges(mentor?.mentor_charges);
  }, [mentor]);

  const [coupon_name, setCoupon_name] = useState();

  const [applied, setApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const validateCoupon = async () => {
    try {
      if (!coupon_name) return toast.error("Please enter coupon code");
      setLoading(true);
      const { data } = await axios.get(
        `/v1/student/coupon-validate/${coupon_name}`
      );

      if (data?.error) toast.error(data?.message);
      else {
        setMentor_charges(
          mentor?.mentor_charges -
            (mentor?.mentor_charges * data?.data?.discountPercentage) / 100
        );
        setApplied(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // console.log("mentor", mentor)
  // // console.log(typeof mentor_charges, "mentor_charges");
  // // console.log(coupon_name)

  return (
    <section className="mentorDetail_section container mx-auto">
      <div className="upper_detail bg-white">
        <h4>Mentor Details</h4>
        <div className="details">
          <div className="mbox1 ">
            <div className="d-flex align-items-center">
              <img width={85} src={mentor?.profile_photo || userPic} alt="Image" />
              <div className="mentor_d d-flex flex-column gap-1 mx-3 ">
                <h3 className="m-0">
                  {" "}
                  {mentor?.first_name} {mentor?.last_name}
                </h3>
                <span>
                {mentor?.industry?.industry_name ?? "-"}{" | "} {parseInt(mentor.industry_year_expreience, 10) ?? 0}{"+ "}years
                </span>
                <p className="m-0">
                  {mentor?.designation} {mentor?.current_company}
                </p>
              </div>
            </div>
            <p className="session_fee mt-4 mb-0">
              ₹ {mentor_charges} / Session
            </p>
          </div>
          <select
            className="project_dropdown pointer"
            value={selectedEnrollmentId}
            onChange={(event) => setSelectedEnrollmentId(event.target.value)}
          >
            <option value="">Select a project</option>
            {enrolledProjects?.map((project) => (
              <option key={project.id} value={project.id} className="py-2">
                {project?.project?.title?.length > 40 ? (
                  <span className="px-5">
                    {project?.project?.title?.substring(0, 40)}...
                  </span>
                ) : (
                  <span>{project?.project?.title}</span>
                )}
              </option>
            ))}
          </select>

          <div className="devider d-flex d-sm-none"></div>
          <div className="box3 ">
            <div className="session_price">
              <div className="base_price flex gap-4">
                <p className="m-0">Base Price</p>
                <span>₹ {basePrice}</span>
              </div>
              <div className="gst border_style flex gap-4 py-1">
                <p className="m-0">CGST (9%)</p>
                <span>₹ {cgst}</span>
              </div>
              <div className="gst flex gap-4 py-1">
                <p className="m-0">SGST (9%)</p>
                <span>₹ {sgst}</span>
              </div>
              <div className="payable flex gap-4">
                <p className="m-0">Payable</p>
                <span>₹ {mentor_charges} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_detail">
        <div className="user_details">
          <label>
            <h4>Your Details</h4>
            <div className="user flex gap user-coupon">
              <img
                width={40}
                className="coupon-img"
                src={user?.profile_photo || userPic}
                alt="Image"
              />
              <div className="text">
                <h3>{user?.first_name}</h3>
                <p>{user?.email_address}</p>
              </div>
            </div>
          </label>

          <label>
            <h4>Discount Coupon</h4>
            <div className="coupon">
              <input
                type="text"
                placeholder="SHRIKANT10"
                onChange={(e) => setCoupon_name(e.target.value)}
                value={coupon_name}
              />
              {!applied ? (
                <button
                  className="btn btn-primary"
                  onClick={() => validateCoupon()}
                >
                  Apply
                </button>
              ) : (
                <button
                  className="btn cencel-btn"
                  onClick={() => {
                    setCoupon_name("");
                    setMentor_charges(mentor?.mentor_charges);
                    setApplied(false);
                  }}
                >
                  Cencel
                </button>
              )}
            </div>
          </label>
          <label>
            <h4>Choose start date</h4>
            <input
              className="date_style form-control"
              id="dateInput"
              placeholder="dd-mm-yyyy"
              value={selectedDate}
              type="date"
              min={minDate}
              onChange={handleDateChange}
            />
            {selectedDate && (
              <div className="time_dropdown row pt-2 my-2">
                {!Object.keys(timeZones).length == 0 ? (
                  <>
                    {Object.keys(timeZones).map((key) => {
                      return (
                        <div key={key} className="col-4 flex gap-2">
                          <span
                            style={{
                              backgroundColor:
                                selectedTime === key ? "#6149cd" : "",
                              color: selectedTime === key ? "#fff" : "",
                            }}
                            className="duration text-center w-100 m-2 p-2 rounded fw-light"
                            onClick={() => handleKeyClick(key)}
                          >
                            {key}
                          </span>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <p>Mentor is not available on this day</p>
                )}
              </div>
            )}
          </label>
        </div>
      </div>

      {selectedTime &&
      selectedEnrollmentId &&
      !Object.keys(timeZones).length == 0 ? (
        <button
          className="bg-success w-100"
          onClick={(e) => {
            initiatePayment(e);
          }}
        >
          {/* onClick={(e) => {
          initiatePayment(e)
        }} */}
          Continue to pay ₹ {mentor_charges}
        </button>
      ) : (
        <button className="bg-success w-100">
          please Select the Date ,timing & Project
        </button>
      )}

      {showModal && <PaymentSuccessModel handleClose={handleClose} />}
    </section>
  );
};

export default MentorDetail;
