import { useEffect, useRef, useState } from "react";

import axios, { get } from "axios";
import EducatorSidebar from "components/dashboard/educatorSidebar/Sidebar";
import Topbar from "components/dashboard/educatorTopbar/Topbar";
import "components/formComponents/style.scss";
import useOutsideClick from "hooks/useOutsideClick";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import "styles/employer/companyDashboard.scss";
import "styles/employer/statbox.scss";
import Notification from "../student/notification/Notification";
// import EducatorSidebar from "components/dashboard/educatorSidebar/EducatorSidebar"

const CollegeDashboard = () => {
  const { register, handleSubmit, setValue, formState } = useForm();
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [posts, setPosts] = useState(() => ({
    Internships: [],
    Jobs: [],
    Projects: [],
  }));
  const [state1, setState1] = useState(() => {});
  const [loading, setLoading] = useState(() => true);
  const userImage = user?.profile_pic;
  const toggleSidebar = (val) => {
    setShowSidebar(val);
  };

  const [notificationData, setNotificationData] = useState(null);
  const [isAlertBoxVisible, setAlertBoxVisibility] = useState(false);
  // const [status, setStatus] = useState(null);
  const [isNotification, setIsNotification] = useState(false);


  const toggleAlertBox = () => {
    setAlertBoxVisibility(!isAlertBoxVisible);
  };

  const alertBoxRef = useRef(null);

  useOutsideClick(alertBoxRef, ()=> setAlertBoxVisibility(false), isAlertBoxVisible);


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (alertBoxRef.current && !alertBoxRef.current.contains(event.target)) {
  //       // Clicked outside the date picker, close the picker
  //       setAlertBoxVisibility(false);
  //     }
  //   };
  
  //   document.addEventListener('mousedown', handleClickOutside);
  
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  // useEffect(() => {
  //   const fetchDataAndStoreInState = async (url, status) => {
  //     try {
  //       const response = await axios.get(url);
  //       setState1((prevState) => ({
  //         ...prevState,
  //         [status]: response?.data,
  //       }));
  //     } catch (error) {
  //       console.error(`Error fetching data for status ${status}:`, error);
  //     }
  //   };

  //   const requests = [
  //     { url: "/v1/employer", status: 0 },
  //     { url: "/v1/invitations/get_invites?status[]=0", status: 1 },
  //     {
  //       url: `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}`,
  //       status: 2,
  //     },
  //     {
  //       url: `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`,
  //       status: 3,
  //     },
  //   ];

  //   // Use Promise.all to make multiple requests concurrently
  //   Promise.all(
  //     requests.map(({ url, status }) => fetchDataAndStoreInState(url, status))
  //   );
  // }, []);

  useEffect(() => {
    const getActiveInternships = async () => {
      const opts = {
        valdateStatus: () => true,
      };

      const internship = get("/v1/enrollments/internship", opts);
      const job = get("/v1/enrollments/job", opts);
      const project = get("/v1/enrollments/project", opts);

      await axios
        .all([internship, job, project])
        .then(
          axios.spread((...responses) => {
            const internships = responses[0]?.data?.data || [];
            const jobs = responses[1]?.data?.data || [];
            const projects = responses[2]?.data?.data || [];

            setPosts((p) => ({
              ...p,
              Internships: internships,
              Jobs: jobs,
              Projects: projects,
            }));
            setLoading(false);
          })
        )
        .catch(() => {
          setLoading(false);
        });
    };
    getActiveInternships();

    return () => {};
  }, [user.id]);

  // useEffect(() => {
  //   dispatch(educatorInvitesNumber(state1));
  // }, [state1, dispatch]);

  useEffect(()=> {

    const getNotification = async () => {
      const res = await axios.get("/v1/notification/");
  
      // console.log("notiii", res?.data);
  
      setNotificationData(res?.data?.data);

      // Check if any notification has is_seen false or status 1
      const hasUnseenNotification = res?.data?.data.some(
        (notification) => !notification.is_seen || notification.status === 1
      );

      // Set isNotification based on the condition
      setIsNotification(hasUnseenNotification);
    
    }

    getNotification();

  }, [isAlertBoxVisible]);


  return (
    <div className="dashboard" style={{overflowX:'hidden'}}>
      <EducatorSidebar
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        projects={posts?.Projects?.length}
        internships={posts?.Internships?.length}
        jobs={posts?.Jobs?.length}
      />
      <div className="main">
        <Topbar
          toggleSidebar={toggleSidebar}
          userImg={userImage}
          name={user.first_name}
          toggleAlertBox={toggleAlertBox}
          isNotification={isNotification}
        />

  <div ref={alertBoxRef}>
      {isAlertBoxVisible && 
          <Notification data={notificationData} toggleAlertBox={toggleAlertBox}  />
      }
  </div>

        <main style={{ backgroundColor: " #f6f7f9" }}>
          <Outlet context={[posts, setPosts, loading, setLoading]} />
        </main>
      </div>
    </div>
  );
};

export default CollegeDashboard;
