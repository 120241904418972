import React from "react";
import "./topNavbar.scss";
import { Link } from "react-router-dom";

const TopNavBar = () => {
  const handleClick = () => {
    window.location.href = "googlegmail:///co?to=sparsh@gmail.com";
  };
  const mapLinks = [
    {
      icon: <i className="facebookSo_icon fa fa-facebook"></i>,
      link: "https://www.facebook.com/Qollabbedutechh",
    },
    {
      icon: <i className="footer_icons fa fa-linkedin" aria-hidden="true"></i>,
      link: "https://www.linkedin.com/company/qollabb/",
    },
    {
      icon: <i className="footer_icons fa fa-instagram" aria-hidden="true"></i>,
      link: "https://www.instagram.com/qollabb/",
    },
  ];

  return (
    <div className="topNavbar flex py-2 align-items-center w-100">
      <div className="container flex">
        <div className="left_side flex gap-2">
          <i
            className="fa fa-envelope-o"
            style={{ color: "#2467EC" }}
            aria-hidden="true"
          ></i>
          <a href="mailto:info@qollabb.com" className="m-0">
            info@qollabb.com
          </a>
        </div>
        <div className="right_side flex gap-2">
          {/* <i class="fa fa-facebook" aria-hidden="true"></i>
        <i class="fa fa-instagram" aria-hidden="true"></i>
        <i class="fa fa-linkedin" aria-hidden="true"></i> */}
          {mapLinks.map((item, i) => (
            <Link target="_blank" to={item.link} key={i}>
              {item.icon}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
