import React, { useEffect } from "react"

const Modal = ({ children, show, setShow, type }) => {
  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "unset"

    return () => {
      document.body.style.overflow = "unset"
    }
  }, [show])

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(122, 134, 161, 0.5)",
      }}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content py-3 px-4">
          <div style={{ border: "none" }} className="modal-header">
            <h5
              style={{ fontWeight: "700" }}
              className="modal-title"
              id="exampleModalLabel"
            >
              Add {type}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setShow(false)}
            ></button>
          </div>

          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
