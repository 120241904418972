import axios from "axios";
import Loader from "components/employer/Loader";
import "components/posts/NewProjectDetails.scss";
import NewProjectDetailsPage from "components/posts/NewProjectDetailsPage";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const ExploreButton = ({ onApply, data }) => {
  return (
    <div className="actions">
      <button
        className="btn btn-primary py-2 px-4 w-100 text-white btn-width"
        onClick={onApply}
      >
        Apply Now
      </button>
    </div>
  );
};

const FetchProjectDetails = ({ id, onApply }) => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});
  // const { loading, data, error } = useFetch(`/v1/filter/project/${id}`);

  const fetchProject = async() => {
    try {
      setLoading(true);
      const data = await axios.get(`/v1/filter/project/${id}`)
      // console.log("daaaaaaaaaate", data);

      setProject(data?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);

    }
  }

  useEffect(()=> {
    fetchProject();
  }, [id])

  // if (loading || !data) return <Loader />;
  // const { error: err, data: project } = data;

  // if (err || error) return <h3>{err}</h3>;
  // console.log("mainprojectt", project)
  if(loading) return <Loader />
  return (
    <NewProjectDetailsPage data={project} onApply={onApply}>
      <Helmet>
        {/* <meta
          charSet="utf-8"
          name="description"
          content={`${project?.project_title}`}
        /> */}
            <meta
              name="title"
              content={project?.project_title}
            />

        <link rel="canonical" href={`https://qollabb.com/project/${project?.project_title?.replace(/[\s#]+/g, '-')}/${project?.id}`} />
        <title>
          {project?.project_title}
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
      <ExploreButton data={project} onApply={onApply} />
    </NewProjectDetailsPage>

        //* old one
    // <ProjectDetailsPage data={project} onApply={onApply}>
    //   <ExploreButton data={project} onApply={onApply} />
    // </ProjectDetailsPage>
  );
};

export default FetchProjectDetails;
