import Resume from "components/student/Resume";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import ProjectDetails from "../post/ProjectDetails"
import FetchProjectDetails from "../post/FetchProjectDetails";
import Screening from "./Screening";
import { useDispatch, useSelector } from "react-redux";

const EnrollProject = ({ defaultStep = 0 }) => {
  const [step, setStep] = useState(() => defaultStep);
  const { id = 0 } = useParams();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const onProceedToApply = async () => {
    if (step === -1) return setStep(0);
    const data = await axios.post("/v1/enrollments/project", {
      project_id: id,
    });

    if (!data?.data?.error) {
      toast.success(data?.data?.message);
      setStep(1);
    } else {
      toast.error(data?.data?.message);
    }
  };

  if (!user?.role) {
    localStorage.setItem("path", window.location.pathname);
  }

  

  switch (step) {
    case -1:
      return <FetchProjectDetails id={id} onApply={onProceedToApply} />;
    case 0:
      return <Resume onAction={onProceedToApply} />;

    default:
      return (
        <Navigate to="/student/explore/*" state={{ enrolled: "project", id }} />
      );
  }
};

export default EnrollProject;
