import Loading from "Loading";
import useFetch from "hooks/useFetch";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "./Card";
import SuggestedProjects from "./SuggestedProjects";
import "./home.scss";
import SuggestedMentors from "./home_cards/sections/Mentors";
// import Suggested from "./home_cards/sections/Suggested"
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
// import Footer from "../footer/Footer"
const AppliedInternship = lazy(() => import("./home_cards/Internship"));
const AppliedJob = lazy(() => import("./home_cards/Job"));

const Home = () => {
  const [applied] = useOutletContext();
  const skills = applied.internship.reduce((acc, item) => {
    const skill = item.internship?.skills?.map((item) => item?.id) || [];
    return [...new Set([...acc, ...skill])];
  }, []);
  const user = useSelector((state) => state?.auth?.user);
  const [path, setPath] = useState(() => localStorage.getItem("path"));

  // // console.log(path, "path123");
// console.log("applied...", applied)
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(() => 0);
  const data = useFetch(`/v1/project/suggested`);
  // // console.log(data, "54321");
  const [mentors, setMentors] = useState(() => []);
  const fetchMentors = async () => {
    try {
      const { data: reqData } = await axios.get(
        `/v1/student/mentor/get_mentor_list`,
        {
          validateStatus: () => true,
        }
      );
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      setMentors(data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchMentors();
  }, []);

  useEffect(() => {
    if (path && user?.role) {
      navigate(path);
      localStorage.removeItem("path");
    }
  }, [navigate, path]);

  // const subscription = true;
  // // console.log(user, "user?.subscriptionStatus?.subscription_name");

  // console.log(user, "userrrrrrrrrrr");

  // // console.log(activeTab, "activeTab");

  return (
    <Suspense fallback={<Loading />}>
      <section className="student_home container">
        <div className="student_user_head d-block d-sm-flex justify-content-between align-items-end">
          <div>
            <h3 className="student_tit title">
              Welcome back, {user?.first_name}
            </h3>
            <p className="titlepara">What do you want to explore today</p>
          </div>

          <div className="subscription_status">
            <p className="para">Subscription Status:</p>
            {user?.subscriptionStatus?.subscription_id === 8 && (
              <h3 className="fs-6 mt-1">
                <span className="text-success fw-bold">
                  {
                    user?.subscriptionStatus?.subscription_database?.subscription_name
                  }
                </span>{" "}
                :{" "}
                {new Date(
                  user?.subscriptionStatus?.valid_upto
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </h3>
            )}
            {user?.subscriptionStatus === null && (
              <h3 className="fs-6 mt-1">
                <span className="text-success fw-bold">Free Plan</span>
              </h3>
            )}
            {user?.subscriptionStatus?.subscription_id === 9 && (
              <h3 className="fs-6 mt-1">
                <span className="text-success fw-bold">
                  {
                    user?.subscriptionStatus.subscription_database
                      .subscription_name
                  }
                </span>{" "}
                :{" "}
                {new Date(
                  user.subscriptionStatus.valid_upto
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </h3>
            )}
          </div>
        </div>

        <Card activeTab={activeTab} setActiveTab={setActiveTab} />

        {/* <Wavy /> */}
        {activeTab === 0 && <SuggestedProjects method="project" data={data} />}
        {/* {activeTab===0 && <Suggested method="internship" skills={skills} />} */}
        {(activeTab === 0 && applied?.project?.length !== 0 ) ? <SuggestedMentors data={mentors ?? []} /> : <></>}
        {activeTab === 1 ? <AppliedInternship /> : <></>}
        {activeTab === 2 ? <AppliedJob /> : <></>}
        {/* <Wavy /> */}
      </section>
    </Suspense>
  );
};

export default Home;
