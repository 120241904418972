import React from "react";

const ToolTip = () => {
  return (
    <div className="toolTip">
      <div className="toolTip_container">
        <p>Complete your Profile to unlock</p>
      </div>
    </div>
  );
};

export default ToolTip;
