import React from "react"
import "../mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link } from "react-router-dom"
import moment from "moment"

const MobileViewOpportunitiesTable = ({ columns, data,ActionForAdmin,setActivate}) => {
  // console.log(columns, data,"opportunity")
 
  return (
    <>
      {data?.map((item, i) => {

        // const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4" key={i}>
            <div className="data_container">
              <div className="table_data">
                <p>Company</p>
                <span className="Total_enrolled">{item?.companyName}<br/>{item?.company_location}</span>
              </div>
              <div className="table_data">
                <p>Contact Person</p>
                <span className="Total_enrolled">{item?.contact_person}<br/>{item?.designation}</span>

                {/* {item?.title?.props?.children[1] ? (
               <span className="project_name">{item.title.props.children[1]}</span>
                     ) : (
                 <span className="project_name">{item.title.props.children}</span>
                     )} */}

              </div>

              <div className="table_data">
                <p>Contact details</p>
                <span className="Total_enrolled">{item?.phone_number}<br/>{item?.email}</span>
              </div>

              <div className="table_data">
                <p>Opportunity Name</p>
                <span className="Total_enrolled">{item?.name}</span>
              </div>
              <div className="table_data">
                <p>Opportunity Type</p>
                <span className="Total_enrolled">{item?.type}</span>
              </div>

              <div className="table_data">
                <p>Date Posted</p>
                <span className="Total_enrolled">{item?.date_posted}</span>
              </div>
              <div className="table_data">
                <p>No. of students</p>
                <span className="Total_enrolled">{item?.students}</span>
              </div>
              <div className="table_data">
                <p>Status</p>
                <span className="Total_enrolled">{item?.status}</span>
              </div>
              
              
             




             
              <div className="Status_data">
              <p>Action</p>
              <ActionForAdmin
                id={item.id}
                status={item.statusCode}
                method={item.postType}
                setActivate={setActivate}
              />
           
              
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewOpportunitiesTable
