import useFetch from "hooks/useFetch"
import React from "react"
import star1 from "../../../../../src/images/star1.png"
import star2 from "../../../../../src/images/star2.png"
import profileImg from "../../../../images/ayush.png"
import download from "../../../../images/download.png"
import lineImg from "../../../../images/line.png"
import mentorimg from "../../../../images/mentor.png"
import print from "../../../../images/print.png"
import WhatsappImg from "../../../../images/wp.png"
import "./plan.scss"




const Plan = () => {

  const data = useFetch(`/v1/subscription/get_subscriptions`);

  // console.log(data,"data");

  return (
    <div className="plan container">
      <h2>Upgrade Your Plan</h2>
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div className="plan-content active">
            <p className="cnt1">
              <span className="md">Beginner</span>{" "}
              <span className="bold">FREE</span>
            </p>

            <div className="row">
              <div className="col-12">
                <div className="plan-text">
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Includes Access to Projects, Internships & Jobs posted by
                      companies
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      Company projects you can work on simultaneouly{" "}
                      <i className="italic">- 02 Projects at a time</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Apply to all internships or job opportunties for freshers-{" "}
                      <i className="italic"> 10 Applications per Day</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Access to all information and data as provided by company
                      for working on Projects
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Build your project work portfolio by submitting your
                      project reports to employers
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Evaluation and validation of your project work by
                      Qollabb’s experts
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-xmark"></i>
                    <span>
                      {" "}
                      Issue of certificate on successful completion of project
                      work - <i className="italic">1 Certification Free</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-xmark"></i>
                    <span>
                      {" "}
                      Prioratized job Alerts on whenever a new job is posted by
                      employer - <i className="italic">(For 6 months)</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-xmark"></i>
                    <span>
                      Resume writing services of a senior career consultant
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="plan-btn">
              <button>Active</button>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div className="plan-content">
            <p className="cnt1">
              <span className="md">Intermediate</span>
              <span className="flot-right">
                <span className="lt">
                  <s>25,000</s>
                </span>
                <span className="bold">₹3800</span>
              </span>
            </p>

            <div className="row">
              <div className="col-12">
                <div className="plan-text">
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Includes Access to Projects, Internships & Jobs posted by
                      companies
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      Company projects you can work on simultaneouly{" "}
                      <i className="italic">- 02 Projects at a time</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Apply to all internships or job opportunties for freshers-{" "}
                      <i className="italic"> 10 Applications per Day</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Access to all information and data as provided by company
                      for working on Projects
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Build your project work portfolio by submitting your
                      project reports to employers
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Evaluation and validation of your project work by
                      Qollabb’s experts
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Issue of certificate on successful completion of project
                      work - <i className="italic">1 Certification Free</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Prioratized job Alerts on whenever a new job is posted by
                      employer - <i className="italic">(For 6 months)</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-xmark"></i>
                    <span>
                      Resume writing services of a senior career consultant
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="plan-btn">
              <button>Upgrade</button>
            </div>
            <div className="star">
              <img src={star1} alt="Image" />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div className="plan-content">
            <p className="cnt1">
              <span className="md">Expert</span>
              <span className="flot-right">
                <span className="lt">
                  <s>10,000</s>
                </span>{" "}
                <span className="bold">₹6500</span>
              </span>
            </p>

            <div className="row">
              <div className="col-12">
                <div className="plan-text">
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Includes Access to Projects, Internships & Jobs posted by
                      companies
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      Company projects you can work on simultaneouly{" "}
                      <i className="italic">- 02 Projects at a time</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Apply to all internships or job opportunties for freshers-{" "}
                      <i className="italic"> 10 Applications per Day</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Access to all information and data as provided by company
                      for working on Projects
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Build your project work portfolio by submitting your
                      project reports to employers
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Evaluation and validation of your project work by
                      Qollabb’s experts
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Issue of certificate on successful completion of project
                      work - <i className="italic">1 Certification Free</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      {" "}
                      Prioratized job Alerts on whenever a new job is posted by
                      employer - <i className="italic">(For 6 months)</i>
                    </span>
                  </div>
                  <div className="flex">
                    <i className="fa-solid fa-check"></i>
                    <span>
                      Resume writing services of a senior career consultant
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="plan-btn">
              <button>Upgrade</button>
            </div>
            <div className="star">
              <img src={star2} alt="Image" />
            </div>
          </div>
        </div>
      </div>

      <div className="addon-btn">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <p>
              Assessment of your project work & Issue of Project Completion
              Certificate.{" "}
            </p>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
            <div className="flot-right">
              <span>₹2900</span>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
            <button>Select Addon</button>
          </div>
        </div>
      </div>
      <div className="addon-btn">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <p>Mentoring support from industry domain experts </p>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
            <div className="flot-right">
              <span>As per mentor's fee/session </span>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
            <button>Explore Mentors</button>
          </div>
        </div>
      </div>

      <div className="mentor">
        <div className="suggested-mentor">
          <h2>Suggested Mentors</h2>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="mentor-info">
                <div className="row">
                  <div className="col-3">
                    <img src={mentorimg} alt="Image" width={85} height="88" />
                  </div>
                  <div className="col-9">
                    <div className="mentor-text">
                      <h3>Shrikant Sharma</h3>
                      <p>Marketing Professional 5+ Years</p>
                      <p className="txt1">Co-Founder Paytm</p>
                    </div>
                  </div>
                </div>
                <div className="mentor-btn">
                  <div className="row">
                    <div className="col-6">
                      <div className="mentor-price">
                        <p>₹ 1000 / Session</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <button>Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="mentor-info">
                <div className="row">
                  <div className="col-3">
                    <img src={mentorimg} alt="Image" width={85} height="88" />
                  </div>
                  <div className="col-9">
                    <div className="mentor-text">
                      <h3>Shrikant Sharma</h3>
                      <p>Marketing Professional 5+ Years</p>
                      <p className="txt1">Co-Founder Paytm</p>
                    </div>
                  </div>
                </div>
                <div className="mentor-btn">
                  <div className="row">
                    <div className="col-6">
                      <div className="mentor-price">
                        <p>₹ 1000 / Session</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <button>Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="mentor-info">
                <div className="row">
                  <div className="col-3">
                    <img src={mentorimg} alt="Image" width={85} height="88" />
                  </div>
                  <div className="col-9">
                    <div className="mentor-text">
                      <h3>Shrikant Sharma</h3>
                      <p>Marketing Professional 5+ Years</p>
                      <p className="txt1">Co-Founder Paytm</p>
                    </div>
                  </div>
                </div>
                <div className="mentor-btn">
                  <div className="row">
                    <div className="col-6">
                      <div className="mentor-price">
                        <p>₹ 1000 / Session</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <button>Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="explore">
          <div className="col-12">
            <button>Explore More</button>
          </div>
        </div>
      </div>

      <div className="subscription-plan">
        <h2>Subscription Plan</h2>
        <div className="plan-describe">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Description</th>
                  <th scope="col" className="text-center">
                    Total Amount
                  </th>
                  <th scope="col" className="text-center">
                    Invoice
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color fw-bold">Intermediate Plan</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color fw-bold">Intermediate Plan</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color fw-bold">Intermediate Plan</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color fw-bold">Intermediate Plan</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color fw-bold">Explore Plan</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="subscription-plan">
        <h2>Subscription Mentoring</h2>
        <div className="plan-describe">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Description</th>
                  <th scope="col" className="text-center">
                    Sessions Completed
                  </th>
                  <th scope="col" className="text-center">
                    Sessions Pending
                  </th>
                  <th scope="col" className="text-center">
                    Total Amount
                  </th>
                  <th scope="col" className="text-center">
                    Invoice
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color">Mentoring - Shrikant Sharma</td>
                  <td className="text-center">05</td>
                  <td className="text-center">03</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color">Mentoring - Shrikant Sharma</td>
                  <td className="text-center">05</td>
                  <td className="text-center">03</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color">Mentoring - Shrikant Sharma</td>
                  <td className="text-center">05</td>
                  <td className="text-center">03</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color">Mentoring - Shrikant Sharma</td>
                  <td className="text-center">05</td>
                  <td className="text-center">03</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
                <tr>
                  <td>02 Oct 2022</td>
                  <td className="des-color">Mentoring - Shrikant Sharma</td>
                  <td className="text-center">05</td>
                  <td className="text-center">03</td>
                  <td className="text-center">₹ 1000</td>
                  <td className="text-center">
                    <img src={download} alt="download" width={20} height={20} />
                    <img src={print} alt="download" width={20} height={20} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="process-select-plan">
        <div className="select-plan">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="process-plan">
                <div className="plan-txt">
                  <h3>Process</h3>
                  <p>
                    Book an Interview & Get your project work evaluated by a
                    Qollabb Expert.
                  </p>
                  <p>Make payment for the evaluation process.</p>
                  <p>Get interviewd.</p>
                  <p>Unlock your certificate.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
              <div className="row">
                <h3>Select Plan to continue</h3>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="plan-content">
                    <p className="cnt1">
                      <span className="md">Intermediate</span>
                      <span className="flot-right">
                        <span className="lt">
                          <s>25,000</s>
                        </span>
                        <span className="bold">₹3800</span>
                      </span>
                    </p>

                    <div className="row">
                      <div className="col-12">
                        <div className="plan-text">
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Includes Access to Projects, Internships & Jobs
                              posted by companies
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              Company projects you can work on simultaneouly{" "}
                              <i className="italic">- 02 Projects at a time</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Apply to all internships or job opportunties for
                              freshers-{" "}
                              <i className="italic"> 10 Applications per Day</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Access to all information and data as provided by
                              company for working on Projects
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Build your project work portfolio by submitting
                              your project reports to employers
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Evaluation and validation of your project work by
                              Qollabb’s experts
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Issue of certificate on successful completion of
                              project work -{" "}
                              <i className="italic">1 Certification Free</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Prioratized job Alerts on whenever a new job is
                              posted by employer -{" "}
                              <i className="italic">(For 6 months)</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-xmark"></i>
                            <span>
                              Resume writing services of a senior career
                              consultant
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="plan-btn">
                      <button>Upgrade</button>
                    </div>
                    <div className="star">
                      <img src={star1} alt="Image" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="plan-content">
                    <p className="cnt1">
                      <span className="md">Expert</span>
                      <span className="flot-right">
                        <span className="lt">
                          <s>10,000</s>
                        </span>{" "}
                        <span className="bold">₹6500</span>
                      </span>
                    </p>

                    <div className="row">
                      <div className="col-12">
                        <div className="plan-text">
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Includes Access to Projects, Internships & Jobs
                              posted by companies
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              Company projects you can work on simultaneouly{" "}
                              <i className="italic">- 02 Projects at a time</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Apply to all internships or job opportunties for
                              freshers-{" "}
                              <i className="italic"> 10 Applications per Day</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Access to all information and data as provided by
                              company for working on Projects
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Build your project work portfolio by submitting
                              your project reports to employers
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Evaluation and validation of your project work by
                              Qollabb’s experts
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Issue of certificate on successful completion of
                              project work -{" "}
                              <i className="italic">1 Certification Free</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              {" "}
                              Prioratized job Alerts on whenever a new job is
                              posted by employer -{" "}
                              <i className="italic">(For 6 months)</i>
                            </span>
                          </div>
                          <div className="flex">
                            <i className="fa-solid fa-check"></i>
                            <span>
                              Resume writing services of a senior career
                              consultant
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="plan-btn">
                      <button>Upgrade</button>
                    </div>
                    <div className="star">
                      <img src={star2} alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className="or">
                    {" "}
                    <img src={lineImg} alt="line" width={300} /> OR{" "}
                    <img src={lineImg} alt="line" width={300} />
                  </h4>
                  <div className="addon-btn">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <p>Evaluation & Certificate </p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <span>₹2700</span>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <button>Select Addon</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button className="btn2">Next: Choose Date & Time</button>
            </div>
          </div>
        </div>
      </div>
      <div className="select-plan-detail">
        <div className="select-plan">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="process-plan">
                <div className="plan-txt">
                  <h3>Process</h3>
                  <p>
                    Book an Interview & Get your project work evaluated by a
                    Qollabb Expert.
                  </p>
                  <p>Make payment for the evaluation process.</p>
                  <p>Get interviewd.</p>
                  <p>Unlock your certificate.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
              <div className="plan-form">
                <h3>Plan</h3>
                <div className="flex">
                  <p className="h4">
                    Plan 1 &nbsp;<span>(Change Plan)</span>
                  </p>
                  <p className="price">
                    <b>₹7500</b>
                    <i className="fa-solid fa-info"></i>
                  </p>
                </div>
                <h3>Project Details</h3>
                <div className="flex gap">
                  <img src={WhatsappImg} alt="Image" width={68} height={68} />
                  <div className="text">
                    <h4>Product Designer by Whatsapp</h4>
                    <p>Noida, UP, India</p>
                  </div>
                </div>
                <h3>Your Details</h3>
                <div className="flex gap">
                  <img src={profileImg} alt="Image" />
                  <div className="text">
                    <h4>Ayush Jii</h4>
                    <p>ayush@ayush.com</p>
                  </div>
                </div>
                <h3>Choose date & time</h3>
                <div className="flex option">
                  <label>
                    Select Date <input type="date" className="wd" />
                  </label>
                  <select>
                    <option selected>Select time slot</option>
                    <option>09:00</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button className="paybtn">Continue to pay ₹ 3500</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Plan
