import { get } from "axios";
import LinkProjectDropdown from "components/linkDropdown/LinkProjectDropdown";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "styles/employer/add-project.scss";
const LinkProjectPopup = ({
  setLinkedProject,
  isPopupOpen,
  setIsPopupOpen,
}) => {
  const id = useSelector((state) => state.auth.user.id);
  const [projects, setProjects] = useState(() => []);
  const [selectedProject, setSelectedProject] = useState(() => {});
  useEffect(() => {
    const getProjects = async () => {
      const opts = {
        params: { initiated_by: id },
        valdateStatus: () => true,
      };

      const { data } = await get("/v1/enrollments/project", opts);

      if (!data.error && data.data) {
        const activeProjects = data.data?.filter((item) => item.status === 2);
        setProjects(activeProjects);
      }
    };

    getProjects();
  }, [id]);

  const handleProjectClick = ({ id, title }) => {
    // console.log(id, title);
    setLinkedProject({ id, name: title });
    setIsPopupOpen(false);
  };

  return isPopupOpen ? (
    <div className="popup_container d-flex flex-column pointer p-4">
      {projects.length > 0 ? (
        <div className="projects col-12">
          <i className="fa-regular fa-file-lines fa-3x"></i>
          <label className="my-3">
            <h5
              style={{
                fontWeight: "700",
              }}
            >
              Select Project
            </h5>
          </label>
          <LinkProjectDropdown
            projects={projects}
            setLinkedProject={setLinkedProject}
            setSelectedProject={setSelectedProject}
            selectedProject={selectedProject}
          />
          <button
            onClick={() => handleProjectClick(selectedProject)}
            className="col-12 mt-3"
          >
            Link Project
          </button>
        </div>
      ) : (
        <div className="no_projects">
          <i className="fa-regular fa-file-lines fa-3x"></i>
          <label className="my-3">
            <h5>No projects to link</h5>
            <span>
              There are no projects to attach with this Project. Kindly add a
              project and then continue with your submission
            </span>
            {/* underline  */}
            <div
              style={{
                width100: "100%",
                height: "2px",
                backgroundColor: "#DCDCDC",
                margin: "10px 0 10px 0",
              }}
            ></div>
            <button
              style={{
                backgroundColor: "#6149cd",
                width: "100%",
                borderRadius: "15",
                padding: "13px",
              }}
            >
              Add Project
            </button>
          </label>
        </div>
      )}
      {/* make a cross button which false the setIsOpen */}
      <i
        className="close-btn fa-regular fa-times fa-x"
        onClick={() => setIsPopupOpen(false)}
      ></i>
    </div>
  ) : (
    <></>
  );
};

export default LinkProjectPopup;
