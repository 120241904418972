import React from "react";
import companyImg from "./companyImg.png";
import User from "./User.png";

import LinkedIn from "./Linkedin.svg";
import Link from "./Link.svg";
import "./companiesCard.scss";
import ProfileIcon from "components/employer/ProfileIcon";

const CompaniesCard = ({ college }) => {
  return (
    <div className="company_card">
      <div className="top_content row">

        <div className="company_details col-sm-12 col-md-6">
          {college?.company_logo || college?.branch?.company_logo ? (
            <img
              src={college?.company_logo || college?.branch?.company_logo}
              alt="Image"
              className="rounded"
            />
          ) : (
            <div
              style={{
                height: "40px",
                width: "40px",
              }}
            >
              <ProfileIcon />
            </div>
          )}
          <div className="company_detais_data">
            <h4>{college?.company?.company_name}</h4>
            <p>{college?.location}</p>
          </div>
        </div>
        <div className="user_deta_project col-sm-12 col-md-6">
          <p>Contact Person</p>
          <div className="user_data_container">
            <img src={User} alt="Image" />
            <div>
              <h4>
                {college?.first_name} {college?.last_name}
              </h4>
              <p>{college?.designation}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mid_content">
        <p>{college?.bio}</p>
      </div>
      <div className="bottom_content">
        {college?.profile_link ? (
          <div className="link_btn">
            <img src={Link} alt="Image" />
            <a href={college?.profile_link}>{college?.profile_link}</a>
          </div>
        ) : (
          <></>
        )}
        {college?.linkedIn_link ? (
          <div className="link_btn">
            <img src={LinkedIn} alt="Image" />
            <a href={college?.linkedIn_link}>{college?.linkedIn_link}</a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CompaniesCard;
