import { combineReducers } from "redux";
import auth from "./auth";
import application from "./application";
import location from "./location";
import educatorInvites from "./educatorInvites";
const rootReducer = combineReducers({
  auth,
  application,
  location,
  educatorInvites,
});

export default rootReducer;
