import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Loader from "components/employer/Loader";
import PreviewField from "components/employer/PreviewField";
import { defaultValues as categories } from "components/formComponents/CategoryField";
import useFetch from "hooks/useFetch";
import moment from "moment";
import "moment-timezone";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserIcon from "../userIcon.svg";
import StudentListAccordian from "../StudentListAccordian";
import CreateListPopup from "../../components/StudentListPopups/CreateListPopup";
import ManualUploadPopup from "../../components/StudentListPopups/ManualUploadPopup";
import PostDetailsSection from "components/posts/PostsHelper/PostDetailsSection";

const EducatorInternshipDetail = () => {
  const location = useLocation();
  const { id = 0 } = useParams();
  const [showModal, setShowModal] = useState(false);

  // // console.log(id);

  const user = useSelector((state) => state.auth.user);
  const { loading, data, error } = useFetch(`/v1/internship/${id}`);
  const [values, setValues] = useState([]);


  const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const[loading1, setLoading1]=useState(false)
    const [refresh, setRefresh] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);


    const closeModal = () => {
      setIsModalOpen(!isModalOpen);
      // handleRefreshFromStudentListTable();
    };

    const closeModal2 = () => {
      setIsModalOpen2(!isModalOpen2);
      // handleRefreshFromStudentListTable();
    };


  useEffect(() => {
    
    const fetchStudentsLists = async()=> {
      try {
        const res = await axios.get(
          "/v1/educator/invite/uploaded_students"
        );
        // console.log("ress", res?.data);

        setValues(res?.data?.data)
        
      } catch (error) {
        console.log("err", error);
      }
    }

    fetchStudentsLists();
  }, [refresh]);
  // // console.log(data);

  if (loading || !data) return <Loader />;
  const { error: err, data: internship } = data;

  if (err || error) return <h3>{err}</h3>;

  // // console.log(internship);

  const sendInvites = async () => {
    setShowModal(true);
    const selected = values.filter((x) => x[5]);
    const ids = selected.map((x) => x[6]);
    // // console.log(selected, ids,"selected")
    const { data } = await axios.post("/v1/educator/invite", {
      // invitee_ids: ids,
      invitee_ids: selectedIds,
      post_id: id,
      method: "internship",
    });

    if (data.error) {
      toast.error(data.message);
      setShowModal(false);
    } else {
      toast.success(data.message);
      setShowModal(false);
    }
  };

  // const joining_date = "20/12/2023"
  // const internship_type = "Remote/Virtual "

  const tempData = [
    {
      student_name: "",
      course: "",
      specialization: "",
      semester: "",
      email_id: "",
    },
  ];
  const changeHandler = (event) => {
    try {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const valuesArray = results.data.reduce(
            (accu, curr) => {
              // console.log(curr);
              if (!curr.email_id) {
                return { ...accu, skipped: accu.skipped + 1 };
              }
              if (
                !curr.email_id.match(
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                )
              ) {
                return { ...accu, invalid: accu.invalid + 1 };
              }
              if (values.some((x) => x["email_id"] === curr.email_id)) {
                return { ...accu, dublicate: accu.dublicate + 1 };
              }

              const {
                name = "",
                student_name = "",
                course = "",
                specialization = "",
                semester = null,
                email_id: email_address = null,
              } = curr;

              return {
                ...accu,
                data: [
                  ...accu.data,
                  [
                    name || student_name,
                    course,
                    specialization,
                    semester,
                    email_address,
                  ],
                ],
              };
            },
            {
              skipped: 0,
              invalid: 0,
              dublicate: 0,
              data: [],
            }
          );

          if (valuesArray.skipped) {
            toast.warning(`Skipped ${valuesArray.skipped} rows.`);
          }
          if (valuesArray.invalid) {
            toast.warning(
              `Skipped ${valuesArray.invalid} invalid emails. Please check the email format.`
            );
          }
          if (valuesArray.dublicate) {
            toast.warning(`Skipped ${valuesArray.dublicate} dublicate emails.`);
          }

          if (!valuesArray.data.length) return;

          const uploaded = await axios.post(
            "/v1/educator/invite/upload_students",
            {
              invitee_ids: valuesArray.data,
            }
          );

          if (uploaded.data.error) {
            toast.error(uploaded.data.message);
          } else {
            const added = uploaded.data.data;
            const newValues = added
              .map((id, index) => {
                if (id) {
                  // console.log(valuesArray.data[index]);
                  return [...valuesArray.data[index], true, id];
                } else return null;
              })
              .filter((x) => x);

            setValues([...values, ...newValues]);

            toast.success(uploaded.data.message);
          }
        },
      });
    } catch (err) {
      // console.log(err);
    } finally {
      event.target.value = null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US");
    return formattedDate;
  };

  const joining_date = moment(internship?.expected_start_date)
    .tz("Asia/Kolkata")
    .format("DD/MM/YYYY");

  // console.log(data, "internshipData");
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  const category_name = categories?.find(
    (item) => item?.id === map[data?.data?.internship_category]
  )?.label;

  if (loading || !data) return <Loader />;
  if (err || error) return <h3>{err}</h3>;
  return (
    <>

     {showModal && (
        <div className="modal-send-invite">
          <div className="modal-send-body">
            <p>
              <strong>Sending Invites</strong>
            </p>
            <button className="bg-primary">
              <div className="spinner">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G...</span>
              </div>
            </button>
          </div>
        </div>
      )}



    <div className="preview_container">
      <div className="preview_box">
        <section>
        <PostDetailsSection data={internship}  type={"internship"} />
          
        <div className="">
              <div className="edu_btn">
                <button
                  className="btn download_btn"
                  onClick={()=>setIsModalOpen(!isModalOpen)}
                >
                  Create New List
                </button>
              </div>
            </div>

        </section>
        <section className="action_containers">
          <div className="actions_btn">
            <div className="right_btn">
                <button
                  className="btn download_btn"
                  onClick={()=>setIsModalOpen(!isModalOpen)}
                >
                  Create New List
                </button>
              {/* <label htmlFor="csv-upload" className="btn upload_btn">
                Upload New List
              </label>
              <input
                id="csv-upload"
                type="file"
                name="file"
                accept=".csv"
                onChange={changeHandler}
                style={{ display: "none", margin: "10px auto" }}
              /> */}
            </div>
            {/* <button className="btn" onClick={onApply} > 
            Apply
          </button> */}
            <div className="send_oportunity flex w-100">
              <button
                style={{
                  width: "100%",
                  color: "white",
                  fontSize: "18.8px",
                  fontWeight: "600",
                  height: "55.5px",
                  backgroundColor: "#47D065",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="btn send_btn"
                onClick={sendInvites}
              >
                Send Opportunity
                <i style={{ color: "#ffffff" }} className="fa fa-send"></i>
              </button>
            </div>
          </div>
          {/* right side    */}
{/* 
          <div className="right_side_container">
            <div className="right_side_title">
              <h4>{values.filter((i) => i[5]).length} Selected</h4>
              <p>See All</p>
            </div>

            <div style={{ width: "100%" }}>
              <div className="row align-items-center">
                <div className="col-1">
                  <div className="form-check d-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      defaultChecked={false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        const newValues = [...values];
                        newValues.forEach((value) => {
                          value[5] = checked;
                        });
                        setValues(newValues);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <strong>Student Name</strong>
                </div>
                <div className="col-5">
                  <strong>Course</strong>
                </div>
              </div>
              <div>
                {values.map((value, index) => {
                  const [
                    name,
                    course,
                    specialization,
                    semester,
                    _email_address,
                    selected,
                    id,
                  ] = value;
                  return (
                    <div className="row align-items-center" key={id}>
                      <div className="col-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={selected}
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              const newValues = [...values];
                              newValues[index][5] = e.target.checked;
                              setValues(newValues);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        {name || ""} {semester ? `(${semester})` : ""}
                      </div>
                      <div className="col-5">
                        {course || ""}{" "}
                        {specialization ? `(${specialization})` : ""}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}


          {
            values && 
            <div className="right_side_container">
              <h3>Student Lists</h3>
              {
                values?.map((item)=> (
                  <StudentListAccordian listName={item?.list_name} students={item?.educator_students} selectedIds={selectedIds} setSelectedIds={setSelectedIds}  />
                  
                ))
              }
            </div>
          }

        </section>
      </div>
    </div>

              {isModalOpen && (
                <CreateListPopup  onClose={closeModal} setIsModalOpen2={setIsModalOpen2}  setRefresh={setRefresh} setLoading={setLoading1} loading={loading1} />
              )}

              {isModalOpen2 && (
                <ManualUploadPopup  onClose={closeModal2} setRefresh={setRefresh} setLoading={setLoading1} loading={loading1} />
              )}

    
    </>
  );
};

export default EducatorInternshipDetail;
