import React, { useState } from "react";
import AdminCreateCoupons from "./create-coupons";
import AdminCouponUsageData from "./usage-data";
import "./style.scss";

const AdminCoupons = () => {
  const tabs = ["Create Coupons", "Usage Data"];
  const [activeTab, setActiveTab] = useState(() => 0);

  return (
    <div
      className="content-container py-5 pt-2 admin-manage-coupons"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <h3 className="mb-0 mt-4">Manage Coupons</h3>
      <div className="row">
        <div className="col-12">
          <div className="table-tabs">
            <nav>
              <div
                className="nav_tabs nav nav-tabs"
                id="nav-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <button
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className={`nav-link ${
                      activeTab === index ? "active" : ""
                    }`}
                    id={`nav-${tab}-tab`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${tab}`}
                    aria-selected="true"
                    onClick={() => setActiveTab(index)}
                    key={tab}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </nav>

            <div
              className="tab-pane fade show active"
              id={`nav-${activeTab}`}
              role="tabpanel"
              aria-labelledby={`nav-${activeTab}-tab`}
            >
              <LoadTable activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCoupons;

const LoadTable = ({ activeTab, data }) => {
  switch (activeTab) {
    case 0:
      return <AdminCreateCoupons />;
    case 1:
      return <AdminCouponUsageData />;
    default:
      return <AdminCreateCoupons />;
  }
};
