import React from "react"
import { Link } from "react-router-dom"
import "../login/style.scss"
import AuthNavbar from "components/navbar/AuthNavbar"
import Heading2 from "components/typography/Heading2"
import TextField from "components/formComponents/TextField"
import SubmitButton from "components/formComponents/SubmitButton"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import axios from "axios"

const AdminForgetPassword = () => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { email: "" },
  })

  const onSubmit = async (e) => {
    const { email = "" } = e
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const isValid = regexp.test(email.toLowerCase())
    if (!email.trim() || !isValid)
      return toast.error("Please enter valid email address", {
        theme: "colored",
        toastId: "forgot_email_empty",
      })

    try {
      const { data } = await axios.post("/v1/auth/forgot_password", { email })
      toast.info(data.message, {
        theme: "colored",
        toastId: "forgot_email_sent",
      })

      reset()

      // navigate(`/reset-password?token=${data.data.hash}&iv=${data.data.iv}`)
    } catch (err) {
      toast.error(
        err?.response?.data?.message || err?.message || "Error sending email",
        {
          toastId: "forgot_email_error",
        }
      )
    }

    // toast.success("Email sent", {
    //   theme: "colored",
    //   toastId: "forgot_email_sent",
    // });
  }

  return (
    <div className="admin-login">
      <AuthNavbar />
      <div className="form-container p-4 py-5">
        <Heading2 text="Forgot Password?" />
        <p>
          Enter the email address you used when you joined and we'll send you
          instructions to reset your password.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Email Address"
            id="email"
            type="email"
            placeholder="Tonynguyen@example.com"
            register={register}
          />
          <SubmitButton value="Submit" onSubmit={handleSubmit(onSubmit)} />
        </form>
        <div className="text-center mb-3">
          <Link
            to="/admin/signin"
            className="back-to-signin"
            style={{
              fontWeight: "700",
            }}
          >
            <i className="fa-solid fa-chevron-left me-2"></i> Back to sign in
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AdminForgetPassword
