import React from "react";
import SuggestedCollege from "./sections/SuggestedCollege";
import InvitationsSent from "./sections/invitationsSent/InvitationsSent";
import InvitationRecieved from "./sections/invitationRecieved/InvitationRecieved";
import InvitationAccepted from "./sections/invitationAccepted/InvitationAccepted";
import ListOfPartnerInstitutions from "./sections/listOfPartnerInstitutions/ListOfPartnerInstitutions";
import ShowBothListAndDetail from "./sections/invitationsSent/InvitationsSent";

const SelectedTabs = ({
  tab = 0,
  post = {},
  enrollments = {},
  handleStatusChange,
  state,
  method,
  new1,
  setNew1,
}) => {
  const getFiltered = (status) => {
    const filtered = enrollments?.data?.filter(
      (item) => item?.status === status
    );
    return {
      count: enrollments?.count,
      data: filtered,
      length: filtered?.length,
    };
  };
  //<Shortlisted post={post} enrollments={getFiltered(4)} />

  //<Hired post={post} enrollments={getFiltered(6)} />

  //<Rejected post={post} enrollments={getFiltered(7)} />

  switch (tab) {
    case 0:
      return (
        <SuggestedCollege
          post={post}
          enrollments={enrollments}
          handleStatusChange={handleStatusChange}
          state={state}
          method={method}
          new1={new1}
          setNew1={setNew1}
        />
      );
    case 1:
      return (
        // <InvitationsSent
        //   state={state}
        //   new1={new1}
        //   setNew1={setNew1}
        //   method={method}
        // />

        <ShowBothListAndDetail
          state={state}
          new1={new1}
          setNew1={setNew1}
          method={method}
        />
      );

    default:
      return <div>Applications</div>;
  }
};

export default SelectedTabs;
