import axios from "axios";
import PreviewField from "components/employer/Field";
import { defaultValues as categories } from "components/formComponents/CategoryField";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getParsed } from "services/factories/getParsed";
import StatusProgress from "view/dashboard/student/application/StatusProgress";
import WeekDetail from "view/dashboard/student/application/WeekDetail";
import ProjectReportTable from "../components/ProjectReportTable";
import ProjectReportTableCheckbox from "../components/ProjectReportTableCheckbox";
import ProjectReportTableCheckbox2 from "../components/ProjectReportTableCheckbox2";
import ProjectReportTableCheckbox3 from "../components/ProjectReportTableCheckbox3";
import ProjectTableMobile from "../components/ProjectTableMobile";
import ProjectTableMobileCheckbox1 from "../components/ProjectTableMobileCheckbox1";
import "./projectEvalutaionDetail.scss";
import img from "./userimg.png";
const ProjectEvaluationDetail = () => {
  const data = { keywords: ["it", "react"] };
  const { id } = useParams();

  const [weekly_submissions, setWeeklySubmissions] = useState([]);
  const [final_submission_report, setFinalSubmissionReport] = useState({});

  const [totalRating, setTotalRating] = useState([]);
  const [rating1, setRating1] = useState([0, 0, 0, 0]);
  const [rating2, setRating2] = useState([0, 0, 0, 0]);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [assesmentRemark, setAssesmentRemark] = useState("");
  const [domainSuggestions, setDomainSuggestions] = useState("");

  const [getSingleEvaluationData, setGetSingleEvaluationData] = useState({});

  // const getSingleEvaluationData = useFetch(
  //   `/v1/evaluation/getSingleEvaluation/${id}`
  // );

  // // console.log(getSingleEvaluationData, "getSingleEvaluationData");

  // const evaluationData = getSingleEvaluationData?.data?.data;

  // // console.log(evaluationData, "evaluationData");

  useEffect(() => {
    axios.get(`/v1/evaluation/getSingleEvaluation/${id}`).then((res) => {
      setGetSingleEvaluationData(res.data.data);
      // setWeeklySubmissions(res.data.data?.weekly_submissions);
      const submissions =
        getParsed(res.data.data?.enrollment?.weekly_submissions) || [];
      // // console.log(submissions, "submissions");
      setFinalSubmissionReport(
        res.data.data?.enrollment?.final_submission_report || false
      );
      setWeeklySubmissions(submissions);
    });
  }, []);
  // // console.log(getSingleEvaluationData, "getSingleEvaluationData");
  // // console.log(weekly_submissions, "weekly_submissions");

  const handleRadioChange = useCallback((event) => {
    // console.log(event.target.value);
  }, []);
  // const TotalRating = 10;

  const [myTotalRating, setMyTotalRating] = useState(0);
  // // console.log(getSingleEvaluationData?.enrollment, "getSingleEvaluationData");
  const navigate = useNavigate()
  const Submit = async () => {
    // console.log(myTotalRating, "totalRating");
    setTotalRating([
      { PRQ: rating1 },
      { PPQ: rating2 },
      { UFP: rating3 },
      { UOP: rating4 },
      { TRS: rating5 },
      { TotalRating: myTotalRating },
    ]);

    const data = await axios.post("/v1/evaluation/submitEvaluation", {
      student_id: getSingleEvaluationData?.student?.id,
      mentor_id: getSingleEvaluationData?.mentor_id,
      enrollment_id: getSingleEvaluationData?.enrollment?.id,
      evaluation_id: getSingleEvaluationData?.id,
      prq_rating: rating1,
      ppq_rating: rating2,
      ufp_rating: rating3,
      iop_rating: rating4,
      trs_rating: rating5,
      overall_rating: myTotalRating,
    });

    if(!data?.data?.error){
      toast.success("Evaluation submit successfully")
      navigate("/mentor/project-evaluation")
    }
    else{
      toast.error(data?.data?.message)
    }
  };

  // // console.log(totalRating, "totalRating", myTotalRating);

  useEffect(() => {
    // const totalRating1 = rating1 * 0.15;
    // const totalRating2 = rating2 * 0.15;
    // const totalRating3 = rating3 * 0.25;
    // const totalRating4 = rating4 * 0.25;
    // const totalRating5 = rating5 * 0.2;

    const calculateTotalRating = (array, weight) => {
      return (
        array.reduce((total, value) => parseInt(total) + parseInt(value), 0) *
        weight
      );
    };

    const totalRating =
      calculateTotalRating(rating1, 0.15) +
      calculateTotalRating(rating2, 0.15) +
      parseFloat(rating3 || 0) * 0.25 +
      parseFloat(rating4 || 0) * 0.25 +
      parseFloat(rating5 || 0) * 0.2;

    setMyTotalRating(totalRating);
  }, [rating1, rating2, rating3, rating4, rating5]);

  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };

  // // console.log(rating3,"handleRatingChange3");

  const category_name = categories?.find(
    (item) =>
      item?.id ===
      map[getSingleEvaluationData?.enrollment?.project?.project_category]
  )?.label;

  // console.log("evalutation",getSingleEvaluationData)
  // console.log("remarks", {assesmentRemark, domainSuggestions});
  return (
    <section className="projectEvaluation_main py-4">
      <div className="mobo_padding container-lg container-fluid">
        <h3>Project Evaluation </h3>

        <div className="top_bar text-white">
          <div className=" left-content ">
            {/* <i class="fa fa-arrow-left text-white fs-2" aria-hidden="true"></i> */}
            <div className="d-flex align-items-center gap-2">
              <img
                width={64}
                height={64}
                className="rounded-circle"
                src={getSingleEvaluationData?.student?.profile_photo || img}
                alt="Image"
              />
              <div>
                <h4>
                  {getSingleEvaluationData?.student?.first_name +
                    " " +
                    getSingleEvaluationData?.student?.last_name}
                </h4>
                <p>{getSingleEvaluationData?.student?.bio.slice(0, 50)}</p>
              </div>
            </div>
          </div>
          <div className="right-content">
            <div>
              <p>Date Assigned</p>
              <h4>
                {moment(getSingleEvaluationData?.mentor_assigned_date)
                  .tz("Asia/Kolkata")
                  .format("DD/MM/YYYY")}
              </h4>
            </div>
            <div>
              <p>Due Date</p>
              <h4>
                {moment(getSingleEvaluationData?.requested_date)
                  .tz("Asia/Kolkata")
                  .format("DD/MM/YYYY")}
              </h4>
            </div>
          </div>
        </div>

        <div className="mobo_padding_inner project-mainSection my-4 gap-3">
          <div>
            <div className="left_upper flex">
              <div className="w-100">
                <div className="text-white px-3 px-sm-0">
                  <h4 className="m-0">
                    {
                      getSingleEvaluationData?.enrollment?.project
                        ?.project_title
                    }
                  </h4>

                  <p>
                    {category_name}{" "}
                    {getSingleEvaluationData?.enrollment?.project?.office_visit
                      ? "with office visit"
                      : ""}
                  </p>
                </div>

                <div className="w-100 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex d-sm-block gap-3">
                  <div className="box2">
                    <img
                      src={
                        getSingleEvaluationData?.enrollment?.project?.project_global_fkey?.employer?.company_logo
                      }
                      width={64}
                      height={64}
                      alt="ibm"
                    />
                    <div className="box-cnt">
                      <h4>
                        {
                          getSingleEvaluationData?.enrollment?.project?.branch
                            ?.company_database?.company_name
                        }
                      </h4>
                      <p className="thin">
                        {/* Noida, Uttar Pradesh, India */}
                        {getSingleEvaluationData?.enrollment?.project?.project_global_fkey?.employer?.designation
                         +
                          " " +
                          getSingleEvaluationData?.enrollment?.project?.project_global_fkey?.employer?.first_name + " " + getSingleEvaluationData?.enrollment?.project?.project_global_fkey?.employer?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="box1_mobo d-flex d-sm-none w-100">
                    <p>Duration</p>
                    <div className="">
                      <h3>
                        {
                          getSingleEvaluationData?.enrollment?.project
                            ?.duration_from
                        }
                        -
                        {
                          getSingleEvaluationData?.enrollment?.project
                            ?.duration_to
                        }
                      </h3>
                      <p className="light">Weeks</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box1 d-none d-sm-block">
                <p>Duration</p>
                <h3>
                  {getSingleEvaluationData?.enrollment?.project?.duration_from}-
                  {getSingleEvaluationData?.enrollment?.project?.duration_to}
                </h3>
                <p className="light">Weeks</p>
              </div>
            </div>
            <div className="project_detail_content">
              <div className="">
                {getSingleEvaluationData?.enrollment?.project?.keywords
                  ?.length > 0 && (
                  <PreviewField
                    label="Project Details"
                    items={
                      getSingleEvaluationData?.enrollment?.project?.keywords
                    }
                    type="chips"
                  />
                )}
                <PreviewField
                  label="What is the main goal for the Project?"
                  text={
                    getSingleEvaluationData?.enrollment?.project?.project_goal
                  }
                />
              </div>
              <button className="d-none">Open Project Details</button>
            </div>
          </div>
          <div>
            <div className="student_progress col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <StatusProgress
                status={getSingleEvaluationData?.enrollment?.status}
                reports={weekly_submissions}
                final_submission={final_submission_report ? true : false}
              />

              <div className="weekly-report-accordian">
                <h2>Weekly Reports</h2>
                {weekly_submissions?.map((item) => (
                  <WeekDetail
                    key={item?.week}
                    report={item?.report}
                    index={item?.week}
                    title={`Week ${item?.week}`}
                    description={item?.description}
                    text="Not Uploaded"
                    midTitle="submitted"
                  />
                ))}
                <div className="final_submission_report_container">
                  {/* <h2>Final Submission</h2> */}
                  {/* {Object?.entries(final_submission_report).map(
                    ([key, value]) => {
                      return (
                        <WeekDetails
                          key={key}
                          report={value}
                          index={key}
                          title={key}
                        />
                      );
                    }
                  )} */}
                  {/* {final_submission_report &&
                    Object.entries(final_submission_report)
                      .slice(1)
                      .map(([key, value]) => (
                        <WeekDetail
                          key={key}
                          report={value}
                          index={key}
                          title={key}
                          description={value ? "Uploaded" : ""}
                          text="Not Uploaded"
                          midTitle="submitted"
                        />
                      ))} */}
                  <WeekDetail
                    report={final_submission_report?.report}
                    index={-1}
                    // handleClick={handleClick}
                    description={
                      final_submission_report?.report ? "Uploaded" : ""
                    }
                    title="Project Report"
                  />
                  <WeekDetail
                    report={final_submission_report?.presentation}
                    index={-1}
                    // handleClick={handleClick}
                    description={
                      final_submission_report?.presentation ? "Uploaded" : ""
                    }
                    title="Project Presentation"
                  />
                  <WeekDetail
                    report={final_submission_report?.other}
                    index={-1}
                    // handleClick={handleClick}
                    description={
                      final_submission_report?.other ? "Uploaded" : ""
                    }
                    title="Any Other Document"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="evaluationForm d-none d-sm-block">
          <h4>Evaluation Form</h4>

          <ProjectReportTable
            title="Project Report Quality"
            subTitle="Description of evaluation criteria"
            text1="Neatly written, no language or grammer mistakes"
            text2="Length-Not to short, not too long (50-150 pages)"
            text3="Clearly explains the project and all its elements, sections, output"
            text4="Overall formatting quality"
            setRating1={setRating1}
            rating1={rating1}
          />
          <ProjectReportTable
            title="Project Presentation Quality"
            subTitle="Description of evaluation criteria"
            text1="Neatly written, no language or grammer mistakes"
            text2="Length-Not to short, not too long (50-150 pages)"
            text3="Clearly explains the project and all its elements, sections, output"
            text4="Overall formatting quality"
            setRating2={setRating2}
            rating2={rating2}
          />
          <ProjectReportTableCheckbox
            title="Usefullness of output/Fitment to purpose"
            subTitle="Description of evaluation criteria"
            text1="Exceeds expectations"
            text2="Pratially exceeds expectation"
            text3="Meets expectation"
            text4="Pratially meets expectation"
            text5="Doesn’t meet expectation"
            checkbox
            setRating3={setRating3}
            rating3={rating3}
          />
          <ProjectReportTableCheckbox2
            title="Innovativeness of output of project"
            subTitle="Description of evaluation criteria"
            text1="Exceeds expectations"
            text2="Pratially exceeds expectation"
            text3="Meets expectation"
            text4="Pratially meets expectation"
            text5="Doesn’t meet expectation"
            checkbox
            setRating4={setRating4}
            rating4={rating4}
          />
          <ProjectReportTableCheckbox3
            weekly_submissions={weekly_submissions}
            final_submission_report={final_submission_report}
            title="Timeliness/decipline in report submission"
            subTitle="Description of evaluation criteria"
            text1="Timeliness/decipline in weekly and final report submission"
            checkbox
            setRating5={setRating5}
            rating5={rating5}
            project_date={getSingleEvaluationData?.enrollment?.project?.duration_to}
            enrollment_date={getSingleEvaluationData?.createdAt}
          />

          <div>
            <h2>Overall qualitative assessment remarks: (max 1000 characters)</h2>
              <textarea
              id={"oqar"}
              className=" customtextarea form-control"
              value={assesmentRemark}
              onChange={(e)=> setAssesmentRemark(e.target.value)}
              />
          </div>
          <div className="mt-4">
          <h2>Suggestions for further development in this domain: (max 1000 characters)</h2>
              <textarea
              id={"sfdtd"}
              className="customtextarea form-control"
              value={domainSuggestions}
              onChange={(e)=> setDomainSuggestions(e.target.value)}
              />
          </div>
        </div>

        <div className="evaluationForm_mobile d-block d-sm-none">
          <h4>Evaluation Form</h4>
          <ProjectTableMobile
            title="Project Report Quality"
            setRating1={setRating1}
            rating1={rating1}
          />
          <ProjectTableMobile
            title="Project Presentation Quality"
            setRating2={setRating2}
            rating2={rating2}
          />
          <ProjectTableMobile
            title="Usefullness of output/Fitment to purpose"
            setRating3={setRating3}
            rating3={rating3}
            checkbox
          />
          <ProjectTableMobile
            title="Innovativeness of output of project"
            checkbox
            setRating4={setRating4}
            rating4={rating4}
          />
          <ProjectTableMobileCheckbox1
            title="Timeliness/decipline in report submission"
            checkbox
            setRating5={setRating5}
            rating5={rating5}
          />
        </div>

        <div className="mobo_padding_inner bottom_section flex-revese my-4 gap-2">
          <button onClick={Submit}>Submit</button>
          <h4>Overall Rating: {myTotalRating.toFixed(1)}</h4>
        </div>
      </div>
    </section>
  );
};

export default ProjectEvaluationDetail;
