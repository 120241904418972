import MiniStudentApplication from "components/cards/MiniStudentResume"
import Freelancerimg from "images/Freelancer.png"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getParsed } from "services/factories/getParsed"
// import ProgressBar from "view/dashboard/employer/components/progressBar/ProgressBar"
import StatusProgress from "view/dashboard/student/application/StatusProgress"
import WeekDetail from "view/dashboard/student/application/WeekDetail"
// import WeekDetails from "view/dashboard/student/application/WeekDetails"
import axios from "axios"
import { toast } from "react-toastify"
import "./applications.scss"

const ApplicationsSubmited = ({ enrollments = [], post = {}, handleStatusChange }) => {
  const [selectedStudent, setSelectedStudent] = useState(0)
  const [weekly_submissions, setWeeklySubmissions] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [final_submission_report, setFinalSubmissionReport] = useState([])
  const navigate = useNavigate()
  // console.log("weekly_submissions", weekly_submissions)
  const [show, setShow] = useState(true)
  const [showFilter, setShowFilter] = useState(false)

  const setSelected = (index) => {
    if (index > enrollments?.data?.length - 1) return
    if (index < 0) return
    setSelectedStudent(index)
  }

 
  useEffect(() => {
    const submissions = (
      getParsed(enrollments?.data[selectedStudent]?.weekly_submissions || []) ||
      []
    )?.filter((item) => item?.report)

    const report = getParsed(
      enrollments?.data[selectedStudent]?.final_submission_report
    )

    setWeeklySubmissions(submissions)
    setFinalSubmissionReport(report || {})
  }, [])

  const changeStatus = async (status) => {
    try {
      const response = await axios.patch(
        `/v1/enrollments/project`,
        {},
        {
          params: {
            id: enrollments?.data[selectedStudent].id,
            status,
          },
        }
      )

      toast.success(response?.data?.message)
    } catch (err) {
      console.log(err)
    }
  }

  const handleNotInterested = () => {
    changeStatus(7)
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 7,
      oldStatus: enrollments?.data[selectedStudent].status,
    })
  }

  const handleShortlisted = () => {
    changeStatus(4)
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 4,
      oldStatus: enrollments?.data[selectedStudent].status,
    })
  }

  const handleHired = () => {
    changeStatus(6)
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 6,
      oldStatus: enrollments?.data[selectedStudent].status,
    })
  }

 

  // Handler function to update the search term state
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  
  // console.log(enrollments?.data,"enrollments?.data?")
  // Filtered array based on search term
  const filterData = (searchTerm) => {
    const filteredEnrollments = enrollments?.data?.filter((item) =>
      item?.student?.course_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.student?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.student?.location?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.student?.branch_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.student?.experiences?.some((experience) =>
    experience?.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    experience?.designation?.toLowerCase().includes(searchTerm.toLowerCase())
  )
    );
    setFilteredData(filteredEnrollments);
  }
  useEffect(() => {
    filterData(searchTerm)
  }, [enrollments, searchTerm])

  const handleChange = () => {
    const chatApi = async () => {
      try {
        const chatRoomId = await axios.post("/v1/chat/create-chat-room", {
          userId: enrollments?.data[selectedStudent]?.student?.id,
          role: enrollments?.data[selectedStudent]?.student?.role,
          enrollment_type: 2,
          enrollment_id: enrollments?.data[selectedStudent]?.id,
        });
        // console.log(chatRoomId);
      } catch (error) {
        throw new Error();
      }
    };
    chatApi();
  };



  return enrollments?.data?.length ? (
    <div className="tab-content">
      <div className="tab-pane fade show active">
        <div className="content-tab">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="searchbox">
                <label>
                  {" "}
                  <input type="text" placeholder="Search candidate" onChange={handleSearch} />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </label>
                <i
                  onClick={() => setShowFilter(true)}
                  className="filter_mobo fa fa-sliders d-flex d-sm-none"
                  aria-hidden="true"
                ></i>
              </div>
              {filteredData?.map((item, index) => {
      const studentFunction = () => {
        setSelectedStudent(index);
        setShow(false);
      };
      return (
        <div key={index} onClick={studentFunction}>
          <MiniStudentApplication key={index} item={item} />
        </div>
      );
    })}
            </div>
            {/* <div>
              <div>

              </div>
            </div> */}
            <div className="status_bottom_btn col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-sm-block">
              <StatusProgress
                status={enrollments?.data?.[selectedStudent]?.status}
                reports={weekly_submissions}
              />
              <div className="weekly-report-accordian">
                <h2>Weekly Reports</h2>
                {weekly_submissions?.map((item) => {
                  // console.log(item,"item");
                  return(
                  <WeekDetail
                    key={item?.week}
                    report={item?.report}
                    index={item?.week}
                    title={`Week ${item?.week}`}
                    description={item?.description}
                    text="uploaded"
                    midTitle="submitted"
                  />
                )})}
              </div>
             
              <div className="inquire-description">
                <div className="btn-box">
                  <button
                    className="intrested"
                    type="button"
                    onClick={handleNotInterested}
                  >
                    Not Interested
                  </button>
                  <button
                    className="short"
                    type="button"
                    onClick={handleShortlisted}
                  >
                    Shortlist
                  </button>
                  <button className="hire" type="button" onClick={handleHired}>
                    Hire
                  </button>
                  <div className="float-end mt-2">
                    <p>
                      {selectedStudent ? (
                        <i
                        style={{borderRadius:"20px" , border:"2px solid #6149cd",padding:'6px' ,color:"#6149cd",backgroundColor:"#ffff"}}
                          className="fa-solid fa-arrow-left cursor-pointer"
                          onClick={() => setSelected((p) => p - 1)}
                        ></i>
                      ) : (
                        <></>
                      )}
                      <span  className="fs-5 text-black fw-bold">{selectedStudent + 1}</span>
                      <span className="fs-5">/{enrollments.data.length} </span>
                      {selectedStudent !== enrollments.data.length - 1 ? (
                        <i
                          className="fa-solid fa-arrow-right cursor-pointer"
                          style={{borderRadius:"20px" , border:"2px solid #6149cd",padding:'6px' ,color:"#6149cd",backgroundColor:"#ffff"}}
                          onClick={() => setSelected((p) => p + 1)}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              
             
            </div>

            {!show && (
              <div className="status_bottom_btn_mobo  d-block d-sm-none">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => setShow(true)}
                  aria-hidden="true"
                ></i>
                <StatusProgress
                  status={enrollments?.data?.[selectedStudent]?.status}
                  reports={weekly_submissions}
                />
                <div className="weekly-report-accordian">
                  <h2>Weekly Reports</h2>
                  {weekly_submissions?.map((item) => (
                    <WeekDetail
                      key={item?.week}
                      report={item?.report}
                      index={item?.week}
                      title={`Week ${item?.week}`}
                      description={item?.description}
                      text="uploaded"
                      midTitle="submitted"
                    />
                  ))}
                </div>
                <div className="inquire-description">
                  <div className="btn-box flex flex-column gap-2">
                    <div className="upper_button">
                      <button
                        className="intrested"
                        type="button"
                        onClick={handleNotInterested}
                      >
                        Reject
                      </button>
                      <button
                        className="short m-0"
                        type="button"
                        onClick={handleShortlisted}
                      >
                        Shortlist
                      </button>
                    </div>

                    <div className="bottom_btn">
                      {/* onClick={handleHired} */}
                      <button className="hire" type="button" onClick={handleHired}>
                        Schedule Interview
                      </button>
                    </div>

                    <div className="float-end mt-2">
                    <p>
                      {selectedStudent ? (
                        <i
                          className="fa-solid fa-arrow-left"
                          onClick={() => setSelected((p) => p - 1)}
                        ></i>
                      ) : (
                        <></>
                      )}
                      {selectedStudent + 1}
                      <span>/{enrollments.data.length} </span>
                      {selectedStudent !== enrollments.data.length - 1 ? (
                        <i
                          className="fa-solid fa-arrow-right"
                          onClick={() => setSelected((p) => p + 1)}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                  </div>
                </div>
                {/* <WeekDetail
                report={final_submission_report?.report}
                index={-1}
                title="Project Report"
              />
              <WeekDetail
                report={final_submission_report?.presentation}
                index={-2}
                title="Project Presentation"
              />
              <WeekDetail
                report={final_submission_report?.other}
                index={-3}
                title="Any Other Document"
              />
              <WeekDetail
                report={final_submission_report?.link}
                index={-4}
                title="Link"
              /> */}
              </div>
            )}

            <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-sm-block">
              <div className="row ">
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="job-filter">
                    <label htmlFor="city">City</label>
                    <input
                      type="Search"
                      id="city"
                      list="search-data"
                      placeholder="Search City"
                    />
                    <datalist id="search-data">
                      <option value="Delhi"></option>
                      <option value="Noida"></option>
                    </datalist>
                    <label htmlFor="skill">Skills</label>
                    <input
                      type="Search"
                      id="skill"
                      list="skills-data"
                      placeholder="Search Skills"
                    />
                    <datalist id="skills-data">
                      <option value="html"></option>
                      <option value="css"></option>
                    </datalist>

                    <div className="spanbox">
                      <span>
                        IT <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        IT Students <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        React <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        B.Tech <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <label htmlFor="qualification">Qualification</label>
                    <input
                      type="text"
                      id="qualification"
                      placeholder="Search qualification"
                    />
                    <button className="clear">Clear</button>
                    <button>Apply</button>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            {showFilter && (
              <div className="mobo_filters  d-flex d-sm-none">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => setShowFilter(false)}
                  aria-hidden="true"
                ></i>
                <div className="row ">
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="job-filter">
                      <label htmlFor="city">Country</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">State</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">City</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">Courses</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="skill">Skills</label>
                      <input
                        type="Search"
                        id="skill"
                        list="skills-data"
                        placeholder="Search Skills"
                      />
                      <datalist id="skills-data">
                        <option value="html"></option>
                        <option value="css"></option>
                      </datalist>

                      <div className="spanbox">
                        <span>
                          IT <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          IT Students <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          React <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          B.Tech <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                      <label htmlFor="qualification">Qualification</label>
                      <input
                        type="text"
                        id="qualification"
                        placeholder="Search qualification"
                      />
                      <button className="clear">Clear</button>
                      <button>Apply</button>
                    </div>
                  </div>
                  {/* <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h4>No Applications</h4>
  )
}

export default ApplicationsSubmited
