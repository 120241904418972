import moment from "moment";
import { addApplicationData } from "services/actions/application";

export const duplicateJob = ({ data, dispatch }) => {
  if (!data) return;
  // console.log({ data });

  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };

  const {
    required_skills,
    courses,
    keywords,
    screening_questions,
    job_title,
    roles_and_responsibilities,
    job_category,
    vacancies,
    ctc_from,
    ctc_to,
    city,
    location,
    expected_start_date,
    job_city,
    office_visit,
  } = data;

  const parse = (data) => {
    if (!data) return [];
    try {
      return JSON.parse(data);
    } catch (error) {
      return [];
    }
  };

  // // console.log(data, "hello world");

  const specific = {
    required_skills: required_skills,
    job_title,
    roles_and_responsibilities,
    job_category: map[job_category],
    incentive_from: ctc_from,
    incentive_to: ctc_to,
    joining_date: moment(expected_start_date).format("YYYY-MM-DD"),
    vacancies,
    job_city,
    office_visit,
    ...(city
      ? {
          city: {
            id: city,
            location: `${location?.city_name}, ${location?.state?.state_name}`,
          },
        }
      : {}),
    ...(location
      ? {
          location: `${location?.city_name}, ${location?.state?.state_name}`,
        }
      : {}),
  };

  dispatch(addApplicationData({ method: "job", data: specific }));

  // dispatch(addApplicationData({ method: "job", step: 2, data: specific["2"] }));
  // dispatch(addApplicationData({ method: "job", step: 3, data: specific["3"] }));
  // dispatch(addApplicationData({ method: "job", step: 4, data: specific["4"] }));
};
