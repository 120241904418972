// import React from 'react'
import Freelancerimg from "images/Freelancer.png";
import CollegesCards from "view/dashboard/employer/components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "view/dashboard/employer/components/collegesCards/collegesDetailCards/CollegesDetailCards";
// import "./PartnerInstitutions.scss";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ComingSoon from "components/comingSoon/ComingSoon";
import { useSelector } from "react-redux";
import CompaniesCard from "../../components/CompanyCard/CompaniesCard/CompaniesCard";
import CompanyDashboard from "view/dashboard/employer";
import CompaniesDetailCard from "../../components/CompanyCard/CompaniesDetailCard/CompaniesDetailCard";
import CompanyTop from "../../components/companyTop/CompanyTop";
import '../suggestCompanies/suggestCompanies.scss'

const PartnerInstitutions = ({ institutions }) => {
  // const [institutions, setInstitutions] = useState([]);
  // const user = useSelector((state) => state.auth.user);
  const style1 = {
    display: "none",
  };
  const style2 = {
    background: "#47D065",
  };

  const [educators, setEducators] = useState(0);
  const [educatorDataDetails, setEducatorDataDetails] = useState();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);

  const handleToggle = () => {
    setOpen((toggle) => !toggle);
  };

  const handleFilterToggle = () => {
    setFilter((toggle) => !toggle);
    console.log("filtertogllleee")
  };

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get(
  //       `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`
  //     );
  //     setInstitutions(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/company/single/${
          institutions[educators]?.sender_emp
            ? institutions[educators]?.sender_id
            : institutions[educators]?.receiver_id
        }`
      );
      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // console.log("function 2 working")
  }, [educators, institutions]);

  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);

  return (
    <>
      {institutions?.length > 0 ? (
        <>
          <div className="container-fluid partnerInstitutionWrapper">
            <div className="row m-0 p-0">
              {/* <div className="container-fluid p-0 m-0">
                <CompanyTop active1={true} />
              </div> */}
              {/* <p className="pageTitle">
                List of Partner Institutions{" "}
                <span>({institutions?.length || 0})</span>
              </p> */}
            </div>
            <div className="row m-0 p-0">
              <div className="col-md-4 col-12">
                <div className="searchField hidesearchbox">
                  <label>
                    {" "}
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      placeholder="Search college/university"
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </label>
                </div>
                {/* mobile view search box and filter */}

                   <div className="searchbox-container d-md-none gap-2  pl-1 pr-1">

       <div className="searchField w-100">
                  <label>
                    {" "}
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      placeholder="Search college/university"
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </label>
                </div>

                    <div className="">
                         <i
                                   className="fa-solid fa-sliders fa-lg filter_mobo"
                                     onClick={() => handleFilterToggle()}
                               style={{ color: "#6149CD" }}
                            ></i>
                        </div>
                      </div>     
 
                {/* ---------------- */}



                <div className="collegeCardListContainer">
                  {/* {institutions?.map((institution) => (
                  <CollegesCards
                    institution={institution?.sender || institution?.receiver}
                  />
                ))} */}
                  {institutions
                    ?.filter((item) => {
                      return (
                        item?.sender_emp?.first_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item?.receiver_emp?.first_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item?.sender_emp?.last_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item?.receiver_emp?.last_name
                          .toLowerCase()
                          .includes(search) ||
                        item?.sender_emp?.company?.company_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item?.receiver_emp?.company?.company_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      );
                    })
                    ?.map((institution) => {
                      const collegesFunction = () => {
                        setEducators(institutions.indexOf(institution));
                      };
                      return (
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={collegesFunction}
                        >
                          <CompaniesCard
                            college={
                              institution.sender_emp || institution.receiver_emp
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-6 companies_mid_container">
                {/* <CollegesDetailCards
                style1={style1}
                style2={style2}
                text1="Send Opportunities"
                text2="Message"
                educatorDataDetails={educatorDataDetails}
                pageLength={institutions?.length}
                setPage={setEducators}
                currentPage={educators}
              /> */}

                <CompaniesDetailCard
                  style1={style1}
                  style2={style2}
                  text1="Send Opportunities"
                  text2="Message"
                  emploayerDataDetails={educatorDataDetails}
                  pageLength={institutions?.length}
                  setPage={setEducators}
                  currentPage={educators}
                />
              </div>
              <div className="col-2 hidesearchbox">
                {" "}
                <div className="row ">
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="job-filter">
                      <label htmlFor="city">City</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="skill">Skills</label>
                      <input
                        type="Search"
                        id="skill"
                        list="skills-data"
                        placeholder="Search Skills"
                      />
                      <datalist id="skills-data">
                        <option value="html"></option>
                        <option value="css"></option>
                      </datalist>

                      <div className="spanbox">
                        <span>
                          IT <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          IT Students <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          React <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          B.Tech <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                      <label htmlFor="qualification">Qualification</label>
                      <input
                        type="text"
                        id="qualification"
                        placeholder="Search qualification"
                      />
                      <button className="clear">Clear</button>
                      <button>Apply</button>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div
                      className="new-post"
                      onClick={() => Navigate("/employer/internship/add")}
                    >
                      <div className="flex">
                        <div>
                          <h3>Post New </h3>
                          <h4>Internship</h4>
                        </div>
                        <div>
                          {" "}
                          <i className="fa-solid fa-right-long"></i>
                        </div>
                      </div>
                      <img src={Freelancerimg} alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
              {filter && (
              <div className="company_right_container_mobo d-block d-sm-none">
                <i
                  class="cross_icon fa fa-times text-end m-3"
                  onClick={() => handleFilterToggle()}
                  aria-hidden="true"
                ></i>
                <div>
                <div className="row ">
                  <div className="filter_tag d-flex justify-content-start align-items-center gap-2 m-2 ">
                    <h4 className="m-0" style={{ color: "#6149CD" }}>
                      Filters
                    </h4>
                    <span className=" d-block ">
                      <i
                        class="fa-solid fa-sliders fa-lg"
                        style={{ color: "#6149CD" }}
                      ></i>
                    </span>
                  </div>
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="job-filter">
                      <label htmlFor="city">City</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="skill">Skills</label>
                      <input
                        type="Search"
                        id="skill"
                        list="skills-data"
                        placeholder="Search Skills"
                      />
                      <datalist id="skills-data">
                        <option value="html"></option>
                        <option value="css"></option>
                      </datalist>

                      <div className="spanbox">
                        <span>
                          IT <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          IT Students <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          React <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          B.Tech <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                      <label htmlFor="qualification">Qualification</label>
                      <input
                        type="text"
                        id="qualification"
                        placeholder="Search qualification"
                      />
                      <button className="clear">Clear</button>
                      <button>Apply</button>
                    </div>
                  </div>
               
                </div>
                  </div>
                
              </div>
            )}
            </div>
          </div>
        </>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>No Invitation Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerInstitutions;
