import React from "react"
import NewChip from "./NewChip"

const NewPreviewField = ({ title, desc, courses ,skills }) => {
  return (
    // <div className="task d-flex flex-column gap-2">
    <div className="task d-flex flex-column gap-1">
      <h3>{title}</h3>

      <div className="task-desc">{desc}</div>

      <div className="d-flex flex-wrap gap-2">
        {courses &&
          courses.map((item, index) => (
            <div key={index} className="new-chip">
              {item?.course_name}
            </div>
          ))}
      </div>
      {
        skills && 
        <div className="d-flex flex-wrap gap-2">
        {skills &&
          skills.map((item, index) => (
            <div key={index} className="new-chip">
              {item?.name}
            </div>
          ))}
      </div>
      }

    </div>
  )
}

export default NewPreviewField
