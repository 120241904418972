import { useState } from "react"
import AddProject from "./AddProject"
import axios from "axios"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { updateUser } from "services/actions/auth"
import AddMore from "components/addmoreButton/AddMore"

const Project = ({ projects, setProjects }) => {
  const [showAddProject, setShowAddProject] = useState(() => !projects.length)

  const dispatch = useDispatch()

  const deleteProject = async (index) => {
    try {
      const id = projects[index].id
      let deleted = false
      if (id) {
        const { data } = await axios.delete(`/v1/student/project/${id}`)
        if (!data?.error) deleted = true
      } else deleted = true
      if (deleted) {
        const newProjects = projects.filter((_, i) => i !== index)
        setProjects(newProjects)
        dispatch(updateUser({ project: newProjects }))
      } else {
        toast.error("Error removing project!")
      }
    } catch (error) {
      toast.error("Error removing project!")
    }
  }

  return (
    <>
      <div className="add_project row">
        {projects?.map((project, index) => (
          <div className="col-md-6 col-sm-12 mb-2 mt-2" key={index}>
            {" "}
            {/* changes in mb-2 mt-2*/}
            <div className="doc_card card">
              <div className="card-body docCard_body">
                <div className="w-100">
                  <h5 className="card-title">{project.name}</h5>
                  <a href={project.link} className="w-100 p-0 mb-2 text-primary">
                    {project.link}
                  </a>
                  <p className="my-2 card-text ">
                    {project.start_date} - {project.end_date}
                  </p>
                  <p className="mb-2 card-text">{project.description}</p>
                </div>

                <div className="docButton">
                  <button
                    className="btn text-danger doc_btn"
                    onClick={() => deleteProject(index)}
                  >
                    Delete
                  </button>
                  <button className="btn text-primary">Edit</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showAddProject ? (
        <AddProject
          setProjects={setProjects}
          setShowAddProject={setShowAddProject}
        />
      ) : (
        <AddMore onClick={() => setShowAddProject(true)} />
      )}
    </>
  )
}

export default Project
