// import React from 'react'
import axios from "axios";
import Freelancerimg from "images/Freelancer.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import CollegesCards from "../components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "../components/collegesCards/collegesDetailCards/CollegesDetailCards";
import "./PartnerInstitutions.scss";

const PartnerInstitutions = () => {
  const [institutions, setInstitutions] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const style1 = {
    display: "none",
  };
  const style2 = {
    background: "#47D065",
  };

  // console.log("userr", user);
  const [educators, setEducators] = useState(0);
  const [educatorDataDetails, setEducatorDataDetails] = useState();

  useEffect(() => {
    const fetchInstitutions = async () => {
      const res = await axios.get(
        `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user?.id}&sender_id=${user?.id}`
      );
      setInstitutions(res?.data?.data);
    };
    fetchInstitutions();
  }, []);

  // console.log("senddd", institutions[educators]?.sender ? institutions[educators]?.sender_id : institutions[educators]?.receiver_id)

  useEffect(() => {
    const fetctEductatorData = async () => {
      const id = institutions[educators]?.sender ? institutions[educators]?.sender_id : institutions[educators]?.receiver_id
      const res = await axios.get(
        `/v1/institution/single/${id}`
      );
      // console.log("ress", res)
      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // // console.log("function 2 working")
  }, [educators, institutions]);

  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);

  // console.log(institutions, "institutions");

  return (
    <>
      {/* <ComingSoon /> */}
      {institutions?.length > 0 ? (
        <div className="container-fluid partnerInstitutionWrapper">
          <div className="row m-0 p-0">
            <p className="pageTitle">
              List of Partner Institutions{" "}
              <span>({institutions?.length || 0})</span>
            </p>
          </div>
          <div className="row m-0 p-0">
            <div className="col-md-4 col-12">
              <div className="searchField">
                <label>
                  {" "}
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Search college/university"
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </label>
              </div>
              <div className="collegeCardListContainer">
                {institutions
                  ?.filter((item) => {
                    return (
                      item?.sender?.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item?.receiver?.first_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item?.sender?.last_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item?.receiver?.last_name
                        .toLowerCase()
                        .includes(search) ||
                      item?.sender?.institute?.institution_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item?.receiver?.institute?.institution_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    );
                  })
                  ?.map((institution) => {
                    const collegesFunction = () => {
                      setEducators(institutions.indexOf(institution));
                    };
                    return (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={collegesFunction}
                      >
                        <CollegesCards
                          institution={
                            institution.sender || institution.receiver
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-md-6">
              <CollegesDetailCards
                style1={style1}
                style2={style2}
                text1="Send Opportunities"
                text5="Message"
                educatorDataDetails={educatorDataDetails}
                pageLength={institutions?.length}
                setPage={setEducators}
                currentPage={educators}
              />
            </div>
            <div className="col-2">
              {" "}
              <div className="row ">
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="job-filter">
                    <label htmlFor="city">City</label>
                    <input
                      type="Search"
                      id="city"
                      list="search-data"
                      placeholder="Search City"
                    />
                    <datalist id="search-data">
                      <option value="Delhi"></option>
                      <option value="Noida"></option>
                    </datalist>
                    <label htmlFor="skill">Skills</label>
                    <input
                      type="Search"
                      id="skill"
                      list="skills-data"
                      placeholder="Search Skills"
                    />
                    <datalist id="skills-data">
                      <option value="html"></option>
                      <option value="css"></option>
                    </datalist>

                    <div className="spanbox">
                      <span>
                        IT <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        IT Students <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        React <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span>
                        B.Tech <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <label htmlFor="qualification">Qualification</label>
                    <input
                      type="text"
                      id="qualification"
                      placeholder="Search qualification"
                    />
                    <button className="clear">Clear</button>
                    <button>Apply</button>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => Navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>No Invitation Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerInstitutions;
