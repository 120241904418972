import axios from "axios"
import Loader from "components/employer/Loader"
import Dropdown from "components/formComponents/post/Dropdown/Dropdown"
import mentorimg from "images/avatar.jpg"
import React, { useEffect, useReducer, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { initialState, reducer } from "view/dashboard/student/explore/handleFilters"
import "./style.scss"
import MentorsFilter from "./MentorFilter"

const MentorManagerMentorsPage = () => {
 
  const {id}=useParams()
  const [search, setSearch] = useState("")
   // console.log(id,"id");
  // const { data, loading, error } = useFetch('/v1/mentor')

  // filterbox
  const [show, setShow] = useState(false);
const toggleOn = () => setShow((p) => !p);

  const toggleOFf = () => setShow(() => false);

  // edn filterbox


  const { state: location } = useLocation();
  
  const selectedType = location?.selectedType;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selected: location?.activeTab ?? 0,

  });
  
  // console.log("state",state)
  // console.log("state",selectedType)

  const [data, setData] = useState(() => []);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 10;
  const [loading, setLoading] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);

  // console.log("updateitem",updateItem)
  // console.log("indus",state.industry_sector,state.charge1,state.charge2)
  useEffect(() => {
    // setLoading(true);
 
    handleSearch();
  }, [state.industry_sector,state.charge1,state.charge2,updateItem]);
  
  const handleSearch = async () => {
    try {
      setLoading(true)
      const filters = Object?.keys(state).reduce((acc, curr) => {
        if (curr === "selected") return acc;
        else if (state[curr] instanceof Set) {
          if (!state[curr].size) return acc;
          const set = [...new Set(state[curr])];
          return { ...acc, [curr]: set };
        } else if (state[curr]) {
          return { ...acc, [curr]: state[curr] }; 
        }
        return acc;
      }, {});
      if (state.charge1 && state.charge2) {
        filters.charge1 = state.charge1;
        filters.charge2 = state.charge2;
      }

      // console.log("filter.....",filters)

     
      
      // const { data } = await axios.get(`/v1/mentor`, {params:{
      const  data  = await axios.post("/v1/mentor/filtermentor", {
        ...filters,
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        updateItem,

      });




      // console.log("pages", data);
      if (!data?.error) {
        setData(data?.data || []);
        setTotalPages(data?.data?.pages?.total || 1);
        setTotalItems(data?.data?.pages?.items);
        setLoading(false);
        // setCurrentPage(data?.data?.pages?.current)
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const navigate = useNavigate()
  
  const AssignMentor = async (mentorId) => {
    try { 
      const res = await axios.post('/v1/evaluation/updateEvaluation',{evaluation_id:id,mentor_id:mentorId})
      // // console.log(res?.data?.data,"assignmentor");
      // setData(res?.data?.data)
      if(res){
        toast.success('Mentor has been assigned')
        navigate("/mentor-manager/dashboard")
      }
      
    } catch (err) {
      throw(err)
    }
  }
  // const arr = [
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  // ]
  const items = ["Rating - High to Low", "Rating - Low to High"]

  // console.log(data?.data,"data");
 
  if (loading) return <Loader/>;
  return (
    <div className="mentors-page py-4 px-3">
      <div className="row ">
        <div className="col-md-9 col-sm-12 col-12">
          <div className="row p-1">

          <div className="searchbar-container mb-3 col-md-9 col-sm-7 col-10 ">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              className="form-control"
              placeholder="Search by keyword"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
         </div>

         <div className="toggle_box col-md-3 col-sm-3 col-2 mb-3 mobile_search d-flex justify-content-center align-items-center gap-2 ">
            <span className="filter d-block d-md-none ">
              <i
                class="fa-solid fa-sliders fa-lg "
                style={{ color: "#6149CD" }}
                onClick={toggleOn}
              ></i>
            </span>
          </div>

          </div>
         
         
          

          <div className="row  fixcontainer">
            {data?.data?.length>0?(

            data?.data?.map((item, index) => (
              <div
                className=" mentor_card col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                key={index}
              >
                <div className="mentor-info1">
                  <div className=" mentor_info_text row">
                    <div className="col-3">
                      <img src={item?.profile_photo==null ? mentorimg: item?.profile_photo} alt="Image" width={85} height="88" />
                    </div>
                    <div className="col-9">
                      <div className="mentor-text1 p-1">
                        <h3 className="">{item?.first_name} { item?.last_name}</h3>
                        <p className="txt2 ">{item?.industry_sector} {item?.industry_year_expreience}+ Years</p>
                        <p className="txt1">{item?.designation} {item?.current_company}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mentor-btn1">
                    <div className="row">
                      <div className="col-5">
                        <div className="mentor-price">
                          <p>₹ {item?.mentor_charges} / Session</p>
                        </div>
                      </div>
                      <div className="button_div col-7">
                        <button onClick={()=>AssignMentor(item?.id)}>Assign</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))

            )
            :(
              <div className="empty_card">
              <div className="emptyCard_content">
                <h4>No Result found </h4>
              </div>
              </div>

            )}
          </div>
         

        </div>

        <div className="col-3 filterbox">
          <div className="">
          <div className="explore_filterBox mb-3">
            <Dropdown
              items={items}
              setItem={setUpdateItem}
              label="Sort by"
              checked={updateItem}
            />
          </div>
          <MentorsFilter dispatch={dispatch} state={state} />

          </div>

         


        </div>



        {/* filter */}
     <section className={`p-0 container mobile_topSearch  ${show ? "open" : ""}`}>

      {show && (
                  <div className="mobo_filter d-block d-md-none">
                    <div
                      className={`mobile_FilterBtn my-2 d-flex justify-content-between align-items-center gap-2 w-100 d-md-none ${
                        show ? "open" : ""
                      } `}
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2 ">
                        <h4 className="m-0" style={{ color: "#6149CD" }}>
                          Filters
                        </h4>
                        <span className=" d-block mt-">
                          <i
                            class="fa-solid fa-sliders fa-lg "
                            style={{ color: "#6149CD" }}
                          ></i>
                        </span>
                      </div>
                      <div className="toggle_box">
                        <span className="d-block filter">
                          <i
                            class="fa-solid fa-x fa-lg "
                            style={{ color: "#6149CD" }}
                            onClick={toggleOFf}
                          ></i>
                        </span>
                      </div>
                    </div>

                    <div className="explore_filterBox">
                  <Dropdown
                        items={items}
                      setItem={setUpdateItem}
                      label="Sort by"
                      checked={updateItem}
                    />
                  </div>
                  
              <MentorsFilter dispatch={dispatch} state={state} />
                  </div>
                )}
      </section>

        {/* filer */}

      </div>
    </div>
  )
}

export default MentorManagerMentorsPage
