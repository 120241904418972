import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { subscribeSubscription } from "services/factories/razorpay";
import PaymentHistory from "./PaymentHistory";
import start from "./Star.svg";
import styles from "./subscriptions.module.scss";

const Subscription = ({ Beginner, Intermediate, Expert }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state)=> state?.auth?.user);

  // console.log("userr", user);

  async function initiatePayment(e, id, amount) {
    e.preventDefault();

    const session = await subscribeSubscription({
      subscription_id: id,
      amount_rs: parseInt(amount),
      navigate: navigate,
      user: user
    });
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`sub_dev ${styles.subscriptionPage} container mt-5 mb-3`}>
      {/*--------------------- Row  1  -------------------*/}

      <div className={`${styles.mainRow} ${styles.row} row`}>
        {/* heading */}
        <div className={` ${styles.subscription} col-md-5 col-12`}>
          <h2 className={styles.title}>Upgrade Your Plan</h2>
        </div>

        {/* Beginner */}
        <div className={`${styles.subscription_type} col-5 col-md-3 `}>
          <div>
            <h3>{Beginner?.subscription_name}</h3>
            <h4>Free</h4>
          </div>
          <button
            className={styles.subscriptions_btn}
            onClick={(e) => {
              initiatePayment(e, Beginner.id, Beginner.price);
            }}
            style={{ backgroundColor: user?.subscriptionStatus === null && '#07bc0c', color: user?.subscriptionStatus === null && '#fff' }}
            disabled={user?.subscriptionStatus?.subscription_id === 2 ||
              user?.subscriptionStatus?.subscription_id === 3 ||
              user?.subscriptionStatus === null}
          >
            {user?.subscriptionStatus === null ? "Active" : "Upgrade"}
          </button>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.subscription_type} col-4 col-md-3 `}>
          <div>
            <div className="d-flex justify-content-center gap-1">
              <h3>{Intermediate?.subscription_name}</h3>
              <div className={styles.star_off_container}>
                <img className={styles.star_off_img1} src={start} alt="Image" />
                <span className={styles.off_span}>
                  25% <br /> OFF
                </span>
              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(5000)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Intermediate?.price)}
                <span className="text-muted">/month</span>
              </h4>
            </div>
          </div>
          <button
            className={styles.subscriptions_btn}
            onClick={(e) => {
              initiatePayment(e, Intermediate.id, Intermediate.price);
            }}
            disabled={ user?.subscriptionStatus?.subscription_id === 2}
            style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 2 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 2 && '#fff' }}
          >
            {user?.subscriptionStatus?.subscription_id === 2 ? "Active" : "Upgrade"}
          </button>
        </div> */}
        {/* Expert */}
        <div className={`${styles.subscription_type} col-5 col-md-3 `}>
          <div>
            <div className="d-flex justify-content-between gap-4">
              <h3>{Expert?.subscription_name}</h3>
              {/* <img src={start} alt="Image" /> */}
              <div className={styles.star_off_container}>
                <img className={styles.star_off_img1} src={start} alt="Image" />
                <span className={styles.off_span}>
                  36% <br /> OFF
                </span>
              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(50781)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Expert?.price)}
                <span className="text-muted">/Year</span> <span>+ (GST@18%)</span>
              </h4>
            </div>
          </div>
          <button
            className={styles.subscriptions_btn}
            onClick={(e) => {
              initiatePayment(e, Expert.id, Expert.price);
            }}
            disabled={ user?.subscriptionStatus?.subscription_id === 3}
            style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 3 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 3 && '#fff' }}
          >
            {user?.subscriptionStatus?.subscription_id === 3 ? "Active" : "Upgrade"}
          </button>
        </div>
      </div>

      {/*--------------------- Row  2  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Post your projects for the students and access their fresh ideas
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <p className={styles.service}>10 Free</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Unlimited</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  3  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Post your internships and/or jobs for the students to apply and hire
            them
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <p className={styles.service}>05 Free</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Unlimited</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  4  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Directly connect with selected universities & colleges and offer
            your projects, internships, and jobs to their students
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <p className={styles.service}>05 Free</p>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Unlimited</p>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <p className={styles.service}>Unlimited</p>
        </div>
      </div>

      {/*--------------------- Row  5  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Evaluation and rating of student's project work out on your behalf
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      {/*--------------------- Row 7  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Track student progress during project work
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>
      {/*--------------------- Row 8  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Directly chat with students, colleges, and schedule interviews
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      {/*--------------------- Row 9  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Promoting your company among universities/colleges and students
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-3 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      {/*--------------------- Row 10  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-5 col-12`}>
          <h5 className={`${styles.subTitle}  `}>Dedicated account manager</h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
        {/* Expert */}
        <div className={`${styles.whatYou_get} col-md-5 col-5`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      {/*--------------------- Row 11  (What You Will get ) -------------------*/}

      {/* {
        open && <PaymentPopUp onClose={handleClose} message="Payment"
        // Beginner={Beginner?.price} Intermediate={Intermediate?.price} Expert={Expert?.price}
        />
      } */}


      <div>
        <PaymentHistory />
      </div>

    </div>
  );
};

export default Subscription;
