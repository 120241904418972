import PreviewField from "components/employer/PreviewField";
import { defaultValues as categories } from "components/formComponents/CategoryField";
import { Link } from "react-router-dom";
import './projectDetail.scss';
import PostDetailsSection from "./PostsHelper/PostDetailsSection";

const InternshipDetailsPage = ({
  id,
  data,
  children,
  MobileActionButtons,
  Action,
}) => {
  const {
    website_url,
    year_of_incorporation,
    employee_strength,
    industry,
    company_logo,
    ceo_name,
    first_name,
    last_name,
    designation,
  } = data.global.employer_account;
  // const company = useSelector((state) => state?.application?.company[1])
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };

  const category_name = categories?.find(
    (item) => item?.id === map[data.internship_category]
  )?.label;

  const employees = {
    0: "1-50",
    1: "51-250",
    2: "251+",
  };

  // console.log(data, "data");

  let path = window.location.pathname.split("/")[1];

  return (
    <div className="Project_preview_container">
      <div className="preview_box">
        <section className="post_container">

          <PostDetailsSection data={data} type={"internship"}/>



        </section>
        <section className="action_container">
          {children}

          {/* { console.log(Action, "action", typeof Action)} */}
          <div className="actions">{Action}</div>
          <div className="actions ">{MobileActionButtons}</div>
          {/* <div className="company_container">
            <button className="btn btn-success text-white w-100 py-2">Apply Now</button>
            <div className="company_header">
              <ListItem
                name={data.branch.company_database.company_name}
                details={data.branch.address}
                image={company_logo}
                isPreview={true}
                icon={<i className="fa-solid fa-location-dot"></i>}
              />
              <div className="company_body">
                <div className="body_item">
                  <h6>Website</h6>
                  <span className="url">{website_url}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Founding Year</h6>
                  <span>{year_of_incorporation}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Name of CEO</h6>
                  <span>{ceo_name}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>No. of Employees</h6>
                  <span>{employees[employee_strength]}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Industry Sector</h6>
                  <span>{industry.industry_name}</span>
                </div>
              </div>
            </div>
            {company?.services?.length ? (
              <div className="service-list">
                <h5 className="list_header">Products & Services</h5>
                <ListContainer Items={company.services} isPreview={true} />
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default InternshipDetailsPage;
