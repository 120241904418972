import React, { useEffect, useReducer, useState } from "react";
import "./mentorProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import NewInputImage from "components/image-uploader/New_Image_uploader/NewInputImage";
import TextInput from "components/formComponents/TextInput";
import PhoneInput from "react-phone-input-2";
import EmailField from "components/formComponents/EmailField";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import TextArea from "components/formComponents/TextArea";
import Dropdown from "components/formComponents/post/Dropdown/Dropdown";
import axios from "axios";
import AddMentorSkills from "./add_skills/AddMentorSkills";

const MentorDetailsByManager = ({ register, setValue, setImage, getValues,Image, setpreviousData,skills,setSkills,SetGetPreviousSkill}) => {
  const [isPopupOpen, updatePopup] = useState(() => false);
  const [isSecondPopupOpen, updateSecondPopup] = useState(() => false);
  const [gender, setGender] = useState(setpreviousData.gender||"");
  const [getSkills, setGetSkills] = useState(() => {
    const initialSkills = setpreviousData?.required_skills;
    return Array.isArray(initialSkills) ? initialSkills.filter(skill => Number.isInteger(skill)) : [];
  });
 

  const updateGender = (value) => {
    setGender(parseInt(value));
    setValue("gender", value);
  };
  // console.log(gender, "gendee");
  const dispachdata = useDispatch();

  const [languages, setLanguages] = useState(() =>setpreviousData.languages|| []);
    const langs = [  
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Nepali",
    "Odia",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu"
  ]

  const updateLanguages = (value) => {
    setLanguages((prev) => {
      if (prev.includes(langs[value])) {
        return prev.filter((item) => item !== langs[value]);
      }
      return [...prev, langs[value]];
    });
  };

  // const saveLanguage = (languages) => {
  //   dispatch(updateUser(languages))
  // }
  setValue("languages", languages);

  const initialState = {
    title: "",
    link: "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);
  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };
  const handleChange = (e) => {
    dispachdata({
      value: e.target.value,
    });
  };

  // console.log(getValues("industry_sector"));

  const fetchSkillsData = async () => {
    try {
      const response = await axios.post("/v1/mentor/getSkills", {
        required_skills: getSkills
      });
      console.log("response",response)
      setGetSkills(response?.data?.data);
      SetGetPreviousSkill(response?.data?.data);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSkillsData();
  }, [])

  // console.log("skills----",getSkills)

  return (
    <div className="row MentorDetails_container">
      <div className="col-12 mb-2">
        <div className="col-sm-2 col-6">
          {/*<InputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_photo}
          />*/}
          <NewInputImage url={setpreviousData.profile_photo}  setImageFile={setImage} />
          <div className="flex-two-column mt-2">
            <p>(Image max size 1 MB)</p>
           {/* <RequiredShowStar /> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="First Name"
          id="first_name"
          labelClassName="fieldLabel"
          register={register}
          className="profileInputField"
          placeholder={setpreviousData?.first_name||""}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Last Name"
          id="last_name"
          register={register}
          placeholder={setpreviousData?.last_name||""}
          className="profileInputField"
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-group mb-3 w-100 text_label">
          {" "}
          Contact Number
          <PhoneInput
            country={"in"}
            placeholder="Enter phone number"
            onChange={(e) => setValue("phone_number", e)}
            defaultCountry="IN"
            onlyCountries={["in"]}
            name="phone_number"
            specialLabel={""}
            containerClass={" mt-2"}
            inputStyle={{ marginTop: "10px" }}
            inputClass={"form-control profileInputField"}
            value={setpreviousData.phone_number || ""}
          />
        </label>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <EmailField
          label="Email"
          id="email_address"
          register={register}
          className="email-disabled"
          disabled={true}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
      
        <Dropdown
          items={["Male", "Female", "Others"]}
          checked={gender}
          label="Gender"
          className="profileDropdown"
          setItem={updateGender}
        />
      </div>
     
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={langs}
          checked={languages}
          label="Language"
          className="profileDropdown"
          setItem={updateLanguages}
          isMultiple={true}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Industry Experience"
          id="industry_year_expreience"
          register={register}
          className="profileInputField"
          placeholder={setpreviousData?.industry_year_expreience ||""}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Industry Sector "
          id="industry_sector"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/filter/industry_sector"
          key_name="industry_name"
          setValue={setValue}
          withId="industry"
          className="profileInputField"
          // value={user?.industry?.industry_name}
          placeholder={setpreviousData?.industry_sector=="-"?"":setpreviousData?.industry_sector}
        />
      
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isSecondPopupOpen}
          updatePopup={updateSecondPopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          className="profileInputField"
          // value={user?.location}
          placeholder={setpreviousData?.location||""}
        />
      </div>

    
      <div className="col-lg-6 col-md-6 col-sm-12">
        <AddMentorSkills
        setSkills={setSkills} 
        skills={skills}
        setPreviousSkills={getSkills}
        />
    
        </div>

   

      <TextArea
        label="Bio (max 1000 characters)"
        id="bio"
        register={register}
        labelClass="profileSectionLabel"
        className="profileInputField"
      />

      <div className="row personal_details_container w-100 mt-4">
        <label className="profileSectionLabel">Profile Links</label>

        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Linkedin"
            id="linkedIn_link"
            register={register}
            className="profileInputField"
            placeholder={setpreviousData?.linkedin||""}
          />
          <TextInput
            label="Portfolio Link"
            id="profile_link"
            register={register}
            className="profileInputField"
            placeholder={setpreviousData?.profile_link||""}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Github"
            id="github_link"
            register={register}
            className="profileInputField"
            placeholder={setpreviousData?.github||""}
          />
        </div>
      </div>
    </div>
  );
};

export default MentorDetailsByManager;
