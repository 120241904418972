import React from 'react';
import './inputOTP.scss';
import { useCountdown } from "hooks/useCountdown.js";

const ResendOTPButton = ({ onResendClick = () => { } }) => {
  const [time, toggle] = useCountdown(20);

  const onClick = () => {
    if (time !== 0) return;
    onResendClick();
    toggle();
  };

  return (
    <div className='resend-otp-btn d-flex align-items-center justify-content-between'>
      <button className='btn' onClick={onClick} disabled={time !== 0}>Resend OTP</button>
      <div className='time'>{time}s</div>
    </div>
  );
};

export default ResendOTPButton;