import { addApplicationData } from "services/actions/application";

export const duplicateProject = ({ data, reduxDispatch }) => {
  if (!data) return;
  // console.log({ data });

  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };

  const {
    project_category,
    project_title,
    project_goal,
    project_tasks,
    project_support,
    additional_info,
    faq,
    office_visit,
    city,
    duration_from,
    duration_to,
    keywords,
    location,
    skills,
    project_city,
  } = data;

  const parse = (data) => {
    if (!data) return [];
    try {
      return JSON.parse(data);
    } catch (error) {
      return [];
    }
  };

  const specific = {
    duration_from,
    duration_to,
    required_skills: skills,
    project_title,
    project_category: map[project_category],
    project_goal,
    project_tasks,
    project_support,
    additional_info,
    faq: parse(faq),
    office_visit,
    project_city,
    ...(city
      ? {
          city: {
            id: city,
            location: `${location?.city_name}, ${location?.state?.state_name}`,
          },
        }
      : {}),
    ...(location
      ? {
          location: `${location?.city_name}, ${location?.state?.state_name}`,
        }
      : {}),
  };

  reduxDispatch(addApplicationData({ method: "project", data: specific }));

  // dispatch(
  //   addApplicationData({ method: "project", step: 2, data: specific["2"] })
  // );
  // dispatch(
  //   addApplicationData({ method: "project", step: 3, data: specific["3"] })
  // );
  // dispatch(
  //   addApplicationData({ method: "project", step: 4, data: specific["4"] })
  // );
};
