import React, { useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import FilterBox from 'view/newWeb/FilterBox';
import "./newSideBar.scss";


const NewMobSidebar = ({
    selectedItems,
    setSelectedItems,
    company,
    locations,
    industries,
    courses,
    skills,
    handleCheckboxChange,
    dispatch,
    state,
    decouncedUpdateLocation,
    toggleFilterSidebar

}) => {

    
    //   // console.log("coursss", courses);
    //   // console.log("company", company);
        // company  filter

        const [searchKeyword, setSearchKeyword] = useState('');
        const handleCompanyFilter = async (item)=> {
          // console.log("item", item)
          handleCheckboxChange(item, "companies");
    
        }
    
        // console.log("industry", industries);
        const handleIndustryFilter = async (item)=> {
          // console.log("item", item)
           handleCheckboxChange(item, "industries");
          
    
          // dispatch({
          //   type: "change_value",
          //   payload: {industry_sector: selectedItems },
          // });
        }
        const handleQualificationFilter = async (item)=> {
          // console.log("item", item)
          handleCheckboxChange(item, "qualifications");
    
        }
        const handleSkillFilter = async (item)=> {
          // console.log("item", item)
          handleCheckboxChange(item.toLowerCase(), "skills");
    
        }
        const handleSalaryFilter = async (item)=> {
          // console.log("item", item)
          handleCheckboxChange(item, "salaries");
    
        }
    
    
        const handleLocationFilter = async (item)=> {
          // console.log("item", item)
          handleCheckboxChange(item, "locations");
    
        }
    
        const clearFilterSelection = (filterType) => {
          setSelectedItems(prevState => ({
            ...prevState,
            [filterType]: ["All" || "project"]
          }));
    
          if(filterType === 'opportunity'){
    
            dispatch({
              type: "change_value",
              payload: { selected: 0 },
            });
    
          }
          if(filterType === 'companies'){
            dispatch({
              type: "change_value",
              payload: { company_ids: new Set() },
            });
          }
          if(filterType==="locations"){
      
            dispatch({
              type: "change_value",
              payload: {address: new Set() },
            });
          }
          if(filterType==="industries"){
      
            dispatch({
              type: "change_value",
              payload: {industry_sector: new Set() },
            });
          }
       
          if(filterType==="qualifications"){
      
            dispatch({
              type: "change_value",
              payload: {course_preferred: new Set() },
            });
          }
          if(filterType==="skills"){
      
            dispatch({
              type: "change_value",
              payload: {skills: new Set() },
            });
          }
    
          
        };
    
        const clearAllSelections = () => {
          setSelectedItems({
            companies: ["All"],
            industries: ["All"],
            qualifications: ["All"],
            locations: ["All"],
            skills:["All"]
          });
    
          dispatch({
            type: "change_value",
            payload: { selected: 0,company_ids: new Set(), address: new Set(), industry_sector: new Set(), course_preferred: new Set(),skills:new Set() },
          });

          // setSearchKeyword('');
        };

        const debounce = (func, delay) => {
          let timeoutId;
          return function(...args) {
            const context = this;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(context, args), delay);
          };
        };

        const dispatch2 = useDispatch();
      
        const handleChange = (e) => {
          // getSearch(e?.target?.value || "");
          const keyword = e.target.value;
      
          // console.log("searcheeeeekeyyyyyy", keyword)
          
          dispatch2({
            type: "SEARCH_KEYWORD",
            payload: {keyword: keyword}
          })

          setSearchKeyword(keyword);
        };

        const decouncedHandleChange = debounce(handleChange, 500);

        const handleSearchChange = (e) => {
          setSearchKeyword(e.target.value);

          decouncedHandleChange(e);
        };

        const clearSearch = () => {
          dispatch2({
            type: "SEARCH_KEYWORD",
            payload: {keyword: ""}
          }) 
          setSearchKeyword('');
        };
      


  return (
    <div className={`mob_left_section`}>
    <div className="left-main">
        <div className="close-button" onClick={toggleFilterSidebar}>
            <span><IoCloseSharp size={25} /></span>
        </div>

      {/* filters head */}
      <div className="filter-top">
          <h4>Filters</h4>

          <p onClick={clearAllSelections} style={{cursor: "pointer"}}>Clear All</p>
      </div>

      <div className="filter-main">
        <div className="searchbox">
          <input type="text" placeholder='Search project/internhsip/job' value={searchKeyword
          }  onChange={handleSearchChange} />
          {searchKeyword && (
              <span className="clear-search" onClick={clearSearch}>
                <IoCloseSharp size={22} />
              </span>
            )}
        </div>

          <div className='mob_filter_box'>
              <FilterBox
              headerText="Company"
              // handleChange={handleCompanyFilter}
              selectedItems={selectedItems.companies}
              handleCheckboxChange={handleCompanyFilter}
              clearSelection={() => clearFilterSelection("companies")}
              listItems={["All", ...company?.map(item=>  item?.company_name)]}
              />

          </div>
          <div className='mob_filter_box'>
              <FilterBox
              headerText="Location"
              // handleChange={handleLocationFilter}
              selectedItems={selectedItems.locations}
              handleCheckboxChange={handleLocationFilter}
              updateLocation={decouncedUpdateLocation}
              clearSelection={() => clearFilterSelection("locations")}
              listItems={["All", ...locations?.map(item => item?.city)]}
              />

          </div>
          <div className='mob_filter_box'>
              <FilterBox
              headerText="Industries"
              // handleChange={handleCompanyFilter}
              selectedItems={selectedItems.industries}
              handleCheckboxChange={handleIndustryFilter}
              clearSelection={() => clearFilterSelection("industries")}
              listItems={["All", ...industries?.map(industry=>  industry?.industry_name)]}
              />

          </div>
          <div className='mob_filter_box'>
              <FilterBox
              headerText="Qualifications"
              // handleChange={handleCompanyFilter}
              selectedItems={selectedItems.qualifications}
              handleCheckboxChange={handleQualificationFilter}
              clearSelection={() => clearFilterSelection("qualifications")}
              listItems={["All", ...courses?.map(course=> course?.course_name)]}
              />

          </div>
          {
            skills?.length==10 &&(

           
          <div className='mob_filter_box'>
              <FilterBox
              headerText="Skills"
              // handleChange={handleCompanyFilter}
              selectedItems={selectedItems.skills}
              handleCheckboxChange={handleSkillFilter}
              clearSelection={() => clearFilterSelection("skills")}
              listItems={["All", ...skills?.map(skill=> skill?.name)]}
              />

          </div>
           )
          }

      </div>
    </div>

  </div>
    )
}

export default NewMobSidebar