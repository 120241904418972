const EmailField = ({
  label,
  id,
  placeholder,
  className = "",
  required = false,
  register,
  options = {},
  disabled = false,
  defaultValue,
}) => {
  return (
    <div className="form-group mb-3">
      <label htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        className={`form-control mt-2 ${className}`}
        type="email"
        name={id}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(id, { required, ...options })}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default EmailField;
