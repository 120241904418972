import React from "react";

const TextInput = ({
  defaultValue,
  label,
  id,
  placeholder,
  required = false,
  value,
  handleChange = () => {},
  disabled = false,
  register = () => {},
  isSearch = false,
  className = "",
  className1,
  labelClassName = "",
}) => {
  return (
    <div className={`form-group mb-3 ${className1}`}>
      {label ? (
        <label htmlFor={id} className={`text_label ${labelClassName}`}>
          {label}
          {required && <span className={`${className1}`}>*</span>}
        </label>
      ) : (
        <></>
      )}
      <input
        className={`upload_all form-control mt-2 bg-white search_skills  ${className} }`}
        type={!isSearch ? "text" : "search"}
        name={id}
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        {...register(id)}
      />
    </div>
  );
};

export default TextInput;
