import React, { useState } from "react"
import { useForm } from "react-hook-form"
import TextField from "components/formComponents/TextField"
import PasswordField from "components/formComponents/PasswordField"
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AdminEditMentorManager = () => {
    const { state } = useLocation();

    const [userType, setUserType] = useState(state?.type || "");

    // console.log("statee",state?.first_name)
    const[loading ,setLoading]=useState(false)
   const navigate=useNavigate()

    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
      };

  const { register, handleSubmit, reset,setValue,getValues} = useForm({
    defaultValues: {
        first_name: `${state?.first_name}` || "",
        last_name: `${state?.last_name}` || "",
        phone_number: state?.phone_number||"",
        

      },
  },
  )

  const onSubmit =  async(data, event) => {

    // console.log("dataaaa edit",data)
    setLoading(true)
    event.preventDefault();

    try {
      const res = await axios.put( `/v1/mentor-manager/edit-mentor-manager/${state?.id}`, data, {
      });

      if(!res?.data?.error){
        setLoading(false)
        // navigate('/admin/users')
        toast.success("User is updated");
        reset();
      }else{
        setLoading(false)
        toast.error(res?.data?.message)
      }

    } catch (error) {
      console.error(error);
      setLoading(false)
      toast.error("An error occurred");
    }
  };


  const handleDeActivateMentorManagerAccount = async () => {
    try {
      // setRefresh(true)
      const response = await axios.delete(`/v1/mentor-manager/mentor-manager/${state?.id}`);
      if (!response.data?.error) {
        toast.info(response?.data?.message);
        navigate('/admin/users')
        // setRefresh(false)
        // onDelete(id);
      } else {
        toast.error(response?.data?.error);
        // setRefresh(false)
      }
    } catch (error) {
      console.error(error);
      // setRefresh(false)
      toast.error("An error occurred while deleting the mentor manager.");
    }


    }



  return (
    <div
      className="admin-users-page py-5 pt-4"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <h4 className="mb-5" style={{ fontWeight: "700" }}>
        Edit User
      </h4>
      <div className="mb-5">
        <h6 style={{ fontWeight: "600" }}>User Details</h6>
        <div className="row mx-0">
          <div className="col-lg-6 px-0">
            <form  onSubmit={handleSubmit(onSubmit)}>
              <div className="row mx-0">
                <div className="col-6 ps-0">
                  <TextField
                    label="first Name"
                    // required={true}
                    id="first_name"
                    placeholder="Enter first name"
                    register={register}
                   setValue={setValue}
                    // disabled={true}
                  />


                </div>

                <div className="col-6 ps-0">
                <TextField
                  label="Last Name"
                //   required={true}
                  id="last_name"
                  placeholder="Enter last name"
                  register={register}
                  setValue={setValue}
                  // disabled={true}
                />
              </div>



             
              </div>



             

              <div className="row mx-0">

                



                <div className="col-6 ps-0">
                  <PasswordField
                    label="Password"
                    id="password"
                    register={register}
                    // required={false}
                  />
                </div>


                <div className="col-6 pe-0">
                  <TextField
                    label="Phone Number"
                    // required={true}
                    id="phone_number"
                    placeholder="Enter phone number"
                    register={register}
                    setValue={setValue}
                    // disabled={true}
                  />
                </div>

                
              </div>


              <button
                className="btn btn-primary mt-4 px-5 text-white py-2"
                style={{
                  fontWeight: "500",
                }}
                type="submit"
              >
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h6 style={{ fontWeight: "600" }}>Danger Zone</h6>
        <button
          className="btn btn-primary px-5 text-white py-2"
          style={{
            fontWeight: "500",
            background: "#FF3737",
            border: "1px solid #FF3737",
          }}

          onClick={()=>handleDeActivateMentorManagerAccount()}
        >
          Deactivate Account
        </button>
      </div>
    </div>
  )
}

export default AdminEditMentorManager
