import React from "react"
import "./Ploicy.scss"

const CookiesPolicy = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center text-black">Cookies Policy</h1>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <p>Effective Date: 22/05/2023</p>
          <p>
            This Cookies Policy explains how Qollabb ("we," "us," or "our") uses
            cookies and similar technologies when you access or use our platform
            and services. This policy describes what cookies are, how we use
            them, and your choices regarding their management and control. By
            accessing or using Qollabb, you consent to the use of cookies as
            described in this policy.
          </p>

          <h2>1. What are Cookies?</h2>
          <p>
            Cookies are small text files that are placed on your device
            (computer, mobile device, or tablet) when you visit a website or use
            an application. They are widely used to enhance user experience,
            store preferences, analyze website performance, and provide
            personalized content.
          </p>

          <h2>2. Types of Cookies We Use</h2>
          <p>
            <strong>2.1 Essential Cookies:</strong> These cookies are necessary
            for the operation of our platform and the provision of our services.
            They enable you to navigate the website and use its features, such
            as accessing secure areas or remembering your preferences. Without
            these cookies, the website may not function properly.
          </p>
          <p>
            <strong>2.2 Analytics and Performance Cookies:</strong> These
            cookies collect information about how users interact with our
            platform, including the pages they visit, the links they click, and
            the time spent on each page. We use this data to analyze user
            behavior, improve our services, and enhance the overall performance
            of Qollabb.
          </p>
          <p>
            <strong>2.3 Functionality Cookies:</strong> Functionality cookies
            allow the website to remember your preferences and provide
            personalized features. For example, they may remember your username,
            language preference, or region selection. These cookies enhance your
            user experience and make the platform more convenient to use.
          </p>
          <p>
            <strong>2.4 Advertising and Targeting Cookies:</strong> We may use
            third-party cookies to deliver advertisements and promotional
            content tailored to your interests and preferences. These cookies
            collect information about your browsing behavior and may track your
            activity across different websites. They help us provide relevant
            and targeted advertising.
          </p>

          <h2>3. Third-Party Cookies</h2>
          <p>
            Qollabb may allow third-party service providers, such as analytics
            or advertising partners, to place cookies on your device. These
            cookies are subject to the respective privacy policies of these
            providers. We recommend that you review their policies to understand
            how they collect and process your information.
          </p>

          <h2>4. Cookie Management and Control</h2>
          <p>
            <strong>4.1 Your Consent:</strong> By accessing or using Qollabb,
            you consent to the use of cookies as described in this policy. When
            you first visit our platform, we will request your consent to use
            non-essential cookies. You have the option to accept or decline
            these cookies, except for essential cookies, which are strictly
            necessary for the functioning of the platform.
          </p>
          <p>
            <strong>4.2 Browser Settings:</strong> Most web browsers allow you
            to control cookies through their settings. You can configure your
            browser to accept, reject, or delete cookies. However, please note
            that disabling or deleting cookies may affect your experience and
            limit certain functionalities of Qollabb.
          </p>

          <h2>5. Changes to this Cookies Policy</h2>
          <p>
            We may update this Cookies Policy from time to time. Any changes
            will be effective upon posting the revised policy on our platform.
            We encourage you to review this policy periodically to stay informed
            about how we use cookies.
          </p>

          <h2>6. Contact Us</h2>
          <p>
            If you have any questions, concerns, or suggestions regarding this
            Cookies Policy, please contact us at:
          </p>
          <p>Email: info@qollabb.com</p>
          <p>
            Address: Qollabb EduTech Private Limited, 231, Bank officers
            HBCS,1st Cross, 40th Main BTM, 2nd Stage, Bangalore, Karnataka,
            India, 560068
          </p>

          <p>
            Thank you for understanding and accepting our use of cookies. We
            strive to provide you with a seamless and personalized experience on
            Qollabb while respecting your privacy preferences.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CookiesPolicy
