import axios from "axios";
import Error from "components/employer/Error";
import Progress from "components/employer/Progress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { REMOVE_APPLICATION_DATA, REMOVE_PROJECT_ID } from "services/constants";
import "styles/employer/submit.scss";

const Submit = ({
  setIsPreview,
  state = -1,
  method,
  company_id,
  branch_id,
  address,
  autoChecked,
}) => {
  const [progress, setProgress] = useState(() => 0);
  const [isError, setIsError] = useState(() => false);
  const [isSuccess, setIsSuccess] = useState(() => false);

  const application = useSelector((state) => state.application);
  // const { company } = application;
  const internship = application[method];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (progress === 100 || isSuccess || isError) return;
    const body = document.querySelector("body");
    const controller = new AbortController();

    try {
      body.style.overflow = "hidden";

      const config = {
        onUploadProgress: (progressEvent) =>
          setProgress(
            () => Math.round(progressEvent.loaded / progressEvent.total) * 100
          ),
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        params: { state },
        signal: controller.signal,
      };

      function buildFormData(formData, data, parentKey) {
        if (
          data !== null &&
          typeof data === "object" &&
          !(data instanceof Date) &&
          !(data instanceof File)
        ) {
          Object.keys(data).forEach((key) => {
            buildFormData(
              formData,
              data[key],
              parentKey ? `${parentKey}[${key}]` : key
            );
          });
        } else if (data !== null) {
          formData.append(parentKey, data);
        }
      }

      const formData = new FormData();
      buildFormData(formData, internship);
      // buildFormData(formData, company);
      buildFormData(formData, { branch_id });
      buildFormData(formData, { company_id });
      buildFormData(formData, { address });
      formData.append("autoChecked", autoChecked);
      // // console.log(company);

      const submitFormWithProgress = async () => {
        try {
          const response = await axios.post(`/v1/${method}`, formData, config);
          if (!response?.data?.error) {
            setIsSuccess(() => true);
          } else {
            toast.error(response?.data?.message || "Something went wrong");
            setIsError(() => true);
          }
        } catch (err) {
          console.log(err);
        }
      };

      submitFormWithProgress();
    } catch (err) {
      setIsError(() => true);
      toast.error(err?.message || "Something went wrong");
    }

    return () => {
      body.style.overflow = "auto";
      // controller?.abort();
    };
  }, [
    // company,
    internship,
    progress,
    state,
    dispatch,
    navigate,
    method,
    isSuccess,
  ]);

  const onAnimationEnd = () => {
    navigate(`/employer/dashboard`);
    // window.location.reload();
    toast.success(`${method} added successfully!`);
    dispatch({ type: REMOVE_APPLICATION_DATA, payload: { method } });
    dispatch({ type: REMOVE_PROJECT_ID, payload: { method } });
  };

  return (
    <div className="submit_container" onClick={() => setIsPreview(0)}>
      {isSuccess ? (
        <>
          <div className="firework" onAnimationEnd={onAnimationEnd}></div>
          <span className="success-message">{method} is live</span>
        </>
      ) : isError ? (
        <Error setIsPreview={setIsPreview} />
      ) : (
        <Progress percentage={progress} />
      )}
    </div>
  );
};

export default Submit;
