import { useNavigate } from "react-router-dom"

const useHandleClearFilters = (dispatch, setSelectedItems, setSuggestComp, role, filterMap, userRole, setFile)=> {

    const navigate = useNavigate();


    const clearFilterSelection = (filterType) => {
        setSelectedItems((prevState) => ({
          ...prevState,
          [filterType]: ["all" || "project"],
        }))
    
        if (filterType === "opportunity") {
          dispatch({
            type: "change_value",
            payload: { selected: 0 },
          })
        }
        if (filterType === "companies") {
          dispatch({
            type: "change_value",
            payload: { company_ids: new Set() },
          })
        }
        if (filterType === "locations") {
          dispatch({
            type: "change_value",
            payload: { address: new Set() },
          })
        }
        if (filterType === "industries") {
          dispatch({
            type: "change_value",
            payload: { industry_sector: new Set() },
          })
        }
    
        if (filterType === "qualifications") {
          dispatch({
            type: "change_value",
            payload: { course_preferred: new Set() },
          })
        }
        if (filterType === "skills") {
          dispatch({
            type: "change_value",
            payload: { skills: new Set() },
          })
        }
      }
    
      const clearAllSelections = () => {
        setSelectedItems({
          companies: ["all"],
          industries: ["all"],
          qualifications: ["all"],
          locations: ["all"],
          skills:["all"]
        })
    
        dispatch({
          type: "change_value",
          payload: {
            selected: 0,
            company_ids: new Set(),
            address: new Set(),
            industry_sector: new Set(),
            course_preferred: new Set(),
            skills:new Set()
          },
        })
    
        setSuggestComp("")
    
    
    
        dispatch({
          type: "change_value",
          payload: { filters: {} },
        })
    
        const rolePath = role === 3 || role === 4 ? `/${userRole[role]}/explore` : "/explore-page" ;
        navigate(`${rolePath}/top-${filterMap[0]}`);
    
    
      }


      const clearResumeSelection = async () => {
        localStorage.removeItem("resume")
        localStorage.removeItem("keywords")
        setFile(null)
        dispatch({
          type: "change_value",
          payload: { keywords: [] },
        })
      }



    return {
        clearFilterSelection,
        clearAllSelections,
        clearResumeSelection
    }
}

export default useHandleClearFilters;