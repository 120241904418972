import React from "react";
import User from "./User.png";
import "./collegesCards.scss";

import ProfileIcon from "components/employer/ProfileIcon";
import Link from "./Link.svg";
import LinkedIn from "./Linkedin.svg";

const CollegesCards = ({ institution }) => {
  // console.log("institution", institution);

  return (
    <>
      <div className="row m-0 collegeCard">
        <div className="cardHeader">
          <div className="collegeDetail">
            <div className="logo">
              {institution?.profile_pic ? (
                <img src={institution?.profile_pic} alt="logo" srcset="" />
              ) : (
                <div className="img-box-img">
                  <ProfileIcon />
                </div>
              )}
            </div>

            <div className="details d-flex flex-column gap-0">
              <div className="collegeName">
                {institution?.institute?.institution_name}
              </div>
              <div className="collegeLocation">
                {institution?.institute_branch?.address}
              </div>
              {/* <div className="collegeType">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                University(Private)
              </div> */}
            </div>
          </div>
          <div className="collegeUserData">
            <h6 className="userLabel">Contact Person</h6>
            <div className="userDetail">
              <div className="profilePic">
                <img src={User} alt="Image" />
              </div>
              <div className="details d-flex flex-column gap-0">
                <h3>
                  {institution?.first_name} {institution?.last_name}
                </h3>
                <h6>{institution?.designation}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="cardBody">
          <div className="collegeDescription">{institution?.bio}</div>
        </div>
        <div className="cardFooter">
          <div className="collegeWebsite">
            {institution?.profile_link && (
              <>
                <img src={Link} alt="Image" />
                {institution?.profile_link}
              </>
            )}
          </div>
          <div className="userLinkedin">
            {institution?.linkedIn_link && (
              <>
                <img src={LinkedIn} alt="Image" />
                {institution?.linkedIn_link}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollegesCards;
