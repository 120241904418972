import React, { useState } from "react"
import "../style.scss"
import AdminSubscriptionTable from "../table"
import SubscriptionHistoryTable from "../table/SubscriptionHistoryTable"
import Datatable from "components/datatable-new/DatatableNew"
import axios from "axios"
import { toast } from "react-toastify"

const AdminCompanySubscription = () => {
  const plans = [
    {
      plan: "Post your projects for the students and access their fresh ideas",
      free: <span className="text-color-grey">10 Free</span>,
      monthly: <span className="text-color-grey">Unlimited</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Post your internships and/or jobs for the students to apply and hire them",
      free: <span className="text-color-grey">05 Free</span>,
      monthly: <span className="text-color-grey">Unlimited</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Directly connect with selected universities & colleges and offer your projects, internships, and jobs to their students",
      free: <span className="text-color-grey">05 Free</span>,
      monthly: <span className="text-color-grey">Unlimited</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Evaluation and rating of student's project work out on your behalf",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Track student progress during project workd",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Directly chat with students, colleges, and schedule interviews",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Promoting your company among universities/colleges and students",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Dedicated account manager",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
  ]
  const history = [
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
  ]
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      width: "15%",
      textalign: "center",
      // Cell: (data) => {
      //   return (
      //     <strong style={{ fontWeight: "700" }}>
      //       {data.row.original.date}

      //     </strong>
      //   )
      // },
    },
    {
      Header: "Monthly",
      accessor: "monthly",
      width: "15%",
      textalign: "center",
    },
    {
      Header: "Yearly",
      accessor: "yearly",
      width: "15%",
      textalign: "center",
    },
    {
      Header: " ",
      accessor: "",
      width: "55%",
    },
  ]


  const [companySubscriptionValue,setCompanySubscriptionValue]=useState({
    monthly:'',
    yearly:''
  })
 
  

  function handleSubscriptionChange(e,type){
    const price=e.target.value
    setCompanySubscriptionValue({
      ...companySubscriptionValue,
      [type]: price
    })
  }
// console.log("plan0",companySubscriptionValue)
// console.log("plan1",companySubscriptionValue.monthly)
// console.log("plan2",companySubscriptionValue.yearly)




const handleSaveChanges = async () => {
    try {
      if (companySubscriptionValue?.monthly) {
        const monthlyResponse = await axios.put(`/v1/subscription/subscription-update/2`, { price: companySubscriptionValue?.monthly });
        // console.log("Monthly", monthlyResponse);
        toast.success('Monthly Subscription Updated Successfully!')
        
      }
      
      if (companySubscriptionValue?.yearly) {
        const yearlyResponse = await axios.put(`/v1/subscription/subscription-update/3`, { price: companySubscriptionValue.yearly });
        // console.log("Yearly subscription updated:", yearlyResponse);
        toast.success('Yearly Subscription Updated Successfully!')
      }
      
      // Reset updatedSubscription and companySubscriptionValue after successful update

      setCompanySubscriptionValue({
        monthly: '',
        yearly: ''
      });
    } catch (error) {
      console.error("An error occurred while updating subscription:", error);
      
    }

};

  return (
    <div className="admin-subscriptions-container">
      <div className="row mx-0 subscription-topbar">
        <div className="col-md-4 col-12 d-flex align-items-center pe-0">
          <h3>Upgrade Your Plan </h3>
        </div>
        <div className="col-md-8 col-12 ps-0">
          <div className="row mx-0">
            <div className="col-4 ps-0 ">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Free Plan</h4>
                <button className="btn btn-outline-primary">Active</button>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Monthly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex flex-wrap mb-3">
                    <span>₹</span>
                    <input type="number"  onChange={(e) => handleSubscriptionChange(e, 'monthly')} value={companySubscriptionValue?.monthly} />
                    <span>/month</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Yearly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex flex-wrap mb-3">
                    <span>₹</span>
                    <input type="number"  onChange={(e) => handleSubscriptionChange(e, 'yearly')} value={companySubscriptionValue.yearly} />
                    <span>/year</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminSubscriptionTable data={plans} />
      <div className="cancel-save-buttons d-flex align-items-center justify-content-end my-3 me-4">
        <button className="btn btn-outline-primary me-2">Cancel</button>
        <button className="btn btn-primary" onClick={handleSaveChanges}>Save Changes</button>
      </div>
      <div className="subscription-history-container py-5">
        <h3 className="mb-3">Price Revision History</h3>
        <div className="subscription-history-table card">
          <Datatable data={history} columns={columns} />
        </div>
      </div>
    </div>
  )
}

export default AdminCompanySubscription
