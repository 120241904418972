import { DevTool } from "@hookform/devtools";
import CategoryField from "components/formComponents/CategoryField";
import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import FromTo from "components/formComponents/FromTo";
import TextArea from "components/formComponents/TextArea";
import TextField from "components/formComponents/TextField";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addApplicationData } from "services/actions/application";
const CreateProject1 = ({ shouldValidate, updateShouldValidate }) => {
  const defaultValues = useSelector((state) => state.application["project"]);
  const navigate = useNavigate();
  const [isPopupOpen, updatePopup] = useState(() => false);
  const { id } = useParams();
  const [courses, setCourses] = useState(defaultValues?.course_preferred || []);
  // // console.log(courses, "hello world");
  const dispatch = useDispatch();
  const project_category = defaultValues?.project_category || "remote";
  const [isRemote, setIsRemote] = useState(true);
  const [remoteOfficeVisit, setRemoteOfficeVisit] = useState(false);
  const deleteItem = (item, id) => {
    try {
      if (id === "course_preferred") {
        setCourses((prev) =>
          prev.filter((course) => course.course_name !== item)
        );
      } else {
        throw new Error("Invalid id");
      }
    } catch (err) {
      toast.error(err?.message || err, { toastId: "deleteItem" });
    }
  };
  // const [flag, setFlag] = useState(() => {
  //   return project_category === "remote" && defaultValues?.office_visit === true
  //     ? true
  //     : false;
  // });

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    unregister,
    control,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      office_visit_remote:
        project_category === "remote" && defaultValues?.office_visit === true
          ? true
          : false,
    },
  });
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  let category_type = null;
  if (typeof defaultValues?.project_category === "number") {
    category_type = map[defaultValues?.project_category];
  } else {
    category_type = defaultValues?.project_category;
  }

  const [defaultType, setDefaultType] = useState(() => {
    return category_type || "remote";
  });
  useEffect(() => {
    const onSubmit = (data) => {
      // console.log(data, "data");
      dispatch(
        addApplicationData({
          method: "project",
          data: {
            ...data,
            course_preferred: courses,
            id: id || null
          },
        })
      );
      navigate("/employer/project/preview");
    };

    if (shouldValidate) {
      unregister("location");
      unregister("course_preferred");
      unregister("office_visit_remote");
      const data = getValues();
      let isError = false;

      // if (data["project_category"] === "remote") {
      //   data["office_visit"] = flag;
      // } else {
      //   data["office_visit"] = true;
      // }

      data.office_visit = false;

      if (!(data["project_category"] === "remote")) {
        data["office_visit"] = true;
      }

      if (isRemote && remoteOfficeVisit) {
        data["office_visit"] = true;
      }

      Object.keys(data).forEach((item, index) => {
        const notRequired = [
          "faq",
          "additional_info",
          "office_visit",
          "project_support",
          "project_city",
          "required_skills",
          "city",
          "id"
        ];
        if (!data[item] && !notRequired.includes(item)) {
          toast.error(`${item?.split("_")?.join(" ")} is required`, {
            toastId: `${item}Error`,
            delay: 100 * index,
          });
          isError = true;
        }
      });

      if (isRemote && !remoteOfficeVisit) {
        delete data["city"];
      } else {
        if (!data["city"]) {
          toast.error(`City is required`, {
            toastId: `cityError`,
            delay: 100 * Object.keys(data).length,
          });
          isError = true;
        }
      }

      if (data["joining_date"]) {
        if (new Date(data["joining_date"]) < new Date()) {
          toast.error("Joining date can't be in past", {
            toastId: "joining_dateError",
            delay: 100 * (Object.keys(data).length + 1),
          });
          isError = true;
        }
      }
      if (!data["duration"]) {
        data["duration_from"] = Number.parseInt(data["duration_from"]);
        if (data?.duration_to) {
          data["duration_to"] = Number.parseInt(data["duration_to"]);
          if (
            data?.duration_from > data?.duration_to ||
            isNaN(data?.duration_from) ||
            isNaN(data?.duration_to)
          ) {
            toast.error("Invalid duration", { toastId: "duration" });
            isError = true;
          }
        }
      } else {
        data["duration"] = Number.parseInt(data["duration"]);
        if (isNaN(data?.duration)) {
          toast.error("Invalid duration", { toastId: "duration" });
          isError = true;
        }
      }

      if (!isError) {
        handleSubmit(onSubmit(data));
        updateShouldValidate(1, false, true);
      }

      return () => {
        updateShouldValidate(1, false, false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldValidate,
    updateShouldValidate,
    handleSubmit,
    getValues,
    unregister,
    dispatch,
    setValue,
  ]);

  React.useEffect(() => {
    if (
      defaultValues?.project_category === "remote" &&
      defaultValues?.office_visit === true
    ) {
      setRemoteOfficeVisit(true);
      setIsRemote(true);
    } else if (
      defaultValues?.project_category === "remote" &&
      defaultValues?.office_visit === false
    ) {
      setRemoteOfficeVisit(false);
      setIsRemote(true);
    } else if (!defaultValues?.project_category) {
      setRemoteOfficeVisit(false);
      setIsRemote(true);
    } else {
      setRemoteOfficeVisit(false);
      setIsRemote(false);
    }
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div className="row mx-0">
        <div className="col-12">
          <TextField
            label="Project Title"
            secondaryLabel="In the text box below, please mention a title of your project that is easily understandable by students"
            required={true}
            id="project_title"
            placeholder="Ex: Full stack developement"
            register={register}
          />
        </div>

        <div className="col-12">
          <TextArea
            label="What is main aim/goal of this Project"
            secondaryLabel="Describe the problem students will be solving OR the outcome you expect the students to achieve at the end of Project."
            required={true}
            id="project_goal"
            placeholder="Ex: Full Stack Development project"
            register={register}
          />
        </div>
        <div className="col-12">
          <TextArea
            label="What tasks students will be required to undertake in order to successfully complete the Project"
            secondaryLabel="List activities, tasks etc that students will be required to undertake to successfully complete the Project."
            required={true}
            id="project_tasks"
            register={register}
          />
        </div>

        <div className="col-12">
          <CategoryField
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            getValues={getValues}
            label="How would you like to categorise this Project?"
            required={true}
            id="project_category"
            register={register}
            subLable="I would like to offer the candidates atleast one day visit to your office if it is a virtual or feild based project? "
            defaultCheck={defaultType}
            setDefaultType={setDefaultType}
            // setFlag={setFlag}
            remoteOfficeVisit={remoteOfficeVisit}
            setRemoteOfficeVisit={setRemoteOfficeVisit}
          />
        </div>
        {(remoteOfficeVisit || !isRemote) && (
          <div className="col-12">
            <TextFieldWithPopup
              label="Location"
              required={true}
              // secondaryLabel="In the text box below, please mention a title of your Project that is easily understandable by students"
              id="location"
              register={register}
              isPopupOpen={isPopupOpen}
              updatePopup={updatePopup}
              path="/v1/location/city"
              key_name="location"
              setValue={setValue}
              withId="city"
              value={defaultValues?.city?.location}
            />
          </div>
        )}
        {/* <ChipsFieldWithPopup
          register={register}
          required={true}
          label="Name The Courses From Which You Would Prefer Students To Undertake This Project"
          secondaryLabel="In the box below, please mention courses of which students are more suitable for your Project. You can mention multiple courses if required, E.g. MBA - Marketing, B.Tech. (Mechanical Engineering) etc"
          id="course_preferred"
          items={courses}
          setItems={setCourses}
          deleteItem={deleteItem}
          setValue={setValue}
          path="/v1/filter/courses"
          key_name="course_name"
        /> */}

        <div className="col-12">
          <FromTo
            register={register}
            label="Total duration of the Project"
            secondaryLabel="Please mention the total number of weeks that you excpect the students
              to work on this Project"
            getValues={getValues}
            setValue={setValue}
          />
        </div>
        <div>
          <ChipsFieldWithPopup
            register={register}
            label="Name The Courses From Which You Would Prefer Students To Undertake This Project"
            secondaryLabel="In the box below, please mention courses of which students are more suitable for your Project. You can mention multiple courses if required, E.g. MBA - Marketing, B.Tech. (Mechanical Engineering) etc"
            id="course_preferred"
            items={courses}
            setItems={setCourses}
            deleteItem={deleteItem}
            setValue={setValue}
            path="/v1/filter/courses"
            key_name="course_name"
          />
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
};

export default CreateProject1;
