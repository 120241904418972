import React from 'react'
import TopNavBar from './components/TopNavbar/TopNavBar'
import NavBar from './components/navbar/NavBar'
import Footer from 'view/dashboard/student/footer/Footer'
import GoToTop from 'GoToTop'
import CookiesPolicy from './Policies/CookiesPolicy'
import { Helmet } from 'react-helmet'

const CookiesPolicyPage = () => {
  return (
    <div className="home_page row justify-content-center align-items-center">
        <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content={`This Cookies Policy explains how Qollabb uses cookies and similar technologies when you access or use our platform and services.`}
        />
            <meta
              name="title"
              content={"Cookies Policy"}
            />
        <title>
          Cookies Policy
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
          <TopNavBar />
          <NavBar />
          <CookiesPolicy/>
          <Footer />
      <GoToTop />
    </div>
  )
}

export default CookiesPolicyPage