import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup"
import EmailField from "components/formComponents/EmailField"
import TextArea from "components/formComponents/TextArea"
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup"
import TextInput from "components/formComponents/TextInput"
import NewInputImage from "components/image-uploader/New_Image_uploader/NewInputImage"
import React, { useReducer, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "./PersonalDetails.scss"
import { useDispatch, useSelector } from "react-redux"
import Dropdown from "components/formComponents/post/Dropdown/Dropdown"
import DateField from "components/formComponents/DateField"
import TextLink from "components/formComponents/TextLink"
import { get } from "react-hook-form"
import InputFile from "components/fileUploader"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { DatePicker } from "rsuite"
import CustomYearPicker from "components/formComponents/YearPicker"

const RequiredShowStar = () => {
  return <p className="requiredShowStar">*</p>
}

const PersonalDetails = ({
  register,
  image,
  setImage,
  setValue,
  data,
  getValues = () => {},
  setFile,
  file,
  studentProfile,
  languages,
  setLanguages,
}) => {
  const user = useSelector((state) => state.auth.user)
  const [skills, setSkills] = useState(() => [])
  const [interests, setInterests] = useState(() => [])
  const [isPopupOpen, updatePopup] = useState(() => false)
  // console.log(user, "user123");
  const [gender, setGender] = useState(() => user?.user_gender || "")

  const [CompletionSelectedYear, setCompletionSelectedYear] = useState(() =>  user?.course_end_year|| ""); 

  const handleYearChange = (year) => {
    setCompletionSelectedYear(year);
    setValue("course_end_year",year) 
  };

  console.log("CompletionSelectedYear",CompletionSelectedYear)

  const updateGender = (value) => {
    setGender(value)
    setValue("user_gender", value)
  }

  const dispachdata = useDispatch()

  const langs = [  
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "English",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu"
]

  // console.log(gender, "std123");

  // console.log(languages, "std123");

  const updateLanguages = (value) => {
    setLanguages((prev) => {
      if (prev.includes(langs[value])) {
        return prev.filter((item) => item !== langs[value])
      }
      return [...prev, langs[value]]
    })
  }

  // const saveLanguage = (languages) => {
  //   dispatch(updateUser(languages))
  // }
  // setValue("languages", languages);
  // console.log(languages, "language");

  const deleteSkill = (skill) => {
    const newSkills = skills.filter((item) => item?.name !== skill)
    setSkills(newSkills)
  }

  const deleteInterests = (interest) => {
    const newInterests = interests.filter((item) => item?.name !== interest)
    setInterests(newInterests)
  }
  const initialState = {
    title: "",
    link: "",
  }
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        }
      case "reset":
        return initialState
      default:
        return state
    }
  }, initialState)
  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    })
  }
  const handleChange = (e) => {
    dispachdata({
      value: e.target.value,
    })
  }

  const maxSize = 5 // MB
  //v1/student/profile

  const onChangeHandler = (e) => {
    // console.log(e)
    const accepted = ["pdf", "ppt", "pptx", "doc", "docx"]
    setFile(null)
    const uploaded = e.target.files[0]

    // check if file is accepted
    const fileExtension = uploaded.name.split(".").pop()
    if (!accepted.includes(fileExtension)) {
      toast.error("File type not accepted")
      return
    }

    // check if file is too large
    const fileSize = uploaded.size / 1024 / 1024
    if (fileSize > maxSize) {
      toast.error(`File size too large (max ${maxSize}MB)`)
      return
    }

    setFile(uploaded)
  }

  const [isPopupOpen1, updatePopup1] = useState(() => false)
  const [isPopupOpen2, updatePopup2] = useState(() => false)

  const onEdit = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = "application/pdf"
    input.click()
    input.onchange = onChangeHandler
  }

  // console.log(file, "file123");

  const style = {
    marginLeft: "10px",
    cursor: "pointer",
    color: "#4d4d4d",
    fontSize: "20px",
  }
  const [editMode, setEditMode] = useState(true);

  const toggleEditMode = () => {
    setEditMode(prevMode => !prevMode);
  };


  return (
    <div className="row personal_details_container">
      <div className="col-12 mb-2">
        <div className="col-sm-2 col-6">
          {/*<InputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_photo}
          />*/}
          <div className="col-12">
            <NewInputImage
              image={image}
              setImageFile={setImage}
              url={user?.profile_photo}
            />
            <div className="flex-two-column mt-2">
            <p>(Image max size 1 MB)</p>
           <RequiredShowStar />
          </div>
            {/* <RequiredShowStar /> */}
          </div>
        </div>
      </div>

      {user?.student_resume ? (
        <div className="col-12">
          <label
            className="form-group mt-2 w-100 text_label flex-two-column "
            style={{ marginLeft: "10px", marginBottom: "0px"}}
          >
            Resume <RequiredShowStar />
          </label>
          <div className="form-control py-3 mb-2" style={{justifyContent: 'space-between',
            display: 'flex'}}>
            {file ? (
              <span>{file?.name}</span>
            ) : (
              <Link to={user?.student_resume} target="_blank">
                <span style={{fontWeight:'700'}}>View Resume</span>
                <i className="fa-solid fa-eye ms-2"></i>
              </Link>
            )}
            {file ? (
              <i
                onClick={() => setFile(null)}
                style={style}
                class="fa-solid fa-xmark"
              ></i>
         
            ) : (

              // <i
              //   onClick={onEdit}
              //   style={style}
              //   className="fa-regular fa-pen-to-square"
              // >
              //   <b style={{fontSize:'10px', fontWeight:'bolder', letterSpacing:'2', color:'#523EAE' }} > Upload New Resume </b>
              
              //   </i>
              <div style={{display: "flex", alignItem:"center"}} onClick={onEdit}>
                     <span style={{marginRight: '5px',cursor:'pointer',fontWeight:'600'}}>Upload New Resume</span>
                   <span  className="fa-regular fa-pen-to-square" style={style}></span>
               </div>

              
            )}
          </div>
        </div>
      ) : (
        <div className="col-12">
          <label className="form-group mb-3 w-100 text_label flex-two-column">
            Upload Resume
            <RequiredShowStar />
          </label>
          <input
            type="file"
            className="form-control py-4 my-3"
            onChange={onChangeHandler}
          />
        </div>
      )}

      {/* editmodeopen */}
      <div className={`col-12 p-3 ${editMode ? 'edit-mode' : 'edit-mode-hide'}`}>
  <div className="d-flex justify-content-end align-items-center" onClick={toggleEditMode}>
    <span style={{ marginRight: '5px', cursor: 'pointer', fontWeight: '700', color: '#523EAE' }}>Edit</span>
    <span className="fa-regular fa-pen-to-square" style={{ color: '#523EAE', fontWeight: '700', cursor: 'pointer' }}></span>
  </div>
</div>


      <div className={`col-lg-6 col-md-6 col-sm-12 ${editMode?'edit-mode':''}`}>
        <TextInput 
          label="First Name"
          id="first_name"
          labelClassName="fieldLabel"
          register={register}
          className={editMode?`edit-disabled-field`:'profileInputField'}
          // placeholder={user?.first_name}
          required={true}
          className1={"requiredShowStar"}
          disabled={editMode}
        />
      </div>

      <div className={`col-lg-6 col-md-6 col-sm-12 ${editMode?'edit-mode':''}`}>
        <TextInput
          label="Last Name"
          id="last_name"
          required={true}
          register={register}
          placeholder={user?.last_name}
          className={editMode?`edit-disabled-field`:'profileInputField'}
          // className="profileInputField"
          className1={"requiredShowStar"}
          disabled={editMode}
        />
      </div>

      <div className={`col-lg-6 col-md-6 col-sm-12 ${editMode?'edit-mode':''}`}>
        <label className="form-group mb-3 w-100 text_label">
          {" "}
          Contact Number <span className="requiredShowStar">*</span>
          <PhoneInput
            country={"in"}
            placeholder="Enter phone number"
            onChange={(e) => setValue("phone_number", e)}
            defaultCountry="IN"
            onlyCountries={["in"]}
            name="phone_number"
            specialLabel={""}
            containerClass={" mt-2" }
            inputStyle={{ marginTop: "10px" }}
            inputClass={editMode?`edit-disabled-field`:'form-control profileInputField '}
            value={state?.phone_number || user?.phone_number}
            disabled={editMode}
          />
        </label>
      </div>
      <div className={`col-lg-6 col-md-6 col-sm-12 ${editMode?'edit-mode':''}`}>
        <EmailField
          label="Email"
          id="email_address"
          register={register}
          placeholder={user?.email_address}
          className="email-disabled"
          disabled={true}
        />
      </div>
{/* end edit mode  */}

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Course Name (Highest Qualification)"
          required={true}
          id="course_name"
          register={register}
          isPopupOpen={isPopupOpen1}
          updatePopup={updatePopup1}
          path="/v1/filter/courses"
          key_name="course_name"
          setValue={setValue}
          withId="course_name"
          className="profileInputField"
          className1={"requiredShowStar"}
          value={user?.course_name}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        {/* <label className="profileSectionLabel mb-4">Current Course</label> */}

        {/* <div className="col-lg-6 col-md-6 col-sm-12">
          <TextFieldWithPopup
            label="Course Name"
            required={true}
            id="course_name"
            register={register}
            isPopupOpen={isPopupOpen1}
            updatePopup={updatePopup1}
            path="/v1/filter/courses"
            key_name="course_name"
            setValue={setValue}
            withId="course_name"
            className="profileInputField"
            className1={"requiredShowStar"}
            value={user?.course_name}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextFieldWithPopup
            label="Branch/specialization"
            id="branch_name"
            register={register}
            isPopupOpen={isPopupOpen2}
            required={true}
            updatePopup={updatePopup2}
            path={`/v1/filter/branch?course_id=${user?.course_id}`}
            key_name="branch_name"
            setValue={setValue}
            withId="branch_name"
            className="profileInputField"
            className1={"requiredShowStar"}
            value={user?.branch_name}
          />
        </div> */}

        {/* <div className="col-lg-6 col-md-6 col-sm-12">
          <DateField
            minDate="1999"
            maxData="2030"
            label="Course start year"
            id="course_start_year"
            register={register}
            placeholder="Choose a date"
            className="profileInputField"
            required={true}
            className1={"requiredShowStar"}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <DateField
            label="Course end year"
            id="course_end_year"
            minDate="1999"
            maxDate="2030"
            register={register}
            required={true}
            className="profileInputField"
            className1={"requiredShowStar"}
          />
        </div> */}

        
       <CustomYearPicker
          label="Year of course completion"
          id="course_end_year"
          minDate="1999"
          maxDate="2030"
          register={register}
          required={true}
          className="profileInputField"
          className1={"requiredShowStar"}
          onYearChange={handleYearChange}
          setValue={setValue} 
          value={user?.course_end_year}

        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <DateField
          minDate="1990"
          maxData={new Date()}
          label="Date of Birth"
          id="date_of_birth"
          register={register}
          placeholder="Choose a date"
          className="profileInputField"
          className1={"requiredShowStar"}
          // required={true}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={["Male", "Female", "Others"]}
          checked={gender}
          label="Gender"
          className="profileDropdown"
          setItem={updateGender}
          // required={true}
          className1={"requiredShowStar"}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          className="profileInputField"
          value={user?.location}
          placeholder={"Enter your location"}
          // required={true}
          className1={"requiredShowStar"}
        />{" "}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={langs}
          checked={languages}
          label="Language"
          className="profileDropdown"
          setItem={updateLanguages}
          isMultiple={true}
          // required={true}
          className1={"requiredShowStar"}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Institution Name"
          id="institution_name"
          register={register}
          placeholder={user?.institution_name}
          className="profileInputField"
          // required={true}
          className1={"requiredShowStar"}
        />
      </div>
     

      {/* {user?.student_resume ? (
        <div className="col-12">
          <label className="form-group mb-3 w-100 text_label flex-two-column">
            Resume <RequiredShowStar />
          </label>
          <div className="form-control py-4 my-3">
            {file ? (
              <span>{file?.name}</span>
            ) : (
              <Link to={user?.student_resume} target="_blank">
                <span>View Resume</span>
              </Link>
            )}
            {file ? (
              <i
                onClick={() => setFile(null)}
                style={style}
                class="fa-solid fa-xmark"
              ></i>
            ) : (
              <i
                onClick={onEdit}
                style={style}
                className="fa-regular fa-pen-to-square"
              ></i>
            )}
          </div>
        </div>
      ) : (
        <div className="col-12">
          <label className="form-group mb-3 w-100 text_label flex-two-column">
            Upload Resume
            <RequiredShowStar />
          </label>
          <input
            type="file"
            className="form-control py-4 my-3"
            onChange={onChangeHandler}
          />
        </div>
      )} */}

      <TextArea
        label="Bio (max 1000 characters)"
        id="bio"
        // required={true}
        register={register}
        labelClass="profileSectionLabel"
        className="profileInputField"
        className1={"requiredShowStar"}
      />

      <div className="row personal_details_container w-100 mt-4">
        <label className="profileSectionLabel">Profile Links</label>

        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextLink
            label="Linkedin"
            id="linkedIn_link"
            register={register}
            className="profileInputField"
            // value={getValues("linkedIn_link") || ""}
          />
          <TextLink
            label="Portfolio Link"
            id="profile_link"
            register={register}
            className="profileInputField"
            // value={getValues("profile_link") || ""}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextLink
            label="Github"
            id="github_link"
            register={register}
            className="profileInputField"
            // value={getValues("github_link") || ""}
          />
        </div>
      </div>
    </div>
  )
}

export default PersonalDetails
