import CategoryField from "components/formComponents/CategoryField";
import ChipsField2 from "components/formComponents/ChipsField";
import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import DateField from "components/formComponents/DateField";
import FromTo from "components/formComponents/FromTo";
import NumberField from "components/formComponents/NumberField";
import TextArea from "components/formComponents/TextArea";
import TextField from "components/formComponents/TextField";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addApplicationData } from "services/actions/application";
import InternshipDetails3 from "./page3";

const defaultItems = [
  {
    id: "incentive_from",
    label: "From",
    required: true,
    options: {
      min: 0,
      max: 99999999,
    },
  },
  {
    id: "incentive_to",
    label: "To",
    required: false,
    options: {
      min: 0,
      max: 99999999,
    },
  },
];

const CreateInternship = ({ shouldValidate, updateShouldValidate }) => {
  const defaultValues = useSelector((state) => state.application["internship"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [courses, setCourses] = useState(
    () => defaultValues?.course_preferred || []
  );
  const internshipLocation = defaultValues?.city?.location || "";
  const [isPopupOpen, updatePopup] = useState(() => false);
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  let category_type = null;

  if (typeof defaultValues?.internship_category === "number") {
    category_type = map[defaultValues?.internship_category];
  } else {
    category_type = defaultValues?.internship_category;
  }

  const [defaultType, setDefaultType] = useState(() => {
    return category_type || "office";
  });

  const [internshipCity, setInternshipCity] = useState(
    () => defaultValues?.internship_city || []
  );

  // const [isRemote, setIsRemote] = useState(() =>
  //   defaultValues?.internship_category === "remote" ? true : false
  // );

  // const [flag, setFlag] = useState(defaultValues?.office_visit ? true : false);
  const [isRemote, setIsRemote] = useState(true);
  const [remoteOfficeVisit, setRemoteOfficeVisit] = useState(false);

  const { handleSubmit, register, getValues, setValue, unregister } = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const dispatch = useDispatch();
  const deleteItem = (item, id) => {
    try {
      if (id === "course_preferred") {
        setCourses((prev) =>
          prev.filter((course) => course.course_name !== item)
        );
      } else if (id === "internship_city") {
        setInternshipCity((prev) => prev.filter((city) => city !== item));
      } else {
        throw new Error("Invalid id");
      }
    } catch (err) {
      toast.error(err?.message || err, { toastId: "deleteItem" });
    }
  };

  const [linkedProject, setLinkedProject] = useState(() => ({
    name: "",
    id: 0,
  }));

  useEffect(() => {
    const onSubmit = (data) => {
      dispatch(
        addApplicationData({
          method: "internship",
          data: {
            ...data,
            course_preferred: courses,
            project_linked: linkedProject,
            internship_city: internshipCity,
            id: id || null,
          },
        })
      );
      navigate("/employer/internship/preview");
      localStorage.setItem("step_Internship", 3);
    };
    if (shouldValidate) {
      // unregister("internship_skills");
      unregister("location");
      unregister("course_preferred");
      unregister("office_visit_remote");
      const data = getValues();

      data.incentive_from = Number.parseInt(data.incentive_from);
      data.incentive_to = Number.parseInt(data.incentive_to);
      data.vacancies = Number.parseInt(data.vacancies);

      // console.log(data, "dataaaa");
      let isError = false;
      data.office_visit = false;

      if (!(data["internship_category"] === "remote")) {
        data["office_visit"] = true;
      }

      if (isRemote && remoteOfficeVisit) {
        data["office_visit"] = true;
      }

      Object.keys(data).forEach((item, index) => {
        // console.log("keyydataa", item)
        const notRequired = [
          "faq",
          "additional_info",
          "office_visit",
          "internship_city",
          "course_preferred",
          "city",
          "internship_city",
          "id",
        ];
        if (!data[item] && !notRequired.includes(item)) {
          toast.error(`${item?.split("_")?.join(" ")} is required`, {
            toastId: `${item}Error`,
            delay: 100 * index,
          });
          isError = true;
        }
      });
      if (data["joining_date"]) {
        if (new Date(data["joining_date"]) < new Date()) {
          toast.error("Joining date can't be in past", {
            toastId: "joining_dateError",
            delay: 100 * Object.keys(data).length,
          });
          isError = true;
        }
      }

      // data["city"] = {
      //   location: data["city"]["location"],
      //   id: data["city"]["id"],
      // };

      // if (!isRemote) {
      //   if (!data["city"]?.["location"] || !data["city"]?.["id"]) {
      //     toast.error("City is required", {
      //       toastId: "cityError",
      //       delay: 100 * Object.keys(data).length,
      //     });
      //     isError = true;
      //   }
      // }
      if (isRemote && !remoteOfficeVisit) {
        delete data["city"];
      } else {
        if (!data["city"]) {
          toast.error(`City is required`, {
            toastId: `cityError`,
            delay: 100 * Object.keys(data).length,
          });
          isError = true;
        }
      }
      if (!data["duration"]) {
        data["duration_from"] = Number.parseInt(data["duration_from"]);
        if (data?.duration_to) {
          data["duration_to"] = Number.parseInt(data["duration_to"]);
          if (
            data?.duration_from > data?.duration_to ||
            isNaN(data?.duration_from) ||
            isNaN(data?.duration_to)
          ) {
            toast.error("Invalid duration", { toastId: "duration" });
            isError = true;
          }
        }
      } else {
        data["duration"] = Number.parseInt(data["duration"]);
        if (isNaN(data?.duration)) {
          toast.error("Invalid duration", { toastId: "duration" });
          isError = true;
        }
      }


      // console.log("incentive", data);

      if (data["incentive_from"] && data["incentive_to"]) {
        if (data["incentive_from"] > data["incentive_to"]) {
          toast.error("Stipend should be greater than minimum stipend", {
            toastId: "incentive",
          });
          isError = true;
        }
      }

      if (!isError) {
        handleSubmit(onSubmit(data))();
        updateShouldValidate(1, false, true);
      }
      return () => {
        updateShouldValidate(1, false, false);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldValidate,
    updateShouldValidate,
    handleSubmit,
    getValues,
    unregister,
    dispatch,
    setValue,
  ]);

  React.useEffect(() => {
    if (
      defaultValues?.internship_category === "remote" &&
      defaultValues?.office_visit === true
    ) {
      setRemoteOfficeVisit(true);
      setIsRemote(true);
    } else if (
      defaultValues?.internship_category === "remote" &&
      defaultValues?.office_visit === false
    ) {
      setRemoteOfficeVisit(false);
      setIsRemote(true);
    } else if (!defaultValues?.internship_category) {
      setRemoteOfficeVisit(false);
      setIsRemote(false);
    } else {
      setRemoteOfficeVisit(false);
      setIsRemote(false);
    }
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div className="row mx-0">
        <div className="col-12">
          <TextField
            label="Internship Title"
            required={true}
            id="internship_title"
            placeholder="Full stack developement"
            register={register}
          />
        </div>

        <div className="col-12">
          <TextArea
            label="Roles and Responsibilities"
            required={true}
            id="roles_and_responsibilities"
            placeholder="What are the role/responsibilities of this Internship"
            register={register}
          />
        </div>

        {/* <ChipsField
              register={register}
              required={true}
              label="What skills need to be acquired before the candidates begin the work on the job"
              id="internship_skills"
              items={skills}
              setItems={setSkills}
              deleteItem={deleteItem}
              setValue={setValue}
              path="/v1/filter/skills"
            /> */}
        <div className="col-12">
          <ChipsField2
            register={register}
            required={false}
            label="Preferred City for the Candidates to work in"
            id="internship_city"
            items={internshipCity}
            setItems={setInternshipCity}
            deleteItem={deleteItem}
          />
        </div>

        <div className="col-12">
          <CategoryField
            label="How would you like to categorise this internship?"
            required={true}
            id="internship_category"
            register={register}
            getValues={getValues}
            isRemote={isRemote}
            // setDefaultCheck={setDefaultCheck}
            setIsRemote={setIsRemote}
            defaultCheck={defaultType}
            setDefaultType={setDefaultType}
            // setFlag={setFlag}
            // flag={flag}
            subLable="I would like to offer the candidates atleast one day visit to your office if it is a virtual or feild based internship? "
            remoteOfficeVisit={remoteOfficeVisit}
            setRemoteOfficeVisit={setRemoteOfficeVisit}
          />
        </div>
        {(remoteOfficeVisit || !isRemote) && (
          <div className="col-12">
            <TextFieldWithPopup
              label="Location"
              required={true}
              id="location"
              register={register}
              isPopupOpen={isPopupOpen}
              updatePopup={updatePopup}
              path="/v1/location/city"
              key_name="location"
              setValue={setValue}
              withId="city"
              value={internshipLocation}
              // defaultValue={internshipLocation}
            />
          </div>
        )}

        <div className="col-12">
          <NumberField
            label="Number of interns needed"
            min={1}
            max={10000}
            register={register}
            id="vacancies"
            placeholder="5"
            disabled={true}
            isCounter={true}
            getValues={getValues}
            setValue={setValue}
          />
        </div>

        <div className="col-12">
          <FromTo
            register={register}
            label="Total duration of the internship (Weeks)"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
        <div className="col-12">
          <DateField
            label="Expected start date"
            required={true}
            id="joining_date"
            placeholder="Date"
            register={register}
          />
        </div>

        <div className="incentive col-12">
          <FromTo
            label="Stipend"
            register={register}
            scale=""
            items={defaultItems}
            component="Per Month"
            getValues={getValues}
            setValue={setValue}
            step={1000}
          />
        </div>
        <ChipsFieldWithPopup
          register={register}
          required={false}
          label="Name The Courses From Which You Would Prefer Students To Undertake This Internship"
          id="course_preferred"
          secondaryLabel="In the box below, please mention courses of which students are more suitable for your internship. You can mention multiple courses if required. E.g. MBA - Marketing, B.tech  (Mechanical Engineering) etc."
          items={courses}
          setItems={setCourses}
          deleteItem={deleteItem}
          setValue={setValue}
          path="/v1/filter/courses"
          key_name="course_name"
        />
        <InternshipDetails3
          linkedProject={linkedProject}
          setLinkedProject={setLinkedProject}
        />
      </div>
    </form>
  );
};

export default CreateInternship;
