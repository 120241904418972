import axios from "axios";
const setAuthToken = (token = false) => {
  if (token) {
    axios.defaults.withCredentials = true;
    axios.defaults.validateStatus = () => true;
  } else {
    axios.defaults.withCredentials = false;
    axios.defaults.validateStatus = (status) => {
      return status >= 200 && status < 300; // default
    };
  }
};

export default setAuthToken;