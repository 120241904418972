import React from "react";
import MentorList from "./MentorList";
import StudentGroupSessions from "./StudentGroupSessions";
import NewAllMentors from "../AllMentors/NewAllMentors";

const MySessions = () => {
  return (
    <div className="" style={{backgroundColor: "#EBEFF7"}}>
      <MentorList />

      <StudentGroupSessions />

      <div className="mentor_list">
        <NewAllMentors />
      </div>
    </div>
  );
};

export default MySessions;
