import React from "react"
import "./mentor.scss"
import rahulImg from "../../../../images/rahul.png"
import { Link } from "react-router-dom"
const Mentor = () => {
  return (
    <section className="mentors-profile">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="mentor-content">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                <div className="flexbox">
                  <img src={rahulImg} alt="rahul" width={85} height={85} />
                  <div className="cnt">
                    <h2>
                      Shrikant Sharma 88{" "}
                      <span className="star starshow">
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-regular fa-star-half-stroke"></i>
                      </span>
                    </h2>
                    <p className="light">Marketing Professional 5+ Years</p>
                    <p className="purple">Co-Founder Paytm</p>
                    <p className="hide">
                      <span className="star">
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-solid fa-star"></i>{" "}
                        <i className="fa-regular fa-star-half-stroke"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-12 non">
                <div className="btn-box">
                  <button>UI Designer</button>
                  <button>Figma</button>
                  <button>Landing Page</button>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
                <div className="booking-btn">
                  <p className="btn">₹ 1000 / Session</p>
                  <button>Book Now</button>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
              <p className="para">
                We would like students to help us with creating content that is
                aligned with our vision, mission, and sector. We will ensure the
                student has a clear understanding of what these elements are for
                our organization.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education br">
                <h3>Qualification</h3>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>BCA</strong> - Satnam Collage{" "}
                    </span>{" "}
                  </h4>
                  <p>May 2015 - August 2019</p>
                </div>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>10+2</strong> - Rusk School of Commerece{" "}
                    </span>
                  </h4>
                  <p>August 2014 - November 2015</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Education</h3>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>Web Developer</strong> - Satnam Webtech{" "}
                    </span>{" "}
                  </h4>
                  <p>Project | May 2020 - August 2020</p>
                </div>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>Site Manager</strong> - Rusk Webtech{" "}
                    </span>
                  </h4>
                  <p>Internship | August 2020 - November 2020</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education br">
                <h3>Skills</h3>
                <div className="education-detail">
                  <h4>
                    <strong>Adobe Photoshop </strong>{" "}
                  </h4>
                </div>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> UIUX </strong>
                  </h4>
                </div>
                <div className="education-detail">
                  <h4>
                    <strong> Figma </strong>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Language</h3>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> Hindi </strong>{" "}
                  </h4>
                </div>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> English </strong>{" "}
                  </h4>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education br">
                <h3>Projects</h3>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> Web Developer </strong> - Satnam Webtech
                  </h4>
                  <p>Project | May 2020 - August 2020</p>
                  <p>
                    <Link to="">Https://behance.net/satnamproject</Link>
                  </p>
                </div>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> Web Developer </strong>- Satnam Webtech{" "}
                  </h4>
                  <p>Project | May 2020 - August 2020</p>
                  <p>
                    <Link to="">Https://behance.net/satnamproject</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Documents</h3>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> Student Id </strong>
                  </h4>
                  <p>
                    <Link to="">Https://qollabb.com/rahulnarang</Link>
                  </p>
                </div>
                <div className="education-detail">
                  <h4>
                    {" "}
                    <strong> Resume </strong>{" "}
                  </h4>
                  <p>
                    <Link to="">Https://qollabb.com/resume</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mentor
