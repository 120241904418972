import React from "react"
import "./projectTableMobile.scss"

const ProjectTableMobile = ({ title, checkbox ,setRating1,setRating2,rating1,rating2,setRating3,setRating4,rating3,rating4}) => {
  const data = [
    {
      table_title: "Neatly written, no language or grammer mistakes",
      checkbox_table_title:"Exceeds expectations",
      max_rating: "0-1",
      ratings: ["0", "1"],
      rating_value:"5",
      function1: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating1([...rating1, selectedValue])
      },
      function2: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating2([...rating2, selectedValue])
      },
      function3: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating3(selectedValue)
      },
      function4: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating4(selectedValue)
      },


    },
    {
      table_title: "Length-Not to shert, not too long (50-150 pages)",
      checkbox_table_title:"Pratially exceeds expectation",
      max_rating: "0-1",
      ratings: ["0", "1"],
      rating_value:"4",
      function1: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating1([...rating1, selectedValue])
      },
      function2: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating2([...rating2, selectedValue])
      },
      function3: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating3(selectedValue)
      },
      function4: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating4(selectedValue)
      },
    },
    {
      table_title: "Clearly explains the project and all its elements, sections, output",
      checkbox_table_title:"Meets expectation",
      max_rating: "0-1",
      rating_value:"3",
      ratings:["0","1","2"],
      function1: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating1([...rating1, selectedValue])
      },
      function2: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating2([...rating2, selectedValue])
      },
      function3: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating3(selectedValue)
      },
      function4: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating4(selectedValue)
      },
    },
    {
      table_title: "Overall formatting quality",
      checkbox_table_title:"Pratially meets expectation",
      max_rating: "0-1",
      ratings: ["0", "1"],
      rating_value:"2",
      function1: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating1([...rating1, selectedValue])
      },
      function2: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating2([...rating2, selectedValue])
      },
      function3: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating3(selectedValue)
      },
      function4: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating4(selectedValue)
      },
    },
    {
      table_title: "",
      checkbox_table_title:"Doesn’t meet expectation",
      max_rating: "0-1",
      rating_value:"1",
      ratings:["0","1"],
      function1: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating1([...rating1, selectedValue])
      },
      function2: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating2([...rating2, selectedValue])
      },
      function3: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating3(selectedValue)
      },
      function4: function handleRatingChange(e) {
        const selectedValue = e.target.value
        setRating4(selectedValue)
      },
    },
  ]



  // console.log(data,"data");

  return (
    <section className="projectTable_mobile w-100">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 15%)</small>
      </h4>
      <div className="projectTable_main">
        <div className="projectTable_title">
          <h4>Description of evaluation criteria</h4>
        </div>
        {data.map((item) => {
          return (
            <div className="projectTable_title">
              <h4>{!checkbox ? item?.table_title:item?.checkbox_table_title}</h4>
              <div className="ratings flex">
                <div className="flex gap-2">
                  <p>Max Rating</p>
                  <small>{!checkbox ? item?.max_rating : item?.rating_value}</small>
                </div>
                <div className="devider"></div>
                <div className="flex gap-2">
                  <p>Mentor Rating</p>
                  {!checkbox ? (
                  <>
                      {rating1 &&
                        <select name="" id="" onChange={item?.function1}>
                          {item?.ratings?.map((rate) => {
                            return (
                              <option value={rate}>{rate}</option>
                       )
                     }) }
                        </select>}
                        {rating2 &&
                        <select name="" id="" onChange={item?.function1}>
                      {item?.ratings?.map((rate) => {
                            return (
                              <option value={rate}>{rate}</option>
                       )
                     }) }
                      </select>}
                    </>
                  ) : (
                    <div>
                        {setRating3 && <label>
                          <input
                            type="radio"
                            name="myRadioGroup"
                            value={item?.rating_value}
                            checked={rating4 === item?.rating_value}
                            onChange={item?.function3}
                          />
                          1
                        </label>}
                        {setRating4 && <label>
                          <input
                            type="radio"
                            name="myRadioGroup"
                            value="5"
                            checked={rating4 === "5"}
                            onChange={item?.function4}
                          />
                          1
                        </label>}
                  </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}

        <div className="table_bottom flex">
          <h4>Maximum (5)</h4>
          <div className="score">
            <h4>Score: 05</h4>
            <p>weighted Score: 0.75</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectTableMobile
