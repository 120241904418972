import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AdminBarGraph = ({ data, option,title}) => {
  return (
    <div className="card " style={{ border: "none",height:'100%', }}>
      

      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h6
            className=""
            style={{
              fontWeight: "600",
            }}
          >

           {title}
          </h6>
         
        </div>
  
      </div>

  
        <Bar data={data} options={option}  className="mt-4" />
    

    </div>
  );
};

export default AdminBarGraph;
