import axios from "axios";
import {
    ADD_USER,
    IS_INTEREST_ADDED,
    REMOVE_USER,
    UPDATE_BRANCH,
    UPDATE_USER,
    UPDATE_VERIFIED_OTP,
} from "../constants";
import setAuthToken from "../factories/setAuthToken";

export const addUser = (user) => {

  // console.log("adduserrrrr", user);
  return {
    type: ADD_USER,
    payload: user,
  };
};

export const updateUser = (payload) => {
  // console.log(payload);
  return {
    type: UPDATE_USER,
    payload,
  };
};

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const updateOTP = () => {
  return {
    type: UPDATE_VERIFIED_OTP,
  };
};

export const updateBranch = (payload) => {
  return {
    type: UPDATE_BRANCH,
    payload: payload,
  };
};

export const isInterestAdded = () => {
  return {
    type: IS_INTEREST_ADDED,
  };
};

export const deleteLocale = () => {
  return async () => {
    localStorage.removeItem("user");
  };
};

export const addAccount = ({ user }) => {
  return async (dispatch) => {
    try {
      setAuthToken(true);
      dispatch(deleteLocale());
      dispatch(addUser(user));
      const {
        ceo_name,
        year_of_incorporation,
        employee_strength,
        website_url,
      } = user;
      // dispatch(
      //   initApplication({
      //     address: user?.branch?.address,
      //     company_name: user?.company?.company_name,
      //     ceo_name,
      //     year_of_incorporation,
      //     employee_strength,
      //     website_url,
      //     industry: {
      //       id: user?.industry?.id,
      //       industry_name: user?.industry?.industry_name,
      //     },
      //   })
      // );
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      console.log({ message: error?.message || error });
    }
  };
};

export const logoutUser = ({ cookieDeleted = false }) => {
  // console.log("logout2", "12345");
  return async (dispatch) => {
    try {
      // console.log("logout3", "12345");
      setAuthToken();
      dispatch(deleteLocale());
      dispatch(removeUser());
      if (!cookieDeleted) {
        const {
          data: { data, error },
        } = await axios.delete("/v1/auth/session", {
          validateStatus: () => true,
        });

        if (error) throw new Error("Error removing session");
        // console.log({ message: data });
      }
    } catch (error) {
      console.log({ message: error?.message });
    }
  };
};

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      const { data: reqData } = await axios.get(`/v1/auth/session/create`, {
        validateStatus: () => true,
      });
      // console.log("req data", reqData);
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      // // console.log(data,reqData,"userDara");
      if (!data || error) throw message;
      localStorage.setItem("user", JSON.stringify(data));
      const user = {
        ...data,
        subscriptionStatus: reqData?.myActiveSubscription,
        isOTPVerified: data?.is_email_verified && !data?.is2FA,
        isInterestAdded:
          data?.role !== 4
            ? data?.branch_id
              ? true
              : false
            : data?.is_email_verified,
      };

      // console.log("userrrrr", user);
      dispatch(addUser(user));
      setAuthToken(true);
    } catch (err) {
      dispatch(logoutUser({ cookieDeleted: true }));
    }
  };
};
