import React, { useState } from "react";
import Dropdown from "components/formComponents/post/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import "./exploreFilterBox.scss";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";

const MentorFilterBox = ({ dispatch, state, toggleOFf }) => {
  // const [qualification, setQualification] = useState(() => []);
  const [industry_sector, setIndustrySector] = useState(() => []);
  const [charge1, setCharge1] = useState(() => []);
  const [charge2, setCharge2] = useState(() => []);

  const { handleSubmit, register, setValue, getValues, reset } = useForm();

  // const deleteQualification = (item) => {
  //   setQualification((prev) =>
  //     prev.filter((skill) => skill.course_name !== item)
  //   );
  // };

  const deleteIndustrySector = (item) => {
    setIndustrySector((prev) =>
      prev.filter((skill) => skill.industry_name !== item)
    );
  };

  // console.log(qualification, "hello world");
  const onSubmit = (e) => {
    e.preventDefault();
    // const course_preferred = qualification.map((item) => item?.id);
    const industry = industry_sector.map((item) => item?.id);
    // console.log(getValues("location"), "location");
    const location = getValues("location");

    dispatch({
      type: "change_value",
      payload: {
        // course_preferred,
        industry_sector: industry,
        location: location,
        charge1,
        charge2,
      },
    });

    toggleOFf();
  };

  // console.log(state, "state");

  const onReset = () => {
    // setQualification(() => []);
    setIndustrySector(() => []);
    setCharge1(() => 0);
    setCharge2(() => 0);
    reset();
    dispatch({
      type: "change_value",
      payload: {
        // course_preferred: [],
        industry_sector: [],
        location: "",
        charge1: 0,
        charge2: 0,
      },
    });
  };

  const [isPopupOpen, updatePopup] = useState(() => false);

  return (
    <div className="explore_filterBox ">
      <form>
        {/* <Dropdown
          items={items}
          setItem={updateSort}
          label="Sort by"
          checked={selected}
        /> */}
        <ChipsFieldWithPopup
          register={register}
          label="Industry Sector"
          id="industry_sector"
          items={industry_sector}
          setItems={setIndustrySector}
          deleteItem={deleteIndustrySector}
          setValue={setValue}
          path="/v1/filter/industry_sector"
          placeholder="Search Industry Sector"
          key_name="industry_name"
        />
        {/* <ChipsFieldWithPopup
          register={register}
          label="Qualification"
          id="qualification"
          items={qualification}
          setItems={setQualification}
          deleteItem={deleteQualification}
          setValue={setValue}
          path="/v1/filter/courses"
          placeholder="Choose Qualification"
          key_name="course_name"
        /> */}

        {/* <div className="col-12">
          <TextFieldWithPopup
            label="Location"
            id="location"
            register={register}
            isPopupOpen={isPopupOpen}
            updatePopup={updatePopup}
            path="/v1/location/city"
            key_name="location"
            setValue={setValue}
            withId="city"
            className="location"
            placeholder="Search Location"
          />
        </div> */}
        <div className="charges">
          <div className="charges_text">
            <label htmlFor="">Charges</label>
          </div>
          <div className="charges_input">
            <input
              type="number"
              className="form-control"
              placeholder="Min"
              onChange={(e) => setCharge1(e.target.value)}
            />
            <p className="to">to</p>
            <input
              type="number"
              className="form-control"
              placeholder="Max"
              onChange={(e) => setCharge2(e.target.value)}
            />
          </div>
        </div>

        <div className="cta">
          <button onClick={onReset} type="reset" className="clear">
            Clear
          </button>
          <button onClick={(e) => onSubmit(e)} type="submit" className="apply">
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default MentorFilterBox;
