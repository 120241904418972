import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "styles/employer/jobContent.scss";
import "styles/employer/mentorjob.scss";
import StudentTableContainer from "../../components/StudentTableContainer";
import "./studentDetails.scss";
const StudentDetails =  () => {

    const { id } = useParams();
    // console.log("studennnniddd", id);

    const [studentData, setStudentData] = useState({});
    const [isStudent, setIsStudent] = useState({});
    const navigate = useNavigate();
    // const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [showAiTextPopup, setShowAiTextPopup] = useState(false);
    // const [showAiData, setShowAiData] = useState();
    // const [posts, setPosts, loading] = useOutletContext();

    // const [posts, setPosts,] = useState(()=> ({
    //     Projects: [],
    //     Internships: [],
    //     Jobs: [],
    // }));

    const [type, setType] = useState("project");

    const handleChange = () => {
        const chatApi = async () => {
          try {
            const chatRoomId = await axios.post("/v1/chat/create-chat-room", {
              userId: "",
              role: 4,
              enrollment_type: null,
              enrollment_id: null,
            });
            // console.log(chatRoomId);
          } catch (error) {
            throw new Error();
          }
        };
        
        if(isStudent?.student?.id !== null){
            // chatApi();
            navigate("/educator/chat")
        }else{
            toast.warning("Student Qollabb account required to initiate chat.");
        }
      };

      const gotoProfile = () => {

        if(isStudent?.student?.id !== null){
            navigate(`/educator/student-profile/${isStudent?.student?.id}`);
        }
        else{
            toast.warning("Student Qollabb account is required to view their profile")
        }
      }

    // // console.log("post", posts);

    useEffect(()=> {

        const getStudentDetails = async ()=>{
            // console.log("sttuudee use effect")
            try {
                
                const res = await axios.get(`/v1/educator/invite/uploaded_student/${id}`)
    
                // console.log("student data", res.data)
                setStudentData(res?.data);

                setIsStudent(res?.data?.student)
            } catch (error) {
                // console.log("errr")
            }

        }

        // const getDetails = async () =>{

        //     const internship = get(`/v1/educator/studentInternships/${id}`);
        //     const job = get(`/v1/educator/studentJobs/${id}`);
        //     const project = get(`/v1/educator/studentProjects/${id}`);
      
        //     await axios
        //       .all([internship, job, project])
        //       .then(
        //         axios.spread((...responses) => {
        //             // console.log("response", responses)
        //           const internships = responses[0]?.data?.data || [];
        //           const jobs = responses[1]?.data?.data  || [];
        //           const projects = responses[2]?.data?.data  || [];

        //           setPosts((p) => ({
        //             ...p,
        //             Internships: internships,
        //             Jobs: jobs,
        //             Projects: projects,
        //           }));
        //         //   setLoading(false);
        //         })
        //       )

              
        // }
              
        // const getProjectDetails = async ()=>{
        //     const res = await axios.get(`/v1/educator/studentProjects/${id}`)

        //     // console.log("student Project data...", res.data)
        //     setStudentProjects(res?.data)
        // }
        // const getInternshipDetails = async ()=>{
        //     const res = await axios.get(`/v1/educator/studentInternships/${id}`)

        //     // console.log("student Internship data...", res.data)
        //     // setStudentProjects(res?.data)
        // }
        // const getJobDetails = async ()=>{
        //     const res = await axios.get(`/v1/educator/studentJobs/${id}`)

        //     // console.log("student Job data...", res.data)
        //     // setStudentProjects(res?.data)
        // }

     getStudentDetails();
        // getDetails();

    }, [])
    
    // // console.log("all Details", posts)

    // console.log("isStudent", isStudent);

    // console.log("sData", studentData?.data)
    return (
        <section className="job-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="content-creation mobo_res">
                            <div className="content-body ">
                                <div className="">
                                    <h2>{studentData?.data?.name}</h2>
                                    <p className="light">{studentData?.data?.course}</p>
                                </div>
                                {/* <div className="col-1 non"></div> */}

                                    <div className="side-buttons col-md-6">
                                        {/* <Link to={`/educator/chat`}> */}
                                            <button className="" style={{
                                                color: "#47D065",
                                            }}
                                            onClick={handleChange}
                                            >
                                            {/* <i className="fa-solid fa-arrow-right-long"></i> */}
                                            <i className="fa-regular fa-comment-dots button-green"></i>
                                            Go to Chat{" "}
                                            </button>
                                        
                                        {/* </Link> */}

                                        {/* <Link to={"/educator/student-profile/4"}> */}
                                            <button type="" onClick={gotoProfile}>
                                            {/* <i className="fa-regular fa-copy"></i> */}
                                            <i class="fa-regular fa-user"></i>
                                            View Profile
                                            </button>
                                        {/* </Link> */}
                                        {/* <button className="close" onClick={handleClose}>
                                        Close Opening <i className="fa-solid fa-xmark"></i>
                                        </button> */}
                                    </div>
                            </div>
                        </div>


                        <div className="project-tabs">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <StudentTableContainer
                                    setType={setType}
                                    // // posts={posts}
                                    // isPopupOpen={isPopupOpen}
                                    // setIsPopupOpen={setIsPopupOpen}
                                    // setRefreshTable={setRefreshTable}
                                    // refreshTable={refreshTable}
                                    // id={id}
                                />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StudentDetails


// {
//     id: 2,
//     educator_id: 3,
//     student_email: 'shraddha@gmail.com',
//     name: 'shraddha jain',
//     course: 'B.sc',
//     specialization: 'mechanical',
//     semester: 1,
//     createdAt: 2023-10-30T05:36:13.203Z
//   }