import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Chips from "./Chips";
import Popup from "./Popup";
import "./newPopup.scss";

const ChipsFieldWithPopup = ({
  label = "",
  id = "",
  placeholder = "",
  required = false,
  register,
  options = {},
  disabled = false,
  items = [],
  setItems = () => {},
  deleteItem,
  secondaryLabel = "",
  setValue,
  path,
  inputValue,
  setInputValue,
  key_name = "name",
  className = "",
  labelClass = "",
  reverse = false,
  flag = false,
}) => {
  const [data, setData] = useState(() => []);

  const [focus, setFocus] = useState(() => 0);
  const inputRef = useRef();
  const [requests, setRequests] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [location, setLocation] = useState(() => []);

  // console.log("chipfileds",items)
  // console.log("path",path)

  const updateItems = (item) => {
    try {
      // if (flag && item.length >= 1) throw new Error("Only one item allowed");
      if (flag && items.length >= 1) {
        throw new Error("Only one item allowed");
      }
      if (items.length >= 5) throw new Error("Maximum 5 items allowed");
      if (items.some((obj) => obj[key_name] === item[key_name]))
        throw new Error("Item already exists");
      setItems((items) => [...items, item]);
      setInputValue("");
    } catch (error) {
      toast.error(error?.message || error, { toastId: "updateItems" });
    }
  };

  useEffect(() => {
    if (!inputValue) return;
    const getResponse = async () => {
      const response = await axios.get(path, {
        params: { name: inputValue },
        validateStatus: (status) => status < 500,
      });
      // // console.log(response?.data?.data,"hello bye")
      setRequests((prev) => ({
        ...prev,
        [inputValue]: response?.data?.data || [],
      }));

      // console.log("filterrrdataa", response?.data?.data);
      setData(response?.data?.data || []);
    };

    // // console.log(data,"hello bye")
    if (!requests[inputValue]) {
      if (
        requests[inputValue?.slice(0, -1)] &&
        !requests[inputValue?.slice(0, -1)].length
      ) {
        setRequests((prev) => ({ ...prev, [inputValue]: [] }));
        return;
      } else getResponse();
    } else setData(requests[inputValue]);
  }, [inputValue, requests, path]);

  const updatePopup = (value) => {
    setIsPopupOpen(value);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInputValue(value); //b

    if (value.length > 0) {
      setFocus(1);
      updatePopup(true);
      const filteredData = data?.filter((item) => {
        return item[key_name]?.toLowerCase().includes(value.toLowerCase());
      });

      setData(filteredData);
      if (filteredData?.length && filteredData[0][id] === value) {
        // console.log(filteredData[0].id, "hello bye");
        setValue(id, filteredData[0].id);
        // setValue(id, filteredData[0].id);
      }
    } else {
      updatePopup(false);
      setData([]);
    }
  };

  const closePopup = () => {
    updatePopup(false);
  };

  const handleKey = (e) => {
    if (!data.length) return;
    setValue(id, 0);
    let focusCounter = focus;
    if (e.key === "ArrowDown") {
      if (focusCounter < data.length) focusCounter++;
    } else if (e.key === "ArrowUp") {
      focusCounter > 0 && focusCounter--;
    } else if (e.key === "Enter") {
      const value = data[focusCounter - 1];
      e.preventDefault();
      inputRef.current.value = value;
      setInputValue(value[key_name]);
      updatePopup(false);
      setValue(id, value[key_name]);
      console.log("inshahshahhahavaa", value);
      setItems(() =>  [value])
      // if (withId) setValue(withId, data[focusCounter - 1]);
      setFocus(0);
    } else {
      return;
    }
    setFocus(focusCounter);
  };

  const updateValue = (item) => {
    // console.log("itmeeeeeeeeeee", item);
    inputRef.current.value = item[key_name];
    setValue(id, item[key_name]);
    setInputValue(item[key_name]);
    setItems(() =>  [item])
    // if (withId) setValue(withId, item);
    updatePopup(false);
    closePopup();
  };

  // console.log("dataaaaammmm", data);

  return (
    <div className=" new_input_popup place_container" onKeyDown={handleKey}>
      <div className="form-group mb-3">
        <label htmlFor={id} className={`${labelClass}`}>
          {label}
          {required && <span>*</span>}
          {secondaryLabel && (
            <small className="form-text text-muted">{secondaryLabel}</small>
          )}
        </label>

            <div className="item-container">
              <input
                className={` mt-1 new_text_field ${className}`}
                type="text"
                name={id}
                id={id}
                placeholder={placeholder}
                {...register(id, { required, ...options })}
                onChange={onChange}
                value={inputValue}
                ref={inputRef}
                autoComplete={"off"}
                disabled={disabled}
              />
              {isPopupOpen ? (
                <Popup
                  data={data}
                  input_value={inputValue}
                  focus={focus}
                  updateValue={updateValue}
                  key_name={key_name}
                  closePopup={closePopup}
                />
              ) : (
                <></>
              )}
            </div>
      </div>
    </div>
  );
};

export default ChipsFieldWithPopup;

/*
   <div className=" new_input_popup mb-1 place_container" onKeyDown={handleKey}>
      {label ? (
        <label htmlFor={id} className="">
          {label}
          {required && <span className={`${className1}`}>*</span>}
          {secondaryLabel && <small>{secondaryLabel}</small>}
        </label>
      ) : null}

      <input
        className={` mt-1 new_text_field ${className}`}
        type="text"
        name={id}
        id={id}
        placeholder={placeholder}
        {...register(id, { required, ...options })}
        onChange={onChange}
        ref={inputRef}
        autoComplete={"off"}
        disabled={disabled}
        value={defaultValue}
        defaultValue={inputValue}
      />
      {isPopupOpen && (
        <Popup
          data={data}
          input_value={inputValue}
          focus={focus}
          updateValue={updateValue}
          key_name={key_name}
          closePopup={closePopup}
        />
      )}
    </div>


*/


