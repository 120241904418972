import React, { useEffect } from "react";
import PartnerInstitutions from "./listOfPartnerCompanies/ListOfPartnerCompanies";
import SuggestCompanies from "./suggestCompanies/SuggestCompanies";
import InvitationSent from "./InvitationsSent/InvitationSent";
import InvitationRecieved from "./invitationRecieved/InvitationRecieved";

const NewComponent = ({
  step,
  institutions,
  company,
  colleges,
  recievedCompany,
  setRefresh,
  onFilterChange
}) => {

  useEffect(() => {
    setRefresh();
  }, [onFilterChange, setRefresh]);

  switch (step) {
    case 2:
      return (
        <PartnerInstitutions
          setRefresh={setRefresh}
          institutions={institutions}
        />
      );
    case 1:
      return <SuggestCompanies setRefresh={setRefresh} company={company} onFilterChange={onFilterChange} />;
    case 3:
      return <InvitationSent setRefresh={setRefresh} colleges={colleges} />;
    case 4:
      return (
        <InvitationRecieved setRefresh={setRefresh} company={recievedCompany} />
      );
    default:
      return (
        <PartnerInstitutions
          setRefresh={setRefresh}
          institutions={institutions}
        />
      );
  }
};

export default NewComponent;
