import axios from "axios";
import Datatable from "components/datatable-new/DatatableNew";
import DeactivatePost from "components/employer/DeactivatePost";
import StatusButton from "components/employer/StatusButton";
import DateField from "components/formComponents/DateField";
import TextField from "components/formComponents/TextField";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "../style.scss";

const AdminCreateCoupons = () => {
  const { register, handleSubmit, reset } = useForm();
  // const activeCoupons = [
  //   {
  //     coupon_name: "QOLLAB20",
  //     date_start: "20/11/2022",
  //     date_end: "20/12/2022",
  //     limits: "100",
  //     usage: "100",
  //     status: "Active",
  //   },
  //   {
  //     coupon_name: "QOLLAB20",
  //     date_start: "20/11/2022",
  //     date_end: "20/12/2022",
  //     limits: "100",
  //     usage: "100",
  //     status: "Active",
  //   },
  //   {
  //     coupon_name: "QOLLAB20",
  //     date_start: "20/11/2022",
  //     date_end: "20/12/2022",
  //     limits: "100",
  //     usage: "100",
  //     status: "Active",
  //   },
  //   {
  //     coupon_name: "QOLLAB20",
  //     date_start: "20/11/2022",
  //     date_end: "20/12/2022",
  //     limits: "100",
  //     usage: "100",
  //     status: "Closed",
  //   },
  //   {
  //     coupon_name: "QOLLAB20",
  //     date_start: "20/11/2022",
  //     date_end: "20/12/2022",
  //     limits: "100",
  //     usage: "100",
  //     status: "Closed",
  //   },
  // ];

  const [loading, setLoading] = React.useState(false);

  const map = {
    true: "Active",
    false: "Deactivated",
  };

  const map2 = {
    true: 2,
    false: 3,
  };

  const [refresh, setRefresh] = React.useState(false);
  const closePost = async (id) => {
    try {
      const response = await axios.put(`/v1/admin/interests/couponss/${id}`, {
        isActive: false,
      });
      // console.log(response.data);
      toast.success("Coupon deactivated successfully");
    } catch (err) {
      // console.log(err.message);
      toast.error("Something went wrong");
    } finally {
      setRefresh(!refresh);
    }
  };

  const activatePost = async (id) => {
    try {
      const response = await axios.put(`/v1/admin/interests/couponss/${id}`, {
        isActive: true,
      });
      // console.log(response.data);
      toast.success("Coupon activated successfully");
    } catch (err) {
      console.log(err.message);
      toast.error("Something went wrong");
    } finally {
      setRefresh(!refresh);
    }
  };

  const [activeCoupons, setActiveCoupons] = React.useState([]);

  useEffect(() => {
    const getActiveCoupons = async () => {
      try {
        const response = await axios.get("/v1/admin/interests/coupons");
        // // console.log(response, "hello world");

        setActiveCoupons(response.data.data);
      } catch (err) {}
    };

    getActiveCoupons();
  }, [refresh]);

  const columns = [
    {
      Header: "Coupon Name",
      accessor: "coupon_name",
      width: "16%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.coupon_name}
          </strong>
        );
      },
    },
    {
      Header: "Date start",
      accessor: "date_start",
      width: "16%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date_start}
          </strong>
        );
      },
    },
    {
      Header: "Date end",
      accessor: "date_end",
      width: "16%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date_end}
          </strong>
        );
      },
    },
    // {
    //   Header: "Limits",
    //   accessor: "limits",
    //   width: "16%",
    //   textalign: "center",
    // },
    {
      Header: "Max Usage",
      accessor: "usage",
      width: "16%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.usage}
          </strong>
        );
      },
    },
    {
      Header: "Discount %",
      accessor: "discount",
      width: "16%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.discount}
          </strong>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "20%",
      textalign: "center",
      Cell: (data) => {
        return <StatusButton status={map[data.row.original.isActive]} />;
      },
    },
    {
      Header: " ",
      width: "1%",
      sortable: false,
      Cell: (data) => {
        return (
          <DeactivatePost
            status={map2[data.row.original.isActive]}
            projectid={data.row.original.id}
            method={"project"}
            closePost={() => closePost(data.row.original.id)}
            activatePost={() => activatePost(data.row.original.id)}
            flag={true}
          />
        );
      },
    },
  ];

  const rows = activeCoupons?.map((coupon) => {
    return {
      id: coupon.id,
      coupon_name: coupon.coupon_code,
      date_start: moment(coupon.startDate).format("DD/MM/YYYY"),
      date_end: moment(coupon.expirationDate).format("DD/MM/YYYY"),
      // limits: coupon.limits,
      usage: coupon.maxUses,
      isActive: coupon.isActive,
      discount: coupon.discountPercentage,
    };
  });

  const handleSubmitForm = async (data) => {
    try {
      setLoading(true);
      Object.keys(data).forEach((key) => {
        if (data[key] === "" || !data[key]) {
          throw new Error(`${key} is required`);
        }
      });

      data.discount = parseFloat(data.discount);
      data.multiple_usage_per_user = parseInt(data.multiple_usage_per_user);

      if (!data.discount) {
        throw new Error(`Discount should be a number`);
      }

      if (!data.multiple_usage_per_user) {
        throw new Error(`Multiple usage per user should be a number`);
      }

      const response = await axios.post("/v1/admin/interests/coupons", data);

      toast.success("Coupon created successfully");
      reset();
    } catch (err) {
      toast.error(err.message);
      return;
    } finally {
      setLoading(false);
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <div className="create-coupons">
        <div className="create-coupon-container p-3">
          <form>
            <div className="row mx-0 d-flex align-items-end">
              <div className="col-md-2 col-12">
                <TextField
                  label="Coupon Name"
                  required={false}
                  id="coupon_name"
                  placeholder=""
                  register={register}
                  // disabled={true}
                />
              </div>

              <div className="col-md-2 col-12">
                <DateField
                  label="Date start"
                  required={false}
                  id="date_start"
                  placeholder="Date"
                  register={register}
                />
              </div>
              <div className="col-md-2 col-12">
              {/* <div className="col-2"> */}
                <DateField
                  label="Date end"
                  required={false}
                  id="date_end"
                  placeholder="Date"
                  register={register}
                />
              </div>
              <div className="col-md-2 col-12">
                <TextField
                  label="Discount %"
                  required={false}
                  id="discount"
                  placeholder=""
                  register={register}
                  // disabled={true}
                />
              </div>
              <div className="col-md-2 col-12">
                <TextField
                  label="Multiple usage per user"
                  required={false}
                  id="multiple_usage_per_user"
                  placeholder=""
                  register={register}
                  // disabled={true}
                />
              </div>
              <div className="col-md-2 col-12">
                <button
                  onClick={handleSubmit(handleSubmitForm)}
                  type="submit"
                  className="btn btn-primary mb-3 w-100"
                >
                  Create Coupon
                </button>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  reset();
                }}
                className="col-md-2 col-12"
              >
                <button className="btn btn-outline-secondary mb-3 w-100">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="py-5">
          <h4 className="mb-3">Active Coupons</h4>
          <div className="active-coupons-table card">
            <Datatable columns={columns} data={rows} />
          </div>
        </div>
      </div>

      <>
        {loading && (
          <div className="loading-container">
            <div className="text-center loading-wrapper">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default AdminCreateCoupons;
