import React, { useState } from "react"
import AdminCompanySubscription from "./admin-company-subscription"
import AdminInstituteSubscription from "./admin-institute"
import AdminStudentSubscription from "./admin-student-subscription"

const AdminSubscriptions = () => {
  const tabs = ["Company", "Institution", "Student"]
  const [activeTab, setActiveTab] = useState(() => 0)
  // console.log(typeof activeTab)
  return (
    <div
      className="content-container py-5 pt-2"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="table-tabs">
            <nav>
              <div
                className="nav_tabs nav nav-tabs"
                id="nav-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <button
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className={`nav-link ${
                      activeTab === index ? "active" : ""
                    }`}
                    id={`nav-${tab}-tab`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${tab}`}
                    aria-selected="true"
                    onClick={() => setActiveTab(index)}
                    key={tab}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </nav>

            <div
              className="tab-pane fade show active"
              id={`nav-${activeTab}`}
              role="tabpanel"
              aria-labelledby={`nav-${activeTab}-tab`}
            >
              <LoadTable activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSubscriptions

const LoadTable = ({ activeTab, data }) => {
  switch (activeTab) {
    case 0:
      return <AdminCompanySubscription />
    case 1:
      return <AdminInstituteSubscription />
    case 2:
      return <AdminStudentSubscription />
    default:
      return <AdminCompanySubscription />
  }
}
