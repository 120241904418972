import React, { useReducer, useState } from "react";
import "./mentorProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import NewInputImage from "components/image-uploader/New_Image_uploader/NewInputImage";
import TextInput from "components/formComponents/TextInput";
import PhoneInput from "react-phone-input-2";
import EmailField from "components/formComponents/EmailField";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import TextArea from "components/formComponents/TextArea";
import Dropdown from "components/formComponents/post/Dropdown/Dropdown";
import DateField from "components/formComponents/DateField";
import LocationPopup from "components/formComponents/LocationPopup";
import AddMentorSkills from "./add_skills/AddMentorSkills";

const MentorDetails = ({ register, setValue, setImage, getValues ,setSkills,skills}) => {
  const [isPopupOpen, updatePopup] = useState(() => false);
  const [isSecondPopupOpen, updateSecondPopup] = useState(() => false);
  const user = useSelector((state) => state.auth.user);

  const [gender, setGender] = useState(user?.gender || "");

  const updateGender = (value) => {
    setGender(parseInt(value));
    setValue("gender", value);
  };
  // console.log(gender, "gendee");
  const dispachdata = useDispatch();

  const [languages, setLanguages] = useState(() => user?.languages || []);
  const langs = [  
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Nepali",
    "Odia",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu"
  ]

  const updateLanguages = (value) => {
    setLanguages((prev) => {
      if (prev.includes(langs[value])) {
        return prev.filter((item) => item !== langs[value]);
      }
      return [...prev, langs[value]];
    });
  };

  // const saveLanguage = (languages) => {
  //   dispatch(updateUser(languages))
  // }
  setValue("languages", languages);

  const initialState = {
    title: "",
    link: "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);
  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };
  const handleChange = (e) => {
    dispachdata({
      value: e.target.value,
    });
  };

  // console.log(getValues("industry_sector"));
  

  return (
    <div className="row MentorDetails_container">
      <div className="col-12">
        <div className="col-2">
          {/*<InputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_photo}
          />*/}
          <NewInputImage url={user?.profile_photo} setImageFile={setImage} />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="First Name"
          id="first_name"
          labelClassName="fieldLabel"
          register={register}
          className="profileInputField"
          placeholder={user?.first_name}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Last Name"
          id="last_name"
          register={register}
          placeholder={user?.last_name}
          className="profileInputField"
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-group mb-3 w-100 text_label">
          {" "}
          Contact Number
          <PhoneInput
            country={"in"}
            placeholder="Enter phone number"
            onChange={(e) => setValue("phone_number", e)}
            defaultCountry="IN"
            onlyCountries={["in"]}
            name="phone_number"
            specialLabel={""}
            containerClass={" mt-2"}
            inputStyle={{ marginTop: "10px" }}
            inputClass={"form-control profileInputField"}
            value={state?.phone_number || user?.phone_number}
          />
        </label>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <EmailField
          label="Email"
          id="email_address"
          register={register}
          className="email-disabled"
          disabled={true}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        {/* <DropdownInput
          label="Gender"
          name="Select"
          register={register}
          value="Select"
          value1="Male"
          value2="Female"
          value3="Others"
          className="profileInputField"
        /> */}
        <Dropdown
          items={["Male", "Female", "Others"]}
          checked={gender}
          label="Gender"
          className="profileDropdown"
          setItem={updateGender}
        />
      </div>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Change Password"
          id="password"
          register={register}
          // placeholder={user?.password}
          className="profileInputField"
        />
      </div> */}
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={langs}
          checked={languages}
          label="Language"
          className="profileDropdown"
          setItem={updateLanguages}
          isMultiple={true}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Industry Sector "
          id="industry_sector"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/filter/industry_sector"
          key_name="industry_name"
          setValue={setValue}
          withId="industry"
          className="profileInputField"
          value={user?.industry?.industry_name}
        />
        {/* <TextFieldWithPopup
          label="Industry Sector"
          required={true}
          id="industry_sector"
          placeholder="Type Your Industry Sector"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/filter/industry_sector"
          key_name="industry_name"
          setValue={setValue}
          withId="industry"
          value={user?.industry_sector || ""} */}
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Industry Experience"
          id="industry_year_expreience"
          register={register}
          className="profileInputField"
        />
      </div>


      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isSecondPopupOpen}
          updatePopup={updateSecondPopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          className="profileInputField"
          value={user?.location}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <AddMentorSkills
        setSkills={setSkills} 
        skills={skills} />
        </div>

      {/* <div className="col-lg-6 col-md-6 col-sm-12">
          <TextInput
            label="Your Charge"
            id="mentor_charges"
            register={register}
            className="profileInputField"
              />
          </div> */}

      <TextArea
        label="Bio (max 1000 characters)"
        id="bio"
        register={register}
        labelClass="profileSectionLabel"
        className="profileInputField"
      />

      <div className="row personal_details_container w-100 mt-4">
        <label className="profileSectionLabel">Profile Links</label>

        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Linkedin"
            id="linkedIn_link"
            register={register}
            className="profileInputField"
          />
          <TextInput
            label="Portfolio Link"
            id="profile_link"
            register={register}
            className="profileInputField"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Github"
            id="github_link"
            register={register}
            className="profileInputField"
          />
        </div>
      </div>
    </div>
  );
};

export default MentorDetails;
