import axios from 'axios';
import MentorMobileDataTable from 'components/datatable-new/mentorMobileDataTable/MentorMobileDataTable';
import Loader from 'components/employer/Loader';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import "./mentoringSession.scss";
import { useNavigate } from 'react-router-dom';
import GroupSessions from './GroupSessions';

const MentoringSession = () => {
    const [getData, setGetData] = useState([]);
  const [getSessionData, setGetSessionData] = useState([]);
  const [today, setToday] = useState("");
  const [status,setStatus] = useState([])

  const [timeZones, setTimeZones] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [Reschedule, setReschedule] = useState({});
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user)

  const minDate = new Date().toISOString().split("T")[0]; // get current date

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()]; // get the day of the week in string format
    setSelectedDate(date.toISOString().split("T")[0]);
    // console.log(date, user, "Filtered");
    const selectedTimezones = user.timings.find(
      (item) => item?.day === dayOfWeek.toLocaleUpperCase()
    );
    // console.log("timezones",selectedTimezones);
    const timeslots = convertTimeSlotsToDateSlots(
      date,
      selectedTimezones?.duration
    );
    // console.log(timeslots, "timeslots");
    setTimeZones(timeslots);
  };

// console.log("zones", timeZones)

  function convertTimeSlotsToDateSlots(date, timeSlots) {
    const dateObj = new Date(date);
    const dateSlots = {};

    timeSlots?.forEach((timeSlot) => {
      const [startTimeStr, endTimeStr] = timeSlot.split("-");
      const startTime = new Date(dateObj.toDateString() + " " + startTimeStr);
      const endTime = new Date(dateObj.toDateString() + " " + endTimeStr);
      const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); // duration in minutes

      let slotStartTime = startTime;
      while (slotStartTime < endTime) {
        const slotEndTime = new Date(slotStartTime.getTime() + 30 * 60 * 1000); // add 30 minutes
        const slotKey = `${slotStartTime.getHours()}:${slotStartTime.getMinutes()}-${slotEndTime.getHours()}:${slotEndTime.getMinutes()}`;
        dateSlots[slotKey] = { startTime: slotStartTime, endTime: slotEndTime };
        slotStartTime = slotEndTime;
      }
    });

    return dateSlots;
  }

  const handleKeyClick = (key) => {
    setSelectedTime(key);
  };


  const HandleReschedule = async (id)=> {
    
    const time = {
      startTime: timeZones[selectedTime]?.startTime,
      endTime: timeZones[selectedTime]?.endTime,
    }

    const ids = {
      id: id.id,
      student_id: id.student_id,
      mentor_id: id.mentor_id,
    }
    const res = await axios.put("/v1/mentor/sessionReschedule", {
      ids,
      status: 2,
      time: time,
    })

    // console.log("update", res)
    // setStatus("2");
    if(!res?.data?.error){
      toast.success(res?.data?.message)
      setReschedule({status: "2"})
    }
    else{
      toast.error(res?.data?.message)
      setReschedule({status: "2"})
    }
    
  }


  async function joinSession(e, item) {
    e.preventDefault();
    // if (new Date(item?.startTime).getTime() === new Date().getTime()) {
    if (1) {
      navigate(`/mentor/session/${item?.id}/${item?.roomId}`);
    } else {
      toast.warning("Your Session Haven't Started Now")
    }
  // }
  }


  useEffect(() => {
    const date = new Date();
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options).replace(/\//g, "-");
    setToday(formattedDate);
  }, [today]);

  // console.log(today, "today");
  
  
    useEffect(() => {
      setLoading(true)
      axios.post("/v1//mentor/getSessions",{session_date: today})
        .then(response => {
          setGetData(response?.data?.data);
          setLoading(false)
        })
        .catch(error => {
          console.error(error);
          setLoading(false)
        });
    }, [today]);

    useEffect(() => {
      setLoading(true)
      axios.post("/v1//mentor/getSessions",{status:status})
        .then(response => {
          setGetSessionData(response?.data?.data);
          setLoading(false)
        })
        .catch(error => {
          console.error(error);
          setLoading(false)
        });
    }, []);
  
  
    const StatusChange = async (id, status) => {
      // if (status < 1 || status > 2) return toast.error("Invalid status")
      const { error, message } = await axios.put(`/v1/mentor/updateSessionStatus/${id}`,null, {
        params: {
          status: status,
        }
      })
      toast.success("ReSheduled Session")
      if (error) {
        toast.error(message)
      } else {
        // toast.success("reShedule")
      }
      // handleBlur()
    }

  // console.log(getData,"getData",getSessionData,status);

    if(loading) return <Loader />;

  return (
      <div>
      <div className='project_evaluationTop'>
       
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <h3 className='fw-bold'>Mentoring Session</h3>
                  <div className='student-enroll-main d-flex gap-md-4 flex-wrap'>
                  <div className="student-enroll">
                  <div className="flex">
                    <h3>Sessions this month</h3>
                    <p className="flex">
                      <span className="h3 mt-2">2,115</span>{" "}
                      <span className="des-color row">
                        <i className="fa-solid fa-sort-up"></i>
                        <b>+1 today</b>
                      </span>
                    </p>
                  </div>
                  </div>
                  
                  <div className="student-enroll">
                  <div className="flex">
                    <h3>Pending this month</h3>
                    <p className="flex">
                      <span className="h3 mt-2">2,115</span>{" "}
                    </p>
                  </div>
                </div>
                  </div>
                
              </div>

              
          </div>
          <div className="px-4">
          {/* Group sessions */}
            <GroupSessions user={user} />



          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="session-request-table">
              <div className="flex">
                <h3>Today’s Sessions</h3>{" "}
                <p className='d-none'>
                  See all <i className="fa-solid fa-arrow-right-long"></i>
                </p>
              </div>
              <div className="table-responsive d-none d-sm-flex">
               {getData?.length > 0 ? 
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Student Name</th>
                      <th scope="col">Project Name</th>
                      <th scope="col">Timings</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                 
                  <tbody>
                    {getData?.map((item) => {
                      return (
                        < tr >
                      <td>{item?.student?.first_name} {item?.student?.first_last}</td>
                      <td className="">{item?.enrollment?.project?.project_title}</td>
                          <td className="red">{new Date(item?.startTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }</td>
                      <td>
                      <button className="btngreen" onClick={(e) => { joinSession(e, item) }}>Join</button>{" "}
                          </td>
                          </tr>
                      )
                    })}
                   
                  </tbody>
                </table>:<div className="w-100 text-center pb-2">No session today</div>}
              </div>
              <div className="student_mobo_table1 d-block d-sm-none">
              {getData.length>0 ? <MentorMobileDataTable data={getData} type={"todaySession"} joinSession={joinSession} />:<>No session today</>}
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="session-request-table">
              <div className="flex">
                <h3> Session Requests </h3>{" "}
                <span className='d-none'>
                  See all <i className="fa-solid fa-arrow-right-long"></i>
                </span>
              </div>
              <div className="table-responsive d-none d-sm-flex">
                 {getSessionData.length>0? <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Student Name</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Date</th>
                      <th scope="col">Timings</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getSessionData?.map((item) => {
                      function formatTime(timeString) {
                        const date = new Date(timeString);
                        let hours = date.getHours();
                        let minutes = date.getMinutes();
                        const ampm = hours >= 12 ? 'pm' : 'am';

                        hours = hours % 12;
                        hours = hours ? hours : 12; // Handle midnight (0 hours)

                        // Add leading zero to minutes if needed
                        minutes = minutes < 10 ? '0' + minutes : minutes;

                        const formattedTime = hours + ':' + minutes + ampm;
                        return formattedTime;
                      }
                      return (
                        <tr>
                          <td>{item?.student?.first_name} {item?.student?.first_last}</td>
                          <td className="" 
                            style={{maxWidth: "250px"}}
                          >{item?.enrollment?.project?.project_title}</td>
                          {/* <td>{new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                          <td className="red">{formatTime(item?.startTime)}</td> */}
                         
                            {
                              Reschedule?.id === item?.id ? (
                              // status === "1" ? (
                                <>
                                <td>
                                  <input
                                    className="date_style form-control"
                                    style={{
                                      marginTop: "5px",
                                      maxWidth: "300px"
                                    }}
                                    id="dateInput"
                                    placeholder="dd-mm-yyyy"
                                    value={selectedDate}
                                    type="date"
                                    min={minDate}
                                    onChange={handleDateChange}
                                  />
                                  </td>
                                  {
                                    !selectedDate ?
                                    <td>{""}</td> : ""

                                  }
                                  {selectedDate && (
                                    <td>
                                    <div className="time_dropdown time-row py-2 px-2 my-2 mx-auto"
                                    style={{
                                      maxWidth: "350px"
                                    }} >
                                      {!Object.keys(timeZones).length == 0 ? (
                                        <>
                                          {Object.keys(timeZones).map((key) => {
                                            return (
                                              <div key={key} className="col-3 gap-1">
                                                <span
                                                  style={{
                                                    backgroundColor:
                                                      selectedTime === key ? "#6149cd" : "",
                                                    color: selectedTime === key ? "#fff" : "",
                                                    cursor: "pointer",
                                                  }}
                                                  className="duration text-center w-100 my-6 p-1 gap-4 rounded fw-light"
                                                  onClick={() => handleKeyClick(key)}
                                                >
                                                  {key}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <p>Mentor is not available on this day</p>
                                      )}
                                    </div>
                                    </td>
                                  )}

                                  {/* <td>
                                    {""}
                                  </td> */}

                                <td>
                                  <button onClick={() => HandleReschedule({id: item.id, student_id: item.student_id, mentor_id: item.mentor_id})} className="btnorg">Update</button>
                                </td>                                
                                </>
                              )
                              :
                              (<>
                                <td>{new Date(item?.startTime).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                                <td className="red">{formatTime(item?.startTime)}</td>
                                <td>
                                <button onClick={() => setReschedule({id: item?.id})} className="btnred">Reschedule</button>
                                </td>
                              </>
                              )
                            }

                        </tr>
                      )
                    })}
                   
                  </tbody>
                </table>:<div className="w-100 text-center pb-2">No session available</div>}
             
              </div>
              <div className="student_mobo_table2 d-block d-sm-none">
                {getSessionData.length>0?<MentorMobileDataTable data={getSessionData} selectedDate={selectedDate} handleDateChange={handleDateChange} handleKeyClick={handleKeyClick} Reschedule={Reschedule} setReschedule={setReschedule} HandleReschedule={HandleReschedule} timeZones={timeZones} selectedTime={selectedTime} type={""} joinSession={joinSession}  />: <p>No session available</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="box1">
                  {/* <h3>Student Assesments</h3> */}
                  <div className="planbox"></div>
                </div>
              </div>
            </div>
          </div>


        </div>
    </div>
  )
}

export default MentoringSession