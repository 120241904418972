import PreviewField from "components/employer/PreviewField"
import useFetch from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import "./applicationInternshipDetail.scss"
import PostDetailsSection from "components/posts/PostsHelper/PostDetailsSection"
import LinkedProjectCard from "components/posts/PostsHelper/LinkedProjectCard"
import RightCard from "components/posts/PostsHelper/RightCard"
import axios from "axios"

const ApplicationInternshipDetail = () => {
  
  const [moreInternships, setMoreInternships] = useState([]);
  const [similarInternships, setSimilarInternships] = useState([]);

  const { id } = useParams()
  const navigate = useNavigate()
  // console.log("idddddd", id)
  const handleDataNavigation = (data) => {
    if (!data?.data?.data?.enrollment) {
      navigate("/student/my-applications")
    }
    return <></>
  }
  const { data, loading, error, setFetchData } = useFetch(
    `/v1/enrollments/internship/getEnrollment/${id}`,
    {},
    [],
    handleDataNavigation
  )

  // console.log(data, "dedo");
  const internshipData = data?.data?.enrollment?.internship
  // console.log("detail data intern", data, internshipData, id);
  const internshipEnrollment = data?.data?.enrollment
  // console.log("internship", internshipData);
  // const company = [
  //   {
  //     website_url: "https://www.google.com",
  //     year_of_incorporation: "1999",
  //     ceo_name: "Sparsh Prajapati",
  //     employee_strength: "355",
  //     industry_sector: "information Technology",
  //     services: {},
  //   },
  // ]
  // const company = useSelector((state) => state?.application?.company[1]);
  const employees = {
    0: "1-50",
    1: "51-250",
    2: "251+",
  }

  const tab = {
    0: "Applied",
    4: "Shortlisted",
    6: "Hired",
    7: "Rejected",
    8: "Exit",
  }

  const ColorTab = {
    0: "#f9b035",
    4: "#6149cd",
    6: "#47d065",
    7: "#ff3737",
    8: "#ff3737",
  }

// console.log("intershipdata",internshipData)

    useEffect(()=> {

        const fetchMoreInternships = async ()=> {
            try {
                const res = await axios.post(`/v1/filter/internship/company/${internshipData?.global?.id}`, {
                    internship_id: internshipData?.id
                });

                // console.log("jobbbbss",res);

                setMoreInternships(res?.data?.data);
            } catch (error) {
                // console.log(error);
            }
        }

        const fetchSimilarInternships = async ()=> {
            try {
                const res = await axios.get(`/v1/filter/internship/similar-internships/${internshipData?.id}`);

                // console.log("similarrrress", res?.data);

                setSimilarInternships(res?.data?.data?.similarInternships);
            } catch (error) {
                // console.log(error);
            }
        }

        if (internshipData?.id) {
          fetchMoreInternships();
          fetchSimilarInternships();
        }

    }, [internshipData?.id]);


  // console.log(data?.courses_preferred, "company");
  const user = useSelector((state) => state.auth.user)
  return (
    <div className="application_preview_container container ">
      <div className="preview_box">
        <section className="post_container">
          <PostDetailsSection data={internshipData} type={"internship"} />
        </section>
        {/* <div className="go_to_chat_btn d-flex">
          <button className="btn btn-success text-white w-100 py-2">
           Shotlisted
          </button>
        </div> */}
        <section className="action_container">
          {/* {children} */}
          <div className="company_container">
            <div className="go_to_chat_btn">
              <button
                className="mobo_btn btn btn-success text-white w-100 py-2"
                style={{
                  backgroundColor: `${ColorTab[internshipEnrollment?.status]}`
                  ,
                  border:'none'
                }}
              >
                {tab[internshipEnrollment?.status]}
              </button>
            </div>

                <div className='right-container d-flex flex-column gap-4 mt-3'>
                    {
                        internshipData?.project_linked > 0 && (
                          <LinkedProjectCard project={internshipData?.project} type={"Internship"} />
                        )
                        
                    }

                    <RightCard title={"More Internships Offered By "} highlightedText={internshipData?.global?.employer_account?.company?.company_name} data={moreInternships} type={"internship"} companyId={internshipData?.global?.employer_account?.company?.id} seeall={true} />

                    <RightCard title={"We Found More Similar "} highlightedText={"Internships For You"} data={similarInternships} type='internship' seeall={true}  />

                </div>

          

            {/* <div className="right_company_data">
              <div className="upper_data">
                <div className="d-flex align-items-center my-2 gap-4">
                  <img
                    src={internshipData?.branch?.company_logo}
                    width={60}
                    alt="Image"
                    className="rounded"
                  />
                  <h2 className="m-0 px-2">
                    {internshipData?.branch?.company_database?.company_name}.
                  </h2>
                </div>
                <p className="d-flex align-items-center justify-content-end">
                  <i className="fa fa-location"></i>
                  {internshipData?.branch?.address}
                </p>
              </div>

              <div className="company_body">
                <div className="body_item flex">
                  <h6>Website</h6>
                  <span className="web_url">
                    {internshipData?.global?.employer_account?.website_url}
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Founding Year</h6>
                  <span>
                    {
                      internshipData?.global?.employer_account
                        ?.year_of_incorporation
                    }
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Name of CEO</h6>
                  <span>
                    {internshipData?.global?.employer_account?.ceo_name}
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>No. of Employees</h6>
                  <span>
                    {
                      employees[
                        internshipData?.global?.employer_account
                          ?.employee_strength
                      ]
                    }
                  </span>
                </div>
                <div className="body_item flex">
                  <h6>Industry Sector</h6>
                  <span>
                    {
                      internshipData?.global?.employer_account?.industry
                        ?.industry_name
                    }
                  </span>
                </div>
              </div>
            </div>
            {company?.services?.length ? (
              <div className="service-list">
                <h5 className="list_header">Products & Services</h5>
                <ListContainer Items={company.services} isPreview={true} />
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default ApplicationInternshipDetail


