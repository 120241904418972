import axios from 'axios'
import { generateInvoicePDF } from 'components/GenerateInvoice'
import React, { useEffect, useState } from 'react'
import DownloadIcon from './DownloadIcon'
import PrintIcon from './PrintIcon'
import styles from "./paymentHistory.module.scss"


const PaymentHistory = () => {

    const [subscriptionData, setSubscriptionData] = useState([])
    const [user, setUser] = useState({});

    const getSubscriptions = async () =>{
        try {
            const res = await axios.get("/v1/subscription/get-my-subscriptions");
            // console.log("dataaa", res?.data);

            setSubscriptionData(res?.data?.data);
            setUser(res?.data?.user);

        } catch (error) {
            console.log("error",error)
        }
    }

    useEffect(()=> {
        getSubscriptions();
    }, []);

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return dateObject.toLocaleDateString('en-US', options);
      };

    // console.log("state data", subscriptionData);

    return (

        <div class={`${styles.SubscriptionPlan} container my-5`}>
            <div className="row mb-2">
                <h2> Payment History</h2>
            </div>
            <div className="row">
                <div class={`${styles.planBox}`}>
                {
                    subscriptionData.length > 0  ? (
                    <table className="table-container">
                        <tbody>
                            <tr className="">
                                <th className={styles.dateHeader} width="12%">Date</th>
                                <th className={styles.dateHeader} width="12%">Valid upto</th>
                                <th className={styles.descriptionHeader} width="36%">Description</th>
                                <th className={`${styles.amountHeader} text-center`} width="15%">Total Amount</th>
                                <th className={styles.invoiceHeader} width="8%">Invoice</th>
                            </tr>

                {
                    subscriptionData.map(item => {
                    return (
                            <tr className="" key={item?.id}>
                                <td data-th="Date" className={`${styles.date} table-content`}>{formatDate(item?.date_of_subscribe)}</td>
                                <td data-th="Date" className={`${styles.date} table-content`}>{formatDate(item?.valid_upto)}</td>
                                <td data-th="Description" className={`${styles.description} table-content`}> {item?.subscription_database?.subscription_name}</td>
                                <td data-th="Total Amount" className={`${styles.amount} table-content`}>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumSignificantDigits: 3 }).format(item?.price)}</td>
                                <td data-th="Invoice" className="table-content">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <span  onClick={()=> generateInvoicePDF({
                                            name: user?.first_name + " " + user?.last_name,
                                            email: user?.email_address,
                                            id: user?.id,
                                            amount: item?.price,
                                            desc: `Corporate ${item?.subscription_database?.subscription_name} Subscription`
                                            })} >
                                            <DownloadIcon />
                                        </span>
                                        <span>
                                            <PrintIcon />
                                        </span>
                                    </div>
                                </td>
                            </tr>

                    )
                    })
                }            
                            

                        </tbody>
                    </table>

                    ): (
                        <div style={{padding: "10px"}}> No Subscription Available </div>
                    )
                }

                </div>
            </div>
        </div>

)
}

export default PaymentHistory;
