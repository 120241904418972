import React, { useState } from "react";
import "./projectReportTable.scss";
import Checkbox from "components/formComponents/Checkbox";

const ProjectReportTableCheckbox2 = ({
  checkbox,
  title,
  subTitle,
  text1,
  text2,
  text3,
  text4,
  text5,
  setRating4,
  rating4,
}) => {
  const handleRatingChange4 = (e) => {
    const selectedValue = e.target.value;
    console.log(selectedValue);
    setRating4(parseInt(selectedValue));
  };

  // console.log(rating1, "rating")

  return (
    <div className="projectReportTable">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 25%)</small>
      </h4>
      <table>
        <tr>
          <th className="table_title">{subTitle}</th>
          <th>Rating</th>
          <th>Mentor Rating</th>
        </tr>
        <tr>
          <td className="table_title">{text1}</td>
          <td>05</td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  name="myRadioGroup2"
                  value="5"
                  checked={rating4 === 5}
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange4}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text2}</td>
          <td>04</td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  name="myRadioGroup2"
                  value="4"
                  checked={rating4 === 4}
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange4}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text3}</td>
          <td>03</td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  name="myRadioGroup2"
                  value="3"
                  checked={rating4 === 3}
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange4}
                />
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="table_title">{text4}</td>
          <td>02</td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  name="myRadioGroup2"
                  value="2"
                  checked={rating4 === 2}
                  style={{ appearance: "auto" }}
                  onChange={handleRatingChange4}
                />
              </label>
            </div>
          </td>
        </tr>
        {text5 && (
          <tr>
            <td className="table_title">
              Neatly written, no language or grammer mistakes
            </td>
            <td>01</td>
            <td>
              <div>
                <label>
                  <input
                    type="radio"
                    name="myRadioGroup2"
                    value="1"
                    checked={rating4 === 1}
                    style={{ appearance: "auto" }}
                    onChange={handleRatingChange4}
                  />
                </label>
              </div>
            </td>
          </tr>
        )}
        <tr>
          <td className="table_title">Maximum (5)</td>
          <td></td>
          <td>
            <h5>Score: {rating4}</h5>
            <p>weighted Score: {(rating4 * 0.25).toFixed(2)}</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ProjectReportTableCheckbox2;
