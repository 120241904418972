import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "services/actions/auth";
import "./mentorProfile.scss";
import MentorExperienceByManager from "./MentorExperienceByManager";
import MentorDetailsByManager from "./MentorDetailsByManager";
import Accordian from "components/accordian";
import { Loader } from "rsuite";


const Submit = ({ onClick = () => {} }) => {
  return (
    <button
      className="save_changes_btn btn btn-primary mx-4 text-white "
      type="submit"
      onClick={(e) => {
        e?.stopPropagation();
        onClick();
      }}
    >
      Save Changes
    </button>
  );
};

const MentorProfileByManager = () => {
  const { state } = useLocation();
  const [image, setImage] = useState(() => null);
 
  const [skills,setSkills]=useState([])
  const[getPreviousSkills,SetGetPreviousSkill]=useState([])

  const navigate = useNavigate();

// console.log("skills;;;;;;;;;",skills)
// console.log("getPreviousSkills",getPreviousSkills)



  // console.log("state---mentortordata",state?.mentorData)
  // console.log(experiences,"hello world")
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      first_name: state?.mentorData?.first_name ||"",
      last_name: state?.mentorData?.last_name|| "",
      languages:state?.mentorData?.languages|| [],
      industry_sector: state?.mentorData?.industry_sector||"",
      email_address: state?.mentorData?.email_address||"",
      bio:  state?.mentorData?.bio||"",
      phone_number: state?.mentorData?.phone_number||"",
      gender: state?.mentorData?.gender||"",
      linkedIn_link:state?.mentorData?.linkedIn_link||"",
      github_link:state?.mentorData.github_link||"",
      profile_link: state?.mentorData?.profile_link||"",
      // profile_photo: state?.mentorData?.profile_photo||"",
      industry_year_expreience:  state?.mentorData?.industry_year_expreience||"",
      location: state?.mentorData?.location||"",
      // required_skills:skills ||[]
    },
  });

  const [experiences, setExperiences] = useState(() => state?.mentorData?.expreiences || []);
  const [loading, setLoading] = useState(false);

  

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const existingSkillNames = getPreviousSkills?.map(skill => skill.name.toLowerCase()) || [];
      const skillsToUpdate = skills && skills.length > 0 ? skills?.map(skill => ({ name: skill.name.toLowerCase() })) : null;


      if (skillsToUpdate || existingSkillNames) {
        // Check for duplicate skill names
        console.log("in the console",skillsToUpdate,existingSkillNames)
       
        const newSkillNames = skillsToUpdate ? skillsToUpdate.map(skill => skill.name) : [];
        const allSkillNames = [...existingSkillNames, ...newSkillNames];
        const isDuplicateSkill = allSkillNames.some((name, index) => allSkillNames.indexOf(name) !== index);
  
        if (isDuplicateSkill) {
          toast.error("Duplicate skill found. Please select unique skills.");
          return;
        }
        // console.log("allSkillNames",allSkillNames)
   
        data.required_skills = allSkillNames;
      }



      if (image && image?.size > 1000000) {
        return toast.error("Image size should be less than 1MB");
      }

      let payload = {};
      if (image) {
        const formData = new FormData();
        formData.append("profile_photo", image);
        formData.append("prev_photo", state?.mentorData?.profile_photo);
        const { data: imageData } = await axios.post(
          `/v1/mentor-manager/profile/image/${state?.mentorData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!imageData?.data?.url) return toast.error("Image upload failed");
        payload.profile_photo = imageData?.data?.url || "";
        data.profile_photo = payload.profile_photo || "";
        
      }
      // // console.log(data, "formData");
      data.experiences = experiences?.filter((i) => !i?.id || []) ;
      data.industry_sector = data?.industry?.id;
      
      const { data: profileData } = await axios.post(
        `/v1/mentor-manager/mentor-profile-by-manager/${state?.mentorData?.id}`,
        data
      );
      if (profileData?.data) {
  
        payload.experience = profileData?.data?.experience || [];
       
        toast.success("Profile updated successfully");
        setLoading(false);

          navigate("/mentor-manager/mentors-list");
      
      
     
      } else {
        toast.error("Profile update failed");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if(loading) return<Loader/>

  return (
    <>
    <div
      className="student-profile p-0 p-sm-4"
      // onSubmit={handleSubmit(onSubmit)}
    >
      <Accordian
        title="UPDATE MENTOR PROFILE"
        defaultOpen={true}
        Component={<Submit onClick={handleSubmit(onSubmit)} />}
        className="card"
      >
        <MentorDetailsByManager
          setImage={setImage}
          Image={image}
          register={register}
          setValue={setValue}
          getValues={getValues}
          setpreviousData={state?.mentorData}
          setSkills={setSkills}
          skills={skills}
          SetGetPreviousSkill={SetGetPreviousSkill}

        />
      </Accordian>

      <Accordian title="UPDATE MENTOR EXPERIENCE" className="card">
        <MentorExperienceByManager
          experiences={experiences}
          setExperiences={setExperiences}
        />
      </Accordian>

    
      {loading && (
        <div className="loading-container">
          <div className="text-center loading-wrapper">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default MentorProfileByManager;
