import {
  ADD_APPLICATION_DATA,
  ADD_SERVICE_DATA,
  INIT_APPLICATION_DATA,
  REMOVE_APPLICATION_DATA,
  REMOVE_SERVICE_DATA,
  ADD_PROJECT_ID,
  REMOVE_PROJECT_ID,
} from "../constants";

function isJsonString(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

const user = isJsonString(localStorage.getItem("user") || false);
const initialState = {
  // company: {
  //   // address: user?.branch?.address,
  //   // company_name: user?.company?.company_name,
  //   // // ceo_name: user["ceo_name"],
  //   // // year_of_incorporation: user["year_of_incorporation"],
  //   // // employee_strength: user["employee_strength"],
  //   // website_url: user?.website_url,
  //   // company_id: user?.company_id,
  //   // branch_id: user?.branch_id,
  //   // industry: {
  //   //   id: user?.industry?.id,
  //   //   industry_name: user?.industry?.industry_name,
  //   // },
  //   // industry_sector: user?.industry?.industry_name,
  //   // services: user?.services || [],
  // },

  internship: {},
  job: {},
  project: {},
};

const getInitialState = () => {
  if (user.role === 2) {
    const item = isJsonString(localStorage.getItem("application"));
    if (item) {
      return item;
    } else return initialState;
  } else return initialState;
};

export default function application(state = getInitialState(), action) {
  const { payload, type } = action;

  switch (type) {
    case INIT_APPLICATION_DATA: {
      return {
        ...initialState,
        // company: {
        //   ...initialState.company,
        //   ...payload,
        // },
      };
    }

    // case ADD_APPLICATION_DATA: {
    //   const newState = {
    //     ...state,
    //     [payload.method]: {
    //       ...state[payload.method],
    //       [payload?.step]: {
    //         ...state[payload.method][payload?.step],
    //         ...payload.data,
    //       },
    //     },
    //   };

    //   localStorage.setItem("application", JSON.stringify(newState));
    //   return newState;
    // }
    case ADD_APPLICATION_DATA: {
      const newState = {
        ...state,
        [payload.method]: {
          // ...state[payload.method],
          ...payload.data,
        },
      };

      localStorage.setItem("application", JSON.stringify(newState));
      return newState;
    }

    case ADD_PROJECT_ID: {
      const newState = {
        ...state,
        opportunityMode: {
          id: payload.id,
          mode: payload.mode,
        },
      };
      localStorage.setItem("application", JSON.stringify(newState));
      return newState;
    }

    case REMOVE_PROJECT_ID: {
      const newState = {
        ...state,
        projectId: null,
      };
      return newState;
    }

    case REMOVE_APPLICATION_DATA:
      const application = JSON.parse(localStorage.getItem("application"));
      delete application[payload.method];
      localStorage.setItem("application", JSON.stringify(application));

      return {
        ...state,
        [payload.method]: {
          ...initialState[payload.method],
        },
      };

    default:
      return state;
  }
}
