import React from 'react'

const MonthlyStatement = () => {
  return (
    <div className="mentor-setting ">
    <div className="container-lg container-fluid">
         
          <div>
          <div className="tab-pane" id="pills-transactionHistory" role="tabpanel" aria-labelledby="pills-transactionHistory-tab">
                    <div className="transcation-historyEmpty">
                      <h3>No transaction so far.</h3>
                      <p>All your transactions will be visible here in this tab.</p>
                    </div>


                    {/* <div className="student-available-table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Student Name</th>
                              <th scope="col">Date Purchased</th>
                              <th scope="col">Paid Amount</th>
                              <th scope="col">Pending Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td >Sahil Sethi </td>
                              <td>7 Aug</td>
                              <td>1000</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>

                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>

                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>
                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>
                              <td>7 Aug</td>

                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>

                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>
                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>

                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                            <tr>
                              <td >Sahil Sethi </td>

                              <td>7 Aug</td>
                              <td>1500</td>
                              <td>2000</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="transaction-entry">
                      <h3>Rahul Narang</h3>

                      <div className="table-accordian">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                October 2022
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="mentor-table">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Date</th>
                                          <th scope="col">Description</th>
                                          <th scope="col">Total Amount</th>
                                          <th scope="col">TDS</th>
                                          <th scope="col">Credited Amount</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button className='gr'>Not Credited</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                October 2022
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="mentor-table">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Date</th>
                                          <th scope="col">Description</th>
                                          <th scope="col">Total Amount</th>
                                          <th scope="col">TDS</th>
                                          <th scope="col">Credited Amount</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button className='gr'>Not Credited</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div></div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                October 2022
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="mentor-table">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Date</th>
                                          <th scope="col">Description</th>
                                          <th scope="col">Total Amount</th>
                                          <th scope="col">TDS</th>
                                          <th scope="col">Credited Amount</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button className='gr'>Not Credited</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                        <tr>
                                          <td >02 Oct 2022</td>
                                          <td className='des-color'>Plan 1 - Mentoring Session</td>
                                          <td>₹ 1000</td>
                                          <td>₹ 100</td>
                                          <td>₹ 900</td>
                                          <td><button>Completed</button></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div></div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div> */}
                  </div >
              </div>
              </div>
      </div>
  )
}

export default MonthlyStatement