import axios from "axios";
import Loader from "components/employer/Loader";
import { duplicateProject } from "components/employer/duplicate/project";
import ProjectDetailsPage from "components/posts/ProjectDetailsPage";
import useFetch from "hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "styles/employer/preview.scss";

const Actions = ({ id = 0, data }) => {
  const reduxDispatch = useDispatch();

  const user = useSelector((state)=> state?.auth?.user);
  const navigate = useNavigate();
  // console.log(data);
  const onDuplicate = () => {
    duplicateProject({ data: data, reduxDispatch });
    navigate("/employer/project/add");
  };

  const handleDetails = () => {
    navigate(`/employer/project/${id}`);
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Internship closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };
console.log("userrr", user);
  return (
    <div className="actions d-none  d-sm-flex flex-sm-column gap-2">

      {
        user?.role === 2 &&  

        <>
          <button
            type="button"
            className="flex justify-content-center w-100 bg-success"
            onClick={onDuplicate}
          >
            <span className="d-none d-sm-flex"> Duplicate</span>{" "}
            <i className="fa-regular fa-copy text-white px-2"></i>
          </button>
          <button
            type="button"
            className="bg-danger flex justify-content-center mx-0 w-100"
            onClick={handleClose}
          >
            <span className="d-none d-sm-flex">Close opening</span>{" "}
            <i className="fa-regular fa-circle-xmark text-white px-2"></i>
          </button>
          <button
            type="button flex"
            className="bg-primary flex justify-content-center w-100"
            onClick={handleDetails}
          >
            <span className="text-nowrap">View candidates</span>{" "}
            <i className="fa-regular fa-eye text-white px-2"></i>
          </button>
          <b className="d-none d-md-flex align-items-center gap-2">
            Send Invite <i className="fa-sharp fa-solid fa-paper-plane"></i>
          </b>
          <div className="outline_buttons d-none d-md-flex">
            <button
              type="button"
              onClick={() => navigate(`/employer/project/students/invite/${id}`)}
            >
              Students
            </button>
            <button
              type="button"
              onClick={() => navigate(`/employer/project/invite/${id}`)}
            >
              College/University
            </button>
          </div>
        
        
        </>
      }

      {
        user?.role === 1 && 

        <button
          type="button"
          onClick={() => navigate(`/admin/project/template`, {state:{data: data}})}
        >
          Generate Template
        </button>
      }
    </div>
  );
};

const MobileActionButtons = ({ id = 0, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDuplicate = () => {
    duplicateProject({ data: data, dispatch });
    navigate("/employer/project/add");
  };

  const handleDetails = () => {
    navigate(`/employer/project/${id}`);
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Internship closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };

  return (
    <div className="mobile_action_button position-fixed bottom-0 left-0 right-0 d-flex d-sm-none w-100 align-items-center justify-content-center bg-white py-2 px-2 gap-2">
      <div className="flex w-40 gap-2">
        <button
          type="button"
          className="flex justify-content-center w-100 bg-success"
          onClick={onDuplicate}
        >
          <span className="d-none d-sm-flex"> Duplicate</span>{" "}
          <i className="fa-regular fa-copy text-white p-1"></i>
        </button>
        <button
          type="button"
          className="bg-danger flex justify-content-center mx-0 w-100"
          onClick={handleClose}
        >
          <span className="d-none d-sm-flex">Close opening</span>{" "}
          <i className="fa-regular fa-circle-xmark text-white p-1"></i>
        </button>
      </div>
      <div className="w-60">
        <button
          type="button flex"
          className="bg-primary flex justify-content-center w-100"
          onClick={handleDetails}
        >
          <span className="text-nowrap">View candidates</span>{" "}
          <i className="fa-regular fa-eye text-white px-2"></i>
        </button>
      </div>
    </div>
  );
};

const ProjectDetails = ({ flag = false }) => {
  const { id = 0 } = useParams();

  const { loading, data, error } = useFetch(`/v1/project/${id}`);
  // // console.log(loading, data, error)

  if (loading || !data) return <Loader />;
  const { error: err, data: project } = data;

  if (err || error) return <h3>{err}</h3>;

  return (
    <ProjectDetailsPage data={project} id={id}>
      {!flag && (
        <>
          <Actions id={id} data={data?.data} />
          <MobileActionButtons id={id} data={data?.data} />
        </>
      )}
    </ProjectDetailsPage>
  );
};

export default ProjectDetails;
