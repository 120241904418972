import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Chips from "./Chips";
import Popup from "./Popup";
import "./popup.scss";

const ChipsFieldWithPopup = ({
  label = "",
  id = "",
  placeholder = "",
  required = false,
  register,
  options = {},
  disabled = false,
  items = [],
  setItems = () => {},
  deleteItem,
  secondaryLabel = "",
  setValue,
  path,
  key_name = "name",
  className = "",
  labelClass = "",
  reverse = false,
  flag = false,
}) => {
  const [data, setData] = useState(() => []);
  const [inputValue, setInputValue] = useState(() => "");
  const [focus, setFocus] = useState(() => 0);
  const inputRef = useRef();
  const [requests, setRequests] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [location, setLocation] = useState(() => []);

  // console.log("chipfileds",items)
  // console.log("path",path)

  const updateItems = (item) => {
    try {
      // if (flag && item.length >= 1) throw new Error("Only one item allowed");
      if (flag && items.length >= 1) {
        throw new Error("Only one item allowed");
      }
      if (items.length >= 5) throw new Error("Maximum 5 items allowed");
      if (items.some((obj) => obj[key_name] === item[key_name]))
        throw new Error("Item already exists");
      setItems((items) => [...items, item]);
      setInputValue("");
    } catch (error) {
      toast.error(error?.message || error, { toastId: "updateItems" });
    }
  };

  useEffect(() => {
    if (!inputValue) return;
    const getResponse = async () => {
      const response = await axios.get(path, {
        params: { name: inputValue },
        validateStatus: (status) => status < 500,
      });
      // // console.log(response?.data?.data,"hello bye")
      setRequests((prev) => ({
        ...prev,
        [inputValue]: response?.data?.data || [],
      }));
      setData(response?.data?.data || []);
    };

    // // console.log(data,"hello bye")
    if (!requests[inputValue]) {
      if (
        requests[inputValue.slice(0, -1)] &&
        !requests[inputValue.slice(0, -1)].length
      ) {
        setRequests((prev) => ({ ...prev, [inputValue]: [] }));
        return;
      } else getResponse();
    } else setData(requests[inputValue]);
  }, [inputValue, requests, path]);

  const updatePopup = (value) => {
    setIsPopupOpen(value);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInputValue(value); //b

    if (value.length > 0) {
      setFocus(1);
      updatePopup(true);
      const filteredData = data?.filter((item) => {
        return item[key_name]?.toLowerCase().includes(value.toLowerCase());
      });

      setData(filteredData);
      if (filteredData?.length && filteredData[0][id] === value) {
        // console.log(filteredData[0].id, "hello bye");
        setValue(id, filteredData[0].id);
        // setValue(id, filteredData[0].id);
      }
    } else {
      updatePopup(false);
      setData([]);
    }
  };

  const closePopup = () => {
    updatePopup(false);
  };

  const handleKey = (e) => {
    if (!data.length) return;
    let focusCounter = focus;
    if (e.key === "ArrowDown") {
      if (focusCounter < data.length) focusCounter++;
    } else if (e.key === "ArrowUp") {
      focusCounter > 0 && focusCounter--;
    } else if (e.key === "Enter") {
      e.preventDefault();
      inputRef.current.value = "";
      updatePopup(false);
      updateItems(data[focusCounter - 1]);
      setFocus(0);
    } else {
      return;
    }
    setFocus(focusCounter);
  };

  const updateValue = (item) => {
    inputRef.current.value = "";
    updateItems(item);
    setInputValue("");
    updatePopup(false);
    closePopup();
  };

  return (
    <div className="col-12 place_container" onKeyDown={handleKey}>
      <div className="form-group mb-3">
        <label htmlFor={id} className={`${labelClass}`}>
          {label}
          {required && <span>*</span>}
          {secondaryLabel && (
            <small className="form-text text-muted">{secondaryLabel}</small>
          )}
        </label>
        {!reverse && (
          <>
            {items?.length ? (
              <div className="col-12">
                <div className="row">
                  <Chips
                    items={items}
                    deleteItem={deleteItem}
                    id={id}
                    key_name={key_name}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="item-container">
              <input
                className={`form-control mt-2 ${className}`}
                type="text"
                name={id}
                id={id}
                placeholder={placeholder}
                {...register(id, { required, ...options })}
                onChange={onChange}
                ref={inputRef}
                autoComplete={"off"}
                disabled={disabled}
              />
              {isPopupOpen ? (
                <Popup
                  data={data}
                  input_value={inputValue}
                  focus={focus}
                  updateValue={updateValue}
                  key_name={key_name}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        )}
        {reverse && (
          <>
            <div className="item-container">
              <input
                className={`form-control mt-2 ${className}`}
                type="text"
                name={id}
                id={id}
                placeholder={placeholder}
                {...register(id, { required, ...options })}
                onChange={onChange}
                ref={inputRef}
                autoComplete={"off"}
                disabled={disabled}
              />
              {isPopupOpen ? (
                <Popup
                  data={data}
                  input_value={inputValue}
                  focus={focus}
                  updateValue={updateValue}
                  key_name={key_name}
                />
              ) : (
                <></>
              )}
            </div>
            {items?.length ? (
              <div className="col-12">
                <div className="row">
                  <Chips
                    items={items}
                    deleteItem={deleteItem}
                    id={id}
                    key_name={key_name}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChipsFieldWithPopup;
