import React, { useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserRole } from "routes/getUserRole";
import MainLogo from "../../../../images/logos/logo-main.png";
import "./nav.scss";
import moboLogo from "./qollabb_logo.png";
const NavBar = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [active, setActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const clickHandler = () => {
    setActive((prev) => !prev);
  };

  // const handleChange = (e) => {

  //   getSearch(e?.target?.value || "");
  
  // };

  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChange = (e) => {
    // getSearch(e?.target?.value || "");
    const keyword = e.target.value;

    // console.log("searcheeeeekeyyyyyy", keyword)
    
    dispatch({
      type: "SEARCH_KEYWORD",
      payload: {keyword: keyword}
    })

    setSearchKeyword(keyword);
  };

  const decouncedHandleChange = debounce(handleChange, 1000);

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);

    decouncedHandleChange(e);
  };

  const clearSearch = () => {
    dispatch({
      type: "SEARCH_KEYWORD",
      payload: {keyword: ""}
    }) 
    setSearchKeyword('');
  };

  return (
    <div className="navbar_container py-2 flex-col">
      <div className="container flex" style={{ padding: "0px" }}>
        <div className="logo_left">
          <Link to="/">
            <img className="main_logo" src={MainLogo} alt="logo" />
          </Link>
        </div>
        <Link to={"/explore-page"}>
          <div className="explore-search">
            <input type="text" placeholder="Search projects/jobs/internships" value={searchKeyword} onChange={handleSearchChange}  />
            {searchKeyword ? (
              <span className="clear-search " onClick={clearSearch}>
                <IoCloseSharp size={25} />
              </span>
            ) : (
              <span className="search-icon"> <HiMagnifyingGlass color="#fff"  /> </span>
            )
          
          }
          </div>
        </Link>


        <nav className="menu_right flex gap-3">
          <ul className="flex list-unstyled m-0 gap-3 fw-bold d-none d-md-flex">
            <li className="text-dark">
              <Link to="/student-page" style={{ color: "black" }}>
                Students
              </Link>
            </li>
            <li>
              <Link to="/college-page" style={{ color: "black" }}>
                Colleges & Universities
              </Link>
            </li>
            <li>
              <Link to="/company-page" style={{ color: "black" }}>
                Companies
              </Link>
            </li>

            {!isAuth?.isAuthenticated && (
              <li>
                <Link to="/signin" style={{ color: "black" }}>
                  Login
                </Link>
              </li>
            )}
          </ul>

          {!isAuth?.isAuthenticated ? (
            <Link
              to="/signup"
              class="btn fw-bold text-dark px-4 py-2"
              style={{ backgroundColor: "#FFB013", borderRadius: "4px" }}
            >
              Signup for free
            </Link>
          ) : (
            <button
              onClick={() => navigate(getUserRole(isAuth?.user?.role))}
              class="btn fw-bold text-dark px-4 py-2"
              style={{ backgroundColor: "#FFB013", borderRadius: "4px" }}
            >
              Dashboard
            </button>
          )}

          <button
            className="btn mob mobile-nav-media"
            onClick={clickHandler}
            type="button"
            title="open-nav"
          >
            <i className="fa-solid fa-ellipsis"></i>
          </button>


        </nav>

        {active ? (
          <div className="student_nav flex">
            <img
              src={moboLogo}
              alt="Image"
              width={65}
              style={{ marginLeft: "30px" }}
            />

            <button className="btn text-bg-dark cros">
              <i className="fa-solid fa-xmark" onClick={clickHandler}></i>
            </button>

            <nav className="outer">
              <ul>
                <li>
                  {/* {getUserRole(user?.role)} */}
                  <Link to="/student-page" onClick={clickHandler}>
                    {" "}
                    Students
                  </Link>
                </li>
                <li>
                  <Link to="/company-page" onClick={clickHandler}>
                    Companies
                  </Link>
                </li>
                <li>
                  <Link to="/college-page" onClick={clickHandler}>
                    Colleges & Universities
                  </Link>
                </li>
                <li>
                  {!isAuth?.isAuthenticated && (
                    <li>
                      <Link to="/signin">Login</Link>
                    </li>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* <div className="mob_explore d-none d-md-flex">
        <Link to={"/explore-page"}>
              <div className="mob_explore-search">
                <input type="text" placeholder="Search projects/jobs/internships" onChange={decouncedHandleChange}  />
                <span className="search-icon"> <HiMagnifyingGlass color="#fff" /> </span>
              </div>
        </Link>
      </div> */}

    </div>
  );
};

export default NavBar;
