import CTASuccess from "components/formComponents/CTA_Success";
import useRelativeTime from "hooks/useRelativeTime";
import React from "react";
import { Link } from "react-router-dom";
import "../../formComponents/post/card.scss";

const ShortlistJobCard = ({
  item,
  data,
  post,
  clickHandler = () => {},
  method = "internship",
  status = "",
}) => {
  // console.log("method", method);
  const items = data?.internship;
  // console.log(items?.branch?.id);
  // console.log("stu jobdata", item);
  const time = useRelativeTime(item?.createdAt);

  const skills = [];
  // const tab = {
  //   0: "Applied",
  //   4: "Shortlisted",
  //   6: "Hired",
  //   7: "Rejected",
  // };
  const statusMap = {
    0: ["Applied", "bg-warning"],
    // 1: ["Left", "bg-danger"],
    // 2: ["Completed", "bg-secondary"],
    // 3: ["Submit Project", "bg-success"],
    7: ["Rejected", "bg-danger"],
    4: ["Shortlisted", "bg-success"],
    6: ["Hired", "bg-success"],
  };
  const CTC_incentive_from = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(item?.[method]?.incentive_from);
  const CTC_incentive_to = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(item?.[method]?.incentive_to);
  // console.log(item?.[method], "inter");

  return (
    <>
          <div className="card project_card p-3 p-md-4">
          <div className="strip" style={{backgroundColor: "#d3c047"}}>
              {/* <p>Project</p> */}
            </div>
            <div className="card__header">
              <div className="card__header__image d-flex justify-content-between align-items-center">
                <img
                  src={
                    item?.[method]?.global_job?.employer_account
                      ?.company_logo ||
                    item?.[method]?.branch?.company_logo
                  }
                  alt="company logo"
                  loading="lazy"
                  className="bg-primary comp-logo-shortlist-project"
                />
                <h6 className="text-muted d-lg-none">{time}</h6>
              </div>
              <Link
                className="card__header__info"
                to={`${method}/detail/${item?.id}`}
              >
                <h5 className="m-0 mb-1">{item?.[method]?.title}</h5>
                <h6 className="text-muted address mb-1">
                { item?.[method]?.location !== null ?  item?.[method]?.location?.city_name + ","+ item?.[method]?.location?.state?.state_name : "Remote/virtual" }
                  {/* {item?.[method]?.location?.city_name},{" "}
                  {item?.[method]?.location?.state?.state_name}{" "} */}
                  <span className="divide"></span>| CTC:
                  <b>
                    {" "}
                    {CTC_incentive_from} - {CTC_incentive_to}
                  </b>
                </h6>
              </Link>

              <div className="card__header__cta">
                <div
                  // className="shortlist_btn"
                  className={`shortlist_btn ${statusMap?.[item?.status]?.[1]}`}
                  onClick={() =>
                    clickHandler(post?.id, post?.screening_questions)
                  }
                >
                  {statusMap?.[item?.status]?.[0]}
                </div>
                {/* <CTASuccess
                  value={tab[item?.status]}
                  onClick={() =>
                    clickHandler(post?.id, post?.screening_questions)
                  }
                /> */}
                <h6 className="text-muted d-none d-lg-block">{time}</h6>
              </div>
            </div>
            <div className="card__body__info__skills d-flex gap-1 gap-lg-3">
              {skills?.map((skill) => (
                <span
                  className="py-1 px-3 p-lgy-2 px-lg-4 d-flex justify-content-center align-items-center"
                  key={skill?.id}
                >
                  {skill?.name}
                </span>
              ))}
            </div>
            <h6 className="text-muted description">
              {item?.[method]?.description?.length > 100
                ? item?.[method]?.description?.slice(0, 100) + "..."
                : item?.[method]?.description}
            </h6>

            <div className="card__body">
              <p>
                {post?.goal?.length > 100
                  ? post?.goal?.slice(0, 100) + "..."
                  : post?.goal}
              </p>

              <CTASuccess
                value={statusMap?.[item?.status]?.[0]}
                onClick={() =>
                  clickHandler(post?.id, post?.screening_questions)
                }
                className="mobile-cta"
              />
            </div>
          </div>
    </>
  );
};

export default ShortlistJobCard;
