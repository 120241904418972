import React, { useEffect, useState } from "react"
import "./professorshodhak.scss"
import professor_imag from "./professor.png"

import Select, { components } from "react-select"
import axios from "axios"



export default function ProfessorShodhak({
  projectTitle,
  projectTask,
  projectGoal,
  projectDurationFrom,
  projectDurationTo,
}) {
  const [inputValue, setInputValue] = useState("")
  const [selectedOption, setSelectedOption] = useState(null)
  const [loading, setLoading] = useState(false)
  const [weekOptions, setWeekOptions] = useState([])
  const [answer, setAnswer] = useState("")
  // const [isDisabled, setIsDisabled] = useState(false);
  // const [finalValue, setFinalValue] = useState('');

  
  // const staticQuestions = [
  //   { label: "What is the maximum size of the weekly report or project report file?", value: "What is the maximum size of the weekly report or project report file?"},

  // ];

  const handleInputChange = (value) => {
    // console.log("ssssssssssss", value)
    setInputValue(value)
    // console.log("value",value)
  }

  const handleChange = async (option) => {
    // console.log("oppppppppp", option)
    setSelectedOption(option)
    // setInputValue(option ? option.label : '');
    // setInputValue(option ? option.value : "")

    setInputValue("");
    await getAnswer(option?.value);
  }


 

  const customComponents = {
    Menu: (props) => {
      return weekOptions.length ? <components.Menu {...props} /> : null
    },
  }

  useEffect(() => {
    const generateWeekOptions = () => {
      // const numberToOrdinalWord = (num) => {
      //   const ordinals = [
      //     "zeroth",
      //     "first",
      //     "second",
      //     "third",
      //     "fourth",
      //     "fifth",
      //     "sixth",
      //     "seventh",
      //     "eighth",
      //     "ninth",
      //     "tenth",
      //     "eleventh",
      //     "twelfth",
      //     "thirteenth",
      //     "fourteenth",
      //     "fifteenth",
      //     "sixteenth",
      //     "seventeenth",
      //     "eighteenth",
      //     "nineteenth",
      //     "twentieth",
      //     "twenty-first",
      //     "twenty-second",
      //     "twenty-third",
      //     "twenty-fourth",
      //     "twenty-fifth",
      //     "twenty-sixth",
      //     "twenty-seventh",
      //     "twenty-eighth",
      //     "twenty-ninth",
      //     "thirtieth",
      //     "thirty-first",
      //     "thirty-second",
      //     "thirty-third",
      //     "thirty-fourth",
      //     "thirty-fifth",
      //     "thirty-sixth",
      //     "thirty-seventh",
      //     "thirty-eighth",
      //     "thirty-ninth",
      //     "fortieth",
      //     "forty-first",
      //     "forty-second",
      //     "forty-third",
      //     "forty-fourth",
      //     "forty-fifth",
      //     "forty-sixth",
      //     "forty-seventh",
      //     "forty-eighth",
      //     "forty-ninth",
      //     "fiftieth",
      //   ]
      //   return ordinals[num]
      // }
      function getOrdinal(n) {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      }

      const options = []
      for (let i = 1; i <= projectDurationTo; i++) {
        const weekString = getOrdinal(i)
        // const questionlist= `hello`;
        const questionlist = `Please provide a step-by-step guidance for week ${i}.`
        const questionPromt = `Please provide me step-by-step guidance on the tasks I need to complete during the ${weekString} week. Please help me break down the activities and provide detailed instructions on how to proceed?`
        // options.push(questionlist);
        options.push({ label: questionlist, value: questionPromt })
      }
      // setWeekOptions(options);
      setWeekOptions(options)
      //  setWeekOptions([...staticQuestions,...options]);
    }

    generateWeekOptions()
  }, [projectDurationTo])

  const getAnswer = async (question) => {
    console.log("question", inputValue)
    
    if (question) {
      setLoading(true)
      try {
        const response = await axios.post("/v1/project/getAnswerByAi", {
          projectTitle,
          projectGoal,
          projectTask,
          question,
          projectDurationTo
        })

        const answerText = response?.data?.data?.data

        answerText
          .split(/\d+\.\s*/)
          .filter((line) => line.trim().length > 0)
          .join("\n")

        // console.log("answerText", answerText)

        setAnswer(answerText)
        // setAnswer(response?.data?.data?.data)
      } catch (error) {
        console.error("Error fetching answer:", error)
      } finally {
        setLoading(false)
      }
    } else {
      // toast.error("Please enter a question.")
      console.log("error")
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      getAnswer(inputValue)
    }
  }


  // const handleButtonClick = () => {
  //   console.log('Selected Value:', selectedOption ? selectedOption.value : inputValue);
  // };



  const customStyles = {
    control: (provided) => ({
      ...provided,
      whiteSpace: "normal",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "normal",
      wordBreak: "break-word",
      
    }),
    singleValue: (provided) => ({
      ...provided,
      whiteSpace: "normal",
      wordBreak: "break-word",
    }),
    // input: (provided) => ({
    //   ...provided,
    //   whiteSpace: "normal",
    //   wordBreak: "break-word",
    // }),
   
    
  

  };

 
 

  // console.log("inputttttt",inputValue)

  return (
    <div className="professor-container">
      <div className="professor-header">
        <div className="speechBubble">
          <p className="professor-heading-text">
            Hi,
            <br /> I am professor <span className="highlight">SHODHAK</span>,
            your virtual AI powered project guide...
          </p>
        </div>

        <div>
          <img src={professor_imag} alt="professor" className="professor-img" />
        </div>

        <div className="professor-header-container"></div>
      </div>
      <div className="professor-bottom">
        <div className="professor-dropdown">
          <Select
            value={selectedOption}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            options={weekOptions}
            isClearable
            placeholder="Ask me anything...."
            components={customComponents}
            noOptionsMessage={() => null}
            blurInputOnSelect={false}
            // className="basic-single"
            onKeyDown={handleKeyDown}
            styles={customStyles}
      

          />
         
        </div>

     

      
        {/* <HiPaperAirplane
          className="icon-send"
          onClick={()=> getAnswer(inputValue)}
        /> */}
      </div>

      <div className="professor-answer">
        <div className="professor-write">
          <img
            src={professor_imag}
            alt="professor"
            className="professor-img-write"
          />
          <p className="professor-answer-write">Prof. Shodhak -</p>
        </div>

        <div className="professor-response mt-0 pt-0">
          {loading ? (
           <p className="typing mt-0 pt-0">
           Typing<span className="dot"></span>
           <span className="dot"></span>
           <span className="dot"></span>
         </p>
         
          ) : (
            answer
          )}
        </div>
      </div>
    </div>
  )
}
