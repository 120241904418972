import axios from "axios";
import PasswordField from "components/formComponents/PasswordField";
import TextField from "components/formComponents/TextField";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateMentorByManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email_address: "",
      full_name: "",
      phone_number: "",
    },
  });

  const onSubmit = async (data, event) => {
    setLoading(true);
    event.preventDefault();
    const encodedCredentials = btoa(`${data?.email_address}:${data?.password}`);
    const endpoint = "/v1/auth/signup/5"; 

    try {
      const res = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      });

      if (!res?.data?.error) {
        setLoading(false);
        toast.success("User is created");
        navigate('/mentor-manager/mentors-list')
        reset();
       
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  return (
    <div
      className="admin-users-page py-5 pt-4"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <h4 className="mb-5" style={{ fontWeight: "700" }}>
        Create New Mentor 
      </h4>
      <div className="row mx-0">
        <div className="col-lg-6 col-md-6 col-sm-12 px-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mx-0">
              <div className="col-lg-6 col-md-6 col-sm-12 ps-0">
                <TextField
                  label="First Name"
                  required={true}
                  id="first_name"
                  placeholder="Enter first name"
                  register={register}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ps-0">
                <TextField
                  label="Last Name"
                  required={true}
                  id="last_name"
                  placeholder="Enter last name"
                  register={register}
                />
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-lg-6 col-md-6 col-sm-12 ps-0">
                <TextField
                  label="Email"
                  required={true}
                  id="email_address"
                  placeholder="Enter email"
                  register={register}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12  ps-0">
                <PasswordField
                  label="Password"
                  required={true}
                  id="password"
                  register={register}
                />
              </div>
            </div>

            <button
              className="btn btn-primary mt-4 px-5 text-white py-2"
              style={{
                fontWeight: "500",
              }}
              type="submit"
            >
              {loading ? (
                <i className="text-white fa fa-circle-o-notch fa-spin"></i>
              ) : (
                "Create Mentor"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateMentorByManager;
