import React from "react"
import { Link } from "react-router-dom"

import ytLogo from "images/yt-icon.png"
import ytImg from "images/yt-pic.png"

import "styles/employer/ytCards.scss"

const YTCards = ({ text = "", link = "#" }) => {
  return (
    <div className="yt-card p-3">
      <div className="row mx-0">
        <div className="col-4 px-0">
          <img src={ytImg} alt="Image" />
        </div>
        <div className="col-8">
          <img src={ytLogo} alt="Image" />
          <h5
            className="mb-1"
            style={{
              fontSize: "16px",
            }}
          >
            {text}
          </h5>
          <Link
            to={link}
            className="p-0"
            style={{
              fontSize: "12.5px",
            }}
          >
            Click here to watch
          </Link>
        </div>
      </div>
    </div>
  )
}

export default YTCards
