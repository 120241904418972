import axios from "axios";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./plan.scss";

const StudentPlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [allData, setAllData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const [enrollmentsId, setEnrollmentsId] = useState("");

  const { data, loading, error, setFetchData } = useFetch(
    `/v1/enrollments/project/progress/${id}`
  );

  // const { getCraditData } = useFetch( `/v1/evaluation/getCredits`)

  useEffect(() => {
    setEnrollmentsId(data?.data?.id);
  }, [data]);

  const project = data?.data?.project;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get("/v1/subscription/get_subscriptions");

        setAllData(data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleChange = () => {
    setOpen((p) => !p);
  };

  // // console.log(requestedData,enrollmentsId,getCraditData,"user")
  const Intermediate = allData?.data?.data.find((item) => item.id === 8);
  const Expert = allData?.data?.data.find((item) => item.id === 9);

  const CurrentPlan = (data) => {
    setCurrentPlan(data);
    setOpen((p) => !p);
  };

  const EvaluationRequest = async () => {
    try {
      const res = await axios.post("/v1/evaluation/createEvaluationRequest", {
        enrollment_id: enrollmentsId,
        mode: "2",
        requested_date: requestedData,
      });
      // console.log(res, res);
      toast.success("Evaluation Request sent successfully");
      navigate("/student/dashboard", { replace: true });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="plan container">
      <div className="select-plan-detail">
        <div className="select-plan">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="process-plan">
                <div className="plan-txt">
                  <h3>Process</h3>
                  <p>
                    Book an Interview & Get your project work evaluated by a
                    Qollabb Expert.
                  </p>
                  <p>Make payment for the evaluation process.</p>
                  <p>Get interviewd.</p>
                  <p>Unlock your certificate.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
              <div className="plan-form">
                <h3>Plan</h3>
                <div className="flex">
                  <p className="h4">
                    {currentPlan
                      ? currentPlan
                      : user?.subscriptionStatus?.subscription_database
                          ?.subscription_name}{" "}
                    {/* &nbsp; */}
                    {/* <span onClick={() => handleChange()}>(Change Plan)</span> */}
                  </p>
                  {/* {open && (
                    <div className="changePlans">
                      <p onClick={() => CurrentPlan("Intermediate")}>Intermediate</p>
                      <p onClick={() => CurrentPlan("Expert")}>Expert</p>
                      <p onClick={() => CurrentPlan("Extra")}>Extra</p>
                    </div>
                  )} */}
                  <p className="price">
                    <b>{`₹${user?.subscriptionStatus?.price}`}</b>
                    {/* <i className="fa-solid fa-info"></i> */}
                  </p>
                </div>
                <h3>Project Details</h3>
                <div className="flex gap">
                  <img
                    src={
                      project?.project_global_fkey?.employer_account
                        ?.company_logo || project?.branch?.company_logo
                    }
                    alt="Image"
                    width={68}
                    height={68}
                  />
                  <div className="text">
                    <h4>{project?.project_title}</h4>
                    <p>{project?.branch?.address}</p>
                  </div>
                </div>
                <h3>Your Details</h3>
                <div className="flex gap">
                  <img
                    src={user?.profile_photo}
                    alt={user?.first_name}
                    width={68}
                    height={68}
                    className="rounded"
                  />
                  <div className="text">
                    <h4>{user?.first_name}</h4>
                    <p>{user?.email_address}</p>
                  </div>
                </div>
                <h3>Choose date & time</h3>
                <div className="flex option">
                  <label>
                    <input
                      type="date"
                      value={requestedData}
                      onChange={(e) => setRequestedData(e.target.value)}
                      className="wd"
                    />
                  </label>
                  {/* <select>
                <option selected>Select time slot</option>
                <option>09:00</option>
              </select> */}
                </div>
              </div>
              <p className="plan-form">
              Your project report has been successfully submitted for evaluation. The certificate of successful completion with the rating will be issued within next 7 working days.
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button className="paybtn" onClick={() => EvaluationRequest()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPlan;
