import PaymentPopUp from "components/paymentPopup/PaymentPopUp"
import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { subscribeSubscription } from "services/factories/razorpay"
import star from "./Star.svg"
import SubscriptionMentoring from "./SubscriptionMentoring"
import SubscriptionPlan from "./SubscriptionPlan"
import styles from "./subscriptions.module.scss"
import NewPaymentPopup from "./newPaymentPopup"
import useOutsideClick from "hooks/useOutsideClick"
import axios from "axios"



const Subscription = ({ Beginner, Intermediate, Expert }) => {
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate();


  const ref = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
    // handleRefreshFromStudentListTable();
  };

  useOutsideClick(ref, closeModal, isModalOpen);

  const [applied, setApplied] = useState(false);

  // console.log("stud user", user);
  async function initiatePayment(e, id, amount, coupon,location, isInput) {
    e.preventDefault()
    
    if(isInput){
      const getlocation = {"location": location }; 
      // console.log("locationnnnn",getlocation)
      
     await axios.post(
        "/v1/student/profile",
        getlocation
      );
    }


    const session = await subscribeSubscription({
      subscription_id: id,
      amount_rs: parseInt(amount),
      navigate: navigate,
      coupon_name: applied ? coupon : null,
      user: user
    })
    //   if (session) {
    //     toast.success("Payment Successful")
    //  }
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }



  return (
    <div className={`sub_dev ${styles.subscriptionPage} container mt-5 mb-3`}>
      {/*--------------------- Row  1  -------------------*/}

      <div className={`${styles.mainRow} ${styles.row} row`}>
        {/* heading */}
        <div className={` ${styles.subscription} col-md-6 col-12`}>
          <h2 className={styles.title}>Upgrade Your Plan</h2>
        </div>

        {/* Beginner */}
        <div className={`${styles.subscription_type} col-4 col-md-3 `}>
          <div>
            <h3>{Beginner?.subscription_name}</h3>
            <h4>Free</h4>
          </div>
          <button className={styles.subscriptions_btn} onClick={(e) => {
            initiatePayment(e, Beginner.id, Beginner.price)
          }}
            style={{ backgroundColor: user?.subscriptionStatus === null && '#07bc0c', color: user?.subscriptionStatus === null && '#fff' }}
            disabled={user?.subscriptionStatus?.subscription_id === 8 ||
              user?.subscriptionStatus?.subscription_id === 9 ||
              user?.subscriptionStatus === null}
          >{user?.subscriptionStatus === null ? "Active" : "Upgrade"}</button>
        </div>
        {/* Intermediate */}
        <div className={`${styles.subscription_type} col-4 col-md-3 `}>
          <div>
            <div className="d-flex justify-content-center gap-1">
              <h3>{Intermediate?.subscription_name}</h3>
              <div className={styles.star_off_container}>
              <img src={star} alt="Image" className={styles.star_off_img1} />
              <span className={styles.off_span}>
                  37.5% <br /> OFF
                </span>

              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(7999)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Intermediate?.price)}
                <span>(valid for 6 months)</span>
              </h4>
            </div>
          </div>
          <button className={styles.subscriptions_btn} 
          // onClick={(e) => {
          //   initiatePayment(e, Intermediate.id, Intermediate.price)
          // }}
          onClick={()=> setIsModalOpen(prev => !prev)}
            disabled={user?.subscriptionStatus?.subscription_id === 8 ||
              user?.subscriptionStatus?.subscription_id === 9}
            style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 8 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 8 && '#fff' }}
          >{user?.subscriptionStatus?.subscription_id === 8 ? "Active" : "Upgrade"}</button>
          


        </div>

        {/* Expert */}
        {/* <div className={`${styles.subscription_type} col-4 col-md-3 `}>
          <div>
            <div className="d-flex justify-content-between gap-4">
              <h3>Expert</h3>
              <div className={styles.star_off_container}>
                <img src={star} alt="Image" className={styles.star_off_img1} />
                <span className={styles.off_span}>
                    36% <br /> OFF
                  </span>
              </div>
            </div>
            <div>
              <small>
                <del>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumSignificantDigits: 3,
                  }).format(10000)}
                </del>
              </small>
              <h4>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  maximumSignificantDigits: 3,
                }).format(Expert?.price)}
                <span>(valid for 1 year)</span>
              </h4>
            </div>
          </div>
          <button className={styles.subscriptions_btn} onClick={(e) => {
            initiatePayment(e, Expert.id, Expert.price)
          }}
            style={{ backgroundColor: user?.subscriptionStatus?.subscription_id === 9 && '#07bc0c', color: user?.subscriptionStatus?.subscription_id === 9 && '#fff' }}
            disabled={
              user?.subscriptionStatus?.subscription_id === 9
            }
          >{user?.subscriptionStatus?.subscription_id === 9 ? "Active" : "Upgrade"}</button>
        </div> */}
      </div>

      {/*--------------------- Row  2  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Access to Projects, Internships & Jobs posted by companies
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
      </div>

      {/*--------------------- Row  3  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Company projects you can work on simultaneously
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>01 Project at a time</p>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>02 Project at a time</p>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>03 Project at a time</p>
        </div> */}
      </div>

      {/*--------------------- Row  4  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Apply to all internships or job opportunties for freshers
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>05 Applications per Day</p>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>10 Applications per Day</p>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>Unlimited</p>
        </div> */}
      </div>

      {/*--------------------- Row  5  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Access to all information and data as provided by company for
            working on Projects
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
      </div>

      {/*--------------------- Row 7  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Build your project work portfolio by submitting your project reports
            to employers
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.yes}>
            <i className="fa-solid fa-check"></i>
          </span>
        </div> */}
      </div>
      {/*--------------------- Row 8  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Evaluation and rating of your project work by Industry experts
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>1 Evaluation Free</p>

        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>2 Evaluation Free</p>
        </div> */}
      </div>

      {/*--------------------- Row 9  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Issue of certificate on successful completion of project work.
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>1 Certification Free</p>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>2 Certification Free</p>
        </div> */}
      </div>

      {/*--------------------- Row 10  (What You Will get ) -------------------*/}
      <div className={`${styles.row} row`}>
        <div className={`${styles.subscription} col-md-6 col-12`}>
          <h5 className={`${styles.subTitle}  `}>
            Prioritize job Alerts on whenever a new job is posted by employer
          </h5>
        </div>
        {/* Beginner */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <span className={styles.no}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
        {/* Intermediate */}
        <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>For 6 months</p>
        </div>
        {/* Expert */}
        {/* <div className={`${styles.whatYou_get} col-md-3 col-4`}>
          <p className={styles.service}>For 1 Year</p>
        </div> */}
      </div>

      {/*--------------------- Row 11  (What You Will get ) -------------------*/}

      {
        open && <PaymentPopUp onClose={handleClose} message="Payment"
        // Beginner={Beginner?.price} Intermediate={Intermediate?.price} Expert={Expert?.price}
        />
      }

        <div ref={ref}> 
          {
            isModalOpen && 
              <NewPaymentPopup data={Intermediate} initiatePayment={initiatePayment} closeModal={closeModal} applied={applied} setApplied={setApplied} />
                    
              }
         </div>

   

      <div>
        <SubscriptionPlan />
      </div>

      <div>
        <SubscriptionMentoring />
      </div>
    </div>
  )
}

export default Subscription
