import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import mentorpng from "./mentor.png"
import "./mentorlist.scss"
import Loader from "components/employer/Loader";
const MentorList = () => {
  // const data = useFetch("/v1/student/get-my-sessions");

  const navigate = useNavigate();
  // const mySessions = data?.data?.data;
  // // console.log(mySessions, "data");
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false);
  const [mySessions, setMySessions] = useState([])


  async function joinSession(e, item) {
    e.preventDefault();
    // console.log("inside join session")
    // if (new Date(item?.startTime).getTime() === new Date().getTime()) {
      // console.log("inside if")
    if (1) {
      navigate(`/student/session/${item?.id}/${item?.roomId}`);
    } else {
      toast.warning("Your Session Haven't Started Now");
    }
  // }
}

  const handleAccept = async (e, item) => {
    e.preventDefault()
   
    const res = await axios.put(`/v1/student/updateSessionStatus/${item?.id}`, {
        status: 1   // reschedule accepted 
    });
    if(!res?.data?.error){
      toast.success("Session Accepted Successfully")
    }else{
      toast.error("Error while accepting session")
    }
    setStatus(1)
    // console.log("response", res);
  }


  useEffect(()=> {

    async function fetchSession(){
      setLoading(true)
      const data = await axios.get("/v1/student/get-my-sessions");
  
      setMySessions(data?.data?.data);
      setLoading(false)
      // console.log("session", data?.data?.data)
    }
  
    fetchSession();
  
  },[status])

  if (loading) return <Loader />;

  return (
    <div className="student_mentor-list container">
      <div className="table-content">
        <div className="row">
          <div className="col-12">
            <div className="slidepage">
              <h2>My Sessions</h2>
              {/* <div>
                <i className="fa-solid fa-arrow-left"></i>
                <span className="fw-bold">1 </span>
                <span>/20</span>
                <i className="fa-solid fa-arrow-right"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {mySessions && mySessions.length > 0?(
      <div className="row">
        <div className="col-12">
          <div className="student-available-table">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="header_top">
                    <th scope="col">Date Booked</th>
                    <th scope="col">Mentor Name</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Date Started</th>
                    <th scope="col" style={{textAlign: "center"}}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {mySessions?.map((item) => {
                    // const date = new Date(item?.createdAt);
                    const date = moment(item?.createdAt).format("DD-MM-YYYY");
                    // const formattedDate = date.toLocaleDateString("en-US");
                    const formattedDate = date;
                    // const startDate = new Date(item?.startTime);
                    const startDate = moment(item?.startTime)
                      .tz("Asia/Kolkata")
                      .format("DD-MM-YYYY");

                    const startTime = moment(item?.startTime)
                      .tz("Asia/Kolkata")
                      .format("hh:mm A");
                    const endTime = moment(item?.endTime).tz("Asia/Kolkata").format("hh:mm A");
                    // const startingDate = startDate.toLocaleDateString("en-US");
                    const startingDate = startDate;
                    return (
                      <tr>
                        <td>{formattedDate}</td>
                        <td className="text-left">
                          {item?.mentor?.first_name} {item?.mentor?.last_name}{" "}
                        </td>
                        <td className="des-color fw-bold">
                          {item?.enrollment?.project?.project_title?.length > 70
                            ? item?.enrollment?.project?.project_title?.slice(
                                0,
                                70
                              ) + "..."
                            : item?.enrollment?.project?.project_title}
                        </td>
                        <td style={{ color: item?.status === 2 ? "#f96767" : ""  }}>
                          {startingDate}
                          <br />
                          ({startTime} - {endTime})
                        </td>

                          {item?.status === 1 && (
                        <td>
                            <button
                              className="bg-success"
                              onClick={(e) => {
                                joinSession(e, item);
                              }}
                            >
                              Join
                            </button>
                          </td>
                          )}
                          {item?.status === 2 && (
                            <td style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                            <button className="gr" onClick={(e)=> handleAccept(e, item)}>Accept</button>
                            
                            <button className="gray">Rescheduled</button>
                            </td>
                          )}
                          {item?.status === 3 && (
                            <td>
                              <button className="gray">Completed</button>
                            </td>
                          )}
                          {item?.status === 4 && (
                            <td>
                              <button className="bg-danger">Cancelled</button>
                            </td>
                          )}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      ):

      <div
          className="d-flex align-items-center justify-content-center"
        >
         <div className="d-flex align-items-center flex-column justify-content-center">
             {/* <img src={mentorpng} alt="mentor-img" style={{width:'40%' ,height:'40%'}} /> */}
             <h3 className="fw-bold d-flex align-items-center">You currently have no mentor sessions booked.</h3>
        </div>
        </div>
    
      }
    </div>
  );
};

export default MentorList;
