
import { useEffect, useState } from "react";
// import "./statusProgress.scss"
import Progress from "./Progress";

const ProgressStatus = ({ status, reports, final_submission = false}) => {
  // console.log({ status, reports, final_submission }, "StatusProgress");
  const [progress, setProgress] = useState(() => 100)
  // console.log({ status, reports, progress })
  
  useEffect(() => {
    // console.log("reports",reports)
    // console.log("reports type", Array.isArray(reports))
    // console.log("reports type", typeof reports )

    // const getProgress = () => {
    //   switch (status) {
    //     case 5:
    //     case 2:
    //       return 100
    //     case 0:
    //     case 1:
    //     case 4:
    //     case 3:
    //     default:
    //       return 0
    //   }
    // }

    // const progress = getProgress()

    if (!final_submission) {
    const completed = reports?.reduce((acc, curr)=> {
        return curr?.report ? acc + 1 : acc
    }, 0)
      setProgress((completed / reports?.length) * 100)
    } else {
      setProgress(100)
    }
  }, [status, reports, final_submission])

  // console.log("progress...", progress)
  // console.log("progress...", isNaN(progress))
  return (
    <div className="align-items-center">
      <Progress
        percentage={isNaN(progress) ? 0 : progress}
        stroke={`hsl(${progress}, 50%, 50%)`}
        strokeWidth={12}
      />
    </div>
  )
}

export default ProgressStatus
