const Checkbox = ({
  id,
  label,
  register,
  options = {},
  checked = false,
  link1,
  link2,
  setCheckedTermCondition=()=>{}
}) => {
  return (
    <div className="form-check mt-2  align-items-center d-flex">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        id={id}
        defaultChecked={checked}
        {...register(id, { ...options })}
        onChange={(e)=>setCheckedTermCondition((prev)=> !prev)}
      />
      <label className="form-check-label" htmlFor={id}>
        <small>
          {label}
          {link1}
          and
          {link2}
        </small>
      </label>
    </div>
  )
}

export default Checkbox
