import React from 'react';
import "./mentorcard.scss";
import mentorImg from "../../../../../images/image 47.png";
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { PiBookBookmarkFill } from "react-icons/pi";


const MentorCard = ({data, setMentorPopup, setSelectedMentor}) => {

    const navigate = useNavigate();

    const openPopup = ()=> {
        setSelectedMentor(data)

        setMentorPopup(prev => !prev)
    }

  return (
    <div className='mentor_card_wrapper'>
        <div className="mentor_details">

           {/* <Link to={`/student/mentor/${data?.id}`} > */}
                <div className="details_top" onClick={()=> navigate(`/student/mentor/${data?.id}`)}>
                    <div className="profile_img">
                        <img src={data?.profile_photo ?? mentorImg} alt="profile" />
                    </div>

                    <div className="mentor_name">
                        <h3>{data?.first_name} {data?.last_name}</h3>
                        <div className="mentor_location">
                            {data?.location?.split(", ").length >= 2 ? `${data?.location?.split(', ')[0]}, ${data?.location?.split(', ')[1]}` : "" }
                        </div>
                    </div>
                </div>
           {/* </Link>  */}


            {/* mentor detail */}
            <div className="mentor_detail">
                <div className="industry" >
                    <span><PiBookBookmarkFill /></span>

                    <p>{`${data?.industry_year_expreience ? parseInt(data?.industry_year_expreience) : "-"} yrs+ Experience in`} <span>{data?.industry?.industry_name ?? "-"}</span> </p>
                    {/* <h4>{`#${data?.industry?.industry_name ?? "-"}`} </h4> */}

                </div>

                <div className="mentor_about item">
                    <h4>About Mentor: </h4>

                    <p>{data?.bio ?? "No Bio"}</p>
                </div>

                <div className="mentor_skills">
                    <h4>Skills: </h4>

                    <div className="skills_chips ">
                        { data?.skills !== null ?
                            data?.skills?.slice(0, 3)?.map((item, i)=> (
                                <div className="skill_chip" key={i}>
                                    {item}
                                </div>
                            )) :
                            (
                                <p>
                                    No skill added
                                </p>
                            )
                        }

                    </div>
                    {/* <p><span><MdOutlineCurrencyRupee /></span> {data?.mentor_charges ?? "-"} / Session</p> */}
                </div>
            </div>



        </div>

            {/* Book button */}
            <div className="book_btn_wrapper">
                <div className="bottom_content">

                    <div className="mentor_charges">
                    <span className='charges_'><MdOutlineCurrencyRupee /></span>{data?.mentor_charges ?? "-"} / <span className='session_'>Session</span>
                    </div>

                    <div className='book_mento_btn'>
                        {/* <Link to={`/student/mentor/${data?.id}`}> */}
                            <button onClick={openPopup} >
                                Book Mentor
                            </button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>

    </div>
  )
}

export default MentorCard