import React, { useEffect, useReducer, useState } from "react";
import Freelancerimg from "images/Freelancer.png";
import { useNavigate } from "react-router-dom";
import CollegesCards from "view/dashboard/employer/components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "view/dashboard/employer/components/collegesCards/collegesDetailCards/CollegesDetailCards";
import "../suggestedCard.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import StudentCard from "view/dashboard/employer/components/studentCard/StudentCard";
import StudentDetailsCard from "view/dashboard/employer/components/studentCard/studentDetailsCard/StudentDetailCard";
import ListView from "view/dashboard/employer/components/list/ListView";
import { useSelector } from "react-redux";
import {
  reducer,
  initialState,
} from "view/dashboard/student/explore/handleFilters";
import StudentInviteFilter from "../../studenInviteFilter.jsx/StudentInvite";
const InvitationsSent = ({
  enrollments = [],
  post = {},
  handleStatusChange,
  method,
  new1,
  setNew1,
}) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const [educators, setEducators] = useState(0);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  // console.log({ enrollments, post })
  const style1 = {
    background: " #F9B035",
  };
  const style2 = {
    display: "none",
  };

  const [refresh, setRefresh] = useState(false);

  const [studentDataDetails, setStudentDataDetails] = useState();

  const { id } = useParams();

  const opportunity_id = id;

  // const { method, opportunity_id, status } = req.query;

  const [state1, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  useEffect(() => {
    const filters = Object.keys(state1).reduce((acc, key) => {
      if (state1[key] && state1[key].length) {
        acc[key] = state1[key];
      }
      return acc;
    }, {});

    const fetchData = async () => {
      const response = await axios.get(
        `/v1/invitations/get_student_invites?status=1&method=${method}&opportunity_id=${opportunity_id}`,
        {
          params: filters,
        }
      );

      setInstitutions(response?.data?.data);
    };

    fetchData();
  }, [refresh, state1]);

  const [educatorDataDetails, setEducatorDataDetails] = useState();
  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/institution/single/${institutions[educators].receiver.id}`
      );

      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // console.log("function 2 working")
  }, [educators, institutions]);

  const [search, setSearch] = useState("");

  return (
    <>
      {institutions.length > 0 ? (
        <>
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="content-tab">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="searchbox">
                      <label>
                        {" "}
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          placeholder="Search candidate"
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </label>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      {institutions
                        ?.filter((item) => {
                          return (
                            item?.receiver?.first_name
                              ?.toLowerCase()
                              .includes(search?.toLowerCase()) ||
                            item?.receiver?.last_name
                              .toLowerCase()
                              .includes(search?.toLowerCase())
                          );
                        })
                        ?.map((institution, index) => {
                          const collegesFunction = () => {
                            setEducators(index);
                            setShow(true);
                            console.log("function working");
                          };
                          return (
                            <div onClick={collegesFunction}>
                              <StudentCard students={institution?.receiver} />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                    <StudentDetailsCard
                      studentDetails={institutions[educators]?.receiver}
                      action2={1}
                      // text2="Send Invitation"
                      // text1={"Not Interested"}
                      text3={"Pending"}
                      pageLength={institutions?.length}
                      setPage={setEducators}
                      currentPage={educators}
                      setRefresh={setRefresh}
                      method={method}
                    />
                  </div>
                  {show && (
                    <div className="w-100 position-fixed mobo_main_college_datail d-flex d-md-none ">
                      <i
                        class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                        aria-hidden="true"
                        onClick={() => setShow(false)}
                      >
                        {" "}
                        Back
                      </i>
                      <StudentDetailsCard
                        studentDetails={institutions[educators]?.receiver}
                        action2={1}
                        // text2="Send Invitation"
                        // text1={"Not Interested"}
                        text3={"Pending"}
                        pageLength={institutions?.length}
                        setPage={setEducators}
                        currentPage={educators}
                        setRefresh={setRefresh}
                        method={method}
                      />
                    </div>
                  )}
                  <StudentInviteFilter dispatch={dispatch} state1={state1} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"></div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
                <StudentInviteFilter
                  flag={false}
                  dispatch={dispatch}
                  state1={state1}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ShowBothListAndDetail = ({ state, method, setNew1 }) =>
  state ? <InvitationsSent method={method} /> : <List method={method} />;

const style1 = {
  display: "none",
};
const style2 = {
  background: "#47D065",
  borderRadius: "8px",
};
const style_i = {
  color: "white",
};

export default ShowBothListAndDetail;

const List = ({ method, setNew1 }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const [state1, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const opportunity_id = id;

  useEffect(() => {
    const filters = Object.keys(state1).reduce((acc, key) => {
      if (state1[key] && state1[key].length) {
        acc[key] = state1[key];
      }
      return acc;
    }, {});

    const fetchData = async () => {
      const response = await axios.get(
        `/v1/invitations/get_student_invites?status=1&method=${method}&opportunity_id=${opportunity_id}`,
        {
          params: filters,
        }
      );

      setEnrolledStudents(response?.data?.data);
    };

    fetchData();
  }, [refresh, state1]);

  console.log(enrolledStudents, "enrolledStudents");

  return (
    <>
      {enrolledStudents?.length > 0 ? (
        <div className="d-flex gap-4">
          <ListView
            text2="Send Invitation"
            style1={style1}
            style2={style2}
            style_i={style_i}
            id={id}
            data={enrolledStudents}
            method={method}
            refresh={refresh}
            setRefresh={setRefresh}
            tableFor="student"
            setNew1={setNew1}
            type={"receiver"}
          />
          <StudentInviteFilter dispatch={dispatch} state1={state1} />
        </div>
      ) : (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"></div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
                <StudentInviteFilter
                  flag={false}
                  dispatch={dispatch}
                  state1={state1}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
