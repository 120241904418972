import { Link, useLocation, useNavigate } from "react-router-dom"
import "./topbar.scss"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { HiMagnifyingGlass } from "react-icons/hi2"
import { IoCloseSharp } from "react-icons/io5"

const Topbar = ({ toggleSidebar, userImg, name, toggleAlertBox, isNotification }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isSubPath = pathname.split("/").length > 3
  const dispatch = useDispatch();




  const [searchKeyword, setSearchKeyword] = useState('');
  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChange = (e) => {
    // getSearch(e?.target?.value || "");
    const keyword = e.target.value;

    // console.log("searcheeeeekeyyyyyy", keyword)
    
    dispatch({
      type: "SEARCH_KEYWORD",
      payload: {keyword: keyword}
    })

    setSearchKeyword(keyword);
  };

  const decouncedHandleChange = debounce(handleChange, 1000);

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);

    decouncedHandleChange(e);
  };

  const clearSearch = () => {
    dispatch({
      type: "SEARCH_KEYWORD",
      payload: {keyword: ""}
    }) 
    setSearchKeyword('');
  };





  return (
    <div className="topbar p-2 px-3 d-flex justify-content-between">
       


      <div>

        <button
          className="btn d-lg-none d-inline-block"
          onClick={() => {
            toggleSidebar(true)
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        {isSubPath ? (
          <button
            className="mx-2"
            style={{ backgroundColor: "white", color: "#000" }}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
        ) : (
          <></>
        )}
      </div>

      <Link to={"/educator/explore"} >
            <div className="explore-search">
            <input type="text" placeholder="Search projects/jobs/internships" value={searchKeyword} onChange={handleSearchChange}  />
            {searchKeyword ? (
              <span className="clear-search " onClick={clearSearch}>
                <IoCloseSharp size={25} />
              </span>
            ) : (
              <span className="search-icon"> <HiMagnifyingGlass color="#fff"  /> </span>
            )
          
          }
          </div>
          </Link>



      <div className="icons-container d-flex align-items-center">
        {/* <div className="icons me-3">
          <i className="fa-solid fa-magnifying-glass"></i>
        </div> */}
        <Link to={"/educator/chat"}>
          <div className="icons me-3">
            <i className="fa fa-commenting"></i>
          </div>
        </Link>

        <div className="icons me-3" style={{position: "relative"}}  onClick={()=> toggleAlertBox(true)} >
            <i className="fa-solid fa-bell" ></i>
            <span className={`${isNotification ? "indicator": ""}`} style={{position: "absolute", left: "31px", top: "4px"}}></span>
        </div>

        <Link to="/educator/profile">
        
        <div className="icons" title={`Hello ${name}`}>
          {!userImg ? (
            <span>{name.charAt(0).toUpperCase()}</span>
          ) : (
            <img
              src={userImg}
              width="45px"
              height="45px"
              alt={name.charAt(0).toUpperCase()}
            />
          )}
          </div>
          </Link>
      </div>
    </div>
  )
}

export default Topbar
