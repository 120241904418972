import moment from "moment";
import { addApplicationData } from "services/actions/application";
export const duplicateInternship = ({ data, dispatch }) => {
  if (!data) return;
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  // console.log({ data });
  const {
    skills,
    courses,
    duration_from,
    duration_to,
    keywords,
    screening_questions,
    office_visit,
    internship_title,
    roles_and_responsibilities,
    internship_category,
    vacancies,
    stipend_from,
    stipend_to,
    city,
    location,
    expected_start_date,
    internship_city,
  } = data;

  const parse = (data) => {
    if (!data) return [];
    try {
      return JSON.parse(data);
    } catch (error) {
      return [];
    }
  };

  const specific = {
    duration_from,
    duration_to,
    // required_skills: skills,
    internship_title,
    roles_and_responsibilities,
    incentive_from: stipend_from,
    incentive_to: stipend_to,
    //2023-10-01
    joining_date: moment(expected_start_date).format("YYYY-MM-DD"),
    vacancies,
    office_visit,
    internship_category: map[internship_category],
    internship_city,
    ...(city
      ? {
          city: {
            id: city,
            location: `${location?.city_name}, ${location?.state?.state_name}`,
          },
        }
      : {}),
    ...(location
      ? {
          location: `${location?.city_name}, ${location?.state?.state_name}`,
        }
      : {}),
  };

  dispatch(addApplicationData({ method: "internship", data: specific }));

  // dispatch(
  //   addApplicationData({ method: "internship", step: 2, data: specific["2"] })
  // );
  // dispatch(
  //   addApplicationData({ method: "internship", step: 3, data: specific["3"] })
  // );
  // dispatch(
  //   addApplicationData({ method: "internship", step: 4, data: specific["4"] })
  // );
};
