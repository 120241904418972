import React from "react"
import "./chip.scss"

const Chips = ({
  items = [],
  deleteItem = () => {},
  id = "",
  key_name = "name",
  hideDelete = false,
}) => {

  // console.log("chipss1", items, key_name)
  // console.log("chipss2", id)

  return items?.map((item, index) => (
    <span className="chip" key={item?.id || index}>
      {item[key_name] || item}
      {hideDelete ? (
        <></>
      ) : (
        <i
          className="fa-regular fa-circle-xmark text-white"
          onClick={() => deleteItem(item[key_name] || item, id)}
        ></i>
      )}
    </span>
  ))
}

export default Chips
