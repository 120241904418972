import React from "react";
import { useEffect } from "react";
import { set } from "react-hook-form";
const Checkbox = ({
  id,
  label,
  myClass = "form-check mt-2  align-items-center d-flex",
  register,
  options = {},
  isSmall = false,
  checked = false,
  setFlag = () => {},
  flag,
  setIsRemote,
  getValues,
  setChecked = ()=> {},
}) => {
  const handleChange = () => {
    setChecked((p) => !p);
  };

  // console.log(checked)

  return (
    <div className={myClass}>
      <input
        className="form-check-input"
        type="checkbox"
        // value={checked}
        id={id}
        // defaultChecked={checked}
        checked={checked}
        {...register(id, { ...options })}
        onChange={handleChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {isSmall ? <small>{label}</small> : label}
      </label>
    </div>
  );
};

export default Checkbox;
