import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import StudentListTable from "../../components/studentListTable/StudentListTable";
import "../../explore/educatorExplore.scss";
import "./studentList.scss";
import { useParams } from "react-router-dom";
import CreateListPopup from "../../components/StudentListPopups/CreateListPopup";
import ManualUploadPopup from "../../components/StudentListPopups/ManualUploadPopup";

const StudentList = () => {
  const [show, setShow] = useState(false);
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState({
    course: "",
    specialization: "",
    semester: "",
  });

  const {id} = useParams();

  // console.log("iddddddddddd", id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const[loading,setLoading]=useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);



  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
    // handleRefreshFromStudentListTable();
  };

  const closeModal2 = () => {
    setIsModalOpen2(!isModalOpen2);
    // handleRefreshFromStudentListTable();
  };



  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (search.semester && isNaN(parseInt(search.semester))) {
      toast.error("Semester must be a number");
      return;
    }
    const getStudents = async () => {
      const res = await axios.get(`/v1/educator/invite/get-students/${id}`, {
        params: {
          course: search.course,
          specialization: search.specialization,
          semester: search.semester ? parseInt(search.semester) : null,
        },
      });
      setStudents(res?.data?.data);
    };
    getStudents();
  }, [refresh]);

  const [values, setValues] = useState([]);
  const sendInvites = async () => {
    const selected = values.filter((x) => x[5]);
    const ids = selected.map((x) => x[6]);
    const { data } = await axios.post("/v1/educator/invite", {
      invitee_ids: ids,
      post_id: students?.id,
      method: "project",
    });

    if (data.error) {
      toast.error(data.message);
    } else {
      toast.success(data.message);
    }
  };
  const tempData = [
    {
      student_name: "",
      course: "",
      specialization: "",
      semester: "",
      email_id: "",
    },
  ];
  const changeHandler = (event) => {
    // console.log("hello world");
    try {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const valuesArray = results.data.reduce(
            (accu, curr) => {
              // console.log(curr);
              if (!curr.email_id) {
                return { ...accu, skipped: accu.skipped + 1 };
              }
              if (
                !curr.email_id.match(
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                )
              ) {
                return { ...accu, invalid: accu.invalid + 1 };
              }
              if (values.some((x) => x["email_id"] === curr.email_id)) {
                return { ...accu, dublicate: accu.dublicate + 1 };
              }

              const {
                name = "",
                student_name = "",
                course = "",
                specialization = "",
                semester = null,
                email_id: email_address = null,
              } = curr;

              return {
                ...accu,
                data: [
                  ...accu.data,
                  [
                    name || student_name,
                    course,
                    specialization,
                    semester,
                    email_address,
                  ],
                ],
              };
            },
            {
              skipped: 0,
              invalid: 0,
              dublicate: 0,
              data: [],
            }
          );

          // console.log("studettttdataaa", valuesArray.data);

          if (valuesArray.skipped) {
            toast.warning(`Skipped ${valuesArray.skipped} rows.`);
          }
          if (valuesArray.invalid) {
            toast.warning(
              `Skipped ${valuesArray.invalid} invalid emails. Please check the email format.`
            );
          }
          if (valuesArray.dublicate) {
            toast.warning(`Skipped ${valuesArray.dublicate} dublicate emails.`);
          }

          if (!valuesArray.data.length) return;

         

          const uploaded = await axios.post(
            "/v1/educator/invite/upload_students",
            {
              invitee_ids: valuesArray.data,
              // collection_name: "first"
            }
          );

          if (uploaded.data.error) {
            toast.error(uploaded.data.message);
          } else {
            const added = uploaded.data.data;
            const newValues = added
              .map((id, index) => {
                if (id) {
                  return [...valuesArray.data[index], true, id];
                } else return null;
              })
              .filter((x) => x);

            setValues([...values, ...newValues]);
            setRefresh(!refresh);

            toast.success(uploaded.data.message);
          }
        },
      });
    } catch (err) {
      // console.log(err);
    } finally {
      event.target.value = null;
    }
  };




  // console.log("students", students);
  // // console.log("students..", processedData);
  const toggleOn = () => setShow((p) => !p);
  return (
    <div
      className="student-container py-4 p-2"
    >
      <div className="student_list_top  ">
        <h5 className="mb-0 " style={{ fontWeight: "700" }}>
          Student List
        </h5>
        <div className="actions_btn actions_btn_hide ">
          <div className="right_btn">
            {/* <button
              className="btn download_btn"
              onClick={() => downloadCSV(tempData)}
            >
              Download Template
            </button> */}
            <label htmlFor="" className="btn upload_btn"   onClick={()=>setIsModalOpen(!isModalOpen)}>
              Upload New List
            </label>
            {/* <input
              id="csv-upload"
              type="file"
              name="file"
              accept=".csv"
              onChange={(e) => {
                changeHandler(e);
              }}
              style={{ display: "none", margin: "10px auto" }}
            /> */}
          </div>
        </div>

        {isModalOpen && (
          <CreateListPopup existsName={students[0]?.list?.list_name}  onClose={closeModal} setIsModalOpen2={setIsModalOpen2}  setRefresh={setRefresh} setLoading={setLoading} loading={loading} />
        )}

        {isModalOpen2 && (
          <ManualUploadPopup existsName={students[0]?.list?.list_name}  onClose={closeModal2} setRefresh={setRefresh} setLoading={setLoading} loading={loading} />
        )}

        {/* <div className="right_btn">
          <button
            className="btn download_btn"
            onClick={() => downloadCSV(tempData)}
          >
            Download Template
          </button>
          <button
            className="btn upload_btn"
            onClick={(e) => {
              // console.log(e.target.files[0], "hello world");
              changeHandler(e);
            }}
          >
            Upload New List
          </button>
        </div> */}
      </div>

      <div className="student_list_search">
        <div className="search_dropdown_main">
          {/* make a the input box for three  */}
          <input
            type="text"
            placeholder="Course"
            className="form-control"
            value={search.course}
            onChange={(e) => setSearch({ ...search, course: e.target.value })}
          />
          <input
            type="text"
            placeholder="Specialization"
            className="form-control"
            value={search.specialization}
            onChange={(e) =>
              setSearch({ ...search, specialization: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Semester"
            className="form-control"
            value={search.semester}
            onChange={(e) => setSearch({ ...search, semester: e.target.value })}
          />
        </div>
        <div className="searchbtn">
          <button
            onClick={() => setRefresh(!refresh)}
            style={{ minWidth: "200px" }}
            className="py-3"
          >
            <i className="fa fa-search text-white"></i>
            Search
          </button>
        </div>
      </div>

{/* mobile filter box and icon */}

      <div className="mobile_search d-flex justify-content-between align-items-center gap-2 d-md-none mt-2">
          <div className="w-100 search_skills_mobile student_list_top">

          <div className="actions_btn">
          <div className="right_btn">
            {/* <button
              className="btn download_btn"
              onClick={() => downloadCSV(tempData)}
            >
              Download Template
            </button> */}
            <label htmlFor="" className="btn upload_btn"   onClick={()=>setIsModalOpen(!isModalOpen)}>
              Upload New List
            </label>
            {/* <input
              id="csv-upload"
              type="file"
              name="file"
              accept=".csv"
              onChange={(e) => {
                changeHandler(e);
              }}
              style={{ display: "none", margin: "10px auto" }}
            /> */}
        </div>
          </div>

          </div>

          <div className="toggle_box">
            <span className="filter d-block d-md-none mb-2">
              <i
                class="fa-solid fa-sliders fa-lg "
                style={{ color: "#6149CD" }}
                onClick={toggleOn}
              ></i>
            </span>
          </div>
        </div>

      <div className="explore_grid row mt-4">
   <div
    className={`w-100 col-md-3 col-lg-3 mb-4 mobile_FilterBox ${
      show ? "open" : ""
    } `}
  >
    {show && (
      <div className="mobo_filter d-block d-md-none">
        <div className={`mobile_FilterBtn my-2 d-flex justify-content-between align-items-center gap-2 w-100 d-md-none ${show ? "open" : ""}`}>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <h4 className="m-0" style={{ color: "#6149CD" }}>
              Filters
            </h4>
            <span className="d-block mt-1">
              <i
                className="fa-solid fa-sliders fa-lg"
                style={{ color: "#6149CD" }}
              ></i>
            </span>
          </div>
          <div className="toggle_box">
            <span className="d-block filter">
              <i
                className="fa-solid fa-x fa-lg"
                style={{ color: "#6149CD" }}
                onClick={toggleOn}
              ></i>
            </span>
          </div>
        </div>

        {/* Add filter inputs */}
        <div className="row mt-2 p-1">
          <div className="col-12 m-2">
        <input
          type="text"
          placeholder="Course"
          className="form-control"
          value={search.course}
          onChange={(e) => setSearch({ ...search, course: e.target.value })}
        />
        </div>
        <div className="col-12 m-2">
        <input
          type="text"
          placeholder="Specialization"
          className="form-control"
          value={search.specialization}
          onChange={(e) =>
            setSearch({ ...search, specialization: e.target.value })
          }
        />
        </div>
        <div className="col-12 m-2">
        <input
          type="text"
          placeholder="Semester"
          className="form-control"
          value={search.semester}
          onChange={(e) => setSearch({ ...search, semester: e.target.value })}
        />
        </div>

        {/* <div className="col-12 col-sm-6 col-md-4 m-2 " style={{textAlign:'center'}}>
          <button
            onClick={() => {setRefresh(!refresh); setShow(false)}}
           
       
            className="py-3"
            style={{width:'300px',borderRadius:'10px',fontSize:'20px'}}
        
          >
            
            <i className="fa fa-search text-white"></i>
            Search
          </button>
        </div> */}

          <div
          className="col-12 m-2"
            style={{
                    textAlign: 'center',
                   borderRadius: '10px',
                    fontSize: '20px',
                   padding: '10px', 
                  backgroundColor: '#6149CD', 
                  color: '#fff',
                   cursor: 'pointer', 
                  fontWeight:'bold'
                  }}
          onClick={() => {
                 setRefresh(!refresh);
                   setShow(false);
                        }}
                >
              <i className="fa fa-search text-white"></i>
             Search
          </div>


        </div>
      </div>
    )}
  </div>
      </div>

{/* mobile filter box End */}



      <StudentListTable flag={false} data={students} setRefresh={setRefresh} />

    </div>
  );
};

export default StudentList;
