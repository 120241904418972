import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addApplicationData } from "services/actions/application";
import PreviewField from "components/employer/PreviewField";

const AiTextModal = ({ isPopupOpen, setIsPopupOpen, type, showAiData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNext = () => {
    dispatch(addApplicationData({ method: type, data: showAiData }));
    navigate(`/employer/${type}/add`);
  };

  return (
    isPopupOpen && (
      <div className="popup_container d-flex flex-column pointer p-4">
        <div className="no_projects text-start d-flex flex-column col-12">
          <i
            className="fa-solid fa-robot fa-xl mt-2 text-center"
            style={{
              color: "#6149cd",
            }}
          ></i>
          <label className="my-2"></label>
          {showAiData &&
            Object.keys(showAiData).map((key, index) => {
              return (
                <div className="d-flex  mt-2 flex-column">
                  {/* <label className="text-capitalize h3 key-label-ai-modal">
                    {key.split("_").join(" ")}:
                  </label> */}
                  <PreviewField
                    label={key.split("_").join(" ")}
                    text={showAiData[key]}
                    labelClass="text-capitalize"
                  />
                </div>
              );
            })}
        </div>
        <i
          className="close-btn fa-regular fa-times fa-x"
          onClick={() => setIsPopupOpen(false)}
        ></i>
        <button onClick={handleNext} className="bg-primary col-12">
          Continue
        </button>
      </div>
    )
  );
};

export default AiTextModal;
