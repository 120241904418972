import Topbar from "components/dashboard/topbar/Topbar";
import "components/formComponents/style.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import "styles/employer/companyDashboard.scss";
import "styles/employer/statbox.scss";
import Sidebar from "view/dashboard/admin/sidebar";

const AdminDashboard = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [posts, setPosts] = useState(() => ({
    Internships: [],
    Jobs: [],
    Projects: [],
  }));

  // // console.log(user.first_name, "name");
  const [loading, setLoading] = useState(() => false);
  const userImage = user?.profile_pic;
  const toggleSidebar = (val) => {
    setShowSidebar(val);
  };
  const sidebarTitle = {
    text1: "Dashboard",
    text2: "Students",
    text3: "Mentoring Sessions",
    text4: false,
    text5: "Project Evaluations",
    text6: "Calendar",
    text7: "Settings",
  };
  // useEffect(() => {
  //   const getData = async () => {
  //     const opts = {
  //       valdateStatus: () => true,
  //     }

  //     const internship = get("/v1/employer", opts)
  //     const job = get("/v1/enrollments/job", opts)
  //     const project = get("/v1/enrollments/project", opts)

  //     await axios
  //       .all([internship, job, project])
  //       .then(
  //         axios.spread((...responses) => {
  //           const internships = responses[0]?.data?.data || []
  //           const jobs = responses[1]?.data?.data || []
  //           const projects = responses[2]?.data?.data || []

  //           setPosts((p) => ({
  //             ...p,
  //             Internships: internships,
  //             Jobs: jobs,
  //             Projects: projects,
  //           }))
  //           setLoading(false)
  //         })
  //       )
  //       .catch(() => {
  //         setLoading(false)
  //       })
  //   }
  //   getData()

  //   return () => {}
  // }, [user.id])

  // console.log(posts);
  return (
    <div className="dashboard">
      <Sidebar
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        projects={posts?.Projects?.length}
        internships={posts?.Internships?.length}
        jobs={posts?.Jobs?.length}
      />
      <div className="main">
        <Topbar
          toggleSidebar={toggleSidebar}
          userImg={userImage}
          name={user?.first_name}
        />
        <Outlet context={[posts, setPosts, loading]} />
      </div>
    </div>
  );
};

export default AdminDashboard;
