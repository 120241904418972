import YTCards from "components/employer/YTCards"
import React from "react"
import edu_icon from "../../images/edu_icon.svg"

const EducatorGuideCard = () => {
  return (
    <div className="guide_card my-4 p-4 mx-0 mx-md-4">
      <div className="guide_main flex">
        <div>
          <img src={edu_icon} alt="Image" />
          <p className="guide_card_p">How-To-Guide</p>
        </div>
        <div className="guide_card_text">
          <p>
            Upload your weekly reports for keeping your employer knows your
            porgress
          </p>
        </div>
      </div>
      <div className="youtube">
        <YTCards
          text="How to assign projects, internships or jobs to students"
          link="https://youtu.be/ZiP6TwRsLxY?si=YgmiIDwQ1n82Leq4"
        />
      </div>
    </div>
  )
}

export default EducatorGuideCard
