import Resume from "components/student/Resume";
import { useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Screening from "./Screening";
import axios from "axios";
import { toast } from "react-toastify";
import JobDetails from "../post/FetchJobDetails";
import { useSelector } from "react-redux";

const EnrollJob = ({ defaultStep = 0 }) => {
  const [step, setStep] = useState(() => defaultStep);
  const { id = 0 } = useParams();
  const user = useSelector((state) => state.auth.user);

  const { state } = useLocation();
  const screening_questions = state?.screening_questions || [];

  const onApply = async (screening_questions = "") => {
    const data = await axios.post("/v1/enrollments/job", {
      job_id: id,
      screening_questions,
    });

    if (!data?.data?.error) {
      toast.success(data?.data?.message);
      setStep(2);
    } else {
      toast.error(data?.data?.message);
    }
  };

  const onProceedToApply = () => {
    if (step === -1) return setStep(0);
    if (!screening_questions?.length) return onApply();
    else setStep(1);
  };

  if (!user?.role) {
    localStorage.setItem("path", window.location.pathname);
  }

  switch (step) {
    case -1:
      return <JobDetails id={id} onApply={onProceedToApply} />;
    case 0:
      return <Resume setStep={setStep} onAction={onProceedToApply} />;
    case 1:
      return <Screening screening={screening_questions} onApply={onApply} />;
    default:
      return <Navigate to="/student/explore/*" state={{ enrolled: "job", id }} />;
  }
};

export default EnrollJob;
