import React, { useState } from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import GoToTop from "GoToTop";
import Footer from "view/dashboard/student/footer/Footer";
import ExplorePage from "./ExplorePage";
import { Outlet } from "react-router-dom";
import ExplorePageNew from "./ExplorePage-New";

{
  /* <Route path="/explore-page" element={<ExploreHomePage />}>
        <Route path="project/:id" element={<ProjectDetail />} />
        <Route path="internship/:id" element={<InternshipDetails />} />
        <Route path="job/:id" element={<JobDetails />} />
      </Route> */
}

const ExploreHomePage = () => {

  // const [search, setSearch] = useState("");

  // const getSearch = (search) => {
  //   setSearch(search);
  // }

  return (
    <section className="home_page row justify-content-center align-items-center mx-0 w-100 ">
      <TopNavBar />
      <NavBar  />
      {/* <ExplorePage /> */}
      <ExplorePageNew />
      <Footer />
      <GoToTop />
    </section>
  );
};

export default ExploreHomePage;
