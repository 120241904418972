import { Fragment, useRef, useState } from "react";
import "./dropdown.scss";
import useOutsideClick from "hooks/useOutsideClick";

const Dropdown = ({
  items = [],
  checked = 0,
  setItem = () => {},
  label = "",
  className = "",
  isMultiple = false,
  required = false,
  className1,
  className2,
}) => {
  // console.log(checked, "12345");
  const [isOpen, setOpen] = useState(() => false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpen(false), isOpen);

  const toggleDropdown = () => setOpen((prev) => !prev);

  const handleItemClick = (index) => {
    setItem(index);
    toggleDropdown();
  };

  // console.log(checked, "checked");

  return (
    <div className={`dropdown  ${className}`} ref={ref}>
      {label ? (
        <label>
          {label}
          {required && <span className={`${className1}`}>*</span>}
        </label>
      ) : (
        <></>
      )}
      <div
        className={`dropdown-header dropdown-header-top ${
          isOpen ? "open" : ""
        }`}
        onClick={toggleDropdown}
      >
        {isMultiple && Array.isArray(checked) && checked.length > 0
          ? checked.join(", ")
          : items[checked]}
        {/* {items[checked]} */}
        <i className={`fa fa-chevron-right icon ${isOpen ? "open" : ""}`}></i>
      </div>
      <div className={`dropdown-body ${className2}   ${isOpen ? "open" : ""}`}>
        {items?.map((item, index) => {
          return index !== checked ? (
            <div
              className="dropdown-item"
              onClick={() => handleItemClick(index)}
              key={item}
            >
              {item}
              {isMultiple ? (
                Array.isArray(checked) && checked.includes(item || index) ? (
                  <i className="fa-regular fa-circle-check"></i>
                ) : (
                  <i className="fa-regular fa-circle"></i>
                )
              ) : (
                <></>
              )}
            </div>
          ) : (
            <Fragment key={index}></Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
