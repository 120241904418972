import useRelativeTime from "hooks/useRelativeTime";
import rahulImg from "images/rahul.png";
import "./miniStudentResume.scss";
import { Link } from "react-router-dom";
import ProfileIcon from "components/employer/ProfileIcon";

const MiniStudentApplication = ({ item, onClick = () => {}, flag = false }) => {
  const date = useRelativeTime(item?.createdAt);
  // console.log({ item })
  const DateType = (value) => {
    return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ");
  };
  // date of birth
  const dob = item?.student?.date_of_birth;
  let age;
  if (dob) {
    const dobDate = new Date(dob);

    const diffMs = Date.now() - dobDate.getTime();

    age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  }

  // console.log(`You are ${age} years old`);

  /********/
  // console.log(item?.student?.location, "location");
  // console.log("mini");

  // console.log(item?.student, "hello world");

  return (
    <div className="mini_student_resume" onClick={onClick}>
      <div className="secondtab">
        <div className="flex">
          <div className="student_resume_data">
            <div style={{ width: "64px", height: "64px" }} className="img_div">
              {item?.student?.profile_photo ? (
                <img
                  src={item?.student?.profile_photo}
                  alt={item?.student?.first_name}
                  className="rounded"
                />
              ) : (
                <div style={{ width: "44px", height: "44px" }}>
                  <ProfileIcon />
                </div>
              )}
            </div>
            <div className="student_content w-100">
              <div className="heading_content">
                <span className=" h2">
                  {item?.student?.first_name} {item?.student?.last_name}
                </span>
                <span className="light">{date}</span>
              </div>

              <p className="add">
                {item?.student?.location ? item?.student?.location : ""}
              </p>
            </div>
            {Date.now() - new Date(item?.createdAt).getTime() <
            1000 * 60 * 60 ? (
              <div className="New_student">
                <p>New</p>
              </div>
            ) : (
              <></>
            )}
{/* 
            <div className="download-resume-btn-contaier">
              {item?.student?.student_resume && (
                <div className="download-resume-btn">
                  <p>View Resume</p>
                  <Link
                    to={item?.student?.student_resume}
                    className="link-box"
                    target="_blank"
                  >
                    <span>View Resume</span>
                    <i className="fa-solid fa-download"></i>
                  </Link>
                </div>
              )}
            </div> */}

            {/* {item.student?.student_resume && (
              <div className="col-12 mb-3 view-resume-container-project">
                <div className="view-resume py-2">
                  <label className="w-100">Resume</label>
                  <Link to={item?.student?.student_resume} target="_blank">
                    <span className="view-box-project">View Resume</span>
                  </Link>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="tab-btn">
          {/* <div className="tags">
            <span>UI Designer</span>
            <span>Figma</span>
            <span>Landing Page</span>
          </div> */}

          <div className="bio">
            {item?.student?.course_name ? (
              <p>
                <i className="fa-solid fa-graduation-cap"></i>{" "}
                <span>{item?.student?.course_name}</span>
              </p>
            ) : (
              <></>
            )}
            {age ? (
              <p style={{ fontSize: "12px" }}>
                <i className="fa-solid fa-cake-candles"></i>
                <span>{age} Years old</span>
              </p>
            ) : (
              <></>
            )}
          </div>
          <p className="text">{item?.student?.bio || "no bio"}</p>
        </div>
      </div>

      <div className="thirdtab">
        <div className="row">
          {item.student?.student_resume && (
            <div className="col-12 view-resume-container">
              <div className="view-resume py-2">
                <label className="w-100">Resume</label>
                <Link to={item?.student?.student_resume} target="_blank">
                  <span className="view-box">View Resume</span>
                </Link>
              </div>
            </div>
          )}
          <div className="col-12">
            {item?.student?.experiences?.length ? (
              <div className="education">
                <h3>Experience</h3>
                {item?.student?.experiences?.map((item) => (
                  <div className="education-detail">
                    <h4>
                      <span className="text-wrap">
                        <strong>{item?.designation || "Web Developer "}</strong>{" "}
                        - {item?.company_name || "Satnam Webtech"}
                      </span>{" "}
                    </h4>
                    <p>
                      Project |{DateType(item?.start_date) || "May 2020 "} -{" "}
                      {DateType(item?.end_date) || "August 2020"}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            {/* {item?.student?.experiences?.map((item) => (
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>{item?.designation}</strong> -{" "}
                      {item?.company_name}
                    </span>{" "}
                  </h4>
                  <p>
                    {new Date(item?.start_date)
                      .toGMTString()
                      .split(" ")
                      .slice(2, 4)
                      .join(" ")}{" "}
                    -{" "}
                    {new Date(item?.end_date)
                      .toGMTString()
                      .split(" ")
                      .slice(2, 4)
                      .join(" ")}
                  </p>
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniStudentApplication;
