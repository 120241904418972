import React from "react"
import DownArrow from "../../images/DownArrow.svg"

const OpportunitiesCard = () => {
  return (
    <div className="opportunity w-100 p-4 my-4">
      <h1>Steps to search and assign opportunities to your students</h1>
      <div>
        <div>
          <div className="flex justify-content-start align-items-center">
            <span>1</span>
            <p>Go to explore, Search for Project, Internship or job</p>
          </div>
          <i class="fa fa-angle-down text-black" aria-hidden="true"></i>
        </div>
        <div>
          <div className="flex justify-content-start">
          <span>2</span>
            <p>Select Project, Internship or Job opportunity to be assigned</p>
          </div>
          <i class="fa fa-angle-down text-black" aria-hidden="true"></i>
        </div>
        <div>
          <div className="flex justify-content-start">
          <span>3</span>
            <p>Select Students to whom the opportunity is to be assigned</p>
          </div>
          <i class="fa fa-angle-down text-black" aria-hidden="true"></i>
        </div>
        <div>
          <div className="flex justify-content-start">
          <span>4</span>
            <p>If no previously uploaded students exsist, upload a new list of students using excel template</p>
          </div>
          <i class="fa fa-angle-down text-black" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  )
}

export default OpportunitiesCard
