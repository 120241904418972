import axios from "axios";
import Loader from "components/employer/Loader";
import { duplicateInternship } from "components/employer/duplicate/internship";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "styles/employer/jobContent.scss";
import "styles/employer/mentorjob.scss";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";
import ShowSelecetdTab from "./sections";

const InternshipPost = () => {
  const { id = 5 } = useParams();
  // const { data, loading } = useFetch(`/v1/enrollments/internship/${id}`);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, reduxDispatch] = useReducer(reducer, initialState);
  const [enrollments, setEnrollments] = useState(() => ({
    count: 0,
    data: [],
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filters = Object?.keys(state).reduce((acc, curr) => {
          if (curr === "selected") return acc;
          else if (state[curr] instanceof Set) {
            if (!state[curr].size) return acc;
            const set = [...new Set(state[curr])];
            return { ...acc, [curr]: set };
          } else if (state[curr]) {
            return { ...acc, [curr]: state[curr] };
          }
          return acc;
        }, {});
        const response = await axios.get(`/v1/enrollments/internship/${id}`, {
          params: { ...filters },
        });

        const { error, message, data } = response?.data;
        setData(data);
        if (error) {
          toast.error(message);
        }
        if (data) {
          setEnrollments(() => ({
            count: {
              ...data?.count,
              applied: data?.enrollments?.length,
            },
            data: data?.enrollments,
          }));
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [state]);

  // useEffect(() => {
  //   if (data?.data) {
  //     setEnrollments(() => ({
  //       count: {
  //         ...data?.data?.count,
  //         applied: data?.data?.enrollments?.length,
  //       },
  //       data: data?.data?.enrollments,
  //     }));
  //   }
  // }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  if (loading || !data) {
    return <Loader />;
  }

  const onDuplicate = () => {
    duplicateInternship({ data: data?.post, dispatch });
    navigate("/employer/internship/add");
  };

  const handleDetails = () => {
    navigate(`/employer/internship/details/${id}`);
  };

  const tabMap = {
    "Applications received": "applied",
    "Project Submitted": "submitted",
    Shortlisted: "shortlisted",
    Rejected: "rejected",
    "Interviews Scheduled": "hired",
  };

  const handleStatusChange = ({ status, oldStatus, id }) => {
    // console.log({ status, oldStatus, id });
    // status
    // 0: Applied
    // 1: Left
    // 2: Seen
    // 3: Under Review
    // 4: Shortlisted
    // 5: In touch
    // 6: Hired
    // 7: Rejected

    // const tab = {
    //   0: "applied",
    //   4: "shortlisted",
    //   6: "hired",
    //   7: "rejected",
    // };

    const tab = {
      0: "applied",
      2: "submitted",
      4: "shortlisted",
      6: "hired",
      7: "rejected",
    };

    setEnrollments(() => ({
      count: {
        ...enrollments?.count,
        [tab[status]]: Number.parseInt(enrollments?.count[tab[status]]) + 1,
        [tab[oldStatus]]:
          Number.parseInt(enrollments?.count[tab[oldStatus]]) - 1,
      },
      data: enrollments?.data?.map((item) => {
        if (item.id === id) {
          item.status = status;
        }
        return item;
      }),
    }));
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/internship/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Internship closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options);
  }

  const Stipend_incentive_from = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(data?.post?.stipend_from);
  const Stipend_incentive_to = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(data?.post?.stipend_to);

  // // console.log(data?.post, "hello world");

  return (
    <section className="job-content mobileView">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content-creation">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h2>{data?.post?.internship_title.slice(0, 50)}</h2>
                  <p className="light">Internship</p>
                  <p className="txt5">
                    {data?.post?.roles_and_responsibilities.slice(0, 450)}
                  </p>
                  {data?.post?.internship_city?.length > 0 && (
                    <p className="light">
                      <p className="light">
                        Preferred cities for the internship
                      </p>
                      <i className="fa-solid fa-map-marker-alt"></i>
                      {data?.post?.internship_city.map((item, index) => (
                        <span className="text-capitalize" key={index}>
                          {item}&nbsp;
                        </span>
                      ))}
                    </p>
                  )}
                </div>
                <div className="col-1 non"></div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="flex">
                    <div className="box1">
                      <p>Start Date</p>
                      <h3>
                        {formatDate(data?.post?.expected_start_date).slice(
                          4,
                          6
                        )}
                      </h3>
                      <p className="light">
                        {formatDate(data?.post?.expected_start_date).slice(
                          0,
                          4
                        )}
                      </p>
                    </div>
                    <div className="box1">
                      <p>Stipend</p>
                      {/* <h3 className="">
                        {Stipend_incentive_from}-{Stipend_incentive_to}
                      </h3> */}
                      <div className="d-flex">
                        <h3 className="text">{Stipend_incentive_from}</h3>
                        <h3 className="text">-{Stipend_incentive_to}</h3>
                      </div>
                      <p className="light">INR/Month</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 d-none d-sm-block">
                  <div className="btn-boxs">
                    <button onClick={handleDetails}>
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                    <button type="button" onClick={onDuplicate}>
                      Duplicate<i className="fa-regular fa-copy"></i>
                    </button>
                    <button className="close">
                      Close Opening <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="job-tabs">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {/* {Object.keys(tabMap)?.map((item, index) => (
                    <button
                      key={index}
                      className={`nav-link ${
                        index === selectedTab ? "active" : ""
                      }`}
                      onClick={() => setSelectedTab(index)}
                    >
                      {item}
                      <span> ({enrollments?.count?.[tabMap[item]] || 0}) </span>
                    </button>
                  ))} */}
                  {Object.keys(tabMap)?.map((item, index) => (
                    <button
                      key={index}
                      className={`nav-link ${
                        index === selectedTab ? "active" : ""
                      }`}
                      onClick={() => setSelectedTab(index)}
                    >
                      {item}
                      <span> ({enrollments?.count?.[tabMap[item]] || 0}) </span>
                    </button>
                  ))}
                </div>
              </nav>
              <ShowSelecetdTab
                tab={selectedTab}
                post={data?.post}
                enrollments={enrollments}
                handleStatusChange={handleStatusChange}
                dispatch={reduxDispatch}
              />
            </div>
          </div>
        </div>
        <div className="mobo_btn_container d-flex d-sm-none">
          <div className="mobo_btn_left">
            <i
              onClick={onDuplicate}
              className="fa-regular fa-copy bg-success"
            ></i>
            <i
              onClick={handleClose}
              className="fa-solid fa-xmark bg-danger"
            ></i>
          </div>
          <button onClick={handleDetails}>
            View Details <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default InternshipPost;
