import { Link } from "react-router-dom"
import React from "react"
import "./studentChoice.scss"
import img3 from "../../images/shape/student-shape-03.png"
import img4 from "../../images/shape/student-shape-04.png"
import img5 from "../../images/shape/student-shape-05.png"
import img6 from "../../images/shape/student-shape-06.png"
import img7 from "../../images/shape/student-shape-07.png"
import student from "../../images/student.png"

const StudentChooseSection = ({ student1,text1,text2 }) => {
  return (
    <div
      className={`student-choose-area w-100 col col-md-10 col-lg-10 fix
      }`}
      style={{ padding: "100px 0px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 align-self-center">
            <div className="student-wrapper">
              <div className="section-title mb-4">
                <h2>{text1}</h2>
              </div>
              <div className="sitdent-choose-content">
                {!text2 ? <p>
                  At Qollabb, companies post real
                  projects and collaborate with students from different
                  universities and disciplines. Through these collaborations,
                  students can apply their classroom learning to real-world
                  problems, gain valuable experience, and build their
                  portfolios.
                  The Companies get the brightest ideas and innovation through
                  these projects and they can hire the best performing students
                  without any cost.
                              </p> :
                                  <p>
                              At Qollabb, we believe that project-based learning and industry collaborations are key to preparing students for successful careers. Our platform enables students, professors, and companies to come together to solve real-world problems and to develop skills that are in demand in the modern workplace.        
                </p>
                }
                          </div>
                         {student1==="student" &&  <><div className="student-choose-list">
                                <ul>
                                    <li><i className="fas fa-check-circle"></i>Free Access to Project,Internships and Jobs </li>
                                    <li><i className="fas fa-check-circle"></i>Easy of use</li>
                                    <li><i className="fas fa-check-circle"></i>Get certificate for your project work</li>
                                </ul>
                            </div>
                            <div className="student-btn">
                                <Link to="/about" className="edu-sec-btn">More about us</Link>
                            </div></>}
            </div>
          </div>
          <div className="col-xl-2 col-lg-2">
            <div className="student-wrapper position-relative">
              <div className="shap-01"></div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="student-choose-wrapper position-relative">
              <div className="shap-02"></div>
              <div className="shap-03">
                <img src={img3} alt="img not found" />
              </div>
              <div className="shap-04">
                <img src={img4} alt="img not found" />
              </div>

              <div className="shap-05">
                <img src={img5} alt="img not found" />
              </div>
              <div className="shap-06">
                <img src={img6} alt="img not found" />
              </div>
              <div className="shap-07">
                <img src={img7} alt="img not found" />
              </div>
              <div className="student-choose-thumb">
                <img src={student} alt="img not found" style={{marginLeft: "-2.5%"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentChooseSection
