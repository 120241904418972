import axios from "axios";
import ProfileIcon from "components/employer/ProfileIcon";
import React from "react";
import { Link as NewLink } from "react-router-dom";
import { toast } from "react-toastify";
import Link from "../collegesCards/Link.svg";
import LinkedImage from "../collegesCards/Linkedin.svg";
import User from "../collegesCards/User.png";
import LeftArrow from "./Left_arrow.svg";
import RightArrow from "./Right_arrow.svg";
import "./collegesDetailCards.scss";
// import RightArrow from "./collegesDetailCards.scss"

const CollegesDetailCards = ({
  educatorDataDetails,
  style1,
  pageLength = 0,
  style2,
  text1,
  action2 = "1",
  text2,
  text5,
  style_i,
  setPage,
  currentPage,
  changeStatus,
  cancelInvite,
  sendInvite = false,
  setRefresh,
}) => {
  //action types:
  //  1: Send Invitation

  // // console.log(educatorDataDetails, "educatorDataDetails");
  // console.log("text1", text1);

  const data = educatorDataDetails;

  const onClickHandler = async (e, id) => {
    e?.preventDefault();
    sendInvitationFunc(id);
  };
  const sendInvitationFunc = async (id = 0) => {
    try {
      // console.log("hello2");
      if (id) {
        const res = await axios.post("/v1/invitations/send_invite", {
          receiver_id: [id],
        });
        setRefresh((prev) => !prev);
        // console.log(res);
        toast.success("invitation Send");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const chatApi = async () => {
      try {
        const chatRoomId = await axios.post("/v1/chat/create-chat-room", {
          userId: data.id,
          role: 3, // educator
          enrollment_type: null,
          enrollment_id: null,
        });
        // console.log(chatRoomId);
      } catch (error) {
        throw new Error();
      }
    };
    chatApi();
  };

  return (
    <>
      {educatorDataDetails ? (
        <>
          <div className="companies_detail_card w-100">
            <div className="menu_container">
              <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
            </div>
            <div className="card_container">
              <div className="top_section">
                <div className="conpany">
                  {data?.profile_pic ? (
                    <img
                      src={data?.profile_pic}
                      alt="logo"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <div className="img-box-img">
                      <ProfileIcon />
                    </div>
                  )}
                  <div className="company_detail">
                    <h4>{data?.institute?.institution_name}</h4>
                    <p>{data?.institute_branch?.address}</p>
                    {/* <p className="collegeType">
                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                    University(Private)
                  </p> */}
                  </div>
                </div>
                <div className="user">
                  <p>Contact Person</p>
                  <div className="user_data_container">
                    <img src={User} alt="Image" />
                    <div>
                      <h4>
                        {data?.first_name} {data?.last_name}
                      </h4>
                      <p>{data?.designation}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mid_section">
                <p>{data?.bio}</p>
              </div>
              <div className="bottom_section">
                <div className="link_btn">
                  {data?.profile_link && (
                    <>
                      <p>
                        <img src={Link} alt="Image" />
                        <a href="in.ibm.com">{data?.profile_link}</a>
                      </p>
                    </>
                  )}
                </div>
                <div className="link_btn">
                  {data?.linkedIn_link && (
                    <>
                      <p>
                        <img src={LinkedImage} alt="Image" />
                        <a href="in.ibm.com">{data?.linkedIn_link}</a>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="tags_container">
            <h4>Courses offered</h4>
            <p>B.Tech in computer science</p>
          </div> */}
            {/* <div className="tags_container">
            <h4>Interest areas</h4>
            <p>B.Tech in computer science</p>
          </div> */}

            <div className="button_container d-none d-md-flex">
              <div className="buttons">
                {text1 && (
                  <button
                    onClick={(e) => cancelInvite(data.id)}
                    className="btn Not_interested"
                    style={style1}
                  >
                    {text1}{" "}
                  </button>
                )}

                {sendInvite ? (
                  <button
                    className="btn send_Invitation"
                    onClick={(e) => sendInvitationFunc(data.id)}
                    style={style2}
                  >
                    {text2}
                    <i className="fa fa-send" style={style_i}></i>
                  </button>
                ) : text2 ? (
                  <button
                    className="btn send_Invitation"
                    onClick={(e) => changeStatus(e, data.id)}
                    style={style2}
                  >
                    {text2}
                    <i className="fa fa-send" style={style_i}></i>
                  </button>
                ) : (
                  <></>
                )}
                {text5 && (
                  <NewLink to={"/employer/chat"}>
                    <button onClick={(e) => handleChange(e)}>{text5}</button>
                  </NewLink>
                )}
              </div>

              <div className="pagination_btn">
                <img
                  src={LeftArrow}
                  onClick={() =>
                    setPage(currentPage === 0 ? 0 : currentPage - 1)
                  }
                  alt="Image"
                />
                <p>
                  {currentPage + 1}/ {pageLength}
                </p>
                <img
                  src={RightArrow}
                  onClick={() =>
                    setPage(
                      pageLength === currentPage + 1
                        ? currentPage
                        : currentPage + 1
                    )
                  }
                  alt="Image"
                />
              </div>
            </div>
            {/****************mobo ********** */}
            <div className="mobo_button_container d-flex flex-column gap-2 align-items-center d-md-none p-3 bg-white">
              <div className="buttons w-100">
                <button
                  onClick={(e) => cancelInvite(data.id)}
                  className="btn Not_interested w-100"
                  style={style1}
                >
                  {text1}
                </button>
                <button
                  className="btn send_Invitation w-100"
                  onClick={(e) =>
                    changeStatus
                      ? changeStatus(e, data.id)
                      : sendInvitationFunc()
                  }
                  style={style2}
                >
                  {text2}
                  <i className="fa fa-send" style={style_i}></i>
                </button>
              </div>

              <div className="pagination_btn">
                <img
                  src={LeftArrow}
                  onClick={() =>
                    setPage(currentPage === 0 ? 0 : currentPage - 1)
                  }
                  alt="Image"
                />
                <p>
                  {currentPage + 1}/ {pageLength}
                </p>
                <img
                  src={RightArrow}
                  onClick={() =>
                    setPage(
                      pageLength === currentPage + 1
                        ? currentPage
                        : currentPage + 1
                    )
                  }
                  alt="Image"
                />
              </div>
            </div>
            {/****************mobo ********** */}
          </div>
        </>
      ) : (
        <h1>no data</h1>
      )}
    </>
  );
};

export default CollegesDetailCards;
