import axios from "axios";
import Loader from "components/employer/Loader";
import { duplicateProject } from "components/employer/duplicate/project";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SelectedTabs from "./SelectedTabs";

import { useReducer } from "react";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";

const Invite = () => {
  const user = useSelector((state) => state?.auth?.user);
  const [state1, setState1] = useState({});

  const [suggesteData, setSuggesteData] = useState([]);

  const [refresh, setRefresh] = useState(() => false);

  const [state2, reduxDispatch] = useReducer(reducer, {
    ...initialState,
  });

  // // console.log(state2, "state2");
  useEffect(() => {
    const fetchDataAndStoreInState = async (url, status) => {
      try {
        const filters = Object.keys(state2).reduce((acc, key) => {
          if (state2[key]) {
            acc[key] = state2[key];
          }
          return acc;
        }, {});

        // // console.log(filters, "filters");
        const response = await axios.get(url, {
          params: {
            ...filters,
          },
        });
        setState1((prevState) => ({
          ...prevState,
          [status]: response.data,
        }));
      } catch (error) {
        console.error(`Error fetching data for status ${status}:`, error);
      }
    };

    const requests = [
      { url: "/v1/institution", status: 0 },
      { url: "/v1/invitations/get_invites?status[]=0", status: 1 },
      { url: "/v1/invitations/get_invites?status[]=2", status: 4 }, // sent
      {
        url: `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}`, //
        status: 2,
      },
      {
        url: `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`,
        status: 3,
      },
    ];

    // Use Promise.all to make multiple requests concurrently
    Promise.all(
      requests.map(({ url, status }) => fetchDataAndStoreInState(url, status))
    );
  }, [refresh, state2]);

  // // console.log(suggesteData, "state12");
  // // console.log(state1[1]?.data, "state1");
  // // console.log(state1[2]?.data, "state2");

  useEffect(() => {
    setSuggesteData(state1[0]?.data);
    const state1DataIds =
      state1?.[1]?.data?.map((item) => {
        // // console.log(item?.receiver_id, "item_receiver");
        return item.receiver_id;
      }) || [];
    const state2DataIds =
      state1?.[2]?.data?.map((item) => {
        // console.log(item?.sender_id, "item_sender");
        return item.sender_id;
      }) || [];

    const state4DataIds =
      state1?.[4]?.data?.map((item) => {
        // console.log(item?.sender_id, "item_sender");
        return item.receiver_id;
      }) || [];

    const state3DataIds = state1?.[3]?.data?.map((item) => {
      if (item?.sender) {
        return item?.sender?.id;
      }
      return item?.receiver?.id;
    });

    setSuggesteData((prevState) => {
      return prevState?.filter((item) => {
        return (
          !state1DataIds?.includes(item?.id) &&
          !state2DataIds?.includes(item?.id) &&
          !state3DataIds?.includes(item?.id) &&
          !state4DataIds?.includes(item?.id)
        );
      });
    });
  }, [
    state1?.[1]?.data,
    state1?.[2]?.data,
    state1?.[3]?.data,
    state1?.[0]?.data,
    refresh,
  ]);

  // useEffect(() => {
  //   setSuggesteData(filteredSuggestedData);
  // }, []);

  // // console.log(filteredSuggesteData, "filteredSuggesteData");

  const { id = 0 } = useParams();
  const { data, loading } = useFetch(`/v1/enrollments/project/${id}`);
  const [enrollments, setEnrollments] = useState(() => ({
    count: 0,
    data: [],
  }));
  const [state, setState] = useState(false);

  useEffect(() => {
    if (data?.data) {
      setEnrollments(() => ({
        count: {
          ...data?.data?.count,
          applied: data?.data?.enrollments?.length,
        },
        data: data?.data?.enrollments,
      }));
    }
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  if (loading || !data?.data) return <Loader />;

  const onDuplicate = () => {
    duplicateProject({ data: data?.data?.post, dispatch });
    navigate("/employer/project/add");
  };

  const handleDetails = () => {
    navigate(`/employer/project/details/${id}`);
  };

  // const tabMap = {
  //   "Suggested college/universities": "applied",
  //   "Invitations sent": "Left",
  //   "Invitation Recieved": "Seen",
  //   "Invitation accepted": "Under Review",
  //   // "List of partner Institutions": "Shortlisted",
  //   // Assignments: "In touch",
  //   // Interviews: "Hired",
  //   // Interviews: "Rejected",
  // };

  const tabMap = {
    0: "Suggested college/universities",
    1: "Invitations sent",
    2: "Invitation Recieved",
    3: "Invitation accepted",
  };

  const handleStatusChange = ({ status, oldStatus, id }) => {
    // console.log({ status, oldStatus, id });
    // console.log("status", status);
    // status
    // 0: Applied
    // 1: Left
    // 2: Seen
    // 3: Under Review
    // 4: Shortlisted
    // 5: In touch
    // 6: Hired
    // 7: Rejected

    const tab = {
      0: "Applied",
      1: "Left",
      2: "Seen",
      3: "Under Review",
      4: "Shortlisted",
      5: " In touch",
      6: "Hired",
      7: "List",
    };

    // setEnrollments(() => ({
    //   count: {
    //     ...enrollments?.count,
    //     [tab[status]]: Number.parseInt(enrollments?.count[tab[status]]) + 1,
    //     [tab[oldStatus]]:
    //       Number.parseInt(enrollments?.count[tab[oldStatus]]) - 1,
    //   },
    //   data: enrollments?.data?.map((item) => {
    //     if (item.id === id) {
    //       item.status = status;
    //     }
    //     return item;
    //   }),
    // }));
  };

  const handleClose = async () => {
    const response = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    const { error, message } = response?.data;

    if (error) {
      toast.error(message);
    } else {
      toast.success("Project closed successfully", {
        onClose: () => navigate("/employer/dashboard"),
      });
    }
  };

  // // console.log(id);

  return (
    <section className="job-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content-creation">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h2>{data?.data?.post?.project_title}</h2>
                  <p className="light">project</p>
                  <p className="txt5">{data?.data?.post?.project_goal}</p>
                </div>
                <div className="col-1 non"></div>
                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="flex">
                    <div className="box1" style={{ width: "100%" }}>
                      <p>Duration</p>
                      <h3>
                        {data?.data?.post?.duration_from} -{" "}
                        {data?.data?.post?.duration_to}
                      </h3>
                      <p className="light">Weeks</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 d-none d-sm-block">
                  <div className="btn-boxs">
                    <button onClick={handleDetails}>
                      View Details{" "}
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                    <button type="button" onClick={onDuplicate}>
                      Duplicate<i className="fa-regular fa-copy"></i>
                    </button>
                    <button className="close" onClick={handleClose}>
                      Close Opening <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-tabs">
              <nav className="flex">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {/* {Object.keys(tabMap)?.map((item, index) => {
                    // console.log(enrollments, "item");
                    return (
                      <button
                        key={index}
                        className={`nav-link ${
                          index === selectedTab ? "active" : ""
                        }`}
                        onClick={() => setSelectedTab(index)}
                      >
                        {item}
                        <span>
                          {" "}
                          ({enrollments?.count?.[tabMap[item]] || 0}){" "}
                        </span>
                      </button>
                    );
                  })} */}

                  {Object.keys(tabMap)?.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className={`nav-link ${
                          index === selectedTab ? "active" : ""
                        }`}
                        onClick={() => setSelectedTab(index)}
                      >
                        {index === 0 ? (
                          <>
                            {tabMap[item]}({suggesteData.length || 0})
                          </>
                        ) : (
                          <>
                            {tabMap[item]}({state1?.[index]?.data?.length || 0})
                          </>
                        )}
                      </button>
                    );
                  })}
                </div>
                <button
                  onClick={() => setState((p) => !p)}
                  className="bg-primary"
                  style={{ width: "247px", height: "48px" }}
                >
                  {state ? "View List " : "Detail List"}
                </button>
              </nav>
              <SelectedTabs
                tab={selectedTab}
                post={data?.data?.post}
                enrollments={enrollments}
                handleStatusChange={handleStatusChange}
                state={state}
                filteredSuggesteData={suggesteData || []}
                setRefresh={setRefresh}
                refresh={refresh}
                reduxDispatch={reduxDispatch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mobo_btn_container d-flex d-sm-none">
        <div className="mobo_btn_left">
          <i
            onClick={onDuplicate}
            className="fa-regular fa-copy bg-success"
          ></i>
          <i onClick={handleClose} className="fa-solid fa-xmark bg-danger"></i>
        </div>
        <button onClick={handleDetails}>
          View Details <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      </div>
    </section>
  );
};

export default Invite;
