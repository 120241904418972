import React, { useState, useEffect, useId } from "react";

import "./newimputImage.scss";
import { toast } from "react-toastify";

const NewInputImage = ({
  image,
  url,
  changeImage = () => {},
  updated = false,
  isListItem = false,
  setImageFile = () => {},
  imgText,
}) => {
  const [file, setFile] = useState(null);
  const [image_url, setImageUrl] = useState(() => url);
  const [image64] = useState(() => image);
  const [preview, setPreview] = useState(() => null);
  const id = useId();

  const onChangeHandler = (e) => {
    const file = e.target.files[0];
    if (file && file?.size > 1000000) {
      return toast.error("Image size should be less than 1MB");
    }

    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setPreview(null);
      changeImage(file);
      setImageFile(file);
    } else {
      setFile(null);
    }
  };

  useEffect(() => {
    if (isListItem && !updated && file) setPreview(null);
    else if (image64 || file) {
      const reader = new FileReader();
      reader.readAsDataURL(image64 || file);
      reader.onloadend = () => {
        setPreview(reader.result);
      };
    } else setPreview(null);
  }, [image64, file, updated, isListItem]);

  const removeImage = () => {
    setFile(null);
    setPreview(null);
    setImageUrl(false);
    setImageFile(null);
  };

  return (
    <>
      {preview !== null || image_url ? (
        <div className="img-container">
          <picture>
            {imgText && <strong className="img-text hide-on-mobile">{imgText}</strong>}
            <div className="img-box-ajay">
              <img
                style={{
                  objectFit: "contain",
                }}
                src={preview || url}
                alt="company_logo"
              />
              <i
                className="fa-solid fa-pen"
                title="Reupload"
                onClick={removeImage}
              ></i>
            </div>
          </picture>
        </div>
      ) : (
        <div className="image-uploader mb-3">
          {imgText && <strong className="img-text">{imgText}</strong>}
          <label className="img-label" htmlFor={`image-field-${id}`}>
            <i
              style={{
                padding: "40px",
              }}
              className="fa-solid fa-arrow-up-from-bracket"
            ></i>
          </label>
          <input
            type="file"
            style={{ display: "none" }}
            id={`image-field-${id}`}
            accept="image/*"
            onChange={onChangeHandler}
          />
        </div>
      )}
    </>
  );
};

export default NewInputImage;
