import React, { useState } from "react";
import TopButtons from "../TopButtons";
import "../settings.scss";
import Monday from "./Monday";
import Sunday from "./Sunday";
import Tuesday from "./Tuesday";
import Wednesday from "./Wednesday";
import Thursday from "./Thursday";
import Friday from "./Friday";
import Saturday from "./Saturday";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Availability = () => {
  const [sunday, setSunday] = useState([]);
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [allAvailabilityData, setAllAvailabilityData] = useState();

  const user = useSelector((state) => state.auth.user);

  const { timings } = user;

  // console.log(sunday,monday,tuesday,wednesday,thursday,friday,saturday,"hdgkfhg");

  const saveAvailabilityData = async () => {
    const availabilityData = {
      SUNDAY: sunday,
      MONDAY: monday,
      TUESDAY: tuesday,
      WEDNESDAY: wednesday,
      THURSDAY: thursday,
      FRIDAY: friday,
      SATURDAY: saturday,
    };

    try {
      const response = await axios.post(
        "/v1/mentor/update-mentor-timing",
        availabilityData
      );
      console.log(response.data, "res");
      setAllAvailabilityData(response.data);
      toast.success("Timing added successfully");
    } catch (error) {
      console.error(error);
    }
  };

  // const OnCancel = () => {
  //   const availabilityData = {
  //     SUNDAY: [],
  //     MONDAY: [],
  //     TUESDAY: [],
  //     WEDNESDAY: [],
  //     THURSDAY: [],
  //     FRIDAY: [],
  //     SATURDAY: []
  //   };

  // }
  // console.log(timings, "timings");

  // console.log(allAvailabilityData, "allAvailabilityData");

  return (
    <div className="mentor-setting">
      <div className="container-lg container-fluid">
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="pills-availability"
            role="tabpanel"
            aria-labelledby="pills-availability-tab"
          >
            <h3>Set Availability</h3>
            <div className="btn-right">
              <button className="cancel">Cancel</button>
              <button className="save" onClick={saveAvailabilityData}>
                Save
              </button>
            </div>
            <Sunday
              sundayTime={timings[0]?.duration}
              setAvailability={setSunday}
              availability={sunday}
            />
            <Monday
              mondayTime={timings[1]?.duration}
              setAvailability={setMonday}
              availability={monday}
            />
            <Tuesday
              tuesdayTime={timings[2]?.duration}
              setAvailability={setTuesday}
              availability={tuesday}
            />
            <Wednesday
              wednesdayTime={timings[3]?.duration}
              setAvailability={setWednesday}
              availability={wednesday}
            />
            <Thursday
              thursdayTime={timings[4]?.duration}
              setAvailability={setThursday}
              availability={thursday}
            />
            <Friday
              fridayTime={timings[5]?.duration}
              setAvailability={setFriday}
              availability={friday}
            />
            <Saturday
              saturdayTime={timings[6]?.duration}
              setAvailability={setSaturday}
              availability={saturday}
            />

            <div className="btn-right">
              <button className="cancel">Cancel</button>
              <button className="save" onClick={saveAvailabilityData}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
