import "./style.scss";
import "./submit.scss";

const SubmitButton = ({
  value = "Next",
  onSubmit = () => {},
  isLoadingState = false, 
  loading = false,
  setLoading,
  flag = false,
  disabled= false
}) => {
  const onClickHandler = async (e) => {
    e?.preventDefault();
    if (isLoadingState) {
      try {
        onSubmit(); 
      } catch (error) {
        console.error("errr", error);
        setLoading(() => false);
      }
    } else onSubmit();
  };
  // console.log(loading, "f123");
  return (
    <div className="form-group my-3 input_container">
      <button
        className="btn submit-btn"
        // onClick={onClickHandler}
        type={loading ? "button" : "submit"}
        onClick={!flag ? onClickHandler : null}
        disabled={disabled}
      >
        {loading ? (
          <i className="text-white fa fa-circle-o-notch fa-spin"></i>
        ) : (
          value
        )}
      </button>
    </div>
  );
};

export default SubmitButton;
