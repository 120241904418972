const StatusButton = ({ status = "" }) => {
  // console.log(status, "status");
  return (
    <div className="status_chip_container">
      {
        // status === "Published"
        status === "Active" && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: "#47D06520",
              color: "#47D065",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
            Active
          </span>
        )
      }
      {status === "Draft" && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#F9B03520",
            color: "#F9B035",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Draft
        </span>
      )}
      {status === "Deactivated" && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#FF373720",
            color: "#FF3737",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Closed
        </span>
      )}
      {status === "Closed" && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#FF373720",
            color: "#FF3737",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Closed
        </span>
      )}
      {status === 0 && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#F9B03520",
            color: "#F9B035",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Open
        </span>
      )}
      {status === 1 && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#47D06520",
            color: "#47D065",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Closed
        </span>
      )}
      {status === 2 && (
        <span
          className={`px-3 py-1`}
          style={{
            fontSize: "12px",
            background: "#FF373720",
            color: "#FF3737",
            fontWeight: "600",
            borderRadius: "20px",
          }}
        >
          Overdue
        </span>
      )}

      {/* <i
        className="fa-solid fa-ellipsis-vertical"
        style={{ color: "gray" }}
        onClick={onClick}
      ></i> */}
    </div>
  );
};

export default StatusButton;
