  import EducatorSidebar from "components/dashboard/educatorSidebar/Sidebar";
  import Topbar from "components/dashboard/educatorTopbar/Topbar";
  import React from "react";
  import { useSelector } from "react-redux";
  import PartnerInstitutions from "./listOfPartnerCompanies/ListOfPartnerCompanies";
  import SuggestCompanies from "./suggestCompanies/SuggestCompanies";
  import InvitationSent from "./InvitationsSent/InvitationSent";
  import InvitationRecieved from "./invitationRecieved/InvitationRecieved";
  import { useEffect } from "react";
  import { useState } from "react";
  import axios from "axios";
  import CompanyTop from "../components/companyTop/CompanyTop";
  import NewComponent from "./NewComponent";
  import { set } from "react-hook-form";

const EducatorCompanyPage = () => {
  const [company, setCompany] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [colleges, setColleges] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [refresh, setRefresh] = useState(() => false);
  const [recievedCompany, setRecievedCompany] = useState([]);
  const [filteredCompany, setFilteredCompany] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filterboxData,setFilterBoxData]=useState({})
   
  const handleFilterChange = (newFilters) => {
    setFilterBoxData(newFilters); 
  };
  console.log("filterboxData",filterboxData)
  const filters = Object.keys(filterboxData).reduce((acc, curr) => {
    if (curr === "selected") {
      return acc;
    } else if (filterboxData[curr] instanceof Set) {
      if (filterboxData[curr].size) {
        const set = [...new Set(filterboxData[curr])];
        return { ...acc, [curr]: set };
      }
    } else if (filterboxData[curr]) {
      return { ...acc, [curr]: filterboxData[curr] };
    }
    return acc;
  }, {});



    const param = new URLSearchParams(window.location.search);
    const [step, setStep] = React.useState(
      param.get("step") ? parseInt(param.get("step")) : 1
    );

  useEffect(() => {
    const fetchInstitutions = async () => {
      const res = await axios.get(
        `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`
      );

      const res1 = await axios.get("/v1/educator/getAllEmployers", {
        params: filters
      });

        const res2 = await axios.get("/v1/invitations/get_invites?status[]=0");

        const res3 = await axios.get(
          `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}`
        );

        setRecievedCompany(res3?.data?.data); // y chiye // mosty {emp_sender{id}}

        setColleges(res2?.data?.data); // {receiver_emp: {id}}

        setCompany(res1?.data?.data);

        setInstitutions(res?.data?.data); // {receiver_emp : {id}

        const allIds = [
          ...res?.data?.data?.map((item) => item?.sender_emp?.id),
          ...res2?.data?.data?.map((item) => item?.receiver_emp?.id),
          ...res3?.data?.data?.map((item) => item?.sender_emp?.id),
        ];

        // console.log(allIds);

        const filteredCompany = res1?.data?.data?.filter((item) => {
          return !allIds.includes(item?.id);
        });

      setFilteredCompany(filteredCompany);
      setLoading(false);
    };
    fetchInstitutions();
  }, [refresh, filterboxData]);

  return (
    <section>
      <CompanyTop
        recievedCompany={recievedCompany?.length}
        institutions={institutions?.length}
        company={filteredCompany?.length || 0}
        colleges={colleges?.length}
        step={step}
        setStep={setStep}
      />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <NewComponent
          step={step}
          setRefresh={setRefresh}
          institutions={institutions}
          company={filteredCompany}
          colleges={colleges}
          recievedCompany={recievedCompany}
          onFilterChange={handleFilterChange}
        />
      )}
    </section>
  );
};

  export default EducatorCompanyPage;
