import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addApplicationData } from "services/actions/application";
import { useNavigate } from "react-router-dom";
import ScreeningQuestions from "components/employer/ScreeningQuestions";
import LinkProjectPopup from "components/employer/LinkProjectPopup";
import Chips from "components/formComponents/Chips";

const InternshipDetails3 = ({
  shouldValidate,
  updateShouldValidate,
  setShouldValidate,
  linkedProject,
  setLinkedProject,
}) => {
  // const defaultValues = useSelector(
  //   (state) => state.application["internship"][4]
  // );
  // const [screening, setScreening] = useState(
  //   () => defaultValues?.screening_questions || []
  // );
  const [isPopupOpen, setIsPopupOpen] = useState(() => false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [linkedProject, setLinkedProject] = useState(() => ({
  //   name: "",
  //   id: 0,
  // }));

  // useEffect(() => {
  //   const onSubmit = () => {
  //     const data = {};
  //     data.screening_questions = screening;
  //     console.log(linkedProject);
  //     if (linkedProject) data.project_linked = linkedProject;
  //     dispatch(addApplicationData({ step: 4, data, method: "internship" }));
  //     navigate("/employer/internship/preview");
  //   };

  //   if (shouldValidate) {
  //     onSubmit();
  //     updateShouldValidate(3, false, true);

  //     return () => {
  //       updateShouldValidate(3, false, false);
  //     };
  //   }
  // }, [
  //   dispatch,
  //   screening,
  //   shouldValidate,
  //   updateShouldValidate,
  //   navigate,
  //   linkedProject,
  // ]);

  return (
    <div className="">
      <LinkProjectPopup
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        setLinkedProject={setLinkedProject}
        linkedProject={linkedProject}
      />
      <div className="row mx-0 screening-container bg-white py-3 rounded border mb-4 align-items-center">
        {/* <div className=""> */}
        <div className="col-md-8 col-12 col-xl-9">
          <label>
            Link Project
            <small>
              The student will be asked to provide the output for this project,
              which will help you make a better decision about the applicant's
              suitability for this role.
            </small>
            {linkedProject?.name && (
              <div className="row">
                <Chips
                  items={[linkedProject.name.slice(0, 30)]}
                  deleteItem={() => setLinkedProject({ name: "", id: 0 })}
                  id="linked_project"
                  key_name="name"
                />
              </div>
            )}
          </label>
        </div>
        <div className="col-md-4 col-12 col-xl-3 d-flex align-items-center justify-content-end">
          <button
            onClick={() => setIsPopupOpen(true)}
            style={{
              backgroundColor: "#6149cd",
              width: "100%",
            }}
            className={`${linkedProject?.name ? "bg-success" : ""}`}
          >
            {linkedProject?.name ? "Change" : "Link Project"}
          </button>
        </div>
        {/* </div> */}
      </div>
      {/* <div className="row mx-0 screening-container bg-white p-3 rounded border mb-4">
        <ScreeningQuestions
          id="screening_item"
          label="Screening Questions/Test:"
          secondaryLabel="Please share upto 10 questions you would like to ask the students for screening of candidates/better filtration"
          screening={screening}
          setScreening={setScreening}
        />
      </div> */}
    </div>
  );
};

export default InternshipDetails3;
