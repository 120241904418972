import React from "react"
import "./projectTableMobile.scss"

const ProjectTableMobileCheckbox1 = ({ rating5,setRating5,title, checkbox ,setRating1,setRating2,rating1,rating2,setRating3,setRating4,rating3,rating4}) => {
  



  // // console.log(data,"data");
  const handleRatingChange = (e) => {
    const selectedValue = e.target.value
    // console.log(selectedValue)
    setRating5(selectedValue)
  }

  return (
    <section className="projectTable_mobile w-100">
      <h4 className="projectReport_title">
        {title} <small> (Weightage 15%)</small>
      </h4>
      <div className="projectTable_main">
        <div className="projectTable_title">
          <h4>Description of evaluation criteria</h4>
        </div>
          return (
            <div className="projectTable_title">
              <h4>All weekly reports submitted on or before due date</h4>
              <div className="ratings flex">
                <div className="flex gap-2">
                  <p>System generated rating</p>
                  <small>01</small>
                </div>
                <div className="devider"></div>
                <div className="flex gap-2">
                  <p>Mentor Rating</p>
                    <div>
                    <label>
                      <input
                        type="radio"
                        name="myRadioGroup"
                    value="1"
                    checked={rating4 === "1"}
                  onChange={handleRatingChange}
                      />
                      1
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )

        <div className="table_bottom flex">
          <h4>Maximum (5)</h4>
          <div className="score">
            <h4>Score: 05</h4>
            <p>weighted Score: 0.75</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectTableMobileCheckbox1
