import React, { useState } from "react"
import PasswordField from "components/formComponents/PasswordField"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import axios from "axios"
import ConfirmModal from "components/cards/ConfirmModal"
import { useDispatch } from "react-redux"
import { logoutUser } from "services/actions/auth"
import svg from "./svg4.svg"
import "./setting.scss";

const ChangePassword = () => {
  const { register, handleSubmit, reset } = useForm()
  const [showModal, setShowModal] = useState(() => false)
  const [deleteAccount,setDeleteAccount] = useState("")
  const dispatch = useDispatch()

  const updatePassword = async ({ password, newPassword }) => {
    try {
      const res = await axios.patch("/v1/auth/change_password", {
        password,
        newPassword,
      })

      if (!res.data.error) {
        toast.success(res.data.message)
        reset({
          password: "",
          newPassword: "",
          confirmPassword: "",
        })
      } else {
        toast.error(res.data.message || "Something went wrong")
      }
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong")
    }
  }

  const onSubmit = (data) => {
    if (!data?.password || !data?.newPassword || !data?.confirmPassword) {
      toast.error("Please fill all the fields")
    } else {
      if (data.newPassword !== data.confirmPassword) {
        toast.error("New password and confirm password does not match")
      } else if (data.password === data.newPassword) {
        toast.error("New password and current password should not be same")
      } else if (data.newPassword.length < 7) {
        toast.error("New password should be 7 characters long")
      } else {
        updatePassword(data)
      }
    }
  }



  // console.log(deleteAccount,"deleteAccount");

  const handleDelete = async () => {
    if (deleteAccount === "delete") {
      const res = await axios.delete("/v1/auth/delete_account")
    
  

    if (!res.data.error) {
      toast.info("Account deleted successfully! We will miss you :(")
      dispatch(logoutUser({ cookieDeleted: true }))
    } else {
      toast.error(res.data.message || "Something went wrong")
      }
    } else {
      toast.error("Please wright delete")
    }
  }

  return (
    <div className="change_password  py-4 container" >
      <div className="row">
        <div className="col-md-6 col-12">
          <h2>Change Password</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="form_style">
            <PasswordField
              register={register}
              id="password"
              placeholder="7+ Characters"
              label="Enter current password"
              displayStrength={false}
            />
            <PasswordField
              register={register}
              id="newPassword"
              placeholder="7+ Characters"
              label="New password"
            />
            <PasswordField
              register={register}
              id="confirmPassword"
              placeholder="7+ Characters"
              label="Confirm password"
              displayStrength={false}
            />

            <div className="right">
              <button className="cancel" type="reset">
                Cancel
              </button>{" "}
              <button type="submit" className="changePass">Change Password</button>
            </div>
          </form>
        </div>

        <div className="col-md-6 col-12 mt-4 mt-md-0 right-side" >
          <div className="right-img">
            <img src={svg} alt="change password" width={320}  />
          </div>
          <div className="danger-side">
            <h5 className="text-danger">Danger Zone</h5>
            <button type="button" className="Delete_my_account" onClick={() => setShowModal(() => true)}>
              Delete my account
            </button>
          </div>

          {showModal ? (
            <ConfirmModal
            setDeleteAccount={setDeleteAccount}
              onConfirm={handleDelete}
              onCancel={() => setShowModal(() => false)}
              title="Delete Account?"
              message="Are you sure you want to delete your account? This action cannot be undone."
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
