import { Link } from "react-router-dom";
import LogoImage from "../../images/logos/brand-logo.png";
import "./navbar.scss";

const AuthNavbar = () => {
  return (
    <div className="navbar no_outline">
      <div className="container d-flex align-items-center justify-content-between">
        <Link to="/">
          <img src={LogoImage} className="dashboard-logo" width="150px" height="50px" alt="Logo" />
        </Link>

        {/* <button className="btn color-tr" type="button" title="open-nav">
          <i className="fa-solid fa-ellipsis"></i>
        </button> */}
      </div>
    </div>
  );
};

export default AuthNavbar;
