import useRelativeTime from 'hooks/useRelativeTime';
import React from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./opportunityCard.scss";
function convertToKFormat(salary) {
    if (salary >= 1000) {
      return (salary / 1000) + 'k';
    } else {
      return salary;
    }
  }
const OpportunityCardNew = ({item, type}) => {

    // console.log("itemm", item);
    // console.log("cardtype", type);

    const user = useSelector((state)=> state?.auth?.user);
    const navigate = useNavigate();

    const handleApply = async (id, screening_questions = "") => {
        if (!user?.student_resume) {
          toast.error("Please Complete your profile to apply");
          return navigate("/student/profile");
        }
        if (!type || !id) return;
        if (screening_questions) {
          try {
            screening_questions = JSON.parse(screening_questions);
          } catch (e) {
            screening_questions = [];
          }
        }
    
        navigate(`/student/explore/${type}/${id}/apply`, {
          state: {
            screening_questions,
          },
        });
        // toast.success("Your application has been submitted");
      };


  return (
    <div className='oppotunity_card mt-2'>

    {
        type === 'all' && 
            <AllOpportunity item={item} type={type} handleApply={handleApply} />
    }
    {
        type === 'project' && 
            <ProjectCard item={item} user={user} type={type} handleApply={handleApply} />
    }
    {
        type === 'internship' && 
            <InternshipCard item={item} user={user} type={type} handleApply={handleApply} />
    }
    {
        type === 'job' && 
            <JobCard item={item} user={user} type={type} handleApply={handleApply} />
    }

    </div>
  )
}

export default OpportunityCardNew;

const AllOpportunity = ({item, type})=> {

    return (
        <>
        <div className='card-wrapper'>
            <div className="card-main">
                <div className="card-top">
                    <div className="project-details">
                        <h4> {item?.title} </h4>
                        <p> {item?.branch?.address} </p>
                    </div>

                    <div className="comp-img">
                        <img src={
                            item?.project_global_fkey?.employer_account?.company_logo ||
                            item?.global?.employer_account?.company_logo ||
                            item?.global_job?.employer_account?.company_logo
                        } alt="Image" />
                    </div>
                </div>

                <div className='mid-details'>
                    <div className='detail'>
                        <p>Opportunity Type</p>
                        <h4>{item?.project_global_fkey ? "Project" : item?.global ? "Internship" : "Job"}</h4>
                    </div>
                    <div className='detail'>
                        <p>{item?.project_global_fkey ? "Duration" : item?.global ? "Stipend" : "Salary"}</p>

                        <h4>
                        {item?.project_global_fkey ? `${item?.duration_from + "-" + item?.duration_to}` : 
                        item?.global ? `${convertToKFormat(item?.stipend_from ) + "-" + convertToKFormat(item?.stipend_to)}` : 
                        `${convertToKFormat(item?.ctc_from) + "-" + convertToKFormat(item?.ctc_to)}`}

                         <span>{item?.project_global_fkey ? "weeks" : item?.global ? "/month" : "/year"}</span> 
                         
                         </h4>
  
                    </div>
                    <div className='detail'>
                        <p>Location</p>
                        <h4> {item?.office_visit ? "Office" : "Virtual"} </h4>
                    </div>
                </div>
            </div>
        </div>

        <div className="cart-footer">
            <div className='bottom-group'>
                {/* <div className='actively-tag'>
                    #Hiring Actively
                </div> */}

            </div>

            <div className='btn-section'>
                <div className='enroll-btn'>
                Apply <FaArrowRight  
                // color='#6149CD'
                color='#ffffff'
                 size={15} />     

                </div>
            </div>
        </div>

        </>
    );
}

const ProjectCard = ({item, user, type, handleApply})=> {

    // console.log("projectitem", item);


  // Replace spaces and '#' / with hyphens in the project title
//   const formattedTitle = item?.title.replace(/[\s#]+/g, '-');
const formattedTitle = item?.title.replace(/[\s#/]+/g, '-').toLowerCase();

    return (
        <>
        <div className='card-wrapper'>
            <Link to={ `/project/${formattedTitle}/${item?.id}`}>
                <div className="card-main">
                    <div className="card-top">
                        <div className="project-details">
                            <h4> {item?.title} </h4>
                            {/* <p> {"BESTMATE IVESTMENT SERVICES PVT LTD".toLowerCase()}, <span className='span_location'>{item?.branch?.address.split(',')[0]}</span> </p> */}
                            <p> {item?.project_global_fkey?.employer_account?.company?.company_name.toLowerCase()}, <span className='span_location'>{item?.branch?.address.split(',')[0]}</span> </p>
                        </div>

                        <div className="comp-img">
                            <img src={
                                item?.project_global_fkey?.employer_account?.company_logo
                            } alt="logo" />
                        </div>
                    </div>

                    <div className='mid-details'>
                        <div className='detail'>
                            <p>Opportunity</p>
                            <h4>{type}</h4>
                        </div>
                        {/* <div className='detail'>
                            <p>location</p>
                            <h4>{item?.branch?.address.split(',')[0]}</h4>
                        </div> */}
                        <div className='detail'>
                            <p>Duration</p>

                            <h4>{item?.duration_from + "-" + item?.duration_to} <span>weeks</span> </h4>
    
                        </div>
                        <div className='detail'>
                            <p>Mode</p>
                            <h4> {item?.office_visit ? "Office" : "Virtual"} </h4>
                        </div>
                    </div>
                </div> 
            </Link>
        </div>

        <div className="cart-footer">
            <div className='bottom-group'>
                {
                    item?.project_global_fkey?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.project_global_fkey?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }

            </div>

            <div className='btn-section'>
                <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                Apply <FaArrowRight  color='#ffffff' size={15} />     

                </div>
            </div>
        </div>

            {/* Mobile card footer */}
        <div className="mob_card_footer">
            {/* <div className="mob_chips-section">
            {
                    item?.project_global_fkey?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.project_global_fkey?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
            </div> */}
            <div className="mob_btn_section">
                <div className='mob_chips-section'>
                {
                    item?.project_global_fkey?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.project_global_fkey?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
                </div>

                <div className='btn-section'>
                    <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                    Apply <FaArrowRight  color='#ffffff' size={15} />     

                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

const InternshipCard = ({item, user, type, handleApply})=> {
    const time = useRelativeTime(item?.createdAt)
    // console.log("iitem", item);

    // const formattedTitle = item?.title.replace(/[\s#]+/g, '-');
    const formattedTitle = item?.title.replace(/[\s#/]+/g, '-').toLowerCase();


    return (
        <>
        <div className='card-wrapper'>
            <Link to={`/internship/${formattedTitle}/${item?.id}` }>
                <div className="card-main">
                    <div className="card-top">
                        <div className="project-details">
                            <h4> {item?.title} </h4>
                            <p> {item?.global?.employer_account?.company?.company_name.toLowerCase()}, <span className='span_location'>{item?.branch?.address.split(',')[0]}</span> </p>
                        </div>

                        <div className="comp-img">
                            <img src={ item?.global?.employer_account?.company_logo } alt="img" />
                        </div>
                    </div>

                    <div className='mid-details'>
                        <div className='detail'>
                            <p>Opportunity</p>
                            <h4>{type}</h4>
                        </div>
                        {/* <div className='detail'>
                            <p>Location</p>
                            <h4>{item?.branch?.address.split(',')[0]}</h4>
                        </div> */}
                        <div className='detail'>
                            <p>Stipend</p>

                                    <h4> {convertToKFormat(item?.stipend_from )}-{convertToKFormat(item?.stipend_to)} 
                                    <span> /month</span></h4> 
                        </div>
                        <div className='detail'>
                            <p>Mode</p>
                            <h4> {item?.office_visit ? "Office" : "Virtual"} </h4>
                        </div>
                    </div>
                </div>
            </Link>
        </div>

            {/* desktop card footer */}
        <div className="cart-footer">
            
            <div className='bottom-group'>
                <div className='time'>
                    {time}
                </div>
                {
                    item?.global?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.global?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.top_company ? (
                    <div className='actively-tag'>
                        #TopCompany
                    </div>

                    ): (
                        ""
                    )
                }

            </div>

            <div className='btn-section'>
                <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                Apply <FaArrowRight  color='#ffffff' size={15} />     

                </div>
            </div>
        </div>

                {/* Mobile card footer */}
        <div className="mob_card_footer">
            <div className="mob_chips-section">
                {
                    item?.global?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.global?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.top_company ? (
                    <div className='actively-tag'>
                        #TopCompany
                    </div>

                    ): (
                        ""
                    )
                }
            </div>
            <div className="mob_btn_section">
                <div className='time'>
                    {time}
                </div>

                <div className='btn-section'>
                    <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                    Apply <FaArrowRight  color='#ffffff' size={15} />     

                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

const JobCard = ({item, user, type, handleApply})=> {
    const time = useRelativeTime(item?.createdAt)
    // console.log("jobbbbbbb", item)
    
    // const formattedTitle = item?.title.replace(/[\s#]+/g, '-');

    const formattedTitle = item?.title.replace(/[\s#/]+/g, '-').toLowerCase();


    return (
        <>
        <div className='card-wrapper'>
        <Link to={`/job/${formattedTitle}/${item?.id}`}>

            <div className="card-main">
                <div className="card-top">
                    <div className="project-details">
                        <h4> {item?.title} </h4>
                        <p> {item?.global_job?.employer_account?.company?.company_name.toLowerCase()}, <span className='span_location'>{item?.branch?.address.split(',')[0]}</span> </p>
                    </div>

                    <div className="comp-img">
                        <img src={
                            item?.global_job?.employer_account?.company_logo
                        } alt="Image" />
                    </div>
                </div>

                <div className='mid-details'>
                    <div className='detail'>
                        <p>Opportunity</p>
                        <h4>{type}</h4>
                    </div>
                    {/* <div className='detail'>
                        <p>Location</p>
                        <h4>{item?.branch?.address.split(',')[0]}</h4>
                    </div> */}
                    <div className='detail'>
                        <p>Salary</p>

                                <h4> {convertToKFormat(item?.ctc_from)}-{convertToKFormat(item?.ctc_to)} 
                                <span> /year </span></h4> 
                    </div>
                    <div className='detail'>
                        <p>Mode</p>
                        <h4> {item?.office_visit ? "Office" : "Virtual"} </h4>
                    </div>
                </div>
            </div>

        </Link>
        </div>

        <div className="cart-footer">
            <div className='bottom-group'>
                <div className='time'>
                    {time}
                </div>
            {
                    item?.global_job?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.global_job?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.top_company ? (
                    <div className='actively-tag'>
                        #TopCompany
                    </div>

                    ): (
                        ""
                    )
                }

            </div>

            <div className='btn-section'>
                <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                Apply  <FaArrowRight  color='#ffffff' size={15} />     

                </div>
            </div>
        </div>

                {/* Mobile card footer */}
        <div className="mob_card_footer">
            <div className="mob_chips-section">
                {
                    item?.global?.employer_account?.actively_hiring ? (
                    <div className='actively-tag'>
                        #HiringActively
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.global?.employer_account?.top_employer ? (
                    <div className='actively-tag'>
                        #TopEmployer
                    </div>

                    ): (
                        ""
                    )
                }
                {
                    item?.top_company ? (
                    <div className='actively-tag'>
                        #TopCompany
                    </div>

                    ): (
                        ""
                    )
                }
            </div>
            <div className="mob_btn_section">
                <div className='time'>
                    {time}
                </div>

                <div className='btn-section'>
                    <div className='enroll-btn' onClick={()=> handleApply(item?.id, item?.screening_questions)}>
                    Apply <FaArrowRight  color='#ffffff' size={15} />     

                    </div>
                </div>
            </div>
        </div>

        </>
    );
}