import SidebarButtons from "../sidebarButtons/SidebarButtons";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../images/logos/dashboard-logo.png";
import "./sidebar.scss";
import { logoutUser } from "services/actions/auth";
import { toast } from "react-toastify";
import axios from "axios";
// import ToolTip from "../sidebarButtons/ToolTip"

const EducatorSidebar = ({
  showSidebar,
  toggleSidebar,
  projects,
  internships,
  jobs,
}) => {
  const { pathname } = useLocation();
  const isActive = pathname.split("/")[2];
  const navigate = useNavigate();

  const logout = async () => {
    const {
      data: { data, error },
    } = await axios.delete("/v1/auth/session/", {
      validateStatus: () => true,
    });

    if (!error) {
      toast.info("Logged out successfully!");
      navigate("/signin");
      logoutUser();
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className={showSidebar ? "sidebar show" : "sidebar"}>
      <div className="brand-container px-3 pt-2 d-flex justify-content-between align-items-center">
        <img
          onClick={() => navigate("/")}
          src={Logo}
          className="dashboard-logo"
          height="30px"
          width="120px"
        />
        <span
          className={`close_btn ${showSidebar ? "show" : ""} .d-lg-none`}
          onClick={() => {
            toggleSidebar(false);
          }}
        ></span>
      </div>
      <hr />
      <div className="sidebar-buttons">
        <SidebarButtons
          text="Dashboard"
          link="/educator/dashboard"
          icon="fa-solid fa-house"
          locked={false}
          myClass={
            isActive === "dashboard" ||
            (/project|internship|job/.test(isActive) &&
              !projects &&
              !internships &&
              !jobs)
              ? "active"
              : ""
          }
        />

        <SidebarButtons
          text="Explore"
          link="/educator/explore/*"
          icon="fas fa-compass"
          locked={false}
          myClass={isActive === "explore" ? "active" : ""}
        />

        <SidebarButtons
          text="Student"
          link="/educator/student-lists"
          icon="fas fa-users"
          locked={false}
          myClass={isActive === "student-list" ? "active" : ""}
        />

        {/* <SidebarButtons
          text="Jobs"
          link="/employer/job/list"
          icon="fa-solid fa-building"
          locked={!internships && !projects && !jobs}
          myClass={isActive === "job" && jobs ? "active" : ""}
        /> */}

        <SidebarButtons
          text="Partner Companies"
          link="/educator/company"
          icon="fa-solid fa-user-group"
          locked={false}
          myClass={isActive === "company" ? "active" : ""}
        />

        <SidebarButtons
          text="Subscription"
          link="/educator/subscriptions"
          icon="fa-solid fa-file-circle-check"
          locked={false}
          myClass={isActive === "subscriptions" ? "active" : ""}
        />

        <SidebarButtons
          text="Settings"
          link="/educator/settings"
          icon="fa-solid fa-gear"
          locked={false}
          myClass={isActive === "settings" ? "active" : ""}
        />
        <SidebarButtons
          text="Helpdesk"
          link="/educator/helpdesk"
          icon="fas fa-headset"
          locked={false}
          myClass={isActive === "helpdesk" ? "active" : ""}
        />
        <SidebarButtons
          text="Logout"
          onClick={logout}
          icon="fa-solid fa-sign-out-alt"
          locked={false}
        />
      </div>
    </div>
  );
};

export default EducatorSidebar;
