import ProfileIcon from "components/employer/ProfileIcon"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import birth from "./birth.svg"
import college from "./college.svg"
import "./resume.scss"
import axios from "axios"
import Loader from "components/employer/Loader"

const Resume = ({ onAction, onApply }) => {
  const navigate = useNavigate()
  const user = useSelector((state) => state?.auth?.user)
  // // console.log("user", user);
  const DateType = (value) => {
    return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ")
  }
  // // console.log("user data resume", user);
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const lang = `${user?.languages}`
  const langs = lang
    .replace(/[{}"]/g, "") // remove {, }, and "
    .split(",") // split by ,
    .map((str) => str.trim()) // remove leading/trailing whitespace

  // // console.log(langs, "langs");

  // date of birth
  let age
  if (user?.date_of_birth) {
    const dob = user?.date_of_birth
    const dobDate = new Date(dob)
    const diffMs = Date.now() - dobDate.getTime()
    age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25))
  }

  // // console.log(`You are ${age} years old`);
  if (!user?.student_resume) {
    toast.error("Please Complete your profile to apply")
    return <Navigate to="/student/profile" />
    // return navigate("/student/profile");
  }
  /********/
  const maxSize = 5 // MB
  const payload = {}

  const onChangeHandler = async (e) => {
    // console.log(e)
    setLoading(true)
    const accepted = ["pdf", "ppt", "pptx", "doc", "docx"]
    setFile(null)
    const uploaded = e.target.files[0]

    // check if file is accepted
    const fileExtension = uploaded.name.split(".").pop()
    if (!accepted.includes(fileExtension)) {
      toast.error("File type not accepted")
      return
    }

    // check if file is too large
    const fileSize = uploaded.size / 1024 / 1024
    if (fileSize > maxSize) {
      toast.error(`File size too large (max ${maxSize}MB)`)
      return
    }

    setFile(uploaded)

    const { data: fileData } = await axios.post(
      "/v1/student/profile/resume",
      { student_resume: uploaded },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    setLoading(false)
    if (fileData?.data) {
      toast.success("Resume Uploaded Successfully!")
    } else {
      toast.error("Resume  upload failed.")
    }

    console.log("fff", file)
  }

  const onEdit = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = "application/pdf"
    input.click()
    input.onchange = onChangeHandler
  }

  // console.log(file, "file123");

  const style = {
    marginLeft: "10px",
    cursor: "pointer",
    color: "#4d4d4d",
    fontSize: "20px",
  }

  // console.log("userrrrr", user)
  if (loading) return <Loader />
  return (
    <section className="resume-profile row container mx-auto">
      <div className="top_buttons d-none d-sm-flex">
        <h2>Review your profile</h2>
        <div className="booking-btn">
          <button
            type="button"
            onClick={() => navigate("/student/profile")}
            className="edit_btn"
          >
            Edit
          </button>
          <button className="bg-success Submit_btn" onClick={onAction}>
            Submit & Apply
          </button>
        </div>
      </div>
      <div className="d-none d-sm-block resume_main card col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-4">
        <div className="mentor-content">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="flexbox mb-2 new-class-stud">
                {user?.profile_photo ? (
                  <img
                    src={user?.profile_photo}
                    alt={user?.first_name}
                    width={85}
                    height={75}
                    style={{ borderRadius: "20%" }}
                  />
                ) : (
                  <div className="profile-icon-stu">
                    <ProfileIcon />
                  </div>
                )}

                <div className="cnt">
                  <h2 className="resume-stu-name">{`${user?.first_name} ${user?.last_name}`}</h2>
                  <p className="light">{user?.location}</p>
                </div>
              </div>
            </div>
            {/* <div className="tags_skills">
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
            </div> */}

            <div className="user_details d-flex align-items-center gap-4 mt-3 mb-1">
              {user?.course_name && (
                <div className="flex gap-2">
                  <img src={college} alt="Image" />{" "}
                  <small>{user?.course_name}</small>
                </div>
              )}
              {age && (
                <div className="flex gap-2">
                  <img src={birth} alt="Image" /> <small>{age} Years old</small>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            {user?.bio && <p className="para">{user?.bio}</p>}
          </div>
        </div>
        <div className="row container-ajay gap-0">
          {/* resume */}
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {user?.student_resume ? (
              <div className="education">
                <h3>Resume</h3>
                <div className="col-12">
                  <div
                    className="form-control py-3 mb-2"
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <Link to={user?.student_resume} target="_blank">
                      <span>View Resume</span>
                      <i className="fa-solid fa-eye ms-2"></i>
                    </Link>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={onEdit}
                    >
                      <span
                        style={{
                          marginRight: "5px",
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        Upload New Resume
                      </span>
                      <span className="fa-regular fa-pen-to-square"></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <label className="form-group mb-3 w-100 text_label flex-two-column">
                  Upload Resume
                </label>
                <input
                  type="file"
                  className="form-control py-4 my-3"
                  onChange={onChangeHandler}
                />
              </div>
            )}
          </div>

          {user?.student_resume && <div className="line" ><div className="resume-line"></div></div>}

          {/* end resume */}
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            {/* {user?.course_name && (
              <div className="education">
                <h3>Education</h3>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>{user?.course_name}</strong>  {user?.branch_name}{" "}
                    </span>{" "}
                  </h4>
                  <p>
                    Date | {moment(user?.course_start_year).format("MMM YYYY")}{" "}
                    - {moment(user?.course_end_year).format("MMM YYYY")}
                  </p>
                </div>
              </div>
            )} */}
            {user?.education?.length > 0 && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="education">
                  <h3>Education</h3>
                  {user?.education?.map((item) => {
                    return (
                      <div className="education-detail">
                        <h4>
                          <span>
                            <strong>{item?.education_type}</strong> -{" "}
                            {item?.board_university
                              ? item.board_university
                              : ""}
                          </span>
                        </h4>
                        <p>
                          <span>
                            {item?.course_name ? item?.course_name : ""}
                          </span>{" "}
                          <span>{item?.course_name ? "|" : ""}</span>{" "}
                          <span>{item?.stream}</span> {item?.stream ? "|" : ""}{" "}
                          <span>
                            {item?.percentage ? `(${item.percentage}%)` : ""}
                          </span>{" "}
                          |{" "}
                          {item?.passing_year
                            ? new Date(item?.passing_year).getFullYear()
                            : ""}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {user?.experiences?.length > 0 &&  <div className="line" ><div className="resume-line"></div></div>}

          {user?.experiences?.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Experience</h3>
                {user?.experiences?.map((item) => {
                  return (
                    <div className="education-detail">
                      <h4>
                        <span>
                          <strong>{item?.designation}</strong> -{" "}
                          {item?.company_name}
                        </span>{" "}
                      </h4>
                      <p>
                        Project | {DateType(item?.start_date)} -{" "}
                        {DateType(item?.end_date)}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {user?.languages?.length > 0 &&  <div className="line" ><div className="resume-line"></div></div>}

        <div className="row">
          {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="education">
              <h3>Skills</h3>
              <div className="education-detail">
                <h4>
                  <strong>Adobe Photoshop </strong>{" "}
                </h4>
              </div>
              <div className="education-detail">
                <h4>
                  {" "}
                  <strong> UIUX </strong>
                </h4>
              </div>
              <div className="education-detail">
                <h4>
                  <strong> Figma </strong>
                </h4>
              </div>
            </div>
          </div> */}
          {user?.languages?.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Language</h3>
                {langs.map((item) => {
                  return (
                    <div className="education-detail">
                      <h4>
                        {" "}
                        <strong>{item} </strong>{" "}
                      </h4>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {user?.student_projects?.length > 0 &&  <div className="line" ><div className="resume-line"></div></div>}
          {user?.student_projects?.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Projects</h3>
                {user?.student_projects?.map((item) => {
                  return (
                    <div className="education-detail_project">
                      <h4>
                        <strong>{item?.name} </strong>
                      </h4>
                      {/* toDateString().split(" ").slice(1,3).join(" ") */}
                      <p>
                        Project | {DateType(item?.start_date)} -{" "}
                        {DateType(item?.end_date)}{" "}
                      </p>
                      <p>
                        <Link to="" className="px-0">
                          {item?.link}
                        </Link>
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          {user?.documents?.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="education">
                <h3>Documents</h3>
                {/* {user?.documents?.map((item) => {
                return (
                  <div className="education-detail" key={item?.title}>
                    <h4>
                      <strong>{item?.title}</strong>
                    </h4>
                    <p>
                      <Link to={item?.link}>{item?.link}</Link>
                    </p>
                  </div>
                )
              })} */}
                {user?.documents?.map((item) => {
                  return (
                    <div className="education-detail_project">
                      <h4>
                        <strong>{item?.title}</strong>
                      </h4>
                      <p>
                        <Link to="" className="px-0">
                          {item?.link}
                        </Link>
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/*mobile*/}

      <div className="d-block d-sm-none">
        <h2 className="title_div">Review your profile</h2>
        <div className="mentor_content_mobo mentor-content">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="flexbox">
                {user?.profile_photo ? (
                  <img
                    src={user?.profile_photo}
                    alt={user?.first_name}
                    width={85}
                    height={75}
                    style={{ borderRadius: "20%" }}
                  />
                ) : (
                  <div className="profile-icon-stu">
                    <ProfileIcon />
                  </div>
                )}
                <div className="cnt">
                  <h2>{`${user?.first_name} ${user?.last_name}`}</h2>
                  <p className="light">{user?.location}</p>
                </div>
              </div>
            </div>
            {/* <div className="tags_skills">
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
              <span className="badge bg-light text-dark me-2 p-2 mt-2 ">
                UX designer
              </span>
            </div> */}

            <div className="user_details d-flex align-items-center gap-4 mt-3 mb-1">
              {user?.course_name && (
                <div className="flex gap-2">
                  <img src={college} alt="Image" />{" "}
                  <small>{user?.course_name}</small>
                </div>
              )}
              {age && (
                <div className="flex gap-2">
                  <img src={birth} alt="Image" /> <small>{age} Years old</small>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            <p className="para">{user?.bio}</p>
          </div>
        </div>

        <div className="py-0 mt-4 mentor_content_mobo">
          {/* {user?.course_name && (
            <div className="mobo_education">
              <h3>Education</h3>
              <div className="education-detail">
                <h4>
                  <span>
                    <strong>{user?.course_name}</strong> - {user?.branch_name}{" "}
                  </span>{" "}
                </h4>
                <p>
                  Data | {moment(user?.course_start_year).format("MMM YYYY")} -{" "}
                  {moment(user?.course_end_year).format("MMM YYYY")}
                </p>
              </div>
            </div>
          )} */}

          {user?.student_resume ? (
            <div className="education">
              <h3>Resume</h3>
              <div className="col-12">
                <div
                  className="form-control py-3 mb-2"
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Link to={user?.student_resume} target="_blank">
                    <span>View Resume</span>
                    <i className="fa-solid fa-eye ms-2"></i>
                  </Link>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={onEdit}
                  >
                    <span
                      style={{
                        marginRight: "5px",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                    >
                      Upload New Resume
                    </span>
                    <span className="fa-regular fa-pen-to-square"></span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <label className="form-group mb-3 w-100 text_label flex-two-column">
                Upload Resume
              </label>
              <input
                type="file"
                className="form-control py-4 my-3"
                onChange={onChangeHandler}
              />
            </div>
          )}

          {user?.education?.length > 0 && (
            <div className="mobo_education">
              <h3>Education</h3>
              {user?.education?.map((item) => {
                return (
                  <div className="education-detail">
                    <h4>
                      <span>
                        <strong>{item?.education_type}</strong> -{" "}
                        {item?.board_university}
                      </span>
                    </h4>
                    <p>
                      <span>{item?.course_name}</span>{" "}
                      <span>{item?.course_name ? "|" : ""}</span>{" "}
                      <span>{item?.stream}</span> {item?.stream ? "|" : ""}{" "}
                      <span>{item?.percentage}%</span> |{" "}
                      {new Date(item?.passing_year).getFullYear()}
                    </p>
                  </div>
                )
              })}
            </div>
          )}

          {user?.experiences?.length > 0 && (
            <div className="pt-0 mobo_education">
              <h3>Experience</h3>
              {user?.experiences?.map((item) => {
                return (
                  <div className="education-detail">
                    <h4>
                      <span>
                        <strong>{item?.designation}</strong> -{" "}
                        {item?.company_name}
                      </span>{" "}
                    </h4>
                    <p>
                      Project |{DateType(item?.start_date)} -{" "}
                      {DateType(item?.end_date)}
                    </p>
                  </div>
                )
              })}
            </div>
          )}
       

     
          {/* <div className="mobo_education">
            <h3>Skills</h3>
            <div className="education-detail">
              <h4>
                <strong>Adobe Photoshop </strong>{" "}
              </h4>
            </div>
            <div className="education-detail">
              <h4>
                {" "}
                <strong> UIUX </strong>
              </h4>
            </div>
            <div className="education-detail">
              <h4>
                <strong> Figma </strong>
              </h4>
            </div>
          </div> */}
          {user?.languages?.length > 0 && (
            <div className="pt-0 mobo_education">
              <h3>Language</h3>
              {langs.map((item) => {
                return (
                  <div className="education-detail">
                    <h4>
                      {" "}
                      <strong>{item} </strong>{" "}
                    </h4>
                  </div>
                )
              })}
            </div>
          )}
      

       
          {user?.student_projects?.length > 0 && (
            <div className="mobo_education">
              <h3>Projects</h3>
              {user?.student_projects?.map((item) => {
                return (
                  <div className="education-detail_project">
                    <h4>
                      <strong>{item?.name} </strong>
                    </h4>
                    {/* toDateString().split(" ").slice(1,3).join(" ") */}
                    <p>
                      Project | {DateType(item?.start_date)} -{" "}
                      {DateType(item?.end_date)}{" "}
                    </p>
                    <p>
                      <Link to="" className="px-0">
                        {item?.link}
                      </Link>
                    </p>
                  </div>
                )
              })}
            </div>
          )}
     

        {user?.documents?.length > 0 && (
          <div className="py-0 mt-4 mentor_content_mobo">
            <div className="mobo_education">
              <h3>Documents</h3>
              {user?.documents?.map((item) => {
                return (
                  <div className="education-detail_project">
                    <h4>
                      <strong>{item?.title}</strong>
                    </h4>
                    <p>
                      <Link to="" className="px-0">
                        {item?.link}
                      </Link>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      </div>

      <div className="bottom_button d-block d-sm-none">
        <div className="booking-btn">
          <button
            type="button"
            onClick={() => navigate("/student/profile")}
            className="edit_btn"
          >
            Edit
          </button>
          <button className="bg-success Submit_btn" onClick={onAction}>
            Submit & Apply
          </button>
        </div>
      </div>
    </section>
  )
}

export default Resume
