import React, { useState } from 'react'
import "./filterbox.scss"
import TextFieldWithPopup from 'components/formComponents/TextFieldWithPopup';

const FilterBox = ({headerText, listItems, handleChange, state, isCheckBox = true, handleCheckboxChange, selectedItems, clearSelection, updateLocation}) => {

    // const [selectedItems, setSelectedItems] = useState([]);

    // const handleCheckboxChange = (item) => {
    //   if (selectedItems.includes(item)) {
    //     setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
    //   } else {
    //     setSelectedItems([...selectedItems, item]);
    //   }
    //   handleChange(selectedItems);
    // };

    // console.log("selected", selectedItems);

    const [isPopupOpen, updatePopup] = useState(() => false);

    // const [inputValue, setInputValue] = useState("");

  return (
    <div className='filter-wrapper'>
        <div className='filter-box-main'>
            <div className='filter-head'>
                <p>{headerText}</p>
                <p style={{cursor: "pointer"}} onClick={clearSelection} >Clear</p>
            </div>

                {
                    headerText === "Location" ? (
                        <TextFieldWithPopup
                        required={true}
                        id="location"
                        isPopupOpen={isPopupOpen}
                        updatePopup={updatePopup}
                        path="/v1/location/city"
                        key_name="location"
                        // setValue={updateLocation}
                        onInputChange={updateLocation}
                        // withId={state?.city}
                        placeholder="Search your location "
                        className="location-box "
                      />

                    // <input
                    // className='location-box'
                    // style={{marginTop: "8px"}}
                    // type='text'
                    // placeholder='Search location'
                    // value={inputValue}
                    // onChange={(e) => {setInputValue(e.target.value); updateLocation(e.target.value);}}
                    // />
                    ): (
                        ""
                    )
                }
            <div className="filter-body">
                <ul>
                    {
                        listItems && listItems?.map((item, index)=> {
                            return (
                                <div className='filter-list-item' key={index}>
                                {isCheckBox && (
                                      <input
                                        className='checkbox'
                                        type="checkbox"
                                        checked={ Array.isArray(selectedItems) && selectedItems.includes(item.toLowerCase())}
                                        onChange={() => handleCheckboxChange(item)}

                                      />
                                    )}
                                    <li className={`${state?.selected === index ? "selected": ""} `} onClick={ ()=> !isCheckBox && handleChange(item, index) } > {item}  </li>
                                </div>
                            );
                        })
                    }
                    {/* <li>All (285)</li>
                    <li>Project (165)</li>
                    <li>Internship (125)</li>
                    <li>Job (55)</li> */}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default FilterBox