import { ADD_CITY, SEARCH_KEYWORD } from "../constants";

const initialState = {
  keyword: "",
  city: "",
  company_id: new Set()
};

export default function location(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_CITY:
      return {
        ...state,
        city: payload || "",
      };

    case SEARCH_KEYWORD:
      // console.log("searchkeyword", payload)
      return {
        ...state,
        keyword: payload.keyword || "",
      };

    case "SET_COMP":
      // console.log("searchkeyword", payload)
      return {
        ...state,
        company_id: payload.company_id || "",
      };

    default:
      return state;
  }
}
