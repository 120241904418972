import { toast } from "react-toastify"
import SocialButton from "../../../layout/buttons/socialButton/SocialButton"

import FacebookImg from "../../../images/logos/socials/facebook.png"
import LinkedInImg from "../../../images/logos/socials/linkedIn.png"
import GoogleImg from "../../../images/logos/socials/google.png"
// import axios from "axios";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getUserRole } from "routes/getUserRole"
import { addAccount } from "../../../services/actions/auth"
import { getLocalStorage } from "../../../services/factories/getParsed.js"
import setAuthToken from "services/factories/setAuthToken"

const SocialLogin = ({ method = "signup", role = 4 }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openChildWindow = (url, windowName, w, h) => {
    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
    return window.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    )
  }
  const clickHandler = (provider) => {
    // let win;
    // if(provider.toLowerCase() === "facebook"){
    //    win = openChildWindow(
    //     `http://localhost:8080/v1/auth/${provider.toLowerCase()}/${method}/${
    //       method === "signup" ? role : ""
    //     }`,
    //     `${process.env.REACT_APP_NAME} ${provider} Login`,
    //     500,
    //     500
    //   )

    // }else{
      
      const win = openChildWindow(
       `/v1/auth/${provider.toLowerCase()}/${method}/${
         method === "signup" ? role : ""
       }`,
       `${process.env.REACT_APP_NAME} ${provider} Login`,
       500,
       500
     )
     
    // }

    win.focus();

    // console.log("winnn", win);
    const interval = setInterval(() => {
      if (!win || win.closed) {
        clearInterval(interval);
        handleWindowClose();
      }
    }, 1000);
  
    const handleWindowClose = () => {
      const message = getLocalStorage("message", false);
      if (!message) return;
      if (message === "success") {
        let user = getLocalStorage("user");
        if (!user) return;
        setAuthToken(true)
        dispatch(addAccount({
            user: {
              ...user,
              isInterestAdded: user.role === 4 ? true : false,
              isOTPVerified: !!user?.is_email_verified && !user?.is2FA,
            } 
          })
        );
        toast.success("Successfully logged in");
        localStorage.removeItem("message");
        if (method === "signup") {
          navigate("/verify-otp", { state: { method: "2" } });
        } else {
          navigate(getUserRole(user?.role));
        } 
      } else {
        console.log("else....");
        toast.error(message);
        localStorage.removeItem("user");
        localStorage.removeItem("message");
      }
    };

    // const timer = setInterval(function () {
    //   if (win?.closed) {
    //     clearInterval(timer)
    //     const message = getLocalStorage("message", false)
    //     if (!message) return
    //     if (message === "success") {
    //       let user = getLocalStorage("user")
    //       if (!user) return
    //       dispatch(addAccount({ user }))
    //       toast.success("Successfully logged in")
    //       localStorage.removeItem("message")
    //       if (method === "signup") {
    //         navigate("/verify-otp", { state: { method: "2" } })
    //       } else navigate(getUserRole(user.role))
    //     } else {
    //       toast.error(message)
    //       localStorage.removeItem("user")
    //       localStorage.removeItem("message")
    //     }
    //   }
    // }, 1000)
  }

  const providers = [
    { name: "Google", img: GoogleImg, color: "#000" },
    { name: "Facebook", img: FacebookImg, color: "#4267B2" },
    { name: "LinkedIn", img: LinkedInImg, color: "#0A66C2" },
  ]

  return (
    <div className="socials-button align-items-center d-flex ">
      {providers?.map((provider, index) => {
        return (
          <SocialButton
            key={index}
            clickHandler={() => clickHandler(provider.name)}
            img={provider.img}
            color={provider.color}
            text={provider.name}
            customClass={"social-login btn me-2 bg-white"}
          />
        )
      })}
    </div>
  )
}

export default SocialLogin
