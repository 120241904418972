export const ADD_USER = "SET_CURRENT_USER";
export const REMOVE_USER = "REMOVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_VERIFIED_OTP = "UPDATE_VERIFIED_OTP";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const IS_INTEREST_ADDED = "IS_INTEREST_ADDED";

export const ADD_APPLICATION_DATA = "ADD_APPLICATION_DATA";
export const REMOVE_APPLICATION_DATA = "REMOVE_APPLICATION_DATA";
export const INIT_APPLICATION_DATA = "INIT_APPLICATION_DATA";
export const ADD_CITY = "ADD_CITY";
export const ADD_SERVICE_DATA = "ADD_SERVICE_DATA";
export const REMOVE_SERVICE_DATA = "REMOVE_SERVICE_DATA";

export const ADD_PROJECT_ID = "ADD_PROJECT_ID";

export const REMOVE_PROJECT_ID = "REMOVE_PROJECT_ID";

export const EDUCATOR_INVITES_NUMBER = "EDUCATOR_INVITES_NUMBER";

export const SEARCH_KEYWORD = "SEARCH_KEYWORD"; 