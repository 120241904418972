import axios from "axios"
import PasswordField from "components/formComponents/PasswordField"
import React from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import "./setting.scss"



const AdminSettings = () => {
  
  const { register, handleSubmit, reset } = useForm()

  const updatePassword = async ({ password, newPassword }) => {
    try {
      const res = await axios.patch("/v1/auth/change_password", {
        password,
        newPassword,
      })

      if (!res.data.error) {
        toast.success(res.data.message)
        reset({
          password: "",
          newPassword: "",
          confirmPassword: "",
        })
      } else {
        toast.error(res.data.message || "Something went wrong")
      }
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong")
    }
  }

  const onSubmit = (data) => {
    if (!data?.password || !data?.newPassword || !data?.confirmPassword) {
      toast.error("Please fill all the fields")
    } else {
      if (data.newPassword !== data.confirmPassword) {
        toast.error("New password and confirm password does not match")
      } else if (data.password === data.newPassword) {
        toast.error("New password and current password should not be same")
      } else if (data.newPassword.length < 7) {
        toast.error("New password should be 7 characters long")
      } else {
        updatePassword(data)
      }
    }
  }


  return (
    <div className="password_field p-2 p-md-4">
    <div className="change_password mx-2">
    <div className="row">
      <div className="col-md-6 col-12">
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="form_style">
            <PasswordField
              register={register}
              id="password"
              placeholder="7+ Characters"
              label="Enter current password"
              displayStrength={false}
            />
            <PasswordField
              register={register}
              id="newPassword"
              placeholder="7+ Characters"
              label="New password"
            />
            <PasswordField
              register={register}
              id="confirmPassword"
              placeholder="7+ Characters"
              label="Confirm password"
              displayStrength={false}
            />

            <div className="right">
              <button className="cancel" type="reset">
                Cancel
              </button>{" "}
              <button type="submit" className="changePass">Change Password</button>
            </div>
          </form>
      </div>
     


    </div>
  </div>
    
</div>



  )
}

export default AdminSettings
