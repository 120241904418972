import React from "react";

const AiTextField = ({
  label,
  placeholder,
  id,
  setValue,
  register,
  disabled = false,
}) => {
  return (
    <div className="form-group mt-3 mb-3 text-right d-flex flex-column text-right">
      <label className="text-start mb-2" htmlFor="">
        {label}
      </label>
      <input
        {...register(id)}
        id={id}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        type="text"
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default AiTextField;
