import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./mentorProfileCard.scss";
import userPic from "./user.png";

const MentorProfileCard = ({ data }) => {
  const navigate = useNavigate();
  // const user = useSelector((state) => state?.auth?.user);

  // const [user, setUser] = useState(() => null);

  const { id } = useParams();

  // useEffect(() => {
  //   const fetchDetails = async () => {
  //     const reponse = await axios.get(`/v1/mentor/${id}`);
  //     setUser(reponse?.data);
  //   };
  //   fetchDetails();
  // }, []);

  // // console.log("user", user);
  const DateType = (value) => {
    return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ");
  };
  // console.log("user data resume", data);

  return (
    <section className="resume-profile row container mx-auto">
      <div className="d-none d-sm-block resume_main card col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-4">
        <div className="mentor-content">
          <div className="row ">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="flexbox">
                <img
                  src={data?.profile_photo || userPic}
                  alt="rahul"
                  width={65}
                  height={65}
                  className="rounded-circle"
                />
                <div className="cnt">
                  <h2>
                    {data?.first_name} {data?.last_name}
                  </h2>
                  <p className="light">{data?.location}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 justify-content-end d-flex mentor-btn1">
              <div className="row w-100">
                <div className="col-7 text-right">
                  <div className="mentor-price">
                    <p>₹ {data?.mentor_charges} / Session</p>
                  </div>
                </div>
                <div className="button_div col-5">
                  <Link to={`/student/mentor/details/${data?.id}`}>
                    <button>Book Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            <p className="para">{data?.bio}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            {data?.experience && (
              <div className="education">
                <h3>Experience</h3>
                {data?.experience?.map((item) => {
                  return (
                    <div className="education-detail">
                      <h4>
                        <span>
                          <strong>{item?.designation}</strong> -{" "}
                          {item?.company_name}
                        </span>{" "}
                      </h4>
                      <p>
                        Project |{DateType(item?.start_date)} -{" "}
                        {DateType(item?.end_date)}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/*mobile*/}

      <div className="d-block d-sm-none">
        <div className="mentor_content_mobo mentor-content">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="flexbox">
                <img src={userPic} alt="rahul" width={85} height={85} />
                <div className="cnt">
                  <h2>{`${data?.first_name} ${data?.last_name}`}</h2>
                  <p className="light">{data?.location}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
            <p className="para">{data?.bio}</p>
          </div>
        </div>

        <div className="py-0 mt-4 mentor_content_mobo">
          <div className="mobo_education">
            <h3>Experience</h3>
            {data?.experiences?.map((item) => {
              return (
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>{item?.designation}</strong> -{" "}
                      {item?.company_name}
                    </span>{" "}
                  </h4>
                  <p>
                    Project |{DateType(item?.start_date)} -{" "}
                    {DateType(item?.end_date)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="bottom_button d-block d-sm-none">
        <div className="booking-btn">
          <div className="mentor-price">
            <p>₹ {data?.mentor_charges}/ Session</p>
          </div>
          <Link to={`/student/mentor/details/${data.id}`}>
            <button className="bg-primary edit_btn">Book Now</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MentorProfileCard;
