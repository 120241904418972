import React from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import HeroSection from "./components/heroSection/HeroSection";
import sImg from "../../images/hero-02.png";
import StartJourney from "./components/Journey/StartJourney";
import TopCategorySection from "./components/TopCategories/topCategorySection";
import ExploreSection from "./components/exploreSection/ExploreSection";

import FeatureSection from "./components/FeatureSection/featureSection";
import PartnerSection from "./components/partners/partnerSection";
import StudentChooseSection from "./components/HowItWorks/studentChooseSection";
import TestimonialSlider from "./components/testimonial/testimonialSlider";
import Footer from "view/dashboard/student/footer/Footer";
import studentHero from "../../images/studentHeroInhanced.png";
// import studentHero from "../../images/studentHero.png";
import studentJimg from "../../images/studentJimg.png";
import GoToTop from "GoToTop";
import img1 from "./images/s1.png";
import img2 from "./images/s2.png";
import img3 from "./images/s3.png";
import { Helmet } from "react-helmet";

// import heroImg from '../../images/heroImg.png'
const StudentPage = () => {
  return (
    <div className="home_page row justify-content-center align-items-center">
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="Internship companies in Chennai can use our platform to find the right candidates. With Qollabb, finding internships for engineering students has never been so easy!"
        />
        <title>
          internship companies in Chennai |internships |
          internship in Bangalore
        </title>
        <link rel="canonical" href="https://qollabb.com/student-page" />

        <meta property="og:title" content={"Qollabb"} />
        <meta property="og:description" content={"Connecting Companies With Brilliant Minds in Campuses"} />
        <meta property="og:image" content={studentHero} />
        <meta property="og:url" content={"https://qollabb.com/student-page"} />
        <meta property="og:type" content="website" />
      </Helmet>

      <TopNavBar />
      <NavBar />
      <HeroSection
        topText="Discover your potential"
        cardText="Top Rated Companies"
        buttonText="Explore all projects"
        hero={studentHero}
        title="Unlock Your "
        title1="With Real Projects, Internships & Jobs"
        title2="Potential"
        semiTitle="At Qollabb, we know that real-world experience is key to landing your dream job. That's why we connect you with real projects, internships and jobs from top companies across a variety of industries."
      />
      <StartJourney
        Simg={studentJimg}
        student="student"
        title1="01. Create a Profile"
        subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
        title2="02. Explore and Work On Opportunities"
        subTitle2="Search, select and join from over hundreds of real industry projects OR simply apply for an internship or a job posted by employers."
        title3="03. Get Certified & Get Noticed By Employers"
        subTitle3="Submit your project work to showcase your capabilities and impress top employers, get noticed. Complete all project milestones, get your project work evaluated by senior experts & earn valuable certification."
      />
      <TopCategorySection />
      <ExploreSection />
      <FeatureSection />
      <PartnerSection />
      <StudentChooseSection
        student1="student"
        text1="Why Students Choose Use to Build Industry Exposure"
        text2
      />
      <TestimonialSlider
        title="What Students"
        img1={img1}
        name1="Rahul Chavan"
        designation1="Student"
        title1="Gained Industry Expertise !"
        subTitle1="We had the opportunity to learn through the extremely interactive and engaging lectures from the industry experts.
       The unique and distinctive proposition of learning through collective discourse providing both theoretical conceptual clarity and application-oriented methodology has helped me in understanding the project management concepts in a more effective way.
       The quality of teaching, infrastructure, staff and overall learning experience is the best in class."
        img2={img2}
        name2="Rutuja Chougale"
        designation2="Student"
        title2="Great Project Experience !"
        subTitle2="The Qollabb provided me the complete guidance in stepwise manner which helped me understand the overall project and complete it in given timeline. The instructor was very enthusiastic and knowledgeable about the subject matter. He kept the class interesting and brought up good examples. Overall I had a great experience with Qollabb."
        img3={img3}
        name3="Aishwarya"
        designation3="Student"
        title3="Helpful Mentors !"
        subTitle3="My internship with Qollabb has been a great blessing to have been paired with phenomenal mentors who have guided me through this educational internship, every step of the way. It's a great opportunity to apply the knowledge I've gained in to a professional work setting. This opportunity has given me a chance to see if what I am studying is really what I want. I have to say that my project guidance from Qollabb helped me immensely in moving to the next level of my career. 
       Thanks and regards for all the support."
      />
      <Footer />
      <GoToTop />
    </div>
  );
};

export default StudentPage;
