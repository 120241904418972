import Datatable from "components/datatable-new/DatatableNew";
import { downloadCSV } from "components/dataTable/Export";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import AddModal from "./modals/AddModal";
import EditModal from "./modals/EditModal";
import axios from "axios";
import { toast } from "react-toastify";

const MDIndustries = ({ data }) => {
  // console.log(data);
  const [search, setSearch] = useState(() => "");
  const [showModal, setShowModal] = useState(false);
  const [newIndus, setNewIndus] = useState("");
  const [editIndus, setEditIndus] = useState("");
  const [editId, setEditId] = useState(-1);
  const [showEditModal, setShowEditModal] = useState(false);
  const columns = [
    {
      Header: "Industry Sector",
      accessor: "name",
      width: "55%",
    },
    {
      Header: "Actions",
      width: "45%",
      Cell: (data) => {
        return (
          <div className="row mx-0">
            <div className="col-6">
              <button
                className={`btn edit-btn`}
                onClick={() => {
                  setShowEditModal(true);
                  setEditIndus(data?.row.original.name);
                  setEditId(data?.row.original.id);
                }}
              >
                Edit
              </button>
            </div>
            <div className="col-6">
              <button
                className={`btn delete-btn`}
                onClick={() => handleDelete(data?.row.original.id)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  const rows = data
    ?.filter((post) => {
      return post?.industry_name
        ?.toLowerCase()
        ?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      return {
        id: post?.id,
        name: post?.industry_name,
      };
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newIndus === "") {
      toast.error("Enter a value");
      return;
    }

    const { data } = await axios.post(`/v1/admin/industries`, {
      name: newIndus,
    });
    if (data?.error)
      return toast.error(data?.message || "Something went wrong");
    else {
      toast.success("Industry Added");
      setNewIndus("");
      setShowModal(false);
    }
  };
  const handleDelete = async (id) => {
    const { data } = await axios.delete(`/v1/admin/industries/${id}`);
    if (data?.error)
      return toast.error(data?.message || "Something went wrong");
    else {
      toast.success("Industry Deleted");
      setNewIndus("");
      setShowModal(false);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const { data } = await axios.put(`/v1/admin/industries/${editId}`, {
      name: editIndus,
    });
    if (data?.error)
      return toast.error(data?.message || "Something went wrong");
    else {
      toast.success("Industry Updated");
      setEditIndus("");
      setShowEditModal(false);
    }
  };

  return (
    <div>
      <div className="datatable-container mt-4 tablepadding">
        <div className="table_head flex mb-3">
          <div className="mobo_upload">
            <h3 className="mb-0">
              Industries <br />{" "}
              <span className="light">{data?.length || 0} records</span>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <button
              type="button"
              className="upload_btn_icon btn btn-primary text-white px-3"
              data-toggle="modal"
              data-target="#addModal"
              onClick={() => setShowModal(true)}
            >
              Add <i className="fa-solid fa-plus"></i>
            </button>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>
        <AddModal show={showModal} setShow={setShowModal} type="industry">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="industry" className="mb-2">
                Industry
              </label>
              <input
                type="text"
                className="form-control"
                id="industry"
                placeholder="Enter industry"
                value={newIndus}
                onChange={(e) => setNewIndus(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setNewIndus("");
                  setShowModal(false);
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </AddModal>
        <EditModal
          show={showEditModal}
          setShow={setShowEditModal}
          type="industry"
        >
          <form onSubmit={handleUpdate}>
            <div className="form-group">
              <label htmlFor="industry" className="mb-2">
                Industry
              </label>
              <input
                type="text"
                className="form-control"
                id="industry"
                placeholder="Enter industry"
                value={editIndus}
                onChange={(e) => setEditIndus(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setEditIndus("");
                  setShowEditModal(false);
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </EditModal>
        <Datatable columns={columns} data={rows} />
        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  );
};

export default MDIndustries;
