import React, { useState } from "react"
import AppliedPostCard from "../../../../components/cards/AppliedPostCard"
import './exploreSection.scss'
import { useNavigate } from "react-router-dom";

const ExploreCard = ({ project_data, method, btn ,tab,url}) => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const navigate= useNavigate();

    const button_color = {
        backgroundColor: "#6149CD",
      }

      const active_btn = {
        backgroundColor: "#6149CD",
        padding: "10px 15px",
        borderRadius: "10px",
        color: "#fff",
        transition: "background-color 0.4s ease-in-out, color 0.4s ease-in-out",
      }

      const filteredProjects = selectedCategory === 'All'
      ? project_data
      : project_data.filter(item => item?.description.includes(selectedCategory));
      // : project_data.filter(item => item.required_skills?.name === selectedCategory);

      // console.log("projectdd", method, project_data);
      // console.log("filterr", filteredProjects);

  
    return (
        <div className="container explore_card">
    <div className="row">
      <div className="col-xl-5 col-lg-5 f-left">
        <div className="section-title mb-50">
          <h2>
            Discover <span className="down-mark-line text-capitalize"> {method}</span> <br />{" "}
            Matching Your Interest
          </h2>
        </div>
      </div>
    </div>
    <div className="course-main-items">
      <div className="d-flex align-items-center skill_menu d-none d-md-flex">
        {/* <p onClick={() => setSelectedCategory('All')} style={selectedCategory === "All" ? active_btn : {}}> */}
        <p onClick={() => {navigate(`/explore-page/${url}`, {state:{tab: tab}})}} style={selectedCategory === "All" ? active_btn : {}}>
          <b>View All</b>
        </p>
        <p onClick={() => setSelectedCategory('AI')} style={selectedCategory === "AI" ? active_btn : {}}>AI, ML & Data Science</p>
        <p onClick={() => setSelectedCategory('Node')} style={selectedCategory === "Node" ? active_btn : {}}>Development</p>
        <p onClick={() => setSelectedCategory('Business')} style={selectedCategory === "Business" ? active_btn : {}}>Business & Entrepreneurship</p>
        <p onClick={() => setSelectedCategory('Sales')} style={selectedCategory === "Sales" ? active_btn : {}}>Sales & Marketing</p>
        <p onClick={() => setSelectedCategory('Engineering')} style={selectedCategory === "Engineering" ? active_btn : {}}>Engineering</p>
      </div>
      <div className="tab-content portfolio-tabs-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          {
            filteredProjects?.length !== 0 ? (
                <div className="row">
                  {filteredProjects?.slice(0,3)?.map((item) => {
                    return (
                      <div className="applied_cards col-xl-4 col-lg-4 col-md-6">
                        <AppliedPostCard
                            button_color={button_color}
                          item={item}
                          method={method}
                          btn={btn}
                        />
                      </div>
                    )
                  })}
                </div>

            ) : (
              <div>No {method} found in this category!</div>
            )
          }
        </div>
      </div>
    </div>
  </div>
    )
  
}
export default ExploreCard
