import axios from "axios";
import Datatable from "components/datatable-new/DatatableNew";
import MobileDataTable from "components/datatable-new/MobileDataTable";
import DeactivatePost from "components/employer/DeactivatePost";
import StatusButton from "components/employer/StatusButton";
import EmployerList from "components/employer/employerListTop/EmployerList";
import moment from "moment";
import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import AiGenerator from "../../components/aiGenerator/AiGenerator";
import AiTextModal from "../../components/aiTextModal/AiTextModal";
const ProjectList = () => {
  const [posts, , loading, setRefreshTable, refreshTable] = useOutletContext();
  const data = posts["Projects"];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showAiTextPopup, setShowAiTextPopup] = useState(false);
  const [showAiData, setShowAiData] = useState();
  const [search, setSearch] = useState(() => "");

  const closePost = async (id, status) => {
    if (status < 1 || status > 2) return toast.error("Invalid status");
    const { error, message } = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    });

    if (error) {
      toast.error(message);
    } else {
      setRefreshTable(!refreshTable);
      toast.success("Project deactivated successfully");
    }
  };

  const activatePost = async (id, status) => {
    if (status !== 3) return toast.error("Invalid status");
    const { error, message } = await axios.patch("/v1/project/status", {
      id,
      status: 2,
    });

    if (error) {
      toast.error(message);
    } else {
      setRefreshTable(!refreshTable);
      toast.success("Project activated successfully");
    }
    // handleBlur()
  };

  const columns = [
    {
      Header: "Date Posted",
      accessor: "date",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.date}
          </strong>
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "title",
      width: "30%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.title}
          </strong>
        );
      },
    },
    {
      Header: "Total enrolled",
      accessor: "enrolled",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.enrolled}
          </strong>
        );
      },
    },
    {
      Header: "Total submitted",
      accessor: "submitted",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.submitted}
          </strong>
        );
      },
    },
    {
      Header: "Total rated",
      accessor: "rated",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.rated}
          </strong>
        );
      },
    },
    // {
    //   Header: "Send Invite",
    //   accessor: "invites",
    //   width: "13.5%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.student_invites}
    //         {data.row.original.educator_invites}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Status",
      accessor: "status",
      width: "13.5%",
      textalign: "center",
    },
    {
      Header: " ",
      width: "1%",
      sortable: false,
      Cell: (data) => {
        return (
          <DeactivatePost
            status={data.row.original.statusCode}
            projectid={data.row.original.id}
            method={"project"}
            closePost={() =>
              closePost(data.row.original.id, data.row.original.statusCode)
            }
            activatePost={() =>
              activatePost(data.row.original.id, data.row.original.statusCode)
            }
          />
        );
      },
    },
  ];

  const map = {
    1: "Draft",
    2: "Active",
    3: "Closed",
  };
  const rows = data
    ?.filter((post) => {
      return post?.title?.toLowerCase()?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        // title:
        //   post?.status === 2 || post?.status === 3 ? (
        //     <Link className="p-0" to={`/employer/project/${post?.id}`}>
        //       {post?.title}
        //     </Link>
        //   ) : (
        //     <>{post?.title || "No title"}</>
        //   ),

        title: post?.status === 2 || post?.status === 3 ? ( 
          <Link
            className="p-0"
            to={`/employer/project/${post?.id}`}
            key={post?.title}
          >
            {" "}
            {post?.title.slice(0, 60) +
              (post?.title.length > 60 ? "..." : "") || "No title"}
          </Link>
        ) : (
          <>{post?.title || "No title"}</>
        ),
       

        enrolled: post?.enrollments.applications || 0,
        submitted: post?.enrollments.completed || 0,
        rated: post?.enrollments.rated || 0,
        status: <StatusButton status={map[post?.status]} />,
        statusCode: post?.status !== 1 ? post?.status : post?.id,
        // student_invites:<Link to={`/employer/project/students/invite/${post?.id}`}>Student invite</Link>,
        // educator_invites:<Link to={`/employer/project/invite/${post?.id}`}>Educator invite</Link>
      };
    });

  const mainStyle = {
    background: "#FFFFFF",
    boxShadow: "0px 4px 44px rgba(0, 0, 0, 0.11)",
    borderRadius: "7px",
    padding: "24px",
  };
  // console.log("colums",columns)
  // console.log("row",rows)
  return (
    <div className="" style={mainStyle}>
      <AiGenerator
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        setShowAiTextPopup={setShowAiTextPopup}
        setShowAiData={setShowAiData}
        type={"project"}
      />
      <AiTextModal
        isPopupOpen={showAiTextPopup}
        setIsPopupOpen={setShowAiTextPopup}
        type={"project"}
        showAiData={showAiData}
      />
      <EmployerList
        type={"project"}
        data={data}
        search={search}
        setSearch={setSearch}
        setIsPopupOpen={setIsPopupOpen}
        isPopupOpen={isPopupOpen}
      />
      {/* <Datatable data={rows} columns={columns} /> */}
      <div className="d-none d-sm-block ">
        <Datatable columns={columns} data={rows} />
      </div>
      <div className="d-block d-sm-none">
        <MobileDataTable columns={columns} data={rows} />
      
      </div>
      
     
 

    
   
      

    </div>
  );
};

export default ProjectList;
