import "./signup.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginLeft from "layout/loginLeft/LoginLeft";

import SubmitButton from "components/formComponents/SubmitButton";

import EmailField from "components/formComponents/EmailField";
import PasswordField from "components/formComponents/PasswordField";
import TextField from "components/formComponents/TextField";
import Checkbox from "./components/CheckBox";
import Heading2 from "components/typography/Heading2";
import SocialLogin from "../socialLogin/SocialLogin";
import axios from "axios";
import { addAccount } from "services/actions/auth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import setAuthToken from "services/factories/setAuthToken";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import PhoneInput from "react-phone-input-2";

const EducatorSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(() => false);
  const [checkedTermCondition, setCheckedTermCondition] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(() => false);
  const [isPopupOpen1, updatePopup1] = useState(() => false);
  const { state } = useLocation();

  const { register, handleSubmit, setValue,getValues } = useForm({
    defaultValues: {
      email: "",
      password: "",
      full_name: "",
      designation: "",
      place_name: "",
      place_id: 0,
      location: "",
      phone_number: state?.phone_number || "",
    },
  });

  function validate(phone_number) {
    var regx = /^[6-9]\d{9}$/;
    if (regx.test(phone_number)) {
      return true;
    } else {
      return false;
    }
  }

  

  const onSubmit = async (formData) => {

    try {
      setLoading(() => true);
      formData = getValues();
      // console.log(formData, "formData");
      if(!formData?.place_name && !formData?.full_name && !formData?.email &&  !formData?.password && !formData.phone_number && !formData.designation && !formData.location){
        throw new Error("All fields are required")
      }


      // if (!formData?.tos)
      //   throw new Error("Please accept the terms and conditions");
      if (!formData?.full_name) throw new Error("Full name is required");
      if (!formData?.email) throw new Error("Email is required");
      if (!formData?.place_name) throw new Error("Institution name is required");
      if (!formData?.designation) throw new Error("Designation is required");
      // if (!formData?.phone_number) throw new Error("Phone number is required");
      if (!validate(formData.phone_number.slice(2))) {
        throw new Error("Invalid phone number");
      }
      // number should be statred with 91
      if (formData.phone_number.slice(0, 2) !== "91") {
        throw new Error("Number should be started with 91");
      }

      if (!formData?.password) throw new Error("Password is required");
      if (!formData?.location) throw new Error("Location is required");
      if (!formData?.confirm_password) throw new Error("Confirm password is required");

      if (formData?.password.length < 8)
        throw new Error("Password must be at least 8 characters");

      if (formData.confirm_password !== formData.password)
        throw new Error("Password and Confirm Password does not match");

        // console.log("formdata",formData)

      // const phoneRegex = /^[6-9]\d{9}$/;
      // if (formData.phone_number.startsWith("0")) {
      //   formData.phone_number = formData.phone_number.replace(/^0+/, "");
      // }

      // if (!phoneRegex.test(formData.phone_number)) {
      //   if (
      //     formData.phone_number.length < 10 ||
      //     formData.phone_number.length > 10
      //   ) {
      //     throw new Error("Phone number must be 10 digits");
      //   }
      // }

      // formData.phone_number = `+91${formData.phone_number}`;

      const path = "/v1/auth/signup/3";

      const auth = {
        username: formData.email,
        password: formData.password,
      };

      const options = { validateStatus: () => true };

      const [first_name, last_name = ""] = formData?.full_name?.split(" ");

      const sessionId = sessionStorage.getItem("sessionId");

      const body = {
        place_name: formData.place_name,
        designation: formData.designation,
        first_name,
        last_name,
        place_id: formData.place_id,
        phone_number: formData.phone_number,
        location: formData.location,
        sessionId: sessionId
      };

      const { data } = await axios.post(path, body, { ...options, auth });

      if (!data)
        throw new Error(
          "Connection to server failed! Please try again or later."
        );
      if (data?.error) throw new Error(data?.message);
      else {
        setAuthToken(true);
        dispatch(
          addAccount({
            user: data.user,
          })
        );
        navigate("/verify-otp", {
          replace: true,
          state: {
            role: data.user.role,
            email: data.user.email_address,
            method: 2,
          },
        });
      }
    } catch (err) {
      setLoading(() => false);
      toast.error(err?.message || err, {
        toastId: err?.message || err,
      });
    }
  };

  const updatePopup = (value) => {
    setIsPopupOpen(value);
  };

  const onFormClick = () => {
    updatePopup(false);
  };

  return (
    <div className="row mx-0">
      <LoginLeft message="Connecting Companies With Brilliant Minds in Campuses" />
      <div className="col-lg-6">
        <div className="signup-right">
          <div className="container">
            <Heading2 text={"Sign up as a College/University"} />
            {/* <br />
            <SocialLogin role={3} />
            <hr />
            <p>Or sign up using your email address</p> */}

            <form
              onSubmit={handleSubmit(onSubmit)}
              onClick={onFormClick}
              noValidate={true}
            >
              <div className="row mx-0">
                <div className="col-md-6 ps-md-0 px-md-2 px-0">
                  <TextField
                    label="Full Name"
                    id="full_name"
                    placeholder="Tony Nguyen"
                    register={register}
                    required={true}
                  />
                </div>
                <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <EmailField
                    label="Work/Official Email"
                    id="email"
                    placeholder="Tony@work.com"
                    register={register}
                    required={true}
                    
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-md-6 ps-md-0 px-md-2 px-0">
                  <TextField
                    label="Institution Name"
                    id="place_name"
                    placeholder="X Institution"
                    register={register}
                    isPopupOpen={isPopupOpen}
                    setValue={setValue}
                    updatePopup={updatePopup}
                    method="institution"
                    required={true}
                  />
                </div>
                <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <TextField
                    label="Designation"
                    id="designation"
                    name="designation"
                    placeholder="Professor"
                    register={register}
                    required={true}
                  />
                </div>

                <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <TextFieldWithPopup
                    label="Location"
                    required={true}
                    id="location"
                    register={register}
                    isPopupOpen={isPopupOpen1}
                    updatePopup={updatePopup1}
                    path="/v1/location/city"
                    key_name="location"
                    className="sign-up-location"
                    setValue={setValue}
                    withId="city"
                    // value={internshipLocation}
                    // defaultValue={internshipLocation}
                  />
                </div>
                {/* <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <TextField
                    label="Phone Number"
                    id="phone_number"
                    name="phone_number"
                    placeholder="0123456789"
                    register={register}
                    maxLength={10}
                    required={true}
                  />
                </div> */}

                <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <label className="form-group mb-3 col-12">
                   Phone Number <span>*</span>
                   <PhoneInput
                      country={"in"}
                      placeholder="Enter phone number"
                      onChange={(e) => setValue("phone_number", e)}
                      defaultCountry="IN"
                      onlyCountries={["in"]}
                      name={"phone_number"}
                      specialLabel={""}
                      containerClass={" mt-2"}
                      inputStyle={{ marginTop: "10px" }}
                      inputClass={"form-control"}
                      value={state?.phone_number}
                    />
                  </label>
                </div>



              </div>
              <div className="row mx-0">
                <div className="col-md-6 ps-md-0 px-md-2 px-0">
                  <PasswordField
                    label="Password"
                    id="password"
                    displayStrength={true}
                    register={register}
                    setValue={setValue}
                  />
                </div>
                <div className="col-md-6 pe-md-0 px-md-2 px-0">
                  <PasswordField
                    label="Confirm Password"
                    id="confirm_password"
                    register={register}
                    displayStrength={false}
                    setValue={setValue}
                  />
                </div>
              </div>

              <Checkbox
                id="tos"
                label="By creating an account, you agree to "
                isSmall={true}
                register={register}
                // link1={<Link to="/tos"> Terms and Conditions </Link>}
                // link2={<Link to="/privacy-policy"> Privacy Policy </Link>}
                link1={
                  <Link to="/terms-conditions"> Terms and Conditions </Link>
                }
                link2={<Link to="/privacy-policy"> Privacy Policy </Link>}
                setCheckedTermCondition={setCheckedTermCondition}
              />

              <SubmitButton
                value="Verify with OTP"
                onSubmit={handleSubmit(onSubmit , onSubmit)}
                isLoadingState={true}
                loading={loading}
                setLoading={setLoading}
                disabled={checkedTermCondition}
              />
            </form>

            <p className="create-account">
              Already a member? <Link to="/signin">Sign in</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducatorSignup;
