import axios from 'axios';
import { downloadCSV } from 'components/dataTable/Export';
import Datatable from 'components/datatable-new/DatatableNew'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Loader } from 'rsuite';

function SubscriptionInvoice() {

    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    
  const getSubscriptions=async()=>{
    try {
        setLoading(true);
        const res = await axios.get("/v1/subscription/get-all-subscription");

        setSubscription(res?.data?.data)
        setLoading(false)
      } catch (error) {
        console.log(error);
        setLoading(false)
      }

  }
  useEffect(()=>{
    getSubscriptions()
  },[])

   

    // console.log("subscriptionsss", subscription);

    const columns = [
      {
        Header: "Invoice Date",
        accessor: "invoice_date",
        width: "10%",
        textalign: "start",
        Cell: (data) => {
          return (
            <strong style={{ fontWeight: "700" }}>
              {data?.row.original.invoice_date}
            </strong>
          );
        },
      },
     
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
        width: "10%",
        textalign: "start",
        Cell: (data) => {
          return (
            <strong style={{ fontWeight: "700" }}>
              {data?.row.original.invoice_number}
            </strong>
          );
        },
      },
      {
        Header: "Valid Upto",
        accessor: "valid_upto",
        width: "10%",
        textalign: "start",
        Cell: (data) => {
          return (
            <strong style={{ fontWeight: "700" }}>
              {data?.row.original.valid_upto}
            </strong>
          );
        },
      },
      {
        Header: "User Name",
        accessor: "user_name",
        width: "17.5%",
        textalign: "start",
        Cell: (data) => {
          return (
            <div className="">
              <h6
                style={{
                  textTransform: "capitalize",
                }}
                className="text-colored"
              >
                {data.row.original.user_name}
              </h6>
          
            </div>
          );
        },
      },
      {
        Header: "Email/Phone",
        accessor: "email",
        width: "13%",
        textalign: "start",
        Cell: (data) => {
          return (
            <div className="">
              <strong style={{ fontWeight: "700" }}>
                {data.row.original.email}
                </strong>
              <p>{data.row.original.phone_number}</p>
            </div>
          );
        },
      },
      
    

      {
        Header: "Base Amount",
        accessor: "base_amount",
        width: "8%",
        textalign: "center",
        Cell: (data) => {
          return (
            <div>
              <strong>
              ₹ {data?.row.original.base_amount}
              </strong>
          
           </div>
          );
        },
      },
      {
        Header: "GST Amount",
        accessor: "GST_amount",
        width: "8%",
        textalign: "center",
        Cell: (data) => {
          return (
          //   <Link
          <div>
            <strong>
              ₹ {data?.row.original.GST_amount}

            </strong>
          </div>
          //   </Link>
          );
        },
      },
     
     
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
        width: "8%",
        textalign: "center",
        Cell: (data) => {
          return (
            <div>
              <strong>
            ₹ {data?.row.original.paid_amount}
                
              </strong>
           </div>
          );
        },
      },
      {
        Header: "Location",
        accessor: "location",
        width: "13%",
        textalign: "center",
        Cell: (data) => {
          return (
            <div>
              <strong>
            {data?.row.original.location}
              </strong>
           </div>
          );
        },
      },
     
     
    
    ];







    const rows=subscription
    ?.filter((post) => {  
      return (
          post?.UserName?.toLowerCase().includes(search.toLowerCase()) ||
          post?.Address?.toLowerCase().includes(search.toLowerCase()) ||
          post?.Email?.toLowerCase().includes(search.toLowerCase())||
          post?.PhoneNumber.toLowerCase().includes(search.toLowerCase())||
          post?.BaseAmount.toLowerCase().includes(search.toLowerCase())||
          post?.GSTAmount.toLowerCase().includes(search.toLowerCase())||
          post?.InvoiceDate.toLowerCase().includes(search.toLowerCase())||
          post?.InvoiceNumber.toLowerCase().includes(search.toLowerCase())



      )
  })
      ?.map((post) => {
        return {
          id: post?.id,
          user_name:post?.UserName,
          location: post?.Address||"",
          phone_number: post?.PhoneNumber || "N/A",
          email: post?.Email,
          invoice_date: moment(post?.InvoiceDate).format("DD/MM/YYYY"),
          invoice_number: post?.InvoiceNumber,
          valid_upto:moment(post?.valid_upto).format("DD/MM/YYYY"),
          paid_amount:post?.PaidAmount ||0,
          base_amount:post?.BaseAmount ||0,
          GST_amount:post?.GSTAmount||0,
         
        
         
        };
      })



      // console.log("rowssss",rows)



    if(loading) return <Loader />

  return (
  

    <div className='submissions_wrapper'>
    <div className="submissions">

    <div className="datatable-container">
    <div className="table_head flex">
      <div className="mobo_upload p-1">
        <h3 className="">
          Subscription Invoice  <br />{" "}
          <span className="light">{subscription?.length || 0} records</span>
        </h3>
      </div>

      <div className="add_icon non">
        <button
          className="upload_btn_icon btn btn-primary"
          onClick={() => downloadCSV(rows)}
        >
          <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
        </button>

        <label className="search_label">
          <input
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </label>
      </div>
    </div>


    <div className="d-none d-sm-block table-responsive">
      {
        (rows && columns) &&
        <Datatable columns={columns} data={rows} />

      }
      </div>
  <div className="d-block d-sm-none">
   {/* <MobileViewEvaluationsRecievedTable columns={columns} data={rows}/> */}
  </div>
  </div>

    </div>
   </div>
    )
}

export default SubscriptionInvoice