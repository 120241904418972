import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const CompanyTop = ({
  style,
  step,
  setStep,
  recievedCompany,
  institutions,
  company,
  colleges,
}) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", step.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  }, [step]);

  return (
    <div className="companies-top mb-4 px-2">
      {/* <Link to="/educator/companies/list-of-partner-companies"> */}
      <div
        onClick={() => setStep(1)}
        style={style}
        className={`companies-top-menu ${
          step === 1 ? "active-bottom-class" : ""
        }`}
      >
        <p>Suggested Companies ({company})</p>
      </div>
      <div
        onClick={() => setStep(2)}
        style={style}
        className={`companies-top-menu ${
          step === 2 ? "active-bottom-class" : ""
        }`}
      >
        <p>Partner Companies ({institutions}) </p>
      </div>
      {/* </Link> */}
      {/* <Link to="/educator/companies/suggest-companies"> */}

      {/* </Link> */}
      {/* <Link to="/educator/companies/invitation-send"> */}
      <div
        onClick={() => setStep(3)}
        style={style}
        className={`companies-top-menu ${
          step === 3 ? "active-bottom-class" : ""
        }`}
      >
        <p>Invitations sent ({colleges})</p>
        <span></span>
      </div>
      {/* </Link> */}
      {/* <Link to="/educator/companies/invitation-recieved"> */}
      <div
        onClick={() => setStep(4)}
        style={style}
        className={`companies-top-menu ${
          step === 4 ? "active-bottom-class" : ""
        }`}
      >
        <p>Invitation Recieved ({recievedCompany})</p>
        <span></span>
      </div>
      {/* </Link> */}

      {/* <Link to="/educator/companies/list-of-partner-companies">
        <div style={style} className="companies-top-menu">
          <p>List of partner Companies</p>
          <span>(22)</span>
        </div>
      </Link> */}
    </div>
  );
};

export default CompanyTop;
