import Datatable from "components/datatable-new/DatatableNew"
import { downloadCSV } from "components/dataTable/Export"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AddModal from "./modals/AddModal"
import EditModal from "./modals/EditModal"
import "./style.scss"
import axios from "axios"
import { toast } from "react-toastify"

import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup"

const MDCourses = ({ data }) => {
  const [search, setSearch] = useState(() => "")
  const [showModal, setShowModal] = useState(false)
  const [newCourse, setNewCourse] = useState("")
  const [editCourse, setEditCourse] = useState("")
  const [editId, setEditId] = useState(-1)
  const [showEditModal, setShowEditModal] = useState(false)
  const [stream, setStream] = useState([])
  const [selectedStreamId, setSelectedStreamId] = useState("")

  const getStreamData = async () => {
    try {
      const response = await axios.get("/v1/admin/streams")
      const data = response?.data?.data || []
      // console.log('datadatadata', data);
      setStream(data)
    } catch (err) {
      console.error("Error fetching stream data:", err)
    } finally {
      console.log("fetched")
    }
  }

  useEffect(() => {
    getStreamData()
  }, [])

  // console.log("stream", stream)

  const handleStreamChange = (e) => {
    setSelectedStreamId(e.target.value);

    // console.log("iddd", e.target.value)
  }

  const handleAddModal=()=>{
    setSelectedStreamId("")
    setShowModal(true)

  }

  // console.log("editid",editId)


  const columns = [
    {
      Header: "Course Name",
      accessor: "name",
      width: "65%",
    },
    {
      Header: "Actions",
      width: "35%",
      Cell: (data) => {
        return (
          <div className="row mx-0">
            <div className="col-6">
              <button
                className={`btn edit-btn`}
                onClick={() => {
                  setShowEditModal(true)
                  setEditCourse(data?.row.original.name)
                  setEditId(data?.row.original.id)
                }}
              >
                Edit
              </button>
            </div>
            <div className="col-6">
              <button
                className={`btn delete-btn`}
                onClick={() => handleDelete(data?.row.original.id)}
              >
                Delete
              </button>
            </div>
          </div>
        )
      },
    },
  ]
  const rows = data
    ?.filter((post) => {
      return post?.course_name?.toLowerCase()?.includes(search?.toLowerCase())
    })
    ?.map((post) => {
      return {
        id: post?.id,
        name: post?.course_name,
      }
    })

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newCourse === "") {
      toast.error("Enter a value")
      return
    }

    if (!selectedStreamId) {
      toast.error('Please select a stream');
      return;
    }


    const { data } = await axios.post(`/v1/admin/courses`, {
      name: newCourse,
      streamid:selectedStreamId
    })
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Course Added")
      setNewCourse("")
      setSelectedStreamId("")
      setShowModal(false)
    }
  }



  const handleDelete = async (id) => {
    const { data } = await axios.delete(`/v1/admin/courses/${id}`)
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Course Deleted")
      setNewCourse("")
      setShowModal(false)
    }
  }
  const handleUpdate = async (e) => {
    e.preventDefault()
    const { data } = await axios.put(`/v1/admin/courses/${editId}`, {
      name: editCourse,
    })
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Course Updated")
      setEditCourse("")
      setShowEditModal(false)
    }
  }
  return (
    <div>
      <div className="datatable-container mt-4 tablepadding">
        <div className="table_head flex mb-3">
          <div className="mobo_upload">
            <h3 className="mb-0">
              Courses
              <br /> <span className="light">{data?.length || 0} records</span>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <button
              type="button"
              className="upload_btn_icon btn btn-primary text-white px-3"
              data-toggle="modal"
              data-target="#addModal"
              onClick={() => handleAddModal()}
            >
              Add <i className="fa-solid fa-plus"></i>
            </button>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

        <AddModal show={showModal} setShow={setShowModal} type="course">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              <select
                value={selectedStreamId}
                onChange={handleStreamChange}
                className="form-control"
              >
                <option value="">Select a stream</option>
                {stream.map((stream) => (
                  <option key={stream.id} value={stream.id}>
                    {stream.stream_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="course" className="mb-2">
                Course
              </label>
              <input
                type="text"
                className="form-control"
                id="course"
                placeholder="Enter course"
                value={newCourse}
                onChange={(e) => setNewCourse(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setNewCourse("")
                  setShowModal(false)
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </AddModal>
        <EditModal
          show={showEditModal}
          setShow={setShowEditModal}
          type="course"
        >
          <form onSubmit={handleUpdate}>
            <div className="form-group">
              <label htmlFor="course" className="mb-2">
                Course
              </label>
              <input
                type="text"
                className="form-control"
                id="course"
                placeholder="Enter course"
                value={editCourse}
                onChange={(e) => setEditCourse(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setEditCourse("")
                  setShowEditModal(false)
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </EditModal>
        <Datatable columns={columns} data={rows} />
        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  )
}

export default MDCourses
