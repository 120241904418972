(async () => {
  // create and show the notification
  const showNotification = () => {
    // create a new notification
    const notification = new Notification('Welcome to Qollabb', {
      body: 'Welcome back, \n Nishant!',
      icon: process.env.PUBLIC_URL + '/img/cil_education.png',
    });

    // close the notification after 10 seconds
    setTimeout(() => {
      notification.close();
    }, 10 * 1000);

    // navigate to a URL when clicked
    notification.addEventListener('click', () => {
      window.open('https://buddyshop.in', '_blank');
    });
  };

  // show an error message
  const showError = () => {
    const error = document.createDocumentFragment();
    error.innerHTML = `
      <div class="error">
        <p>Please allow notifications</p>
      </div>
    `;

    document.body.appendChild(error);
  };

  // check notification permission
  let granted = false;

  if (Notification.permission === 'granted') {
    granted = true;
  } else if (Notification.permission !== 'denied') {
    let permission = await Notification.requestPermission();
    granted = permission === 'granted' ? true : false;
  }

  // show notification or error
  // only send notification if the user is not active
  if (!granted || document.visibilityState === 'visible') return;
  granted ? showNotification() : showError();
})();