import axios from "axios"
import { useEffect } from "react"

const capitalizeFirstLetter = (string) => {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const useFetchData = (setLoading, state, currentPage, ITEMS_PER_PAGE, setData, setTotalItems, setTotalPages, setCounts, updateURL, file, getUrlTab, allFilters)=> {


  useEffect(() => {
   

    const map = {
      0: "project",
      1: "internship",
      2: "job",
    }


    const fetchData = async () => {

      // console.log("stateeeeseleee", state);

      try {
        setLoading(true)
        const filters = Object?.keys(state).reduce((acc, curr) => {
          if (curr === "selected") return acc
          else if (state[curr] instanceof Set) {
            if (!state[curr].size) return acc
            const set = [...new Set(state[curr])]
            return { ...acc, [curr]: set }
          } else if (state[curr]) {
            if (curr === 'address' && Array.isArray(state[curr])) {
              const capitalizedAddresses = state[curr].map((address) => capitalizeFirstLetter(address));
              return { ...acc, [curr]: capitalizedAddresses };
            }
            return { ...acc, [curr]: state[curr] }
          }
          return acc
        }, {})

        // console.log("filterssss", filters);

        const { data } = await axios.post(`/v1/filter/${map[state.selected || 0]}`, {
          ...filters,
          page: currentPage,
          limit: ITEMS_PER_PAGE,
        })
        // // console.log(data, "page123");
        setData(data?.data?.items || [])

        if (!data?.error) {
          // // console.log("pages", data?.data);
          setTotalItems(data?.data?.pages?.items || 0)
          if (data?.data?.pages?.current) {
            // setCurrentPage(data?.data?.pages?.current || 1);
            setTotalPages(data?.data?.pages?.total)
          }

          setCounts({
            pCount: data?.data?.pages?.pCount,
            iCount: data?.data?.pages?.iCount,
            jCount: data?.data?.pages?.jCount,
          })
        }
        setLoading(false)
        // localStorage.removeItem("company")
      } catch (err) {
        setLoading(false)
      }
    }

    if(allFilters?.company && allFilters?.locations && allFilters?.industries && allFilters?.courses){

      fetchData()
      updateURL()
    }
    
    
   
  }, [state, currentPage, file, getUrlTab])





}

export default useFetchData;