import html2pdf from "html2pdf.js";
import moment from "moment";
import logo from "../images/logos/logo-main.png"


function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}


export const generateInvoicePDF = async ({name, email, id, amount, desc}) => {

  // calculate gst saperate
  const baseAmount = amount / 1.18; // Calculate base amount without GST
  const gstAmount = amount - baseAmount; // Calculate GST amount

  const content = `
  <div class="invoice-wrapper">
  <style>

    .invoice {
      width: 100%;
      margin: 20px auto;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .company-info{
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 97%;
      padding: 12px 12px;
      border: 1px solid black;
    }
    .left-info{
      display: flex;
      flex-direction: column;
    }

    .left-info img{
      width: 40%;
      float: left;
      margin-bottom: 10px;
    }

    .comp-name{
      line-height: 18px;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 10px;
    }
    .comp-name span{
      font-weight: 600;
      font-size: 11px;
    }

    .comp-address{
      line-height: 20px;
      font-family: sans-serif;
    }

    .acc-info{
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .pan{
      display: flex;
      gap: 6px;
      font-weight: 400;
      font-size: 14px;
    }

    .pan span{
      width: 30%;
      text-align: center;
      font-weight: 400;
    }




    .right-info{
      display: flex;
      flex-direction: column;
      min-width: 40%;
      margin-right: 4px;

    }

    .invoice-no{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 13px;
  }

  .invoice-no span{
      font-size: 20px;
  }

  .contact-details{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
  }

  .num{
      font-weight: 600;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      
  }

  .num span{
      margin-left: 5px;
      font-weight: 400;
  }

    

    .customer-info{
      display: flex;
      flex-direction: column;
      width: 97%;
      padding: 12px 12px;
      border: 1px solid black;
      gap: 20px;
    }
    .main-info{
      display: flex;
      justify-content: space-between;
    }

    .main-left{
      font-weight: bold;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .name{
      display: flex; 
      gap: 25px;
    }
    .name span{
      font-size: 15px;
      font-weight: 400;
    }

    .main-right{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 40%;
    }

    .id{
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .id span {
      width: 40%;
      font-weight: 400;
      font-size: 15px;
      text-align: start;
      margin-right: 35px;
    }

    .product-info{
      display: flex;
      width: 97%;
      padding: 12px 12px;
      border: 1px solid black;
      /* align-items: center; */
      justify-content: space-between;
    }



    .amount{
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 40%;
    }

    .amount h2{
      text-align: end;
      margin-right: 35px;
    }


    .amount-details{
      display: flex;
      flex-direction: column;
      gap: 7px;
    }

    .subtotal{
      font-weight: 600;
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      font-size: 15px;

    }
    
    .subtotal span{
      margin-right: 35px;
    }

    .total{
      border-top: 1px solid black;
    }

    .footer{
      width: 97%;
      margin-top: -10px;
    }

  </style>
  <div class="invoice">
      <div class="company-info">
          <div class="left-info">
              <div class="logo">
                  <img src="${logo}" alt="logo">
              </div>
              <div class="comp-name">
                  Qollabb edutech pvt. ltd. <br>
                 <span>Connecting Companies with brilliant minds in campuses</span> 
              </div>
              <div class="comp-address">
                  231/4 SF - II, Behind Silk Board Building, <br>
                  BTM second Stage, Bangalore, <br>
                  Karnataka - 560068, India
              </div>
              <div class="acc-info">
                  <div class="pan">
                      Pan: <span>AAACQ6873E</span>
                  </div>
                  <div class="pan">
                      GSTIN: <span>29AAACQ6873E1Z5</span>
                  </div>
              </div>
          </div>
          
          <div class="right-info">
              <h1>INVOICE</h1>
              <div class="invoice-details">
                  <div class="invoice-no">
                      <h2>Invoice No.</h2> <span> 0000111</span>
                  </div>
                  <div class="contact-details">
                      <div class="num">Contact Number: <span>+91 7844588548</span></div>
                      <div class="num">Email: <span>info@qollabb.com</span></div>
                      <div class="num">Website: <span>www.qollabb.com</span></div>
                  </div>
              </div>
          </div>
      </div>

      <div class="customer-info">
          <h2>Customer Details:</h2>
          <div class="main-info">
              <div class="main-left">
                  <div class="name">Name: <span>${name}</span></div>
                  <div class="name">Email: <span>${email}</span></div>
              </div>
              <div class="main-right">
                  <div class="id">Customer Id: <span>${id}</span></div>
                  <div class="id">Date: <span>13/12/2023</span></div>
              </div>
          </div>
      </div>

      <div class="product-info">
          <div class="serial-no">
              <h2>S.No</h2>
              <p>1</p>
          </div>
          <div class="desc">
              <h2>Description</h2>
              <p>${desc}</p>
          </div>
          <div class="amount">
              <h2>Amount</h2>

              <div class="amount-details">
                  <div class="subtotal">Sub Total: <span>₹ ${baseAmount}</span></div>
                  <div class="subtotal">Discount: <span>₹ 0</span></div>
                  <div class="subtotal">Total: <span>₹ ${baseAmount}</span></div>
                  <div class="subtotal">GST@18%: <span>₹ ${gstAmount}</span></div>
                  <div class="subtotal total">Total payable: <span>₹ ${amount}</span></div>
              </div>
          </div>
      </div>

      <div class="footer">
          <p>Note*: This is a computer-generated invoice & does not require a signature/stamp. Please do not reply to this email. It has been sent from an email-account that is not monitored.</p>
      </div>
  </div>
</div>
  `;

  const opt = {
    margin: 8,
    filename: "Invoice.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };
  html2pdf().set(opt).from(content).save();
};
