const DateField = ({
  label,
  id,
  placeholder,
  required = false,
  register = () => {},
  options = {},
  disabled = false,
  dateValue = "",
  minDate = new Date().toISOString().split("T")[0],
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toISOString()
    .split("T")[0],
  handleChange = () => {},
  className = "",
  className1,
}) => {
  return (
    <div className="form-group mb-3">
      <label htmlFor={id} className="text_label">
        {label}
        {required && <span className={`${className1}`}>*</span>}
      </label>
      <input
        className={`form-control mt-2 ${className}`}
        type="date"
        name={id}
        id={id}
        placeholder={placeholder}
        {...register(id, { required, ...options })}
        required={required}
        disabled={disabled}
        min={minDate}
        max={maxDate}
        onChange={handleChange}
        // value={dateValue}
        defaultValue={dateValue}
      />
    </div>
  );
};

export default DateField;
