import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css/bundle';


import p4 from '../../images/brand/partner-04.png'
import p11 from '../../images/brand/partner-11.png'
import p12 from '../../images/brand/partner-12.png'

import p17 from '../../images/brand/company-Rio.png'

import p19 from '../../images/brand/company-sol.jpg'
import p20 from '../../images/brand/company femtosoft.png'

import p22 from '../../images/brand/company-Aaptha.png'
import p23 from '../../images/brand/company-black-paper.png'
import p24 from '../../images/brand/company-kds.png'

import p26 from '../../images/brand/company-tech-mayhem.png'
import p27 from '../../images/brand/partner27.png'
import p28 from '../../images/brand/company-bizjunket.png'
import p29 from '../../images/brand/company-bmdu.png'
import p30 from '../../images/brand/company-RIK.png'
import p31 from '../../images/brand/company-technovins.png'
import p32 from '../../images/brand/company-indifuture.png'
import p33 from '../../images/brand/company-monitize.png'
import p34 from '../../images/brand/company-snm.png'
import p35 from '../../images/brand/company-bric-x-infra.png'
import p36 from '../../images/brand/company-saadaa.png'

import p38 from '../../images/brand/company-avantika-innovations.png'
import p39 from '../../images/brand/compnay-ima-appweb.png'
import p40 from '../../images/brand/company-bsoftIndia.png'
import p41 from '../../images/brand/company-web3task.png'
import p42 from '../../images/brand/company-stackchain.png'
import p43 from '../../images/brand/company-upcloud-global-solutions.png'
import p44 from '../../images/brand/company-spectical-asset.png'

import p46 from '../../images/brand/company-quirinus-soft.png'
import p47 from '../../images/brand/company-sprockets.png'

import p49 from '../../images/brand/company-zymo.png'

import p51 from '../../images/brand/company-mlworkx.png'
import p52 from '../../images/brand/company-my3dmeta.png'
import p53 from '../../images/brand/company-admedia.png'
import p54 from '../../images/brand/company-tridint.png'

import p56 from '../../images/brand/company-nextfly.png'




import partner from '../../images/partner.png'
import './partner.scss'

const IndustryPartnerSection = () => {
    const partnerImages = [
      
        p4,
       p20,p22,p31,p32,p33,p35,p38,p40,p42,p43,p44,p46,p47,p49,p51,p52,p53,p54,p56,
        p11,
        p12,
        p17,p19,p36,p39,p23,p34,p24,p26,p28,p29,p27,p30,p41,
       
        
    ];

    return (
        <div className="patner-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="partner-box mb-30">
                            <div className="partner-thumb d-none d-sm-block">
                                <img src={partner} alt="partner-png" />
                            </div>
                            <div className="section-title mb-30 text-center">
                                <h2>Our <span className="down-mark-line-2">Industry</span> Partners
                                </h2>
                            </div>
                            <div className="Partner-content text-center">
                                <p>Partnerships for best talent discovery from campuses</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={2}
                            slidesPerView={8} // Adjust the number of slides per view as needed
                            loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 3, 
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 4,
                                },
                                992: {
                                    slidesPerView: 5,
                                },
                                1200: {
                                    slidesPerView: 6,
                                },
                                1400: {
                                    slidesPerView: 7,
                                },
                                1600: {
                                    slidesPerView: 8,
                                },
                            }}
                            pagination={{
                                clickable: true,
                                el: '.partner-pagination',
                            }}
                           

                       
                            autoplay={{
                                delay: 1000,
                                disableOnInteraction: true
                            }}
                        >
                            {partnerImages.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className='partner-card'>
                                    <img width={110}  src={image} alt={`not-found`} className='partner-image' />
                                    </div>
                                   
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        
                        {/* <div className="partner-pagination text-center pt-2 paginationhide"></div> */}
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndustryPartnerSection;