import React from 'react'
import "./chip.scss"

const NewChip = ({text}) => {
  return (
    <div className='new-chip'>
        {text}
    </div>
  )
}

export default NewChip