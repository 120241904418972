import React from "react"
import OpportunitiesCard from "./OpportunitiesCard"
import EducatorGuideCard from "./EducatorGuideCard"
import "./educatorHomeCard.scss"

const EducatorHomeCard = () => {
  return (
    <div className="educator_home_card w-100">
      <OpportunitiesCard />
      <EducatorGuideCard />
    </div>
  )
}

export default EducatorHomeCard
