import { Navigate, Outlet } from "react-router-dom";
// import { getUserRole } from "./getUserRole"
import { useSelector } from "react-redux";
import { getUserRole } from "./getUserRole";

const ProtectedRoute = (props) => {
  // console.log("helo");
  const { roleNeeded = false, navigate = false } = props;
  // console.log(navigate, "navi");
  const { user = {}, isSemiAuthenticated = false, isAuthenticated = false } =
    useSelector((state) => state.auth) || {};
  // console.log(user, "123user");

  if (
    (roleNeeded ? roleNeeded === user?.role : true) &&
    isAuthenticated &&
    isSemiAuthenticated &&
    !navigate
  ) {
    return <Outlet />;
  } else if (navigate) return <Navigate to={getUserRole(user?.role)} />;
  else if (!isSemiAuthenticated) return <Navigate to="/signin" />;
  else if (isSemiAuthenticated && !isAuthenticated) {
    if (!user?.isOTPVerified) return <Navigate to="/verify-otp" />;
    if (!user?.branch_id && (user?.role === 2 || user?.role === 3)) {
      // console.log("branch");
      return (
        <Navigate to={getUserRole(user?.role)} />
        // <Navigate
        //   to={user?.role === 2 ? "/company" : "/institution"}
        //   replace={true}
        // />
      );
    }
  } else return <Navigate to="/verify-otp" />;
};

export default ProtectedRoute;
