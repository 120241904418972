import React, { useRef, useState } from 'react'
import { IoIosCreate } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import "./manualUpload.scss";
import { FaUpload } from "react-icons/fa6";
import { toast } from 'react-toastify';
import axios from 'axios';

const ManualUploadPopup = ({onClose, loading = false, setRefresh, setLoading, existsName = null}) => {


    const [selectedFile, setSelectedFile] = useState(null);
    const inputRef = useRef(null); 
    const [listName, setListName] = useState( existsName || "My list");


    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    }



    const [addedStudentsCount, setAddedStudentsCount] = useState(1);
    
    const [students, setStudents] = useState([
      {
        studentname: '',
        course: '',
        specialization: '',
        semester: '',
        email: '',
      },
    ]);
  
    const handleAddStudent = () => {
      setStudents((prevStudents) => [
        ...prevStudents,
        {
          studentname: '',
          course: '',
          specialization: '',
          semester: '',
          email: '',
        },
      ]);
      setAddedStudentsCount((count) => count + 1);
    };
    const handleInputChange = (index, field, event) => {
      const newStudents = [...students];
      newStudents[index][field] = event.target.value;
      setStudents(newStudents);
    };
  
  
    const handleDeleteStudent = (index) => {
      setStudents((prevStudents) =>
        prevStudents.filter((_, i) => i !== index)
      );
      setAddedStudentsCount((count) => count > 0 ? count - 1 : 0);
    };
  
    const handleSubmit = async () => {
      try {
        setLoading(true);
        // Check if any field is missing in any student entry
        const isAnyFieldMissing = students.some(
          (student) => !student.studentname || !student.email
        );
    
        if (isAnyFieldMissing) {
          toast.error('Please fill all fields');
          return;

        }

        // Check email format for each student
        const isAnyEmailInvalid = students.some(
          (student) =>
            !student.email.match(
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            )
        );
    
        if (isAnyEmailInvalid) {
          toast.error('Invalid email format. Please check the email format.');
          return;
        }
    
        // Check for duplicate emails
        const isAnyDuplicateEmail = students.some(
          (student, index) =>
            students.findIndex(
              (s) => s.email.toLowerCase() === student.email.toLowerCase()
            ) !== index
        );
    
        if (isAnyDuplicateEmail) {
          toast.error('Duplicate emails are not allowed.');
          return;
        }

    
        const studentsData = students.map((student) => [
          student.studentname,
          student.course,
          student.specialization,
          student.semester,
          student.email,
        ]);

        // setLoading(true)
        // Make an Axios POST request to your API
        const response = await axios.post('/v1/educator/invite/upload_students', {
          invitee_ids: studentsData,
           list_name: listName
        });
    
        // console.log('Response from the server:', response.data);
    
        onClose();

        setLoading(false);
        setRefresh((prev)=> !prev);
        // Display success message
        toast.success(`${addedStudentsCount} Students Successfully Uploaded`)
        // toast.success("Students added successfully!");
      
      
        // setRefresh((prevRefresh) => !prevRefresh)
    //    setLoading((prevRefresh) => !prevRefresh)
    
        

    } catch (error) {
        setLoading(false);
        setRefresh((prev)=> !prev);
        
        console.error('Error sending data:', error);
        toast.error('Error submitting data');
      }
    };
    
  
    const semesterOptions = Array.from({ length: 8 }, (_, index) => index + 1);

    



  return (
    <div className="upload-list-popup-overlay">
    <div className="upload-list-popup" >
        <div className="create_list_top d-flex justify-content-between align-items-center">
            <div className='d-flex justify-content-between align-items-center gap-2'>
                <FaUpload size={22} /> <span className='list_heading'>Upload Students</span>
            </div>

            <div className="" onClick={onClose}>
                <RxCross2 size={30} style={{cursor: "pointer"}} />
            </div>
        </div>


        <div className="popup_body">
        <div className="popup-header">
            <div className='list_name'>
                <label htmlFor="">List Name</label>
                    <input
                        placeholder="Enter list name"
                        className="popup-input"
                        type="text"
                        value={listName}
                        onChange={(e)=> setListName(e.target.value)}
                    />
            </div>

            <div className="uploaded_count">
                student Added {addedStudentsCount}            
            </div>

        </div>

        <div className="student_model_content">
               
                <div className="student-model-detail">
                {students?.map((student, index) => (
                    <div className="row modal-border" key={index}>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <label className="label-student">Student Name</label>
                        <input
                        type="text"
                        required
                        placeholder="Student Name"
                        className="student-form-control"
                        value={student.studentname}
                        onChange={(e) => handleInputChange(index, 'studentname', e)}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <label className="label-student">Email</label>
                        <input
                        type="email"
                        required
                        placeholder="Email Id"
                        className="student-form-control"
                        value={student.email}
                        onChange={(e) => handleInputChange(index, 'email', e)}
                        />
                    </div>
    
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <label className="label-student">Course</label>
                        <input
                        type="text"
                        required
                        
                        placeholder="Course"
                        className="student-form-control"
                        value={student.course}
                        onChange={(e) => handleInputChange(index, 'course', e)}
                        />
                    </div>
    
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <label className="label-student">Specialization</label>
                        <input
                        type="text"
                        required
                        
                        placeholder="Specialization"
                        className="student-form-control"
                        value={student.specialization}
                        onChange={(e) => handleInputChange(index, 'specialization', e)}
                        />
                    </div>
    
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1">
                    <label className="label-student">Semester</label>
                        <select
                        className="student-form-control"
                        value={student.semester}
                        
                        onChange={(e) => handleInputChange(index, 'semester', e)}
                        >
                        <option value="" className="student-option">
                            Semester
                        </option>
                        {semesterOptions.map((option) => (
                            <option key={option} value={option} className="student-option">
                            {option}
                            </option>
                        ))}
                        </select>
                    </div>
    
                    
    
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 actionbtnmodal">

                    {students.length > 1 && (
                        <>
                          <i
                              className="fa fa-trash"
                              aria-hidden="true"
                              onClick={() => handleDeleteStudent(index)}
                              style={{ color: 'red', cursor: 'pointer'}}
                          ></i>
                        </>
                    )}

                    {index === students.length - 1 && (
                        <i
                        className="fa fa-plus"
                        aria-hidden="true"
                        onClick={handleAddStudent}
                        style={{ color: '#120752', cursor: 'pointer' }}
                        ></i>
                    )}
        
                    </div>

                    
                    </div>
                ))}
                </div>
        </div>    
                
        <div className="model_upload_btn">
            <button  onClick={handleSubmit}>
              {
                loading ? <i className="text-white fa fa-circle-o-notch fa-spin" ></i> : "Upload List"
              }
                
            </button>
        </div>
        </div>
    </div>
    </div>  
    )
}

export default ManualUploadPopup