import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Datatable from "components/datatable-new/DatatableNew";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../index.scss";
import AdminDashCard from "../table-card";
import MobileViewMentorTable from "./MobileViewMentorTable";
const AdminDashMentors = ({ data, setActivate, counts, date, setdates }) => {
  const [search, setSearch] = useState(() => "");

  // const sortedData = data.sort(
  //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  // );

  // console.log("mentordata",data)
  // console.log("Evalutions",counts)

  

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [temporaryDates, setTemporaryDates] = useState([date[0]]);

  const handleToggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // const handleDateChange = (ranges) => {
  //   setdates([ranges.selection])

   
  //   setCalendarVisible(false);

  // };

  const handleDateChange = (ranges) => {
    setTemporaryDates([ranges.selection]);
  };

  const handleApply = () => {

    setdates([...temporaryDates]);
    setCalendarVisible(false);
  };



  const datePickerRef= useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        // Clicked outside the date picker, close the picker
        setCalendarVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

 

  const cardsData = [
    {
      primaryText: 'Mentors',
      secondaryText: `+${data.length}`,
      value: `${data.length}`,
      date: `${data.map((item) => item?.createdAt)}`,
     
    },
    
  ];
   // Separate the date values
   const dateStrings = cardsData[0].date.split(',');
   // console.log("datestring",dateStrings)
   // Create an object to store the total companies added for each day
  const dayCounts = {};
  
 
  dateStrings.forEach((dateStr) => {
    const date = new Date(dateStr);
   
    const fullDate = date.toLocaleDateString(undefined,{ month: 'short', day: 'numeric',year:'numeric' }); // Extract the day part
    // console.log("fuldate",fullDate)
    const day = fullDate// Extract the day part

    // console.log("dayyyy",day)

    if (dayCounts[day]) {
      dayCounts[day]++;
    } else {
      dayCounts[day] = 1;
    }
  });

   // Create an array with objects for the chart data
   const formattedData = Object.keys(dayCounts).map((day) => {
   
    const total = dayCounts[day];
    const isValidDate = !isNaN(Date.parse(day));
  
    return {
      day: isValidDate ? day : 'Invalid Date',
      total: isValidDate ? total : 0,
    };

  });
 // Sort the formattedData array by date in ascending order
//  formattedData.sort((a, b) => parseInt(a.day, 10) - parseInt(b.day, 10));
 formattedData.sort((a, b) => new Date(a.day) - new Date(b.day));
  // console.log("formattedData", formattedData);

  const mentorDataset = {
    label: 'mentors',
    data: formattedData.map((item)=>{return item.total}),
    borderColor: '#6149CD',
        backgroundColor: '#6149CD',
  };


  // console.log("datedefault",date[0].startDate)
const currentdate=date[0].startDate
const defaultdate=new Date(currentdate)


 
  const chartData = {
  
    // labels: formattedData.map((item)=> {
    //  return item.day
    // }),
    // datasets: [mentorDataset ]

    labels: formattedData.map((item)=> {
      const date = item && item.day ? new Date(item.day) : new Date(defaultdate);
  
  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    // If invalid, replace with today's date
    return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }
  
  return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    }),
    datasets: [mentorDataset ]


  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display:false // Change 'linear' to 'bottom' or 'top'
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date', // X-axis title
        },
        // beginAtZero: true, // Start the x-axis from 0
      },
      y: {
        title: {
          display: true,
          text: 'Mentors', // Y-axis title
       
        },
        beginAtZero: true, // Start the y-axis from 
        ticks: {
          stepSize: 2,
        },
      },
      
    },
  };

  // averge evalution graph
  let totalEvaluationCount = 0;
 

  data.forEach((item) => {
    if (item.EvaluationCount) {
      totalEvaluationCount += parseInt(item.EvaluationCount);
    }
  });
  
  // console.log("total evaluation", totalEvaluationCount);
 const totalMentor=counts?.mentors?.totalMentorCounts
 // console.log("totalmentor",totalMentor)

  var avgEvalution = totalEvaluationCount / totalMentor
  // var avgEvalution = totalEvaluationCount / data.length;
  // console.log("total",avgEvalution)


  // Extract the evaluation dates

  const evaluationDatesdayCounts = {};

  const evaluationDates = counts?.mentors?.projectevalution?.map((evalItem) => {
    // Extract the formatted date
    const date = new Date(evalItem.mentor_evaluated_date).toLocaleDateString(undefined, { month: 'short', day: 'numeric',year:'numeric' });
    
    // Increment the count for the extracted date
    if (evaluationDatesdayCounts[date]) {
      evaluationDatesdayCounts[date]++;
    } else {
      evaluationDatesdayCounts[date] = 1;
    }
  
    // Return the formatted date
    return date;
  });
  
  // console.log("Evaluation Dates", evaluationDates);
  // console.log("Evaluation Dates Day Counts", evaluationDatesdayCounts);

  const evalutionFormattedData = Object.keys(evaluationDatesdayCounts).map((day) => {
   
    const total = evaluationDatesdayCounts[day];
    const isValidDate = !isNaN(Date.parse(day));
  
    return {
      day: isValidDate ? day : 'Invalid Date',
      total: isValidDate ? total : 0,
    };

  });

  evalutionFormattedData.sort((a, b) => new Date(a.day) - new Date(b.day));
  
  // console.log("evalutionFormattedData",evalutionFormattedData)


  



  const evalutionChartData = {
  
    // labels: evalutionFormattedData.map((item)=> {
    //  return item.day
    // }),
    labels: evalutionFormattedData.map((item)=> {
      const date = item && item.day ? new Date(item.day) : new Date(defaultdate);
  
  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    // If invalid, replace with today's date
    return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }
  
  return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    }),

    datasets:[
      {
      label: 'Poject Evaluations',
      data: evalutionFormattedData.map((item)=>{return item.total}),
      borderColor: '#6149CD',
          backgroundColor: '#6149CD',
    }
    ]
  };

  const evaluationOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display:false // Change 'linear' to 'bottom' or 'top'
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date', // X-axis title
        },
        // beginAtZero: true, // Start the x-axis from 0
      },
      y: {
        title: {
          display: true,
          text: 'Evaluations', // Y-axis title
       
        },
        beginAtZero: true, // Start the y-axis from 
        ticks: {
          stepSize: 2,
        },
      },
      
    },
  };






  const handleAccountActivation = async (id) => {
    setActivate((prev) => !prev);
    const data = await axios.delete(`/v1/mentor/mentor/${id}`);
    // // console.log(data, "data");
    if (!data.data?.error) {
      toast.info(data?.data?.message);
    } else {
      toast.error(data?.data?.error);
    }
  };
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "10%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            <h6>{data.row.original.name}</h6>
            {/* <p>
              {data.row.original.designation}-
              {data.row.original.current_company}
            </p> */}
          </strong>
        );
      },
    },
    {
      Header: "Industry Sector",
      accessor: "industry_name",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.industry_name}
          </strong>
        );
      },
    },
    {
      Header: "Signup Date",
      accessor: "signup_date",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.signup_date}
          </strong>
        );
      },
    },
    {
      Header: "Last login",
      accessor: "last_login",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.last_login}
          </strong>
        );
      },
    },
    {
      Header: "Mentoring Sessions",
      accessor: "mentoring_sessions",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.mentoring_sessions}
          </strong>
        );
      },
    },
    {
      Header: "Evaluations",
      accessor: "evaluations",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.EvaluationCount}
          </strong>
        );
      },
    },
    {
      Header: "Mentoring Earnings",
      accessor: "mentoring_earnings",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.mentoring_earnings}
          </strong>
        );
      },
    },
    {
      Header: "Evaluation Earnings",
      accessor: "evaluation_earnings",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {"-"}
          </strong>
        );
      },
    },

    {
      Header: "Action",
      accessor: "status",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <button
            onClick={() => handleAccountActivation(data.row.original.id)}
            className={`deactivate-btn ${
              data.row.original.deletedAt === "Activate"
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {data.row.original.deletedAt}
          </button>
        );
      },
    },
  ];

  const rows = data
    ?.filter((post) => {
      if (
        post?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.email_address?.toLowerCase().includes(search.toLowerCase()) ||
        post?.phone_number?.toLowerCase()?.includes(search.toLowerCase()) ||
        post?.designation?.toLowerCase()?.includes(search.toLowerCase()) ||
        post?.institute_branch?.address
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      ) {
        return post;
      } else {
        return null;
      }
    })
    ?.map((post) => {
      // Calculate total session orders for the mentor
    const sessionOrderLength = post?.session_order?.length || 0;
    // Calculate mentor earnings
    const mentorEarnings = sessionOrderLength * post?.mentor_charges;
     // Count  completed session orders with status 3
     const sessionCountStatus3 = post?.session_order?.filter(session => session.status === 3).length || 0;
    
      return {
        id: post?.id,
        name: post?.first_name + " " + post?.last_name,
        signup_date: moment(post?.createdAt).format("DD/MM/YYYY"),
        last_login: moment(post?.lastLogin).format("DD/MM/YYYY"),
        projects: post?.projects || 0,
        internships: post?.internships || 0,
        jobs: post?.jobs || 0,
        subscription: post?.subscription || "Beginner",
        subscription_validity: moment(post?.subscription_validity).format(
          "DD/MM/YYYY"
        ),
        type: post?.type || "B2C",
        industry_sector: post?.industry_sector,
        industry_name:post?.industry?.industry_name,
        designation: post?.designation,
        current_company: post?.current_company,
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
        EvaluationCount:post?.EvaluationCount||0,
        mentoring_earnings: mentorEarnings,
        mentoring_sessions: sessionCountStatus3
      };
    });

  // // console.log(data,"hello world");

  return (
    <div className="admin-dash-companies">
      <div className="row flex-nowrap overflow-auto graphcardgap">
        {cardsData.map((card, index) => {
          return (
            <div className="col-12 col-md-6" key={index}>
              <AdminDashCard
                data={chartData}
                option={options}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          );
        })}

           <div className="col-12 col-md-6">
              <AdminDashCard
                data={evalutionChartData}
                option={evaluationOptions }
                primaryText={"Average Evaluations"}
                secondaryText={avgEvalution || 0}
                value={avgEvalution || 0}
              />
            </div>



      </div>

      <div className="datatable-container mt-4">
        <div className="table_head flex">
          <div className="mobo_upload">
            <h3 className="new-class-active">
              <div>
                <h3>Mentors</h3>
                <span className="light new-span-count">
                  {data.length || 0} records{" "}
                  {
                    <div className="new-type">
                      <div className="active">
                        Active - {counts?.mentors?.activeMentors}
                      </div>
                      <div className="inactive">
                        Inactive - {counts?.mentors?.inactive}
                      </div>
                    </div>
                  }
                </span>
              </div>
            </h3>

            <Link to="" className="upload_btn_icon_mobo btn btn-primary">
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>
          <div className="add_icon non"ref={datePickerRef}>
            {/* date range */}
            <div  >
      <span className="light new-span-count" style={{color: '#7a86a1'}}>select date range</span>

      <div className="date-input-container" >

        <input
           className="myinput"
        // style={{ width: 'auto',
        //   padding: '5px 10px',
        //   alignSelf: 'baseline',
        //   border: '1px solid #6149cd',
        //   borderRadius: '8px',
        // cursor:'pointer'}}
       
          type="text"
          // value={`${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`}
          value={
            date[0]?.startDate && date[0]?.endDate
              ? `${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`
              : 'Select Date Range'
          }
          onClick={handleToggleCalendar}
          readOnly
        />
         {calendarVisible && (
        <div className="mydiv">
          {/* <DateRangePicker
            ranges={date}
            showPreview={false}
            onChange={handleDateChange}

          /> */}
           <DateRangePicker ranges={temporaryDates} showPreview={false} onChange={handleDateChange}
           />
           <div className=" applybtn-div">
           <button className="rdrApplyButton" onClick={handleApply}>Apply</button>
           </div>

        </div>
      )}
        
      </div>
     
        <style>
        {`
          /* Place the CSS here */
         
          .rdrStaticRangeLabel {
            color:#000000

          }
          


          /* Add the rest of the CSS styles here */
        `}
      </style>
              </div>
         


            
            {/*  End date Rage */}
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewMentorTable columns={columns} data={rows} handleAccountActivation={handleAccountActivation}/>
      </div>


        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  );
};

export default AdminDashMentors;
