import { lazy } from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import StudentDashboardExplore from "view/dashboard/student/explore/Explore";
import StudentDashboardHome from "view/dashboard/student/home/Home";
// import PreviewContainer from "components/preview/Preview"
// import PostsHome from "components/preview/Home"
// import HomeContainer from "components/preview/HomeContainer"
import Plan from "view/dashboard/student/plans/Plan";
import Mentors from "view/dashboard/student/studentMentor/Mentor";
import Setting from "view/dashboard/student/setting/Setting";
// import Changepassword from "view/dashboard/student/setting/Changepassword"
import Notification from "view/dashboard/student/notification/Notification";
import Application from "view/dashboard/student/application/Application";
import ApplicationProjectDetail from "view/dashboard/student/application/post/ApplicationProjectDetail";
import List from "view/dashboard/mentor/MentorList";
import Calender from "view/dashboard/mentor/MentorCalender";
// import PostContent from "components/employer/PostContent"
import EmployerSignup from "view/auth/signup/EmployerSignup";
import EducatorSignup from "view/auth/signup/EducatorSignup";
import SemiProtectedRoute from "./SemiAuthenticated";
import ProtectedRoute from "./FullAuthenticated";
import PostContent from "components/employer/PostContent";
import EnrollInternship from "view/dashboard/student/explore/enroll/Internship";
import StudentApplication from "view/dashboard/student/application/MyApplication";
import EnrollJob from "view/dashboard/student/explore/enroll/Job";
import EnrollProject from "view/dashboard/student/explore/enroll/Project";
import InternshipDetails from "view/dashboard/employer/internship/Details";
import JobDetails from "view/dashboard/employer/job/Details";
import ProjectDetails from "view/dashboard/employer/project/Details";
import InternshipPost from "view/dashboard/employer/internship/id/Id";
import JobPost from "view/dashboard/employer/job/id/Id";
import ProjectPost from "view/dashboard/employer/project/id/Id";
import StudentProfile from "view/dashboard/student/profile";
import Subscriptions from "components/student/Subscriptions";

import CollegeDashboard from "view/dashboard/educator";
import CollegeEducatorHome from "view/dashboard/educator/Home";
import EducatorExplore from "view/dashboard/educator/explore/EducatorExplore";
import ProjectDetail from "view/dashboard/educator/projectDetail/ProjectDetail";

import ProjectDetailComponent from "view/dashboard/educator/preview";
import UploadStudent from "view/dashboard/educator/pages/UploadStudent";
import EducatorInternshipDetail from "view/dashboard/educator/preview/internship/EducatorInternshipDetail";
import EducatorJobDetail from "view/dashboard/educator/preview/job/EducatorJobDetail";
import StudentList from "view/dashboard/educator/pages/studentList/StudentList";
import Companies from "view/dashboard/educator/companies";
import SuggestCompanies from "view/dashboard/educator/companies/suggestCompanies/SuggestCompanies";
import InvitationSent from "view/dashboard/educator/companies/InvitationsSent/InvitationSent";
import InvitationRecieved from "view/dashboard/educator/companies/invitationRecieved/InvitationRecieved";
import InvitationAccepted from "view/dashboard/educator/companies/invitationAccepted/InvitationAccepted";
import ListOfPartnerCompanies from "view/dashboard/educator/companies/listOfPartnerCompanies/ListOfPartnerCompanies";
import ProjectList from "view/dashboard/employer/project/projectList/ProjectList";
import JobList from "view/dashboard/employer/job/jobList/JobList";
import InternshipDataTable from "components/opportunity/internshipDataTable/InternshipDataTable";
import InternshipList from "view/dashboard/employer/internship/internshipList/InternshipList";
import Invite from "view/dashboard/employer/project/invite/Invite";
import StudentInvite from "view/dashboard/employer/project/studentInvite/Invite";
import InternshipInvite from "view/dashboard/employer/internship/invite/InternshipInvite";
import JobInvite from "view/dashboard/employer/job/invite/Invite";
import EmployerProfile from "view/dashboard/employer/profile/EmployerProfile";
import EmployerSubscription from "view/dashboard/employer/subscriptions/Subscriptions";
import Settings from "view/dashboard/employer/settings/Settings";
import PartnerInstitutions from "view/dashboard/employer/partnerInstitutions/PartnerInstitutions";
import Availability from "view/dashboard/mentor/mentorSetting/availability/Availability";
import BillingAndPayments from "view/dashboard/mentor/mentorSetting/billingAndPayments/BillingAndPayments";
import FeeAndCharges from "view/dashboard/mentor/mentorSetting/feeAndCharges/FeeAndCharges";
import TransectionAndHistory from "view/dashboard/mentor/mentorSetting/transectionAndHistory/TransectionAndHistory";
import StudentDetail from "view/dashboard/mentor/studentDetail/StudentDetail";
import MentoringSessions from "view/dashboard/mentor/MentoringSessions";
import ProjectEvaluation from "view/dashboard/mentor/ProjectEvaluation";
import ProjectEvaluationDetail from "view/dashboard/mentor/projectEvaluationDetail/ProjectEvaluationDetail";
import EducatorSetting from "view/dashboard/educator/setting/Setting";
import AdminLogin from "view/auth/admin/login";
import AdminVerifyOtp from "view/auth/admin/verify-otp";
import AdminForgetPassword from "view/auth/admin/forget-password";
import AdminDashboardHome from "view/dashboard/admin/home";
import AdminDashboard from "view/dashboard/admin";
import AdminEvaluations from "view/dashboard/admin/evaluations";
import AdminMasterData from "view/dashboard/admin/master-data";
import AdminSubscriptions from "view/dashboard/admin/subscriptions";
import AdminComplaints from "view/dashboard/admin/complaints";
import AdminCoupons from "view/dashboard/admin/coupons";
import AdminUsers from "view/dashboard/admin/users";
import AdminSettings from "view/dashboard/admin/settings";
import EducatorSubscriptions from "view/dashboard/educator/subscriptions/Subscriptions";
import ApplicationInternshipDetail from "view/dashboard/student/application/post/ApplicationInternshipDetail";
import AdminMentorsPage from "view/dashboard/admin/evaluations/mentors-page";
import AdminEvaluationDetails from "view/dashboard/admin/evaluations/evaluation-details";
import AdminAddUser from "view/dashboard/admin/users/add-user";
import AdminEditUser from "view/dashboard/admin/users/edit-user";
import Mentor from "view/dashboard/student/studentMentor/Mentor";
import MentorSettings from "view/dashboard/mentor/mentorSetting/Settings";
import EducatorProfile from "view/dashboard/educator/profile/EducatorProfile";
import MentorProfile from "view/dashboard/mentor/components/mentorProfile/MentorProfile";
import SelectCompanyContainer from "view/entity/selectCompany/SelectCompany";
import MentorHome from "view/dashboard/student/mentor";
import MentorProfilePage from "view/dashboard/student/mentor/mentorProfilePage";
import MentorDetail from "view/dashboard/student/mentor/mentorDetail/MentorDetail";
import AllMentors from "view/dashboard/student/mentor/AllMentors/AllMentors";
import StudentSubscriptions from "view/dashboard/student/subscriptions/Subscriptions";
import StudentChat from "view/dashboard/student/chat/StudentChat";
import ApplicationJobDetail from "view/dashboard/student/application/post/ApplicationJobDetail";
import EmployerSettings from "view/dashboard/employer/settings/Settings";
import MySessions from "view/dashboard/student/mentor/mentorList";
import MentoringSession from "view/dashboard/mentor/mentoringSessions/MentoringSession";
import HomePage from "view/newWeb/HomePage";
import CompanyPage from "view/newWeb/CompanyPage";
import CollegePage from "view/newWeb/CollegePage";
import StudentPage from "view/newWeb/StudentPage";
import ExploreHomePage from "view/newWeb/ExploreHomePage";
import TermsAndConditions from "view/newWeb/Policies/TermsAndConditions";
import TermsAndConditionPage from "view/newWeb/TermsAndConditionPage";
import PrivacyPolicyPage from "view/newWeb/PrivacyPolicyPage";
import CookiesPolicyPage from "view/newWeb/CookiesPolicyPage";
import MentorPolicyPage from "view/newWeb/MentorPolicyPage";
import EducatorChat from "view/dashboard/educator/chat/EducatorChat";
import EmployerChat from "view/dashboard/employer/chat/EmployerChat";
import MentorChat from "view/dashboard/mentor/chat/StudentChat";
import StudentPlan from "view/dashboard/student/plans/StudentPlan";
import EditProject from "view/dashboard/employer/project/add/edit";
import EditInternship from "view/dashboard/employer/internship/add/edit";
import EditJob from "view/dashboard/employer/job/add/edit";
import ExploreHomePageNew from "view/newWeb/ExplorePageNew";
import EducatorCompanyPage from "view/dashboard/educator/companies/EducatorCompanyPage";
import StudentDetailsCard from "view/dashboard/employer/components/studentCard/studentDetailsCard/StudentDetailCard";
import StudentDetails from "view/dashboard/educator/pages/studentDetails/StudentDetails";
import AdminAnalytics from "view/dashboard/admin/analytics";
import AllNotifications from "view/dashboard/student/notification/AllNotifications";
import ProjectImageTemplate from "view/dashboard/employer/project/ImageTemplate/ProjectImageTemplate";
import InternshipTemplate from "view/dashboard/employer/internship/ImageTemplate/InternshipTemplate";
import JobTemplate from "view/dashboard/employer/job/ImageTemplate/JobTemplate";
import HelpDesk from "view/dashboard/student/setting/HelpDesk";
import ChangePassword from "view/dashboard/student/setting/changepassword";
import EduStudent_Profile from "view/dashboard/educator/components/StudentProfile/EduStudentProfile";
import EduStudentProfile from "view/dashboard/educator/components/StudentProfile/EduStudentProfile";
import NewAllMentors from "view/dashboard/student/mentor/AllMentors/NewAllMentors";
import MentorManagerMentorsPage from "view/dashboard/Mentor-Manager/mentors-page";
import CreateMentorByManager from "view/dashboard/Mentor-Manager/AddMentor";
import MentorManagerMentorList from "view/dashboard/Mentor-Manager/MentorList/Index";
import MentorProfileByManager from "view/dashboard/Mentor-Manager/MentorList/mentorProfile/MentorProfileBymanager";
import EditPasswordByManager from "view/dashboard/Mentor-Manager/MentorList/EditPassword";
import AdminEditMentorManager from "view/dashboard/admin/users/edit-user/EditMentorManager";
import OurTeam from "view/newWeb/components/ourTeam/ourTeam";
import OurTeamPage from "view/web/OurTeamPage";
import IndustryPartners from "view/web/IndustryPartner";
import AcademicPartners from "view/web/AcademicPartners";
import ProjectSubmission from "view/dashboard/admin/submissions/ProjectSubmission";
import SubscriptionInvoice from "view/dashboard/admin/invoice/Invoice";

const FAQ = lazy(() => import("view/newWeb/components/faq"));
const ExplorePageNew = lazy(() => import("view/newWeb/ExplorePage-New"));
const ExploreNew = lazy(() => import("view/dashboard/student/explore/ExploreNew"));
const ContactUsPage = lazy(() => import("view/web/ContactUsPage"));
const RefundPolicyPage = lazy(() => import("view/newWeb/RefundPolicyPage"));
const ProjectStudentList = lazy(() => import("view/dashboard/admin/mentor-sessions/ProjectStudentList"));
const MentorListPage = lazy(() => import("view/dashboard/admin/mentor-sessions/MentorListPage"));
const MentorProjectDetails = lazy(() => import("view/dashboard/mentor/mentoringSessions/MentorProjectDetails"));
const NewStudentList = lazy(() => import("view/dashboard/educator/pages/studentList/NewStudentList"));
const CreateListPopup = lazy(() => import("view/dashboard/educator/components/StudentListPopups/CreateListPopup"));
const NewEducatorExplore = lazy(() => import("view/dashboard/educator/explore/NewEducatorExplor"));
const ReviewerDashboardHome = lazy(() => import("view/dashboard/Reviewer/home"));
const ReviewerProfile = lazy(() => import("view/dashboard/Reviewer/ReviewerProfile/ReviewerProfile"));
const ReviewerProject = lazy(() => import("view/dashboard/Reviewer/project"));
const ReviewerInternship = lazy(() => import("view/dashboard/Reviewer/internship"));
const ReviewerJob = lazy(() => import("view/dashboard/Reviewer/job"));
const ReviewerProjectDetailsPage = lazy(() => import("view/dashboard/Reviewer/postDetailsPage/project"));
const ReviewerInternshipDetailsPage = lazy(() => import("view/dashboard/Reviewer/postDetailsPage/internship"));
const ReviewerJobDetailsPage = lazy(() => import("view/dashboard/Reviewer/postDetailsPage/job"));
const ReviewerSettings = lazy(() => import("view/dashboard/Reviewer/settings"));
const UserTable = lazy(() => import("view/dashboard/admin/users/UserTable"));
const AdminEditReviewer = lazy(() => import("view/dashboard/admin/users/edit-user/edit-reviewer"));
const MentorManagerDashboardHome = lazy(() => import("view/dashboard/Mentor-Manager/home"));
const MentorManagerSetting = lazy(() => import("view/dashboard/Mentor-Manager/settings"));
const MentorManagerProfile = lazy(() => import("view/dashboard/Mentor-Manager/MentorManagerProfile/MentorManagerProfile"));

const CheckUtmCampaign = lazy(() => import("./CheckUtmCampaign"));
const Campaigns = lazy(() => import("view/dashboard/admin/campaigns/Campaigns"));
const CampaignDetails = lazy(() => import("view/dashboard/admin/campaigns/CampaignDetails"));
// import InternshipDetails from "view/dashboard/student/explore/post/InternshipDetails"
const EmployerHome = lazy(() => import("view/web/Employer"));
const EducatorHome = lazy(() => import("view/web/Educator"));
const StudentHome = lazy(() => import("view/web/Student"));
const MentorSetting = lazy(() => import("view/dashboard/mentor/MentorSetting"));
const MentorDashboard = lazy(() =>
  import("view/dashboard/mentor/MentorDashboard")
);
const Zego = lazy(() => import("view/dashboard/mentor/zego"));
const SessionRequest = lazy(() =>
  import("view/dashboard/mentor/SessionRequest")
);
const AvailableProject = lazy(() =>
  import("view/dashboard/mentor/AvailableProject")
);
const ProjectEnroll = lazy(() => import("view/dashboard/mentor/ProjectEnroll"));
const Mentorjob = lazy(() => import("view/dashboard/mentor/Mentorjob"));
const JobContent = lazy(() => import("view/dashboard/mentor/JobContent"));
const MentorCollage = lazy(() => import("view/dashboard/mentor/MentorCollage"));
const MentorJoblist = lazy(() => import("view/dashboard/mentor/MentorJoblist"));
const Jobdescription = lazy(() =>
  import("view/dashboard/mentor/Jobdescription")
);

// Home
const Home = lazy(() => import("view/web/Home"));

// Auth
const Login = lazy(() => import("view/auth/login/Login"));
const ForgotPass = lazy(() =>
  import("view/auth/forgot_password/ForgotPassword")
);
const CreatePassword = lazy(() =>
  import("view/auth/create_password/CreatePassword")
);
const Signup = lazy(() => import("view/auth/signup/Signup"));
const VerifyOTP = lazy(() => import("view/auth/verify-otp/VerifyOTP"));
const StudentSignup = lazy(() => import("view/auth/signup/StudentSignup"));
const Entity = lazy(() => import("view/entity/Entity"));
const CompanyDashboard = lazy(() => import("view/dashboard/employer"));
const CompanyDashboardHome = lazy(() =>
  import("view/dashboard/employer/home.jsx")
);
const OnBoardingScreen3 = lazy(() =>
  import("view/entity/onBoardingScreen3/OnBoardingScreen3")
);
// const OnBoardingScreen2 = lazy(() =>
//   import("view/entity/onBoardingScreen2/OnBoardingScreen2")
// );

// Create post
const CreateInternship = lazy(() =>
  import("view/dashboard/employer/internship/add")
);
const InternshipPreview = lazy(() =>
  import("view/dashboard/employer/internship/preview")
);
const InternshipTable = lazy(() =>
  import("view/dashboard/employer/internship/list")
);
const ProjectTable = lazy(() => import("view/dashboard/employer/project/list"));
const ProjectTemplate = lazy(() =>
  import("view/dashboard/employer/project/template")
);
const JobTable = lazy(() => import("view/dashboard/employer/job/list"));
const CreateProject = lazy(() => import("view/dashboard/employer/project/add"));
const ProjectPreview = lazy(() =>
  import("view/dashboard/employer/project/preview")
);
const CreateJob = lazy(() => import("view/dashboard/employer/job/add"));
const JobPreview = lazy(() => import("view/dashboard/employer/job/preview"));

const StudentDashboard = lazy(() => import("view/dashboard/student/Dashboard"));

const NotFound = lazy(() => import("view/NotFound.jsx"));

// const StudentChatPage = lazy(() => import("view/dashboard/student/chat/index"))
const PostInvitation = lazy(() =>
  import("view/dashboard/educator/postInvitation/postInvitation.jsx")
);

const Reviewer=lazy(()=>import("view/dashboard/Reviewer/index"))
const MentorManager=lazy(()=>import("view/dashboard/Mentor-Manager/index"))
 
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />}>

      <Route element={<CheckUtmCampaign />}> 
      
      <Route path="signin" element={<Login />} />
      <Route path="/admin/signin" element={<AdminLogin />} />
      <Route path="/admin/verify-otp" element={<AdminVerifyOtp />} />
      <Route path="/admin/forgot-password" element={<AdminForgetPassword />} />

      <Route path="signup" element={<Signup />} />
      <Route path="signup/educator" element={<EducatorSignup />} />
      <Route path="signup/student" element={<StudentSignup />} />
      <Route path="signup/employer" element={<EmployerSignup />} />
      <Route
        path="verify-otp"
        element={<SemiProtectedRoute component={<VerifyOTP />} />}
      />
      <Route path="forgot-password" element={<ForgotPass />} />
      <Route path="reset-password" element={<CreatePassword />} />
      <Route
        path="company"
        element={
          <SemiProtectedRoute
            roleNeeded={2}
            component={<Entity mode="company" />}
          />
        }
      />
      <Route
        path="institution"
        element={
          <SemiProtectedRoute
            roleNeeded={3}
            component={<Entity mode="institution" />}
          />
        }
      />
      <Route
        path="add_interest"
        element={
          <SemiProtectedRoute
            roleNeeded={4}
            component={<Entity mode="Student" />}
          />
        }
      />

      <Route
        path="dashboard/admin"
        element={<ProtectedRoute roleNeeded={1} component={<>Hello Admin</>} />}
      />
      <Route path="select-company" element={<SelectCompanyContainer />} />

      <Route path="/admin" element={<ProtectedRoute roleNeeded={1} />}>
        <Route element={<AdminDashboard />}>
          <Route
            path="project/details/:id"
            element={<ProjectDetails  />}
          />

          <Route
            path="project/template"
            element={<ProjectImageTemplate  />}
          />
          <Route
            path="internship/details/:id"
            element={<InternshipDetails />}
          />
          <Route
            path="internship/template"
            element={<InternshipTemplate  />}
          />
          <Route path="job/details/:id" element={<JobDetails />} />
          <Route
            path="job/template"
            element={<JobTemplate  />}
          />
          <Route
            path=":type/student-list/:id"
            element={<ProjectStudentList/>}
          />
          <Route
            path="mentors"
            element={<MentorListPage/>}
          />
          


          <Route element={<Navigate replace to="dashboard" />} />
          <Route path="dashboard" element={<AdminDashboardHome />} />
          {/* <Route path="evaluations" element={<AdminEvaluations />} /> */}
       
          <Route path="evaluations">
            <Route path="" element={<AdminEvaluations />} />
            <Route path="mentors/:id" element={<AdminMentorsPage />} />
            <Route
              path="evaluation-details"
              element={<AdminEvaluationDetails />}
            />
          </Route>
          <Route path="project-submissions" element={<ProjectSubmission />} />

          <Route path="analytics" element={<AdminAnalytics />} />
          <Route path="master-data" element={<AdminMasterData />} />
          <Route path="subscriptions" element={<AdminSubscriptions />} />
          <Route path="complaints" element={<AdminComplaints />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="campaign/:campaign_name" element={<CampaignDetails />} />
          <Route path="coupons" element={<AdminCoupons />} />
          <Route path="users">
            <Route path="" element={<UserTable />} />
            <Route path="add-user" element={<AdminAddUser />} />
            <Route path="edit-user/:id" element={<AdminEditUser />} />
            <Route path="edit-reviewer/:id" element={<AdminEditReviewer />} />
            <Route path="edit-mentor-manager/:id" element={<AdminEditMentorManager />} />
          </Route>
          <Route path="settings" element={<AdminSettings />} />
          <Route path="subscription-invoice" element={<SubscriptionInvoice/>}/>
        </Route>
      </Route>

      {/* <Route path="/educator">
        <Route element={<CollegeDashboard />} />
        <Route path="dashboard" element={<EducatorHome />} />
      </Route> */}

      <Route path="/employer" element={<ProtectedRoute roleNeeded={2} />}>
        <Route element={<CompanyDashboard />}>
          <Route path="dashboard" element={<CompanyDashboardHome />} />

          <Route path="internship">
            <Route path="add" element={<CreateInternship />} />
            <Route path="edit/:id" element={<EditInternship />} />
            <Route path="preview" element={<InternshipPreview />} />
            {/* <Route path="list" element={<InternshipTable />} /> */}
            <Route path="list" element={<InternshipList />} />
            <Route path="details/:id" element={<InternshipDetails />} />
            <Route path=":id" element={<InternshipPost />} />
            <Route path="invite/:id" element={<Invite />} />
            <Route
              path="students/invite/:id"
              element={<StudentInvite method={2} />}
            />
          </Route>
          <Route path="job">
            <Route path="add" element={<CreateJob />} />
            <Route path="preview" element={<JobPreview />} />
            <Route path="edit/:id" element={<EditJob />} />
            {/* <Route path="list" element={<JobTable />} /> */}
            <Route path="list" element={<JobList />} />
            <Route path="details/:id" element={<JobDetails />} />
            <Route path=":id" element={<JobPost />} />
            <Route path="invite/:id" element={<Invite />} />
            <Route
              path="students/invite/:id"
              element={<StudentInvite method={3} />}
            />
          </Route>
          <Route path="project">
            <Route path="add" element={<CreateProject />} />
            <Route path="edit/:id" element={<EditProject />} />
            <Route path="preview" element={<ProjectPreview />} />
            <Route path="template" element={<ProjectTemplate />} />
            {/* <Route path="list" element={<ProjectTable />} /> */}
            <Route path="list" element={<ProjectList />} />
            <Route path="details/:id" element={<ProjectDetails />} />
            <Route path=":id" element={<ProjectPost />} />
            <Route path="invite/:id" element={<Invite />} />
            <Route
              path="students/invite/:id"
              element={<StudentInvite method={1} />}
            />
          </Route>

          <Route path="all-notifications" element={<AllNotifications />} />
          <Route path="employer-profile" element={<EmployerProfile />} />
          <Route path="subscription" element={<EmployerSubscription />} />
          <Route path="employer-settings" element={<EmployerSettings />} />
          <Route
            path="partner-institutions"
            element={<PartnerInstitutions />}
          />
          <Route path="settings" element={<Setting />} />
          <Route path="helpdesk" element={<HelpDesk />} />
          <Route path="chat" element={<EmployerChat />} />
          

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      {/* <ProtectedRoute roleNeeded={3} component={<>Hello Educator</>} />*/}

      {/*Eduator routes */}
      <Route path="/educator" element={<ProtectedRoute roleNeeded={3} />}>
        <Route element={<CollegeDashboard />}>
          {/* <Route element={<Navigate replace to="dashboard" />} /> */}
          <Route path="dashboard" element={<CollegeEducatorHome />} />
          <Route path="explore/*" element={<NewEducatorExplore />} />
          {/* <Route path="explore" element={<EducatorExplore />} /> */}
          <Route path="upload-student" element={<UploadStudent />} />
          <Route path="profile" element={<EducatorProfile />} />
          <Route path="all-notifications" element={<AllNotifications />} />


          <Route
            path="project/details/:id"
            element={<ProjectDetailComponent />}
          />
          <Route
            path="internship/details/:id"
            element={<EducatorInternshipDetail />}
          />
          <Route path="job/details/:id" element={<EducatorJobDetail />} />
          <Route path="student-lists" element={<NewStudentList />} />

          <Route path="student-list/:id" element={<StudentList />} />

          <Route path="companies" element={<Companies />} />

          <Route path="company" element={<EducatorCompanyPage />} />
          <Route path="student-detail/:id" element={<StudentDetails />} />
          <Route path="student-profile/:id" element={<EduStudentProfile />} />
          {/* <Route
              path="companies/suggest-companies"
              element={<SuggestCompanies />}
            />
            <Route
              path="companies/invitation-send"
              element={<InvitationSent />}
            />
            <Route
              path="companies/invitation-recieved"
              element={<InvitationRecieved />}
            />
            <Route
              path="companies/list-of-partner-companies"
              element={<ListOfPartnerCompanies />}
            />
          </Route> */}

          <Route path="subscriptions" element={<EducatorSubscriptions />} />
          <Route path="chat" element={<EducatorChat />} />
          <Route path="settings" element={<EducatorSetting />} />
          <Route path="helpdesk" element={<HelpDesk />} />
        </Route>
      </Route>

      {/*Educator Routes */}

      <Route path="/student" element={<ProtectedRoute roleNeeded={4} />}>
        <Route element={<StudentDashboard />}> 
          <Route path="session/:id/:roomId" element={<Zego />} />
          <Route element={<Navigate replace to="dashboard" />} />
          <Route path="dashboard" element={<StudentDashboardHome />} />
          <Route path="explore">
            <Route
                path="/student/explore/*"
                element={<ExplorePageNew />}
              />
            {/* New student explore page */}

            {/* <Route
              path="/student/explore"
              element={<ExploreNew />}
            /> */}
            
           

            {/* <Route
              path="/student/explore"
              element={<StudentDashboardExplore />}
            /> */}
            <Route
              path="internship/:id"
              element={<EnrollInternship defaultStep={-1} />}
            />
            <Route path="internship/:id/apply" element={<EnrollInternship />} />
            <Route path="job/:id" element={<EnrollJob defaultStep={-1} />} />
            <Route path="job/:id/apply" element={<EnrollJob />} />
            <Route
              path="project/:id"
              element={<EnrollProject defaultStep={-1} />}
            />
            <Route path="project/:id/apply" element={<EnrollProject />} />
          </Route>

          <Route path="my-applications">
            <Route
              path="/student/my-applications"
              element={<StudentApplication />}
            />
            <Route path="project/:id" element={<Application />} />
            <Route
              path="project/detail/:id"
              element={<ApplicationProjectDetail />}
            />
            <Route
              path="internship/:id"
              element={<PostContent method="internship" />}
            />
            <Route
              path="internship/detail/:id"
              element={<ApplicationInternshipDetail />}
            />
            <Route path="job/:id" element={<PostContent method="job" />} />
            <Route path="job/detail/:id" element={<ApplicationJobDetail />} />
          </Route>
          <Route path="notifications" element={<Notification />} />
          <Route path="all-notifications" element={<AllNotifications />} />

          <Route path="mentor">
            <Route path="" element={<MySessions />} />
            <Route path="/student/mentors" element={<MentorHome />} />
            <Route path="/student/mentor/:id" element={<MentorProfilePage />} />
            <Route
              path="/student/mentor/details/:id"
              element={<MentorDetail />}
            />
            <Route path="all-mentor" element={<NewAllMentors />} />
          </Route>

          <Route path="settings" element={<Setting />} />
          <Route path="helpdesk" element={<HelpDesk />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="chat" element={<StudentChat />} />
          <Route path="mentors" element={<Plan />} />
          <Route path="profile" element={<StudentProfile />} />
          <Route path="plan/:id" element={<StudentPlan />} />

          <Route path="subscriptions" element={<StudentSubscriptions />} />
        </Route>
      </Route>

      {/* <Route
        path="dashboard/student"
        element={
          <ProtectedRoute roleNeeded={4} component={<StudentDashboard />} />
        }
      >
        <Route path="/dashboard/student" element={<StudentDashboardHome />} />
        
      </Route> */}
      <Route path="/mentor" element={<ProtectedRoute roleNeeded={5} />}>
        <Route element={<MentorDashboard />}>
          <Route path="session/:id/:roomId" element={<Zego />} />
          {/* <Route element={<Navigate replace to="dashboard" />} /> */}
          <Route path="dashboard" element={<SessionRequest />} />
          <Route path="zego/:roomid" element={<Zego />} />
          <Route path="list" element={<List />} />
          <Route path="calender" element={<Calender />} />
          <Route path="settings" element={<MentorSettings />}>
            <Route path="availability" element={<Availability />} />
            <Route path="mentor-setting" element={<MentorSetting />} />
            <Route path="billing-payment" element={<BillingAndPayments />} />
            <Route path="fee-charges" element={<FeeAndCharges />} />
            <Route
              path="transaction-history"
              element={<TransectionAndHistory />}
            />
          </Route>
          <Route path="profile" element={<MentorProfile />} />
          <Route path="mentoring-session" element={<MentoringSession />} />
          {/* <Route path="sessionrequest" element={<SessionRequest />} /> */}
          <Route path="availableproject" element={<AvailableProject />} />
          <Route path="projectenroll" element={<ProjectEnroll />} />
          <Route path="jobdetail" element={<Mentorjob />} />
          <Route path="jobcontent" element={<JobContent />} />
          <Route path="mentorcollage" element={<MentorCollage />} />
          <Route path="mentorjoblist" element={<MentorJoblist />} />
          <Route path="jobdescription" element={<Jobdescription />} />

          <Route
            path="project-evaluation-detail/:id"
            element={<ProjectEvaluationDetail />}
          />
          <Route path="student-detail/:id" element={<StudentDetail />} />
          <Route path="project-evaluation" element={<ProjectEvaluation />} />
          <Route path="mentors" element={<Mentors />} />
          <Route path="chat" element={<MentorChat />} />
          <Route path="project-detail/:id" element={<MentorProjectDetails />} />

          {/* <Route path="mentor" element={<Mentor />} /> */}
        </Route>
        <Route
          path="dashboard"
          element={<ProtectedRoute roleNeeded={true} navigate={true} />}
        />
        <Route
          path="user_interests"
          element={
            <ProtectedRoute
              roleNeeded={false}
              component={<OnBoardingScreen3 />}
            />
          }
        />
      </Route>

       {/* reviewer */}
      {/* <Route path="/reviewer" element={<ProtectedRoute roleNeeded={6}/>}>  */}
      <Route path="/reviewer" element={<ProtectedRoute roleNeeded={6}/>}> 
         <Route element={<Reviewer/>}>
          <Route path="dashboard" element={<ReviewerDashboardHome/>}/>
          <Route path="profile" element={<ReviewerProfile/>} />
          <Route path="projects" element={<ReviewerProject/>} />
          <Route path="internships" element={<ReviewerInternship/>} />
          <Route path="jobs" element={<ReviewerJob/>} />
          <Route
            path="project/details/:id"
            element={<ReviewerProjectDetailsPage />}
          />
           <Route
            path="internship/details/:id"
            element={<ReviewerInternshipDetailsPage/>}
          />
           <Route
            path="job/details/:id" 
            element={<ReviewerJobDetailsPage />}
           />
         <Route path="settings" element={<ReviewerSettings/>} />
        </Route>
      </Route> 

      {/* end reviewer */}
     


      {/* mentor_manager */}
      <Route path="/mentor-manager" element={<ProtectedRoute roleNeeded={7}/>}> 
      <Route element={<MentorManager/>}>
        <Route  path="dashboard" element={ <MentorManagerDashboardHome/> }/>
        <Route path="profile" element={<MentorManagerProfile/>} />
        <Route path="settings" element={<MentorManagerSetting/>} />
        <Route path="mentors/:id" element={<MentorManagerMentorsPage />} />
        <Route path="add-mentor" element={<CreateMentorByManager />} />
        <Route path="mentors-list" element={<MentorManagerMentorList />} />
        <Route path="update-mentor-profile" element={<MentorProfileByManager/>} />
        <Route path="update-mentor-password" element={<EditPasswordByManager/>} />


        </Route>
      </Route>

      {/* end mentor_manager */}


      {/* <Route path="posts" element={<HomeContainer />}> */}
      {/* <Route path="/posts" element={<PostsHome />} /> */}
      {/* <Route
          path=":method/:id"
          element={<PreviewContainer isPost={true} />}
        />
      </Route>
      */}
      <Route path="/post-invitation" element={<PostInvitation />} />
      <Route path="for_student" element={<StudentHome />} />
      <Route path="for_educator" element={<EducatorHome />} />
      <Route path="for_employer" element={<EmployerHome />} />
      <Route path="/" element={<HomePage />} />
      {/* <Route path="/home" element={<HomePage />} /> */}
      <Route path="/company-page" element={<CompanyPage />} />
      <Route path="/college-page" element={<CollegePage />} />
      <Route path="/student-page" element={<StudentPage />} />
      <Route path="/explore-page/*" element={<ExploreHomePage />} />

      <Route path="/" element={<ExploreHomePageNew />}>
        <Route
          path="project/:name/:id"
          element={<EnrollProject defaultStep={-1} />}
        />

        <Route
          path="internship/:name/:id"
          element={<EnrollInternship defaultStep={-1} />}
        />
        <Route path="job/:name/:id" element={<EnrollJob defaultStep={-1} />} />
      </Route>
      

      <Route path="/faq" element={<FAQ/>} />
      <Route path="/terms-conditions" element={<TermsAndConditionPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
      <Route path="/mentoring-policy" element={<MentorPolicyPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/refund-policy" element={<RefundPolicyPage />} />
      <Route path="/team" element={<OurTeamPage/>} />
      <Route path="/industry-partners" element={<IndustryPartners/>} />
      <Route path="/academic-partners" element={<AcademicPartners/>} />
      <Route path="*" element={<NotFound />} />

      </Route>
      
    </Route>
  )
);

export default router;
