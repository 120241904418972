export const getUserRole = (role = false) => {
  const map = {
    1: "admin",
    2: "employer",
    3: "educator",
    4: "student",
    5: "mentor",
    6: "reviewer",
    7: "mentor-manager"
  };

  return map[role] ? `/${map[role]}/dashboard` : "/signin";
};