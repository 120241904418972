import axios from "axios";
import InputFile from "components/fileUploader";
import SubmitButtonAlt from "components/formComponents/SubmitButtonAlt";
import TextArea from "components/formComponents/TextArea";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getParsed } from "services/factories/getParsed";
import "./weekModalBody.scss";

const WeekModalBody = ({
  onCancel,
  week = 0,
  enrollment_id,
  project_id,
  setFetchData,
  enrollment_createdAt,
  project_date,
}) => {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState(null);
  const[loading,setLoading]=useState(false)

  const [weekly_report_date, setWeekly_report_date] = useState();

  useEffect(() => {
    const weeksToAdd = project_date;

    const submissionDate = new Date(enrollment_createdAt);

    submissionDate.setDate(submissionDate.getDate() + weeksToAdd * 7);

    const sd = moment(submissionDate).tz("Asia/Kolkata").format("DD/MM/YYYY");

    // const today = moment().tz("Asia/Kolkata").format("DD/MM/YYYY");

    // const diff = moment(sd, "DD/MM/YYYY").diff(
    //   moment(today, "DD/MM/YYYY"),
    //   "days"
    // );

    // if (diff >= 0) {
    //   setFinal_submission_report_check(true);
    // }

    setWeekly_report_date(new Date());
  }, [enrollment_createdAt]);

  const onSubmit = async (e) => {
    e?.preventDefault();
    if (!description)
      return toast.error("Please add a description", {
        toastId: "description",
      });
    // if (!file)
    //   return toast.error("Please upload a file", {
    //     toastId: "file",
    //   })

    const formData = new FormData();
    formData.append("weekly_report", file);
    formData.append("week", week);
    formData.append("description", description);
    formData.append("enrollment_id", enrollment_id);
    formData.append("project_id", project_id);
    formData.append("weekly_report_date", weekly_report_date);
    setLoading(true)

    const { data } = await axios.post("/v1/student/weekly_report", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setLoading(false)

    if (data?.error) {
      return toast.error(data?.message, {
        toastId: "error",
      });
    } else {
      toast.success("Report uploaded successfully", {
        toastId: "success",
      });
    }

    const enrollment_data = data?.data;
    const weekly_submissions = getParsed(enrollment_data?.weekly_submissions);

    setFetchData((prev) => {
      // console.log({ prev, enrollment_data, weekly_submissions });
      return {
        ...prev,
        data: {
          ...prev?.data,
          data: {
            ...prev?.data?.data,
            ...enrollment_data,
            weekly_submissions,
          },
        },
      };
    });
    onCancel();
  };

  const handleDescChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <form className="weekModalBoady form-group" onSubmit={onSubmit}>
      <TextArea
        label="Description of work completed"
        id="description"
        handleChange={handleDescChange}
        placeholder="Type here"
      />
      <InputFile file={file} setFile={setFile} />

      <div className="modal_btns d-flex align-items-center justify-content-end gap-2">
        <SubmitButtonAlt value="Close" onClick={onCancel} />
        <button className="SubmitBtn m-2 p-2" onClick={onSubmit}>
          
          {loading ? (
          <i className="text-white fa fa-circle-o-notch fa-spin" style={{ backgroundColor:"#6149CD"}}></i>
        ) : (
          "Submit"
        )}
        </button>
      </div>
    </form>
  );
};

export default WeekModalBody;
