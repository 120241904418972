import GoToTop from 'GoToTop'
import React from 'react'
import Footer from 'view/dashboard/student/footer/Footer'

import TopNavBar from 'view/newWeb/components/TopNavbar/TopNavBar'
import NavBar from 'view/newWeb/components/navbar/NavBar'

import IndustryPartnerSection from 'view/newWeb/components/partners/industryPartner'

export default function IndustryPartners(){
    return(
        <div>
        <TopNavBar />
        <NavBar />
        <div className='mt-5 mb-5'>
        <IndustryPartnerSection/>

        </div>
       
       
        <Footer/>
        <GoToTop />

         </div>
    )
}