import axios from 'axios'
import useOutsideClick from 'hooks/useOutsideClick'
import React, { useEffect, useRef, useState } from 'react'
import "./NewProjectDetails.scss"
import NewPreviewField from './PostsHelper/NewPreviewField'
import RightCard from './PostsHelper/RightCard'
import TopCard from './PostsHelper/TopCard'
import PostDetailsSection from './PostsHelper/PostDetailsSection'

const NewProjectDetailsPage = ({  
    data,
    Action,
    children,
    onApply,}) => {
    
    const [moreProjects, setMoreProjects] = useState([]);
    const [similarProjects, setSeimilarProjects] = useState([]);
    const [scores, setScores] = useState([]);

    useEffect(()=> {

        const fetchMoreProjects = async ()=> {
            try {
                const res = await axios.post(`/v1/filter/project/company/${data?.project_global_fkey?.id}`, {
                    project_id: data?.id
                });

                // console.log("proojeeectt", res);

                setMoreProjects(res?.data?.data);
            } catch (error) {
                // console.log(error);
            }
        }

        const fetchSimilarProjects = async ()=> {
            try {
                const res = await axios.get(`/v1/filter/project/similar-projects/${data?.id}`);

                // console.log("similarrrress", res?.data);

                setSeimilarProjects(res?.data?.data?.similarProjects);
                setScores(res?.data?.data?.scores)
            } catch (error) {
                // console.log(error);
            }
        }

        fetchMoreProjects();
        fetchSimilarProjects();

    }, []);

    const [show, setShow] = useState(false);

    const toggleDropdown = () => setShow((prev) => !prev);

    const ref = useRef(null)
    useOutsideClick(ref, ()=> setShow(false), show)

    // console.log("oouterr", similarProjects)

    // console.log("ppppdataaa", data);
  return (
    <div className='details-wrapper  pt-4'>
        <div className='container d-flex gap-4'>
            <div className='col-md-9'>
                <PostDetailsSection data={data} children={children} type={"project"} />
            </div>


            <div className="right-section col-3">
                <div className='right-container d-flex flex-column gap-4'>

                {
                    data?.internships?.length > 0 && 

                    <RightCard title={"This Project Linked With The Following"} highlightedText={"Internships"} data={data?.internships} type="internship"  />
                } 
                {
                    data?.job?.length > 0 && 

                    <RightCard title={"This Project Linked With The Following"} highlightedText={"Jobs"} data={data?.job} type="job"  />
                } 


                    <RightCard title={"More Projects Offered By "} highlightedText={data?.project_global_fkey?.employer_account?.company?.company_name} data={moreProjects} type="project" companyId={data?.project_global_fkey?.employer_account?.company?.id} seeall={true} />

                    <RightCard title={"We Found More Similar "} highlightedText={"Projects For You"} data={similarProjects} type="project"  scores={scores} seeall={true}  />

                </div>
            </div>

        </div>
    </div>
  )
}

export default NewProjectDetailsPage