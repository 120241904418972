import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Datatable from "components/datatable-new/DatatableNew";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../style.scss";
import AdminDashCard from "../table-card";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
import "../index.scss";
import AdminBarGraph from "../table-card/bargraph";
import MobileViewCompanyTable from "./mobileViewcompanyTable";

const AdminDashCompanies = ({data, setdates,setActivate,date}) => {

// console.log("data33",data)
// console.log("datasss11",date)
// console.log("industry",  data.map((item)=>{return item.industry?.industry_name}))



  const [calendarVisible, setCalendarVisible] = useState(false);
  const [temporaryDates, setTemporaryDates] = useState([date[0]]);

  const handleToggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // const handleDateChange = (ranges) => {
  //   setdates([ranges.selection])
 
  //   setCalendarVisible(false);

  // };

  const handleDateChange = (ranges) => {
    setTemporaryDates([ranges.selection]);
  };

  const handleApply = () => {

    setdates([...temporaryDates]);
    setCalendarVisible(false);
  };

  
 


  // const handleOkButtonClick = () => {
  //   setCalendarVisible(false);
  // };
 

  const datePickerRef= useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        // Clicked outside the date picker, close the picker
        setCalendarVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  

 


  
  // console.log("componydata",data)

 

  const [search, setSearch] = useState(() => "");
  


   // Calculate the total number of companies
   const totalCompanies = data.length;
   // console.log("length",totalCompanies)

   let ActiveEmployees=0;
   data.map((item)=>{
    // console.log("deleted", item?.deletedAt)
    if(item?.deletedAt == null){
      ActiveEmployees++

    }
   })


// // console.log("daterange...", dateRange)

   // Create a single card to display the total number of companies
   const cardsData = [
    {
      primaryText: 'Companies',
      secondaryText: `+${totalCompanies}`,
      value: `${totalCompanies}`,
      date: `${data.map((item) => item?.createdAt)}`,
      isCompany: false, 
      isActive: true, 
    
    },
    
  ];

  // date
  // console.log("datedefault",date[0].startDate)
   const currentdate=date[0].startDate
   const defaultdate=new Date(currentdate)
  

   
   // Separate the date values
   const dateStrings = cardsData[0].date.split(',');
   // console.log("datestring",dateStrings)
   // Create an object to store the total companies added for each day
  const dayCounts = {};
  
  // Process each date string and count the number of companies added on that day
  dateStrings.forEach((dateStr) => {
    const date = new Date(dateStr);
    // const fullDate = date.toISOString().split('T')[0]; // Extract the day part
    const fullDate = date.toLocaleDateString(undefined,{ month: 'short', day: 'numeric',year:'numeric' })
    // .split('T')[0] // Extract the day part
    // // console.log("fuldate",fullDate)
    // const day = fullDate.split('/')[0]; // Extract the day part
    const day = fullDate// Extract the day part


    // console.log("dayyyy",day)
    if (dayCounts[day]) {
      dayCounts[day]++;
    } else {
      dayCounts[day] = 1;
    }
  });

   // Create an array with objects for the chart data
   const formattedData = Object.keys(dayCounts).map((day) => {
    // return {
    //   day,
    //   total: dayCounts[day],
    // };
    const total = dayCounts[day];
    const isValidDate = !isNaN(Date.parse(day));
  
    return {
      day: isValidDate ? day : 'Invalid Date',
      total: isValidDate ? total : 0,
    };

    
  });
 // Sort the formattedData array by date in ascending order
//  formattedData.sort((a, b) => parseInt(a.day, 10) - parseInt(b.day, 10));
 formattedData.sort((a, b) => new Date(a.day) - new Date(b.day));
  // console.log("formattedData", formattedData);



 
const CompaniesDataset = {
  label: ' Companies',
  data: formattedData.map((item)=>{return item.total}),
  borderColor: '#6149CD',
      backgroundColor: '#6149CD',
};


 const chartData = {
  
  labels: formattedData.map((item)=> {
    // return item.day
    // const nodata="No Data"
    // const date = item && item.day ? new Date(item.day) : "no data";
    const date = item && item.day ? new Date(item.day) : new Date(defaultdate);
    // console.log("const_date",date)

  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    // If invalid, replace with today's date
    return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }

  return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric'});
  }),
  datasets:  [CompaniesDataset ]
};

// console.log("dataset",formattedData)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display:false // Change 'linear' to 'bottom' or 'top'
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date', // X-axis title
      },
      // beginAtZero: true, // Start the x-axis from 0
    },
    y: {
      title: {
        display: true,
        text: 'Company', // Y-axis title
     
      },
      beginAtZero: true, // Start the y-axis from 
      ticks: {
        stepSize: 2,
      },
    },
    
  },
};



const industryFrequency = {};

data.map((item) => {
  const industryName = item?.industry?.industry_name; 
  // // console.log("industryname",industryName)

  if(industryName !== undefined){

    industryFrequency[industryName] = (industryFrequency[industryName] || 0) + 1;
    
  }
});

// console.log("industryname",industryFrequency)

// Sort the industries by frequency in descending order
const sortedIndustries = Object.keys(industryFrequency).sort((a, b) => industryFrequency[b] - industryFrequency[a]);

// Take the top 5 industries
const top5Industries = sortedIndustries.slice(0, 5);
// Extract labels and data for the top 5 industries
const labels = top5Industries.map(industry => industry.split(' ')[0]);
const filterdata = top5Industries.map(industry => industryFrequency[industry]);

  const Industrydata = {

    labels: labels,
    datasets: [
      {
        label: 'Industry',
        data: filterdata,
        borderColor: '#6149CD',
        backgroundColor: '#6149CD',
        borderWidth: 1,
        barPercentage: 0.5, // Adjust the thickness of the bars
      //  categoryPercentage: 0.5, // Adjust the padding between the bars
       borderRadius:10,
       
      },
    ],
  };

  const barOptions = {
    
    scales: {
      x: {
        display: true, // Hide x-axis labels
        position:'right',
        ticks: {
         
          // maxRotation:5,
          minRotation:30,
         
          autoSkip: false, // Prevent auto-skipping of labels
        },
    
      
      },
      y: {
        display: true, // Hide y-axis labels
        ticks: {
          stepSize: 2,
        },
       
      },
    },
    indexAxis: 'x', // This controls the orientation of the bars
    plugins: {
      legend: {
        display: false, // Hide legend?
      },
     
    },

  
  };
  



  const handleAccountActivation = async (id) => {
    const data = await axios.delete(`/v1/employer/employer/${id}`);
    // console.log(data, "dataemployee");
    if (!data.data?.error) {
      toast.info(data?.data?.message);
      setActivate((prev) => !prev);
    } else {
      toast.error(data?.data?.error);
    }
  };

  const columns = [
    {
      Header: "Company",
      accessor: "company_name",
      width: "20%",
      textalign: "start",
      sortType: (rowA, rowB, columnId) => {
        return rowA?.original?.company_name?.toLowerCase() <
          rowB?.original?.company_name?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <div className="name-block">
            <h6
              style={{
                textTransform: "capitalize",
              }}
            >
              {data.row.values.company_name}
            </h6>
            <p>{data.row.original.company_location}</p>
          </div>
        );
      },
    },
    {
      Header: "Contact Person",
      accessor: "name",
      width: "13%",
      textalign: "start",
      sortType: (rowA, rowB, columnId) => {
        return rowA?.original?.name?.toLowerCase() <
          rowB?.original?.name?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <div className="name-block">
            <h6
              style={{
                textTransform: "capitalize",
              }}
              className="text-colored"
            >
              {data.row.values.name}
            </h6>
            <p>{data.row.original.designation}</p>
          </div>
        );
      },
    },
    {
      Header: "Contact details",
      accessor: "phone_number",
      width: "8%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.phone_number}
            <p>{data.row.original.email}</p>
          </strong>
        );
      },
    },
    // {
    //   Header: "Email",
    //   accessor: "email",
    //   width: "10%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.email}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Signup Date",
      accessor: "signup_date",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.signup_date}
            <strong className="d-block">{data.row.original.signup_time}</strong>
          </strong>
        );
      },
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        // console.log(dateA, dateB, "dateA, dateB");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },

      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {moment(data?.row?.original?.last_login)?.format("DD/MM/YYYY")}
            <strong className="d-block">{data.row.original.last_time}</strong>
          </strong>
        );
      },
    },
    {
      Header: "Projects",
      accessor: "projects",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.projects}
          </strong>
        );
      },
    },
    {
      Header: "Interships",
      accessor: "internships",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.internships}
          </strong>
        );
      },
    },
    {
      Header: "Jobs",
      accessor: "jobs",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.jobs}
          </strong>
        );
      },
    },
    {
      Header: "Subscription Validity",
      accessor: "subscription_validity",
      width: "13%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        const dateB = rowB?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-");
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.subscription_validity}
            {data?.row?.original?.planType && (
              <p> {data.row.original.planType}</p>
            )}
          </strong>
        );
      },
    },
    {
      Header: "Sector",
      accessor: "sector",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        return rowA?.original?.sector?.toLowerCase() <
          rowB?.original?.sector?.toLowerCase()
          ? -1
          : 1;
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700", textTransform: "capitalize" }}>
            {data.row.original.sector}
          </strong>
        );
      },
    },
    {
      Header: "Action",
      accessor: "status",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <button
            onClick={() => handleAccountActivation(data.row.original.id)}
            className={`deactivate-btn ${
              data.row.original.deletedAt === "Activate"
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {data.row.original.deletedAt}
          </button>
        );
      },
    },
  ];
  // // console.log(data, "data")
  const rows=data
  // const rows = filteredData
    ?.filter((post) => {
      if (
        post?.company.company_name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        post?.branch?.address.toLowerCase().includes(search.toLowerCase()) ||
        post?.email_address.toLowerCase().includes(search.toLowerCase()) ||
        post?.first_name.toLowerCase().includes(search.toLowerCase()) ||
        post?.last_name.toLowerCase().includes(search.toLowerCase()) ||
        post?.industry?.industry_name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        post?.phone_number?.toLowerCase().includes(search?.toLowerCase())
      ) {
        return post;
      } else {
        return null;
      }
    })
    ?.map((post) => {
      return {
        id: post?.id,
        company_name: post?.company.company_name,
        company_location: post?.branch?.address,
        phone_number: post?.phone_number || "N/A",
        email: post?.email_address,
        name: post?.first_name + " " + post?.last_name,
        signup_date: moment(post?.createdAt).format("DD/MM/YYYY"),
        last_login: post?.lastLogin,
        projects: post?.account?.project_count || 0,
        internships: post?.account?.internship_count || 0,
        jobs: post?.account?.job_count || 0,
        last_time: moment(post?.lastLogin).tz("Asia/Kolkata").format("hh:mm A"),
        subscription_validity:
          post?.account?.subscription_subscriber.length > 0
            ? moment(
                post?.account?.subscription_subscriber[0]?.valid_upto
              ).format("DD/MM/YYYY")
            : "N/A",
        planType:
          post?.account?.subscription_subscriber[0]?.subscription_database
            .subscription_name,
        sector: post?.industry?.industry_name || "N/A",
        designation: post?.designation,
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
      };
    })
   

  return (
    <div className="admin-dash-companies">
      <div className="row flex-nowrap overflow-auto graphcardgap">
     
        {cardsData.map((card, index) => {
          return (
            <div className="col-12 col-md-6" key={index}>
              <AdminDashCard
                 data={chartData}
                 option={options}

                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          );
        })}

        <div className="col-12 col-md-6">
        <AdminBarGraph
        title={`Top ${filterdata?.length} Industry Sectors`}
        data={Industrydata}
        option={barOptions}
        />

        </div>
      </div>

      <div className="datatable-container mt-4">
        <div className="table_head flex">
          <div className="mobo_upload">
            <h3 className="new-class-active">
              <div>
                <h3>Companies</h3>
                <span className="light new-span-count">
                  {data.length || 0} records{" "}
                  {
                    <div className="new-type">
                      <div className="active">
                        Active ({ActiveEmployees})
                        {/* Active ({data?.[0]?.active_employee_count}) */}
                      </div>
                      <div className="inactive">
                        Inactive ({totalCompanies - ActiveEmployees})
                        {/* Inactive ({data?.[0]?.inactive_employee_count}) */}
                      </div>
                    </div>
                  }
                </span>
              </div>
            </h3>

            <Link to="" className="upload_btn_icon_mobo btn btn-primary">
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non" ref={datePickerRef} >
            {/* date range */}
            <div  >
      <span className="light new-span-count" style={{color: '#7a86a1'}}>select date range</span>

      <div className="date-input-container" >

        <input
           className="myinput"
        // style={{ width: 'auto',
        //   padding: '5px 10px',
        //   alignSelf: 'baseline',
        //   border: '1px solid #6149cd',
        //   borderRadius: '8px',
        // cursor:'pointer'}}
       
          type="text"
          value={
            date[0]?.startDate && date[0]?.endDate
              ? `${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`
              : 'Select Date Range'
          }
          // value={`${date[0].startDate?.toLocaleDateString()} - ${date[0].endDate?.toLocaleDateString()}`}
          onClick={handleToggleCalendar}
          readOnly
        />
         {calendarVisible && (
        <div className="mydiv">
          {/* <DateRangePicker
            ranges={date}
            showPreview={false}
            onChange={handleDateChange}
            
          /> */}
         
          <DateRangePicker ranges={temporaryDates} showPreview={false} onChange={handleDateChange}
           />
           <div className=" applybtn-div">
           <button className="rdrApplyButton" onClick={handleApply}>Apply</button>
           </div>
           
        </div>
           
      )}
        
      </div>
    
     
        <style>
        {`
          /* Place the CSS here */
         
          .rdrStaticRangeLabel {
            color:#000000

          }
          


          /* Add the rest of the CSS styles here */
        `}
      </style>
              </div>
         


            
            {/*  End date Rage */}
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>


        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewCompanyTable columns={columns} data={rows} handleAccountActivation={handleAccountActivation}/>
      </div>



        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  );
};

export default AdminDashCompanies;
