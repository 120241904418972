import React from "react";

// import User from "./User.png";

import ProfileIcon from "components/employer/ProfileIcon";
import moment from "moment";
import Link from "./Link.svg";
import LinkedIn from "./Linkedin.svg";
import "./studentCard.scss";

const StudentCard = ({ students }) => {
  // // console.log("students", students);

  const currentAge = (date_of_birth) => {
    var today = new Date();
    var birthDate = new Date(date_of_birth);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  // console.log(students, "hello world");

  return (
    <>
      <div className="row m-0 collegeCard">
        <div className="cardHeader">
          <div className="collegeDetail studentDetail">
            <div className="logo">
              {students?.profile_photo ? (
                <img src={students?.profile_photo} alt="logo" srcset="" />
              ) : (
                <div className="img-box-img">
                  <ProfileIcon />
                </div>
              )}
            </div>

            <div className="details d-flex flex-column gap-0">
              <div className="collegeName studentName">
                {students?.first_name} {students?.last_name}
              </div>
              <div className="collegeLocation">
                {students?.location && students?.location}
              </div>

              {/* <div className="collegeType">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                University(Private)
              </div> */}
            </div>
          </div>
        </div>

        {/* "experiences": [
                    {
                        "company_name": "ddadadda",
                        "designation": "dadad",
                        "start_date": "2023-07-15T00:00:00.000Z",
                        "end_date": "2023-07-30T00:00:00.000Z",
                        "description": "daiodjauiodk"
                    }
                ] */}

        <div className="cardBody">
          <div className="education-details">
            {students?.course_name && (
              <div className="student-course">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                {students?.course_name}
              </div>
            )}
            {students?.date_of_birth && (
              <div className="student-course">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                {students?.date_of_birth &&
                  currentAge(students?.date_of_birth)}{" "}
              </div>
            )}
          </div>
          <div className="collegeDescription">{students?.bio}</div>
          <div className="student-exp-border"></div>
          {students?.experiences?.length > 0 ? (
            <div className="student-exp-details">
              <div className="student-exp-title">Experience</div>
              <div className="student-exp">
                {students?.experiences?.map((item, index) => {
                  return (
                    <>
                      <div className="student-exp-details">
                        <div className="exp-detail">
                          <div className="student-exp-title1">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                            {item?.designation}
                          </div>

                          <div className="student-exp-company">
                            {item?.company_name}
                          </div>
                        </div>
                        <div className="student-exp-duration">
                          {moment(item?.start_date).format("MMM YYYY")} -{" "}
                          {moment(item?.end_date).format("MMM YYYY")}
                        </div>
                        <div className="student-exp-description">
                          {item?.description}
                        </div>
                      </div>
                      <div className="student-exp-border"></div>
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="cardFooter student-card-footer">
          <div className="collegeWebsite">
            {students?.profile_link && (
              <>
                <img src={Link} alt="Image" />
                {students?.profile_link}
              </>
            )}
          </div>
          <div className="userLinkedin">
            {students?.linkedIn_link && (
              <>
                <img src={LinkedIn} alt="Image" />
                {students?.linkedIn_link}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentCard;
