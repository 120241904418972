// import { Link } from 'gatsby';
import React from 'react';
import './aboutSection.scss'
// import img3 from '../../images/step-journey-1.png'

const AboutSection = ({Simg,title1,title2,title3,subTitle1,subTitle2,subTitle3}) => {
    return (
        <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
               
                <div className="row justify-content-center gap-4">
                    
                    <div className="col-xl-5 col-lg-5">
                        <div className="about-content mb-50">
                            
                            <div className="student-choose-list">
                                <h3 >{title1}</h3>
                               
                                <p className=" mb-30">{subTitle1}</p>
                                
                            </div>
                            <div className="student-choose-list">
                                <h3 >{title2}</h3>
                               
                                <p className=" mb-30">{subTitle2}</p>
                                
                            </div>
                            <div className="student-choose-list">
                                <h3 >{title3}</h3>
                               
                                <p className=" mb-30">{subTitle3}</p>
                                
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 align-self-center">
                        <div className="about-img position-relative mb-50">
                            <div className="about-main-img">
                                <img src={Simg} alt="about" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;