import axios from "axios";
import MiniStudentApplication from "components/cards/MiniStudentResume";
import StudentJobDescription from "components/cards/StudentJobDescription";
import Freelancerimg from "images/Freelancer.png";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SectionSideFilter from "view/dashboard/employer/project/id/sections/SectionSideFilter";

const Applications = ({ enrollments, post, handleStatusChange, dispatch }) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [showFilter, setShowFilter] = useState(false);

  const changeStatus = async (status) => {
    try {
      const response = await axios.patch(
        `/v1/enrollments/internship`,
        {},
        {
          params: {
            id: enrollments?.data[selectedStudent].id,
            status,
          },
        }
      );

      toast.success(response?.data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNotInterested = () => {
    changeStatus(7);
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 7,
      oldStatus: enrollments?.data[selectedStudent].status,
    });
  };

  const handleShortlisted = () => {
    changeStatus(4);
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 4,
      oldStatus: enrollments?.data[selectedStudent].status,
    });
  };

  const handleHired = () => {
    changeStatus(6);
    handleStatusChange({
      id: enrollments?.data[selectedStudent].id,
      status: 6,
      oldStatus: enrollments?.data[selectedStudent].status,
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Handler function to update the search term state
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // // console.log(enrollments?.data, "enrollments?.data?");
  // Filtered array based on search term
  const filterData = (searchTerm) => {
    const filteredEnrollments = enrollments?.data?.filter(
      (item) =>
        item?.student?.course_name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        item?.student?.first_name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        item?.student?.location
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        item?.student?.branch_name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        item?.student?.experiences?.some(
          (experience) =>
            experience?.company_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            experience?.designation
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
    );
    setFilteredData(filteredEnrollments);
  };
  useEffect(() => {
    filterData(searchTerm);
  }, [enrollments, searchTerm]);

  // // console.log(
  //   enrollments?.data[selectedStudent]?.student.id,
  //   "hello world id"
  // );
  // // console.log(
  //   enrollments?.data[selectedStudent]?.student?.role,
  //   "hello world role"
  // );

  // // console.log(enrollments?.data[selectedStudent]?.id, "hello world enrollment id");

  const handleChange = () => {
    const chatApi = async () => {
      try {
        const chatRoomId = await axios.post("/v1/chat/create-chat-room", {
          userId: enrollments?.data[selectedStudent]?.student?.id,
          role: enrollments?.data[selectedStudent]?.student?.role,
          enrollment_type: 1,
          enrollment_id: enrollments?.data[selectedStudent]?.id,
        });
        // console.log(chatRoomId);
      } catch (error) {
        throw new Error();
      }
    };
    chatApi();
  };

  return enrollments?.data?.length ? (
    <div className="tab-content">
      <div className="tab-pane fade show active">
        <div className="content-tab">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="searchbox">
                <label>
                  {" "}
                  <input
                    type="text"
                    placeholder="Search candidate"
                    onChange={handleSearch}
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </label>
                <i
                  onClick={() => setShowFilter(true)}
                  className="filter_mobo fa fa-sliders d-flex d-sm-none"
                  aria-hidden="true"
                ></i>
              </div>
              {filteredData?.map((item, index) => {
                const studentFunction = () => {
                  setSelectedStudent(index);
                  setShow(true);
                };
                return (
                  <div key={index} onClick={studentFunction}>
                    <MiniStudentApplication key={index} item={item} />
                  </div>
                );
              })}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-sm-block">
              <StudentJobDescription
                data={enrollments?.data?.[selectedStudent]}
                post={post}
                method={"internship"}
                selected={selectedStudent}
                setSelected={setSelectedStudent}
                max={enrollments?.data?.length}
                handleStatusChange={handleStatusChange}
                handleChat={handleChange}
              />
            </div>
            {show && (
              <div className="status_bottom_btn_mobo  d-block d-sm-none col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => setShow(false)}
                  aria-hidden="true"
                ></i>
                <StudentJobDescription
                  data={enrollments?.data?.[selectedStudent]}
                  post={post}
                  method={"internship"}
                  selected={selectedStudent}
                  setSelected={setSelectedStudent}
                  max={enrollments?.data?.length}
                  handleStatusChange={handleStatusChange}
                  handleChat={handleChange}
                />
              </div>
            )}
            <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-sm-block">
              <div className="row">
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <SectionSideFilter dispatch={dispatch} />
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            {showFilter && (
              <div className="mobo_filters  d-flex d-sm-none">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => setShowFilter(false)}
                  aria-hidden="true"
                ></i>
                <div className="row ">
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="job-filter">
                      <label htmlFor="city">Country</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">State</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">City</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">Courses</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="skill">Skills</label>
                      <input
                        type="Search"
                        id="skill"
                        list="skills-data"
                        placeholder="Search Skills"
                      />
                      <datalist id="skills-data">
                        <option value="html"></option>
                        <option value="css"></option>
                      </datalist>

                      <div className="spanbox">
                        <span>
                          IT <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          IT Students <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          React <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          B.Tech <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                      <label htmlFor="qualification">Qualification</label>
                      <input
                        type="text"
                        id="qualification"
                        placeholder="Search qualification"
                      />
                      <button className="clear">Clear</button>
                      <button>Apply</button>
                    </div>
                  </div>
                  {/* <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="tab-content">
      <div className="tab-pane fade show active">
        <div className="content-tab">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
              No Applications Found
            </div>
            {/* <div>
          <div>

          </div>
        </div> */}
            <div className="status_bottom_btn col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-sm-block"></div>

            <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-sm-block">
              <div className="row ">
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <SectionSideFilter dispatch={dispatch} />
                </div>
                <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="new-post"
                    onClick={() => navigate("/employer/internship/add")}
                  >
                    <div className="flex">
                      <div>
                        <h3>Post New </h3>
                        <h4>Internship</h4>
                      </div>
                      <div>
                        {" "}
                        <i className="fa-solid fa-right-long"></i>
                      </div>
                    </div>
                    <img src={Freelancerimg} alt="Image" />
                  </div>
                </div>
              </div>
            </div>

            {showFilter && (
              <div className="mobo_filters  d-flex d-sm-none">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => setShowFilter(false)}
                  aria-hidden="true"
                ></i>
                <div className="row ">
                  <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="job-filter">
                      <label htmlFor="city">Country</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">State</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">City</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="city">Courses</label>
                      <input
                        type="Search"
                        id="city"
                        list="search-data"
                        placeholder="Search City"
                      />
                      <datalist id="search-data">
                        <option value="Delhi"></option>
                        <option value="Noida"></option>
                      </datalist>
                      <label htmlFor="skill">Skills</label>
                      <input
                        type="Search"
                        id="skill"
                        list="skills-data"
                        placeholder="Search Skills"
                      />
                      <datalist id="skills-data">
                        <option value="html"></option>
                        <option value="css"></option>
                      </datalist>

                      <div className="spanbox">
                        <span>
                          IT <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          IT Students <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          React <i className="fa-solid fa-xmark"></i>
                        </span>
                        <span>
                          B.Tech <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                      <label htmlFor="qualification">Qualification</label>
                      <input
                        type="text"
                        id="qualification"
                        placeholder="Search qualification"
                      />
                      <button className="clear">Clear</button>
                      <button>Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
