import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./listAccordian.scss"
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";


const StudentListAccordian = ({listName, students, selectedIds, setSelectedIds }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);

    // console.log("selecteddddd", selectedIds);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    const listIds = students?.map(item => item?.id);

  
    const toggleCheckbox = (student, checked) => {
        // console.log("studentttt", student);

        let selectedarr ;
        let listSelected = [];

        if(Array.isArray(student)){
            // console.log("iniffselected", selectedStudents);

            selectedarr =  checked ? [...selectedIds?.filter(i => selectedStudents.every((item) => i !== item)) ,...student.map((item) => item.id) ]: [...selectedIds.filter(element => !listIds.includes(element))];

            // setSelectedStudents(checked ? [] : [] );

            
        }else{
            selectedarr = selectedIds?.includes(student.id)
            ? selectedIds?.filter((id) => id !== student.id)
            : [...selectedIds, student.id];

            // if(selectedStudents === undefined){
            //     setSelectedIds([]);
            // }

             listSelected = selectedStudents?.includes(student?.id) ? selectedStudents?.filter((id) => id !== student.id) : [...selectedStudents, student?.id]

        }
        
        setSelectedStudents(listSelected);

        setSelectedIds(selectedarr);
      
    };


    // console.log("selecteddd", selectedStudents);
    // getSelectedIds(selectedIds);
     
  return (
<>
    <div className="educator-accordion-title" onClick={toggleAccordion}>
    <div className="col-8">
    <h4>{listName}</h4>
  </div>
  <div className="col-4 d-flex justify-content-end">
        {isOpen ? <FaCaretDown size={20} /> : <FaCaretUp size={20} />}
  </div>
    </div>
    {/* Student list */}
    {isOpen && (
      <div className="educator-student-list">
        <div className="educator-right_side_container">
          <div className="educator-right_side_title">
            <h5>{listIds.filter(element => selectedIds.includes(element)).length} Selected</h5>
            {/* <p>
              {" "}
              <Link to="/educator/student-list">See All</Link>{" "}
            </p> */}
            <p>{students?.length} Students</p>
          </div>
          <div style={{ width: "100%" }} className='list_students_parent'>
            <div className="row align-items-center">
              <div className="col-1">
                <div className="form-check d-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={students?.map(item=> item?.id).every(element => selectedIds?.includes(element))}
                    onChange={(e) => toggleCheckbox(students, e.target.checked)}
                  />
                </div>
              </div>
              <div className="col-6">
                <strong>Student Name</strong>
              </div>
              <div className="col-5">
                <strong>Course</strong>
              </div>
            </div>
           
            <div className='list_students_scroll'>
              {students.map((student, index) => {
                return (
                  <div className="row align-items-center list_students_scroll" key={index}>
                    <div className="col-1">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`flexCheckDefault_${index}`}
                          checked={selectedIds.includes(student?.id)}
                          onChange={() => toggleCheckbox(student)}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      {student.name || ""}
                    </div>
                    <div className="col-5">
                      {student.course || ""} {student?.semester ? `(${student?.semester} sem)` : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )}
</>  
)
}

export default StudentListAccordian