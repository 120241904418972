import React, { useReducer } from "react";
import DateField from "components/formComponents/DateField";
import TextArea from "components/formComponents/TextArea";
import TextInput from "components/formComponents/TextInput";
import { toast } from "react-toastify";
import "./addExperience.scss";

const AddExperience = ({ setExperiences, setShowAddExperience, editedValues, setEditedValues,experiences, index,setIndex }) => {
  const initialState = {
    company_name: editedValues?.company_name || "",
    description: editedValues?.description ||  "",
    start_date: editedValues?.start_date || "",
    end_date: editedValues?.end_date || "",
    designation: editedValues?.designation || "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  // console.log("inittitalstte", initialState)

  const onSubmit = () => {
    // let isValid = 0;
    // Object.keys(state).forEach((key) => {
    //   if (state[key] === "") {
    //     toast.error(`${key.split("_").join(" ")} is required`, {
    //       delay: 300 * isValid,
    //       toastId: key,
    //     });
    //     isValid++;
    //   }
    // });
    // if (isValid > 0) return;
    // setExperiences((p) => [...p, state]);

    // console.log("indexxxxxxx", state)

    if ( index !== null) {
      setExperiences((p) =>
        p.map((item, i) => (i === index ? { ...item, ...state } : item))
      );
      // console.log("in if block",state )

    } else {
      // Add a new experience to the array
      setExperiences((p) => [...p, state]);
      // console.log("in  else block")
    }


    dispatch({ type: "reset" });
    setShowAddExperience(false);
    setEditedValues({});
    setIndex(null)
  };

  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };

  const dateBefore100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100)
  )
    .toISOString()
    .split("T")[0];

  const dateAfter100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() + 100)
  )
    .toISOString()
    .split("T")[0];

  const today = new Date().toISOString().split("T")[0];

  const startdatefield = initialState?.start_date
  ? new Date(initialState?.start_date).toISOString().split("T")[0]
  : "";
  const enddatefield = initialState?.end_date
  ? new Date(initialState?.end_date).toISOString().split("T")[0]
  : "";


  const handleCancle = async ()=> {
    setShowAddExperience(false)
    setEditedValues({})
    setIndex(null);
  }

  return (
    <div className="add_experiences row">
      <div className="add_experience_main buttons add_btn">
        <p className="add_btn_p fs-6 fs-md-5 fs-lg-4">Add Experience</p>
        <div>
          <button
            className="btn drop_btn_style  btn-outline-primary  m-2 "
            onClick={handleCancle}
          >
            Cancel
          </button>

          <button
            className="btn drop_btn_style btn-primary m-2 text-white"
            onClick={() => onSubmit()}
          >
            Save
          </button>
        </div>
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Company Name"
          id="company_name"
          handleChange={onChange}
          defaultValue={initialState?.company_name}
        />
        {/* <TextField
          label="Company Name"
          id="place_name"
          placeholder="X Company"
          register={register}
          isPopupOpen={isPopupOpen}
          setValue={setValue}
          updatePopup={updatePopup}
          method="company"
        /> */}
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Designation"
          id="designation"
          handleChange={onChange}
          defaultValue={initialState?.designation}
        />
      </div>
      <div className="add_experience col-12">
        <TextArea
          label="Job Description"
          id="description"
          handleChange={onChange}
          defaultValue={initialState?.description}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <DateField
          label="Start Date"
          id="start_date"
          handleChange={onChange}
          minDate={dateBefore100Years}
          maxDate={today}
          dateValue={startdatefield}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <DateField
          label="End Date"
          id="end_date"
          handleChange={onChange}
          minDate={dateBefore100Years}
          maxDate={dateAfter100Years}
          dateValue={enddatefield}
        />
      </div>
    </div>
  );
};

export default AddExperience;
