import Datatable from "components/datatable-new/DatatableNew";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./mentorlist.scss";
//Just for check

const MentorList = ({ data }) => {
  const [studentData, setStudentData] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState();

  // useEffect(() => {
  //   axios.post("/v1//mentor/getSessions")
  //     .then(response => {
  //       setStudentData(response?.data?.data);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }, []);

  // const StatusChange = async (id, status) => {
  //   // if (status < 1 || status > 2) return toast.error("Invalid status")
  //   const { error, message } = await axios.put(`/v1/mentor/updateSessionStatus/${id}`,null, {
  //     params: {
  //       status: status,
  //     }
  //   })
  //   toast.success("ReSheduled Session")
  //   if (error) {
  //     toast.error(message)
  //   } else {
  //     // toast.success("reShedule")
  //   }
  //   // handleBlur()
  // }
// console.log("dataaa", data)
  const columns = [
    {
      Header: "Date Assigned",
      accessor: "date",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row?.original?.date}
          </strong>
        );
      },
    },
    {
      Header: "Student Name",
      accessor: "title",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row?.original?.title}
          </strong>
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "ProjectTitle",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong
            style={{
              fontWeight: "700",
              color: "#6610f2",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.row.original.ProjectTitle}
          </strong>
        );
      },
    },
    {
      Header: "Due date",
      accessor: "dueDate",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row?.original?.dueDate}
          </strong>
        );
      },
    },
    {
      Header: "Rating",
      accessor: "rated",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row?.original?.rated}
          </strong>
        );
      },
    },

    {
      Header: "Action",
      accessor: "status",
      width: "13.5%",
      textalign: "center",
      Cell: (data) => {
        // // console.log(data?.row);
        return (
          <Link
            className={`bg-success rounded-3 text-white px-5 py-2 ${
              data?.row?.original?.mentor_evaluated_date ? "disabled" : ""
            }`}
            to={
              !data?.row?.original?.mentor_evaluated_date &&
              `/mentor/project-evaluation-detail/${data?.row?.original?.id}`
            }
          >
            {data?.row?.original?.mentor_evaluated_date ? "Rated" : "Rate"}
          </Link>
        );
      },
    },
    // {
    //   Header: " ",
    //   width: "1%",
    //   sortable: false,
    //   Cell: (data) => {
    //     return (
    //       <select>

    //       </select>
    //     )
    //   },
    // },
  ];
  const map = {
    1: "Draft",
    2: "Published",
    3: "Closed",
  };
  const [search, setSearch] = useState(() => "");

  // // console.log(data, "data");
  const rows = data
    ?.filter((post) => {
      return post?.student?.first_name
        ?.toLowerCase()
        ?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      // // console.log(post, "post");
      return {
        id: post?.id,
        date: moment(post?.createdAt).tz("Asia/Kolkata").format("DD-MM-YYYY"),
        title: (
          <Link to={`/mentor/student-detail/${post?.id}`} key={post?.title}>
            {" "}
            {post?.student?.first_name} {post?.student?.last_name}
          </Link>
        ),
        ProjectTitle:
          post?.enrollment?.project?.project_title || "Project name...",
        dueDate: moment(post?.requested_date)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
        mentor_evaluated_date: post?.mentor_evaluated_date,
        rated: post?.overall_rating || 0,
        status: (
          // <StatusButton status={map[post?.status]} key={map[post?.status]} />
          <button>Completed</button>
        ),
      };
    });

  // // console.log(studentData, "setData", rows);
  return (
    <div className="mentor-list">
      <div className="table-content">
        <div className="row">
          <div className="col-12">
            <div className="slidepage">
              {/* <h2>Available Students for Mentoring</h2> */}
              {/* <div>
                <i className="fa-solid fa-arrow-left"></i>
                <span className="fw-bold">1 </span>
                <span>/20</span>
                <i className="fa-solid fa-arrow-right"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="student-available-table">
            <div className="table-responsive">
              <Datatable columns={columns} data={rows} />
              <div className="d-block d-sm-none">
                {/* <MobileDataTable columns={columns} data={rows} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorList;
