import React, { Suspense } from "react";
import Loading from "./Loading";
import { ToastContainer } from "react-toastify";

import "components/typography/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/RouteMap";

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer theme="colored" />
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
