import React from 'react'
// import "./notification.scss";
import MentorProfileCard from 'components/student/MentorProfileCard';

const MentorHome = () => {

  return (
    <MentorProfileCard/>
  )
}

export default MentorHome