import React from "react";
import './Ploicy.scss'
const MentorsPolicy = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center text-black">Policy and Guidelines for Mentors</h1>

          
          <div className="row">
              <div className="col-lg-8 offset-lg-2">
              <h2>Policy:</h2>
      <ol>
        <li>
          <strong>Purpose:</strong> The purpose of this policy is to provide clear guidelines for mentors who
          participate in the Qollabb platform. Mentors play a crucial role in guiding and supporting
          mentees, and it is essential to maintain professionalism, integrity, and ethical conduct
          throughout the mentoring relationship.
        </li>
        <li>
          <strong>Qualifications and Selection:</strong> Qollabb selects mentors based on their expertise, experience,
          and ability to provide valuable guidance to mentees. Mentors are chosen through a rigorous
          screening process, which includes evaluating their credentials, professional background, and
          commitment to the mentoring program's goals and values.
        </li>
        <li>
          <strong>Confidentiality:</strong> Mentors must respect and maintain the confidentiality of all information
          shared by mentees during the mentoring relationship. This includes but is not limited to
          personal information, sensitive business data, and any discussions or materials shared within
          the Qollabb platform. Mentors should not disclose or use this information for personal gain
          or any other purpose outside the mentoring relationship.
        </li>
        <li>
          <strong>Professionalism and Ethical Conduct:</strong> Mentors are expected to always conduct themselves
          professionally and ethically, both within and outside the Qollabb platform. This includes
          treating mentees with respect, fairness, and impartiality, regardless of their background,
          gender, race, or any other characteristic. Mentors should provide guidance and feedback in
          a constructive and supportive manner, focusing on the mentees' growth and development.
        </li>
        <li>
          <strong>Commitment and Availability:</strong> Mentors should commit to regular and meaningful
          engagement with their mentees on the Qollabb platform. This includes setting realistic
          expectations regarding availability and responsiveness. Mentors should strive to be
          accessible, responsive, and timely in their communications and interactions with mentees. If
          any circumstances prevent a mentor from fulfilling their commitments, they should
          communicate promptly and transparently with both the mentee and Qollabb administrators.
        </li>
        <li>
          <strong>Boundaries and Limitations:</strong> Mentors should be aware of their own expertise and limitations.
          It is important to provide guidance within their area of expertise and refrain from offering
          advice or guidance beyond their knowledge. If a mentee seeks assistance outside the
          mentor's expertise, the mentor should guide the mentee to appropriate resources or
          professionals.
        </li>
      </ol>

      <h2>Guidelines:</h2>
      <ol>
        <li>
          <strong>Platform Usage:</strong>
          <ul>
            <li>Mentors should familiarize themselves with the features, functionalities, and guidelines of
              the Qollabb platform. This includes understanding how to effectively navigate the platform,
              communicate with mentees, and utilize the available tools and resources.</li>
            <li>Mentors should actively engage with mentees within the Qollabb platform, utilizing
              messaging, video calls, document sharing, and other platform features to facilitate
              meaningful interactions and knowledge exchange.</li>
            <li>Mentors should adhere to the Qollabb platform's terms of service and community
              guidelines, promoting a positive and inclusive environment for all users.</li>
          </ul>
        </li>
        <li>
          <strong>Establishing Expectations:</strong>
          <ul>
            <li>At the beginning of the mentoring relationship on Qollabb, mentors should communicate
              and establish clear expectations regarding the objectives, scope, and frequency of
              interactions with their mentees. This ensures both parties are aligned and have a shared
              understanding of the mentoring process.</li>
            <li>Mentors should outline the goals, milestones, and desired outcomes of the mentoring
              relationship, providing mentees with a roadmap for their professional development.</li>
          </ul>
        </li>
        <li>
          <strong>Active Listening and Empathy:</strong>
          <ul>
            <li>Mentors should actively listen to their mentees on the Qollabb platform, understanding
              their perspectives, challenges, and goals. They should demonstrate empathy and provide
              emotional support, helping mentees navigate their professional journey effectively.</li>
            <li>Mentors should encourage mentees to share their thoughts, concerns, and aspirations
              openly within the platform, fostering a safe and supportive space for discussion.</li>
          </ul>
        </li>
        <li>
          <strong>Guidance and Feedback:</strong>
          <ul>
            <li>Mentors should provide constructive guidance and feedback to mentees on the Qollabb
              platform, based on their experience and expertise. Feedback should be specific, actionable,
              and focused on the mentee's professional growth. Mentors should encourage mentees to
              reflect on their experiences, set goals, and develop strategies to overcome challenges.</li>
            <li>Mentors should utilize the available tools and resources on the Qollabb platform to
              deliver feedback effectively, such as document annotations, video calls, or shared project
              management tools.</li>
          </ul>
        </li>
        <li>
          <strong>Mentoring Boundaries:</strong>
          <ul>
            <li>Mentors should maintain appropriate boundaries in their interactions with mentees on
              the Qollabb platform, ensuring a professional and respectful relationship. They should avoid
              engaging in any form of harassment, discrimination, or inappropriate behavior. If any
              concerns or issues arise, mentors should report them to Qollabb administrators promptly.</li>
            <li>Mentors should refrain from sharing personal contact information or engaging in
              communication outside the Qollabb platform unless it is necessary and approved by Qollabb
              administrators.</li>
          </ul>
        </li>
        <li>
          <strong>Continuous Learning:</strong>
          <ul>
            <li>Mentors should embrace a mindset of continuous learning and professional development.
              They should stay updated on industry trends, best practices, and relevant knowledge to
              provide valuable guidance to their mentees. Mentors are encouraged to seek feedback and
              actively participate in training or development opportunities provided by Qollabb.</li>
            <li>Mentors should leverage the Qollabb platform's resources, such as webinars, articles, and
              knowledge-sharing communities, to enhance their own expertise and contribute to mentees'
              growth.</li>
          </ul>
        </li>
        <li>
          <strong>Ending the Mentoring Relationship:</strong>
          <ul>
            <li>When the mentoring relationship comes to an end on the Qollabb platform, mentors
              should facilitate a smooth transition and provide closure to the mentees. They should
              summarize the progress made, discuss final goals achieved, and encourage mentees to
              continue their growth journey independently.</li>
            <li>Mentors should ensure mentees have access to relevant resources, networks, or referrals
              that can support their ongoing professional development beyond the mentoring
              relationship.</li>
          </ul>
        </li>
      </ol>

      <p>
        Failure to comply with these policies and guidelines may result in disciplinary action, including
        termination of the mentor's role within the Qollabb platform.
      </p>

      <p>
        By adhering to these policies and guidelines, mentors contribute to creating a supportive and
        enriching mentoring environment on the Qollabb platform, fostering mentees' personal and
        professional development.
                  </p>
                  </div>
          </div>
          </div>
          
  );
};

export default MentorsPolicy;
