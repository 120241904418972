import axios from "axios";
import Accordian from "components/accordian";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "services/actions/auth";
import MentorDetails from "./MentorDetails";
import MentorExperience from "./MentorExperience";
import "./mentorProfile.scss";
import Loader from "components/employer/Loader";

const Submit = ({ onClick = () => {} }) => {
  return (
    <button
      className="save_changes_btn btn btn-primary mx-4 text-white "
      type="submit"
      onClick={(e) => {
        e?.stopPropagation();
        onClick();
      }}
    >
      Save Changes
    </button>
  );
};
const MentorProfile = () => {
  const [image, setImage] = useState(() => null);
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(false);

  const[skills,setSkills]=useState([])

  const navigate = useNavigate();
 console.log("mentorstateeee",user)
  // // console.log(experiences,"hello world")
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      languages: user?.languages || [],
      industry_sector: user?.industry?.industry_name || "",
      email_address: user.email_address,
      bio: user?.bio || "",
      phone_number: user?.phone_number || "",
      gender: user?.gender || "",
      linkedIn_link: user?.linkedIn_link || "",
      github_link: user?.github_link || "",
      profile_link: user?.profile_link || "",
      profile_photo: user?.profile_photo || "",
      industry_year_expreience: user?.industry_year_expreience || "",
      location: user?.location || "",
      required_skills: skills || [],
    }
  });

  const [experiences, setExperiences] = useState(user?.experience || []);
  // phone number string problem
// console.log("skills",skills)
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const existingSkillNames = user?.required_skills?.map(skill => skill.name.toLowerCase()) || [];
      const skillsToUpdate = skills && skills.length > 0 ? skills?.map(skill => ({ name: skill.name.toLowerCase() })) : null;
      console.log("skillsToUpdate",skillsToUpdate)

      if (image && image?.size > 1000000) {
        return toast.error("Image size should be less than 1MB");
      }

      let payload = {};


      if (skillsToUpdate || existingSkillNames) {
        // Check for duplicate skill names
        console.log("in the console",skillsToUpdate,existingSkillNames)
       
        const newSkillNames = skillsToUpdate ? skillsToUpdate.map(skill => skill.name) : [];
        const allSkillNames = [...existingSkillNames, ...newSkillNames];
        const isDuplicateSkill = allSkillNames.some((name, index) => allSkillNames.indexOf(name) !== index);
  
        if (isDuplicateSkill) {
          toast.error("Duplicate skill found. Please select unique skills.");
          return;
        }
        console.log("allSkillNames",allSkillNames)
   
        data.required_skills = allSkillNames;
      }
        
      console.log("data",data)
  
      

      if (image) {
        const formData = new FormData();
        formData.append("profile_photo", image);
        formData.append("prev_photo", user?.profile_photo);
        const { data: imageData } = await axios.post(
          "/v1/mentor/profile/image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!imageData?.data?.url) return toast.error("Image upload failed");
        payload.profile_photo = imageData?.data?.url || "";
        data.profile_photo = payload.profile_photo || "";
      }
      // // console.log(data, "formData");
      data.experiences = experiences?.filter((i) => !i?.id || []) ;
      data.industry_sector = data?.industry?.id;
      const { data: profileData } = await axios.post(
        "/v1/mentor/profile",
        data
      );
      if (profileData?.data) {
        // payload = { ...profileData?.data?.userData } || {};
        payload.experience = profileData?.data?.experience || [];
        const getskills=profileData?.data?.skills || [];
        payload =
          {
            ...payload,
            ...user,
            ...profileData?.data?.userData,

          } || {};
          payload.required_skills=getskills
          console.log("payload",payload)

          toast.success("Profile updated successfully");
          setLoading(false);

          dispatch(updateUser(payload));
       
          navigate("/mentor/dashboard");
          
     
      } else {
        toast.error("Profile update failed");
      }


    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if(loading) return(<Loader/>)

  return (
    <div
      className="student-profile p-0 p-sm-4"
      // onSubmit={handleSubmit(onSubmit)}
    >
      <Accordian
        title="PERSONAL DETAILS"
        defaultOpen={true}
        // Component={<Submit />}
        Component={<Submit onClick={handleSubmit(onSubmit)} />}

        className="card"
      >
        <MentorDetails
          setImage={setImage}
          register={register}
          setValue={setValue}
          getValues={getValues}
          setSkills={setSkills} 
          skills={skills}
          loading={loading}
        />
      </Accordian>

      <Accordian title="EXPERIENCE" className="card">
        <MentorExperience
         experiences={experiences}
         setExperiences={setExperiences}
        />
      </Accordian>
      {loading && (
        <div className="loading-container">
          <div className="text-center loading-wrapper">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MentorProfile;
