import React from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import GoToTop from "GoToTop";
import Footer from "view/dashboard/student/footer/Footer";
// import ExplorePage from "./ExplorePage";
import { Outlet } from "react-router-dom";

const ExploreHomePageNew = () => {
  return (
    <section className="home_page justify-content-center align-items-center mx-0 w-100 ">
      <TopNavBar />
      <NavBar />
      <div style={{
        // width: "89%",
      }}>
        <Outlet /> 
      </div>
      <Footer />
      <GoToTop />
    </section>
  );
};

export default ExploreHomePageNew;
