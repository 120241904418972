import React from 'react';
import './featureSection.scss'
import img from '../../images/fact.png'
import OnliceC from "../../images/online_course.svg"
import imgC from '../../images/certificates.svg'
import laptop from '../../images/laptop.svg'

const FeatureSection = () => {
    return (
        <div className="features-area pt-45 pb-15" style={{ backgroundImage: `${img}`,backgroundColor:'blue' }}>
            <div className="container">
                <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                <img src={laptop} alt="Image" />
                            </div>
                            <div className="features-content">
                                <h3>Opportunities from best companies</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                <img src={imgC} alt="Image" />
                            </div>
                            <div className="features-content">
                                <h3>Earn certificates of project completion</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="features-wrapper d-flex align-items-center mb-30">
                            <div className="features-icon">
                                {/* <i className="flaticon-online-course"></i> */}
                                <img src={OnliceC} alt="not found" />
                            </div>
                            <div className="features-content">
                                <h3>Build your skills and get your dream job</h3>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default FeatureSection;