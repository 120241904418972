import { useEffect, useState } from 'react';

const useCountdown = (targetTime) => {
  const [countDown, setCountDown] = useState(() => targetTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prev) => prev >= 0 ? prev - 1 : 0);
    }, 1000);

    if (countDown === 0) clearInterval(interval);

    return () => clearInterval(interval);
  }, [countDown]);

  const toggle = () => {
    setCountDown(targetTime);
  };

  return [countDown, toggle];
};


export { useCountdown };
