const NewLoader = () => {
    return (
      <div className="d-flex justify-content-center align-items-center mt-2 p-3">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  
  export default NewLoader;
  