import React, { memo, useEffect, useState } from 'react'
import { MdCurrencyRupee } from 'react-icons/md'
import { PiBookBookmarkFill } from 'react-icons/pi'
import { FaCircle } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import "./mentorProfile.scss";
import MentorDetailPopup from './mentorDetail/MentorDetailPopup';
import mentorImg from "../../../../images/image 47.png";

const MemoizedFaCircle = memo(FaCircle);
const MemoizedAiFillStar = memo(AiFillStar);
const MemoizedMdCurrencyRupee = memo(MdCurrencyRupee);
const MemoizedPiBookBookmarkFill = memo(PiBookBookmarkFill);


const MentorProfile = ({data}) => {

    const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

    const [selectedDate, setSelectedDate] = useState();
    const [selectedDay, setSelectedDay] = useState(days[0]);
    const [timeZones, setTimeZones] = useState(null);

    const [mentorPopup, setMentorPopup] = useState(false)

    const closeMentorPopup = ()=> {
      setMentorPopup(false)
    }
    console.log("dataaaaaa", data);

    useEffect(() => {
        const defaultDay = days[0];
        const selectedTimezones = data?.timings?.find((item) => item?.day === defaultDay);
        const timeslots = convertTimeSlotsToDateSlots(new Date(), selectedTimezones?.duration);
        setTimeZones(timeslots);
      }, [data]);

    const handleDayClick = (day) => {
        setSelectedDay(day);
        const selectedTimezones = data?.timings?.find((item) => item?.day === day);
        const timeslots = convertTimeSlotsToDateSlots(new Date(), selectedTimezones?.duration);
        setTimeZones(timeslots);
    };


    const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const dayOfWeek = daysOfWeek[date.getDay()]; // get the day of the week in string format
        setSelectedDate(date.toISOString().split("T")[0]);
        const selectedTimezones = data.timings.find(
          (item) => item?.day === dayOfWeek.toLocaleUpperCase()
        );
        // // console.log(selectedTimezones);
        const timeslots = convertTimeSlotsToDateSlots(
          date,
          selectedTimezones?.duration
        );
        // // console.log(timeslots, "timeslots");
        setTimeZones(timeslots);
      };

      function convertTimeSlotsToDateSlots(date, timeSlots) {
        const dateObj = new Date(date);
        const dateSlots = {};
    
        timeSlots?.forEach((timeSlot) => {
          const [startTimeStr, endTimeStr] = timeSlot.split("-");
          const startTime = new Date(dateObj.toDateString() + " " + startTimeStr);
          const endTime = new Date(dateObj.toDateString() + " " + endTimeStr);
          const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); // duration in minutes
    
          let slotStartTime = startTime;
          while (slotStartTime < endTime) {
            const slotEndTime = new Date(slotStartTime.getTime() + 30 * 60 * 1000); // add 30 minutes
            const slotKey = `${slotStartTime.getHours()}:${slotStartTime.getMinutes()}-${slotEndTime.getHours()}:${slotEndTime.getMinutes()}`;
            dateSlots[slotKey] = { startTime: slotStartTime, endTime: slotEndTime };
            slotStartTime = slotEndTime;
          }
        });
    
        return dateSlots;
      }


  return (
    <div className='top_wrapper'>
        <div className='m_profile_wrapper container'>
            <h4>Mentor Profile</h4>

            <div className="m_container">

                {/* mentor left section */}
                <div className="m_left_section">

                    {/* mentor profile main card */}
                    <div className="m_profile_card">

                        {/* mentor profile details */}
                        <div className="profile_section">
                            <div className="profile_left">
                                <img src={data?.profile_photo || mentorImg} alt="mentor profile" />

                                <div className="left_details">
                                    <div className="name_section">
                                        <h4>{data?.first_name} {data?.last_name} </h4>
                                        <p>{data?.location?.split(", ").length >= 2 ? `${data?.location?.split(', ')[0]}, ${data?.location?.split(', ')[1]}` : "" } </p>
                                    </div>

                                    <div className="industry_section">
                                        <span><MemoizedPiBookBookmarkFill /></span> {data?.industry_year_expreience ? parseInt(data?.industry_year_expreience) : "-"} Yrs+ Experience in <span className='industry_active'>{data?.industry?.industry_name || "-"}</span> 
                                    </div>


                                    {/* mob pricing */}
                                    <div className="mob_m_pricing">
                                        <span><MemoizedMdCurrencyRupee /></span>{data?.mentor_charges ?? "-"} /Session
                                    </div>
                                </div>
                            </div>
                            <div className="profile_right">
                                <span><MemoizedMdCurrencyRupee /></span>{data?.mentor_charges ?? "-"} /Session
                            </div>
                        </div>

                        {/* mob industry sector */}
                        <div className="mob_industry_section">
                             <span><MemoizedPiBookBookmarkFill /></span> {data?.industry_year_expreience ? parseInt(data?.industry_year_expreience) : "-"} Yrs+ Experience in <span className='industry_active'>{data?.industry?.industry_name || "-"}</span> 
                        </div>


                        {/* about section */}
                        <div className="about_section">
                            <h4>About Mentor:</h4>
                            <p>{data?.bio || "No bio"}</p>
                        </div>

                        {/* Experience section */}
                        {
                            data?.experience?.length !== 0 &&
                                <div className="experience_section">
                                    <h4>Experiences:</h4>

                                    <div className='exp_wrapper'>
                                        {data?.experience?.map((item)=> (
                                            <div className='m_experience' key={item?.id}>
                                                <div className="circle_">
                                                    <span><MemoizedFaCircle color='#47D065' width={10}/> <span className='line_'></span> </span> 
                                                </div>

                                                <div className="exp_details">
                                                    <div className="position">
                                                        <span>Software Developer</span> <div className='verticle_line'></div> <span className="exp_time"> May, 2023 - Aug, 2024</span>
                                                    </div>

                                                    <div className="company_">
                                                        Tech Nova pvt. ltd.
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        }

                        {/* Languages section */}
                        {
                           ( data?.languages !==  null ) && 
                                <div className="language_section">
                                    <h4>Languages:</h4>
                                    <div className='m_languages'> 
                                        {
                                            data?.languages?.map((item, i) => (
                                                <div className="lang" key={i}>
                                                    {item}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                        }


                        {/* skills section */}
                        {
                            ( data?.skills !== null) &&  
                                <div className="skills_section">
                                    <h4>Skills:</h4>
                                    <div className='m_skills'>
                                        {
                                            data?.skills?.map((item, i) => (
                                                <div className="skill" key={i}>
                                                    #{item}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                        }

                    </div>



                </div>


                

                {/* mentor right section */}
                <div className="m_right_section">

                    {/* book session card */}
                    <div className="book_session_card">
                        <div className="stars">
                            {
                                [1,2,3,4,5].map(item => ( 
                                    <MemoizedAiFillStar key={item} color='#F9F364' size={20} />
                                ))
                            }
                        </div>

                        <div className="text">
                            Connect with leading industry expert for personalized mentorship sessions
                        </div>

                        <div className="book_btn">
                            <button onClick={()=> setMentorPopup(true)} >Book A Session Now</button>
                        </div>


                        {
                            mentorPopup && 
                                <MentorDetailPopup selectedMentor={data} onClose={closeMentorPopup} />
                        }
                    </div>

                    {/* mentor availability card */}
                    <div className="m_availability_card">
                        <h4>Mentor Availability</h4>
                        <div className="divider"></div>

                        <div className="m_availablity">
                            <div className="avail_days">
                                {
                                    days?.map((day, i)=> (
                                        <div className={`circle ${selectedDay === day ? 'selected' : ''}`}
                                        onClick={() => handleDayClick(day)} key={i}>
                                            {day.charAt(0)}
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="slots_section">
                                <h4>Slots:</h4>
                                {
                                    selectedDay &&
                                        <div className="slots">
                                                { timeZones !== null &&  !Object.keys(timeZones).length == 0 ? (
                                                    <>
                                                        {Object.keys(timeZones).map((key) => {
                                                        return (
                                                            <div key={key} className={`time_slot selected_slot`}>
                                                                <span
                                                                    className="slot"
                                                                    // onClick={() => handleKeyClick(key)}
                                                                >
                                                                    {key}
                                                                </span>
                                                            </div>
                                                        );
                                                        })}
                                                    </>
                                                    ) : (
                                                    <p>Mentor is not available on this day</p>
                                                )}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
  )
}

export default MentorProfile