import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import Datatable from "components/datatable-new/DatatableNew";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../index.scss";
import "../style.scss";
import AdminDashCard from "../table-card";
import MobileViewCollegeTable from "./MobileViewCollegeTable";
const AdminDashColleges = ({ data, counts, setActivate,date,setdates }) => {
  const [search, setSearch] = useState(() => "");

  // const sortedData = data.sort(
  //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  // );

  // console.log("collegedata",data)
 

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [temporaryDates, setTemporaryDates] = useState([date[0]]);

  const handleToggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // const handleDateChange = (ranges) => {
  //   setdates([ranges.selection])

  //   setCalendarVisible(false);
  
  // };

  const handleDateChange = (ranges) => {
    setTemporaryDates([ranges.selection]);
  };

  const handleApply = () => {

    setdates([...temporaryDates]);
    setCalendarVisible(false);
  };




  const datePickerRef= useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        // Clicked outside the date picker, close the picker
        setCalendarVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const collegeData=data.map((item)=>item.Total_studentCount)
  const totalStudent = collegeData.reduce((acc, value) => acc + parseInt(value, 10), 0);
  var avgStudents = Math.floor(totalStudent / data.length);
  // console.log("total",avgStudents)


  const studentCardData=[
    {
      primaryText: 'Average Students',
      secondaryText: `+${avgStudents ? avgStudents : "0"}`,
      value: `${avgStudents? avgStudents : "0"}`,
      date: `${data.map((item) => item?.createdAt)}`,
    
    },
  ]
 
 
  const cardsData = [
    {
      primaryText: 'Institutions',
      secondaryText: `+${data.length}`,
      value: `${data.length}`,
      date: `${data.map((item) => item?.createdAt)}`,
     
    
    },

    
  ];

   // Separate the date values
   const dateStrings = cardsData[0].date.split(',');
   // console.log("datestring",dateStrings)
   // Create an object to store the total companies added for each day
  const dayCounts = {};
  
  // Process each date string and count the number of companies added on that day
  dateStrings.forEach((dateStr) => {
    const date = new Date(dateStr);
    // const fullDate = date.toISOString().split('T')[0]; // Extract the day part
    const fullDate = date.toLocaleDateString(undefined,{ month: 'short', day: 'numeric',year:'numeric' })
    // .split('T')[0]; // Extract the day part
    // // console.log("fuldate",fullDate)
    // const day = fullDate.split('/')[0]; // Extract the day part
    const day = fullDate // Extract the day part

    // console.log("dayyyy",day)

    if (dayCounts[day]) {
      dayCounts[day]++;
    } else {
      dayCounts[day] = 1;
    }
  });

   // Create an array with objects for the chart data
   const formattedData = Object.keys(dayCounts).map((day) => {
    // return {
    //   day,
    //   total: dayCounts[day],
    // };
    const total = dayCounts[day];
    const isValidDate = !isNaN(Date.parse(day));
  
    return {
      day: isValidDate ? day : 'Invalid Date',
      total: isValidDate ? total : 0,
    };
  });
 // Sort the formattedData array by date in ascending order
//  formattedData.sort((a, b) => parseInt(a.day, 10) - parseInt(b.day, 10));
 formattedData.sort((a, b) => new Date(a.day) - new Date(b.day));
  // console.log("formattedData", formattedData);

  

 
const instituteDataset = {
  label: 'colleges',
  data: formattedData.map((item)=>{return item.total}),
  borderColor: '#6149CD',
      backgroundColor: '#6149CD',
};


// Sort the data by date
// data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
// const uniqueDatesSet = new Set(); // Set to track unique dates

const formattedStudentData = Object.keys(dayCounts).map((day) => {
  // uniqueDatesSet.add(day)
  // return {
  //   day,
  //   total: dayCounts[day],
  // };
  const total = dayCounts[day];
  const isValidDate = !isNaN(Date.parse(day));

  return {
    day: isValidDate ? day : 'Invalid Date',
    total: isValidDate ? total : 0,
  };
});
// const uniqueDatesArray = Array.from(uniqueDatesSet); // Convert set to array for consistent order
// uniqueDatesArray.sort((a, b) => new Date(a) - new Date(b)); // Sort unique dates

formattedStudentData.sort((a, b) => new Date(a.day) - new Date(b.day));

// console.log("studentsdata2", formattedStudentData);



const totalStudentsByDate = {};

data.forEach((item) => {

  const date = new Date(item.createdAt).toLocaleDateString(undefined,{ month: 'short', day: 'numeric',year:'numeric' })
  // .split('/')[0];

  if (!totalStudentsByDate[date]) {
    totalStudentsByDate[date] = 0;
  }

  totalStudentsByDate[date] += parseInt(item.Total_studentCount);
});
// console.log("sum",totalStudentsByDate)

const AverageStudentDataset={
  label:'TotalStudents',
   data: formattedStudentData.map((item) => {
    const date = item.day
    

    return totalStudentsByDate[date] || 0;
  }),
 
  // data: data.map((item)=>{return item.Total_studentCount}),
  borderColor: '#6149CD',
      backgroundColor: '#6149CD',
}



// console.log("datedefault",date[0].startDate)
const currentdate=date[0].startDate
const defaultdate=new Date(currentdate)




const studentChartData={
  // labels: formattedStudentData.map((item)=> {
  //   return item.day
  //  }),
  //  datasets: [AverageStudentDataset]
  labels: formattedStudentData.map((item)=> {
    const date = item && item.day ? new Date(item.day) : new Date(defaultdate);

// Check if the date is invalid
if (isNaN(date.getTime())) {
  // If invalid, replace with today's date
  return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
}

return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }),
  datasets: [AverageStudentDataset ]

}

 const chartData = {
  
  // labels: formattedData.map((item)=> {
  //  return item.day
  // }),
  // datasets: [instituteDataset]

  labels: formattedData.map((item)=> {
    const date = item && item.day ? new Date(item.day) : new Date(defaultdate);

// Check if the date is invalid
if (isNaN(date.getTime())) {
  // If invalid, replace with today's date
  return new Date(defaultdate).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
}

return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }),
  datasets: [instituteDataset ]


};



const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display:false // Change 'linear' to 'bottom' or 'top'
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'day', // X-axis title
      },
    },
    y: {
      title: {
        display: true,
        text: 'institutions', // Y-axis title
        
      },
      beginAtZero: true, // Start the y-axis from 0
      ticks: {
        stepSize: 2,
      },
    },
  },
};

const studentOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display:false // Change 'linear' to 'bottom' or 'top'
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'day', // X-axis title
      },
    },
    y: {
      title: {
        display: true,
        text: 'students', // Y-axis title
        
      },
      beginAtZero: true, // Start the y-axis from 0
      ticks: {
        stepSize: 2,},}  },
};



  const handleAccountActivation = async (id) => {
    setActivate((prev) => !prev)
    const data = await axios.delete(`/v1/educator/educator/${id}`)
    // // console.log(data, "data");
    if (!data.data?.error) {
      toast.info(data?.data?.message)
    } else {
      toast.error(data?.data?.error)
    }
  }

  const columns = [
    {
      Header: "College Name",
      accessor: "college_name",
      width: "10%",
      textalign: "start",
      sortType: (rowA, rowB) => {
        return rowA?.original?.college_name?.toLowerCase() <
          rowB?.original?.college_name?.toLowerCase()
          ? -1
          : 1
      },
      Cell: (data) => {
        return (
          <div className="name-block">
            <h6>{data.row.original.college_name}</h6>
            <p>{data.row.original.address}</p>
          </div>
        )
      },
    },
    {
      Header: "Contact Person",
      accessor: "name",
      width: "20%",
      textalign: "center",
      sortType: (rowA, rowB) => {
        return rowA?.original?.name?.toLowerCase() <
          rowB?.original?.name?.toLowerCase()
          ? -1
          : 1
      },
      Cell: (data) => {
        return (
          <div className="name-block">
            <h6 className="text-colored">{data.row.original.name}</h6>
            <p>{data.row.original.designation}</p>
          </div>
        )
      },
    },
    {
      Header: "Contact details",
      accessor: "phone_number",
      width: "20%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.phone_number}
            <p>{data.row.original.email_address}</p>
          </strong>
        )
      },
    },
    {
      Header: "Signup Date",
      accessor: "signup_date",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-")
        const dateB = rowB?.original?.signup_date
          ?.split("/")
          ?.reverse()
          ?.join("-")
        if (dateA < dateB) {
          return -1
        } else if (dateA > dateB) {
          return 1
        } else {
          return 0
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.signup_date}
          </strong>
        )
      },
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-")
        const dateB = rowB?.original?.last_login
          ?.split("/")
          ?.reverse()
          ?.join("-")
        if (dateA < dateB) {
          return -1
        } else if (dateA > dateB) {
          return 1
        } else {
          return 0
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
       {moment(data?.row?.original?.last_login)?.format("DD/MM/YYYY")}
            <strong className="d-block">{data.row.original.last_time}</strong>
          </strong>
        )
      },
    },
    {
      Header: "Projects",
      accessor: "submitted",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.projects}
          </strong>
        )
      },
    },
    {
      Header: "Interships",
      accessor: "internships",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.internships}
          </strong>
        )
      },
    },
    {
      Header: "Jobs",
      accessor: "jobs",
      width: "6%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.jobs}
          </strong>
        )
      },
    },
    {
      Header: "Subscription Validity",
      accessor: "subscription_validity",
      width: "10%",
      textalign: "center",
      sortType: (rowA, rowB, columnId) => {
        const dateA = rowA?.original?.subscription_validity
          ?.split("/")
          ?.reverse()
          ?.join("-")
        const dateB = rowB?.original?.subscription_validity
          ?.split("/")
          ?.reverse()
          ?.join("-")
        if (dateA < dateB) {
          return -1
        } else if (dateA > dateB) {
          return 1
        } else {
          return 0
        }
      },
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.subscription_validity}
            <p>{data.row.original.planType}</p>
          </strong>
        )
      },
    },
    // {
    //   // Header: "Sector",
    //   Header: "Proffession",
    //   accessor: "sector",
    //   width: "10%",
    //   textalign: "center",
    //   Cell: (data) => {
    //     return (
    //       <strong style={{ fontWeight: "700" }}>
    //         {data.row.original.sector}
    //       </strong>
    //     );
    //   },
    // },
    {
      Header: "Action",
      accessor: "status",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <button
            onClick={() => handleAccountActivation(data.row.original.id)}
            className={`deactivate-btn ${
              data.row.original.deletedAt === "Activate"
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {data.row.original.deletedAt}
          </button>
        )
      },
    },
  ]

  // console.log("dataaa", data);
  
  const rows = data

    ?.filter((post) => {
      if (
        post?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        post?.email_address?.toLowerCase().includes(search.toLowerCase()) ||
        post?.phone_number?.toLowerCase()?.includes(search.toLowerCase()) ||
        post?.designation?.toLowerCase()?.includes(search.toLowerCase()) ||
        post?.institute_branch?.address
          ?.toLowerCase()
          ?.includes(search.toLowerCase()) ||
        post?.institute?.institution_name.toLowerCase()?.includes(search)
      ) {
        return post
      } else {
        return null
      }
    })
    ?.map((post) => {
      return {
        id: post?.id,
        // company_name: "Pinsout Innovation Pvt.Ltd.",
        college_name: post?.institute?.institution_name || "N/A",
        address: post?.location || post?.institute_branch?.address || "N/A",
        // company_location: "Noida, India",
        name: post?.first_name + " " + post?.last_name,
        phone_number: post?.phone_number || "N/A",
        email_address: post?.email_address || "N/A",
        signup_date: moment(post?.createdAt).format("DD/MM/YYYY"),
        last_login: post?.lastLogin,
        // last_login: moment(post?.lastLogin).format("DD/MM/YYYY"),
        last_time: moment(post?.lastLogin).tz("Asia/Kolkata").format("hh:mm A"),
        projects: parseInt(post?.project_count)  ,
        internships: parseInt(post?.internship_count) ,
        jobs: parseInt(post?.job_count),
        subscription_validity: post?.account?.subscription_subscriber
          ?.valid_upto
          ? moment(post?.account?.subscription_subscriber?.valid_upto).format(
              "DD/MM/YYYY"
            )
          : "N/A",
        planType:
          post?.account?.subscription_subscriber?.subscription_database
            .subscript,
        // sector: post?.designation,
        designation: post?.designation,
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
      }
    })

  // // console.log(rows);

  return (
    <div className="admin-dash-companies">
      <div className="row flex-nowrap overflow-auto graphcardgap">
        {cardsData.map((card, index) => {
          return (
            <div className="col-12 col-md-6" key={index}>
              <AdminDashCard
                img={card.imgUrl}
                data={chartData}
                option={options}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          );
        })}
         {studentCardData.map((card, index) => {
          return (
            <div className="col-12 col-md-6" key={index}>
              <AdminDashCard
           
                data={studentChartData}
                option={studentOptions}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          )
        })}
      </div>

      <div className="datatable-container mt-4">
        <div className="table_head ">
          <div className="mobo_upload">
            <h3 className="new-class-active">
              <div className="">
                <h3>Colleges</h3>
                <span className="light new-span-count ">
                  {data.length || 0} records{" "}
                  {
                    <div className="new-type opportunities" >
                      <div className="total-count1 opportunities" >
                        TotalAssignStudents -{" "}
                        {counts?.colleges?.totalAssignStudents}
                      </div>
                      <div className="total-count1 opportunities">
                        ProjectAssignStudents -{" "}
                        {counts?.colleges?.projectAssignStudents}
                      </div>
                      <div className="total-count1 opportunities">
                        internshipAssignStudents -{" "}
                        {counts?.colleges?.internshipAssignStudents}
                      </div>
                      <div className="total-count1 opportunities">
                        JobAssignStudents -{" "}
                        {counts?.colleges?.jobAssignStudents}
                      </div>
                      <div className="active opportunities">
                        Active - {counts?.colleges?.activeColleges}
                      </div>
                      <div className="inactive opportunities">
                        Inactive - {counts?.colleges?.inactive}
                      </div>
                    </div>
                  }
                </span>
              </div>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>

          </div>

           <div className="add_icon non row "ref={datePickerRef}>
            {/* date range */}
                <div className="col-md-5">
        <span className="light new-span-count" style={{color: '#7a86a1'}}>select date range</span>

      <div className="date-input-container">

        <input
        className="myinput"
        // style={{ width: 'auto',
        //   padding: '5px 10px',
        //   alignSelf: 'baseline',
        //   border: '1px solid #6149cd',
        //   borderRadius: '8px',
        // cursor:'pointer'}}
       
          type="text"
          // value={`${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`}
          value={
            date[0]?.startDate && date[0]?.endDate
              ? `${date[0].startDate.toLocaleDateString()} - ${date[0].endDate.toLocaleDateString()}`
              : 'Select Date Range'
          }
          onClick={handleToggleCalendar}
          readOnly
        />
         {calendarVisible && (
        <div className="mydiv" >
          {/* <DateRangePicker
            ranges={date}
            showPreview={false}
            onChange={handleDateChange}

            
          /> */}
          <DateRangePicker ranges={temporaryDates} showPreview={false} onChange={handleDateChange}
           />
           <div className=" applybtn-div">
           <button className="rdrApplyButton" onClick={handleApply}>Apply</button>
           </div>

        </div>
      )}
        
      </div>
     
        <style>
        {`
          /* Place the CSS here */
         
          .rdrStaticRangeLabel {
            color:#000000

          }
          


          /* Add the rest of the CSS styles here */
        `}
        </style>
                </div>
            {/*  End date Rage */}
            <div className="col-md-6 ">
              <div className="row tablerow">
                
                 <div className="col-md-2 col-6">
                <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>
                </div>
                <div className="col-md-6 col-6 tablerow add_icon non">
                <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
                </div>
               
              </div>
            

            </div>

           </div>

        </div>
        {/* <Datatable columns={columns} data={rows} /> */}

        
        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewCollegeTable columns={columns} data={rows} handleAccountActivation={handleAccountActivation}/>
      </div>


        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
          columns={columns}
          data={rows}
          pagination
          className="table-responsive"
        /> */}
      </div>
    </div>
  )
}

export default AdminDashColleges
