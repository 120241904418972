import React, { useState } from "react";
import TopButtons from "./TopButtons";
import Availability from "./availability/Availability";
import BillingAndPayments from "./billingAndPayments/BillingAndPayments";
import FeeAndCharges from "./feeAndCharges/FeeAndCharges";
import TransectionAndHistory from "./transectionAndHistory/TransectionAndHistory";
import "./settings.scss";
const MentorSettings = () => {
  const step = new URLSearchParams(window.location.search).get("step");

  const [value, setValue] = useState(step ? parseInt(step) : 0);
  // console.log(value);
  const tabs = [
    "Availabilty",
    " Billing & Payment",
    " Fee & Charges",
    "Transaction history",
  ];
  return (
    <div className="setting_main">
      <section className="Top_buttons">
        {tabs.map((items, index) => {
          return (
            <button
              key={index}
              className={`${index === value ? "active" : " disactive"}`}
              onClick={() => {
                setValue(index);
                window.history.replaceState(
                  {},
                  "",
                  `${window.location.pathname}?step=${index}`
                );
              }}
            >
              {items}
            </button>
          );
        })}
      </section>

      {value === 0 && <Availability />}
      {value === 1 && <BillingAndPayments />}
      {value === 2 && <FeeAndCharges />}
      {value === 3 && <TransectionAndHistory />}
    </div>
  );
};

export default MentorSettings;
