import React, { useState } from "react"
import SubmitButton from "../SubmitButton"
import "./inputOTP.scss"

const InputOTP = ({
  label,
  onInputSubmit,
  message = "",
  setOtpMessage = () => {},
  loading = false,
  setLoading,
}) => {
  const [verification] = useState(true)
  const [otp, setOTP] = useState([...Array(6).fill("")])
 

  const inputfocus = (element) => {
    // console.log(element)
    if (element.key === "Delete" || element.key === "Backspace") {
      const next = element.target.tabIndex - 2
      if (next > -1) element.target.form.elements[next].focus()
    } else {
      if (isNaN(element.key)) return
      const next = element.target.tabIndex
      if (next <= 5) element.target.form.elements[next].focus()
    }
  }

  const handleChange = (e, index) => {
    if (isNaN(e.target.value)) return
    setOTP((prev) => {
      const newArray = [...prev]
      newArray[index] = e.target.value
      return newArray
    })
  }
 

  const onSubmit = (e) => {
    e?.preventDefault()
    onInputSubmit(otp.join(""))
  }

  return (
    <>
      <div className="form-group ">
        <label className="mb-2" htmlFor="otp-input">
          {label}
        </label>

        <div
          id="otp-input"
          className={`otp-input-div d-flex align-items-center justify-content-center ${message}`}
        >
          {[...Array(6)].map((_, i) => (
            <input
              key={i}
              type="text"
              maxLength={1}
              value={otp[i]}
              className="form-control mx-2"
              tabIndex={i + 1}
              onKeyUp={(e) => inputfocus(e)}
              onChange={(e) => handleChange(e, i)}
              onAnimationEnd={() => {
                i === 0 && message === "error" && setOtpMessage("")
              }}
            />
          ))}
        </div>

        {verification === false && (
          <span className="wrong-otp-msg">Wrong OTP try again</span>
        )}
      </div>
      <SubmitButton
        value="Verify"
        onSubmit={onSubmit}
        isLoadingState={true}
        loading={loading}
        route="/signup"
        setLoading={setLoading}
      />
    </>
  )
}

export default InputOTP
