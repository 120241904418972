import React from 'react';
import { RiShareBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import "./rightcard.scss";

const RightCard = ({title, highlightedText, data, type, scores = [], companyId = null, seeall = false}) => {
    const user = useSelector((state)=> state?.auth?.user);

    // console.log("orderss", scores);

    // const [state, dispatch] = useReducer(reducer, {
    //     ...initialState,
    // });

    // const [state, dispatch] = useReducer(reducer, {
    //     ...initialState,
    //   });
    // const compArr = [
    //     {
    //         id: 1,
    //         name: "Ui for social App"
    //     },
    //     {
    //         id: 2,
    //         name: "Chat Bot for Ecommers website"
    //     },
    //     {
    //         id: 3,
    //         name: "Sentimental Analysis project"
    //     },
    //     {
    //         id: 4,
    //         name: "Database Design of Social Media App"
    //     }
    // ]
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(()=> {
    //     const comp = [companyId]

    //     dispatch({
    //         type: "SET_COMP",
    //         payload: {company_id: comp },
    //     });
    // }, [])
    const handleClick = ()=> {

        if(companyId){
            
            const comp = [companyId]
    
            dispatch({
                type: "SET_COMP",
                payload: {company_id: comp },
            });


        }

        // if(scores.length !== 0){
        //     dispatch({
        //         type: "change_value",
        //         payload: { orders: scores },
        //       });
        // }
        // console.log("typeeee",type)


        
        user?.role === 4 ? navigate(`/student/explore/top-${type}s`, {state: {activeTab: type === 'project' ? 0 : type === 'internship' ? 1 : 2 }, replace: true}) : navigate(`/explore-page/top-${type}s`)


    }

    // console.log("statee" )

    // console.log("righttdataa", companyId);
  return (
    data?.length !== 0 ? (
    <div className='suggestion-card'>
        <div className="card-head">
            <h3> {title} <span>{highlightedText} !!</span></h3>
        </div>
        <div className="card-main d-flex flex-column gap-2">
            { type === "project" &&
              data?.length !== 0 &&  data?.map((item, i)=> {
                    const truncatedName = item?.project_title.length > 20 ? item.project_title.slice(0, 20) + '...' : item.project_title;
                    return (
                        <NavLink to={user?.role === 4 ? `/project/${item?.project_title.replace(/[\s#]+/g, '-')}/${item?.id}` : `/project/${item?.project_title.replace(/[\s#]+/g, '-')}/${item?.id}`} key={i}>
                            <div className='comp-items d-flex justify-content-between' key={item.id} >
                                <h4>{truncatedName}</h4>
                                <RiShareBoxLine size={20} color='#6149CD' className='link-icon' />
                            </div>
                        </NavLink>
                    );
                })
            }
            { type === "internship" &&
              data?.length !== 0 &&  data?.map((item, i)=> {
                    const truncatedName = item?.internship_title.length > 20 ? item.internship_title.slice(0, 20) + '...' : item.internship_title;
                    return (
                        <NavLink to={user?.role === 4 ? `/internship/${item?.internship_title.replace(/[\s#]+/g, '-')}/${item?.id}` : `/internship/${item?.internship_title.replace(/[\s#]+/g, '-')}/${item?.id}`} key={i}>
                            <div className='comp-items d-flex justify-content-between' key={item?.id} >
                                <h4>{truncatedName}</h4>
                                <RiShareBoxLine size={20} color='#6149CD' className='link-icon' />
                            </div>
                        </NavLink>
                    );
                })
            }
            { type === "job" &&
              data?.length !== 0 &&  data?.map((item, i)=> {
                    const truncatedName = item?.job_title.length > 20 ? item.job_title.slice(0, 20) + '...' : item.job_title;
                    return (
                        <NavLink to={ user?.role === 4 ? `/job/${item?.job_title.replace(/[\s#]+/g, '-')}/${item?.id}`: `/job/${item?.job_title.replace(/[\s#]+/g, '-')}/${item?.id}`} key={i}>
                            <div className='comp-items d-flex justify-content-between' key={item.id} >
                                <h4>{truncatedName}</h4>
                                <RiShareBoxLine size={20} color='#6149CD' className='link-icon' />
                            </div>
                        </NavLink>
                    );
                })
            }
        </div>
        {
         seeall &&

            <div className='see-all' onClick={handleClick}>
                {/* <Link to={user?.role === 4 ? "/student/explore" : "/explore-page"}> */}
                    See All
                {/* </Link> */}
            </div>
        }
    </div>
    ): (
        ""
    )
  )
}

export default RightCard