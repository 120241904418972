const Progress = ({ strokeWidth = 8, percentage = 0, stroke = "#007dbc" }) => {
  const radius = 50 - strokeWidth / 2
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `

  const diameter = Math.PI * 2 * radius

  const progressStyle = {
    stroke,
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
    transition: "stroke-dashoffset 0.35s ease 0s, stroke 0.35s ease",
  }

  return (
    <svg
      className={`circular_progressbar ${percentage === 100 ? "uploaded" : ""}`}
      viewBox="0 0 100 100"
      width={70}
      height={70}
    >
      <path
        className="circular_progressbar_trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#d6d6d6",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={50}
        style={{
          fill: stroke,
          fontSize: "20px",
          fontFamily: "'Urbanist', sans-serif",
          dominantBaseline: "central",
          textAnchor: "middle",
          fontWeight: "bolder",
        }}
      >
        {`${Number.parseInt(percentage)}%`}
      </text>
    </svg>
  )
}

export default Progress
