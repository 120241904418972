// import AppliedPostCard from "components/cards/AppliedPostCard"

import AppliedPostCard from "components/cards/AppliedPostCard";
import useFetch from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
const SuggestedMentors = ({ method = "job", skills = [],activeTab }) => {
  const { loading, data, error } = useFetch(
    skills?.length
      ? `/v1/${method}/suggested?student_skills=${skills.join(",")}`
      : `/v1/${method}/suggested`
  );
  // if (loading) return <></>
  // if (error) return <></>
  const navigate = useNavigate();

  const handleExplore = () => {
    navigate(`/student/explore/top-${method}s`, {
      state: {
        activeTab: activeTab,
      },
    });
  };

  const p = true;
  const button_color = {
    backgroundColor: "#6149CD",
  };
  // console.log(data?.data, "internshipData");
  //data?.data?
  return (
    <>
      {data?.data?.length > 0 ? (
        <div className="mentor">
          <div className="suggested-mentor">
            <h2>Suggested {method}</h2>
            <div className="row">
              {p ? (
                data?.data
                  ?.slice(0, 3)
                  ?.map((item, index) => (
                    <AppliedPostCard
                      key={index}
                      item={item}
                      method={method}
                      btn="Apply Now"
                      button_color={button_color}
                    />
                  ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row" id="explore">
            <div className="col-12">
              <button onClick={handleExplore}>Explore More</button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SuggestedMentors;
