import { useState } from "react";
import InternshipTable from "./InternshipTable";
import JobTable from "./JobTable";
import ProjectTable from "./ProjectTable";

const StudentTableContainer = ({setType}) => {
  const tabs = ["Projects", "Internships", "Jobs"];
  const [activeTab, setActiveTab] = useState(() => tabs[0]);

  return (
    <div className="row w-100 m-0">
      <div className="col-12 p-0">
        <div className="table-tabs">
          <nav>
            <div className="nav_tabs nav nav-tabs" id="nav-tab" role="tablist">
              {tabs?.map((tab) => (
                <button
                  style={{ fontSize: "16px", fontWeight: "500" }}
                  className={`nav-link ${activeTab === tab ? "active" : ""}`}
                  id={`nav-${tab}-tab`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${tab}`}
                  aria-selected="true"
                  onClick={() => setActiveTab(tab)}
                  key={tab}
                >
                  {tab}
                </button>
              ))}
            </div>
          </nav>

          <div
            className="tab-pane fade show active"
            id={`nav-${activeTab}`}
            role="tabpanel"
            aria-labelledby={`nav-${activeTab}-tab`}
          >
            <LoadTable
              // isPopupOpen={isPopupOpen}
              // setIsPopupOpen={setIsPopupOpen}
              setType={setType}
              activeTab={activeTab}
              // // data={posts}
              // setRefreshTable={setRefreshTable}
              // refreshTable={refreshTable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentTableContainer;

const LoadTable = ({
  activeTab,
  // data,
  setType,
  // isPopupOpen,
  // setIsPopupOpen, setRefreshTable, refreshTable
}) => {
  switch (activeTab) {
    case "Internships":
      console.log("internship active")
      return (
        <InternshipTable
          setType={setType}
          // // data={data[activeTab]}
          // isPopupOpen={isPopupOpen}
          // setIsPopupOpen={setIsPopupOpen}
          // setRefreshTable={setRefreshTable}
          // refreshTable={refreshTable}
        />
      );
    case "Jobs":
      console.log("job active")
      return (
        <JobTable
          setType={setType}
          // // data={data[activeTab]}
          // isPopupOpen={isPopupOpen}
          // setIsPopupOpen={setIsPopupOpen}
          // setRefreshTable={setRefreshTable}
          // refreshTable={refreshTable}
        />
      );
    case "Projects":
        console.log("project active")
      return (
        <ProjectTable
          setType={setType}
          // // data={data[activeTab]}
          // isPopupOpen={isPopupOpen}
          // setIsPopupOpen={setIsPopupOpen}
          // setRefreshTable={setRefreshTable}
          // refreshTable={refreshTable}
        />
      );
    default:
      console.log("default active")
      return (
        <InternshipTable
          setType={setType}
          // // data={data[activeTab]}
          // isPopupOpen={isPopupOpen}
          // setIsPopupOpen={setIsPopupOpen}
          // setRefreshTable={setRefreshTable}
          // refreshTable={refreshTable}
        />
      );
  }
};
