import { useNavigate } from "react-router-dom";

const useHandleUrl = (state, role, userRole, filterMap, getUrlTab,)=> {

    const navigate = useNavigate();


    const updateURL = async() => {
        const urlPath = buildUrlPath();
        // console.log('Navigatingto:', urlPath);
        navigate(urlPath);
      };
    
      
      const buildUrlPath = () => {
    
        const prevFilters = state?.filters;
    
        const { companies, locations, industries, qualifications,skills } = prevFilters;
    
        // console.log("prevvvvvv", prevFilters);
    
        const rolePath = role === 3 || role === 4 ? `/${userRole[role]}/explore` : "/explore-page" ;
        let urlPath = `${rolePath}/top-${filterMap[getUrlTab || 0]}`;
        // let urlPath = `/explore-page/top-${filterMap[getUrlTab || 0]}`;
    
    
        // console.log("urlpathhh", urlPath);
    
        if (companies?.length > 0) {
          urlPath += `-by-${companies.join(',').toLowerCase().replace(/ /g, '_')}`;
        }
    
        if (qualifications?.length > 0) {
          urlPath += `-for-${qualifications.join(',').toLowerCase().replace(/ /g, '_')}`;
        }
    
        if (locations?.length > 0) {
          urlPath += `-in-${locations.join(',').toLowerCase().replace(/ /g, '_')}`;
        }
    
        if (industries?.length > 0) {
          urlPath += `-sector-${industries.join(',').toLowerCase().replace(/ /g, '_')}`;
        }
        if (skills?.length > 0) {
          urlPath += `-skill-${skills.join(',').toLowerCase().replace(/ /g, '_')}`;
        }
    
        // console.log("urllllllllll", urlPath);
        return urlPath;
    
    
    
    
      }   



    return {
        updateURL
    }
}

export default useHandleUrl;