import React from "react"
import "../../admin/tables/mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import StatusButton from "components/employer/StatusButton"
import { useState,useEffect } from "react"
import { IoTerminalSharp } from "react-icons/io5"


const MobileViewUserTable = ({ columns, data}) => {
  // console.log(columns, data,"user")

const navigate=useNavigate()

  const handleActivateMentorAccount = async (id) => {
    try {
      // setRefresh(true)
      const response = await axios.delete(`/v1/mentor/mentor/${id}`);
      if (!response.data?.error) {
        toast.info(response?.data?.message);
        navigate('/admin/dashboard')
   
      } else {
        toast.error(response?.data?.error);

      }
    } catch (error) {
      console.error(error);

      toast.error("An error occurred while deleting the mentor.");
    }


    }
 

    const handleEditClick = (data) => {
      navigate(`edit-user/${data?.id}`, {
        state: {
          id:data?.id,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
        },
      })
    }


  return (
    <>
      {data?.map((item) => {

        const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4">
            <div className="data_container">
              <div className="table_data">
                <p>Date Created</p>
                <span className="Total_enrolled"> {item?.createdAt}</span>
              </div>
              <div className="table_data">
                <p>User Name</p>
                <span className="Total_enrolled"> {item?.first_name}  {item?.last_name}</span>


              </div>

              <div className="table_data">
                <p>Email</p>
                <span className="project_name">{item?.email_address}</span>
              </div>

              <div className="table_data">
                <p>Phone Number</p>
                <span className="Total_enrolled">{item?.phone_number}</span>
              </div>

             

              {/* <div className="table_data">
                <p>User Type</p>
                <span className="Total_enrolled">{item?.role===5 && "Mentor"}</span>
              </div> */}
             
             <div className="Status_data">
  <p>Action</p>
  {item?.deletedAt === "Activate" ? (
    <button
      onClick={() => handleActivateMentorAccount(item?.id)}
      className="btn px-4 py-1 text-white bg-success"
      style={{
        borderRadius: "22px",
        fontSize: "12px",
        fontWeight: "600",
      }}
    >
      Activate
    </button>
  ) : (
    <button
      onClick={() => handleEditClick(item)}
      className="btn btn-primary px-5 py-1 text-white"
      style={{
        borderRadius: "20px",
        fontSize: "12px",
        fontWeight: "600",
      }}
    >
      Edit
    </button>
  )}
</div>


            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewUserTable
