import React, { useEffect, useState } from "react";
import TopButtons from "../TopButtons";
import "../settings.scss";

const Sunday = ({ setAvailability, availability, sundayTime }) => {
  //   const [availability, setAvailability] = useState([]);
  const [dayFrom, setDayFrom] = useState("");
  const [dayTo, setDayTo] = useState("");
  const [checkValue, setCheckValue] = useState(false);

  const handleAddAvailability = () => {
    if (dayFrom && dayTo) {
      const slot = `${dayFrom}-${dayTo}`;
      const newAvailability = [...availability, slot];
      setAvailability(newAvailability);
      setDayFrom("");
      setDayTo("");
    }
  };

  // console.log(availability, dayFrom, dayTo, checkValue, "availibility");

  useEffect(() => {
    if (sundayTime) {
      setAvailability(sundayTime);
    }
  }, [setAvailability, sundayTime]);

  const handleRemoveAvailability = (index) => {
    const newAvailability = [...availability];
    newAvailability.splice(index, 1);
    console.log(newAvailability, "newAvailability");
    setAvailability(newAvailability);
  };

  const renderAvailability = () => {
    return availability?.map((slot, index) => (
      <div className="flex w-100 py-2" key={index}>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
          <div className="row">
            <div className="col-12">
              <label>Time: {slot}</label>
            </div>
          </div>
        </div>
        <div className="col-2 d-flex align-items-center justify-content-end m-0">
          <label
            className="m-0 w-auto"
            onClick={() => handleRemoveAvailability(index)}
          >
            <i className="fa-solid fa-minus m-0"></i>
          </label>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="row flex">
        <div className="col-3">
          <label className="dayname">
            Sunday <br />
            <div className="d-flex">
              <input
                type="checkbox"
                readOnly
                checked={checkValue}
                style={{ appearance: "auto" }}
                onChange={() => setCheckValue((p) => !p)}
              />
              <h6 className="mb-0 mx-2">Not Available</h6>
            </div>
          </label>
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
          {!checkValue && (
            <div className="row">
              <div className="col-12">
                <label>
                  From <br />
                  <select
                    value={dayFrom}
                    onChange={(e) => setDayFrom(e.target.value)}
                  >
                    <option value="">Select Time</option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>

                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>

                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                    <option value="24:00">24:00</option>
                  </select>
                </label>
                <label>
                  To <br />
                  <select
                    value={dayTo}
                    onChange={(e) => setDayTo(e.target.value)}
                  >
                    <option value="">Select Time</option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>

                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>

                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                    <option value="24:00">24:00</option>
                  </select>
                </label>
              </div>
            </div>
          )}
        </div>
        {!checkValue && (
          <div className="col-1">
            <label onClick={handleAddAvailability}>
              <i className="fa-solid fa-plus"></i>
            </label>
          </div>
        )}
        {renderAvailability()}
      </div>
    </>
  );
};
export default Sunday;
