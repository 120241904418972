import axios from 'axios'
import useOutsideClick from 'hooks/useOutsideClick'
import React, { useEffect, useRef, useState } from 'react'
import "./NewProjectDetails.scss"
import LinkedProjectCard from './PostsHelper/LinkedProjectCard'
import NewPreviewField from './PostsHelper/NewPreviewField'
import RightCard from './PostsHelper/RightCard'
import TopCard from './PostsHelper/TopCard'
import PostDetailsSection from './PostsHelper/PostDetailsSection'

const NewInternshipDetailsPage = ({  
    data,
    Action,
    children,
    onApply,}) => {
    
    const [moreInternships, setMoreInternships] = useState([]);
    const [similarInternships, setSimilarInternships] = useState([]);

    useEffect(()=> {

        const fetchMoreInternships = async ()=> {
            try {
                const res = await axios.post(`/v1/filter/internship/company/${data?.global?.id}`, {
                    internship_id: data?.id
                });

                // console.log("jobbbbss", res);

                setMoreInternships(res?.data?.data);
            } catch (error) {
                // console.log(error);
            }
        }

        const fetchSimilarInternships = async ()=> {
            try {
                const res = await axios.get(`/v1/filter/internship/similar-internships/${data?.id}`);

                // console.log("similarrrress", res?.data);

                setSimilarInternships(res?.data?.data?.similarInternships);
            } catch (error) {
                // console.log(error);
            }
        }

        fetchMoreInternships();
        fetchSimilarInternships();

    }, []);

    const [show, setShow] = useState(false);

    const toggleDropdown = () => setShow((prev) => !prev);

    const ref = useRef(null)
    useOutsideClick(ref, ()=> setShow(false), show)

    // console.log("oouterr", moreInternships)

    // console.log("iiidataaa", data);
  return (
    <div className='details-wrapper pt-4'>
        <div className='container d-flex gap-4'>
            <div className='col-md-9'>
                <PostDetailsSection data={data} children={children} type={"internship"} />
            </div>

            <div className="right-section col-3">
                <div className='right-container d-flex flex-column gap-4'>
                    {
                        data?.project_linked > 0 && (
                            <LinkedProjectCard project={data?.project} type={"Internship"} />
                          )
                    }

                    <RightCard title={"More Internships Offered By "} highlightedText={data?.global?.employer_account?.company?.company_name} data={moreInternships} type={"internship"} companyId={data?.global?.employer_account?.company?.id} seeall={true} />

                    <RightCard title={"We Found More Similar "} highlightedText={"Internships For You"} data={similarInternships} type='internship' seeall={true}  />

                </div>
            </div>

        </div>
    </div>
  )
}

export default NewInternshipDetailsPage;