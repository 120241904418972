import React from "react"
import "./Ploicy.scss"
const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center text-black">Privacy Policy</h1>

      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <p className="text-black">Effective Date: 22/05/2023</p>
          <p>
            At Qollabb, we are committed to protecting the privacy and security
            of our users' personal information. This Privacy Policy outlines how
            we collect, use, disclose, and safeguard the information you provide
            when using our platform and services. By accessing or using Qollabb,
            you consent to the practices described in this policy.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            <strong>1.1 Personal Information:</strong> When you sign up for an
            account or use our services, we may collect personally identifiable
            information such as your name, email address, phone number, and
            professional details. We collect this information to provide you
            with access to our platform and customize your experience.
          </p>
          <p>
            <strong>1.2 Usage Information:</strong> We automatically collect
            certain information about your interaction with Qollabb, such as
            your IP address, browser type, operating system, and device
            information. This data helps us analyze trends, administer the
            platform, and improve user experience.
          </p>
          <p>
            <strong>1.3 Cookies and Similar Technologies:</strong> Qollabb uses
            cookies and similar technologies to enhance your browsing
            experience, personalize content, and analyze usage patterns. Cookies
            may be stored on your device to remember your preferences and
            optimize the platform's performance. You can manage your cookie
            preferences through your browser settings.
          </p>

          <h2>2. Use of Information</h2>
          <p>
            <strong>2.1 Provision of Services:</strong> We use the collected
            information to operate, maintain, and improve Qollabb's platform and
            services, including facilitating connections between users,
            providing customer support, and sending administrative
            notifications.
          </p>
          <p>
            <strong>2.2 Communication:</strong> We may use your contact
            information to send you service-related communications, updates, and
            promotional materials. You can opt out of these communications at
            any time by following the instructions provided in the messages or
            contacting us directly.
          </p>
          <p>
            <strong>2.3 Aggregated Data:</strong> We may anonymize and aggregate
            the information collected from users to generate statistical or
            analytical insights. Aggregated data is used to analyze user
            behavior, measure the effectiveness of our services, and improve our
            platform.
          </p>
          <p>
            <strong>2.4 Legal Compliance and Protection:</strong> We may access,
            preserve, and disclose your personal information if required by law
            or if we believe in good faith that such action is necessary to
            comply with legal obligations, protect our rights or the safety of
            others, investigate fraud, or respond to government requests.
          </p>

          <h2>3. Data Sharing</h2>
          <p>
            <strong>3.1 Service Providers:</strong> We may engage trusted
            third-party service providers to assist us in delivering our
            services, including hosting, data analysis, customer support, and
            marketing. These providers have access to personal information as
            necessary to perform their functions but are obligated to maintain
            confidentiality and security.
          </p>
          <p>
            <strong>3.2 Third-Party Integration:</strong> Qollabb may integrate
            with third-party applications or services. If you choose to use such
            integrations, you may be sharing information with those third
            parties, and their use of your data will be subject to their
            respective privacy policies. We encourage you to review the privacy
            policies of these third parties before using their services.
          </p>
          <p>
            <strong>3.3 Business Transactions:</strong> In the event of a
            merger, acquisition, or sale of assets, your personal information
            may be transferred to a third party as part of the transaction. We
            will notify you via email and/or prominent notice on our platform of
            any such change in ownership or control of your personal
            information.
          </p>

          <h2>4. Data Security</h2>
          <p>
            Qollabb implements reasonable security measures to protect the
            personal information we collect and maintain. However, no method of
            transmission over the Internet or electronic storage is entirely
            secure. We cannot guarantee absolute security, and you should take
            appropriate measures to protect your personal information.
          </p>

          <h2>5. Google API Services User Data Policy</h2>
          {/* <p>
        Qollabb use and transfer to any other app of information received from Google APIs will adhere to 
        <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank"><u style={{color:'#15c'}}>Google API Services User Data Policy</u></a>, including the Limited Use requirements.
      </p> */}
          <p>
            Qollabb's use and transfer of information received from Google APIs
            will adhere to
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
       
            >
              <u style={{color:'#15c'}}>Google API Services User Data Policy</u>
            
            </a>
            , as well as
            <a
              href="https://developers.google.com/terms"
              target="_blank"
        
            >
              <u style={{color:'#15c'}}> Google’s API Terms of Service</u>
             
            </a>{" "}
            and
            <a
              href="https://developers.google.com/terms"
              target="_blank"
         
            >
             <u style={{color:'#15c'}}>Google Developer Policy</u> 
            </a>
            , including the Limited Use requirements outlined in
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
           
            >
              <u style={{color:'#15c'}}>  Google’s Limited Use requirements</u>
            
            </a>
            .
          </p>

          <h2>6. No Sharing with Third Parties</h2>
          <p>We do not share any user data with third-party tools, including AI models.</p>

          <h2>7. Limited Use of Data</h2>
          <p> Any information received from Google APIs or any other sources is used solely for the intended functionality of our app. We do not transfer this data to other apps or services.</p>

 
          <h2>8. Children's Privacy</h2>
          <p>
            Qollabb is not intended for use by individuals under the age of 13.
            We do not knowingly collect personal information from children. If
            you believe we have inadvertently collected information from a
            child, please contact us immediately, and we will take steps to
            remove the information promptly.
          </p>

          <h2>9. Your Choices</h2>
          <p>
            You have the right to access, correct, update, or delete your
            personal information. You can manage your account settings and
            preferences by logging into your Qollabb account. If you need
            assistance or have any questions regarding your personal
            information, please contact us using the information provided below.
          </p>

          <h2>10. Changes to this Privacy Policy</h2>
          <p>
            Qollabb may update this Privacy Policy from time to time. We will
            notify you of any material changes by posting the updated policy on
            our platform or sending you an email. We encourage you to review
            this Privacy Policy periodically to stay informed about how we are
            protecting your information.
          </p>

          <h2>11. Contact Us</h2>
          <p>
            If you have any questions, concerns, or suggestions regarding this
            Privacy Policy or our practices, please contact us at:
          </p>
          <p>Email: info@qollabb.com</p>
          <p>
            Address: Qollabb EduTech Private Limited, 231, Bank officers
            HBCS,1st Cross,, 40th Main BTM, 2nd Stage, Bangalore, Karnataka,
            India, 560068
          </p>

          <p>
            Thank you for trusting Qollabb with your personal information. We
            are dedicated to maintaining your privacy and safeguarding your
            data.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
