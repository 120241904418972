import Datatable from "components/datatable-new/DatatableNew"
import { downloadCSV } from "components/dataTable/Export"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./style.scss"
import AddModal from "./modals/AddModal"
import EditModal from "./modals/EditModal"
import axios from "axios"
import { toast } from "react-toastify"

const MDSpecialization = ({ data }) => {
  const [search, setSearch] = useState(() => "")
  const [showModal, setShowModal] = useState(false)
  const [newSpecialization, setNewSpecialization] = useState("")
  const [editSpecialization, setEditSpecialization] = useState("")
  const [editId, setEditId] = useState(-1)
  const [showEditModal, setShowEditModal] = useState(false)
  const columns = [
    {
      Header: "Specialization Name",
      accessor: "name",
      width: "75%",
    },
    {
      Header: "Actions",
      width: "25%",
      Cell: (data) => {
        return (
          <div className="row mx-0">
            <div className="col-6">
              <button
                className={`btn edit-btn`}
                onClick={() => {
                  setShowEditModal(true)
                  setEditSpecialization(data?.row.original.name)
                  setEditId(data?.row.original.id)
                }}
              >
                Edit
              </button>
            </div>
            <div className="col-6">
              <button
                className={`btn delete-btn`}
                onClick={() => handleDelete(data?.row.original.id)}
              >
                Delete
              </button>
            </div>
          </div>
        )
      },
    },
  ]
  const rows = data
    ?.filter((post) => {
      return post?.name?.toLowerCase()?.includes(search?.toLowerCase())
    })
    ?.map((post) => {
      return {
        id: post?.id,
        name: post?.name,
      }
    })
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newSpecialization === "") {
      toast.error("Enter a value")
      return
    }

    const { data } = await axios.post(`/v1/admin/specializations`, {
      name: newSpecialization,
    })
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Specialization Added")
      setNewSpecialization("")
      setShowModal(false)
    }
  }
  const handleDelete = async (id) => {
    const { data } = await axios.delete(`/v1/admin/specializations/${id}`)
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Specialization Deleted")
      setNewSpecialization("")
      setShowModal(false)
    }
  }
  const handleUpdate = async (e) => {
    e.preventDefault()
    const { data } = await axios.put(`/v1/admin/specializations/${editId}`, {
      name: editSpecialization,
    })
    if (data?.error) return toast.error(data?.message || "Something went wrong")
    else {
      toast.success("Specialization Updated")
      setEditSpecialization("")
      setShowEditModal(false)
    }
  }
  return (
    <div>
      <div className="datatable-container mt-4 tablepadding">
        <div className="table_head flex mb-3">
          <div className="mobo_upload">
            <h3 className="mb-0">
              Specializations
              <br /> <span className="light">{data?.length || 0} records</span>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <Link
              to=""
              className="upload_btn_icon btn btn-primary text-white px-3"
              data-toggle="modal"
              data-target="#addModal"
              onClick={() => setShowModal(true)}
            >
              Add <i className="fa-solid fa-plus"></i>
            </Link>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>
        <AddModal show={showModal} setShow={setShowModal} type="specialization">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="specialization" className="mb-2">
                Specialization
              </label>
              <input
                type="text"
                className="form-control"
                id="specialization"
                placeholder="Enter specialization"
                value={newSpecialization}
                onChange={(e) => setNewSpecialization(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setNewSpecialization("")
                  setShowModal(false)
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </AddModal>
        <EditModal
          show={showEditModal}
          setShow={setShowEditModal}
          type="specialization"
        >
          <form onSubmit={handleUpdate}>
            <div className="form-group">
              <label htmlFor="specialization" className="mb-2">
                Specialization
              </label>
              <input
                type="text"
                className="form-control"
                id="specialization"
                placeholder="Enter specialization"
                value={editSpecialization}
                onChange={(e) => setEditSpecialization(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setEditSpecialization("")
                  setShowEditModal(false)
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary text-white px-4 py-2"
              >
                Save
              </button>
            </div>
          </form>
        </EditModal>
        <Datatable columns={columns} data={rows} />
        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  )
}

export default MDSpecialization
