import axios from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./notification.scss";

const AllNotifications = () => {

    const location = useLocation();
    // const {data} = location?.state;
    const data = location?.state?.data;
    const role = location?.state?.role;
    const navigate = useNavigate();


    // // console.log("status", status);
// console.log("rolee", role)


    const convertUTCToIST = (utcDate) => {
        return {
             
             date: new Date(utcDate).toLocaleString("en-IN", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
             }),
             time: new Date(utcDate).toLocaleString("en-IN", {
                  hour: "numeric",
                  minute: "numeric",
             })
        };
};

   const handleRedirect = async (item)=> {
    // console.log("itememe", item);

    switch (item?.notification_payload?.role || role) {
        case 2:        // employer     

            if(item?.notification_type === "chat"){
                navigate("/employer/chat")
            }

            
            if(item?.notification_type === "complete_profile-reminder"){
                navigate("/employer/employer-profile")
            }

            
            if(item?.notification_type === "subscription-reminder"){
                navigate("/employer/subscription")
            }



            break;
        case 3:        // educator
            if(item?.notification_type === "chat"){
                navigate("/educator/chat")
            }

            if(item?.notification_type === "complete_profile-reminder"){
                navigate("/educator/educator-profile")
            }
            
            if(item?.notification_type === "subscription-reminder" || "max_assign-reminder"){
                navigate("/educator/subscriptions")
            }
            
            if(item?.notification_type === "eduStudent_project-reminder"){
                navigate("/educator/student-list")
            }
            
            break;
        case 4:        // student
        // console.log("Student case")

            if(item?.notification_type === "complete_profile-reminder"){
                navigate("/student/profile")
            }

            if(item?.notification_type === "chat"){
                // console.log("chat")
                navigate("/student/chat")
            }

            if(item?.notification_type === "project"){
                navigate(`/student/my-applications/project/${item?.notification_payload?.post_id}`)
            }

            if(item?.notification_type === "internship"){
                // console.log("intenshipiddd", item?.notification_payload?.post_id)
                navigate(`/student/my-applications/internship/detail/${item?.notification_payload?.post_id}`)
            }

            if(item?.notification_type === "job"){
                navigate(`/student/my-applications/job/detail/${item?.notification_payload?.post_id}`)
            }

            if(item?.notification_type === "project_report-reminder"){
                navigate(`/student/my-applications/job/detail/${item?.notification_payload?.project_id}`)
            }

            if(item?.notification_type === "mentor_session-reminder"){
                navigate(`/student/mentor`)
            }

            if(item?.notification_type === "subscription-reminder"){
                navigate(`/student/subscriptions`)
            }
            
            break;

        default:
            break;
    }

    const changeStatus = async ()=> {

        try {
            const res = await axios.post("/v1/notification/update", {
                    id: item?.id,
                    is_seen: true,
                    status: 2,      // seen -> 2
 
            })

            // console.log("res", res);
        } catch (error) {
            console.log("err", error)
        }
    }

    if(!item?.is_seen && item?.status == 1 ){
        changeStatus();
    } 

}

    // // console.log("dataa", state)
    // console.log("all notification", data);

  return (
    <div className='notification container'>
        <div className={`${role !== 4 && "mx-4"}`}>
            <h2>All Notifications</h2>
        </div>

        <div className='all-notification'>
            <ul className={`${role !== 4 && "mx-4"}`}>
                {
                    data && data.map((item, index)=> (
                        <li key={index} className={`noti_body`} onClick={()=> handleRedirect(item)}>
                            <div className='noti_top'>

                                <div className='noti_title'>
                                    <span >{item?.notification_title} </span> <span className='noti_desc'>{item?.notification_description}</span>
                                </div>

                                {!item?.is_seen && item?.status == 1 &&  <span className='seen-status'></span> }

                            </div>

                            {/* <div className='noti_desc'>
                                {item?.notification_description}
                            </div> */}

                            <div className='notification_time'>
                                <span>
                                    {convertUTCToIST(item?.createdAt).date}
                                </span>
                                <span>
                                    {convertUTCToIST(item?.createdAt).time}   
                                </span>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
  )
}

export default AllNotifications