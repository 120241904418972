import useRelativeTime from "hooks/useRelativeTime";
import { Link } from "react-router-dom";
// import styles from "./card.module.scss"

const AppliedPostCard = ({ button_color, item, method, btn }) => {
  const {
    createdAt = new Date(),
    title = `${method} Title`,
    branch: { address, company_logo } = { address: "", company_logo: "" },
    incentive_to = 0,
    duration_to = 0,
    required_skills,
    description,
    incentive_from,
    course_preferred,
    updatedAt,
  } = item[method] || {};
  const time = useRelativeTime(item.createdAt);
  // const skills = [{ name: "Stack Develpment", id: 1 }, { name: "MERN", id: 2 }, { name: "MEAN", id: 3 }]
  // // console.log()
  // // console.log(item?.[method]?.id, "w123456");
  // // console.log("changeMethod", method);
  // console.log("item..1",item)
  // console.log("item..2", method)
  // console.log("item..3", required_skills)
  return (
    <div className="suggested_main_ col-12 col-sm-4 py-2">
      <div className="student_card_applied w-100 col-12 col-sm-3  ">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <img
            // style={{
            //   objectFit: "contain",
            // }}
            className="comp-img rounded-circle"
            width={50}
            height={50}
            src={
              item?.global?.employer_account?.company_logo ||
              item?.global_job?.employer_account?.company_logo ||
              item?.project_global_fkey?.employer_account?.company_logo ||
              item?.[method]?.global?.employer_account?.company_logo ||
              item?.[method]?.global_job?.employer_account?.company_logo ||
              item?.[method]?.project_global_fkey?.employer_account
                ?.company_logo ||
              item[method]?.branch?.company_logo
            }
            alt="Company Logo"
          />
          <p style={{ fontWeight: "500" }} className="m-0">
            {/* {time} */}
            {new Date(item?.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </p>
        </div>
        <div
          style={{
            minHeight: "300px",
            position: "relative",
          }}
          className="card-content"
        >
          <Link
            // to={`/student/explore/${method}/${item.id}`}
            to={
              // btn === "Applied"
              ["Applied", "Shortlisted", "Hired", "Rejected"].includes(btn)
                ? `/student/my-applications/${method}/detail/${item?.id}`
                : `/${method}/${item?.title?.replace(/[\s#/]+/g, '-').toLowerCase()}/${item?.id}`
            }
          >
            <h5 className="my-2 text-black">
              {item?.title || item?.[method]?.title}
            </h5>
          </Link>
            <p className="address_tag pb-2 mb-0">
              <b>{address}</b>{" "}
              {method === "project" ? (
                <>
                  Duration: {item?.duration_from} - {item?.duration_to}/weeks
                  {/* <b>{course_preferred[0]}-{course_preferred[0]} Weeks</b> */}
                </>
              ) : (
                ""
              )}
            </p>

          {method !== "project" ? (
            <p className="pb-2 mb-0">
              {method !== "job" ? (
                <>
                  Stipend:{" "}
                  <b>
                    {item?.stipend_from || item[method]?.incentive_from} -{" "}
                    {item?.stipend_to || item[method]?.incentive_to} / Month{" "}
                  </b>
                </>
              ) : (
                <>
                  CTC:{" "}
                  <b>
                    {item?.ctc_from || item[method]?.incentive_from}-{" "}
                    {item?.ctc_to || item[method]?.incentive_to}/ Annum{" "}
                  </b>
                </>
              )}
              {method !== "job" ? (
                <>
                  | Tenure:
                  <b>
                    {" "}
                    {item?.duration_to || item[method]?.duration_to} / Weeks{" "}
                  </b>{" "}
                </>
              ) : (
                <></>
              )}
            </p>
          ) : (
            <></>
          )}
          {method !== "project" ? (
            <p className="pb-2 mb-0">
              Seeking students from:{" "}
              <b>
                {item?.[`${method}_city`]
                  ? item?.[`${method}_city`]?.map((item) => item).join(", ") ||
                    "AnyWhere"
                  : item?.[method]?.[`${method}_city`]
                      ?.map((item) => item)
                      .join(", ") || "AnyWhere"}
              </b>
            </p>
          ) : (
            <></>
          )}
          <div className="d-flex">
            {/* <span  className="badge bg-light text-dark me-2 p-2 mt-2 " >
              RectJs
            </span> */}
            {required_skills?.map((skill) => (
              <span
                style={{
                  backgroundColor: "#F2F2F2",
                  color: "#7A86A1",
                  letterSpacing: "1px",
                  fontWeight: "500",
                }}
                className="badge bg-light text-dark me-2 p-2 mt-2 "
                key={skill?.id}
              >
                {skill?.name}
              </span>
            ))}
          </div>
          <p className="desc_tag pb-2 pt-3">
            {/* {description
              ? `${description.slice(0, 200)}`
              : "IBM India india is hiring MERN Developer Intern. The location of the stated Internship is Noida, U.P. Following is the job description of the same..."} */}
            {item?.description?.slice(0, 100) || item?.[method]?.description.slice(0,100)}
          </p>
          <Link
            to={
              // btn === "Applied"
              ["Applied", "Shortlisted", "Hired", "Rejected"].includes(btn)
                ? `/student/my-applications/${method}/detail/${item?.id}`
                : `/${method}/${item?.title?.replace(/[\s#/]+/g, '-').toLowerCase()}/${item?.id}`
            }
            className="text-white btn-gold"
          >
            <div
              style={button_color}
              type="button"
              className="internship_btn btn btn-gold w-100"
            >
              {btn}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppliedPostCard;
