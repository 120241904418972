import React from "react"
import "./mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link } from "react-router-dom"
import moment from "moment"

const  MobileViewEvaluationsEvaluatedTable = ({ columns, data,handleDownload}) => {
  // console.log(columns, data,"Project Evaluations")
 
  return (
    <>
      {data?.map((item) => {

        // const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4">
            <div className="data_container">
              <div className="table_data">
                <p>Date Evaluated</p>
                <span className="Total_enrolled">{item?.date_Evaluated}</span>
              </div>
              <div className="table_data">
                <p>Mentor Name</p>
                <span className="Total_enrolled">{item?.mentor_name}</span>
              </div>
              
              <div className="table_data">
                <p>Student Name</p>
                <span className="Total_enrolled">{item?.student_name}</span>
              </div>

              <div className="table_data">
              <p>Subscription Plan</p>
                <span className="Total_enrolled">{item?.subscription_plan}</span>
              </div>


              <div className="table_data">
                <p>Project Name</p>
                <span className="Total_enrolled">{item?.project_name}</span>
              </div>

              <div className="table_data">
                <p>Rating</p>
                <span className="Total_enrolled">{item?.rating}</span>
              </div>

             
        

              <div className="Status_data">
              <p>Certificate</p>
              <button className="deactivate-btn" style={{ fontWeight: "500" }}
          onClick={() => handleDownload(item)} >
            Download
          </button>
              
           
              
                       
              </div>


            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileViewEvaluationsEvaluatedTable
