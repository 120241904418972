import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";

import Datatable from "components/datatable-new/DatatableNew";
import Loader from "components/employer/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MentorManagerAction from "./MentorManagerAction";
import { toast } from "react-toastify";


export default function MentorManagerMentorList(){


const [search, setSearch] = useState(() => "")
  const [mentorData,setMentorData] = useState([])
const [mentorDataCount,setMentorDataCount] = useState([])

  const [loading,setLoading]=useState(false)
  const[refresh,setRefresh]=useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axios.get("/v1/mentor");
      // console.log(response?.data,"mentor");
      setMentorDataCount(response?.data)
      setMentorData(response?.data?.data)
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh])


  const handleActivateMentorAccount = async (id) => {
    try {
      setRefresh(true)
      const response = await axios.delete(`/v1/mentor/mentor/${id}`);
      if (!response.data?.error) {
        toast.info(response?.data?.message);
        setRefresh(false)
        // onDelete(id);
      } else {
        toast.error(response?.data?.error);
        setRefresh(false)
      }
    } catch (error) {
      console.error(error);
      setRefresh(false)
      toast.error("An error occurred while deleting the mentor.");
    }


    }
 

  const columns = [
    {
      Header: "Date Created",
      accessor: "createdAt",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.createdAt}
          </strong>
        );
      },
    },
    {
      Header: "Mentor Name",
      accessor: "mentor_name",
      width: "17.5%",
      textalign: "center",
    },
    {
      Header: "Email",
      accessor: "email_address",
      width: "17.5%",
      textalign: "center",
    },
    {
      Header: "Industry Sector",
      accessor: "industry_sector",
      width: "22.5%",
      textalign: "center",
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.phone_number}
          </strong>
        );
      },
    },
    {
      Header: "Mentor Charges",
      accessor: "mentor_charges",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.mentor_charges}
          </strong>
        );
      },
    },
   
    {
      Header: "Action",
      accessor: "actions",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        if (data.row.original.deletedAt === "Activate") {
          return (
            <button
              onClick={() => handleActivateMentorAccount(data.row.original.id)}
              className="deactivate-btn bg-success"
            >
              Activate
            </button>
          );
        }

        return (
          <MentorManagerAction 
            data={data?.row.original}
            setRefresh={setRefresh}
           
          />
        );
       
      },
    },
  ];





  const rows = mentorData
    ?.filter((post) => {
      return post?.first_name?.includes(search?.toLowerCase()) || 
      post?.last_name?.includes(search?.toLowerCase()) ||
      post?.phone_number.includes(search?.toLowerCase()) ||
      post?.email_address.includes(search?.toLowerCase());
    //   post?.industry?.industry_name.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      // console.log(post, "post");
      return {
        id: post?.id,
        profile_photo: post?.profile_photo ,
        createdAt: moment(post?.createdAt)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
        mentor_name:
          post?.first_name + " " + post?.last_name,
          first_name:post?.first_name ||"",      
          last_name:post?.last_name ||"",      
          bio:post?.bio||"",      
          languages:post?.languages ||[],      
        phone_number: post?.phone_number || "-",
        designation: post?.designation || "",
        email_address:post?.email_address,
        mentor_charges:post?.mentor_charges || "-",
        industry_sector:post?.industry?.industry_name || "-",
        industry_id:post?.industry_sector || "-",
        industry_year_expreience:post?.industry_year_expreience || " ",
        linkedin:post?.linkedIn_link|| "",
        github:post?.github_link|| "",
        profile_link:post?.profile_link|| "",
        location: post?.location||"",
        gender: post?.gender||"",
        expreiences:post?.experience ||[],
        actions: "Update" ,
        deletedAt: post?.deletedAt ? "Activate" : "Deactivate",
        required_skills:post?.required_skills||[]
       
      };
    });
    // console.log("rowss",rows)


if(loading) return <Loader/>
    return(
        <div className="datatable-container mt-4 m-2 p-3">
            <div className="table_head flex">
              <div className="mobo_upload p-1">
                <h3 className="new-class-active">
                  <div>
                 Mentors <br />{" "}
                  <span className="light new-span-count">{mentorData.length || 0} records

                  {
                    <div className="new-type">
                      <div className="active">
                        Active - {mentorDataCount?.activeMentors || 0}
                      </div>
                      <div className="inactive">
                        Inactive - {mentorDataCount?.inactiveMentors || 0}
                      </div>
                    </div>
                  }
                  
                  </span>
                  </div>
                </h3>
    
                <Link
                  to=""
                  className="upload_btn_icon_mobo btn btn-primary"
                >
                  <i
                    className="fa-solid fa-cloud-arrow-down post-icons"
                    onClick={() => downloadCSV(rows)}
                  ></i>
                </Link>
              </div>
    
              <div className="add_icon non">
                <Link
                  to="/mentor-manager/add-mentor"
                  className="upload_btn_icon btn btn-primary text-white px-3"
                >
                  Add <i className="fa-solid fa-plus"></i>
                </Link>
                <button
                  className="upload_btn_icon btn btn-primary"
                  onClick={() => downloadCSV(rows)}
                >
                  <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
                </button>
    
                <label className="search_label">
                  <input
                    type="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </label>
              </div>
            </div>
    
            {/* <Datatable columns={columns} data={rows} /> */}
    
            <div className=" table-responsive">
            <Datatable columns={columns} data={rows} />
              </div>
          <div className="d-block d-sm-none">
           {/* <MobileViewEvaluationsRecievedTable columns={columns} data={rows} type={"mentor-manager"}/> */}
          </div>
    

          </div>
    )
}