import React, { useEffect, useState } from "react"
import "./paymentPopup.scss"
import { MdOutlineCurrencyRupee } from "react-icons/md"
import { toast } from "react-toastify"
import axios from "axios"
import { RxCross2 } from "react-icons/rx"
import Loader from "components/employer/Loader"

import { useSelector } from "react-redux"

import NewLocationTextFieldWithPopup from "components/formComponents/newLocationTextFieldPopup"

const NewPaymentPopup = ({
  data,
  initiatePayment,
  closeModal,
  applied,
  setApplied,
  
}) => {
  const [loading, setLoading] = useState(false)
  // const [applied, setApplied] = useState(false);
  const [coupon, setCoupon] = useState("")
  const [couponData, setCouponData] = useState({})
  const [isPopupOpen, updatePopup] = useState(() => false)
  const [value, setValue] = useState("")
  const user = useSelector((state) => state.auth.user)
  const [location, setLocation] = useState(user?.location);
  const [isInput, setIsInput] = useState(user?.location ? false : true);
 

  const validateCoupon = async () => {
    try {
      if (!coupon) return toast.error("Please enter coupon code")
      setLoading(true)
      const { data } = await axios.get(`/v1/student/validate-coupon/${coupon}`)

      if (data?.error) toast.error(data?.message)
      else {
        // setFinalAmount(
        //   data?.mentor_charges -
        //     (mentor?.mentor_charges * data?.data?.discountPercentage) / 100
        // );
        setApplied(true)
        setCouponData(data?.data)
        toast.success("Coupon applied successfully")
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  // console.log("daaaaaaaaaaaa", couponData);

  // Calculate discount amount
  const discountAmount = applied
    ? (data?.price * couponData?.discountPercentage) / 100
    : 0
  const basePrice = data?.price
  const cgst = (basePrice - discountAmount) * 0.09
  const sgst = (basePrice - discountAmount) * 0.09
  const amountPayable = basePrice - discountAmount + cgst + sgst

  // console.log("discount_basePrice_amountpayable", discountAmount, basePrice, amountPayable);

  // useEffect(()=> {
  //   setIsInput(true);
  // },[location]);

 

  // console.log("Location:", location);
  const handlePayment = (e) => {
    if (!location) {
      return toast.error("Location is required");
    }
    initiatePayment(e, data?.id, data?.price - discountAmount, coupon, location, isInput);
  };
 

  return (
    <div className="payment_popup">
      <div className={`${user?.location ? "have_location" : "payment_body"}`}>
        <span className="cross" onClick={closeModal}>
          <RxCross2 size={20} />
        </span>

        {isInput && (
          <div className="popup_header">
            <h3>Your Location</h3>
            <div className="mt-0 mb-0">
              <NewLocationTextFieldWithPopup
                id="location"
                isPopupOpen={isPopupOpen}
                updatePopup={updatePopup}
                path="/v1/location/city"
                key_name="location"
                withId="city"
                className="new_text_field"
                value={value}
                setValue={setValue}
                setLocation={setLocation}
                placeholder={"Enter your location"}
        
                
              />
            </div>
          </div>
        )}

        <div className="popup_header">
          <h3>
            Discount Coupon <span>(If you have)</span>
          </h3>
          <div className="coupon_input">
            <input
              type="text"
              placeholder="Enter code"
              onChange={(e) => setCoupon(e.target.value)}
            />
            <span onClick={validateCoupon}> Apply</span>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="price_breakdown">
            <div className="item">
              <span>Base Price: </span>{" "}
              <span>
                <MdOutlineCurrencyRupee />
                {basePrice}{" "}
              </span>
            </div>
            <div className="item">
              <span>Discount: </span>{" "}
              <span>
                -<MdOutlineCurrencyRupee />
                {discountAmount}{" "}
              </span>
            </div>
            <div className="item">
              <span>CGST (9%): </span>{" "}
              <span>
                <MdOutlineCurrencyRupee />
                {cgst}{" "}
              </span>
            </div>
            <div className="item">
              <span>SGST (9%): </span>{" "}
              <span>
                <MdOutlineCurrencyRupee />
                {sgst}{" "}
              </span>
            </div>

            <div className="divider"></div>
          </div>
        )}

        <div className="final_amount">
          <span>Amount Payable: </span>
          <span>
            <MdOutlineCurrencyRupee />
            {amountPayable}{" "}
          </span>
        </div>

        <div className="payment_btn">
          <button
            // onClick={(e) =>
            //   initiatePayment(e, data?.id, data?.price - discountAmount, coupon,location)

            // }
            onClick={(e)=>handlePayment(e)}
          >
            Proceed to pay
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewPaymentPopup
