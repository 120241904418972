import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getParsed } from "services/factories/getParsed";
import ListView from "view/dashboard/employer/components/list/ListView";
import StudentCard from "view/dashboard/employer/components/studentCard/StudentCard";
import StudentDetailsCard from "view/dashboard/employer/components/studentCard/studentDetailsCard/StudentDetailCard";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";
import StudentInviteFilter from "../studenInviteFilter.jsx/StudentInvite";
import "./suggestedCard.scss";

const Details = ({
  state,
  enrollments = [],
  post = {},
  handleStatusChange,
  method,
  new1,
  setNew1,
  // id,
}) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [educators, setEducators] = useState(0);
  const [inviteId, setInviteId] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [sentStudent, setSentStudent] = useState([]);
  const [rejectedStudent, setRejectedStudent] = useState([]);
  const [filterEnrolledStudents, setFilterEnrolledStudents] = useState([]);

  const { id } = useParams();

  const weekly_submissions = (
    getParsed(enrollments?.data[selectedStudent]?.weekly_submissions) || []
  )?.filter((item) => item?.report);

  const final_submission_report = getParsed(
    enrollments?.data[selectedStudent]?.final_submission_report
  );

  const [state1, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  // // console.log(state1, "hello world");

  useEffect(() => {
    const fetchData = async () => {
      const filters = Object.keys(state1).reduce((acc, key) => {
        if (state1[key] && state1[key].length) {
          acc[key] = state1[key];
        }
        return acc;
      }, {});

      // console.log(filters, "filters");

      const response = await axios.get(
        `/v1/enrollments/project/all-enrolled-students/${user.id}`,
        {
          params: {
            ...filters,
          },
        }
      );

      const response2 = await axios.get(
        `/v1/invitations/get_student_invites?status=1&method=${method}&opportunity_id=${id}`,
        {
          params: {
            ...filters,
          },
        }
      );

      const response3 = await axios.get(
        `/v1/invitations/get_student_invites?status=2&method=${method}&opportunity_id=${id}`,
        {
          params: {
            ...filters,
          },
        }
      );

      setEnrolledStudents(response?.data?.data);
      setSentStudent(response2?.data?.data);
      setRejectedStudent(response3?.data?.data);

      const responseId1 = response2?.data?.data?.map(
        (item) => item?.receiver_id
      );
      const responseId2 = response3?.data?.data?.map(
        (item) => item?.receiver_id
      );

      const allId = [...responseId1, ...responseId2];

      setEnrolledStudents((prev) =>
        prev?.filter((item) => !allId?.includes(item?.student?.id))
      );
    };

    fetchData();
  }, [refresh, state1]);

  // // console.log(enrolledStudents, "enrolledStudents");

  const [educatorDataDetails, setEducatorDataDetails] = useState();
  // useEffect(() => {
  //   const fetctEductatorData = async () => {
  //     const res = await axios.get(
  //       `/v1/institution/single/${institutions[educators].id}`
  //     );
  //     // console.log(res, "educatordata");
  //     setEducatorDataDetails(res?.data?.data);
  //   };
  //   fetctEductatorData();
  //   // // console.log("function 2 working")
  // }, [educators, institutions]);

  // // console.log(institutions, educators, educatorDataDetails, "institutions");

  const filterData = (searchTerm) => {
    const filteredResults = institutions?.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.institute &&
          item.institute.institution_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (item.institute_branch &&
          item.institute_branch.address
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (item.designation &&
          item.designation.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filteredResults);
  };
  useEffect(() => {
    filterData(searchTerm);
  }, [institutions, searchTerm]);

  return (
    <>
      {enrolledStudents?.length > 0 ? (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="searchbox">
                    <label>
                      {" "}
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {enrolledStudents
                      ?.filter((item) => {
                        return (
                          item?.student?.first_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item?.student?.last_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        );
                      })
                      ?.map((student, index) => {
                        const collegesFunction = () => {
                          setEducators(index);
                          setShow(true);
                          // console.log("function working");
                        };
                        return (
                          <div onClick={collegesFunction}>
                            <StudentCard students={student?.student} />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                  <StudentDetailsCard
                    studentDetails={enrolledStudents[educators]?.student}
                    action2={1}
                    text2="Send Invitation"
                    text1={"Not Interested"}
                    pageLength={enrolledStudents?.length}
                    setPage={setEducators}
                    currentPage={educators}
                    setRefresh={setRefresh}
                    method={method}
                    setNew1={setNew1}
                  />
                </div>
                {show && (
                  <div className="position-fixed col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobo_main_college_datail d-flex d-md-none ">
                    <i
                      class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                      aria-hidden="true"
                      onClick={() => setShow(false)}
                    >
                      {" "}
                      Back
                    </i>
                    <StudentDetailsCard
                      studentDetails={enrolledStudents[educators]?.student}
                      action2={1}
                      text2="Send Invitation"
                      text1={"Not Interested"}
                      pageLength={enrolledStudents?.length}
                      setPage={setEducators}
                      currentPage={educators}
                      setRefresh={setRefresh}
                      method={method}
                    />
                  </div>
                )}
                <StudentInviteFilter dispatch={dispatch} state1={state1} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  {/* <div className="searchbox">
                    <label>
                      {" "}
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </label>
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {enrolledStudents
                      ?.filter((item) => {
                        return (
                          item?.student?.first_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          item?.student?.last_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        );
                      })
                      ?.map((student, index) => {
                        const collegesFunction = () => {
                          setEducators(index);
                          setShow(true);
                          // console.log("function working");
                        };
                        return (
                          <div onClick={collegesFunction}>
                            <StudentCard students={student?.student} />
                          </div>
                        );
                      })}
                  </div> */}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                  {/* <StudentDetailsCard
                    studentDetails={enrolledStudents[educators]?.student}
                    action2={1}
                    text2="Send Invitation"
                    text1={"Not Interested"}
                    pageLength={enrolledStudents?.length}
                    setPage={setEducators}
                    currentPage={educators}
                    setRefresh={setRefresh}
                    method={method}
                    setNew1={setNew1}
                  /> */}
                </div>
                <StudentInviteFilter
                  flag={false}
                  dispatch={dispatch}
                  state1={state1}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SuggestedCollege = ({ state, method, new1, setNew1, ...props }) =>
  state ? (
    <Details setNew1={setNew1} new1={new1} {...props} method={method} />
  ) : (
    <List {...props} method={method} new1={new1} setNew1={setNew1} />
  );
const style1 = {
  display: "none",
};
const style2 = {
  background: "#47D065",
  borderRadius: "8px",
};
const style_i = {
  color: "white",
};
const List = ({ method, setNew1 }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [refresh, setRefresh] = useState(false);
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const [sentStudent, setSentStudent] = useState([]);
  const [rejectedStudent, setRejectedStudent] = useState([]);
  const [state1, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const filters = Object.keys(state1).reduce((acc, key) => {
        if (state1[key] && state1[key].length) {
          acc[key] = state1[key];
        }
        return acc;
      }, {});

      // // console.log(filters, "filters");

      const response = await axios.get(
        `/v1/enrollments/project/all-enrolled-students/${user.id}`,
        {
          params: {
            ...filters,
          },
        }
      );

      const response2 = await axios.get(
        `/v1/invitations/get_student_invites?status=1&method=${method}&opportunity_id=${id}`,
        {
          params: {
            ...filters,
          },
        }
      );
      // for reject student
      const response3 = await axios.get(
        `/v1/invitations/get_student_invites?status=2&method=${method}&opportunity_id=${id}`,
        {
          params: {
            ...filters,
          },
        }
      );

      setEnrolledStudents(response?.data?.data);
      setSentStudent(response2?.data?.data);
      setRejectedStudent(response3?.data?.data);

      const responseId1 = response2?.data?.data?.map(
        (item) => item?.receiver_id
      );
      const responseId2 = response3?.data?.data?.map(
        (item) => item?.receiver_id
      );

      const allId = [...responseId1, ...responseId2];

      setEnrolledStudents((prev) =>
        prev?.filter((item) => !allId?.includes(item?.student?.id))
      );
    };

    fetchData();
  }, [refresh, state1]);

  return (
    <>
      {enrolledStudents?.length > 0 ? (
        <div className="d-flex gap-4">
          <ListView
            text2="Send Invitation"
            style1={style1}
            style2={style2}
            style_i={style_i}
            id={id}
            data={enrolledStudents?.map((item) => item?.student)}
            method={method}
            refresh={refresh}
            setRefresh={setRefresh}
            tableFor="student"
            setNew1={setNew1}
          />
          <StudentInviteFilter dispatch={dispatch} state1={state1} />
        </div>
      ) : (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"></div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
                <StudentInviteFilter
                  flag={false}
                  dispatch={dispatch}
                  state1={state1}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestedCollege;
