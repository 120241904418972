import Accordian from "components/accordian";
import React, { useState } from "react";
import { get, useForm } from "react-hook-form";
import PersonalDetails from "view/dashboard/student/profile/PersonalDetails";

import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "services/actions/auth";
import EmployerPersonalDetails from "./EmployerPersonalDetails";
import { useNavigate } from "react-router-dom";

const Submit = () => {
  return (
    <button
      className="save_changes_btn btn btn-primary mx-4 text-white "
      type="submit"
      onClick={(e) => e?.stopPropagation()}
    >
      Save Changes
    </button>
  );
};

const EmployerProfile = () => {
  const [image, setImage] = useState(() => null);
  const [companyImage, setCompanyImage] = useState(() => null);
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(() => false);

  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      // languages: user?.languages || [],
      email_address: user?.email_address,
      location: user?.location || user?.branch?.address,
      // bio: user?.bio,
      phone_number: user?.phone_number,
      // gender: user?.gender,
      // location: user?.location,
      // linkedIn_link: user?.linkedIn_link,
      // profile_link: user?.profile_link,
      designation: user?.designation,
      // profile_pic: user?.profile_pic,
      // company_logo: user?.company_logo,
      // date_of_birth: user?.date_of_birth,
      // ceo_name: user?.ceo_name,
      industry_name: user?.industry?.industry_name,
      // industry_sector: user?.industry?.id,
      // industry_name: user?.industry?.industry_name,
      // website_url: user?.website_url,
      // industry_sector: user?.industry_sector,
      // company_logo:user?.company_logo,
    },
  });

  // console.log(formState?.errors);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (image && image?.size > 1000000) {
        toast.error("Image size should be less than 1MB");
        return;
      }

      if (!user["company_logo"] && !image) {
        toast.error("Company logo is required");
        return;
      }

      for (const key of Object.keys(data)) {
        if (user?.["industry"]?.["industry_name"] && !data?.["industry_name"]) {
          data["industry_name"] = user["industry"]["industry_name"];
        }
        if (user?.["location"] && !data?.["location"]) {
          data["location"] = user["location"];
        }
        if (!user[key] && !data[key]) {
          toast.error(`${key} is required`);
          return;
        }
      }

      let payload = {};

      if (image) {
        // Handle image upload
        const formData = new FormData();
        formData.append("profile_photo", image);
        formData.append("previous_photo", user.company_logo);

        const { data: imageData } = await axios.post(
          "/v1/employer/profile/image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!imageData?.data?.url) {
          toast.error("Image upload failed");
          setLoading(false); // Set loading to false here
          return;
        }

        payload.company_logo = imageData?.data?.url || "";
      }

      if (companyImage && companyImage?.size > 1000000) {
        toast.error("Image size should be less than 1MB");
        setLoading(false); // Set loading to false here
        return;
      }

      if (companyImage) {
        // Handle company image upload
        const formData = new FormData();
        formData.append("company_logo", companyImage);

        const { data: imageData } = await axios.post(
          "/v1/employer/profile/company/logo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!imageData?.data?.url) {
          toast.error("Company image upload failed");
          setLoading(false); // Set loading to false here
          return;
        }

        payload.company_logo = imageData?.data?.url || "";
      }

      if (user?.company_id) {
        data.company_id = user?.company_id;
      }

      if (data?.industry) {
        data.industry_sector = data?.industry?.id;
      }

      data.company_logo = payload.company_logo;

      const { data: profileData } = await axios.post(
        "/v1/employer/profile",
        data
      );

      if (data?.industry) {
        profileData.data.userData["industry"] = data?.industry || {};
      }

      if (profileData?.data) {
        payload.experiences = profileData?.data?.experience || [];
        payload.projects = profileData?.data?.project || [];
        payload.documents = profileData?.data?.document || [];
        toast.success("Profile updated successfully");
        dispatch(updateUser(profileData?.data?.userData));
        navigate("/employer/dashboard");
      } else {
        toast.error("Profile update failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="student-profile mx-sm-4 "
      onSubmit={handleSubmit(onSubmit)}
    >
      <Accordian
        title="PERSONAL DETAILS"
        defaultOpen={true}
        Component={<Submit />}
        className="card"
      >
        <EmployerPersonalDetails
          register={register}
          image={image}
          setImage={setImage}
          setValue={setValue}
          setCompanyImage={setCompanyImage}
        />
      </Accordian>
      {loading && (
        <div className="loading-container">
          <div className="text-center loading-wrapper">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default EmployerProfile;
