import axios from "axios"
import Loader from "components/employer/Loader"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import "../settings.scss"

const FeeAndCharges = () => {
  const [price, setPrice] = useState()
  const [cgst, setCgst] = useState(0)
  const [sgst, setSgst] = useState(0)
  const [qollabCharges, setQollabCharges] = useState(0)
  const [mentorAmmount, setMentorAmmount] = useState(0)
  const [isChecked, setIsChecked] = useState(false)
  const [show, setShow] = useState(true)
  const [editInput, setEditInput] = useState(false)

  ///*********** */
  const [mentorPrice, setMentorPrice] = useState('')
  const [newCgst, setNewCgst] = useState()
  const [newSgst, setNewSgst] = useState()
  const [inputValue, setInputValue] = useState()
  const [newQollabCharges, setNewQollabCharges] = useState()
  const [newMentorAmmount, setNewMentorAmmount] = useState()

  const [loading, setLoading] = useState(false);

  // // console.log(price)
  const handleChange = (e) => {
    const price = e.target.value
    const beforeTaxPrice = price / 1.18
    const gst = price - beforeTaxPrice // 18% GST
    const cgst = gst / 2
    const sgst = gst / 2
    const qollabCharges = beforeTaxPrice * 0.25
    const mentorAmmount = price - qollabCharges
    setPrice(price)
    setCgst(cgst)
    setSgst(sgst)
    setQollabCharges(qollabCharges)
    setMentorAmmount(mentorAmmount)
    setInputValue(price)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isChecked) {
      const response = await axios.post("/v1/mentor/profile", {
        mentor_charges: price,
      })

      if (response?.data) {
        toast.success("Profile updated successfully")
        setMentorPrice(price)
        setShow(false)
      } else {
        toast.error("Profile update failed")
      }
    } else {
      toast.error("Please agree to Qollabb Payment Policies")
    }
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get("/v1/auth/session/getSessionUser")
        const { data } = response.data
        const mentorPricedata = data?.mentor_charges
        
        if(data?.mentor_charges) setShow(false)
        setMentorPrice(mentorPricedata);
        // // console.log(mentorPricedata, "charges")

        // // console.log(data, "data");
        const newbeforeTaxPrice = mentorPrice / 1.18
        const new_gst = mentorPrice - newbeforeTaxPrice // 18% GST
        const newCgst = new_gst / 2
        const newSgst = new_gst / 2
        const newQollabCharges = newbeforeTaxPrice * 0.25
        const newMentorAmmount = mentorPrice - newQollabCharges
        setNewCgst(newCgst)
        setNewSgst(newSgst)
        setNewQollabCharges(newQollabCharges)
        setNewMentorAmmount(newMentorAmmount)
        // setMentorPrice(mentorPrice)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }

    fetchData()
  }, [mentorPrice])
  // // console.log(mentorPrice, "hello");
  // const mentorPrice = data?.mentor_charges;

  const Edit = () => {
    setShow(true)
    setInputValue(mentorPrice)
  }
  // console.log(mentorPrice,"mentorPrice +");

  if(loading) return <Loader />

  return (
    <div className="mentor-setting ">
      <div className="container-lg container-fluid">
        <div className="tab-pane">
          <div className="fee-charge-box">
            <h3>Fees & Charges</h3>
            <form className="fee-chargedetail">
              <p className="txt3">Set your charges</p>
             {show && <>  <input type="number" value={inputValue} onChange={handleChange} />
              {/* disabled={isEditDisabled} */}
              {price ? (
                <div className="charge">
                  <p>
                    <span>Qollabb Charges (25%)</span>{" "}
                    <span>₹{qollabCharges}</span>
                  </p>
                  <p>
                    <span>CGST (9%)</span> <span>₹{cgst}</span>
                  </p>
                  <p>
                    <span>SGST (9%)</span> <span>₹{sgst}</span>
                  </p>
                  <p className="earnings_style">
                    <span style={{ color: "#6149CD" }}>
                      Earning after Qollabb Share
                    </span>{" "}
                    <span className="fw-bold">₹{mentorAmmount}</span>
                  </p>
                  <p className="earnings_style">
                    <span>Total Charges to student (ex. GST)</span>{" "}
                    <span className="fw-bold">₹{price} </span>
                  </p>
                </div>
              ) : (
                <></>
                )}
                </> }

              {!show ? (
                <div className="charge">
                  <p>
                    <span>Qollabb Charges (25%)</span>{" "}
                    <span>₹{newQollabCharges}</span>
                  </p>
                  <p>
                    <span>CGST (9%)</span> <span>₹{newCgst}</span>
                  </p>
                  <p>
                    <span>SGST (9%)</span> <span>₹{newSgst}</span>
                  </p>
                  <p className="earnings_style">
                    <span style={{ color: "#6149CD" }}>
                      Earning after Qollabb Share
                    </span>{" "}
                    <span className="fw-bold">₹{newMentorAmmount}</span>
                  </p>
                  <p className="earnings_style">
                    <span>Total Charges to student (ex. GST)</span>{" "}
                    <span className="fw-bold">₹{mentorPrice} </span>
                  </p>
                </div>
              ) : (
                <></>
              )}

              {show && (
                <>
                  {" "}
                  <div className="payment_policies">
                    <input type="checkbox" onChange={handleCheckboxChange} />
                    <span>I agree to Qollabb Payment Policies</span>
                  </div>
                  <div className="btn-right">
                    <button className="cancel">Cancel</button>
                    <button
                      className="save"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </>
              )}


              {!show && (
                <div className="d-flex justify-content-end mt-2 ">
                  <p className="editCharges" onClick={Edit}>Edit charges</p>
                </div>
              )}
            </form>
          </div>
          <br /> <br />
        </div>
      </div>
    </div>
  )
}

export default FeeAndCharges
