import GoToTop from 'GoToTop'
import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from 'view/dashboard/student/footer/Footer'

import TopNavBar from 'view/newWeb/components/TopNavbar/TopNavBar'
import NavBar from 'view/newWeb/components/navbar/NavBar'
import OurTeam from 'view/newWeb/components/ourTeam/ourTeam'

export default function OurTeamPage(){
    return(
        <div>
        <Helmet>
            <meta
            charSet="utf-8"
            name="description"
            content={`Vipendra Singh (Founder-CEO) , Ayush Singhal (Chief Technical Officer) , Pooja Banerjee (HR & Operations) , Amisha Sinha (Strategic Partnerships).`}
            />
                <meta
                name="title"
                content={"Meet Our Team"}
                />
            <title>
            Meet Our Team
            </title>
            {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
        <TopNavBar />
        <NavBar />
        <div className='mt-5'>
        <OurTeam/>


        </div>
       
       
        <Footer/>
        <GoToTop />

         </div>
    )
}