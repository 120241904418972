import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { initialState, reducer } from "view/dashboard/student/explore/handleFilters";
import CompaniesCard from "../../components/CompanyCard/CompaniesCard/CompaniesCard";
import CompaniesDetailCard from "../../components/CompanyCard/CompaniesDetailCard/CompaniesDetailCard";
import EducatorFilters from "../../components/EducatorFilter/EducatorFilters";
import "./suggestCompanies.scss";

const SuggestCompanies = ({ company, setRefresh, onFilterChange }) => {
  // const [company, setCompany] = useState([]);
  const [employer, setEmployer] = useState(0);
  const [show, setShow] = useState(false);
  // const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [state1, setState1] = useState({});
  const [search, setSearch] = useState("");

  const user = useSelector((state) => state.auth.user);


  const { state: location } = useLocation();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selected: location,
  });

  console.log("educatorState",state)

  useEffect(()=>{
    try {
            const filters = Object?.keys(state).reduce((acc, curr) => {
              if (curr === "selected") return acc;
              else if (state[curr] instanceof Set) {
                if (!state[curr].size) return acc;
                const set = [...new Set(state[curr])];
                return { ...acc, [curr]: set };
              } else if (state[curr]) {
                return { ...acc, [curr]: state[curr] };
              }
              return acc;
            }, {});
            onFilterChange(filters)
        }catch(err){
          console.warn('error in filtering')
        }
            
   
  },[state.industry_sector]) 



  // const [refresh, setRefresh] = useState(() => false);

  // useEffect(() => {
  //   const fetchDataAndStoreInState = async (url, status) => {
  //     try {
  //       const response = await axios.get(url);
  //       setState1((prevState) => ({
  //         ...prevState,
  //         [status]: response?.data,
  //       }));
  //     } catch (error) {
  //       console.error(`Error fetching data for status ${status}:`, error);
  //     }
  //   };

  //   const requests = [
  //     { url: "/v1/employer", status: 0 },
  //     { url: "/v1/invitations/get_invites?status[]=0", status: 1 },
  //     {
  //       url: `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}`,
  //       status: 2,
  //     },
  //     {
  //       url: `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`,
  //       status: 3,
  //     },
  //   ];

  //   // Use Promise.all to make multiple requests concurrently
  //   Promise.all(
  //     requests.map(({ url, status }) => fetchDataAndStoreInState(url, status))
  //   );
  // }, [refresh]);

  // useEffect(() => {
  //   const fetchInstitutions = async () => {
  //     const res = await axios.get("/v1/employer");
  //     setCompany(res?.data?.data);
  //   };
  //   fetchInstitutions();
  // }, []);

  // const [suggesteData, setSuggesteData] = useState([]);

  // useEffect(
  //   () => {
  //     setCompany(state1?.[0]?.data || []);
  //     const state1DataIds =
  //       state1?.[1]?.data?.map((item) => {
  //         console.log(item?.receiver_id, "item_receiver");
  //         return item.receiver_id;
  //       }) || [];
  //     const state2DataIds =
  //       state1?.[2]?.data?.map((item) => {
  //         console.log(item?.sender_id, "item_sender");
  //         return item.sender_id;
  //       }) || [];

  //     const state3DataIds = state1?.[3]?.data?.map((item) => {
  //       if (item?.sender_emp) {
  //         return item?.sender?.id;
  //       }
  //       return item?.receiver?.id;
  //     });

  //     // console.log(state1DataIds, state2DataIds, state3DataIds, "ids");
  //     // console.log(state1, "Stat1");

  //     setCompany((prevState) => {
  //       return prevState?.filter((item) => {
  //         return (
  //           !state1DataIds?.includes(item?.id) &&
  //           !state2DataIds?.includes(item?.id) &&
  //           !state3DataIds?.includes(item?.id)
  //         );
  //       });
  //     });
  //   },
  //   [state1[0]],
  //   [state1[1]],
  //   [state1[2]],
  //   [state1[3]]
  // );

  // useEffect(() => {
  //   dispatch(
  //     educatorInvitesNumber(
  //       company?.length,
  //       state1?.[1]?.data?.length,
  //       state1?.[2]?.data?.length,
  //       state1?.[3]?.data?.length
  //     )
  //   );
  // }, [state1, dispatch]);

  // console.log(company, "company");

  const [educatorDataDetails, setEducatorDataDetails] = useState();
  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(`/v1/company/single/${company[employer].id}`);
      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
  }, [employer, company]);
  // console.log(company, employer, educatorDataDetails, "institutions");

  const handleToggle = () => {
    setOpen((toggle) => !toggle);
  };

  const handleFilterToggle = () => {
    setFilter((toggle) => !toggle);
  };

  const style = {
    borderBottom: " 2px solid #6149CD",
  };

  // console.log(company, "company");

  const rejectInvite = (e, id) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 2,
      })
      .then((res) => {
        // console.log(res, "res");
        toast.success("Invitation Rejected");
      })
      .catch((err) => {
        // console.log(err, "err");
        toast.error("Invitation Rejected");
      });
  };

  // console.log(company?.length, "company");

  // console.log(company, "company");

  // console.log(company, "company");

  return (
    <>
      <div className="companies-component"> 
        {/* <CompanyTop companyLength={company?.length} active2={true} /> */}

        {/* Mobile Search Toggle  */}

        {/* Filter Toggle Close Button  */}

        {/* {company?.length > 0 ? ( */}
        {company?.length > 0 ? (
          <div className="companies-left">
            <div className="companies_left_container">
              
              <div className="searchbox w-100 hidesearchbox ">
       
                <label>
                  {" "}
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Search company"
                  />
                  <i className="fa-solid fa-magnifying-glass text-black"></i>
                </label>
              
                <i
                 
                  className="fa-solid fa-sliders fa-lg d-flex d-md-none filter_mobo"
                  // className="filter_mobo fa fa-sliders d-flex d-sm-none"
                  onClick={() => handleFilterToggle()}
                  style={{ color: "#6149CD" }}
                ></i>
           
         
              </div>

{/* mobile view search box and filter */}

       <div className="searchbox-container d-md-none gap-2  pl-1 pr-1">

             <div className="searchbox w-100">
                              <label>
                       <input
                             onChange={(e) => setSearch(e.target.value)}
                             type="text"
                           placeholder="Search candidate"
                             />
                      <i className="fa-solid fa-magnifying-glass text-black"></i>
                           </label>
                </div>

             <div className="">
               <i
                  className="fa-solid fa-sliders fa-lg filter_mobo"
                  onClick={() => handleFilterToggle()}
                style={{ color: "#6149CD" }}
                ></i>
                 </div>
        </div>     
              
{/* ---------------- */}
              <div className="scroll_components scrollViewAllCompany mobileViewCompanyCard">
                {
                company
                  ?.filter((item) => {
                    return (
                      item?.company?.company_name
                        .toLowerCase()
                        .includes(search) ||
                      (item?.first_name + " " + item?.last_name)
                        .toLowerCase()
                        .includes(search)
                    );
                  })
                  ?.map((company, index) => {
                    const collegesFunction = () => {
                      setEmployer(index);
                      handleToggle();
                    };
                    return (
                      <div className="w-100 p-2 m-2" onClick={collegesFunction}>
                        <CompaniesCard college={company} />
                      </div>
                    );
                  })}
              </div>
              </div>
                    
            {/* <div className="companies_mid_container d-none d-sm-block"> */}
            <div className="companies_mid_container">
              <CompaniesDetailCard
                // text1="Not Interested"
                text2="Send Invitation"
                emploayerDataDetails={company[employer]}
                cancelInvite={rejectInvite}
                invitationId={company[employer]?.id}
                cancel={true}
                setPage={setEmployer}
                currentPage={employer}
                pageLength={company?.length}
                setRefresh={setRefresh}
              />
            </div>

            {/* {open && (
              <div className="companies_mid_container_mobo d-flex d-sm-none">
                <i
                  class="cross_icon fa fa-times"
                  onClick={() => handleToggle()}
                  aria-hidden="true"
                ></i>
                <CompaniesDetailCard
                  text2="Send Invitation"
                  emploayerDataDetails={educatorDataDetails}
                  cancelInvite={rejectInvite}
                  cancel={true}
                />
              </div>
            )} */}

            <div className="company_right_container">
              <EducatorFilters state={state} dispatch={dispatch} />
            </div>
            {filter && (
              <div className="company_right_container_mobo d-block d-sm-none">
                <i
                  class="cross_icon fa fa-times text-end m-3"
                  onClick={() => handleFilterToggle()}
                  aria-hidden="true"
                ></i>
                <EducatorFilters state={state} dispatch={dispatch} />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="new-post">
                <div className="flex">
                  <div>
                    <h3>
                      <b>No Companies Found</b>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SuggestCompanies;
