import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./applicationProjectDetail.scss";
import cmp from "./company.png";
import PreviewField from "components/employer/PreviewField";
import ListContainer from "components/employer/company_form/ListContainer";
import Wpimg from "../../../../../images/wp.png";
import ExitProjectModal from "../modals/ExitProjectModal";
import useFetch from "hooks/useFetch";
import { useParams } from "react-router-dom";
import PostDetailsSection from "components/posts/PostsHelper/PostDetailsSection";

const ApplicationProjectDetail = () => {
  const { id } = useParams();
  const { data, loading, error, setFetchData } = useFetch(
    `/v1/enrollments/project/progress/${id}`
  );
  const [open, setOpen] = useState(false);

  const cancel = () => {
    setOpen(false);
  };

  const projectdata = data?.data?.project;
  // console.log("detail data", projectdata, id)

  const company = [
    {
      website_url: "https://www.google.com",
      year_of_incorporation: "1999",
      ceo_name: "Sparsh Prajapati",
      employee_strength: "355",
      industry_sector: "information Technology",
      services: {},
    },
  ];

  const tab = {
    0: "Applied",
    4: "Shortlisted",
    6: "Hired",
    7: "Rejected",
    8: "Exit",
  };

  const cleanedString = projectdata?.faq
    ?.replace(/^\[|]$/g, "")
    ?.replace(/\\/g, "");

  // Split the cleaned string at each comma to create an array
  const faqArray = cleanedString ? cleanedString.split(",") : [];

  const cleanedFaqArray = faqArray.map((item) => item.replace(/(^"|"$)/g, ""));

  // const faqArray=[]

  // console.log(projectdata?.faq ,cleanedFaqArray, "projectdata")
  console.log("ppp",projectdata);

  const user = useSelector((state) => state.auth.user);
  return (
    <div className="Application_project_container my-2  container">
      <div className="preview_box">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 ">
          <PostDetailsSection data={projectdata} type={"project"}/>
          </div>
          <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <button
            className="Exit_project_btn"
            onClick={() => setOpen(true)}
          >
            Exit Project
          </button>

          </div>
         
        </div>

        {/* <section>
          <div className="body_container">

          <PostDetailsSection data={projectdata} type={"project"}/>
          </div>
       
        </section> */}
      </div>

      {open && (
        <div className="danger_modal">
          <ExitProjectModal id={id} onCancel={cancel} />
        </div>
      )}
    </div>
  );
};
export default ApplicationProjectDetail;



{/* <div className="body_container">
<PreviewField
  label="Project Title"
  text={projectdata?.project_title}
/>
<PreviewField
  label="What is the main goal for the Project?"
  text={projectdata?.project_goal}
/>
<PreviewField
  label="What tasks students will be required to undertake in order to successfully complete the Project?"
  text={projectdata?.project_tasks}
/>
<PreviewField
  label="What support will you provide to students to help them complete the Project?"
  text={projectdata?.project_support}
/>
<PreviewField
  label="What skills need to be acquired before the candidates begin the work on the Project?"
  items={projectdata?.required_skills}
  type="chips"
/>
{/* <PreviewField
  label="Required Qualification"
  items={projectdata?.course_preferred}
  key_name="course_name"
  type="chips"
/> 
<PreviewField
  label="Location"
  text={projectdata?.branch?.address}
/>

<PreviewField
  label="Total duration of the Project"
  text={
    projectdata?.duration_from +
    "-" +
    projectdata?.duration_to +
    " Weeks"
  }
/>

<PreviewField
  label="Additional Information & Resources"
  // text={project?.project_category}
  text={projectdata?.additional_info}
/>
<PreviewField label="FAQs" items={cleanedFaqArray} type="faq" />
<PreviewField
  label="Name The Courses From Which You Would Prefer Students To Undertake This Project*"
  items={projectdata?.course_preferred}
  type="chips"
  key_name="course_name"
/>


</div> */}