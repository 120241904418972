import { useLocation, useNavigate } from "react-router-dom"

const { useState } = require("react")

const useHandleFilters = ( industries, courses, company, locations, dispatch, setIsBottomSheetOpen, role, parseFiltersFromUrl,skills)=> {

    const filterMap = {
      0: "projects",
      1: "internships",
      2: "jobs",
    }

    const userRole={
      3:"educator",
      4:"student"
    }

    const location = useLocation();


    const [suggestComp, setSuggestComp] = useState("")
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState({
        companies: parseFiltersFromUrl(location?.pathname)?.filters?.companies.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.companies : ["all"],
        industries: parseFiltersFromUrl(location?.pathname)?.filters?.industries.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.industries : ["all"],
        qualifications: parseFiltersFromUrl(location?.pathname)?.filters?.qualifications?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.qualifications : ["all"],
        skills: parseFiltersFromUrl(location?.pathname)?.filters?.skills?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.skills : ["all"],
        locations: parseFiltersFromUrl(location?.pathname)?.filters?.locations?.length !== 0 ? parseFiltersFromUrl(location?.pathname)?.filters?.locations :  ["all"],
        salaries: [],
      })

      // console.log("selecteeditmeee", selectedItems);

    const handleCheckboxChange = (item, setSelectionBox) => {
        let updatedSelectedItems = { ...selectedItems }
      
        // console.log("itemmmmmmm", item);

        if (item === "all") {
          // If "all" checkbox is clicked, toggle selection of all other items
          if (updatedSelectedItems[setSelectionBox].includes("all")) {
            // Deselect all items if "all" is already selected
            updatedSelectedItems[setSelectionBox] = []
          } else {
            // Select all items if "all" is not already selected
            updatedSelectedItems[setSelectionBox] = ["all"]
          }
        } else {
          // Toggle selection of the clicked item
          if (updatedSelectedItems[setSelectionBox].includes(item)) {
            updatedSelectedItems[setSelectionBox] = updatedSelectedItems[
              setSelectionBox
            ].filter((selectedItem) => selectedItem !== item)
          } else {
            updatedSelectedItems[setSelectionBox] = [
              ...updatedSelectedItems[setSelectionBox],
              item,
            ]
          }
          // If any other item is selected, remove "all" from selected items
          updatedSelectedItems[setSelectionBox] = updatedSelectedItems[
            setSelectionBox
          ].filter((selectedItem) => selectedItem !== "all")
        }
    
        // console.log("updateddd", updatedSelectedItems);
    
        setSelectedItems(updatedSelectedItems)
    
        if (setSelectionBox === "industries") {
          const matchedIds = []
    
          industries.forEach((item) => {
            // console.log("iiiiii", item)
            if (updatedSelectedItems["industries"].includes(item?.industry_name)) {
              matchedIds.push(item?.id)
            }
          })
    
          // console.log("matched", matchedIds);
    
          dispatch({
            type: "change_value",
            payload: { industry_sector: matchedIds },
          })
        }
    
        if (setSelectionBox === "qualifications") {
          const matchedIds = []
    
          courses.forEach((item) => {
            // console.log("iiiiii", item)
            if (
              updatedSelectedItems["qualifications"].includes(item?.course_name)
            ) {
              matchedIds.push(item?.id)
            }
          })
    
          // console.log("matched", matchedIds);
    
          dispatch({
            type: "change_value",
            payload: { course_preferred: matchedIds },
          })
        }


        if (setSelectionBox === "skills") {
          const matchedIds = []
    
          skills.forEach((item) => {
            // console.log("iiiiii", item)
            if (
              updatedSelectedItems["skills"].includes(item?.name.toLowerCase())
            ) {
              matchedIds.push(item?.id)
            }
          })
    
          console.log("matched", matchedIds);
    
          dispatch({
            type: "change_value",
            payload: {skills: matchedIds },
          })
        }
    
        if (setSelectionBox === "companies") {
          const matchedIds = []
    
          company.forEach((item) => {
            // console.log("iiiiii", item)
            if (updatedSelectedItems["companies"].includes(item?.company_name)) {
              matchedIds.push(item?.id)
            }
          })
    
          // console.log("cmatched", matchedIds);
    
          dispatch({
            type: "change_value",
            payload: { company_ids: matchedIds },
          })
        }
    
        if (setSelectionBox === "locations") {
          const matchedIds = []
    
          locations.forEach((item) => {
            // console.log("litem", item)
            if (updatedSelectedItems["locations"].includes(item?.city)) {
              matchedIds.push(item?.city)
            }
          })
    
          // console.log("lmatched", matchedIds);
    
          dispatch({
            type: "change_value",
            payload: { address: matchedIds.length !== 0 ? matchedIds : undefined },
          })
    
        }
    
        // setSelectedItems(prevState => ({
        //   ...prevState,
        //   [setSelectionBox]: prevState[setSelectionBox].includes(item)
        //     ? prevState[setSelectionBox].filter(selectedItem => selectedItem !== item)
        //     : [...prevState[setSelectionBox], item]
        // }));
    
        const filters = {};
        Object.keys(updatedSelectedItems).forEach((key) => {
          filters[key] = updatedSelectedItems[key].filter((item) => item !== "all");
        });
    
    
        dispatch({
          type: "change_value",
          payload: { filters: filters },
        })
    
    
      }
  


    const handleCompanyFilter = async (item, id, isSingle) => {
        // console.log("compppppitem", item)
    
        // console.log("issingle", isSingle, id);
    
        if (isSingle) {
          setSelectedItems((prev) => ({
            ...prev,
            companies: [item],
          }))
    
          dispatch({
            type: "change_value",
            payload: { company_ids: [id] },
          })
    
          setSelectedItems((prev) => ({
            ...prev,
            companies: ["all"],
          }))
    
          setSuggestComp(item)
        } else {
          setSuggestComp("")
    
          handleCheckboxChange(item, "companies")
        }
      }
    
      // console.log("industry", industries);
      const handleIndustryFilter = async (item) => {
        // console.log("item", item)
        handleCheckboxChange(item, "industries")
    
        // dispatch({
        //   type: "change_value",
        //   payload: {industry_sector: selectedItems },
        // });
      }
      const handleQualificationFilter = async (item) => {
        // console.log("item", item)
        handleCheckboxChange(item, "qualifications")
      }
      const handleSkillFilter = async (item) => {
        // console.log("item", item.toLowerCase())
        handleCheckboxChange(item.toLowerCase(), "skills")
      }
      // const handleSalaryFilter = async (item)=> {
      //   // console.log("item", item)
      //   handleCheckboxChange(item, "salaries");
    
      // }
    
      const handleLocationFilter = async (item) => {
        // console.log("item", item)
        handleCheckboxChange(item, "locations")
      }


      const handleSortChange = (e) => {
        const selectedSortOption = e.target.value
    
        const order = ["id,desc", "id,asc", "stipend_to,desc"]
        dispatch({
          type: "change_value",
          payload: { order: order[selectedSortOption] },
        })
    
        // close bottom sheet  when user selects an option
        setIsBottomSheetOpen((prev)=> !prev)
    
        // For example: sortData(selectedSortOption);
      }
    
      // handle opportunity change
      const handleChangeOpportunity = async (item, index) => {    
        
        dispatch({
          type: "change_value",
          payload: { selected: index},
        })
        
        // updateURL()
        // navigate(`top-${filterMap[index]}`)
    
        const rolePath = role === 3 || role === 4 ? `/${userRole[role]}/explore` : "/explore-page" ;
        navigate(`${rolePath}/top-${filterMap[index]}`);
    
      }
      



    return {
        selectedItems, setSelectedItems,
        suggestComp, setSuggestComp, handleCompanyFilter, 
        handleIndustryFilter, handleQualificationFilter,
        handleLocationFilter, handleCheckboxChange,
        handleChangeOpportunity, handleSortChange,handleSkillFilter


    }
}

export default useHandleFilters;