import { useLocation, useNavigate } from "react-router-dom";
import SidebarButtons from "../sidebarButtons/SidebarButtons";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logoutUser } from "services/actions/auth";
import WhiteLogo from "../../../images/logos/logo-white.png";
import "./sidebar.scss";

// import ToolTip from "../sidebarButtons/ToolTip"

const Sidebar = ({
  showSidebar,
  toggleSidebar,
  projects,
  internships,
  setShowSidebar = () => {},
  jobs,
}) => {
  const { pathname } = useLocation();
  const isActive = pathname.split("/")[2];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  // console.log(isActive, "pathname");

  const logout = async () => {
    const {
      data: { data, error },
    } = await axios.delete("/v1/auth/session/", {
      validateStatus: () => true,
    });

    if (!error) {
      toast.info("Logged out successfully!");
      navigate("/signin");
      dispatch(logoutUser({ cookieDeleted: false }));
      localStorage.clear();
    } else {
      toast.error("Something went wrong!");
    }
  };

  // // console.log(user, "user")

  return (
    <div className={showSidebar ? "sidebar show" : "sidebar"}>
      <div className="brand-container px-3 pt-2 d-flex justify-content-between align-items-center">
        <img
          onClick={() => navigate("/")}
          src={WhiteLogo}
          className="dashboard-logo"
          height="30px"
          width="120px"
          alt="Image"
        />
        <span
          className={`close_btn ${showSidebar ? "show" : ""} .d-lg-none`}
          onClick={() => {
            toggleSidebar(false);
          }}
        ></span>
      </div>
      <hr />
      <div className="sidebar-buttons">
        <SidebarButtons
          text="Dashboard"
          link="/employer/dashboard"
          icon="fa-solid fa-house"
          locked={false}
          myClass={
            // isActive === "dashboard" ||
            // (/project|internship|job/.test(isActive) &&
            //   !projects &&
            //   !internships &&
            //   !jobs)
            //   ? "active"
            //   : ""
            isActive === "dashboard" ? "active" : ""
          }
          setShowSidebar={setShowSidebar}
        />

        <SidebarButtons
          text="Projects"
          link="/employer/project/list"
          icon="fas fa-tasks"
          setShowSidebar={setShowSidebar}
          // locked={!internships && !projects && !jobs}
          locked={!user?.industry_sector && true}
          myClass={isActive === "project" ? "active" : ""}
          // myClass={isActive === "project" ? "active" : ""}
        />

        <SidebarButtons
          text="Internships"
          link="/employer/internship/list"
          icon="fas fa-id-badge"
          setShowSidebar={setShowSidebar}
          // locked={!internships && !projects && !jobs}
          locked={!user?.industry_sector && true}
          myClass={isActive === "internship"  ? "active" : ""}
          // myClass={isActive === "internship" ? "active" : ""}
          // myClass={
          //   isActive === "internship" || isActive === "Internship"
          //     ? "active"
          //     : ""
          // }
        />

        <SidebarButtons
          text="Jobs"
          link="/employer/job/list"
          icon="fas fa-briefcase"
          setShowSidebar={setShowSidebar}
          // locked={!internships && !projects && !jobs}
          locked={!user?.industry_sector && true}
          myClass={isActive === "job"  ? "active" : ""}
          // myClass={isActive === "job" ? "active" : ""}
        />

        <SidebarButtons
          text="Partner Institutions"
          link="/employer/partner-institutions"
          icon="fa-solid fa-user-group"
          locked={false}
          setShowSidebar={setShowSidebar}
          // myClass={isActive === "partner" ? "active" : ""}
          myClass={isActive === "partner-institutions" ? "active" : ""}
        />

        <SidebarButtons
          text="Subscription"
          link="/employer/subscription"
          icon="fa-solid fa-file-circle-check"
          locked={false}
          myClass={isActive === "subscription" ? "active" : ""}
        />
        <SidebarButtons
          text="Settings"
          link="/employer/settings"
          icon="fa-solid fa-gear"
          locked={false}
          setShowSidebar={setShowSidebar}
          myClass={isActive === "settings" ? "active" : ""}
        />
        <SidebarButtons
          text="Helpdesk"
          link="/employer/helpdesk"
          icon="fas fa-headset"
          locked={false}
          setShowSidebar={setShowSidebar}
          myClass={isActive === "helpdesk" ? "active" : ""}
        />
        <SidebarButtons
          text="Logout"
          onClick={logout}
          icon="fa-solid fa-sign-out-alt"
          locked={false}
        />
      </div>
    </div>
  );
};

export default Sidebar;
