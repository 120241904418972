import React from "react"
import "./contact.scss"

const ContactUs = () => {
  return (
    <div className="contact_us">
      <div className="container">
        <div className="main_content">
          <h3>Support Contact</h3>

          <div className="innerContent">
            <div className="box_inner d-flex align-items-start">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <div className="content">
                <p>
                  <b>Phone</b>
                </p>
                <span>
                  Mobile :<b>(+91) 742-809-9546</b>{" "}
                </span>
              </div>
            </div>
            <div className="box_inner d-flex align-items-start">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <div className="content">
                <p>
                  <b>Email</b>
                </p>
                <span>
                  <a href="mailto:info@qollabb.com?subject=Resume%20for%20the%20role%20of%20Mentor&body=Please%20find%20attached%20my%20resume%20for%20the%20role%20of%20mentor." >info@qollabb.com</a>
                  <br/>
                  
                
               
                  <a href="mailto:support@qollabb.com?subject=Resume%20for%20the%20role%20of%20Mentor&body=Please%20find%20attached%20my%20resume%20for%20the%20role%20of%20mentor." >support@qollabb.com</a>
                </span>
              </div>
            </div>
            <div className="box_inner d-flex align-items-start">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <div className="content">
                <p>
                  <b>Address</b>
                  <h3>Qollabb Edutech Private Limited</h3>
                </p>
                <div style={{display: "flex", alignItems: "center", gap: "10px" ,justifyContent: "space-between"}}>
                  <span style={{width: "45%"}}>
                    <h3>Noida:</h3>
                    {/* Qollabb EduTech Private Limited <br />  */}
                    C-340, Tower-C, Bhutani Cyberpark, Sector-62,
                    Noida, Uttar Pradesh- 201309, India{" "}
                  </span>
                  <span style={{width: "50%"}}>
                  <h3>Bangalore:</h3>
                    {/* Qollabb EduTech Private Limited <br />  */}
                    231/4 SF - II, Behind Silk Board Building, BTM Second Stage,
                    Bangalore, Karnataka - 560068, India{" "}
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
