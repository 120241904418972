import React, { useState } from "react"
import "../style.scss"
import AdminSubscriptionTable from "../table"
import SubscriptionHistoryTable from "../table/SubscriptionHistoryTable"
import Datatable from "components/datatable-new/DatatableNew"
import axios from "axios"
import { toast } from "react-toastify"

const AdminInstituteSubscription = () => {
  const plans = [
    {
      plan: "Invite companies directly ",
      free: <span className="text-color-grey">10 Invitations per month</span>,
      monthly: (
        <span className="text-color-grey">10 Invitations per month</span>
      ),
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Partner with companies",
      free: <span className="text-color-grey">Maximum 10 companies</span>,
      monthly: <span className="text-color-grey">Maximum 50 companies</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Upload your student lists and send project work, Internship and Job opportunities to your students",
      free: <span className="text-color-grey">Maximum 100 students</span>,
      monthly: <span className="text-color-grey">Maximum 500 students</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Track student progress during project work period",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Get preferentials notifications related to newly posted project, internships andjobs by companies",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Promoting your university/college among companies	",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
  ]

  const history = [
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
    {
      date: "2 Oct 2022",
      monthly: "₹ 1000",
      yearly: "₹ 2000",
    },
  ]
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      width: "15%",
      textalign: "center",
      // Cell: (data) => {
      //   return (
      //     <strong style={{ fontWeight: "700" }}>
      //       {data.row.original.date}

      //     </strong>
      //   )
      // },
    },
    {
      Header: "Monthly",
      accessor: "monthly",
      width: "15%",
      textalign: "center",
    },
    {
      Header: "Yearly",
      accessor: "yearly",
      width: "15%",
      textalign: "center",
    },
    {
      Header: " ",
      accessor: "",
      width: "55%",
    },
  ]

  const [collegeSubscriptionValue ,setCollegeSubscriptionValue]=useState({
    monthly:'',
    yearly:''
  })

const handleCollegeSubscriptionChange=(e,type)=>{
  const price =e.target.value;
  setCollegeSubscriptionValue({
    ...collegeSubscriptionValue,
    [type]:price
  })

}


// console.log("plan0",collegeSubscriptionValue)
// console.log("plan1",collegeSubscriptionValue.monthly)
// console.log("plan2",collegeSubscriptionValue.yearly)

const handleSaveChanges = async () => {
  try {
    if (collegeSubscriptionValue?.monthly) {
      const monthlyResponse = await axios.put(`/v1/subscription/subscription-update/5`, { price: collegeSubscriptionValue?.monthly });
      // console.log("Monthly", monthlyResponse);
      toast.success('Monthly Subscription Updated Successfully!')
      
    }
    
    if (collegeSubscriptionValue?.yearly) {
      const yearlyResponse = await axios.put(`/v1/subscription/subscription-update/6`, { price: collegeSubscriptionValue?.yearly });
      // console.log("Yearly subscription updated:", yearlyResponse);
      toast.success('Yearly Subscription Updated Successfully!')
    }
    
    // Reset updatedSubscription and companySubscriptionValue after successful update

    setCollegeSubscriptionValue({
      monthly: '',
      yearly: ''
    });
  } catch (error) {
    console.error("An error occurred while updating subscription:", error);
    
  }

};


  return (
    <div className="admin-subscriptions-container">
      <div className="row mx-0 subscription-topbar">
        <div className="col-md-4 col-12 d-flex align-items-center pe-0">
          <h3>Upgrade Your Plan </h3>
        </div>
        <div className="col-md-8 col-12 ps-0">
          <div className="row mx-0">
            <div className="col-4 ps-0">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Free Plan</h4>
                <button className="btn btn-outline-primary">Active</button>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Monthly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex mb-3 flex-wrap">
                    <span>₹</span>
                    <input type="number" onChange={(e)=>handleCollegeSubscriptionChange(e,'monthly')} value={collegeSubscriptionValue?.monthly}/>
                    <span>/month</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Yearly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex mb-3 flex-wrap">
                    <span>₹</span>
                    <input type="number" onChange={(e)=>{handleCollegeSubscriptionChange(e,'yearly')}} />
                    <span>/year</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminSubscriptionTable data={plans} />
      <div className="cancel-save-buttons d-flex align-items-center justify-content-end my-3 me-4">
        <button className="btn btn-outline-primary me-2">Cancel</button>
        <button className="btn btn-primary" onClick={handleSaveChanges}>Save Changes</button>
      </div>
      <div className="subscription-history-container py-5">
        <h3 className="mb-3">Price Revision History</h3>
        <div className="subscription-history-table card">
          <Datatable data={history} columns={columns} />
        </div>
      </div>
    </div>
  )
}

export default AdminInstituteSubscription
