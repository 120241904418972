import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
// import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// import setAuthToken from "services/factories/setAuthToken";
// import { addAccount } from "services/actions/auth";
import axios from "axios";
import Checkbox from "components/formComponents/Checkbox";
import EmailField from "components/formComponents/EmailField";
import PasswordField from "components/formComponents/PasswordField";
import SubmitButton from "components/formComponents/SubmitButton";
import AuthNavbar from "components/navbar/AuthNavbar";
import Heading2 from "components/typography/Heading2";
import { useState } from "react";
import { getUserRole } from "routes/getUserRole";
import { addAccount } from "services/actions/auth";
import setAuthToken from "services/factories/setAuthToken";

const AdminLogin = () => {
  const isUser = useSelector((state) => state.auth);

  const onLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(() => false);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { email: "", password: "", remember_me: true },
  });

  const onSubmit = async (formData) => {
    try {
      // console.log({
      //   formData,
      //   len: formData.password.length,
      //   val: formData.password,
      // });
      if (!formData?.email) throw new Error("Email is required");
      if (!formData?.password) throw new Error("Password is required");
      if (formData.password.length < 8)
        throw new Error("Password must be at least 8 characters long");

      const path = "/v1/auth/login";

      const options = { validateStatus: () => true };

      const auth = {
        username: formData.email.toLowerCase(),
        password: formData.password,
      };

      const { data } = await axios.post(
        path,
        { remember: !!formData?.remember_me },
        { ...options, auth }
      )

      if (!data)
        throw new Error(
          "Connection to server failed! Please try again or later."
        )
      if (data?.error) throw new Error(data?.message || "Something went wrong!")
      else {
        setAuthToken(true)
        dispatch(
          addAccount({
            user: {
              ...data.user,
              isOTPVerified: false,
            },
            message: data.data,
          })
        )

      navigate("/admin/verify-otp");
      }
    } catch (err) {
      console.log(err);
      setLoading(() => false);
      toast.error(err?.message || err, { toastId: "login" });
    }
  };

  return (
    <div className="admin-login">
      <AuthNavbar />
      {!isUser?.isAuthenticated ? (
        <div className="form-container p-4 py-5">
          <Heading2 text="Admin Sign In" />
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <EmailField
              label="Work/Official Email"
              id="email"
              placeholder="Tonynguyen@example.com"
              register={register}
            />

            <PasswordField
              displayStrength={false}
              label="Password"
              id="password"
              register={register}
              setValue={setValue}
            />

            <Link to="/admin/forgot-password" className="forgot-pass-link">
              Forgot password?
            </Link>

            <SubmitButton
              value="Sign in"
              onSubmit={handleSubmit(onSubmit)}
              isLoadingState={true}
              loading={loading}
              setLoading={setLoading}
            />

            <Checkbox
              myClass="form-check d-flex align-items-center justify-content-center mb-3"
              id="remember_me"
              label="Remember me"
              register={register}
              checked={true}
            />
          </form>
          <p className="create-account text-center">
            New user?{" "}
            <Link to="/signup" className="">
              Create an account
            </Link>
          </p>
        </div>
      ) : (
        <div className="logout">
          <button onClick={onLogout}>Logout</button>
          <Link to={getUserRole(isUser?.user?.role)} style={{ color: "white" }}>
            or go to your dashboard
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
