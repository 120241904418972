import React, { useState, useEffect, useId } from "react"
import { toast } from "react-toastify"

// import "./inputImage.scss"

const InputFile = ({ file = null, setFile = () => {}, maxSize = 20 }) => {
  const id = useId()
  // console.log({ id })

  const onChangeHandler = (e) => {
    // console.log(e)
    const accepted = ["pdf", "ppt", "pptx", "doc", "docx", "mp4", "avi", "mov", "mkv"]
    setFile(null)
    const uploaded = e.target.files[0]

    // check if file is accepted
    const fileExtension = uploaded.name.split(".").pop()
    if (!accepted.includes(fileExtension)) {
      toast.error("File type not accepted")
      return
    }

    // check if file is too large
    const fileSize = uploaded.size / 1024 / 1024
    if (fileSize > maxSize) {
      toast.error(`File size too large (max ${maxSize}MB)`)
      return
    }

    setFile(uploaded)
  }

  const removeImage = () => {
    // setFile(null)
    setFile(null)
    // setPreview(null)
    // setImageUrl(false)
    // setImageFile(null)
  }

  return (
    <div className="image-uploader mb-3">
      {file ? (
        <div className="img-container">
          <i
            className="fa-solid fa-arrow-up-from-bracket"
            title="Reupload"
            onClick={removeImage}
          ></i>
          <p>{file.name}</p>
        </div>
      ) : (
        <label className="img-container" htmlFor={`file-field-${id}`}>
          <i className="fa-solid fa-arrow-up-from-bracket"></i>
          <p>Upload Report</p>
        </label>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        id={`file-field-${id}`}
        onChange={onChangeHandler}
      />
    </div>
  )
}

export default InputFile
