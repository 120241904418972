import React from "react";
import "./style.scss";
import "./submit.scss";

const SubmitButtonAlt = ({ value, onClick }) => {
  return (
    <div className="form-group my-3">
      <input
        type="submit"
        value={value}
        onClick={onClick}
        className="btn submit-btn submit-btn-alt"
      />
    </div>
  );
};

export default SubmitButtonAlt;
