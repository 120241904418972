import React from "react";
import logo from "../../images/logos/brand-logo.png";
import WhiteLogo from "../../images/logos/logo-white.png";

import "./loginLeft.scss";
import bg1 from "../../images/login/login-bg-1.png";
import bg2 from "../../images/login/login-bg-2.png";
import bg3 from "../../images/login/login-bg-3.png";
import vector1 from "../../images/login/vector-1.png";
import vector2 from "../../images/login/vector-2.png";
import { useNavigate } from "react-router-dom";

const LoginLeft = ({
  message = "Connecting Companies With Brilliant Minds in Campuses",
}) => {
  const navigate = useNavigate();
  return (
    <div className="col-lg-5 px-0">
      <div className="login-left">
        <img className="bg-1" src={bg1} alt="bg-1" loading="lazy" />
        <img className="bg-2" src={bg2} alt="bg-2" />
        <img className="bg-3" src={bg3} alt="bg-3" />
        <img className="vector-1" src={vector1} alt="vector-1" />
        <img className="vector-2" src={vector2} alt="vector-2" />

        <img
          onClick={() => navigate("/")}
          className="brand-logo new-brand-logo-class"
          src={WhiteLogo}
          alt="Brand Logo"
        />

        <h1>{message}</h1>

        <small>
          &copy;Copyright {new Date().getFullYear()}. Qollabb Edutech Private
          Limited
        </small>
      </div>
    </div>
  );
};

export default LoginLeft;
