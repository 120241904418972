import React, { useState, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import "../login/style.scss"
import axios from "axios"
import { toast } from "react-toastify"
import AuthNavbar from "components/navbar/AuthNavbar"
import Heading2 from "components/typography/Heading2"
import ResendOTPButton from "components/formComponents/otp/ResendOTPButton"
import InputOTP from "components/formComponents/otp/InputOTP"
import { updateOTP } from "services/actions/auth"

const AdminVerifyOtp = (props) => {
  const { state } = useLocation()
  const [loading, setLoading] = useState(() => false)
  const navigate = useNavigate()
  const user = useSelector((state) => state?.auth?.user)
  const dispatch = useDispatch()
  const [otpMessage, setOtpMessage] = useState(() => "")
  const { email = user?.email_address, method = props.method || 1 } =
    state || {}

  useEffect(() => {
    // console.log(user?.isOTPVerified)
    if (user?.isOTPVerified) navigate("/admin/dashboard")
  }, [user, navigate])

  const onResendClick = async () => {
    try {
      const { data: axiosData } = await axios.get(`/v1/auth/admin/otp`, {
        validateStatus: (status) => status < 512,
      })
      const { error, message } = axiosData
      if (error) throw new Error(message)
      else toast.success(message)
    } catch (err) {
      toast.error(err?.message || err, { toastId: "resend-otp-error" })
    }
  }
  const onInputSubmit = async (otp) => {
    try {
      if (`${otp}${method}`.length !== 7)
        throw new Error("Please fill all the fields")
      const { data: axiosData } = await axios.post(
        `/v1/auth/otp/`,
        { otp: otp + method },
        {
          validateStatus: (status) => status < 512,
        }
      )
      const { error, message } = axiosData
      if (error) throw new Error(message)
      else {
        dispatch(updateOTP())
        toast.success(message)
      }
    } catch (err) {
      setLoading(() => false)
      setOtpMessage("error")
      toast.error(err?.message || err)
    }
  }

  return (
    <div className="admin-login">
      <AuthNavbar />
      <div className="form-container p-4 py-5">
        <Heading2 text="Verify with OTP" />
        <p>Enter the 6 digit OTP sent on the {email}</p>

        <form>
          <InputOTP
            label="Your OTP"
            onInputSubmit={onInputSubmit}
            message={otpMessage}
            setOtpMessage={setOtpMessage}
            loading={loading}
            setLoading={setLoading}
          />
        </form>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <ResendOTPButton onResendClick={onResendClick} />
        </div>
        <div className="text-center mb-3">
          <Link
            to="/signup"
            className="back-to-signin"
            style={{ fontWeight: "700" }}
          >
            <i className="fa-solid fa-chevron-left me-2"></i> Back to sign up
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AdminVerifyOtp
