import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
// import testimonial1 from "./first.png"
import Image from "../../images/testimonial/Image.png"
import quotes from "../../images/testimonial/quotes.png"
// import testimonial2 from './second.png'
// import testimonial3 from './3.png'
// Import Swiper styles
import './testimonial.scss'
import 'swiper/css/bundle'

const TestimonialSlider = ({img1,img2,img3,title,name1,name2,name3,designation1,designation2,designation3,title1,title2,title3,subTitle1,subTitle2,subTitle3}) => {
    return (
        <div className="testimonial-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 pb-4">
                        <div className="section-title text-center mb-55">
                            <h2>{title}<br /> Think and Say About <span className="down-mark-line">Qollabb</span></h2>
                        </div>
                    </div>
                </div>
                <div className="swiper-container testimonial-active">
                    <div className="swiper-wrapper">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={30}
                            slidesPerView={1}
                            autoplaydisableoninteraction={"false"}
                            loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1
                                },
                                480: {
                                    slidesPerView: 1
                                },
                                640: {
                                    slidesPerView: 1
                                },
                                991: {
                                    slidesPerView: 2
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1400: {
                                    slidesPerView: 3
                                }
                            }}
                            pagination={{
                                clickable: true,
                                el: '.testimonial-pagination',
                            }}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: true
                            }}
                        >
                            <SwiperSlide>
                                <div className="testimonial-items position-relative">
                                    <div className="testimonial-header">
                                        <div className="testimonial-img">
                                            <img src={img1} alt="img not found" />
                                        </div>
                                        <div className="testimonial-title">
                                            <h4>{name1}</h4>
                                            <span>{designation1}</span>
                                        </div>
                                    </div>
                                    <div className="testimoni-quotes">
                                        <img src={quotes} alt="img not found" />
                                    </div>
                                    <div className="testimonial-body">
                                        <h3>{title1}</h3>
                                        <p>{subTitle1}</p>
                                    </div>
                                    <div className="testimonial-icon">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="testimonial-items position-relative">
                                    <div className="testimonial-header">
                                        <div className="testimonial-img">
                                            <img src={img2} alt="img not found" />
                                        </div>
                                        <div className="testimonial-title">
                                        <h4>{name2}</h4>
                                            <span>{designation2}</span>
                                        </div>
                                    </div>
                                    <div className="testimoni-quotes">
                                        <img src={quotes} alt="img not found" />
                                    </div>
                                    <div className="testimonial-body">
                                    <h3>{title2}</h3>
                                        <p>{subTitle2}</p>
                                    </div>
                                    <div className="testimonial-icon">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="testimonial-items position-relative">
                                    <div className="testimonial-header">
                                        <div className="testimonial-img">
                                            <img src={img3} alt="img not found" />
                                        </div>
                                        <div className="testimonial-title">
                                        <h4>{name3}</h4>
                                            <span>{designation3}</span>
                                        </div>
                                    </div>
                                    <div className="testimoni-quotes">
                                        <img src={quotes} alt="img not found" />
                                    </div>
                                    <div className="testimonial-body">
                                    <h3>{title3}</h3>
                                        <p>{subTitle3}</p>
                                    </div>
                                    <div className="testimonial-icon">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="testimonial-pagination text-center"></div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;