import { Navigate } from "react-router-dom";
import { getUserRole } from "./getUserRole";
import { useSelector } from "react-redux";

const SemiProtectedRoute = ({
  roleNeeded = false,
  component = <Navigate to="/signin" />,
}) => {
  const { user = {}, isSemiAuthenticated = false, isAuthenticated = false } =
    useSelector((state) => state.auth) || {};

  if (!isSemiAuthenticated) return <Navigate to="/signin" />;
  else if (isAuthenticated) {
    const role = user?.role;
    return <Navigate to={getUserRole(role)} />;
  } else if (roleNeeded && roleNeeded !== user?.role) {
    const role = user?.role;
    return (
      <Navigate to={getUserRole(role)} />
      // <Navigate
      //   to={
      //     user?.role === 2
      //       ? "/company"
      //       : user?.role === 3
      //       ? "/institution"
      //       : user?.role === 4
      //       ? "/add_interest"
      //       : "/signin"
      //   }
      // />
    );
  }
  return component;
};

export default SemiProtectedRoute;
