import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import EmailField from "components/formComponents/EmailField";
import TextArea from "components/formComponents/TextArea";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import TextInput from "components/formComponents/TextInput";
import NewInputImage from "components/image-uploader/New_Image_uploader/NewInputImage";
import React, { useReducer, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "components/formComponents/post/Dropdown/Dropdown";
import "./employerPersonalDetail.scss";
import TextLink from "components/formComponents/TextLink";
import DateField from "components/formComponents/DateField";
import TextField from "components/formComponents/TextField";

const EmployerPersonalDetails = ({
  register,
  image,
  setCompanyImage,
  setImage,
  setValue,
}) => {
  const [skills, setSkills] = useState(() => []);
  const [interests, setInterests] = useState(() => []);
  const [isPopupOpen, updatePopup] = useState(() => false);
  const [isPopupOpen1, updatePopup1] = useState(() => false);

  const userGender = useSelector((state) => state.auth.user.gender);

  const [gender, setGender] = useState(userGender);

  // console.log(userGender, "123456");

  const updateGender = (value) => {
    setGender(value);
    setValue("gender", value);
  };

  // console.log(gender, "gender");

  const dispachdata = useDispatch();

  const lang = useSelector((state) => state.auth.user.languages || []);

  // console.log(lang, "lang");
  // const [languages, setLanguages] = useState(() => lang);

  // const langs = ["English", "Hindi", "Marathi", "Gujarati", "Tamil", "Telugu"];

  // const updateLanguages = (value) => {
  //   console.log(value, "updateLangvalue");
  //   setLanguages((prev) => {
  //     console.log(prev, "prev");
  //     if (prev?.includes(langs[value])) {
  //       return prev.filter((item) => item !== langs[value]);
  //     }
  //     return [...prev, langs[value]];
  //   });

  //   // setValue("languages", [...languages]);
  //   console.log(languages, "languages"); // pass the updated prev value
  // };

  // const saveLanguage = (languages) => {
  //   dispatch(updateUser(languages));
  // };
  // setValue("languages", [...languages]);
  // console.log(languages, "language");

  const user = useSelector((state) => state.auth.user);

  const deleteSkill = (skill) => {
    const newSkills = skills.filter((item) => item?.name !== skill);
    setSkills(newSkills);
  };

  const deleteInterests = (interest) => {
    const newInterests = interests.filter((item) => item?.name !== interest);
    setInterests(newInterests);
  };
  const initialState = {
    title: "",
    link: "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);
  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };
  const handleChange = (e) => {
    dispachdata({
      value: e.target.value,
    });
  };

  //v1/student/profile

  return (
    <div className="row personal_details_container">
      <div className="col-12">
        <div className="col-sm-2 col-4">
          {/*<InputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_photo}
          />*/}
          <NewInputImage
            image={image}
            setImageFile={setImage}
            url={user?.company_logo}
            imgText={"Company Logo"}
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="First Name"
          id="first_name"
          labelClassName="fieldLabel"
          register={register}
          className="profileInputField"
          placeholder={user?.first_name}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Last Name"
          id="last_name"
          register={register}
          placeholder={user?.last_name}
          className="profileInputField"
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-group mb-3 w-100 text_label">
          {" "}
          Contact Number
          <PhoneInput
            country={"in"}
            placeholder="Enter phone number"
            onChange={(e) => setValue("phone_number", e)}
            defaultCountry="IN"
            onlyCountries={["in"]}
            name="phone_number"
            specialLabel={""}
            containerClass={" mt-2"}
            inputStyle={{ marginTop: "10px" }}
            inputClass={"form-control profileInputField"}
            value={state?.phone_number || user?.phone_number}
          />
        </label>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <EmailField
          label="Email"
          id="email_address"
          register={register}
          placeholder={user?.email_address}
          className="email-disabled"
          disabled={true}
        />
      </div>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <DateField
          minDate="1990"
          maxData="2040"
          label="Date of Birth"
          id="date_of_birth"
          register={register}
          placeholder="Choose a date"
          className="profileInputField"
        />
      </div> */}
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={["Male", "Female", "Others"]}
          // checked={gender}
          register={register}
          checked={gender}
          label="Gender"
          className="profileDropdown"
          setItem={updateGender}
        />
      </div> */}
      <div className="col-lg-6 col-md-6 col-sm-12 designation">
        {/* <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          className="profileInputField"
          value={user?.branch?.address}
          placeholder={"Enter Location"}
        />{" "} */}

        <TextFieldWithPopup
          label="Location"
          // required={true}
          id="location"
          register={register}
          isPopupOpen={isPopupOpen1}
          updatePopup={updatePopup1}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          value={user?.location || user?.branch?.address}
          // value={internshipLocation}
          // defaultValue={internshipLocation}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 designation">
        <TextFieldWithPopup
          label="Industry Sector"
          // required={true}
          id="industry_name"
          placeholder="Type Your Industry Sector"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/filter/industry_sector"
          key_name="industry_name"
          setValue={setValue}
          withId="industry"
          value={user?.industry?.industry_name}
          // required={true}
        />
      </div>

      {/* <div className="col-lg-6 col-md-6 col-sm-12 designation">
        <TextField
          label="Website"
          id="website_url"
          register={register}
          placeholder="qwe.com"
        />
      </div> */}

      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={langs}
          checked={languages}
          label="Language"
          className="profileDropdown"
          setItem={updateLanguages}
          isMultiple={true}
          register={register}
        />
      </div> */}
      <div className="designation col-lg-6 col-md-6 col-sm-12">
        <TextField
          label="Designation"
          id="designation"
          name="designation"
          placeholder="Founder"
          register={register}
        />
      </div>


      {/* <TextArea
        label="BIO"
        id="bio"
        register={register}
        labelClass="profileSectionLabel"
        className="profileInputField"
      /> */}
      {/* <div className="designation col-lg-12 col-md-12 col-sm-12">
        <TextField
          label="Industry Sector"
          id="industry_sector"
          register={register}
          placeholder="Enter Industry Sector"
          className="profileInputField"
        />
      </div> */}

      {/* <ChipsFieldWithPopup
        label="INDESTRY SECTOR"
        id="interest"
        register={register}
        items={interests}
        setItems={setInterests}
        path="/v1/filter/interests/joined"
        deleteItem={deleteInterests}
        key_name="interest_name"
        labelClass="profileSectionLabel"
        className="profileInputField"
      /> */}
      {/* <ChipsFieldWithPopup
        label="COMPANY INTERESTS"
        id="user_skills"
        register={register}
        path="/v1/filter/skills"
        items={skills}
        setItems={setSkills}
        deleteItem={deleteSkill}
        labelClass="profileSectionLabel"
        className="profileInputField"
      /> */}

      {/* <div className="row personal_details_container w-100 mt-4">
        <label className="profileSectionLabel">Profile Links</label>

        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextLink
            label="Linkedin"
            id="linkedIn_link"
            register={register}
            className="profileInputField"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextLink
            label="Portfolio Link"
            id="profile_link"
            register={register}
            className="profileInputField"
          />
        </div>
      </div> */}
      <div className="personal_details_ceo_container w-100 mt-4 gap-4">
        {/* <div className="col-lg-4 col-md-4 col-12 my-4">
          <TextInput
            label="Ceo Name"
            id="ceo_name"
            register={register}
            className="profileInputField"
          />
        </div> */}
        {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 image_content gap-4">
          <div className="d-flex flex-column">
            <label>Company Logo</label>
            <div className="d-flex gap-4 py-2">
              <NewInputImage
                image={image}
                setImageFile={setCompanyImage}
                url={user?.company_logo}
              />
              <button
                className="btn btn-primary text-white px-5"
                style={{ width: "200px" }}
              >
                Upload
              </button>
            </div>
          </div>
        </div> */}
       
      </div>
    </div>
  );
};

export default EmployerPersonalDetails;
