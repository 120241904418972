import React, { useState } from "react"
import "../style.scss"
import AdminSubscriptionTable from "../table"
import SubscriptionHistoryTable from "../table/SubscriptionHistoryTable"
import Datatable from "components/datatable-new/DatatableNew"
import axios from "axios"
import { toast } from "react-toastify"

const AdminStudentSubscription = () => {
  const plans = [
    {
      plan: "Includes	Access to Projects, Internships & Jobs posted by companies",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Company projects you can work on simultaneouly",
      free: <span className="text-color-grey">1 Project at a time</span>,
      monthly: <span className="text-color-grey">2 Project at a time</span>,
      yearly: <span className="text-color-grey">2 Project at a time</span>,
    },
    {
      plan: "Apply to all internships or job opportunties for freshers",
      free: <span className="text-color-grey">05 Applications per Day</span>,
      monthly: <span className="text-color-grey">10 Applications per Day</span>,
      yearly: <span className="text-color-grey">Unlimited</span>,
    },
    {
      plan: "Access to all information and data as provided by company for working on Projects",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Build your project work portfolio by submitting your project reports to employers",
      free: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Evaluation and validation of your project work by  Qollabb's experts",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
    {
      plan: "Issue of certificate on successful completion of project work.",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: <span className="text-color-primary">1 Certification Free</span>,
      yearly: <span className="text-color-primary">2 Certificate Free</span>,
    },
    {
      plan: "Prioratized job Alerts on whenever a new job is posted by employer (For 1 Year)",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: <span className="text-color-grey">For 6 months</span>,
      yearly: <span className="text-color-grey">For 1 Year</span>,
    },
    {
      plan: "Resume writing services of a senior career consultant",
      free: (
        <span className="text-color-grey">
          <i class="fa-solid fa-times"></i>
        </span>
      ),
      monthly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
      yearly: (
        <span className="text-color-primary">
          <i class="fa-solid fa-circle-check"></i>
        </span>
      ),
    },
  ]

  const history = [
    {
      date: "2 Oct 2022",
      intermediate: "₹ 1000",
      expert: "₹ 2000",
      sgst: "9%",
      cgst: "10%",
    },
    {
      date: "2 Oct 2022",
      intermediate: "₹ 1000",
      expert: "₹ 2000",
      sgst: "9%",
      cgst: "10%",
    },
    {
      date: "2 Oct 2022",
      intermediate: "₹ 1000",
      expert: "₹ 2000",
      sgst: "9%",
      cgst: "10%",
    },
    {
      date: "2 Oct 2022",
      intermediate: "₹ 1000",
      expert: "₹ 2000",
      sgst: "9%",
      cgst: "10%",
    },
    {
      date: "2 Oct 2022",
      intermediate: "₹ 1000",
      expert: "₹ 2000",
      sgst: "9%",
      cgst: "10%",
    },
  ]

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      width: "15%",
      textalign: "center",
      // Cell: (data) => {
      //   return (
      //     <strong style={{ fontWeight: "700" }}>
      //       {data.row.original.date}

      //     </strong>
      //   )
      // },
    },
    {
      Header: "Intermediate",
      accessor: "intermediate",
      width: "15%",
      textalign: "center",
    },
    {
      Header: "Expert",
      accessor: "expert",
      width: "15%",
      textalign: "center",
    },
    {
      Header: "SGST",
      accessor: "sgst",
      width: "15%",
      textalign: "center",
    },
    {
      Header: "CGST",
      accessor: "cgst",
      width: "15%",
      textalign: "center",
    },
    {
      Header: " ",
      accessor: "",
      width: "25%",
    },
  ]

  const [studentSubscriptionValue,setStudentSubscriptionValue]=useState({
    monthly:'',
    yearly:''
  })
 
  

  function handleSubscriptionChange(e,type){
    const price=e.target.value
    setStudentSubscriptionValue({
      ...studentSubscriptionValue,
      [type]: price
    })
  }
// console.log("plan0",studentSubscriptionValue)
// console.log("plan1",studentSubscriptionValue.monthly)
// console.log("plan2",studentSubscriptionValue.yearly)




const handleSaveChanges = async () => {
    try {
      if (studentSubscriptionValue?.monthly) {
        const monthlyResponse = await axios.put(`/v1/subscription/subscription-update/8`, { price: studentSubscriptionValue?.monthly });
        // console.log("Monthly", monthlyResponse);
        toast.success('Monthly Subscription Updated Successfully!')
        
      }
      
      if (studentSubscriptionValue?.yearly) {
        const yearlyResponse = await axios.put(`/v1/subscription/subscription-update/9`, { price: studentSubscriptionValue.yearly });
        // console.log("Yearly subscription updated:", yearlyResponse);
        toast.success('Yearly Subscription Updated Successfully!')
      }
      
      // Reset updatedSubscription and companySubscriptionValue after successful update

      setStudentSubscriptionValue({
        monthly: '',
        yearly: ''
      });
    } catch (error) {
      console.error("An error occurred while updating subscription:", error);
      
    }

};

  return (
    <div className="admin-subscriptions-container">
      <div className="row mx-0 subscription-topbar">
        <div className="col-md-4 col-12  d-flex align-items-center pe-0">
          <h3>Upgrade Your Plan </h3>
        </div>
        <div className="col-md-8 col-12  ps-0">
          <div className="row mx-0">
            <div className="col-4 ps-0">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Free Plan</h4>
                <button className="btn btn-outline-primary">Active</button>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Monthly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex mb-3 flex-wrap">
                    <span>₹</span>
                    <input type="number" onChange={(e)=>handleSubscriptionChange(e,'monthly')} />
                    <span>/month</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column align-items-start justify-content-between p-3">
                <h4>Yearly Plan</h4>
                <div className="w-100">
                  <div className="input-container d-flex mb-3 flex-wrap">
                    <span>₹</span>
                    <input type="number" onChange={(e)=>handleSubscriptionChange(e,'yearly')} />
                    <span>/year</span>
                  </div>
                  <button className="btn btn-outline-primary">Upgrade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminSubscriptionTable data={plans} />
      <div className="cancel-save-buttons d-flex align-items-center justify-content-end my-3 me-4">
        <button className="btn btn-outline-primary me-2">Cancel</button>
        <button className="btn btn-primary" onClick={handleSaveChanges}>Save Changes</button>
      </div>
      <div className="subscription-history-container py-5">
        <h3 className="mb-3">Price Revision History</h3>
        <div className="subscription-history-table card">
          <Datatable data={history} columns={columns} />
        </div>
      </div>
    </div>
  )
}

export default AdminStudentSubscription
