import { Link, useLocation, useNavigate } from "react-router-dom"
import "./topbar.scss"

const Topbar = ({ toggleSidebar, userImg, name,toggleAlertBox, isNotification }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isSubPath = pathname.split("/").length > 3

  return (
    <div className="topbar p-2 px-3 d-flex justify-content-between">
      <div>
        <button
          className="btn d-lg-none d-inline-block"
          onClick={() => {
            toggleSidebar(true)
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        {isSubPath ? (
          <button
            className="mx-2"
            style={{ backgroundColor: "white", color: "#000" }}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="icons-container d-flex align-items-center">
        <Link to="/employer/chat">
          <div className="icons me-3">
            <i className="fa fa-commenting" aria-hidden="true"></i>
          </div>
        </Link>
        
          <div className="icons me-3" style={{position: "relative"}}  onClick={()=> toggleAlertBox(true)}>
            <i className="fa-solid fa-bell"></i>
            <span className={`${isNotification ? "indicator": ""}`} style={{position: "absolute", left: "31px", top: "4px"}}></span>
          </div>

        <Link to="/employer/employer-profile">

          <div className="icons" title={`Hello ${name}`}>
            {!userImg ? (
              <span>{(name) ? name?.charAt(0).toUpperCase() : "-"}</span>
            ) : (
              <img
                src={userImg}
                width="45px"
                height="45px"
                alt={name.charAt(0).toUpperCase()}
              />
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Topbar
