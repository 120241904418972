import React, { useState } from 'react'
import ChangePassword from './changepassword'
import HelpDesk from './HelpDesk'



const EmployerSettings = () => {
  const [selectedTab, setSelectedTab] = useState(() => 0)
  return (
    <section className="employer_setting">
    <div className="setting-tab " style={{backgroundColor:"white"}}>
      <span
        className={selectedTab === 0 ? "setting_active" : "settng_btn"}
        onClick={() => setSelectedTab(0)}
      >
        Change Password
      </span>
      <span
        className={selectedTab === 1 ? "setting_active" : "settng_btn"}
        onClick={() => setSelectedTab(1)}
      >
        Qollabb Helpdesk
      </span>
    </div>

    <div className="password_field p-2 p-md-4">
      {selectedTab === 0 ? <ChangePassword /> : <HelpDesk />}
    </div>
  </section>
  )
}

export default EmployerSettings