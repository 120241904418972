// import Accordian from "components/accordian"
import StudentAccordian from "components/accordian/studentAccordian/StudentAccordian";

const WeekDetail = ({
  handleClick = () => {},
  title = "",
  midTitle,
  index,
  report,
  description,
  text = "Submit",
}) => {

  function download(url, filename) {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
          // console.log("hello")
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            // console.log("hello")
            a.click();
        }
        );
    });
  }
  // // console.log({ report, description })
  return (
    <StudentAccordian
      report={report}
      description={description}
      title={title}
      midTitle={midTitle}
    >
      <div title={title}>
        {description ? (
          <div className="upload_content d-flex row justify-content-center align-items-center">
            <p>{description || report}</p>
            <div className="devider"></div>
            {report ? (
              <div className="report-action-button" style={{width: "fitContent" ,display:"flex"}}>
                <a
                  href={report}
                  target="_blank"
                  className="btn bg-transparent text-primary text-start"
                  rel="noopener noreferrer"
                >
                  View Report
                </a>
                <div 
                  onClick={()=> download(report, "report.pdf")}
                  // className="btn bg-transparent text-primary text-start"
                >
                  <a
                  //  href={report}
                  //   target="_blank"
                  //   download="report.pdf" 
                  //   rel="noopener noreferrer"
                    className="btn bg-transparent text-primary text-start"
                  >
                    download
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* <div className="devider"></div> */}
          </div>
        ) : (
          <div className=" d-flex  not-uploaded">
            <div
              className="weekly-upload-btn"
              onClick={() => handleClick(index)}
            >
              {text}
            </div>
          </div>
        )}
      </div>
    </StudentAccordian>
  )
}

export default WeekDetail
