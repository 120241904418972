import { useState, useMemo, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Loader from "components/employer/Loader";
import { useSelector } from "react-redux";
import AdminDashCard from "../tables/table-card";
import axios, { get } from "axios";
const AdminAnalytics = () => {

    const user = useSelector((state) => state.auth.user);
    const [activate, setActivate] = useState(() => false);
    const [counts, setCounts] = useState(() => ({}));
    // const [posts, , loading] = useOutletContext();
    const [loading, setLoading] = useState(() => true);
    const tabs = [
      "Companies",
      "Opportunities",
      "Students",
      "Colleges/universities",
      "Mentors",
    ];
    const [activeTab, setActiveTab] = useState(() => 0);
    const [data, setData] = useState(() => ({
      Companies: [],
      Opportunities: [],
      Students: [],
      Colleges: [],
      Mentors: [],
    }));

    const cardsData = [
        {
          imgUrl: "",
          primaryText: "Companies",
          secondaryText: "+50 this month",
          value: "128",
        },
    
      ];

      // code for chart/graph
 const chartData = {
  
    labels: cardsData.map((item)=> {
     return item.day
    }),// X-axis labels
    // labels: data.map((item, index) => index +1), // X-axis labels
    datasets: [
      {
        data: cardsData.map((item)=>{return item.total}), // Y-axis values
        // data: data.map((item) => item.value), // Y-axis values
        borderColor: '#6149CD',
        backgroundColor: '#6149CD',
      },]
  };
  
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display:false // Change 'linear' to 'bottom' or 'top'
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'company', // X-axis title
        },
      },
      y: {
        title: {
          display: true,
          text: 'company', // Y-axis title
        },
      },
    },
  };
  

    useEffect(() => {
      const getAll = async () => {
        const internship = get("/v1/internship/all");
        const job = get("/v1/job/all");
        const project = get("/v1/project/all");
        const employer = get("/v1/employer");
        const student = get("/v1/student/all");
        const mentor = get("/v1/mentor");
        const educator = get("/v1/educator/all");
  
        await axios
          .all([internship, job, project, employer, student, mentor, educator])
          .then(
            axios.spread((...responses) => {
              const internships =
                responses[0]?.data?.data?.map((x) => ({
                  ...x,
                  type: "internship",
                })) || [];
              const jobs =
                responses[1]?.data?.data?.map((x) => ({
                  ...x,
                  type: "job",
                })) || [];
              const projects =
                responses[2]?.data?.data?.map((x) => ({
                  ...x,
                  type: "project",
                })) || [];
              const employers = responses[3]?.data?.data || [];
              const students = responses[4]?.data?.data || [];
              const mentors = responses[5]?.data?.data || [];
              const educators = responses[6]?.data?.data || [];
              setCounts((prev) => {
                return {
                  ...prev,
                  projects: {
                    activeCount: responses[2]?.data?.activeProjects,
                    closedProjects: responses[2]?.data?.closedProjects,
                    totalCount: responses[2]?.data?.totalCount,
                  },
                  jobs: {
                    activeCount: responses[1]?.data?.activeJobs,
                    closedJobs: responses[1]?.data?.closedJobs,
                    totalCount: responses[1]?.data?.totalCount,
                  },
                  internships: {
                    activeCount: responses[0]?.data?.activeInternships,
                    closedInternships: responses[0]?.data?.closedInternships,
                    totalCount: responses[0]?.data?.totalCount,
                  },
                  students: {
                    activeStudent: responses[4]?.data?.activeStudent,
                    inactive: responses[4]?.data?.inactiveStudents,
                  },
                  colleges: {
                    activeColleges: responses[6]?.data?.activeColleges,
                    inactive: responses[6]?.data?.inactiveColleges,
                    totalAssignStudents: responses[6]?.data?.totalAssignCount,
                    projectAssignStudents: responses[6]?.data?.projectAssignCount,
                    jobAssignStudents: responses[6]?.data?.jobAssignCount,
                    internshipAssignStudents:
                      responses[6]?.data?.internshipAssignCount,
                  },
                  mentors: {
                    activeMentors: responses[5]?.data?.activeMentors,
                    inactive: responses[5]?.data?.inactiveMentors,
                  },
                };
              });
  
              setData((p) => ({
                ...p,
                Opportunities: [...projects, ...internships, ...jobs],
                Companies: employers,
                Students: students,
                Mentors: mentors,
                Colleges: educators,
              }));
  
              setLoading(false);
            })
          )
          .catch(() => {
            setLoading(false);
          });
      };
      getAll();
    }, [activate]);
  
    if (loading) return <Loader />;
  
    // console.log(counts, "counts");
 

  return (
    <div
    className="content-container py-5 pt-2"
    style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
  >
    <div className="row">
      <div className="col-12">
        <div className="table-tabs">
          <nav>
            <div
              className="nav_tabs nav nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              {tabs.map((tab, index) => (
                <button
                  style={{ fontSize: "16px", fontWeight: "500" }}
                  className={`nav-link ${
                    activeTab === index ? "active" : ""
                  }`}
                  id={`nav-${tab}-tab`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${tab}`}
                  aria-selected="true"
                  onClick={() => setActiveTab(index)}
                  key={tab}
                >
                  {tab}
                </button>
              ))}
            </div>
          </nav>

          <div
            className="tab-pane fade show active"
            id={`nav-${activeTab}`}
            role="tabpanel"
            aria-labelledby={`nav-${activeTab}-tab`}
          >
        
          </div>

          <div className="row">
        {cardsData.map((card, index) => (
            <div className="col-3" key={index}>
                <AdminDashCard
                    data={chartData}
                    option={options}
                    img={card.imgUrl}
                    primaryText={card.primaryText}
                    secondaryText={card.secondaryText}
                    value={card.value} />
            </div>
        ))}
      </div>





        </div>
      </div>
    </div>
  </div>

   
  );
};

export default  AdminAnalytics;

