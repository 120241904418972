import React from "react"
import "../mobileViewTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import { Link } from "react-router-dom"
import moment from "moment"

const StudentMobileViewTable = ({ columns, data,handleAccountActivation}) => {
  // console.log(columns, data,"student")
 
  return (
    <>
      {data?.map((item, i) => {

        const lastLogin=moment(item.last_login).format("DD/MM/YYYY")
        // console.log("lastlogin",lastLogin)

        return (
          <div className="mobileDataTable mt-4" key={i}>
            <div className="data_container">
              <div className="table_data">
                <p>Name</p>
                <span className="Total_enrolled">{item?.name}<br/>{item?.company_location}</span>
              </div>
              <div className="table_data">
                <p>Phone Number</p>
                <span className="Total_enrolled">{item?.phone_number}<br/>{item?.email_address}</span>

                {/* {item?.title?.props?.children[1] ? (
               <span className="project_name">{item.title.props.children[1]}</span>
                     ) : (
                 <span className="project_name">{item.title.props.children}</span>
                     )} */}

              </div>

              <div className="table_data">
                <p>Signup Date</p>
                <span className="Total_enrolled">{item?.signup_date}</span>
              </div>

              <div className="table_data">
              <p>Last Login</p>
                <span className="Total_enrolled">{lastLogin}<br/>{item?.last_time}</span>
              </div>

              <div className="table_data">
                <p>Projects</p>
                <span className="Total_enrolled">{item?.projects}</span>
              </div>
              <div className="table_data">
                <p>Internships</p>
                <span className="Total_enrolled">{item?.internships}</span>
              </div>
              <div className="table_data">
                <p>Jobs</p>
                <span className="Total_enrolled">{item?.jobs}</span>
              </div>

              <div className="table_data">
                <p>Subscription</p>
                <span className="Total_enrolled">{item?.subscription}</span>
              </div>

              
              <div className="table_data">
                <p>Valid Upto</p>
                <span className="Total_enrolled">{item?.subscription_validity}</span>
              </div>


              <div className="table_data">
                <p>Type</p>
                <span className="Total_enrolled">{item?.type}</span>
              </div>

              <div className="Status_data">
              <p>Action</p>
              <button
       onClick={() => handleAccountActivation(item?.id)}
         className={`deactivate-btn ${
          item?.deletedAt === "Activate" ? "bg-success" : "bg-danger"
          }`}
                    >
          {item?.deletedAt}
            </button>
           
              
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default StudentMobileViewTable
