import axios from "axios"
import Loader from "components/employer/Loader"
import useFetch from "hooks/useFetch"
import moment from "moment-timezone"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import mainLogo from "../../../../images/logos/logo-main.png"
import presentationImg from "../../../../images/presentationImg.png"
import reportImg from "../../../../images/reportImg.png"
import threeManimg from "../../../../images/threeman.png"
import projectPresentation from "../../../../resourses/Project Presentation Making Guidelines 29112023.pdf"
import projectReport from "../../../../resourses/Project Report Preperation Guidelines 29112023.pdf"
import SuggestedMentors from "../home/home_cards/sections/Mentors"
import { generatePDF } from "./GenerateProjectCertificate"
import StatusProgress from "./StatusProgress"
import WeekDetails from "./WeekDetails"
import "./application.scss"
import successImg from "./successImg.png"
import success_icon from "./sucess_tips.svg"

import certificateSign from "../../../../images/certificate-sign.png"
import ProfessorShodhak from "./ProfessorShodhak"

const ApplicationStu = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate();

  const handleDataNavigation = (data) => {
    // console.log(data,"hello world");
    if (!data?.data?.data) {
      navigate("/student/my-applications")
    }
    return <></>
  }
  const { data, loading, error, setFetchData } = useFetch(
    `/v1/enrollments/project/progress/${id}`,
    {},
    [],
    handleDataNavigation
  )

  const { data: getCraditData } = useFetch(`/v1/evaluation/getCredits`)

  const [mentors, setMentors] = useState(() => [])
  const fetchMentors = async () => {
    try {
      const { data: reqData } = await axios.get(
        `/v1/student/mentor/get_mentor_list`,
        {
          validateStatus: () => true,
        }
      )
      if (!reqData) throw new Error("Error fetching mentor")
      const { data, error, message } = reqData
      setMentors(data)
    } catch (error) {}
  }
  useEffect(() => {
    fetchMentors()
  }, [])

  const [defaultValue, setDefaultValue] = useState(() => 0)

  if (loading || error || data?.error) return <Loader />
  // if (error || data?.error) return <p>Loading..</p>;
  // // console.log(data, "hello world12");
  const project = data?.data?.project

  const statusMap = {
    0: ["Submit Project", "bg-success"],
    1: ["Left", "bg-danger"],
    2: ["Completed", "bg-secondary"],
    3: ["Submit Project", "bg-success"],
    7: ["Rejected", "bg-danger"],
    4: ["Shortlisted", "bg-success"],
    6: ["Completed", "bg-secondary"],
  }

  const onClickHandler = () => {
    // console.log(data?.data?.status);
    if (data?.data?.status === 0 || data?.data?.status === 3) {
      setDefaultValue(() => -1)
    }
  }

  // // console.log(
  //   statusMap[data?.data?.status][0],
  //   "statusMap[data?.data?.status][0]"
  // );
  const handleDownload = (pdf, filename) => {
    const link = document.createElement("a")
    link.setAttribute("href", pdf)
    link.setAttribute("target", "_blank")
    link.setAttribute("download", filename)
    link.click()
  }

  // console.log(getCraditData, "getCraditData")
  // // console.log(getCraditData?.data?.myCredits);

  const handleNavigation = () => {
    if (getCraditData?.data?.myCredits >= 1) {
      navigate(`/student/plan/${project?.id}`)
    } else {
      toast.error("Please upgrade your plan to evalute project", {
        autoClose: 2000,
        onClose: () => {
          navigate("/student/subscriptions")
        },
      })
    }
  }

  // // console.log(
  //   project?.project_global_fkey?.employer_account?.company_logo,
  //   "hello world"
  // );

  // console.log("dataaaa...", data?.data)
  return (
    <section className="student_application container">
      <p className="txt">
        <Link to={"/student/dashboard"}>Home /</Link>
        <Link to={"/student/my-applications"}>My Applications /</Link>
        <Link to={`/student/my-applications/project/detail/${id}`}>
          Project
        </Link>
      </p>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="application-detail">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                <div className="txt1 gap-2">
                  <img
                    style={{
                      borderRadius: "50%",
                      backgroundSize: "contain",
                    }}
                    src={
                      project?.project_global_fkey?.employer_account
                        ?.company_logo ||
                      project?.branch?.comapny_logo ||
                      ""
                    }
                    alt="Whatsaap-img"
                    height={84}
                    width={84}
                  />
                  <div className="mt-0 mt-sm-2 gap ">
                    <h2 className="m-0 mb-sm-2">{project?.project_title}</h2>
                    <p className="m-0 mb-sm-2">{project?.branch?.address}</p>
                  </div>
                </div>
              </div>
              <div className="submit_project_btn col-3 btn d-flex d-md-block">
                <div className="new-relative">
                  <button
                    type="button"
                    className={`${statusMap[data?.data?.status]?.[1]} `}
                    onClick={onClickHandler}
                  >
                    {statusMap[data?.data?.status]?.[0]}
                  </button>
                  <Link
                    className="d-flex-d"
                    to={`/student/my-applications/project/detail/${id}`}
                  >
                    <p className="txt2 both-ajay">
                      <div className="both-wrapper">
                        <span class="fa-stack mx-3 fa-sm">
                          <i
                            class="fa fa-circle-thin fa-stack-2x"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="fa fa-info fa-stack-1x"
                            aria-hidden="true"
                          ></i>
                        </span>{" "}
                        Project Details
                      </div>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="progress-status">
        <h2 className="weekly_report_h2">Weekly Reports</h2>
        <div className="w-100 m-0 row">
          <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12 col-12">
            <StatusProgress
              status={data?.data?.status}
              reports={data?.data?.weekly_submissions}
              final_submission={
                data?.data?.final_submission_report ? true : false
              }
            />

            <div className="weekly-report-accordian">
              <h2>Weekly Reports</h2>
              <WeekDetails
                // duration={project?.duration_to}
                data={data?.data}
                project_id={project?.id}
                enrollment_id={data?.data?.id}
                setFetchData={setFetchData}
                activeListItem={defaultValue}
                setActiveListItem={setDefaultValue}
                // enrollment_createdAt={data?.data?.createdAt}
              />
            </div>
          </div>

          <div className="col-xl-7 col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="">
              {statusMap[data?.data?.status]?.[0] !== "Completed" ? (
                // it need to be uncommented when guide card is ready
                // <GuideCard />
                <div className="">
                  <ProfessorShodhak
                    projectTitle={project?.project_title}
                    projectTask={project?.project_tasks}
                    projectGoal={project?.project_goal}
                    projectDurationFrom={project?.duration_from}
                    projectDurationTo={project?.duration_to}
                  />
                </div>
              ) : (
                <div className="sucess-tip2 mt-3">
                  <div className="row">
                    <div className="col-7">
                      <div className="sucess-tip-text">
                        <div className="sucess-tip_btn flex">
                          <img
                            src={success_icon}
                            alt="icon"
                            width={50}
                            height={50}
                            className="success-icon"
                          />
                          <span className="px-2 ">Success Tip</span>
                        </div>
                        <h3>Get noticed by an employer</h3>
                        <p>
                          Do you know that an applicant with high rating & a
                          project completion certificate on his resume stands
                          11x chances of getting noticed instantly by an
                          employer, so get your pro
                        </p>
                        {/* <Link onClick={handleNavigation}> */}

                        {data?.data?.evaluationProject[0]
                          ?.mentor_evaluated_date ? (
                          <button
                            className="px-2 py-2"
                            onClick={(e) => {
                              e.preventDefault()
                              generatePDF({
                                id: data?.data?.evaluationProject[0]?.id,
                                company_name:
                                  project?.project_global_fkey?.employer_account
                                    ?.company?.company_na,
                                project_title: project?.project_title,
                                completion_date: moment(
                                  data?.data?.final_submission_report
                                    ?.final_submission_report_date
                                )
                                  .tz("Asia/Kolkata")
                                  .format("DD/MM/YYYY"),
                                rating:
                                  data?.data?.evaluationProject[0]?.overall_rating.toFixed(
                                    2
                                  ),
                                student_name:
                                  user?.first_name + " " + user?.last_name,

                                qollabbLogo: mainLogo,

                                certificateSign: certificateSign,
                                secondCompanyLogo:
                                  project?.project_global_fkey?.employer_account
                                    ?.company_logo,
                                companyAddress:
                                  project?.project_global_fkey?.employer_account
                                    ?.location,
                              })
                            }}
                          >
                            Download Certificate PDF
                          </button>
                        ) : (
                          <button
                            className="px-2 py-2"
                            onClick={handleNavigation}
                          >
                            Get Certified Now
                          </button>
                        )}

                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="mobile-img">
                        <img
                          src={successImg}
                          alt="Image"
                          style={{ height: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="suggested_templates mt-5 ">
              <div className="suggested_template">
                {/* <h2>Project Report Preperation Guidelines</h2> */}
                <div className="template">
                  <div className="template_img">
                    <img src={reportImg} alt="Image" />
                  </div>
                  <button
                    onClick={() =>
                      handleDownload(
                        projectReport,
                        "Project Report Preperation Guidelines"
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
              <div className="suggested_template">
                {/* <h2>Project Presentation Making Guidelines</h2> */}
                <div className="template">
                  <div className="template_img">
                    <img src={presentationImg} alt="Image" />
                  </div>
                  <button
                    onClick={() =>
                      handleDownload(
                        projectPresentation,
                        "Project Presentation Making Guidelines"
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
              {/* <div className="suggested_template">
            <h2>Suggested Project report template</h2>
            <div className="template">
              <div className="template_img">
                <img src={threeManimg} alt="Image" />
              </div>
              <button>Download</button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-5 col-lg-7 col-md-12 col-sm-12 col-12">
        <div className="sucess-tip2 ">
          <div className="row">
            <div className="col-7">
              <div className="sucess-tip-text">
                <h3>
                  Seek help from the experts to complete this project with
                  suggestion.
                </h3>
                <Link to={`/student/mentor/all-mentor`}>
                  <button>Explore</button>
                </Link>
              </div>
            </div>
            <div className="col-5">
              <div className="mobile-img">
                <img src={threeManimg} alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuggestedMentors data={mentors} />
    </section>

    // <div>
    //   <div dangerouslySetInnerHTML={{ __html: certificateHTML }} />
    // </div>
  )
}

export default ApplicationStu
