import React, { useEffect, useReducer, useState } from 'react'
import "./newAllMentors.scss";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { initialState, reducer } from "../../explore/handleFilters.js";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import NewTextFieldWithPopup from 'components/formComponents/newTextFieldWithPopup';
import MentorCard from './MentorCard';
import { Navigate } from 'react-router-dom';
import Loader from 'components/employer/Loader';
import MentorFilterBox from '../../explore/exploreFilterBox/MentorFilterBox';
import MentorDetailPopup from '../mentorDetail/MentorDetailPopup';




const NewAllMentors = () => {

    const [inputValue, setInputValue] = useState(() => "");

    const [loading, setLoading] = useState(false);
    const [mentors, setMentors] = useState(() => []);
    const [isPopupOpen, updatePopup] = useState(() => false);
    const [industry_sector, setIndustrySector] = useState(() => []);
    const [charge1, setCharge1] = useState(() => []);
    const [charge2, setCharge2] = useState(() => []);
    const [show, setShow] = useState(false);
    const toggleOFf = () => setShow(() => false);

    const [mentorPopup, setMentorPopup] = useState(false)

    const closeMentorPopup = ()=> {
      setMentorPopup(false)
    }
  
    const [state, dispatch] = useReducer(reducer, initialState);

    const { handleSubmit, register, setValue, getValues, reset } = useForm();
    const mentorsData = [
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/1.jpg',
          first_name: 'John',
          last_name: 'Doe',
          location: 'New York, USA',
          industry: { industry_name: 'Software Development' },
          industry_year_expreience: '10',
          bio: 'Experienced software developer with expertise in web technologies.',
          mentor_charges: '1500',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/1.jpg',
          first_name: 'Jane',
          last_name: 'Smith',
          location: 'London, UK',
          industry: { industry_name: 'Marketing' },
          industry_year_expreience: '8',
          bio: 'Marketing specialist with a focus on digital marketing strategies.',
          mentor_charges: '1200',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/2.jpg',
          first_name: 'Robert',
          last_name: 'Brown',
          location: 'San Francisco, USA',
          industry: { industry_name: 'Finance' },
          industry_year_expreience: '15',
          bio: 'Financial advisor with extensive experience in investment banking.',
          mentor_charges: '2000',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/2.jpg',
          first_name: 'Emily',
          last_name: 'Johnson',
          location: 'Sydney, Australia',
          industry: { industry_name: 'Human Resources' },
          industry_year_expreience: '7',
          bio: 'HR manager with a passion for employee development and relations.',
          mentor_charges: '1000',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/3.jpg',
          first_name: 'Michael',
          last_name: 'Williams',
          location: 'Toronto, Canada',
          industry: { industry_name: 'Project Management' },
          industry_year_expreience: '12',
          bio: 'Project manager with a strong background in IT and construction projects.',
          mentor_charges: '1800',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/3.jpg',
          first_name: 'Linda',
          last_name: 'Davis',
          location: 'Berlin, Germany',
          industry: { industry_name: 'Healthcare' },
          industry_year_expreience: '20',
          bio: 'Healthcare professional with a focus on patient care and hospital management.',
          mentor_charges: '2500',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/4.jpg',
          first_name: 'James',
          last_name: 'Miller',
          location: 'Paris, France',
          industry: { industry_name: 'Education' },
          industry_year_expreience: '5',
          bio: 'Educator with a background in teaching and curriculum development.',
          mentor_charges: '800',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/4.jpg',
          first_name: 'Patricia',
          last_name: 'Martinez',
          location: 'Madrid, Spain',
          industry: { industry_name: 'Legal' },
          industry_year_expreience: '18',
          bio: 'Lawyer specializing in corporate law and intellectual property.',
          mentor_charges: '2200',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/5.jpg',
          first_name: 'David',
          last_name: 'Garcia',
          location: 'Rome, Italy',
          industry: { industry_name: 'Architecture' },
          industry_year_expreience: '9',
          bio: 'Architect with a focus on sustainable building design and urban planning.',
          mentor_charges: '1600',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/5.jpg',
          first_name: 'Barbara',
          last_name: 'Rodriguez',
          location: 'Dubai, UAE',
          industry: { industry_name: 'Real Estate' },
          industry_year_expreience: '14',
          bio: 'Real estate agent with expertise in property management and sales.',
          mentor_charges: '1400',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/6.jpg',
          first_name: 'Richard',
          last_name: 'Martinez',
          location: 'Tokyo, Japan',
          industry: { industry_name: 'Engineering' },
          industry_year_expreience: '11',
          bio: 'Mechanical engineer with a focus on automotive and aerospace industries.',
          mentor_charges: '1700',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/6.jpg',
          first_name: 'Elizabeth',
          last_name: 'Hernandez',
          location: 'Mumbai, India',
          industry: { industry_name: 'Consulting' },
          industry_year_expreience: '13',
          bio: 'Business consultant with a focus on strategy and operations management.',
          mentor_charges: '1900',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/7.jpg',
          first_name: 'Charles',
          last_name: 'Lopez',
          location: 'Seoul, South Korea',
          industry: { industry_name: 'Entertainment' },
          industry_year_expreience: '6',
          bio: 'Entertainment industry professional with experience in film production.',
          mentor_charges: '1300',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/7.jpg',
          first_name: 'Susan',
          last_name: 'Gonzalez',
          location: 'São Paulo, Brazil',
          industry: { industry_name: 'Sports' },
          industry_year_expreience: '10',
          bio: 'Sports coach with a focus on athlete development and performance.',
          mentor_charges: '1100',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/8.jpg',
          first_name: 'Joseph',
          last_name: 'Wilson',
          location: 'Johannesburg, South Africa',
          industry: { industry_name: 'Mining' },
          industry_year_expreience: '17',
          bio: 'Mining engineer with experience in resource extraction and management.',
          mentor_charges: '2300',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/8.jpg',
          first_name: 'Karen',
          last_name: 'Anderson',
          location: 'Moscow, Russia',
          industry: { industry_name: 'Technology' },
          industry_year_expreience: '4',
          bio: 'Tech entrepreneur with a focus on startups and innovation.',
          mentor_charges: '900',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/9.jpg',
          first_name: 'Steven',
          last_name: 'Thomas',
          location: 'Singapore, Singapore',
          industry: { industry_name: 'Logistics' },
          industry_year_expreience: '19',
          bio: 'Logistics manager with expertise in supply chain and transportation.',
          mentor_charges: '2400',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/9.jpg',
          first_name: 'Donna',
          last_name: 'Taylor',
          location: 'Hong Kong, China',
          industry: { industry_name: 'Retail' },
          industry_year_expreience: '3',
          bio: 'Retail specialist with a focus on customer experience and sales.',
          mentor_charges: '700',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/men/10.jpg',
          first_name: 'Daniel',
          last_name: 'White',
          location: 'Bangkok, Thailand',
          industry: { industry_name: 'Tourism' },
          industry_year_expreience: '8',
          bio: 'Tourism expert with experience in travel planning and hospitality.',
          mentor_charges: '1500',
      },
      {
          profile_photo: 'https://randomuser.me/api/portraits/women/10.jpg',
          first_name: 'Nancy',
          last_name: 'Harris',
          location: 'Kuala Lumpur, Malaysia',
          industry: { industry_name: 'Hospitality' },
          industry_year_expreience: '12',
          bio: 'Hospitality manager with a focus on hotel operations and guest services.',
          mentor_charges: '1300',
      },
  ];
  

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        // console.log("industryyy", industry_sector);
        // const course_preferred = qualification.map((item) => item?.id);
        const industry = industry_sector.map((item) => item?.id);
        // console.log(getValues("location"), "location");
        // const location = getValues("location");
      // console.log("charsssss", charge1, charge2);

        dispatch({
          type: "change_value",
          payload: {
            // course_preferred,
            industry_sector: industry,
            // location: location,
            charge1,
            charge2,
          },
        });

        setLoading(false)
      };



      const onReset = () => {
        setLoading(true)
        // setQualification(() => []);
        setIndustrySector(() => []);
        setCharge1(() => "");
        setCharge2(() => "");
        setInputValue("")

        reset();
        dispatch({
          type: "change_value",
          payload: {
            // course_preferred: [],
            industry_sector: [],
            location: "",
            charge1: 0,
            charge2: 0,
          },
        });

        setLoading(false)
      };

    const fetchMentors = async () => {
      setLoading(true)
        const filters = Object?.keys(state).reduce((acc, curr) => {
          if (curr === "selected") return acc;
          else if (state[curr] instanceof Set) {
            if (!state[curr].size) return acc;
            const set = [...new Set(state[curr])];
            return { ...acc, [curr]: set };
          } else if (state[curr]) {
            return { ...acc, [curr]: state[curr] };
          }
          return acc;
        }, {});
        try {
          const { data: reqData } = await axios.get(
            `/v1/student/mentor/get_mentor_list`,
            {
              params: { ...filters },
              validateStatus: () => true,
            }
          );
          if (!reqData) throw new Error("Error fetching user");
          const { data, error, message } = reqData;
          if(data?.length === 0){
            setLoading(false)
          }
          // setMentors(mentorsData);
          setMentors(data);
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      };

      useEffect(() => {
        fetchMentors();
      }, [state]);

      const [selectedMentor, setSelectedMentor] = useState({})

      // console.log("indudsssss", industry_sector);



      // console.log("mentorrr", mentors);

  return (
    <div  style={{backgroundColor: "#EBEFF7"}}>
      <div className='container new_all_mentors_wrapper'>
          <h2 className='d_mentor_head'>Explore Our Mentors</h2>

          {/* Filter box section */}
          <div className="top_filter_box">

          <form className='mentor_desktop_filters'>
              <div className="filters">

                  {/* industry filter */}
                  <div className="industry_filter filter_item">
                      <div className="label">
                          {/* <h4>Industry Sector</h4> */}
                      </div>

                      <div className="filter_input">
                      <NewTextFieldWithPopup
                          label="Industry Sector"
                          id="industry"
                          register={register}
                          items={industry_sector}
                          setItems={setIndustrySector}
                          isPopupOpen={isPopupOpen}
                          updatePopup={updatePopup}
                          path="/v1/filter/industry_sector"
                          key_name="industry_name"
                          setValue={setValue}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          withId="city"
                          // className="location"
                          placeholder="Enter Industry Sector"
                          // onInputChange={setIndustrySector}
                      />
                      </div>
                  </div>

                  <div className="vertical_line"></div>
                  
                  {/* charges filter */}
                  <div className="charges_filter filter_item">
                      <div className="label">
                          <h4>Charges (per session)</h4>
                      </div>

                      <div className="filter_input">
                          <div className="min_max">
                              <div className="charge_inputs">
                                  <span> <MdOutlineCurrencyRupee size={20} /> </span>
                                  <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Min"
                                  onChange={(e) => setCharge1(e.target.value)}
                                  value={charge1}
                              />
                              </div>

                              <div className="arrow">
                                  <FaArrowRightLong />
                              </div>

                              <div className="charge_inputs">
                              <span><MdOutlineCurrencyRupee size={20} /></span>
                              <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Max"
                                  onChange={(e) => setCharge2(e.target.value)}
                                  value={charge2}
                              />
                              </div>

                          </div>
                      </div>

                  </div>

                  <div className="vertical_line"></div>

                  {/* search button */}
                  <div className="charges_filter search_btn">
                  <button onClick={(e) => onSubmit(e)} type="submit" className="apply">
                      { "Search"}
                  </button>
                  {
                      (charge1?.length !== 0 || charge2?.length !== 0 || industry_sector?.length !== 0) &&

                      <span onClick={onReset}><RxCross2 size={22} /></span>

                  }
                  </div>

              </div>
          </form>

          <div className="mentor_mob_filters">
            <div>
              <h2> Explore Our Mentors</h2>
            </div>
              {/* <div className="search_box_style w-100">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="search" placeholder="search by keyword"  />
              </div> */}
            <i
              class="fa fa-sliders slider"
              aria-hidden="true"
              onClick={() => setShow(true)}
            ></i>
          </div>
          


          </div>

          {show && (
            <div className="mobo_filter d-block d-md-none">
              <div
                className={`mobile_FilterBtn my-2 d-flex justify-content-between align-items-center gap-2 w-100 d-md-none ${
                  show ? "open" : ""
                } `}
              >
                <div className="d-flex justify-content-center align-items-center gap-2 ">
                  <h4 className="m-0" style={{ color: "#6149CD" }}>
                    Filters
                  </h4>
                  <span className=" d-block mt-">
                    <i
                      class="fa-solid fa-sliders fa-lg "
                      style={{ color: "#6149CD" }}
                    ></i>
                  </span>
                </div>
                <div className="toggle_box">
                  <span className="d-block filter">
                    <i
                      class="fa-solid fa-x fa-lg "
                      style={{ color: "#6149CD" }}
                      onClick={toggleOFf}
                    ></i>
                  </span>
                </div>
              </div>

              <MentorFilterBox dispatch={dispatch} state={state} toggleOFf={toggleOFf} />
            </div>
          )}

          
          {/* mentor cards */}
          <div className="mentor_cards">

            {
              mentors?.length !== 0 ? 
             ( mentors?.map((mentor, i)=> (

                <MentorCard data={mentor} key={i} setMentorPopup={setMentorPopup} setSelectedMentor={setSelectedMentor}  />
              ))) :

              (
                loading ? (
                  <div className='loder_div' >
                    <Loader />

                </div>
                ): (
                  <p className='loder_div' >No Mentor found</p>
                )

               
              )
            }
          </div>

          

      </div>

      {
        mentorPopup && 
          <MentorDetailPopup selectedMentor={selectedMentor} onClose={closeMentorPopup}  />
      }

    </div>
  )
}

export default NewAllMentors