import SidebarButtons from "components/dashboard/sidebarButtons/SidebarButtons";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../../images/logos/dashboard-logo.png";
import "./style.scss";
import { logoutUser } from "services/actions/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
// import ToolTip from "../sidebarButtons/ToolTip"

const AdminSidebar = ({ showSidebar, toggleSidebar }) => {
  const { pathname } = useLocation();
  const isActive = pathname.split("/")[2];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = async () => {
    const {
      data: { data, error },
    } = await axios.delete("/v1/auth/session/", {
      validateStatus: () => true,
    });

    if (!error) {
      toast.info("Logged out successfully!");
      navigate("/signin");
      dispatch(logoutUser({ cookieDeleted: false }));
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className={showSidebar ? "sidebar show" : "sidebar"}>
      <div className="brand-container px-3 pt-2 d-flex justify-content-between align-items-center">
        <img
          onClick={() => {
            navigate("/");
          }}
          src={Logo}
          height="30px"
          width="120px"
          className="dashboard-logo"
          alt="qollabb icon"
        />
        <span
          className={`close_btn ${showSidebar ? "show" : ""} .d-lg-none`}
          onClick={() => {
            toggleSidebar(false);
          }}
        ></span>
      </div>
      <hr />
      <div className="sidebar-buttons">
        <SidebarButtons
          text="Dashboard"
          link="/admin/dashboard"
          icon="fa-solid fa-house"
          locked={false}
          myClass={isActive === "dashboard" ? "active" : ""}
        />

        <SidebarButtons
          text="Evaluations"
          link="/admin/evaluations"
          icon="fas fa-clipboard-check"
          locked={false}
          myClass={isActive === "evaluations" ? "active" : ""}
        />
        <SidebarButtons
          text="Submissions"
          link="/admin/project-submissions"
          icon="fas fa-file-upload"
          locked={false}
          myClass={isActive === "project-submissions" ? "active" : ""}
        />

        <SidebarButtons
          text="Master Data"
          link="/admin/master-data"
          icon="fas fa-database"
          locked={false}
          myClass={isActive === "master-data" ? "active" : ""}
        />

        <SidebarButtons
          text="Subscriptions"
          link="/admin/subscriptions"
          icon="fas fa-credit-card"
          locked={false}
          myClass={isActive === "subscriptions" ? "active" : ""}
        />
        <SidebarButtons
          text="Subscription Invoice"
          link="/admin/subscription-invoice"
          icon="fas fa-credit-card"
          locked={false}
          myClass={isActive === "subscription-invoice" ? "active" : ""}
        />

        <SidebarButtons
          text="Complaints"
          link="/admin/complaints"
          icon="fas fa-comment-dots"
          locked={false}
          myClass={isActive === "complaints" ? "active" : ""}
        />

        <SidebarButtons
          text="Coupons"
          link="/admin/coupons"
          icon="fas fa-ticket-alt"
          locked={false}
          myClass={isActive === "coupons" ? "active" : ""}
        />

        <SidebarButtons
          text="Users"
          link="/admin/users"
          icon="fas fa-users"
          locked={false}
          myClass={isActive === "users" ? "active" : ""}
        />
         {/* <SidebarButtons
          text="Analytics"
          link="/admin/analytics"
          icon="fa-solid fa-file-circle-check"
          locked={false}
          myClass={isActive === "analytics" ? "active" : ""}
        /> */}

        <SidebarButtons
          text="Campaigns"
          link="/admin/campaigns"
          icon="fas fa-bullhorn"
          locked={false}
          myClass={isActive === "campaigns" ? "active" : ""}
        />

        <SidebarButtons
          text="Settings"
          link="/admin/settings"
          icon="fa-solid fa-gear"
          locked={false}
          myClass={isActive === "settings" ? "active" : ""}
        />
        <SidebarButtons
          text="Logout"
          onClick={logout}
          icon="fa-solid fa-sign-out-alt"
          locked={false}
        />
      </div>
    </div>
  );
};

export default AdminSidebar;
