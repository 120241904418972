import React, { useEffect, useRef, useState } from "react"
import image from "./footerLogo.png"
import "./footer.scss"
import { Link, useNavigate } from "react-router-dom"
import MainLogo from "../../../../images/logos/logo-main.png"
import axios from "axios"

const Footer = () => {
  const mapLinks = [
    {
      icon: <i className="facebookSo_icon fa fa-facebook" aria-hidden="true"></i>,
      link: "https://www.facebook.com/Qollabbedutechh",
    },
    {
      icon: <i class="footer_icons fa fa-linkedin" aria-hidden="true"></i>,
      link: "https://www.linkedin.com/company/qollabb/",
    },
    {
      icon: <i class="footer_icons fa fa-instagram" aria-hidden="true"></i>,
      link: "https://www.instagram.com/qollabb/",
    },
    {
      icon: <i class="footer_icons fa fa-youtube" aria-hidden="true"></i>,
      link: "https://www.youtube.com/@Qollabb",
    },
  ]

  const navigate = useNavigate()

  const handleLinkClick = (tab) => {
    if (tab === 0) {
      navigate("/explore-page/top-projects", { state: { tab: tab } })
    } else if (tab === 1) {
      navigate("/explore-page/top-internships", { state: { tab: tab } })
    } else if (tab === 2) {
      navigate("/explore-page/top-jobs", { state: { tab: tab } })
    }
  }

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const [Location, setLocation] = useState([])
  const [Industry, setIndustry] = useState([])
  const [courses,setCourses] =useState([])
  const [skills,setSkills]=useState([])

  const fetchIndustries = async () => {
    const result = await axios.get("/v1/filter/industry_sector/top-industries")

    const data = result?.data?.data?.map((item) => ({
      id: item?.industry?.id,
      industry_name: item?.industry?.industry_name,
    }))

    setIndustry(data)
  }

  const fetchLocations = async () => {
    const result = await axios.get("/v1/location/top-locations")

    const data = [...new Set(result?.data?.data?.map((item) => item?.city))]
      .slice(0, 5)
      .map((item) => ({
        city: item,
      }))

    setLocation(data)
  }

  const fetchCourses = async () => {
    const result = await axios.get("/v1/filter/courses/top-courses")


    const data = result?.data?.data.map(course => ({
      ...course,
      course_name: course.course_name.toLowerCase()
    }))

    setCourses(data)
  }
  const fetchSkills = async () => {
    const result = await axios.get("/v1/filter/skills/top-skills")
    // console.log("result",result)

    const data = result?.data?.data?.map(skill => ({
      ...skill,
      skill_name: skill?.name
    }))

    setSkills(data)
  }
// console.log("skills",skills)


  useEffect(() => {
    fetchIndustries()
    fetchLocations()
    fetchCourses()
    fetchSkills()
  }, [])

  // console.log("location",Location)
  // console.log("industy",Industry)
  // console.log("courses",courses)

  return (
    <div className="footer_container-main">
      <div className="footer_container main_padding main container-lg">
        <div className="row pb-3 ">
          <div className="col-lg-10 col-md-12 col-12 col-sm-12">
            <div className="row pt-3">
              <div className="col-lg-3 col-md-6 col-12 col-sm-6  left_part_footer gap-2 ">
                <ul className="">
                  <h5>Users</h5>

                  <p>
                    <Link to="/student-page" className="p-0">
                      Students{" "}
                    </Link>
                  </p>
                  <p>
                    <Link to="/company-page" className="p-0">
                      Companies / Employers{" "}
                    </Link>
                  </p>
                  <p>
                    <Link to="/college-page" className="p-0">
                      Colleges & Universities{" "}
                    </Link>
                  </p>
                  <p>
                    <Link to="/signup/student" className="p-0">
                      Student Signup
                    </Link>
                  </p>

                  <p>
                    <Link to="/signup/employer" className="p-0">
                      Company / Employer Signup
                    </Link>
                  </p>

                  <p>
                    <Link to="/signup/educator" className="p-0">
                      College / University Signup
                    </Link>
                  </p>

                  <p>
                    <Link to="/signin" className="p-0">
                      Login
                    </Link>
                  </p>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-12 col-sm-6  left_part_footer">
                <ul className="">
                  <h5>Company</h5>
                  <p>
                    <Link to="#" className="p-0">
                      About Us
                    </Link>
                  </p>

                  <p>
                    <Link to="/team" className="p-0">
                      Team
                    </Link>
                  </p>
                  <p>
                    <Link to="/industry-partners" className="p-0">
                      Industry Partners
                    </Link>
                  </p>
                  <p>
                    <Link to="/academic-partners" className="p-0">
                      Academic Partners
                    </Link>
                  </p>
                  <p>
                    <Link to="/faq" className="p-0">
                      FAQ
                    </Link>
                  </p>

                  <p>
                    <Link to="/contact-us" className="p-0">
                      Contact Us
                    </Link>
                  </p>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-lg-3 col-md-6 col-12 col-sm-6  left_part_footer">
                <ul className="">
                  <h5>Policies</h5>
                  <p>
                    <Link className="p-0" to="/terms-conditions">
                      Terms & Conditions
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link className="p-0" to="/cookies-policy">
                      Cookies Policy
                    </Link>
                  </p>
                  <p>
                    <Link className="p-0" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </p>
                  <p>
                    <Link className="p-0" to="/mentoring-policy">
                      Mentoring Policy
                    </Link>
                  </p>
                  <p>
                    <Link className="p-0" to="/refund-policy">
                      Cancellation & Refund Policy
                    </Link>
                  </p>
                </ul>
              </div>

           

               <div className="col-lg-3 col-md-6 left_part_footer">
                <ul className="custom-news-list">
                  <h5>Professional Tips and Advice </h5>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/python-internship/"
                      className="p-0"
                    >
                      Top 5 Tech Internship Opportunities for College Students
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/python-internship/"
                      className="p-0"
                    >
                      Top 5 Tech Internship Opportunities for College Students
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/cloud-computing/"
                      className="p-0"
                    >
                      How Karthik, A B.Com Graduate, Got a Job as a Software Developer
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/top-internships-in-tech/"
                      className="p-0"
                    >
                      Top Internships in Data Science, Data Analysis, Android App Development
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/qollabb-success-story-graphic-design-animation/"
                      className="p-0"
                    >
                      How Qollabb Helped Avni Grab Her Dream Job in the Graphic Designing and Animation Industry
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/mastering-campus-placements/"
                      className="p-0"
                    >
                      How to Secure Campus Placement: A Comprehensive Guide
                    </Link>
                  </p>
                  {/* <p>
                    <Link
                      to="https://blog.qollabb.com/unlocking-opportunities-top-20-jobs-for-2024-graduates/"
                      className="p-0"
                    >
                      Top 20 Jobs for 2024 Graduates
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/cruciality-of-personal-branding-for-youngsters/"
                      className="p-0"
                    >
                      Cruciality of Personal Branding for Youngsters
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://blog.qollabb.com/embracing-rise-robotics-opportunities-students/"
                      className="p-0"
                    >
                      The Evolving Landscape of Robotics and the Abundance of
                      Opportunities Across Industries
                    </Link>
                  </p> */}
                  <p>
                    <Link to="https://blog.qollabb.com/" className="p-0">
                      See All ...
                    </Link>
                  </p>
                </ul>
              </div>


            </div>

            <div className="row pt-3">
              <div className="col-lg-3 col-md-6 left_part_footer">
                <ul className="">
                  <h5>Industry Projects</h5>
                  {Industry?.map((item) => (
                    <p key={item.id}>
                      <Link
                        to={`/explore-page/top-projects-sector-${item?.industry_name
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Projects in {item.industry_name}
                      </Link>
                    </p>
                  ))}
                  {Location.map((item, index) => (
                    <p key={index}>
                      <Link
                        to={`/explore-page/top-projects-in-${item?.city
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Projects in {item?.city}
                      </Link>
                    </p>
                  ))}
                   <p >
                      <Link
                        to={`/explore-page/top-projects`}
                        className="p-0"
                      >
                        See All...
                      </Link>
                    </p>

                </ul>
              </div>

              <div className="col-lg-3 col-md-6 left_part_footer">
                <ul className="">
                  <h5>Internships</h5>
                  {Industry?.map((item) => (
                    <p key={item.id}>
                      <Link
                        to={`/explore-page/top-internships-sector-${item?.industry_name
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Internships in {item.industry_name}
                      </Link>
                    </p>
                  ))}
                  {Location.map((item, index) => (
                    <p key={index}>
                      <Link
                        to={`/explore-page/top-internships-in-${item?.city
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Internships in {item?.city}
                      </Link>
                    </p>
                  ))}
                    <p >
                      <Link
                        to={`/explore-page/top-internships`}
                        className="p-0"
                      >
                        See All...
                      </Link>
                    </p>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 left_part_footer">
                <ul className="">
                  <h5>Fresher Jobs</h5>
                  {Industry?.map((item) => (
                    <p key={item.id}>
                      <Link
                        to={`/explore-page/top-jobs-sector-${item?.industry_name
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Jobs in {item.industry_name}
                      </Link>
                    </p>
                  ))}
                  {Location.map((item, index) => (
                    <p key={index}>
                      <Link
                        to={`/explore-page/top-jobs-in-${item?.city
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Jobs in {item?.city}
                      </Link>
                    </p>
                  ))}
                    <p >
                      <Link
                        to={`/explore-page/top-jobs`}
                        className="p-0"
                      >
                        See All...
                      </Link>
                    </p>
                </ul>
              </div>

            
               <div className="col-lg-3 col-md-6 left_part_footer">
                <ul>
                <h5>Top Programs / Courses</h5>
                {courses?.map((item) => (
                    <p key={item.id}>
                      <Link
                        to={`/explore-page/top-internships-for-${item?.course_name
                          .toLowerCase()
                          .replace(/ /g, "_")}`}
                        className="p-0"
                      >
                        Internships for {capitalizeWords(item.course_name)}
                      </Link>
                    </p>
                  ))}
                   <p >
                      <Link
                        to={`/explore-page/top-internships`}
                        className="p-0"
                      >
                        See All...
                      </Link>
                    </p>
               

                </ul>

              </div>



            




            </div>

           




            {/* Other content */}
          </div>




          <div className="col-lg-2 col-md-12 col-12 col-sm-12 change-poistion">
          
            <div className="right_part_footer row pt-3 pb-5">
              <Link className="p-0" to="/">
                <img className="footer_logo" src={MainLogo} alt="Image" />
              </Link>
              <h4>
                Connecting companies with
                <br />
                the brilliant minds
                <br />
                in campuses
              </h4>
              <div className="left_part_icons">
                {mapLinks.map((item, i) => (
                  <Link target="_blank" to={item.link} key={i}>
                    {item.icon}
                  </Link>
                ))}
              </div>
            </div>

            
            <div className="left_part_footer row pt-3 topskills">
           {


              <ul>
              <h5>Top Skills</h5>
              {skills?.map((item) => (
                       <p key={item.id}>
                         <Link
                           to={`/explore-page/top-internships-skill-${item?.name
                             .toLowerCase()
                             .replace(/ /g, "_")}`}
                           className="p-0"
                         >
                           Internships in {item?.name}
                         </Link>
                       </p>
                     ))}
                      <p >
                         <Link
                           to={`/explore-page/top-internships`}
                           className="p-0"
                         >
                           See All...
                         </Link>
                       </p>
   
   
              </ul>

           }
        

             </div>



          
          


          </div>




          {/* mobile view */}
          <div className="col-lg-2 col-md-12 col-12 col-sm-12 change-poistion-mobile  ">
          <div className="row pt-3">
            <div className="col-12 left_part_footer gap-2   pb-5 ">
            {

              <ul>
              <h5>Top Skills</h5>
              {skills?.map((item) => (
                       <p key={item.id}>
                         <Link
                           to={`/explore-page/top-internships-skill-${item?.name
                             .toLowerCase()
                             .replace(/ /g, "_")}`}
                           className="p-0"
                         >
                           Internships in {item?.name}
                         </Link>
                       </p>
                     ))}
                      <p >
                         <Link
                           to={`/explore-page/top-internships`}
                           className="p-0"
                         >
                           See All...
                         </Link>
                       </p>
   
   
              </ul>

           }

            </div>

            <div className=" col-12  right_part_footer ">
              <Link className="p-0" to="/">
                <img className="footer_logo" src={MainLogo} alt="Image" />
              </Link>
              <h4>
                Connecting companies with
                <br />
                the brilliant minds
                <br />
                in campuses
              </h4>
              <div className="left_part_icons">
                {mapLinks.map((item, i) => (
                  <Link target="_blank" to={item.link} key={i}>
                    {item.icon}
                  </Link>
                ))}
              </div>
            </div>



            </div>
          </div>
          {/* end mobile view */}

        </div>

        <div className="bottom_part">
          <p>Copyright@Qollabb EduTech Pvt. Ltd. - 2020, All rights Reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
