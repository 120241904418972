import React from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import HeroSection from "./components/heroSection/HeroSection";
import StartJourney from "./components/Journey/StartJourney";
import PartnerSection from "./components/partners/partnerSection";
import TestimonialSlider from "./components/testimonial/testimonialSlider";
import Footer from "view/dashboard/student/footer/Footer";
import companyimg from "../../images/companyHeroInhanced.png";
import companyJimg from "../../images/companyJimg.png";
import GoToTop from "GoToTop";
import img1 from "./images/c1.png";
import img2 from "./images/c2.png";
import img3 from "./images/c3.png";

import { Helmet } from "react-helmet";
import IndustryPartnerSection from "./components/partners/industryPartner";

const CompanyPage = () => {
  return (
    <section className="home_page row justify-content-center align-items-center">
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="We are offers internship for engineering students, where they can hone their skills and apply their knowledge in a professional environment."
        />
        <title>
          Project-based learning | online paid internships | internship for
          engineering students
        </title>
        <link rel="canonical" href="https://qollabb.com/company-page" />
      </Helmet>
      <TopNavBar />
      <NavBar />
      <HeroSection
        topText="Discover innovative minds"
        cardText="Great Way To Find Talent"
        buttonText="Get Started"
        hero={companyimg}
        title="Find Best Performing College Graduates with "
        title2="Qollabb"
        semiTitle="At Qollabb, we know that finding the right employee can be a challenge. That's why we offer a solution that allows you to engage with students and recent graduates, build your brand, and potentially find your next star employee."
      />
      <StartJourney
        Simg={companyJimg}
        title1="01. Create a Profile"
        subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
        title2="02. Drive Innovation & Build Bright Ideas"
        subTitle2="Outsource small projects to young bright students & allow them to comeback with innovative ideas & solutions for your challenges. Connect & collaborate with their colleges/universities to build a sustained pool of brightest candidates."
        title3="03. Simplify Hiring The Brightest"
        subTitle3="Hire only the graduates who have already contributed with their bright ideas & showcased their capabilities."
      />
      {/* <PartnerSection /> */}
      <IndustryPartnerSection/>
      <TestimonialSlider
        title="What Employers"
        img1={img1}
        name1="Amit Rawat"
        designation1="CEO, Meetri Infotech"
        title1="Great Way To Find Talent !"
        subTitle1="We were impressed with the quality of the work delivered by the students we collaborated with on Qollabb. Their fresh perspectives and enthusiasm brought new ideas to our project and we were able to see the potential in some of the candidates we worked with. Qollabb was a great way for us to connect with the next generation of talent and identify potential hires for our team."
        img2={img2}
        name2="Prashant Sirohi"
        designation2="CEO, Regent DigiTech"
        title2="Cost Effective Hiring !"
        subTitle2="As a small business, we were looking for a cost-effective way to get some help with our marketing strategy. Qollabb was the perfect solution for us. We were able to work with a group of marketing students who provided us with valuable insights and recommendations that helped us reach our target audience more effectively. We would highly recommend Qollabb to other small businesses looking for creative solutions."
        img3={img3}
        name3="Ayush Singhal"
        designation3="CEO, Pinsout Innovation"
        title3="Easy Collaboration With Colleges !"
        subTitle3="Our company is always looking for ways to give back to the community and we found the perfect opportunity through Qollabb. We worked with a group of students on a project that aimed to improve access to healthcare for underserved populations. The students were passionate and dedicated to the cause, and we were impressed by the quality of work they produced. Working with Qollabb allowed us to make a positive impact while also identifying potential future employees for our team."
      />
      <Footer />
      <GoToTop />
    </section>
  );
};

export default CompanyPage;
