import axios from "axios";
import ProfileIcon from "components/employer/ProfileIcon";
import React from "react";
import { Link as NewLink } from "react-router-dom";
import { toast } from "react-toastify";
import Link from "../../newCompanyCards/Link.svg";
import LinkedImage from "../../newCompanyCards/Linkedin.svg";
import User from "../CompaniesCard/User.png";
import LeftArrow from "./Left_arrow.svg";
import RightArrow from "./Right_arrow.svg";
import "./companiesDetailCard.scss";

const CompaniesDetailCard = ({
  style1,
  style2,
  text1,
  text2,
  setPage,
  currentPage,
  emploayerDataDetails,
  pageLength = 0,
  cancelInvite,
  cancel = false,
  invitationId,
  changeStatus,
  change = false,
  setRefresh,
}) => {
  const data = emploayerDataDetails;
  const onClickHandler = async (e, id) => {
    e?.preventDefault();
    sendInvitationFunc(id);
  };
  const sendInvitationFunc = async (id = 0) => {
    try {
      // console.log("hello2");
      if (id) {
        const res = await axios.post("/v1/invitations/send_invite", {
          receiver_id: [id],
        });

        setRefresh((prevState) => !prevState);
        toast.success("Invitation Sent");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const chatApi = async () => {
      try {
        const chatRoomId = await axios.post("/v1/chat/create-chat-room", {
          userId: data.id,
          role: 2, // employer
          enrollment_type: null,
          enrollment_id: null,
        });
        // console.log(chatRoomId);
      } catch (error) {
        throw new Error();
      }
    };
    chatApi();
  };

  // console.log("detail data",data);

  return (
    <div className="companies_detail_card">
      <div className="menu_container">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
      </div>
      <div className="card_container">
        <div className="top_section">
          <div className="conpany">
            {data?.company_logo || data?.branch?.company_logo ? (
              <img
                src={data?.company_logo || data?.branch?.company_logo}
                alt="Image"
                className="rounded"
                width={50}
                height={50}
              />
            ) : (
              <div
                style={{
                  height: "40px",
                  width: "40px",
                }}
              >
                <ProfileIcon />
              </div>
            )}
            <div className="company_detail">
              <h4>{data?.company?.company_name}</h4>
              <p>{data?.location}</p>
            </div>
          </div>
          <div className="user">
            <p>Contact Person</p>
            <div className="user_data_container">
              <img src={User} alt="Image" />
              <div>
                <h4>
                  {data?.first_name} {data?.last_name}
                </h4>
                <p>{data?.designation}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mid_section">
          <p>{data?.bio}</p>
        </div>
        <div className="bottom_content">
          {data?.profile_link ? (
            <div className="link_btn">
              <img src={Link} alt="Image" />
              <a href={data?.profile_link}>{data?.profile_link}</a>
            </div>
          ) : (
            <></>
          )}
          {data?.linkedIn_link ? (
            <div className="link_btn">
              <img src={LinkedImage} alt="Image" />
              <a href={data?.linkedIn_link}>{data?.linkedIn_link}</a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* <div className="tags_container">
        <h4>Interest areas</h4>
        <p>B.Tech in computer science</p>
      </div> */}

      <div className="button_container">
        <div className="buttons">
          {text1 && (
            <button
              {...(cancel && {
                onClick: (e) => cancelInvite(e, invitationId),
              })}
              className="btn Not_interested"
              style={style1}
            >
              {text1}
            </button>
          )}
          {change ? (
            <button
              className="btn send_Invitation"
              onClick={(e) => changeStatus(e, invitationId)}
              style={style2}
            >
              {/* {text2} */}
              <i className="fa fa-send"></i>
            </button>
          ) : (
            <NewLink to={"/educator/chat"}>
              <button
                className="btn send_Invitation"
                onClick={(e) => handleChange(e)}
                style={style2}
              >
                {text2}
                <i className="fa fa-send"></i>
              </button>
            
            </NewLink>
          )}
        </div>

        <div className="pagination_btn">
          <img
            onClick={() => setPage(currentPage === 0 ? 0 : currentPage - 1)}
            src={LeftArrow}
            alt="Image"
          />
          <p>
            {currentPage + 1}/{pageLength}
          </p>
          <img
            onClick={() =>
              setPage(
                pageLength === currentPage + 1 ? currentPage : currentPage + 1
              )
            }
            src={RightArrow}
            alt="Image"
          />
        </div>
      </div>
    </div>
  );
};

export default CompaniesDetailCard;
