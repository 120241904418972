import TextInput from 'components/formComponents/TextInput'
import React from 'react'
import NumberInputField from 'view/dashboard/mentor/components/NumverInputField/NumberInputField'

const PaymentPopUp = ({ message, onClose, handlePayment,mentorCharges ,register=()=>{}},Beginner,Intermediate,Expert) => {
    return (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="modal_container"
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "500px",
              height: "fit-content",
            }}
          >
            <div className="flex w-100 pb-1 border-bottom ">
              <h3 className="fs-4 fw-bold">{message}</h3>
              <i
                onClick={onClose}
                class="fa fa-times fs-3 fw-bold"
                aria-hidden="true"
              ></i>
            </div>
    
            <div className="my-4">
              <span className="my-2 fw-bold">Pay With:</span>
              <div className="d-flex w-100 gap-2 mt-2 mb-3">
                <div className="flex gap-2">
                  <input className="form-check-input" type="checkbox" value="" />
                  <p className="m-0">Card</p>
                </div>
                <div className="flex gap-2">
                  <input className="form-check-input" type="checkbox" value="" />
                  <p className="m-0">Bank</p>
                </div>
                <div className="flex gap-2">
                  <input className="form-check-input" type="checkbox" value="" />
                  <p className="m-0">Upi</p>
                </div>
              </div>
    
              <div>
                <div className="w-100">
                  <NumberInputField
                    label="Card Number"
                    id="card_number"
                    labelClassName="fieldLabel"
                    register={register}
                    placeholder="1234 5678 9101 1121"
                    className="car_fields"
                  />
                </div>
                <div className="d-flex gap-2 w-100">
                  <TextInput
                    label="Expiration Date"
                    id="expiration_date"
                    labelClassName="fieldLabel"
                    register={register}
                    placeholder="MM/YY"
                    className="car_fields"
                    className1="w-100"
                  />
                  <NumberInputField
                    label="CVV"
                    id="cvv"
                    labelClassName="fieldLabel"
                    register={register}
                    placeholder="123"
                    className="car_fields"
                    className1="w-100"
                  />
                </div>
                <div className="d-flex gap-2">
                  <input className="form-check-input" type="checkbox" value="" />
                  <p className="m-0">Save card details</p>
                </div>
              </div>
              <button className="bg-success w-100 my-4" onClick={handlePayment}>
              Pay ₹ {mentorCharges}
              </button>
    
              <p>
                Your personal data will be used to process your order, support your
                experience throughout this website.
              </p>
            </div>
          </div>
        </div>
      )
}

export default PaymentPopUp