import axios from "axios"
import PasswordField from "components/formComponents/PasswordField"
import TextField from "components/formComponents/TextField"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const AdminAddUser = () => {
  // const navigate=useNavigate()

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [userType, setUserType] = useState("")
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email_address: "",
      full_name: "",
      phone_number: "",
    },
  })
  // console.log("usertype",userType)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data, event) => {
    setLoading(true)
    event.preventDefault()
    const encodedCredentials = btoa(`${data?.email_address}:${data?.password}`)
    let endpoint = ""

    try {
      if (userType === "1") {
        // mentor
        endpoint = "/v1/auth/signup/5"
      } else if (userType === "3") {
        // reviewer
        endpoint = "/v1/auth/signup/6"
      } else if (userType == "2") {
        // mentor manager
        endpoint = "/v1/auth/signup/7"
      } else {
        console.log("Invalid role")
      }
      // console.log("endpoint",endpoint)
      const res = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      })

      if (!res?.data?.error) {
        setLoading(false)
        toast.success("User is created")
        reset()
        //  navigate('/admin/dashboard')
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
      // console.log(res?.data, "userData");
    } catch (error) {
      console.error(error)
      setLoading(false)
      toast.error("An error occurred")
    }
  }

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value)
  }
  return (
    <div
      className="admin-users-page py-5 pt-4"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <h4 className="mb-5" style={{ fontWeight: "700" }}>
        Create New User
      </h4>
      <div className="row mx-0">
        <div className="col-lg-6 px-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mx-0">
              <div className="col-6 ps-0">
                <TextField
                  label="First Name"
                  required={true}
                  id="first_name"
                  placeholder="Enter full name"
                  register={register}
                  // disabled={true}
                />
              </div>
              <div className="col-6 ps-0">
                <TextField
                  label="Last Name"
                  required={true}
                  id="last_name"
                  placeholder="Enter full name"
                  register={register}
                  // disabled={true}
                />
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-6 ps-0">
                <TextField
                  label="Email"
                  required={true}
                  id="email_address"
                  placeholder="Enter email"
                  register={register}
                  // disabled={true}
                />
              </div>

              <div className="col-6 ps-0">
                <PasswordField
                  label="Password"
                  required={true}
                  id="password"
                  register={register}
                />
              </div>
            </div>
            <div className="col-6 pe-0">
              <div className="form-group">
                <label htmlFor="user_type" className="mb-2">
                  User Type
                </label>
                <select
                  class="form-select form-control"
                  aria-label="Default select example"
                  onChange={handleUserTypeChange}
                >
                  <option selected>Select User Type</option>
                  <option value="1">Mentor</option>
                  <option value="2">Mentor Manager</option>
                  <option value="3">Reviewer</option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-primary mt-4 px-5 text-white py-2"
              style={{
                fontWeight: "500",
              }}
              type="submit"
            >
              {loading ? (
                <i className="text-white fa fa-circle-o-notch fa-spin"></i>
              ) : (
                "Create User"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminAddUser
