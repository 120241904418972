import React, { useState } from "react";
// import CompanyImg from "../collegesCards/companyImg.png";
// import collegeImg from "../collegesCards/companyImg.png";
// import User from "../collegesCards/User.png";
import Link from "../Link.svg";
import LinkedImage from "../Linkedin.svg";

// import LinkedIn from "../collegesCards/Linkedin.svg";
import axios from "axios";
import ProfileIcon from "components/employer/ProfileIcon";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "./Left_arrow.svg";
import RightArrow from "./Right_arrow.svg";
// import RightArrow from "./collegesDetailCards.scss"

const StudentDetailsCard = ({
  studentDetails,
  style1,
  pageLength = 0,
  style2,
  text1,
  action2 = "1",
  text2,
  style_i,
  setPage,
  text3,
  currentPage,
  changeStatus,
  cancelInvite,
  sendInvite = false,
  setRefresh,
  method,
  setNew1,
}) => {
  //action types:
  //  1: Send Invitation

  // // console.log(educatorDataDetails, "educatorDataDetails");

  const students = studentDetails;

  // // console.log(students, "students");

  const { id } = useParams();

  const opportunity_id = id;

  const currentAge = (date_of_birth) => {
    var today = new Date();
    var birthDate = new Date(date_of_birth);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  const onClickHandler = async (e, id) => {
    e?.preventDefault();
    sendInvitationFunc(id);
  };
  const sendInvitationFunc = async (id) => {
    try {
      setLoading(true);
      if (id) {
        // console.log(id, "id123");
        const res = await axios.post(`/v1/employer/student-invitee`, {
          receiver_id: [id],
          // status: 1,
          opportunity_id: parseInt(opportunity_id),
          method: method,
        });

        // console.log(res);

        if (!res.data.error) {
          toast.success("Invitation Sent");
        } else {
          toast.error(res.data.message || "Something went wrong");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }

    setRefresh((prev) => !prev);
    setNew1((prev) => !prev);
  };

  const [loading, setLoading] = useState(false);

  const rejectInvitationFunc = async (id) => {
    try {
      setLoading(true);
      if (id) {
        const res = await axios.post("/v1/invitations/send_student_invite", {
          receiver_id: [id],
          status: 2,
          opportunity_id: parseInt(opportunity_id),
          method: method,
        });

        // console.log(res);
        toast.success("Rejected Invitation");
        setRefresh((prev) => !prev);
        setNew1((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const warningStyle = {
    background: "#F9B035",
  };

  return (
    <>
      {studentDetails ? (
        <>
          <div className="row m-0 collegeCard">
            <div className="cardHeader">
              <div className="collegeDetail studentDetail">
                <div className="logo">
                  {students?.profile_photo ? (
                    <img src={students?.profile_photo} alt="logo" srcset="" />
                  ) : (
                    <div className="img-box-img">
                      <ProfileIcon />
                    </div>
                  )}
                </div>

                <div className="details d-flex flex-column gap-0">
                  <div className="collegeName studentName">
                    {students?.first_name} {students?.last_name}
                  </div>
                  <div className="collegeLocation">
                    {students?.location && students?.location}
                  </div>
                </div>
              </div>
            </div>

            <div className="cardBody">
              <div className="education-details">
                {students?.course_name && (
                  <div className="student-course">
                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                    {students?.course_name}
                  </div>
                )}
                {students?.date_of_birth && (
                  <div className="student-course">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    {students?.date_of_birth &&
                      currentAge(students?.date_of_birth)}{" "}
                  </div>
                )}
              </div>
              <div className="collegeDescription">{students?.bio}</div>
              <div className="student-exp-border"></div>
              {students?.experiences?.length > 0 ? (
                <div className="student-exp-details">
                  <div className="student-exp-title">Experience</div>
                  <div className="student-exp">
                    {students?.experiences?.map((item, index) => {
                      return (
                        <>
                          <div className="student-exp-details">
                            <div className="exp-detail">
                              <div className="student-exp-title1">
                                <i class="fa fa-circle" aria-hidden="true"></i>
                                {item?.designation}
                              </div>

                              <div className="student-exp-company">
                                {item?.company_name}
                              </div>
                            </div>
                            <div className="student-exp-duration">
                              {moment(item?.start_date).format("MMM YYYY")} -{" "}
                              {moment(item?.end_date).format("MMM YYYY")}
                            </div>
                            <div className="student-exp-description">
                              {item?.description}
                            </div>
                          </div>
                          <div className="student-exp-border"></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="cardFooter student-card-footer my-2">
              <div className="collegeWebsite">
                {students?.profile_link && (
                  <>
                    <img src={Link} alt="Image" />
                    {students?.profile_link}
                  </>
                )}
              </div>
              <div className="userLinkedin">
                {students?.linkedIn_link && (
                  <>
                    <img src={LinkedImage} alt="Image" />
                    {students?.linkedIn_link}
                  </>
                )}
              </div>
            </div>
            <div className="student-btn w-100">
              {text1 && (
                <button
                  className="btn send_Invitation"
                  onClick={(e) => rejectInvitationFunc(students?.id)}
                  style={style2}
                >
                  {text1}
                  <i className="fa fa-send" style={style_i}></i>
                </button>
              )}
              {text2 && (
                <button
                  className="btn send_Invitation"
                  onClick={(e) => onClickHandler(e, students?.id)}
                  style={style2}
                >
                  {text2}
                  <i className="fa fa-send" style={style_i}></i>
                </button>
              )}
              {text3 && (
                <button
                  className="btn send_Invitation bg-warning warning-icon"
                  style={warningStyle}
                >
                  {text3}
                  <i className="fa fa-send" style={style_i}></i>
                </button>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                textAlign: "left",
                margin: "10px 0px",
              }}
              className="pagination_btn"
            >
              <img
                src={LeftArrow}
                onClick={() => setPage(currentPage === 0 ? 0 : currentPage - 1)}
                alt="Image"
              />
              <p>
                {currentPage + 1}/ {pageLength}
              </p>
              <img
                src={RightArrow}
                onClick={() =>
                  setPage(
                    pageLength === currentPage + 1
                      ? currentPage
                      : currentPage + 1
                  )
                }
                alt="Image"
              />
            </div>
          </div>
          {loading && (
            <div className="loading-container">
              <div className="text-center loading-wrapper">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <h1>no data</h1>
      )}
    </>
  );
};

export default StudentDetailsCard;
