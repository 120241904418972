import React from "react";

const StatusDropdown = ({
  name,
  id,
  label,
  required = false,
  options = [],
  setValue = () => {},
}) => {
  return (
    <div className="form-group mb-3">
      <label htmlFor={id} className="text_label">
        {label}
        {required && <span>*</span>}
      </label>
      <form>
        <select
          name={name}
          className="form-control form-select mt-2"
          onChange={setValue}
        >
          {options.map((value, index) => {
            return (
              <option value={value} key={index} className="form-control mt-2 ">
                {value}
              </option>
            );
          })}
        </select>
      </form>
    </div>
  );
};

export default StatusDropdown;
