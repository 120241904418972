import React from 'react'
import "./uploadStudent.scss"
import alertImg from "../../../../images/alert.png";

const UploadStudent = () => {
  return (
      <div className='px-4 py-5'>
          <div className="uploadStudent_progress_container">
          <div
          className="profile_progress profile-progress-container p-3"
          title={
            "Complete your profile to create internships, jobs and projects"
          }
        >
          <h4 className="d-flex align-items-center justify-content-between">
            <span>Complete your Profile</span>
            <span>100%</span>
          </h4>
          <div className="progress"></div>
        </div>
          </div>


          <div className="uploadStudent">
              <div className="upload_content"> 
              <img src={alertImg} alt="alert" />
                  <h4>You are yet to upload your students here</h4>
                  <p>Please upload your list of students using this template</p>

                  <div className="upload_btn">
                      <button className="btn download_btn">
                      Download Template
                      </button>

                      <button className="btn upload_list">
                      Upload List
                      </button>
                  </div>
              </div>
              
          </div>
        
      </div>
  )
}

export default UploadStudent