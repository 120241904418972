import { Link } from 'react-router-dom';
import React from 'react';
import './exploreSection.scss'
import useFetch from "hooks/useFetch"

import ExploreCard from './ExploreCard'

const ExploreSection = () => {
    const data = useFetch(`/v1/filter/project`)

  const internshipData = useFetch("/v1/filter/internship")

  const jobData = useFetch("/v1/filter/job")

  const job_data = jobData?.data?.data?.items

  const internship_data = internshipData?.data?.data?.items

  const project_data = data?.data?.data?.items
    return (
        <section className="course-area p-relative ">
            <ExploreCard project_data={project_data} method="project" btn="Apply Now" tab="0" url="top-projects" />
            <ExploreCard project_data={ internship_data} method="internship" btn="Apply Now" tab="1" url="top-internships"/>
            <ExploreCard project_data={ job_data} method="job"  btn="Apply Now" tab="2" url="top-jobs"/>
        </section>
    );
};

export default ExploreSection;