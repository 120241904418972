import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
import { toast } from "react-toastify";


const StudentGroupSessions = () => {

    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false);
    const [mySessions, setMySession] = useState({});
    const navigate = useNavigate();
    
    async function joinSession(e, item) {
        e.preventDefault();
        // console.log("inside join session")
        // if (new Date(item?.startTime).getTime() === new Date().getTime()) {
          // console.log("inside if")
        // if (new Date(item?.startTime) === new Date()) {
          if (1) {
            navigate(`/student/session/${item?.id}/${item?.roomId}`, {state: {isGroupSession: true}});
          } else {
            toast.warning("Your Session Haven't Started Now");
          }
          
        // }
        // else{
        //   toast.warning("Session not started yet")
        // }
      // }
    }
    
      const handleAccept = async (e, item) => {
        e.preventDefault()
       
        const res = await axios.put(`/v1/student/updateSessionStatus/${item?.id}`, {
            status: 1   // reschedule accepted 
        });
        if(!res?.data?.error){
          toast.success("Session Accepted Successfully")
        }else{
          toast.error("Error while accepting session")
        }
        setStatus(1)
        // console.log("response", res);
      }
    
      useEffect(()=> {

        const getData = async()=> {
            
            try {
                const res = await axios.get("/v1/student/get-group-session");

                // console.log("ress", res?.data?.data)
                setMySession(res?.data?.data);

            } catch (error) {
                console.log("err", error);
            }
        }
        
        getData();

      }, []);

      // console.log("mysession", mySessions);

                    // const date = new Date(item?.createdAt);
                  const date = moment(mySessions?.createdAt).format("DD-MM-YYYY");
                  // const formattedDate = date.toLocaleDateString("en-US");
                  const formattedDate = date;
                  // const startDate = new Date(item?.startTime);
                  const startDate = moment(mySessions?.startTime)
                    .tz("Asia/Kolkata")
                    .format("DD-MM-YYYY");

                  const startTime = moment(mySessions?.startTime)
                    .tz("Asia/Kolkata")
                    .format("hh:mm A");
                  const endTime = moment(mySessions?.endTime).tz("Asia/Kolkata").format("hh:mm A");
                  // const startingDate = startDate.toLocaleDateString("en-US");
                  const startingDate = startDate;

  return (
    mySessions !== null ? (
        <div className="student_mentor-list container">
        <div className="table-content">
        <div className="row">
            <div className="col-12">
            <div className="slidepage">
                <h2>Group Sessions</h2>
            </div>
            </div>
        </div>
        </div>
        {/* {mySessions && mySessions.length > 0?( */}
        <div className="row">
        <div className="col-12">
            <div className="student-available-table">
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr className="header_top">
                    <th scope="col">Date Schedule</th>
                    <th scope="col">Mentor Name</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Date Started</th>
                    <th scope="col" style={{textAlign: "center"}}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                    mySessions?.map((item) => {

                    return ( */}
                        <tr>
                        <td>{formattedDate}</td>
                        <td className="text-left">
                            {mySessions?.mentor?.first_name} {mySessions?.mentor?.last_name}{" "}
                        </td>
                        <td className="des-color fw-bold">
                            {mySessions?.project?.project_title?.length > 70
                            ? mySessions?.project?.project_title?.slice(
                                0,
                                70
                                ) + "..."
                            : mySessions?.project?.project_title}
                        </td>
                        <td style={{ color: mySessions?.status === 2 ? "#f96767" : ""  }}>
                            {startingDate}
                            <br />
                            ({startTime})
                        </td>

                            {mySessions?.status === 1 && (
                        <td>
                            <button
                                className="bg-success"
                                onClick={(e) => {
                                joinSession(e, mySessions);
                                }}
                            >
                                Join
                            </button>
                            </td>
                            )}
                            {/* {mySessions?.status === 2 && (
                            <td style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                            <button className="gr" onClick={(e)=> handleAccept(e, mySessions)}>Accept</button>
                            
                            <button className="gray">Rescheduled</button>
                            </td>
                            )} */}

                            {mySessions?.status === 3 && (
                              <td>
                                <button className="gray">Completed</button>
                              </td>
                            )}
                        </tr>
                    {/* );
                    })} */}
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
        {/* ): */}
    {/* 
        <div
        //     className="d-flex align-items-center justify-content-center"
        //     style={{ width: "auto", height: "71vh" }}
        //   >
        //    <div className="d-flex align-items-center flex-column justify-content-center">
        //        <img src={mentorpng} alt="mentor-img" style={{width:'40%' ,height:'40%'}} />
        //        <h3 className="fw-bold d-flex align-items-center">You currently have no mentor sessions booked.</h3>
        //   </div>
        //   </div>
        // ""
    
        // } */}
    </div>

    ): (
        ""
    )
  )
}

export default StudentGroupSessions