const StatusButton = ({ status = "", type="" }) => {
    // console.log(status, "status");
    return (
      <div className="status_chip_container">
        {(status === 0 || status === 3)  && (
          <span
            className={`px-4 py-2`}
            style={{
              fontSize: "12px",
              background: `${type === "project" ? "#F9B035" : "#6149CD"}`,
              color: "#FFFFFF",
              fontWeight: "500",
              borderRadius: "20px",
            }}
          >
           { type === "project" ?  "Ongoing" 
            : type === "internship" ? "Applied" 
            :type === "job" ? "Applied" :  ""
           }
          </span>
        )}
        {status === 1 && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: "#47D06520",
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
            Left
          </span>
        )}
        {status === 2 && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: "#47D065",
              color: "#FFFFFF",
              fontWeight: "500",
              borderRadius: "20px",
            }}
          >
            { type === "project"?  "Completed" 
            : type === "internship" ? "Seen" 
            :type === "job" ? "Seen" :  ""
           }
          </span>
        )}
        {status === 4 && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: `${type === "project" ? "#47d065" : "#F9B035"}`,
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
            { type === "project"?  "Shorlisted" 
            : type === "internship" ? "Shortlisted" 
            : type === "job" ? "Shortlisted" :  ""
           }
          </span>
        )}
        {status === 5 && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: "#F9B035",
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
            { type === "project"?  "Accepted" 
            : type === "internship" ? "In touch" 
            : type === "job" ? "In touch" :  ""
           }
          </span>
        )}
        {status === 6 && (
          <span
            className={`px-4 py-2`}
            style={{
              fontSize: "12px",
              background: "#F9B035",
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",

            }}
          >
            { type === "project"?  "Interview Scheduled" 
            : type === "internship" ? "Interview Scheduled" 
            :type === "job" ? "Interview Scheduled" :  ""
           }
          </span>
        )}
        {status === 7 && (
          <span
            className={`px-3 py-1`}
            style={{
              fontSize: "12px",
              background: `${type === "project" ? "#FF3737" : "#FF3737"}`,
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
           { type === "project"?  "Rejected" 
            : type === "internship" ? "Rejected" 
            :type === "job" ? "Rejected" :  ""
           }
          </span>
        )}

        {status === 9 && (
          <span
            className={`px-3 py-2`}
            style={{
              fontSize: "12px",
              background: `${type === "project" ? "#FF3737" : "#FF3737"}`,
              color: "#FFFFFF",
              fontWeight: "600",
              borderRadius: "20px",
            }}
          >
           { type === "project"?  "Not Enrolled" 
            : type === "internship" ? "Not Enrolled" 
            :type === "job" ? "Not Enrolled" :  ""
           }
          </span>
        )}

      </div>
    );
  };
  
  export default StatusButton;
  