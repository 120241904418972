import { downloadCSV } from "components/dataTable/Export";
import StatusButton from "components/employer/StatusButton";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminDashCard from "../../tables/table-card";
import Datatable from "components/datatable-new/DatatableNew";
import "../../tables/style.scss";

import moment from "moment-timezone";
import MobileViewEvaluationsRecievedTable from "./MobileViewEvaluationsRecieved";
const EvaluationsRecieved = ({ data }) => {
  const [search, setSearch] = useState(() => "");
  const cardsData = [
    {
      primaryText: "Sessions Mentored",
      secondaryText: "+50 this month",
      value: "128",
    },
   
  ];

  // code for chart/graph
 const chartData = {
  
  labels: cardsData?.map((item)=> {
   return item.day
  }),// X-axis labels
  // labels: data.map((item, index) => index +1), // X-axis labels
  datasets: [
    {
      data: cardsData?.map((item)=>{return item.total}), // Y-axis values
      // data: data.map((item) => item.value), // Y-axis values
      borderColor: '#6149CD',
      backgroundColor: '#6149CD',
    },]
};


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display:false // Change 'linear' to 'bottom' or 'top'
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: '', // X-axis title
      },
    },
    y: {
      title: {
        display: true,
        text: '', // Y-axis title
      },    },
  },
};


  const columns = [
    {
      Header: "Date of Submission",
      accessor: "date_of_submission",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.date_of_submission}
          </strong>
        );
      },
    },
    {
      Header: "Student Name",
      accessor: "student_name",
      width: "17.5%",
      textalign: "start",
    },
    {
      Header: "Project Name",
      accessor: "project_name",
      width: "22.5%",
      textalign: "start",
    },
    {
      Header: "Date of Request",
      accessor: "date_of_request",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.date_of_request}
          </strong>
        );
      },
    },
    {
      Header: "Subscription Plan",
      accessor: "subscription_plan",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.subscription_plan}
          </strong>
        );
      },
    },
    {
      Header: "Mentor Name",
      accessor: "mentor_name",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data?.row.original.mentor_name}
          </strong>
        );
      },
    },
    {
      Header: "Action",
      accessor: "actions",
      width: "15%",
      textalign: "center",
      Cell: (data) => {
        return (
          <Link
            to={
              data?.row.original.actions === "Assign" &&
              `mentors/${data.row.original.id}`
            }
            className={`deactivate-btn btn btn-primary text-white py-1 ${
              data?.row.original.actions === "Assigned" ? "disabled" : ""
            }`}
            style={{
              fontWeight: "500",
              fontSize: "13px",
            }}
          >
            {data?.row.original.actions}
          </Link>
        );
      },
    },
  ];
  // console.log(data, "hdh");
  const rows = data
    ?.filter((post) => {
      return post?.student?.first_name?.includes(search?.toLowerCase()) || 
      post?.student?.last_name?.includes(search?.toLowerCase()) ||
      post?.enrollment?.project?.project_title.includes(search?.toLowerCase()) ||
      post?.mentor?.first_name?.includes(search?.toLowerCase()) 
      ;
    })
    ?.map((post) => {
      // console.log(post, "post");
      return {
        id: post?.id,
        date_of_submission: moment(post?.createdAt)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
        student_name:
          post?.student?.first_name + " " + post?.student?.last_name,
        project_name: post?.enrollment?.project?.project_title,
        date_of_request: moment(post?.requested_date)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY"),
          subscription_plan: post?.subscription?.subscription_database?.subscription_name || "-",
        actions: post?.mentor_id ? "Assigned" : "Assign",
        mentor_name: post?.mentor
          ? post?.mentor?.first_name + " " + post?.mentor?.last_name
          : "Not Assigned",
      };
    });

  return (
    <div className="admin-dash-companies p-2">
      {/* <div className="row ">
        {cardsData?.map((card, index) => {
          return (
            <div className="col-12 col-md-3" key={index}>
              <AdminDashCard
                img={card.imgUrl}
                data={chartData}
                option={options}

                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                value={card.value}
              />
            </div>
          );
        })}
      </div> */}

      <div className="datatable-container">
        <div className="table_head flex">
          <div className="mobo_upload p-1">
            <h3 className="">
              Project Evaluations <br />{" "}
              <span className="light">{data?.length || 0} records</span>
            </h3>

            <Link
              to="/employer/internship/add"
              className="upload_btn_icon_mobo btn btn-primary"
            >
              <i
                className="fa-solid fa-cloud-arrow-down post-icons"
                onClick={() => downloadCSV(rows)}
              ></i>
            </Link>
          </div>

          <div className="add_icon non">
            <Link
              to="/employer/internship/add"
              className="upload_btn_icon btn btn-primary text-white px-3"
            >
              Add <i className="fa-solid fa-plus"></i>
            </Link>
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>

        {/* <Datatable columns={columns} data={rows} /> */}

        <div className="d-none d-sm-block table-responsive">
        <Datatable columns={columns} data={rows} />
          </div>
      <div className="d-block d-sm-none">
       <MobileViewEvaluationsRecievedTable columns={columns} data={rows}/>
      </div>


        {/* <InternshipDataTable data={ rows} columns={columns} /> */}
        {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
      </div>
    </div>
  );
};

export default EvaluationsRecieved;
