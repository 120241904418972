import React from "react"

const TextArea = ({
  label,
  labelClass = "",
  className = "",
  id,
  rows = 4,
  cols = 3,
  register = () => {},
  required = false,
  options = {},
  placeholder,
  secondaryLabel = false,
  handleChange = () => {},
  defaultValue,
  className1,
}) => {
  return (
    <div className="desc form-group my-4">
      <label htmlFor={id} className={`text_label ${labelClass}`}>
        {label}
        {required ? <span className={`${className1}`}>*</span> : <></>}{" "}
        {secondaryLabel ? <small>{secondaryLabel}</small> : <></>}
      </label>
      <textarea
        className={`form-control mt-2  ${className}`}
        id={id}
        name={id}
        rows={rows}
        required={required}
        {...register(id, { required, ...options })}
        placeholder={placeholder}
        cols={cols}
        onChange={handleChange}
        defaultValue={defaultValue}
      ></textarea>
    </div>
  )
}

export default TextArea
