import axios from "axios";
import Accordian from "components/accordian";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "services/actions/auth";
import Experience from "./Experience";
import PersonalDetails from "./PersonalDetails";
import Project from "./Projects";
import "./studentProfile.scss";
import AddCourse from "./AddCourse";
import { lang } from "moment";
import EducationDetails from "./EducationDetails";

const Submit = ({ onClick = () => {} }) => {
  return (
    <button
      className="save_changes_btn btn btn-primary mx-4 text-white "
      type="submit"
      onClick={(e) => {
        e?.stopPropagation();
        onClick();
      }}
    >
      Save Changes
    </button>
  );
};

const StudentProfile = () => {
  const [image, setImage] = useState(() => null);
  const user = useSelector((state) => state?.auth?.user);
  const [file, setFile] = useState(null);

  // console.log("userrrrrrrr", user);
  const navigate = useNavigate();

  const [studentProfile, setStudentProfile] = useState();
  const fetchStudent = async () => {
    try {
      const { data: reqData } = await axios.get(
        `/v1/auth/session/getSessionUser`,
        {
          validateStatus: () => true,
        }
      );
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      setStudentProfile(data);
      reset(data);
      return data;
    } catch (error) {}
  };
  useEffect(() => {
    fetchStudent();
  }, []);

  // console.log(studentProfile, "studentProfile123");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    errors,
  } = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      languages: user?.languages,
      email_address: user?.email_address,
      bio: user?.bio,
      phone_number: "",
      course_name: user?.course_name,
      branch_name: user?.branch_name,
      course_start_year: user?.course_start_year,
      course_end_year: user?.course_end_year,
      user_gender: user?.user_gender,

      location: user?.location,
      Institution_name: user?.Institution_name,
      user_skills: [],
      linkedIn_link: user?.linkedIn_link,
      github_link: user?.github_link,
      profile_link: user?.profile_link,
      profile_photo: user?.profile_photo,
      date_of_birth: user?.date_of_birth,
      student_resume: user?.student_resume || "",
    },
  });


  const [education, setEducation] = useState(() => user?.education || [])
  const [experiences, setExperiences] = useState(() => user?.experiences || []);
  const [documents, setDocuments] = useState(() => user?.documents || []);
  const [projects, setProjects] = useState(() => user?.student_projects || []);
  const [languages, setLanguages] = useState(() => user?.languages || []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // console.log("education....",education)
  // console.log("experiences....",experiences)
  // console.log("studentProfile123", getValues());
  // console.log("student-resume", file);



  const onSubmit = async (image, file) => {
    try {
      setLoading(true);
      const data = getValues();

      // console.log(data, "data123");

      if (!data?.first_name) return toast.error("First name is required");
      if (!data?.last_name) return toast.error("Last name is required");
      if (!data?.email_address) return toast.error("Email is required");
      if (!data?.phone_number) return toast.error("Phone number is required");
      // if (!data?.date_of_birth) return toast.error("Date of birth is required");

      // if(data?.bio.length > 1000) return toast.error("Bio must be in 1000 characters");

      // gender go in the data base string 0 and 1 and comes from the database like interger 1 and 0

      // if(user?.gender === 0 && )
      // console.log("locaaaa", data?.location, user?.location)
      // if (!data.location && !user?.location) return toast.error("Location is required");
      // if (!data?.languages?.length) return toast.error("Languages is required");

      // if (!data.branch_name && !user.branch_name)
      //   return toast.error("Branch name is required");
      if (!data?.course_name && !user?.course_name)
        return toast.error("Course name is required");
      // if (!data?.course_start_year)
      //   return toast.error("Course start year is required");
      if (!data?.course_end_year)
        return toast.error("Year of course completion is required");
      // if (!data?.institution_name)
      //   return toast.error("Institution name is required");

      // if (languages?.length === 0) {
      //   return toast.error("Languages is required");
      // }

      // if (languages?.length > 0) {
      //   data.languages = languages;
      // }
      // console.log("dataaaaa", data);
      if (data?.course_name || user?.course_name) {
        data.course_name = data?.course_name?.course_name || user?.course_name;
      }

      // if (data.branch_name || user.branch_name) {
      //   data.branch_name = data.branch_name.branch_name || user.branch_name;
      // }

      // if (languages?.length === 0 && !user?.languages)
      //   return toast.error("Languages is required");

      // // console.log(data, "data123");
      if (image && image?.size > 1000000) {
        return toast.error("Image size should be less than 1MB");
      }

      const payload = {};

      if (file) {
        const formData = new FormData();
        formData.append("student_resume", file);
        const { data: fileData } = await axios.post(
          "/v1/student/profile/resume",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // // console.log(fileData?.data, "fileData123");
        if (!fileData?.data) return toast.error("File upload failed");
        payload.student_resume = fileData?.data || "";
      }

      if (image) {
        const formData = new FormData();
        formData.append("profile_photo", image);
        formData.append("previous_photo", user.profile_photo);

        const { data: imageData } = await axios.post(
          "/v1/student/profile/image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!imageData?.data?.url) return toast.error("Image upload failed");
        payload.profile_photo = imageData?.data?.url || "";
      }

      data.experiences = experiences?.filter((i) => !i?.id || []) ;
      data.projects = projects?.filter((i) => !i?.id || []);
      data.documents = documents?.filter((i) => !i?.id || []);
      data.education= education?.filter((i) => !i?.id || [])
      
    // console.log("data.education",data.education)

      data.profile_photo = payload.profile_photo;
      data.student_resume = payload.student_resume;

      if (!data.profile_photo && !studentProfile.profile_photo)
        return toast.error("Profile photo is required");
      if (!data?.student_resume && !studentProfile?.student_resume)
        return toast.error("Resume is required");

      // console.log(data, "hello world");
      // console.log("experience222222",data.experiences)
      // console.log("experience222222",data.education)

      // console.log(data, "data123");
      const { data: profileData } = await axios.post(
        "/v1/student/profile",
        data
      );
      // console.log("profileData",profileData.data)

      if (profileData?.data) {
        payload.experiences = profileData?.data?.experience || [];
        payload.projects = profileData?.data?.project || [];
        payload.documents = profileData?.data?.document || [];
        payload.education = profileData?.data?.education || [];


        toast.success("Profile updated successfully");
        fetchStudent().then((response) => {
          dispatch(updateUser(response));
        });
        navigate("/student/dashboard");
      } else {
        toast.error("Profile update failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="student-profile">
        {/*<NewAccordian
        title="PERSONAL DETAILS"
        defaultOpen={true}
        Component={<Submit />}
        className="card"
      >
        <PersonalDetails
          register={register}
          image={image}
          setImage={setImage}
          setValue={setValue}
        />
       </NewAccordian>*/}
        <Accordian
          title="PERSONAL DETAILS"
          defaultOpen={true}
          Component={<Submit onClick={() => onSubmit(image, file)} />}
          className="card container"
        >
          <PersonalDetails
            register={register}
            image={image}
            setImage={setImage}
            setValue={setValue}
            getValues={getValues}
            setFile={setFile}
            file={file}
            studentProfile={studentProfile}
            languages={languages}
            setLanguages={setLanguages}
          />
        </Accordian>
      </div>
      {/* <Accordian title="DOCUMENTS" className="card">
        {/* <NewDocuments documents={documents} setDocuments={setDocuments}/>*/}
      {/* <Documents documents={documents} setDocuments={setDocuments} /> */}
      {/* </Accordian>  */}

      <Accordian title="EDUCATION DETAILS" className="card card2 container">

            <EducationDetails education={education} setEducation={setEducation} /> 
        
      </Accordian>

      <Accordian title="EXPERIENCE" className="card card2 container">
        <Experience experiences={experiences} setExperiences={setExperiences} />
      </Accordian>

      <Accordian title="PROJECTS" className="card card2 container">
        <Project projects={projects} setProjects={setProjects} />
      </Accordian>
      {loading && (
        <div className="loading-container">
          <div className="text-center loading-wrapper">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentProfile;
