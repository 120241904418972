import { Link, useParams } from "react-router-dom";
// import StatusButton from "components/employer/StatusButton";
import axios from "axios";
import { useEffect, useState } from "react";
// import Datatable from "components/datatable-new/DatatableNew";
import Datatable from "../../../../components/datatable-new/DatatableNew";
// import DeactivatePost from "./DeactivatePost";
// import MobileDataTable from "components/datatable-new/MobileDataTable";
// import MobileDataTable from "../../../../components/datatable-new/MobileDataTable";

import moment from "moment";
import MobileDataTable from "./MobileDataTable";
import StatusButton from "./StatusButton";

const JobTable = ({
  // data,
  isPopupOpen,
  setIsPopupOpen,
  setType,
  setRefreshTable,
  refreshTable,
}) => {

    // // console.log("Job table", data)

    const {id} = useParams();

    // console.log("jtable id", id)
    
    const [jobs, setJobs] = useState([]);
    
    useEffect(()=> {
      const JobDetails = async () =>{
        const job = await axios.get(`/v1/educator/studentJobs/${id}`)
    
        // console.log("jtable job", job)
        setJobs(job?.data?.data)
      }
      JobDetails();
    },[]);
    
    // console.log("jtable j", jobs)

    const columns = [
        {
          Header: "Date Assigned",
          accessor: "date",
          width: "15%",
          textalign: "start",
          Cell: (data) => {
            // console.log("date", data)
            return (
              <strong style={{ fontWeight: "700" }}>
                {data.row.original.date}
              </strong>
            );
          },
        },
        {
          Header: "Assigned Opportunity",
          accessor: "opportunity",
          width: "17%",
          textalign: "start",
        },
        {
          Header: "Company Name",
          accessor: "company",
          width: "13.5%",
          textalign: "center",
          Cell: (data) => {
            return (
              <strong style={{ fontWeight: "700" }}>
                {data.row.original.company}
              </strong>
            ); 
          },
        },
        {
          Header: "Employer Name",
          accessor: "employer",
          width: "13.5%",
          textalign: "center",
          Cell: (data) => {
            return (
              <strong style={{ fontWeight: "700" }}>
                {data.row.original.employer}
              </strong>
            );
          },
        },
        // {
        //   Header: "Progress Status",
        //   accessor: "progress",
        //   width: "13.5%",
        //   textalign: "center",
        //   Cell: (data) => {
        //     return (
        //       <strong style={{ fontWeight: "700" }}>
        //         {data.row.original.progress}
        //       </strong>
        //     );
        //   },
        // },
        
        // {
        //   Header: "Send Invite",
        //   accessor: "invites",
        //   width: "13.5%",
        //   textalign: "center",
        //   Cell: (data) => {
        //     return (
        //       <strong style={{ fontWeight: "700" }}>
        //         {data.row.original.student_invites}
        //         {data.row.original.educator_invites}
        //       </strong>
        //     );
        //   },
        // },
    
        // /employer/project/students/invite/5
        {
          Header: "Status",
          accessor: "status",
          width: "13.5%",
          textalign: "center",
          Cell: (data) => {
            return <strong>{data.row.original.status}</strong>;
          },
        },
        // {
        //   Header: " ",
        //   width: "1%",
        //   sortable: false,
        //   Cell: (data) => {
        //     return (
        //       <DeactivatePost
        //         status={data.row.original.statusCode}
        //         projectid={data.row.original.id}
        //         method={"project"}
        //         closePost={() =>
        //           closePost(data.row.original.id, data.row.original.statusCode)
        //         }
        //         // activatePost={() =>
        //         //   activatePost(data.row.original.id, data.row.original.statusCode)
        //         // }
        //       />
        //     );
        //   },
        // },
      ];

  // const map = {
  //   1: "Draft",
  //   2: "Active",
  //   3: "Closed",
  // };

  // const filteredData = jobs.map(item => {
  //   const jobId = item.job_id;

  //   // Filter project_enrollments to keep only those with matching project_id
  //   const filteredEnrollments = item?.educator_students_job?.student?.job_enrollments.filter(enrollment => {
  //       return enrollment?.job_id === jobId;
  //   });

  //   // Create a new object with the filtered project_enrollments
  //   return {
  //       ...item,
  //       educator_students_job: {
  //           ...item?.educator_students_job,
  //           student: {
  //               ...item?.educator_students_job?.student,
  //               job_enrollments: filteredEnrollments,
  //           },
  //       },
  //   };
  // });


  const rows = jobs
    ?.map((post) => {
      // const jobEnrollments = post?.educator_students_job?.student?.job_enrollments?.length > 0 ? post?.educator_students_job?.student?.job_enrollments[0] : {};

      const jobEnrollment = post?.educator_students_job?.student?.job_enrollments?.find(
        enrollment => enrollment.job_id === post?.job_id
      );

      return {
        id: post?.id,
        date: moment(post?.createdAt).format("DD/MM/YYYY"),
        opportunity: (
          <Link to={`/employer/internship/${post?.id}`}
            key={post?.job?.job_title || "No title"}
          >
            {/* {" "} */}
            {post?.job?.job_title || "No title"}
          </Link>
        ),
        company:  post?.job?.global_job?.employer?.company?.company_name || "",
        employer: post?.job?.global_job?.employer?.first_name + " " + post?.job?.global_job?.employer?.last_name || "",
        status: (
            <StatusButton
              status={jobEnrollment?.status ?? 9  }
              type={"job"}
             />
           )     ,
      };
    });


  return (
    <div className="internshipTable_main">
      {/* <div className="table_head flex">

      </div> */}
      <div className="d-none d-sm-block">
        <Datatable columns={columns} data={rows} />
      </div>
      <div className="d-block d-sm-none">
        <MobileDataTable columns={columns} data={rows} type={"job"} />
      </div>
      {/* <JobDataTable data={rows} columns={columns} /> */}
      {/* <DataTable
        columns={columns}
        data={rows}
        pagination
        className="table-responsive"
      /> */}
    </div>
  );
};

export default JobTable;
