import Chips from "./Chips";
import { useRef } from "react";
import "./popup.scss";
import { toast } from "react-toastify";

const ChipsField = ({
  label = "",
  id = "",
  placeholder = "",
  required = false,
  register,
  options = {},
  disabled = false,
  items = [],
  setItems = [],
  deleteItem,
  secondaryLabel = "",
  setValues = () => {},
}) => {
  const ref = useRef();

  const updateItems = (inputValue) => {
    try {
      const inputs = inputValue.split(/[,]+/);
      if (inputs.length + items.length > 5)
        throw new Error("Maximum 5 items allowed");
      inputs.forEach((input) => {
        // if (items.some((item) => item === input))
        //   throw new Error("Item already exists");
        if (items.some((item) => item === input)) {
          deleteItem(input, id);
        }
      });
      setItems((items) => [...items, ...inputs]);
      setValues(id, [...items, ...inputs]);
    } catch (error) {
      toast.error(error?.message || error, { toastId: "updateItems" });
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = ref.current.value;
      if (!value) return;
      updateItems(value);
      ref.current.value = "";
    } else {
      return;
    }
  };

  return (
    <div
      className="col-12 place_container"
      onKeyDown={handleKey}
      onBlur={(e) => {
        const value = e.target.value;
        if (!value) return;
        updateItems(value);
        ref.current.value = "";
      }}
    >
      <div className="form-group mb-3">
        <label htmlFor={id}>
          {label}
          {required && <span>*</span>}
          {secondaryLabel && (
            <small className="form-text text-muted">{secondaryLabel}</small>
          )}
        </label>
        {items?.length ? (
          <div className="col-12">
            <div className="row">
              <Chips items={items} deleteItem={deleteItem} id={id} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="item-container">
          <input
            className="form-control mt-2"
            type="text"
            name={id}
            id={id}
            placeholder={placeholder}
            {...register(id, { required, ...options })}
            ref={ref}
            autoComplete={"off"}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ChipsField;
