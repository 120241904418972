import React from "react";
import TopNavBar from "./components/TopNavbar/TopNavBar";
import NavBar from "./components/navbar/NavBar";
import HeroSection from "./components/heroSection/HeroSection";
import StartJourney from "./components/Journey/StartJourney";
import PartnerSection from "./components/partners/partnerSection";
import TestimonialSlider from "./components/testimonial/testimonialSlider";
import Footer from "view/dashboard/student/footer/Footer";
import collegeHero from "../../images/collegeHeroInhanced.png";
// import collegeHero from "../../images/collegeHero.png";
import collegeJimg from "../../images/collegeJimg.png";
import GoToTop from "GoToTop";
import img1 from "./images/first.png";
import img2 from "./images/second.png";
import img3 from "./images/3.png";
import { Helmet } from "react-helmet";
import CollegePartnerSection from "./components/partners/collegePartner";

const CollegePage = () => {
  return (
    <section className="home_page row justify-content-center align-items-center">
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="College project partnerships through Qollabb helps students to sharpen their skills and gain hands-on experience in the field of their choice."
        />
        <title>
          College project partnerships | Student project experiences | Student
          employability projects
        </title>
        <link rel="canonical" href="https://qollabb.com/college-page" />
      </Helmet>
      <TopNavBar />
      <NavBar />
      <HeroSection
        topText="Discover excellence"
        cardText="Easy Collaboration With Companies"
        buttonText="Get Started"
        hero={collegeHero}
        title="Transform your students' learning experience with real "
        title2="Projects"
        semiTitle="Our platform connects you with top companies and organizations across various industries, giving your students the opportunity to work on real-world projects, gain valuable experience, and build their network. "
      />
      <StartJourney
        Simg={collegeJimg}
        title1="01. Create a Profile"
        subTitle1="Create your free account with Google, Facebook, Linkedin or simply create a Qollabb account using your email ID."
        title2="02. Connect Your Students With Real Opportunities"
        subTitle2="Assign industry project work, internships & jobs to your students and keep a track of their progress."
        subTitle3="Explore collaboration possibilities with industry directly assign industry projects to your students or faculty & build your industry network. "
        title3="03. Build Collaborations With Companies"
      />
      {/* <PartnerSection /> */}
      <CollegePartnerSection/>
      <TestimonialSlider
        title="What Educators"
        img1={img1}
        name1="Kunwar Shekhar Vijendra"
        designation1="Chancellor, Shobhit University"
        title1="Easy Collaboration With Companies !"
        subTitle1="Qollabb has transformed the way we teach our students. By connecting them with real industry projects, they are able to apply their learning to real-world scenarios and gain valuable experience. The platform is easy to use and provides an opportunity for our students to network with potential employers. We have seen a significant improvement in our students' career readiness since incorporating Qollabb into our curriculum."
        img2={img2}
        name2="Dr Jayant Sonwalkar"
        designation2="Former VC, MP Bhoj Open University"
        title2="Excellent Real World Projects !"
        subTitle2="Qollabb is an excellent tool for educators who want to provide their students with hands-on experience in the workforce. The platform is intuitive and easy to navigate, and it has helped us to forge connections with local businesses and non-profit organizations. By collaborating on real-world projects, our students have developed essential skills such as problem-solving, critical thinking, and communication."
        img3={img3}
        name3="Dr Safia Farooqi"
        designation3="Director, COL, Dr D Y Patil Vidyapeeth"
        title3="Game Changer !"
        subTitle3="Qollabb has been a game-changer for our classroom. Our students have been able to apply their theoretical knowledge to practical projects, which has helped them to become more engaged and motivated in their learning. Additionally, Qollabb has helped to bridge the gap between academia and industry, giving our students a clear path to career success."
      />
      <Footer />
      <GoToTop />
    </section>
  );
};

export default CollegePage;
