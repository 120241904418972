import TextArea from "components/formComponents/TextArea";
import React from "react";
import { useForm } from "react-hook-form";

const Screening = ({ screening = [], onApply }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    const screening_questions = JSON.stringify(Object.values(data));
    onApply(screening_questions);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {screening?.map((question, index) => (
        <div key={index}>
          <TextArea
            register={register}
            label={question}
            id={`question${index}`}
          />
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};

export default Screening;
