import React, { useEffect, useState } from "react"
import "./StudentPopup.scss"

import { toast } from "react-toastify"
import axios from "axios"
import { RxCross2 } from "react-icons/rx"
import Loader from "components/employer/Loader"

import { useSelector } from "react-redux"

const StudentPopup = ({ closeModal, enrollType, enrollId }) => {

    const [details,setDetails]=useState([])
    const [loading, setLoading] = useState(false);



    const getDetails = async () => {
        try {
          if (enrollType && enrollId) {
            setLoading(true)
            const { data } = await axios.get(`/v1/student/enroll-student-details/${enrollType}/${enrollId}`);
            if (data.error) {
           console.log("error",data?.error)
            } else {
              setDetails(data.data);
              setLoading(false)
            }
          } else {
            console.log("Invalid enroll type");
          }
        } catch (error) {
          console.error("Error fetching details:", error);
        } finally {
          setLoading(false);
        }
      };

      useEffect(()=>{
        getDetails()

      },[enrollType,enrollId])
      
// console.log("details",details)


const renderDetails = () => {
    return details?.map((detail, index) => {
      const commonFields = {
        title: '',
        companyName: '',
        appliedDate: new Date(detail.createdAt).toLocaleDateString()
      };

      if (enrollType === 'Projects') {
        commonFields.title = detail?.project?.project_title;
        commonFields.companyName = detail?.project?.project_global_fkey?.employer_account?.company?.company_name||detail?.project?.project_global_fkey?.employer_account?.company?.company_na;
      } else if (enrollType === 'Internships') {
        commonFields.title = detail?.internship?.internship_title;
        commonFields.companyName = detail?.internship.global.employer_account?.company?.company_name;
      } else if (enrollType === 'Jobs') {
        commonFields.title = detail?.job?.job_title;
        commonFields.companyName = detail?.job.global_job?.employer_account?.company?.company_name;
      }

      return (
        <div key={index} className="row mb-3">
        <div className="col-6">
          <h3>{commonFields.title}</h3>
        </div>
        <div className="col-4">
          <h3>{commonFields.companyName}</h3>
        </div>
        <div className="col-2">
          <h3>{commonFields.appliedDate}</h3>
        </div>
      </div>
      );

    
    })
}


  return (
    <div className="student_popup">
      <div className="student_body">
        <span className="cross" onClick={closeModal}>
          <RxCross2 size={20} />
        </span>

        <div className="">
          <h2> Applied {enrollType}</h2>
        </div>

             <div className="row mb-3 label">
              <div className="col-6">
                <label className="mb-2">Title</label>
              </div>
              <div className="col-4">
                <label className="mb-2">Company Name</label>
              </div>
              <div className="col-2">
                <label className="mb-2">Applied Date</label>
              </div>
            </div>

        <div className="student_header">
       
           
            {
              loading?
              <Loader/>:
            renderDetails()
            
            }
        
      </div>
     
      </div>
    </div>
  )
}

export default StudentPopup
