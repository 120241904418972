import Accordian from "components/accordian";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./eduactorprofile.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "services/actions/auth";
import EducatorPersonalDetails from "./EducatorPersonalDetails";

const Submit = () => {
  return (
    <button
      className="save_changes_btn btn btn-primary mx-4 text-white "
      type="submit"
      onClick={(e) => e?.stopPropagation()}
    >
      Save Changes
    </button>
  );
};

const EducatorProfile = () => {
  const [image, setImage] = useState(() => null);
  const user = useSelector((state) => state.auth.user);
  // // console.log(user?.bio);

  const [eduactorProfile, setEduactorProfile] = useState();
  const fetchStudent = async () => {
    try {
      const { data: reqData } = await axios.get(
        `/v1/auth/session/getSessionUser`,
        {
          validateStatus: () => true,
        }
      );
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      setEduactorProfile(data);
      reset(data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchStudent();
  }, []);
  // // console.log("eduactorProfile", eduactorProfile);
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      first_name: eduactorProfile?.first_name,
      last_name: user?.last_name,
      languages: eduactorProfile?.languages || [],
      email_address: user?.email_address,
      bio: eduactorProfile?.bio || user?.bio,
      phone_number: user?.phone_number,
      course_name: "",
      course_branch: "",
      course_start_year: "",
      course_end_year: "",
      user_gender: "",
      location: user?.location,
      Institution_name: parseInt(user?.gender),
      user_skills: [],
      linkedIn_link: user?.linkedin_id,
      github_link: "",
      profile_link: "",
      progile_pic: user?.profile_pic,
      date_of_birth: "",
    },
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (image && image?.size > 1000000) {
      return toast.error("Image size should be less than 1MB");
    }

    const payload = {};
    if (image) {
      // console.log("hello");
      const formData = new FormData();
      formData.append("profile_photo", image);

      const { data: imageData } = await axios.post(
        "/v1/educator/profile/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!imageData?.data?.url) return toast.error("Image upload failed");
      payload.profile_pic = imageData?.data?.url || "";
      // console.log(imageData?.data?.url, "imageData?.data?.url");
    }

    // console.log(data, "dataed");
    data.profile_pic = payload.profile_pic;
    const { data: profileData } = await axios.post(
      "/v1/educator/profile",
      data
    );

    if (profileData?.data) {
      toast.success("Profile updated successfully");
      fetchStudent();
      dispatch(updateUser(payload));
    } else {
      toast.error("Profile update failed");
    }
  };

  return (
    <form className="student-profile mx-4" onSubmit={handleSubmit(onSubmit)}>
      {/*<NewAccordian
        title="PERSONAL DETAILS"
        defaultOpen={true}
        Component={<Submit />}
        className="card"
      >
        <PersonalDetails
          register={register}
          image={image}
          setImage={setImage}
          setValue={setValue}
        />
  </NewAccordian>*/}
      <Accordian
        title="PERSONAL DETAILS"
        defaultOpen={true}
        Component={<Submit />}
        className="card"
      >
        <EducatorPersonalDetails
          register={register}
          image={image}
          setImage={setImage}
          setValue={setValue}
          // setCompanyImage={setCompanyImage}
        />
      </Accordian>
    </form>
  );
};

export default EducatorProfile;
