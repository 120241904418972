import React from "react";
import "./cta_success.scss";

const CTASuccess = ({ onClick, value, className }) => {
    return (
        <button onClick={onClick} className={`${className} shortlist_btn`}>
            {value}
        </button>
    );
};

export default CTASuccess;
