import React from 'react'
import TopNavBar from './components/TopNavbar/TopNavBar'
import NavBar from './components/navbar/NavBar'
import Footer from 'view/dashboard/student/footer/Footer'
import GoToTop from 'GoToTop'
import MentorsPolicy from './Policies/MentorsPolicy'

const MentorPolicyPage = () => {
  return (
    <div className="home_page row justify-content-center align-items-center">
          <TopNavBar />
          <NavBar />
          <MentorsPolicy/>
          <Footer />
      <GoToTop />
    </div>
  )
}

export default MentorPolicyPage