import React, { useCallback, useEffect, useState } from 'react'
import "./projectSubmissions.scss";
import Datatable from 'components/datatable-new/DatatableNew';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ProgressStatus from 'view/dashboard/educator/components/ProgressStatus';
import { FaDownload } from "react-icons/fa6";
import { toast } from 'react-toastify';
import JSZip from 'jszip';
import {saveAs} from "file-saver";
import Loader from 'components/employer/Loader';
import { downloadCSV } from 'components/dataTable/Export';


/* 
    enroll Date, name, phone, email, lastLogin, type, project title, company, Subscription type, completion status, completion Date, project report, ppt
*/

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const ProjectSubmission = () => {


    const [enrollments, setEnrollments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    const getEnrollmets = useCallback(async() => {
      try {
        setLoading(true);
        const res = await axios.post("/v1/enrollments/project/get-all-submissions?search="+debouncedSearch);

        setEnrollments(res?.data?.data)
        setLoading(false)
      } catch (error) {
        console.log(error);
        setLoading(false)
      }


    },[debouncedSearch])

    useEffect(()=> {
        getEnrollmets();
    }, [debouncedSearch,getEnrollmets])

    console.log("enrolllllllll", enrollments);




    const columns = [
        {
          Header: "Enroll Date",
          accessor: "enroll_date",
          width: "12%",
          textalign: "start",
          Cell: (data) => {
            return (
              <strong style={{ fontWeight: "700" }}>
                {data?.row.original.enroll_date}
              </strong>
            );
          },
        },
        {
          Header: "Student",
          accessor: "student_name",
          width: "17.5%",
          textalign: "start",
          Cell: (data) => {
            return (
              <div className="">
                <h6
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="text-colored"
                >
                  {data.row.values.student_name}
                </h6>
                <p>{data.row.original.type}</p>
              </div>
            );
          },
        },
        // {
        //   Header: "Phone",
        //   accessor: "phone_no",
        //   width: "10%",
        //   textalign: "start",
        // },
        {
          Header: "Email/Phone",
          accessor: "email",
          width: "13%",
          textalign: "start",
          Cell: (data) => {
            return (
              <div className="">
                <strong style={{ fontWeight: "700" }}>
                  {data.row.values.email}
                  </strong>
                <p>{data.row.original.phone_no}</p>
              </div>
            );
          },
        },
        {
          Header: "Last Login",
          accessor: "lastLogin",
          width: "12%",
          textalign: "center",
          sortType: (rowA, rowB, columnId) => {
            const dateA = rowA?.original?.last_login
              ?.split("/")
              ?.reverse()
              ?.join("-");
            const dateB = rowB?.original?.last_login
              ?.split("/")
              ?.reverse()
              ?.join("-");
            // console.log(dateA, dateB, "dateA, dateB");
            if (dateA < dateB) {
              return -1;
            } else if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          },
          Cell: (data) => {
            return (
              <strong style={{ fontWeight: "700" }}>
                {data?.row.original.lastLogin}
                <strong className="d-block">{data.row.original.last_time}</strong>
              </strong>
            );
          },
        },
        // {
        //   Header: "Type",
        //   accessor: "type",
        //   width: "5%",
        //   textalign: "center",
        //   Cell: (data) => {
        //     return (
        //       <strong style={{ fontWeight: "700" }}>
        //         {data?.row.original.type}
        //       </strong>
        //     );
        //   },
        // },
        {
          Header: "Subscription",
          accessor: "subscription",
          width: "8%",
          textalign: "center",
          Cell: (data) => {
            return (
            //   <Link
            <div>
                {data?.row.original.subscription}
            </div>
            //   </Link>
            );
          },
        },
        {
          Header: "Project",
          accessor: "project_title",
          width: "20%",
          textalign: "center",
          Cell: (data) => {
            return (
            //   <Link
            <strong>
                {data?.row.original.project_title}
            </strong>
            //   </Link>
            );
          },
        },
        {
          Header: "Company",
          accessor: "company",
          width: "17%",
          textalign: "center",
          Cell: (data) => {
            return (
            //   <Link
            <strong>
                {data?.row.original.company}
            </strong>
            //   </Link>
            );
          },
        },
        {
          Header: "Status",
          accessor: "completition_status",
          width: "20%",
          textalign: "center",
          Cell: (data) => {
            return (
            //   <Link
            <div>
                {data?.row.original.completition_status}
            </div>
            //   </Link>
            );
          },
        },
        {
          Header: "Completition Date",
          accessor: "completion_date",
          width: "15%",
          textalign: "center",
          Cell: (data) => {
            return (
            //   <Link
            <strong>
                {data?.row.original.completion_date}
            </strong>
            //   </Link>
            );
          },
        },
        {
          Header: "Reports",
          accessor: "actions",
          width: "5%",
          textalign: "center",
          Cell: (data) => {

            const handleDownload = async (links) => {
              if (links?.length !== 0) {
                const zip = new JSZip();
                const folder = zip.folder("reports");
      
                for (const link of links) {
                  if (link) {
                    const filename = link.split('/').pop();
                    const response = await fetch(link);
                    const blob = await response.blob();
                    folder.file(filename, blob);
                  }
                }
      
                zip.generateAsync({ type: "blob" }).then((content) => {
                  saveAs(content, "reports.zip");
                });
      
              } else {
                toast.warning("Final reports not uploaded yet");
              }
            };
            return (
            //   <Link
            <div onClick={() => handleDownload(data.row.original.actions.links)} style={{ cursor: 'pointer' }}>
                {data?.row.original.actions?.icon}
                
            </div>
            //   </Link>
            );
          },
        },
      ];


    const rows = enrollments?.map((post) => {
          // console.log(post, "post");
          return {
            id: post?.id,
            enroll_date: moment(post?.createdAt).format("DD/MM/YYYY"),
            student_name: `${post?.s_first_name} ${post?.s_last_name}`,
            phone_no: post?.s_phone_number,
            email: post?.s_email_address,
            lastLogin: moment(post?.s_lastLogin).format("DD/MM/YYYY"),
            last_time: moment(post?.s_lastLogin).tz("Asia/Kolkata").format("hh:mm A"),
            type: post?.studentType,
            subscription: post?.subscription_name || "Beginner",
            project_title: post?.project_title,
            company: post?.company_name,
            completition_status: (
              <ProgressStatus
                status={post?.status ?? 0 }
                reports={JSON.parse(post?.weekly_submissions ?? "[]")}
                final_submission={post?.final_submission_report ?? null} 
               />
              
            ),
            completion_date: post?.final_submission_report ? moment(post?.final_submission_report?.final_submission_report_date).format("DD/MM/YYYY") : "N/A",
            actions: {
              icon: <FaDownload size={22} />,
              links: post?.final_submission_report !== null ? [
                post?.final_submission_report?.report,
                post?.final_submission_report?.presentation,
                post?.final_submission_report?.other
              ] : []
            }
            
          };

        });

        if(loading) return <Loader />

  return (
    <div className='submissions_wrapper'>
        <div className="submissions">

        <div className="datatable-container">
        <div className="table_head flex">
          <div className="mobo_upload p-1">
            <h3 className="">
              Project Submissions <br />{" "}
              <span className="light">{enrollments?.length || 0} records</span>
            </h3>
          </div>

          <div className="add_icon non">
            <button
              className="upload_btn_icon btn btn-primary"
              onClick={() => downloadCSV(rows)}
            >
              <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
            </button>

            <label className="search_label">
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </label>
          </div>
        </div>


        <div className="d-sm-block table-responsive">
          {
            (rows && columns) &&
            <Datatable columns={columns} data={rows} />

          }
          </div>
      <div className="d-block d-sm-none">
       {/* <MobileViewEvaluationsRecievedTable columns={columns} data={rows}/> */}
      </div>
      </div>

        </div>
    </div>
  )
}

export default ProjectSubmission;