import Loader from "components/employer/Loader";
import PreviewField from "components/employer/PreviewField";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./educatorPreview.scss";
import UserIcon from "./userIcon.svg";
// import CompanyIcon from "./companyIcon.svg"
import axios from "axios";
import { downloadCSV } from "components/dataTable/Export";
import ProfileIcon from "components/employer/ProfileIcon";
import { defaultValues as categories } from "components/formComponents/CategoryField";
import Papa from "papaparse";
import { toast } from "react-toastify";
import CreateListPopup from "../components/StudentListPopups/CreateListPopup";
import ManualUploadPopup from "../components/StudentListPopups/ManualUploadPopup";
import StudentListAccordian from "./StudentListAccordian";
import PostDetailsSection from "components/posts/PostsHelper/PostDetailsSection";

const ProjectDetailComponent = () => {
  // const location = useLocation()
  const { id = 0 } = useParams();
  // const id = location.pathname.split("/")[3]
  // const [tableRows, setTableRows] = useState({})
  // const [selected, setSelected] = useState([])

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState(() => "");
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // // console.log(values,id,"id")
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const[loading1, setLoading1]=useState(false)
    const [refresh, setRefresh] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    
    // const [data, setData] = useState([]);
  
  
    const closeModal = () => {
      setIsModalOpen(!isModalOpen);
      // handleRefreshFromStudentListTable();
    };

    const closeModal2 = () => {
      setIsModalOpen2(!isModalOpen2);
      // handleRefreshFromStudentListTable();
    };




  useEffect(() => {
    const getStudents = async () => {
      const res = await axios.get("/v1/student");
      setStudents(res?.data?.data);
    };
    getStudents();
  }, []);

  const tempData = [
    {
      student_name: "",
      course: "",
      specialization: "",
      semester: "",
      email_id: "",
    },
  ];

  const user = useSelector((state) => state.auth.user);
  const { loading, data, error } = useFetch(`/v1/project/${id}`);


  useEffect(() => {
    
    const fetchStudentsLists = async()=> {
      try {
        const res = await axios.get(
          "/v1/educator/invite/uploaded_students"
        );
        // console.log("ress", res?.data);

        setValues(res?.data?.data)
        
      } catch (error) {
        console.log("err", error);
      }
    }

    fetchStudentsLists();

  }, [refresh]);

  if (loading || !data) return <Loader />;
  const { error: err, data: projects } = data;

  if (err || error) return <h3>{err}</h3>;
  const project = data?.data;

  const changeHandler = (event) => {
    try {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const valuesArray = results.data.reduce(
            (accu, curr) => {
              // console.log(curr);
              if (!curr.email_id) {
                return { ...accu, skipped: accu.skipped + 1 };
              }
              if (
                !curr.email_id.match(
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                )
              ) {
                return { ...accu, invalid: accu.invalid + 1 };
              }
              if (values.some((x) => x["email_id"] === curr.email_id)) {
                return { ...accu, dublicate: accu.dublicate + 1 };
              }

              const {
                name = "",
                student_name = "",
                course = "",
                specialization = "",
                semester = null,
                email_id: email_address = null,
              } = curr;

              return {
                ...accu,
                data: [
                  ...accu.data,
                  [
                    name || student_name,
                    course,
                    specialization,
                    semester,
                    email_address,
                  ],
                ],
              };
            },
            {
              skipped: 0,
              invalid: 0,
              dublicate: 0,
              data: [],
            }
          );

          if (valuesArray.skipped) {
            toast.warning(`Skipped ${valuesArray.skipped} rows.`);
          }
          if (valuesArray.invalid) {
            toast.warning(
              `Skipped ${valuesArray.invalid} invalid emails. Please check the email format.`
            );
          }
          if (valuesArray.dublicate) {
            toast.warning(`Skipped ${valuesArray.dublicate} dublicate emails.`);
          }

          if (!valuesArray.data.length) return;

          const uploaded = await axios.post(
            "/v1/educator/invite/upload_students",
            {
              invitee_ids: valuesArray.data,
            }
          );

          if (uploaded.data.error) {
            toast.error(uploaded.data.message);
          } else {
            const added = uploaded.data.data;
            const newValues = added
              .map((id, index) => {
                if (id) {
                  // console.log(valuesArray.data[index]);
                  return [...valuesArray.data[index], true, id];
                } else return null;
              })
              .filter((x) => x);

            setValues([...values, ...newValues]);

            toast.success(uploaded.data.message);
          }
        },
      });
    } catch (err) {
      // console.log(err);
    } finally {
      event.target.value = null;
    }
  };


// console.log("selectedIds", selectedIds);



  const sendInvites = async () => {
    setShowModal(true);
    const selected = values.filter((x) => x[5]);
    const ids = selected.map((x) => x[6]);
    const { data } = await axios.post("/v1/educator/invite", {
      // invitee_ids: ids,
      invitee_ids: selectedIds,
      post_id: id,
      method: "project",
    });

    if (data.error) {
      toast.error(data.message);
      setShowModal(false);
    } else {
      toast.success(data.message);
      setShowModal(false);
    }
  };

  const cleanedString = project?.faq
    ?.replace(/^\[|]$/g, "")
    ?.replace(/\\/g, "");

  // Split the cleaned string at each comma to create an array
  const faqArray = cleanedString ? cleanedString.split(",") : [];

  const cleanedFaqArray = faqArray.map((item) => item.replace(/(^"|"$)/g, ""));

  const columns = [
    {
      Header: " ",
      width: "1%",
      sortable: false,
      Cell: (data) => {
        return (
          <div className="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
          </div>
        );
      },
    },

    {
      Header: "Student Name",
      accessor: "name",
      width: "15%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.name}
          </strong>
        );
      },
    },
    {
      Header: "Course",
      accessor: "course",
      width: "9%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "400" }}>
            {data.row.original.course}
          </strong>
        );
      },
    },
  ];

  const rows = students
    ?.filter((post) => {
      return post?.first_name?.toLowerCase()?.includes(search?.toLowerCase());
    })
    ?.map((post) => {
      return {
        id: post?.id,
        date: new Date(post?.createdAt).toLocaleDateString(),
        name: post?.first_name + " " + post?.last_name,
        course: "B.Tech",
        specialization: "Information Technology",
        semester: "8",
        email: post?.email_address,
        phone: post?.phone_number || "0123456789",
        opportunities: "5",
      };
    });
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  const category_name = categories?.find(
    (item) => item?.id === map[data?.data?.project_category]
  )?.label;

  // console.log(data, "hhhh");

  // console.log(project, "hello world");

  return (
    <>
      {showModal && (
        <div className="modal-send-invite">
          <div className="modal-send-body">
            <p>
              <strong>Sending Invites</strong>
            </p>
            <button className="bg-primary">
              <div className="spinner">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G...</span>
              </div>
            </button>
          </div>
        </div>
      )}
      <div className="preview_container">
        <div className="preview_box">
          <section>
          <PostDetailsSection data={project}  type={"project"} />
            
            <div className="">
              <div className="edu_btn">
                <button
                  className="btn download_btn"
                  onClick={()=>setIsModalOpen(!isModalOpen)}
                >
                  Create New List
                </button>
              </div>
            </div>

           
          </section>
          <section className="action_containers">
            <div className="actions_btn">
              <div className="right_btn">
                <button
                  className="btn download_btn"
                  onClick={()=>setIsModalOpen(!isModalOpen)}
                >
                  Create New List
                </button>


                {/* <label htmlFor="csv-upload" className="btn upload_btn">
                  Upload New List
                </label>
                <input
                  id="csv-upload"
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={changeHandler}
                  style={{ display: "none", margin: "10px auto" }}
                /> */}
              </div>
              <div className="send_oportunity flex w-100">
                <button
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: "18.8px",
                    fontWeight: "600",
                    height: "55.5px",
                    backgroundColor: "#47D065",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="btn send_btn"
                  onClick={sendInvites}
                >
                  Send Opportunity
                  <i style={{ color: "#ffffff" }} className="fa fa-send"></i>
                </button>
              </div>
            </div>
            {/* right side    */}

            {/* <div className="right_side_container">
              <div className="right_side_title">
                <h4>{values.filter((i) => i[5]).length} Selected</h4>
                <p>See All</p>
              </div>

              <div style={{ width: "100%" }}>
                <div className="row align-items-center">
                  <div className="col-1">
                    <div className="form-check d-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        defaultChecked={false}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const newValues = [...values];
                          newValues.forEach((value) => {
                            value[5] = checked;
                          });
                          setValues(newValues);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <strong>Student Name</strong>
                  </div>
                  <div className="col-5">
                    <strong>Course</strong>
                  </div>
                </div>
                <div>
                  {values.map((value, index) => {
                    const [
                      name,
                      course,
                      specialization,
                      semester,
                      _email_address,
                      selected,
                      id,
                    ] = value;
                    // console.log("stddd valus", value)
                    return (
                      <div className="row align-items-center" key={id}>
                        <div className="col-1">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              checked={selected}
                              onChange={(e) => {
                                // console.log(e.target.checked);
                                const newValues = [...values];
                                newValues[index][5] = e.target.checked;
                                setValues(newValues);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          {name || ""} {semester ? `(${semester})` : ""}
                        </div>
                        <div className="col-5">
                          {course || ""}{" "}
                          {specialization ? `(${specialization})` : ""}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          {
            values && 
            <div className="right_side_container">
              <h3>Student Lists</h3>
              {
                values?.map((item)=> (
                  <StudentListAccordian listName={item?.list_name} students={item?.educator_students} selectedIds={selectedIds} setSelectedIds={setSelectedIds}  />
                  
                ))
              }
            </div>
          }


          </section>
        </div>
      </div>


            {isModalOpen && (
                <CreateListPopup  onClose={closeModal} setIsModalOpen2={setIsModalOpen2}  setRefresh={setRefresh} setLoading={setLoading1} loading={loading1} />
              )}

              {isModalOpen2 && (
                <ManualUploadPopup  onClose={closeModal2} setRefresh={setRefresh} setLoading={setLoading1} loading={loading1} />
              )}

      
    </>
  );
};

export default ProjectDetailComponent;
