import React, { useRef, useState } from 'react'
import "./createListpopup.scss"
import { RxCross2 } from "react-icons/rx";
import { IoIosCreate } from "react-icons/io";
import { downloadCSV } from 'components/dataTable/Export';
import Papa from "papaparse";
import { toast } from "react-toastify";
import axios from 'axios';



const CreateListPopup = ({onClose, setRefresh, setLoading, setIsModalOpen2, loading, existsName = null}) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const inputRef = useRef(null); 
    const [listName, setListName] = useState(existsName ?? "My list");

    
    const [values, setValues] = useState([]);
    // const [refresh, setRefresh] = useState(false);
    
    const tempData = [
        {
          student_name: "",
          course: "",
          specialization: "",
          semester: "",
          email_id: "",
        },
      ];


    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    }


    const handleUpload = async ()=> {
        try {
          setLoading((prev)=> !prev);
            Papa.parse(selectedFile, {
              header: true,
              skipEmptyLines: true,
              complete: async function (results) {
                // console.log("resultttttttt", results);
                const valuesArray = results.data.reduce(
                  (accu, curr) => {
                    // console.log(curr);
                    if (curr.email_id) {
                      curr.email_id = curr.email_id.trim();
                    }

                    if (!curr.email_id) {
                      return { ...accu, skipped: accu.skipped + 1 };
                    }
                    if (
                      !curr.email_id.match(
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                      )
                    ) {
                      return { ...accu, invalid: accu.invalid + 1 };
                    }
                    if (values.some((x) => x["email_id"] === curr.email_id)) {
                      return { ...accu, dublicate: accu.dublicate + 1 };
                    }
      
                    const {
                      name = "",
                      student_name = "",
                      course = "",
                      specialization = "",
                      semester = null,
                      email_id: email_address = null,
                    } = curr;
      
                    return {
                      ...accu,
                      data: [
                        ...accu.data,
                        [
                          name || student_name,
                          course,
                          specialization,
                          semester,
                          email_address,
                        ],
                      ],
                    };
                  },
                  {
                    skipped: 0,
                    invalid: 0,
                    dublicate: 0,
                    data: [],
                  }
                );
      
                // console.log("studettttdataaa", valuesArray.data);
      
                if (valuesArray.skipped) {
                  toast.warning(`Skipped ${valuesArray?.skipped} rows.`);
                }
                if (valuesArray.invalid) {
                  toast.warning(
                    `Skipped ${valuesArray?.invalid} invalid emails. Please check the email format.`
                  );
                }
                if (valuesArray.dublicate) {
                  toast.warning(`Skipped ${valuesArray?.dublicate} dublicate emails.`);
                }
      
                if (!valuesArray.data.length) return;
      
               
                // console.log("vlaueseeeeeeeee", valuesArray.data, listName);
                const uploaded = await axios.post(
                  "/v1/educator/invite/upload_students",
                  {
                    invitee_ids: valuesArray.data,
                    list_name: listName
                  }
                );
      
                if (uploaded.data.error) {
                  toast.error(uploaded.data.message);
                  setLoading((prev)=> !prev);
                  setRefresh((prev)=> !prev);
                } else {
                  const added = uploaded.data.data;
                  const newValues = added
                    .map((id, index) => {
                      if (id) {
                        return [...valuesArray.data[index], true, id];
                      } else return null;
                    })
                    .filter((x) => x);
      
                  setValues([...values, ...newValues]);
                  setRefresh((prev)=> !prev);
                  onClose();
                  setLoading((prev)=> !prev)
      
                  toast.success(uploaded.data.message);
                }
              },
            });

            
          } catch (err) {
            console.log(err);
            setLoading((prev)=> !prev);
            setRefresh((prev)=> !prev)
          } 
    }

 
  return (

    <div className="create-list-popup-overlay">
    <div className="create-list-popup" >
        <div className="create_list_top d-flex justify-content-between align-items-center mb-4">
            <div className='d-flex justify-content-between align-items-center gap-2'>
                <IoIosCreate size={22} /> <span className='list_heading'>Create Student List</span>
            </div>

            <div className="" onClick={onClose}>
                <RxCross2 size={30} style={{cursor: "pointer"}} />
            </div>
        </div>


    <div className="popup_body">
      <div className="popup-header">
        <div className='list_name'>
            <label htmlFor="">List Name</label>

            <input
                placeholder="Enter list name"
                className="popup-input"
                type="text"
                value={listName}
                onChange={(e)=> setListName(e.target.value)}
            />
        </div>

      </div>

      <div className="popup-upload">

        <div className="upload_header mb-3">
            <div className="upload_label ">Upload CSV</div>
            <button className="popup-download-btn" onClick={() => downloadCSV(tempData)}>Download Template</button>
        </div>

        <div className="upload_section rounded  text-center text-gray-500 my-2"  onClick={() => inputRef.current.click()}>
          {/* Upload CSV file */}

          {selectedFile ? selectedFile.name : 'Upload CSV file'}
              <input
                type="file"
                id="fileInput"
                ref={(el) => (inputRef.current = el)}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
        </div>
        <button className="upload_btn" onClick={handleUpload}>{loading ? <i className="text-white fa fa-circle-o-notch fa-spin" ></i> : "Upload List"}  </button>
      </div>


      <div className="popup-divider">
        <div className="ruler"></div>
        <p>OR</p>
        <div className="ruler"></div>
      </div>

      <div className='action_btn_section'>
        <button className="popup-action-btn" onClick={()=>{ setIsModalOpen2((prev)=> !prev ); onClose();}}>
            Add students individually
        </button>
      </div>

    </div>
    </div>
    </div>

  )
}

export default CreateListPopup