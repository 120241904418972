import React from "react"

import {Line} from "react-chartjs-2"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);








const AdminDashCard = ({ data ,option, primaryText, secondaryText, value ,b2bstudents, b2bvalue}) => {
  
  // console.log("")

  return (
    <div className="card p-3 cols-2" style={{ border: "none" }}>
      {/* <img
        className="mt-4"
        src={img !== "" ? img : Image}
        alt=""
        height="250px"
      /> */}
      
      <Line  className="" data={data} options={option} />
      

      <div className="d-flex align-items-center justify-content-between mt-4">
        <div>
          <h6
            className="my-0"
            style={{
              fontWeight: "600",
             
            }}
          >
            {primaryText}
          </h6>
          <h6
            className="my-0"
            style={{
              fontWeight: "600",
              color:"#FF6384"
             
            }}
          >
            {b2bstudents?b2bstudents:''}

          </h6>       
          <p
            className="my-0"
            style={{
              fontSize: "10.5px"


            }}
          >
            {secondaryText}
          </p>
        </div>
        <div>
          <h6
            className="my-0"
            style={{
              fontWeight: "600",
            }}
          >
            {value}
          </h6>
          <h6
            className="my-0"
            style={{
              fontWeight: "600",
              color:"#FF6384"
            }}
          >
            {b2bvalue?b2bvalue:''}
          </h6>



        </div>
      </div>
    </div>
  )
}

export default AdminDashCard
