import React from 'react';
import './Ploicy.scss'

const TermsAndConditions = () => {
  return (
    <div className="container my-5" >
      <h1 className="text-center text-black">Terms and Conditions </h1>

      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <p>
            These terms and conditions ("Terms") govern your use of the Qollabb platform ("Platform") provided by Qollabb EduTech Private Limited (hereafter referred to as "Qollabb," "we," or "us"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with these Terms, you should not use the Platform.
          </p>

          <h2>1. User Accounts and Registration</h2>
          <p>
            <strong>1.1 Eligibility:</strong> To use the Platform, you must be at least 18 years old or have reached the age of majority in your jurisdiction. By registering an account, you represent and warrant that you meet these eligibility requirements.
          </p>
          <p>
            <strong>1.2 Account Information:</strong> You are responsible for providing accurate and up-to-date information when registering an account. You must maintain the confidentiality of your account credentials and notify us immediately of any unauthorized use or suspected security breach.
          </p>
          <p>
            <strong>1.3 User Responsibilities:</strong> You are responsible for all activities conducted through your account and agree to comply with all applicable laws and regulations.
          </p>

          <h2>2. Platform Usage</h2>
          <p>
            <strong>2.1 Purpose:</strong> The Platform facilitates connections between students, industry partners, professors, and mentors for real-world projects and collaboration.
          </p>
          <p>
            <strong>2.2 User Conduct:</strong>
          </p>
          <ul>
            <li>
              <strong>Students:</strong>
              <ul>
                <li>Students shall use the Platform to explore and engage in real-world projects offered by industry partners.</li>
                <li>Students shall comply with the guidelines, deadlines, and requirements specified by the industry partners for each project.</li>
                <li>Students shall interact with industry partners, professors, and mentors in a professional and respectful manner.</li>
              </ul>
            </li>
            <li>
              <strong>Industry Partners:</strong>
              <ul>
                <li>Industry partners shall post projects on the Platform and provide accurate and detailed project descriptions.</li>
                <li>Industry partners shall review and evaluate student applications for their projects in a fair and timely manner.</li>
                <li>Industry partners shall provide necessary guidance and support to students during the project collaboration.</li>
              </ul>
            </li>
            <li>
              <strong>Professors and Mentors:</strong>
              <ul>
                <li>Professors and mentors shall support and guide students throughout their project collaboration.</li>
                <li>Professors and mentors shall provide valuable feedback to students to enhance their learning experience.</li>
                <li>Professors and mentors shall engage in professional and ethical conduct while interacting with students and industry partners.</li>
              </ul>
            </li>
          </ul>

          <h2>2.3 Content Ownership</h2>
          <p>
            <strong>2.3.1 Users:</strong> Users retain ownership of the content they submit to the Platform, including project proposals, reports, and other project-related materials.
          </p>
          <p>
            <strong>2.3.2 License:</strong> By using the Platform, users grant Qollabb a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, distribute, and display their content for the purposes of operating and promoting the Platform.
          </p>

          <h2>2.4 Use of Logos</h2>
          <p>
            <strong>2.4.1 User Logos:</strong> By using the Platform, users grant Qollabb the right to use their company, university, college, or organization's logo ("User Logo") on Qollabb's promotional material and student certificates, subject to the following conditions:
          </p>
          <ul>
            <li>The use of User Logos is limited to the purposes of promoting the collaboration between users and Qollabb facilitated through the Platform.</li>
            <li>Qollabb shall make reasonable efforts to accurately represent the collaborations and relationships with users.</li>
            <li>Qollabb shall promptly remove any User Logo upon request from the respective user.</li>
            <li>Users shall not use Qollabb's or other users' logos for any unauthorized purposes or misrepresentation.</li>
          </ul>

          <h2>3. Intellectual Property</h2>
          <p>
            <strong>3.1 Qollabb Property:</strong> All intellectual property rights related to the Platform (including logos, trademarks, software, and content) are owned by Qollabb or its licensors. Users may not use, reproduce, or modify any Qollabb intellectual property without prior written consent.
          </p>
          <p>
            <strong>3.2 User Content:</strong> Users retain ownership of their content and are responsible for ensuring they have the necessary rights and permissions to use and share such content on the Platform.
          </p>

          <h2>4. Privacy and Data Protection</h2>
          <p>
            <strong>4.1 Privacy Policy:</strong> Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using the Platform, you consent to the collection and use of your personal information as described in our Privacy Policy.
          </p>

          <h2>5. Disclaimer and Limitation of Liability</h2>
          <p>
            <strong>5.1 Platform Availability:</strong> We strive to provide a reliable and uninterrupted Platform. However, we do not guarantee that the Platform will be always available or free from errors, interruptions, or defects. Qollabb shall not be held liable for any loss or damages arising from the unavailability or interruption of the Platform.
          </p>
          <p>
            <strong>5.2 User Interactions:</strong> Qollabb does not guarantee the accuracy, quality, or authenticity of user-generated content. Qollabb does not endorse or validate the credentials or claims made by users. Users are solely responsible for their interactions and collaborations with other users.
          </p>
          <p>
            <strong>5.3 Limitation of Liability:</strong> To the fullest extent permitted by law, Qollabb shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Platform. This includes but is not limited to loss of data, loss of profits, or any other losses incurred.
          </p>

          <h2>6. Modifications to the Terms</h2>
          <p>
            <strong>6.1</strong> Qollabb reserves the right to modify or update these Terms at any time without prior notice.
          </p>
          <p>
            <strong>6.2</strong> Users are encouraged to review the Terms periodically to stay informed of any changes.
          </p>

          <h2>7. Termination</h2>
          <p>
            <strong>7.1</strong> Qollabb may, in its sole discretion, suspend or terminate your access to the Platform if you violate these Terms or engage in any fraudulent, illegal, or harmful activities.
          </p>
          <p>
            <strong>7.2</strong> Upon termination, you must cease all use of the Platform and any associated content.
          </p>

          <h2>8. Governing Law and Jurisdiction</h2>
          <p>
            <strong>8.1</strong> These Terms shall be governed by and construed in accordance with the laws of India and the State of Karnataka.
          </p>
          <p>
            <strong>8.2 </strong>Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of Bengaluru, Karnataka, India.
          </p>

          <p>
            By continuing to use the Qollabb Platform, you acknowledge and agree to comply with the above-stated Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
