import axios, { get } from "axios";
import Sidebar from "components/dashboard/sidebar/Sidebar";
import Topbar from "components/dashboard/topbar/Topbar";
import "components/formComponents/style.scss";
import useOutsideClick from "hooks/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "styles/employer/companyDashboard.scss";
import "styles/employer/statbox.scss";
import Notification from "../student/notification/Notification";

const CompanyDashboard = () => {
  const location = useLocation();
 
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [posts, setPosts] = useState(() => ({
    Internships: [],
    Jobs: [],
    Projects: [],
  }));
  const [loading, setLoading] = useState(() => true);
  const [refreshTable, setRefreshTable] = useState(() => false);

  const [notificationData, setNotificationData] = useState(null);
  const [isAlertBoxVisible, setAlertBoxVisibility] = useState(false);
  // const [status, setStatus] = useState(null);
  const [isNotification, setIsNotification] = useState(false);



  const toggleAlertBox = () => {
    setAlertBoxVisibility(!isAlertBoxVisible);
  };


  const alertBoxRef = useRef(null);
  useOutsideClick(alertBoxRef, ()=> setAlertBoxVisibility(false), isAlertBoxVisible);


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (alertBoxRef.current && !alertBoxRef.current.contains(event.target)) {
  //       // Clicked outside the date picker, close the picker
  //       setAlertBoxVisibility(false);
  //     }
  //   };
  
  //   document.addEventListener('mousedown', handleClickOutside);
  
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  
  // const userImage = user?.profile_pic;
  const userImage = user?.company_logo;
  const toggleSidebar = (val) => {
    setShowSidebar(val);
  };
  const sidebarTitle = {
    text1: "Dashboard",
    text2: "Students",
    text3: "Mentoring Sessions",
    text4: false,
    text5: "Project Evaluations",
    text6: "Calendar",
    text7: "Settings",
  };

  useEffect(() => {
    const getActiveInternships = async () => {
      const opts = {
        valdateStatus: () => true,
      };

      const internship = get("/v1/enrollments/internship", opts);
      const job = get("/v1/enrollments/job", opts);
      const project = get("/v1/enrollments/project", opts);

      await axios
        .all([internship, job, project])
        .then(
          axios.spread((...responses) => {
            const internships = responses[0]?.data?.data || [];
            const jobs = responses[1]?.data?.data || [];
            const projects = responses[2]?.data?.data || [];

            setPosts((p) => ({
              ...p,
              Internships: internships,
              Jobs: jobs,
              Projects: projects,
            }));
            setLoading(false);
          })
        )
        .catch(() => {
          setLoading(false);
        });
    };
    getActiveInternships();

    return () => {};
  }, [user.id, location.state, navigate, refreshTable]);


  useEffect(()=> {

    const getNotification = async () => {
      const res = await axios.get("/v1/notification/");
  
      // console.log("notiii", res?.data);
  
      setNotificationData(res?.data?.data);

      // Check if any notification has is_seen false or status 1
      const hasUnseenNotification = res?.data?.data.some(
        (notification) => !notification.is_seen || notification.status === 1
      );

      // Set isNotification based on the condition
      setIsNotification(hasUnseenNotification);

    }

    getNotification();

  }, [isAlertBoxVisible]);

  return (
    <div className="dashboard">
      <Sidebar
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        setShowSidebar={setShowSidebar}
        projects={posts?.Projects?.length}
        internships={posts?.Internships?.length}
        jobs={posts?.Jobs?.length}
      />
      <div className="main">
        <Topbar
          toggleSidebar={toggleSidebar}
          userImg={userImage}
          name={user.first_name}
          toggleAlertBox={toggleAlertBox}
          isNotification={isNotification}
        />

      <div ref={alertBoxRef} >
      {isAlertBoxVisible && 
        <div>
          <Notification data={notificationData} toggleAlertBox={toggleAlertBox} />
        </div>
      }

      </div>

        <Outlet
          context={[posts, setPosts, loading, setRefreshTable, refreshTable]}
        />
      </div>
    </div>
  );
};

export default CompanyDashboard;
