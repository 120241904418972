import useOutsideClick from "hooks/useOutsideClick";
import { useRef, useState } from "react";
import { duplicateProject } from "components/employer/duplicate/project";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFetch from "hooks/useFetch";
import { addProjectId } from "services/actions/application";
import axios from "axios";
import { duplicateInternship } from "./duplicate/internship";
import { duplicateJob } from "./duplicate/job";

const DeactivatePost = ({
  status,
  method,
  closePost = () => {},
  activatePost = () => {},
  projectid,
  flag = false,
}) => {
  const ref = useRef();
  const [toggle, setToggle] = useState(false);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };

  useOutsideClick(
    ref,
    () => {
      setToggle(false);
    },
    true
  );

  const editButton = async (id) => {
    const { data, loading } = await axios.get(
      `/v1/enrollments/${method}/${projectid}`
    );

    // dispatch(addProjectId(id));

    // reduxDispatch(
    //   addProjectId({
    //     id,
    //     mode: "edit",
    //   })
    // );

    if (method === "project") {
      duplicateProject({ data: data?.data?.post, reduxDispatch });
    }
    if (method === "internship") {
      duplicateInternship({ data: data?.data?.post, dispatch: reduxDispatch });
    }
    if (method === "job") {
      duplicateJob({ data: data?.data?.post, dispatch: reduxDispatch });
    }
    navigate(`/employer/${method}/edit/${projectid}`);
  };
  return (
    <>
      {status === 3 && (
        <div
          style={{
            position: "relative",
          }}
        >
          <i
            className="fa-solid fa-ellipsis-vertical toggle-dots"
            style={{
              display: "inline-block",
              width: "20px",
              color: "gray",
              fontSize: "18px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={handleToggle}
          ></i>
          <div
            style={{
              position: "absolute",
              right: "100%",
              top: "150%",
            }}
            className={`${toggle ? "" : "d-none"}`}
            ref={ref}
          >
            <button
              className="p-2 px-4"
              style={{
                fontSize: "12.5px",
                color: "gray",
                border: "1px solid gray",
                background: "white",
              }}
              onClick={activatePost}
            >
              Activate
            </button>
            {!flag && (
              <button
                className="p-2 px-4 w-100 mt-1"
                style={{
                  fontSize: "12.5px",
                  color: "gray",
                  border: "1px solid gray",
                  background: "white",
                }}
                onClick={() => editButton(projectid)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}

      {status === 2 && (
        <div
          style={{
            position: "relative",
          }}
        >
          <i
            className="fa-solid fa-ellipsis-vertical toggle-dots"
            style={{
              display: "inline-block",
              width: "20px",
              color: "gray",
              fontSize: "18px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={handleToggle}
          ></i>
          <div
            style={{
              position: "absolute",
              right: "100%",
              top: "150%",
            }}
            className={`${toggle ? "" : "d-none"}`}
            ref={ref}
          >
            <button
              className="p-2 px-4"
              style={{
                fontSize: "12.5px",
                color: "gray",
                border: "1px solid gray",
                background: "white",
              }}
              onClick={closePost}
            >
              Deactivate
            </button>
            {!flag && (
              <button
                className="p-2 px-4 w-100 mt-1"
                style={{
                  fontSize: "12.5px",
                  color: "gray",
                  border: "1px solid gray",
                  background: "white",
                }}
                onClick={() => editButton(projectid)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DeactivatePost;
