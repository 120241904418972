import React, { useEffect, useReducer, useState } from "react"
import DateField from "components/formComponents/DateField"
import TextArea from "components/formComponents/TextArea"
import TextInput from "components/formComponents/TextInput"
import { toast } from "react-toastify"
import "./AddEducationDetails.scss"
import Dropdown from "components/formComponents/post/Dropdown/Dropdown"
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup"
import { useSelector } from "react-redux"
import axios from "axios"
import CustomYearPicker from "components/formComponents/YearPicker"

const AddEducationDetails = ({
  setEducation,
  setShowAddEducation,
  editedValues,
  setEditedValues,
  index,
  setIndex,
}) => {
  const user = useSelector((state) => state.auth.user)

  const [isPopupOpen1, updatePopup1] = useState(() => false)

  const eduTypes = [
    "High School (10th)",
    "Higher Secondary Education (10+2)",
    "Bachelors (Graduation)",
    "Masters",
  ]

  const [initialState, setInitialState] = useState({
    board_university: editedValues?.board_university || "",
    education_type: editedValues?.education_type || eduTypes[0],
    course_name: editedValues?.course_name || "",
    stream: editedValues?.stream || "",
    passing_year: editedValues?.passing_year || "",
    percentage: editedValues?.percentage || "",
  })

  // const initialState = {
  //   board_university: editedValues.board_university|| "",
  //   education_type: editedValues.education_type||  eduTypes[0],
  //   course_name : editedValues.course_name|| "",
  //   stream : editedValues.stream|| "",
  //   passing_year: editedValues.passing_year|| "",
  //   percentage: editedValues.percentage|| "",

  // };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        // console.log("addddddd", action, state)
        return {
          ...state,
          [action.field]: action.value,
        }
      case "reset":
        return initialState
      default:
        return state
    }
  }, initialState)

  // console.log("initialstate",initialState)
  // console.log("initialstate22",state)

  // useEffect(() => {

  //   dispatch({
  //     type: "add",
  //     field: "education_type",
  //     value: initialState.education_type,
  //   });

  // }, [initialState.education_type]);

  const updateEducationType = (value) => {
    // const selectedType = eduTypes[value];
    // console.log("updateeducationtype",selectedType)
    setInitialState((prevState) => ({
      ...prevState,
      education_type: eduTypes[value],
    }))

    dispatch({
      type: "add",
      field: "education_type",
      value: initialState?.education_type,
    })
  }

  useEffect(() => {
    dispatch({
      type: "add",
      field: "education_type",
      value: initialState.education_type,
    })
  }, [initialState.education_type])

  const setValue = async (val1, val2) => {
    if (initialState.education_type === eduTypes[0]) {
      // If education type is High School, set course_name and stream to empty

      setInitialState((prevState) => ({
        ...prevState,
        course_name: "",
        stream: "",
      }))
      dispatch(
        {
          type: "add",
          field: "course_name",
          value: "",
        },
        {
          type: "add",
          field: "stream",
          value: "",
        }
      )
    } else if (initialState.education_type === eduTypes[1]) {
      // If education type is Higher Secondary, set only course_name to empty

      setInitialState((prevState) => ({ ...prevState, stream: "" }))

      dispatch({
        type: "add",
        field: "course_name",
        value: "",
      })
    } else {
      // For other education types, check if val2 is defined and has course_name property
      if (val2 && val2.course_name !== undefined) {
        const coursename = val2.course_name
        setInitialState((prevState) => ({
          ...prevState,
          course_name: coursename,
        }))
        dispatch({ type: "add", field: "course_name", value: coursename })
      }
    }
  }

  const onSubmit = () => {
    updateEducationType(eduTypes.indexOf(initialState.education_type))
    // Check if percentage is not a number or greater than 100
    if (isNaN(state.percentage) || state.percentage > 100) {
      toast.error("Percentage  invalid")
      return
    }

    // let isValid = 0;

    // // Adjust validation based on education type
    // if (state.education_type === eduTypes[0]) {
    //   // High School

    // } else if (state.education_type === eduTypes[1]) {
    //   if (state.stream === "") {
    //     toast.error("Stream is required", {
    //       delay: 300 * isValid,
    //       toastId: "stream",
    //     });
    //     isValid++;
    //   }
    // } else {
    //   // For Graduation or Masters, set the course_name to the state value
    //   if (state.course_name === "") {
    //     toast.error("Course Name is required", {
    //       delay: 300 * isValid,
    //       toastId: "course_name",
    //     });
    //     isValid++;
    //   }
    //   if (state.stream === "") {
    //     toast.error("Stream is required", {
    //       delay: 300 * isValid,
    //       toastId: "stream",
    //     });
    //     isValid++;
    //   }
    // }

    // // Check other fields
    // Object.keys(state).forEach((key) => {
    //   if (key !== "course_name" && key !== "stream" && state[key] === "") {
    //     toast.error(`${key.split("_").join(" ")} is required`, {
    //       delay: 300 * isValid,
    //       toastId: key,
    //     });
    //     isValid++;
    //   }
    // });

    // if (isValid > 0) return;

    // setEducation((p) => [...p, state]);

    // console.log("indexxxxxxx", state)

    if (index !== null) {
      setEducation((p) =>
        p.map((item, i) => (i === index ? { ...item, ...state } : item))
      )
      // console.log("in if block",state )
    } else {
      // Add a new experience to the array
      setEducation((p) => [...p, state])
      // console.log("in  else block")
    }

    dispatch({ type: "reset" })
    setShowAddEducation(false)
    setEditedValues({})
    setIndex(null)

    // console.log("data education", state);
  }

  // console.log("initiiiiiiiii", initialState)
  // console.log("initiiiiiiiii22222222", state)

  const onChange = (e) => {
    const { id, value } = e.target

    setInitialState((prevState) => ({ ...prevState, [id]: value }))

    dispatch({
      type: "add",
      field: id,
      value: value,
    })
  }

  const handleCancle = async () => {
    setShowAddEducation(false)
    setEditedValues({})
    setIndex(null)
  }

  const dateBefore100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100)
  )
    .toISOString()
    .split("T")[0]

  const dateAfter100Years = new Date(
    new Date().setFullYear(new Date().getFullYear() + 100)
  )
    .toISOString()
    .split("T")[0]

  const today = new Date().toISOString().split("T")[0]

  const datefield = initialState?.passing_year
    ? new Date(initialState?.passing_year).toISOString().split("T")[0]
    : ""

  return (
    <div className="add_experiences row">
      <div className="add_experience_main buttons add_btn">
        <p className="add_btn_p fs-6 fs-md-5 fs-lg-4">Add Education </p>
        <div>
          <button
            className="btn drop_btn_style  btn-outline-primary  m-2 "
            onClick={handleCancle}
          >
            Cancel
          </button>

          <button
            className="btn drop_btn_style btn-primary m-2 text-white"
            onClick={() => onSubmit()}
          >
            Save
          </button>
        </div>
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={eduTypes}
          checked={eduTypes.indexOf(initialState?.education_type)}
          label="Education Type"
          className="profileDropdown"
          setItem={updateEducationType}
          required={true}
          className1={"requiredShowStar"}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Board/University"
          id="board_university"
          handleChange={onChange}
          defaultValue={initialState?.board_university || ""}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Course Name"
          required={false}
          id="course_name"
          // register={register}
          isPopupOpen={isPopupOpen1}
          updatePopup={updatePopup1}
          path="/v1/filter/courses"
          key_name="course_name"
          setValue={setValue}
          withId="course_name"
          className={`profileInputField`}
          className1={"requiredShowStar"}
          value={initialState?.course_name || ""}
          disabled={
            initialState?.education_type === eduTypes[0] ||
            initialState?.education_type === eduTypes[1]
              ? true
              : false
          }
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextInput
          required={false}
          label="Stream"
          id="stream"
          handleChange={onChange}
          disabled={initialState?.education_type === eduTypes[0] ? true : false}
          defaultValue={initialState?.stream || ""}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Percentage % (convert CGPA to percentage)"
          id="percentage"
          handleChange={onChange}
          defaultValue={initialState?.percentage || ""}
        />
      </div>

      <div className="add_experience col-lg-6 col-md-6 col-sm-12">
        {/* <DateField
          className="profileInputField"
          label="year of passing"
          id="passing_year"
          handleChange={onChange}
          minDate={dateBefore100Years}
          maxDate={today}
          dateValue={datefield}
          // value = {formState?.passing_year||""}
        /> */}
          <CustomYearPicker
          label="year of passing"
          id="passing_year"
          minDate={dateBefore100Years}
          maxDate={today}
          required={false}
          className="profileInputField"
          onYearChange={(year) =>
            onChange({ target: { id: "passing_year", value: year } })
          }
          value={initialState?.passing_year||""}

        />
      </div>
    </div>
  )
}

export default AddEducationDetails
