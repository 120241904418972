import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "styles/employer/createPost.scss";
import { toast } from "react-toastify";
import OnSaveToDraft from "components/employer/OnSaveToDraft";

import InternshipStepsLabels from "components/employer/InternshipStepsLabels";
import InternshipSteps from "components/employer/InternshipSteps";
import { REMOVE_APPLICATION_DATA, REMOVE_PROJECT_ID } from "services/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CreateInternship from "./createInternship";
import InternshipDetails3 from "./page3";

const EditInternship = () => {
  const user = useSelector((state) => state.auth.user);
  const step_internship = localStorage.getItem("step_Internship");
  const [saveToDraft, setSaveToDraft] = useState(() => false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(() =>
    step_internship ? parseInt(step_internship) : 1
  );
  const [refresh, setRefresh] = useState(() => false);

  const project_id = useSelector(
    (state) => state?.application?.projectId || null
  );
  const [shouldValidate, setShouldValidate] = useState(() => false);

  useEffect(() => {}, [refresh]);

  /**
   * It updates the state of the shouldValidate array, which is used to determine whether or not to
   * show the validation error messages
   */
  // const updateShouldValidate = (index, value, isNext = false) => {
  //   try {
  //     const steps = shouldValidate.length;
  //     if (index < 0 || index >= steps) throw new Error("Invalid index");
  //     setShouldValidate((prev) => {
  //       prev[index] = value;
  //       return prev;
  //     });

  //     // console.log(shouldValidate, "shouldValidate");
  //     if (isNext) {
  //       index === step - 1 &&
  //         setStep((prev) => (prev < steps ? prev + 1 : prev));
  //       setRefresh(true);
  //     }
  //     return true;
  //   } catch (err) {
  //     toast.error(err?.message || err, { toastId: "updateShouldValidate" });
  //     setRefresh(false);
  //   }
  // };

  const updateShouldValidate = (index, value, isNext = false) => {
    try {
      setShouldValidate(value);
      setRefresh((prev) => !prev);

      if (isNext) {
      }
    } catch (err) {
      toast.error(err?.message || err, { toastId: "updateShouldValidate" });
    }
  };

  // const onNextClick = () => {
  //   // setSaveToDraft(false);
  //   setShouldValidate((prev) => {
  //     prev[step - 1] = true;
  //     return prev;
  //   });
  //   setRefresh((prev) => !prev);
  // };
  const onNextClick = () => {
    setShouldValidate(true);
    setRefresh((prev) => !prev);
  };
  // console.log(shouldValidate, "shouldValidate");
  const company1 = useSelector((state) => state.application.company);

  const onSaveDraft = async (method) => {
    if (JSON.parse(localStorage.getItem("application"))["company"]) {
      var company = JSON.parse(localStorage.getItem("application"))["company"];
      company[1]["branch_id"] =
        company[1]["branch_id"] || company1[1]["branch_id"];
      company[1]["company_id"] =
        company[1]["company_id"] || company1[1]["company_id"];
    } else {
      company = company1;
    }

    if (!company[1]["branch_id"] || !company[1]["company_id"]) {
      return toast.error("You cannot save to draft without company details!");
    }
    const state = JSON.parse(localStorage.getItem("application"))[method];

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      params: { state: 1 },
    };

    function buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    }

    const formData = new FormData();

    buildFormData(formData, state);
    buildFormData(formData, company);

    // console.log(state, "else world");
    formData.append("save_project_id", project_id);
    const { data } = await axios.post(`/v1/${method}`, formData, config);
    // console.log(data, "hello internship")
    if (data?.error) {
      setSaveToDraft(false);
      toast.error(data?.message || "Something went wrong :(");
    } else {
      toast.success("Saved to draft successfully!");
      setSaveToDraft(false);
      dispatch({ type: REMOVE_APPLICATION_DATA, payload: { method } });
      dispatch({ type: REMOVE_PROJECT_ID, payload: { method } });
      console.log(`step_${method}`, "hello");
      if (method === "internship") {
        localStorage.removeItem("step_Internship");
      } else {
        localStorage.removeItem(`step_${method}`);
      }
      navigate("/employer/dashboard");
    }
  };

  const onSavedToDraftClick = () => {
    setSaveToDraft(true);
    setShouldValidate((prev) => {
      prev[step - 1] = true;
      return prev;
    });
    setRefresh((prev) => !prev);
  };

  return (
    <div className="create-post px-4 py-5">
      <div className="header d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">Add an Internship</h1>
        <div className="draft_save_container d-flex flex-column flex-md-row align-items-center gap-3">
          <div className="d-flex justify-content-center gap-3">
            <button className="btn-previous btn d-sm-none">Previous</button>
            <button
              className={`btn btn-main ms-3 ${
                step === shouldValidate?.length ? "last-step" : ""
              }`}
              onClick={onNextClick}
            >
              {step === shouldValidate?.length ? "Review & Publish" : "Next"}
            </button>
          </div>
          {/* <OnSaveToDraft onClick={onSavedToDraftClick} method="internship" /> */}
        </div>
      </div>
      <div className="form-container p-5">
        <CreateInternship
          updateShouldValidate={updateShouldValidate}
          shouldValidate={shouldValidate}
        />
        {/* <div className="row mx-0">
          <InternshipStepsLabels
            shouldValidate={shouldValidate}
            step={step}
            setStep={setStep}
          />

          <div className="col-12 col-md-9 right">
            <InternshipSteps
              user={user}
              step={step}
              shouldValidate={shouldValidate}
              updateShouldValidate={updateShouldValidate}
              setShouldValidate={setShouldValidate}
              saveToDraft={saveToDraft}
              onSaveDraftHandle={onSaveDraft}
            />
          </div>
        </div> */}
        <div className="header d-flex justify-content-end align-items-center">
          <div className="draft_save_container d-flex flex-column flex-md-row align-items-center gap-3 py-3">
            <div className="d-flex justify-content-center gap-3">
              <button className="btn-previous btn">Previous</button>
              <button
                className={`btn btn-main  ${
                  step === shouldValidate?.length ? "last-step" : ""
                }`}
                onClick={onNextClick}
              >
                {/* {step === shouldValidate?.length ? "Review & Publish" : "Next"} */}
                Next
              </button>
            </div>

            {/* <OnSaveToDraft method="project" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInternship;
