import React from "react"

const AdminSubscriptionTable = ({ data }) => {
  return (
    <div className="admin-subscription-table">
      {data.map((item, index) => {
        return (
          <div key={index} className="row mx-0">
            <div className="col-4 pe-0">
              <div className="border p-3">{item.plan}</div>
            </div>
            <div className="col-8 ps-0">
              <div className="row mx-0">
                <div className="col-4 ps-0">
                  <div className="border d-flex align-items-center justify-content-center">
                    {item.free}
                  </div>
                </div>
                <div className="col-4">
                  <div className="border d-flex align-items-center justify-content-center">
                    {item.monthly}
                  </div>
                </div>
                <div className="col-4">
                  <div className="border d-flex align-items-center justify-content-center">
                    {item.yearly}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AdminSubscriptionTable
