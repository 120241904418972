import PreviewField from "components/employer/PreviewField";
import ProfileIcon from "components/employer/ProfileIcon";
import ListContainer from "components/employer/company_form/ListContainer";
import ListItem from "components/employer/company_form/ListItem";
import React from "react";
import { useSelector } from "react-redux";
import "./projectDetail.scss";
import { Link } from "react-router-dom";
import { defaultValues as categories } from "components/formComponents/CategoryField";
import PostDetailsSection from "./PostsHelper/PostDetailsSection";

const JobDetails = ({ data, children, id }) => {
  const user = useSelector((state) => state.auth.user);
  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };
  const employees = {
    0: "1-50",
    1: "51-250",
    2: "251+",
  };

  const CTC_incentive_from = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(data?.ctc_from);
  const CTC_incentive_to = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(data?.ctc_to);

  // console.log(categories);

  // [
  //   { id: 'remote', label: 'Remote/Virtual ' },
  //   { id: 'office', label: 'Office/Factory Based' },
  //   { id: 'field', label: 'Field Work Based' }
  // ]

  const category_name = categories?.find(
    (item) => item?.id === map[data.job_category]
  )?.label;

  // console.log(category_name);

  // console.log(user, "user");
  // const company = useSelector((state) => state?.application?.company[1])
  // console.log(data);

  let path = window.location.pathname.split("/")[1];

  return (
    <div className="Project_preview_container">
      <div className="preview_box">
        <section className="post_container">
          

          <PostDetailsSection data={data} type={"job"} />


        </section>
        <section className="action_container">
          {children}

          {/* <div className="company_container">
            <div className="company_header">
              <ListItem
                name={data?.branch?.company_database?.company_name}
                details={data?.branch?.address}
                image={data?.branch?.company_logo}
                isPreview={true}
                icon={<i className="fa-solid fa-location-dot"></i>}
              />
              <div className="company_body">
                <div className="body_item">
                  <h6>Website</h6>
                  <span className="url">
                    {data?.global_job?.employer_account?.website_url}
                  </span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Founding Year</h6>
                  <span>
                    {data?.global_job?.employer_account?.year_of_incorporation}
                  </span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Name of CEO</h6>
                  <span>{data?.global_job?.employer_account?.ceo_name}</span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>No. of Employees</h6>
                  <span>
                    {
                      employees[
                        data?.global_job?.employer_account?.employee_strength
                      ]
                    }
                  </span>
                </div>
                <div className="devider"></div>
                <div className="body_item">
                  <h6>Industry Sector</h6>
                  <span>
                    {
                      data?.global_job?.employer_account?.industry
                        ?.industry_name
                    }
                  </span>
                </div>
              </div>
            </div>
            {company?.services?.length ? (
              <div className="service-list">
                <h5 className="list_header">Products & Services</h5>
                <ListContainer Items={company.services} isPreview={true} />
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default JobDetails;
