import React from "react"
import TopCard from "./TopCard"
import NewPreviewField from "./NewPreviewField"
import "./postDetailSection.scss"

const PostDetailsSection = ({ data, children, type }) => {

  // console.log("dtaaa",data)
  return (
    // <div>
    <>
      <div className="left-section col-12 mob_col-12">
        <div className="d-flex flex-column gap-3">
          <div className="top-section ">
            <TopCard data={data} type={type} children={children} />
          </div>

          <div className="details-section">
            <div className="tasks-details d-flex flex-column gap-5">
              {type === "project" ? (
                <>
                  <NewPreviewField
                    title={"Project Objectives:"}
                    desc={data?.project_goal}
                  />

                  <NewPreviewField
                    title={"Project Tasks:"}
                    desc={data?.project_tasks}
                  />
                  {data?.course_preferred &&
                    data.course_preferred.length > 0 && (
                      <NewPreviewField
                        title={"Required Qualification:"}
                        courses={data.course_preferred}
                      />
                    )}

                  {data?.required_skills &&
                    data.required_skills.length > 0 && (
                      <NewPreviewField
                        title={"Required Skills:"}
                        skills={data.required_skills}
                      />
                    )}


                </>
              ) : (
                <>
                  <NewPreviewField
                    title={"Roles & Responsibilities:"}
                    desc={data?.roles_and_responsibilities}
                  />
                  {data?.course_preferred &&
                    data.course_preferred.length > 0 && (
                      <NewPreviewField
                        title={"Required Qualification:"}
                        courses={data.course_preferred}
                      />
                    )}

                  {data?.required_skills &&
                    data.required_skills.length > 0 && (
                      <NewPreviewField
                        title={"Required Skills:"}
                        skills={data.required_skills}
                      />
                    )}
                </>
              )}
              {/* {
                            type === "internship" || "job" ? 

                            <>
                                <NewPreviewField 
                                    title={"Roles & Responsibilities:"}
                                    desc={data?.roles_and_responsibilities}
                                />
                            </> : <></>
                        }     */}
            </div>
          </div>
        </div>

        {/* Mob bottom buttons */}
        <div className="mob_bottom_section">
          <div className="bottom-main">
            <div className="bottom-left">{children}</div>
          </div>
        </div>
      </div>
    </>
    // </div>
  )
}

export default PostDetailsSection
