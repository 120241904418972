import Progress from "components/employer/Progress"
import { useEffect, useState } from "react"
import "./statusProgress.scss"

const StatusProgress = ({ status, reports, final_submission = false}) => {
  // console.log({ status, reports, final_submission }, "StatusProgress");
  const [progress, setProgress] = useState(() => 100)
  // console.log({ status, reports, progress })
  
  useEffect(() => {
    // const getProgress = () => {
    //   switch (status) {
    //     case 5:
    //     case 2:
    //       return 100
    //     case 0:
    //     case 1:
    //     case 4:
    //     case 3:
    //     default:
    //       return 0
    //   }
    // }

    // const progress = getProgress()

    if (!final_submission) {
      const completed = reports?.reduce((acc, curr) => {
        return curr?.report ? acc + 1 : acc
      }, 0)
      setProgress((completed / reports?.length) * 100)
    } else {
      setProgress(100)
    }
  }, [status, reports, final_submission])
// console.log("sprogress", progress)
  return (
    <div className="chart d-flex justify-content-between align-items-center">
      <span>Progress Status</span>
      <Progress
        percentage={progress}
        stroke={`hsl(${progress}, 50%, 50%)`}
        strokeWidth={12}
      />
    </div>
  )
}

export default StatusProgress
