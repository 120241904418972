import { useEffect } from "react"

const Popup = ({
  data = [],
  input_value = "",
  updateValue = () => { },
  focus = 0,
  key_name,
  closePopup = () => { },
  children = <></>,
}) => {
  useEffect(() => {
    document.addEventListener("click", closePopup)
    return () => document.removeEventListener("click", closePopup)
  }, [closePopup])

  const onclick = (_, item) => {
    updateValue(item)
  }

  // console.log("popoppp", data);

  return data?.length ? (
    <div className="place_items">
      {data?.map((item, index) => {

        // console.log("item", item, item[key_name], input_value)
        return (
          <div
            onClick={(e) => onclick(e, item)}
            key={index}
            className={`place_item ${focus === index + 1 ? "focus" : ""}`}
          >
            {/* <strong>{item[key_name].substr(0, input_value.length)}</strong>
            <span>{item[key_name].substr(input_value.length)}</span> */}
            <FormatText input={input_value} str={item[key_name]} />
            <input type="hidden" value={item[key_name]} />
          </div>
        )
      })}
    </div>
  ) : (
    <>{children}</>
  )
}

export default Popup

const FormatText = ({ input, str }) => {
  const from = str?.toLowerCase().indexOf(input?.toLowerCase())
  const to = input?.length + from
  const left = str?.slice(0, from)
  const middle = str?.slice(from, to)
  const right = str?.slice(to)

  // console.log({ from, to, right, left, middle })

  return (
    <>
      {left ? <span> {left}</span> : <></>}
      <strong>{middle}</strong>
      {right ? <span>{right}</span> : <></>}
    </>
  )
}
