import DateField from "components/formComponents/DateField";
import EmailField from "components/formComponents/EmailField";
import TextArea from "components/formComponents/TextArea";
import TextField from "components/formComponents/TextField";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import TextInput from "components/formComponents/TextInput";
import NewInputImage from "components/image-uploader/New_Image_uploader/NewInputImage";
import React, { useReducer, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import "./employerPersonalDetail.scss";
const EducatorPersonalDetails = ({
  register,
  image,
  setCompanyImage,
  setImage,
  setValue,
}) => {
  const user = useSelector((state) => state.auth.user);
  const [skills, setSkills] = useState(() => []);
  const [interests, setInterests] = useState(() => []);
  const [isPopupOpen, updatePopup] = useState(() => false);
  const [gender, setGender] = useState(() => parseInt(user?.gender || null));

  const updateGender = (value) => {
    setGender(value);
    setValue("gender", value);
  };

  const dispachdata = useDispatch();

  // console.log(user, "user");

  const [languages, setLanguages] = useState(user?.languages || []);
  const langs = [  
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri",
    "Marathi",
    "Nepali",
    "Odia",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Urdu"
  ]

  const updateLanguages = (value) => {
    setLanguages((prev) => {
      if (prev.includes(langs[value])) {
        return prev.filter((item) => item !== langs[value]);
      }
      return [...prev, langs[value]];
    });
    // setValue("languages", languages)
  };

  // const saveLanguage = (languages) => {
  //   dispatch(updateUser(languages))
  // }
  setValue("languages", languages);
  // console.log(languages, "language");

  // console.log(user, "language");

  const deleteSkill = (skill) => {
    const newSkills = skills.filter((item) => item?.name !== skill);
    setSkills(newSkills);
  };

  const deleteInterests = (interest) => {
    const newInterests = interests.filter((item) => item?.name !== interest);
    setInterests(newInterests);
  };
  const initialState = {
    title: "",
    link: "",
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return {
          ...state,
          [action.field]: action.value,
        };
      case "reset":
        return initialState;
      default:
        return state;
    }
  }, initialState);
  const onChange = (e) => {
    dispatch({
      type: "add",
      field: e.target.id,
      value: e.target.value,
    });
  };
  const handleChange = (e) => {
    dispachdata({
      value: e.target.value,
    });
  };

  //v1/student/profile

  return (
    <div className="row personal_details_container">
      <div className="col-12">
        <div className="col-sm-2 col-4">
          {/*<InputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_photo}
          />*/}
          <span>University/College Logo</span>
          <NewInputImage
            image={image}
            setImageFile={setImage}
            url={user?.profile_pic}
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="First Name"
          id="first_name"
          labelClassName="fieldLabel"
          register={register}
          className="profileInputField"
          placeholder={user?.first_name}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextInput
          label="Last Name"
          id="last_name"
          register={register}
          placeholder={user?.last_name}
          className="profileInputField"
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <label className="form-group mb-3 w-100 text_label">
          {" "}
          Contact Number
          <PhoneInput
            country={"in"}
            placeholder="Enter phone number"
            onChange={(e) => setValue("phone_number", e)}
            defaultCountry="IN"
            onlyCountries={["in"]}
            name="phone_number"
            specialLabel={""}
            containerClass={" mt-2"}
            inputStyle={{ marginTop: "10px" }}
            inputClass={"form-control profileInputField"}
            value={state?.phone_number || user?.phone_number}
          />
        </label>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <EmailField
          label="Email"
          id="email_address"
          register={register}
          placeholder={user?.email_address}
          className="profileInputField"
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <DateField
          minDate="1990"
          maxData="2040"
          label="Date of Birth"
          id="date_of_birth"
          register={register}
          placeholder="Choose a date"
          className="profileInputField"
        />
      </div>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={["Male", "Female", "Others"]}
          checked={gender}
          label="Gender"
          className="profileDropdown"
          setItem={updateGender}
        />
      </div> */}
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          withId="city"
          className="profileInputField"
          value={user?.location}
        />{" "}
      </div>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <Dropdown
          items={langs}
          checked={languages}
          label="Language"
          className="profileDropdown"
          setItem={updateLanguages}
          isMultiple={true}
        />
      </div> */}
      <div className="designation col-lg-6 col-md-6 col-sm-12">
        <TextField
          label="Designation"
          id="designation"
          name="designation"
          placeholder={user?.designation ? "" : "Founder"}
          register={register}
          value={user?.designation}
        />
      </div>

      <TextArea
        label="Brief Introduction of Your University/College (max 1000 characters)"
        id="bio"
        register={register}
        labelClass="profileSectionLabel"
        className="profileInputField"
      />
      {/* <ChipsFieldWithPopup
        label="PROGRAMS"
        id="interest"
        register={register}
        items={interests}
        setItems={setInterests}
        path="/v1/filter/interests/joined"
        deleteItem={deleteInterests}
        key_name="interest_name"
        labelClass="profileSectionLabel"
        className="profileInputField"
        placeholder="Search"
      />
      <ChipsFieldWithPopup
        label="COLLEGE/UNIVERSITY INTERESTS"
        id="user_skills"
        register={register}
        path="/v1/filter/skills"
        items={skills}
        setItems={setSkills}
        deleteItem={deleteSkill}
        labelClass="profileSectionLabel"
        className="profileInputField"
        placeholder="Search"
      /> */}

      {/* <div className="row personal_details_container w-100 mt-4">
        <label className="profileSectionLabel">Profile Links</label>

        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Linkedin"
            id="linkedIn_link"
            register={register}
            className="profileInputField"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
          <TextInput
            label="Portfolio Link"
            id="profile_link"
            register={register}
            className="profileInputField"
          />
        </div>
      </div> */}
      {/* <div className="personal_details_ceo_container w-100 mt-4 gap-4">
        <div className="col-lg-6 col-md-6 col-sm-12 image_content gap-4">
          <div className="d-flex flex-column">
            <label>Company Logo</label>
            <div className="d-flex gap-4 py-2">
              <NewInputImage
                image={image}
                setImageFile={setCompanyImage}
                url={user?.company_logo}
              />
              <NewInputImage
                image={image}
                setImageFile={setImage}
                url={user?.profile_pic}
              />
              <button
                className="btn btn-primary text-white px-5"
                style={{ width: "200px" }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EducatorPersonalDetails;
