import React from 'react'
import './herosection.scss'

import cardIcon from '../../images/cardIcon.svg'
import { Link } from 'react-router-dom'
import img1 from "../../images/shape/slider-card-1.png"
import img2 from "../../images/shape/slider-card-2.png"
import img3 from "../../images/shape/slider-card-3.png"
import img4 from "../../images/shape/slider-card-4.png"

import shape3 from "../../images/shape/shape-03.png"
import shape2 from "../../images/shape/shape-02.png"
import shape1 from "../../images/shape/shape-01.png"
import shape4 from "../../images/shape/shape-04.png"
// import hero from '../../images/heroImg.png'

import slider from "../../images/shape/slider-shape-6.png"

const HeroSection = ({topText,cardText,hero,title,title1,title2,semiTitle,card,buttonText}) => {
  return (
    <section className="slider-area hero-height position-relative fix" >
    {/* <img className="shape-3 d-none d-xxl-block" src={shape3} alt="img not found" /> */}
    <div className="container" >
        <div className="row" >
            <div className="col-xl-6 col-lg-6 col-md-9 " style={{marginTop:'-11%'}}>
                <div className="hero-text pt-95">
                    <h5>{topText}</h5>
                          <h1>{title} <span className="down-mark-line">{ title2}</span> {title1}</h1>
                    <p>{semiTitle}</p>
                    <div className="hero-btn">
                        <Link to="/explore-page" className="edu-btn text-white">{buttonText}</Link>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="hero-right position-relative">
                    <img data-depth="0.2" className="shape shape-1" src={shape1} alt="shape" />
                    <img data-depth="0.2" className="shape-2" src={shape2} alt="shape" />
                    <img className="shape-6" src={slider} alt="shape" />
                    {!card && <div className="shape-4">
                        <img className="" src={shape4} alt="shape" />
                        <h5 className="hero-shape-text">{cardText}</h5>
                    </div>}
                          <img className="hero" src={hero} alt="img not found" />
                   
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default HeroSection