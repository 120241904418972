import { addApplicationData } from "services/actions/application";

export const duplicatePost = ({ data, method, dispatch }) => {
  if (!data || !method) return;
  const {
    skills,
    courses,
    duration_from,
    duration_to,
    keywords,
    vacancies,
    screening_questions,
  } = data;

  const specific = {
    2: {
      duration_from,
      duration_to,
      required_skills: skills,
    },
    3: {
      vacancies,
      course_preferred: courses,
    },
    4: {
      screening_questions,
    },
  };

  specific["2"][`${method}_title`] = data[`${method}_title`];
  specific["2"][`${method}_goal`] = data[`${method}_goal`];
  specific["2"][`${method}_tasks`] = data[`${method}_tasks`];
  specific["2"][`${method}_support`] = data[`${method}_support`];
  specific["3"][`${method}_keywords`] = keywords;

  dispatch(
    addApplicationData({ method, step: 2, data: specific["2"] })
  );
  dispatch(
    addApplicationData({ method, step: 3, data: specific["3"] })
  );
  dispatch(
    addApplicationData({ method, step: 4, data: specific["4"] })
  );
};