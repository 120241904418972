import { useMemo, useEffect, useState } from "react";
import { calculateScore } from "components/employer/calculateCompletion";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import Loader from "components/employer/Loader";
import { useSelector } from "react-redux";
import Wavy from "../student/home/Wavy";
import EducatorPostCard from "components/cards/EducatorPostCard";
import "./home.scss";
import EducatorHomeCard from "./homeCard/EducatorHomeCard";
import useFetch from "hooks/useFetch";
import EducatorInternshipCard from "components/cards/educatorInternshitCard/EducatorInternshipCard";
import EducatorJobCard from "components/cards/educatorJobCard.jsx/EducatorJobCard";
import axios from "axios";

const CollegeEducatorHome = () => {
  const user = useSelector((state) => state.auth.user);
  const score = useMemo(() => calculateScore(user), [user]);
  const [posts] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const [data,setData]=useState([])

  const data = useFetch(`/v1/filter/project?page=1&limit=4`);

  const internshipData = useFetch("/v1/filter/internship?page=1&limit=4");

  const jobData = useFetch("/v1/filter/job?page=1&limit=4");

  // useEffect(() => {
  //   const getActiveData = async () => {
  //     const project = axios.get(`/v1/filter/project?page=1&limit=10`)
  //     const internship = axios.get("/v1/filter/internship?page=1&limit=10")
  //     const job = axios.get("/v1/filter/job?page=1&limit=10")
  //     const response = await axios.all([internship, job, project])
  //     setData(response);
  //   }
  //   getActiveData();
  // },[])

  const job_data = jobData?.data?.data?.items;

  const internship_data = internshipData?.data?.data?.items;
  // console.log("Internship Data 4444", internship_data)
  // console.log(" Data", data)

  const project_data = data?.data?.data?.items;
  // console.log("project_data", project_data)
  // console.log("adrtyuiqweqwe", user)

  // console.log("hello world", job_data, internship_data, project_data);

  useEffect(() => {
    if (data?.data?.data?.items?.length > 0) {
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-container px-4 py-5 ">
      <div className="profile-container d-sm-flex align-items-center justify-content-between">
        <div className="user mb-sm-0">
          <h2>Hi, {user?.first_name}</h2>
          {/* <h2>Hi, {user?.first_name || user}</h2> */}
          <h2>Welcome to {process.env.REACT_APP_NAME}</h2>
          <p className="mb-0 mt-2">
            {/* Here you can post a project, internship or job for students */}
            Please explore projects, internships and jobs and assign them to
            your students
          </p>
        </div>
        <div className="d-block d-sm-flex flex-wrap mt-2 gap-2">
          {user?.subscriptionStatus === null && (
            <div className="subscription-container py-2 px-3 me-3 ">
              <h4 className="d-flex align-items-center justify-content-between mb-1">
                <span>Subscription Status</span>
              </h4>
              <h3 className={`mb-0 text-success fw-bold`}>Free Plan</h3>
            </div>
          )}
          {user?.subscriptionStatus?.subscription_id === 5 && (
            <div className="subscription-container py-2 px-3 me-3 ">
              <h4 className="d-flex align-items-center justify-content-between mb-1">
                <span>Subscription Status</span>
              </h4>
              <h3 className="mb-0">
                <span className="fw-bold text-success">
                  {" "}
                  {
                    user?.subscriptionStatus?.subscription_database
                      ?.subscription_name
                  }{" "}
                </span>
                :{" "}
                {new Date(
                  user.subscriptionStatus.valid_upto
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </h3>
            </div>
          )}
          {user?.subscriptionStatus?.subscription_id === 6 && (
            <div className="subscription-container py-2 px-3 me-3 ">
              <h4 className="d-flex align-items-center justify-content-between mb-1">
                <span>Subscription Status</span>
              </h4>
              <h3 className="mb-0">
                <span className="fw-bold text-success">
                  {" "}
                  {
                    user?.subscriptionStatus?.subscription_database
                      ?.subscription_name
                  }{" "}
                </span>
                :{" "}
                {new Date(
                  user.subscriptionStatus.valid_upto
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </h3>
            </div>
          )}

          {/* <div className="profile-progress-container p-3">
            <h4 className="d-flex align-items-center justify-content-between">
              <span>Complete your Profile</span>
              <span>{score}%</span>
            </h4>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${score}%` }}
                aria-valuenow={score}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div> */}
        </div>
      </div>
      <EducatorHomeCard />
      {/* <Wavy /> */}

      <div className="edu_explore my-4">
        <div className="flex justify-content-between align-items-center mb-1">
          <h1 className=" text-black ">Explore Projects</h1>
          <button
            onClick={() => {
              navigate("/educator/explore/top-projects", {
                // state: { value: 0 },
                state: { tab: 0 },
              });
            }}
          >
            View All
          </button>
        </div>

        <div className="edu_post">
          {project_data?.map((item) => {
            return <EducatorPostCard item={item} />;
          })}
        </div>
      </div>

      <div className="edu_explore my-4">
        <div className="flex justify-content-between align-items-center mb-1">
          <h1 className=" text-black">Explore Internships</h1>
          <button
            onClick={() => {
              navigate("/educator/explore/top-internships", {
                state: { tab: 1 },
              });
            }}
          >
            View All
          </button>
        </div>

        <div className="edu_post">
          {internship_data?.map((item) => {
            return <EducatorInternshipCard item={item} />;
          })}
        </div>
      </div>

      <div className="edu_explore my-4">
        <div className="flex justify-content-between align-items-center mb-1">
          <h1 className=" text-black">Explore Jobs</h1>
          <button
            onClick={() => {
              navigate("/educator/explore/top-jobs", {
                state: { tab: 2 },
              });
            }}
          >
            View All
          </button>
        </div>

        <div className="edu_post">
          {job_data?.map((item) => {
            return <EducatorJobCard item={item} />;
          })}

          {/* {job_data?.map((item) => {
              return (
                <EducatorJobCard/>
              )
            })} */}
        </div>
      </div>
    </div>
  );
};

export default CollegeEducatorHome;
