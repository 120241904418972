import axios from "axios"
import { useEffect } from "react"
import { toast } from "react-toastify"

const useMiscellaneous = ( setCurrentPage, totalPages, company_id, dispatch, dispatch2, setFile, leadEmail) => {


  useEffect(() => {
    // const companyId = location?.companyId;

    if (company_id?.length !== 0) {
      dispatch({
        type: "change_value",
        payload: { company_ids: company_id },
      })

      dispatch2({
        type: "SET_COMP",
        payload: { company_id: null },
      })
    }

  }, [])

    const handleClickPrev = () => {
        setCurrentPage((page) => {
          if (page <= 1) return 1
          else return page - 1
        })
      }
    
      const handleClickNext = () => {
        setCurrentPage((page) => {
          if (page >= totalPages) return totalPages
          else return page + 1
        })
      }

      const viewResume = () => {
        const resumeData = localStorage.getItem("resume")
        if (resumeData) {
          const pdfBlob = dataURLtoBlob(JSON.parse(resumeData))
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, "_blank")
        } else {
          console.error("Resume not found in localStorage")
        }
      }
    
      const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(",")
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      }


      const sendLead = async () => {
        if (leadEmail) {
          const res = await axios.post("/v1/lead/email", { email: leadEmail })
          // console.log("ress", res);
          if (res?.error) {
            toast.error("Faild to submit email")
          } else {
            toast.success(res?.data?.message)
          }
        }
      }
    
      const maxSize = 5 // MB
    
      const handleFileUpload = async (e) => {
        setFile(null)
        const uploaded = e.target.files[0]
    
        // check if file is too large
        const fileSize = uploaded.size / 1024 / 1024
        if (fileSize > maxSize) {
          toast.error(`File size too large (max ${maxSize}MB)`)
          return
        }
    
        setFile(uploaded)
    
        let payload = {}
    
        if (uploaded) {
          // setCachedResume(URL.createObjectURL(uploaded))
          const storeResume = (file) => {
            const reader = new FileReader()
            reader.onload = () => {
              const resumeData = reader.result
              localStorage.setItem("resume", JSON.stringify(resumeData))
            }
            reader.readAsDataURL(file)
          }
    
          storeResume(uploaded)
          // setFile(uploaded);
    
          const formData = new FormData()
          formData.append("lead_resume", uploaded)
          const { data: fileData } = await axios.post(
            "/v1/lead/resume-upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
    
          // console.log("keyyywordsss", fileData?.data);
          // // console.log(fileData?.data, "fileData123");
          if (!fileData?.data) return toast.error("File upload failed")
          payload.keywords = fileData?.data
          // payload.student_resume = fileData?.data || "";
        }
    
        // console.log("payloaddd", payload);
    
        localStorage.setItem("keywords", JSON.stringify(payload?.keywords))
    
        if (payload?.keywords) {
          dispatch({
            type: "change_value",
            payload: { keywords: payload.keywords },
          })
        }
      } 


      return {handleClickPrev, handleClickNext, viewResume, handleFileUpload, sendLead};

  }
  
  export default useMiscellaneous;