import React from "react"
import "./mobileDataTable.scss"
import { toast } from "react-toastify"
import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import StatusButton from "./StatusButton"
import ProgressStatus from "./ProgressStatus"

const MobileDataTable = ({ columns, data, type }) => {
  console.log(columns, data,type, "inf")
  // const closePost = async (id, status) => {
  //   if (status < 1 || status > 2) return toast.error("Invalid status")
  //   const { error, message } = await axios.patch("/v1/project/status", {
  //     id,
  //     status: 3,
  //   })

    // if (error) {
    //   toast.error(message)
    // } else {
    //   toast.success("Project closed successfully")
    // }
    // handleBlur()
  // }
  return (
    <>
      {data?.map((item, i) => {
        return (
          <div className="mobileDataTable mt-4" key={i}>
            <div className="data_container">
              <div className="table_data">
                <p>Date Assigned</p>
                <span>{item?.date}</span>
              </div>
              <div className="table_data">
                <p>Assigned Opportunity</p>
                <span className="project_name">{item?.opportunity?.key}</span>
              </div>
              <div className="table_data">
                <p>Company Name</p>
                <span className="Total_enrolled">{item?.company}</span>
              </div>
              <div className="table_data">
                <p>Employer Name</p>
                <span className="Total_enrolled">{item?.employer}</span>
              </div>

          {
            type === "project" && 

              <div className="table_data">
                <p>Progress Status</p>
                <span className="Total_enrolled">
                <ProgressStatus
                  status={item?.progress?.props?.status}
                  reports={item?.progress?.props?.reports}
                  final_submission={item?.progress?.props?.final_submission?.report} 
                />
                </span>
              </div>
          }

              <div className="table_data">
                <p>Status</p>
                <span>
                  <StatusButton
                    status={item?.status?.props?.status}
                    type={type}  
                  />
                </span>
               {/* {item?.status?.props?.status==="Published"? <small className="Active_btn">Active</small> : <small className="Closed_btn">Closed</small>}
               <DeactivatePost
            closePost={() =>
              closePost(item?.id, item?.status)
            } */}
          {/* // handleToggle={() => handleToggle(data.row.original.id)}
          /> */}
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileDataTable
