import axios from "axios";
import Loader from "components/employer/Loader";
import React, { useEffect, useState } from "react";
import MentorList from "./MentorList";
import "./projectevaluation.scss";
const ProjectEvaluation = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const getAll = async () => {
      try {
        setLoading(true)
        const res = await axios.post("/v1/evaluation/getEvaluations");
        setData(res?.data?.data);

        setLoading(false)
      } catch (err) {
        setLoading(false)
        throw err;
      }
    };
    getAll();

    return () => {};
  }, []);

  //  Parse mentor_evaluated_date dates and extract month and year
const parsedData = data.map((item) => {
  return {
    ...item,
    month: item.mentor_evaluated_date ? new Date(item.mentor_evaluated_date).getMonth() + 1 : null,
    year: item.mentor_evaluated_date ? new Date(item.mentor_evaluated_date).getFullYear() : null,
  };
});

//  Filter data for entries where mentor_evaluated_date is null
const filteredData = parsedData.filter((item) => item?.mentor_evaluated_date === null);

// console.log("filtered", filteredData)
//  Group filtered data by month and year
const groupedData = filteredData.reduce((acc, item) => {
  if (item?.month !== null && item?.year !== null) {
    const key = `${item.month}-${item.year}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
  }
  return acc;
}, {});

// console.log("groupdata", groupedData)
//  Count entries in each group
const monthlyEvaluatedCount = Object.keys(groupedData).map((key) => {
  const group = groupedData[key];
  return {
    month: group[0].month,
    year: group[0].year,
    evaluatedCount: group.length,
  };
});
  // console.log("count", monthlyEvaluatedCount)
  // console.log("evaluate", data)

  if(loading) return <Loader />;
  
  return (
    <div>
      <div className="project_evaluationTop">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <h3 className="fw-bold">Project Evaluation</h3>
          <div className="student-enroll-main d-flex gap-md-4">
            <div className="student-enroll">
              <div className="flex">
                <h3>Evaluated this month</h3>
                <p className="flex">
                  <span className="h3 mt-2">{data.length - filteredData.length}</span>{" "}
                  <span className="des-color row">
                    <i className="fa-solid fa-sort-up"></i>
                    <b>+1 today</b>
                  </span>
                </p>
              </div>
            </div>

            <div className="student-enroll">
              <div className="flex">
                <h3>Pending this month</h3>
                <p className="flex">
                  {/* <span className="h3 mt-2"></span>{" "} */}
                  <span className="h3 mt-2">{filteredData.length}</span>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MentorList data={data} />
    </div>
  );
};

export default ProjectEvaluation;
