import { Link } from 'react-router-dom';
import React from 'react';
import './zoom.scss'
import t1 from "../../images/teacher/zoom-shape-1.png"
import t2 from "../../images/teacher/zoom-shape-2.png"
import t3 from "../../images/teacher/Zoom.png"

const ZoomSection = () => {
    return (
        <section className="zoom-area ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-6 col-xl-5 col-lg-5 ">
                        <div className="zoom-thumb position-relative">
                            <img className="zoom-shape-01" src={t1} alt="img not found" />
                            <img className="zoom-shape-02" src={t2} alt="img not found" />
                            <img className="zoom-thumb-main-img" src={t3} alt="img not found" />
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-5 col-lg-5 order-lg-1">
                        <div className="zoom-class-wrapper mb-60">
                            <div className="section-title mb-30">
                                <h2>Become a <span className="down-mark-line-2">Mentor</span> And <br />
Help Students Build <br /> Industry Skills</h2>
                            </div>
                            <div className="zoon-class-text py-2">
                                <p>Make a lasting impact on students' careers with Qollabb's real industry projects.</p>
                                <br /> <p className="m-0">Submit your resume now.</p> 
                            </div>
                            
                            {/* <a href="mailto:info@qollabb.com?subject=Resume%20for%20the%20role%20of%20Mentor&body=Please%20find%20attached%20my%20resume%20for%20the%20role%20of%20mentor." className="edu-btn btn-transparent mt-25">Join Us Now!</a> */}
                            
                            <a href='/signup' className="edu-btn btn-transparent mt-25">Join Us Now!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ZoomSection;