import axios from "axios"
import Loader from "components/employer/Loader"
import NewInternshipDetailsPage from "components/posts/NewInternshipDetailsPage"
import "components/posts/NewProjectDetails.scss"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import "styles/employer/preview.scss"


const Actions = ({ onApply }) => {
  return (
    <div className="actions">
      <button  className="btn btn-primary py-2 w-100 text-white btn-width" onClick={onApply}>
        Apply Now
      </button>
     
    </div>
  )
}

const FetchInternshipDetails = ({ id, onApply }) => {

  const [loading, setLoading] = useState(false);
  const [internship, setinternship] = useState({});

  const fetchInternship = async() => {
    try {
      setLoading(true);
      const data = await axios.get(`/v1/filter/internship/${id}`)
      // console.log("daaaaaaaaaate", data);

      setinternship(data?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);

    }
  }

  useEffect(()=> {
    fetchInternship();
  }, [id])

  // const { loading, data, error } = useFetch(`/v1/filter/internship/${id}`)

  // if (loading || !data) return <Loader />
  // const { error: err, data: internship } = data

  // if (err || error) return <h3>{err}</h3>

  if(loading) return <Loader />

  return (
    <NewInternshipDetailsPage
      data={internship}
    >
      <Helmet>
        {/* <meta
          charSet="utf-8"
          name="description"
          content={`${project?.project_title}`}
        /> */}
            <meta
              name="title"
              content={internship?.internship_title}
            />
            <link rel="canonical" href={`https://qollabb.com/project/${internship?.internship_title?.replace(/[\s#]+/g, '-')}/${internship?.id}`} />
        <title>
          {internship?.internship_title}
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>
      <Actions  data={internship} onApply={onApply} />
    </NewInternshipDetailsPage>
  )
}

export default FetchInternshipDetails
