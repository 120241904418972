import * as React from "react"
import { memo } from "react"

const SvgComponent = () => (
    <svg width="24" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.897 10.905c.566-.646.107-1.658-.752-1.658h-2.746a1 1 0 0 1-1-1V1.322a1 1 0 0 0-1-1H8.474a1 1 0 0 0-1 1v6.925a1 1 0 0 1-1 1H3.728c-.859 0-1.318 1.012-.752 1.658l8.208 9.381a1 1 0 0 0 1.505 0l8.208-9.38ZM.037 25.121a1 1 0 0 1 1-1h21.799a1 1 0 0 1 1 1v.975a1 1 0 0 1-1 1H1.037a1 1 0 0 1-1-1v-.975Z"
            fill="#6149CD"
        />
    </svg>
)

const DownloadIcon = memo(SvgComponent)
export default DownloadIcon
