import { useEffect, useState } from "react";
import { get } from "axios";

export default function useFetch(path = "", options = {}, deps = [], fn) {
  const [fetchData, setFetchData] = useState({
    status: 102,
    data: null,
    loading: true,
    error: false,
  });

  useEffect(() => {
    const controller = new AbortController();

    const opt = {
      signal: controller.signal,
      withCredentials: true,
      ...options,
    };

    const fetchPath = async ({ path, opt }) => {
      try {
        const data = await get(path, opt);
        // console.log(data, "newData");
        if (fn) fn(data);
        setFetchData({
          status: data.status,
          data: data.data,
          loading: false,
          error: data.data.error,
        });
      } catch (err) {
        setFetchData({
          status: 500,
          error: true,
          loading: false,
          data: err?.message,
        });
      }
    };

    fetchPath({ path, opt });

    return () => {
      controller.abort();
    };
  }, [path, ...deps]);

  return { ...fetchData, setFetchData };
}
