import axios from "axios";
import useRelativeTime from "hooks/useRelativeTime";
import { useState } from "react";
import { toast } from "react-toastify";
import "./studentJobDescription.scss";

import ProfileIcon from "components/employer/ProfileIcon";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InterviewSchedule from "view/dashboard/employer/project/id/sections/interviewSchedule";

// const ScreeningQuestions = ({ questions, answers }) => {
//   const parseJSON = (str) => {
//     try {
//       return JSON.parse(str);
//     } catch (err) {
//       return [];
//     }
//   };

//   const que = parseJSON(questions);
//   const ans = parseJSON(answers);
//   return ans?.map((answer, index) => {
//     return (
//       <Fragment key={index}>
//         <p className="ques">{que[index]}</p>

//         <p className="ans">{answer}</p>
//       </Fragment>
//     );
//   });
// };

const StudentJobDescription = ({
  data,
  method = "internship",
  post = {},
  selected,
  setSelected,
  max = 1,
  handleStatusChange,
  handleChat,
  Rejected = false,
}) => {
  const user = useSelector((state) => state.auth.user);
  // // console.log("user",user)
  // // console.log(post,"posttt")

  const date = useRelativeTime(data?.createdAt);
  const internshipStatus = data?.status;
  const newData = data;
  // // console.log(internshipStatus, "s123");

  // // console.log(data?.student?.first_name, "data12ddddd3");
  // // console.log(method);

// console.log("ppoosttt", newData);

  const sendNotification = async (userId, role, status)=> {
    try {

      const notificationData = {
        userId,
        role,
        notification_title: `Employer ${status === 4 ? "shortlisted": "declined"} your ${method === "internship" ? "internship" : "job"} application`,
        notification_description: `${status === 4 ? "Shortlisted": "Declined"} your ${method === "internship" ? post?.internship_title : post?.job_title } application`,
        notification_type: `${method === "internship" ? "internship" : "job"}`,
        notification_payload: {
          id: userId,
          role: 4,
          post_id: newData?.id,
          post_title: method === "internship" ? post?.internship_title : post?.job_title

        },

      }

      const res = await axios.post("/v1/notification/", {
        notificationData
      })

      // console.log("ress", res);
      
    } catch (error) {
      // console.log("err", error?.message)
    }
  }


  const changeStatus = async (status) => {
    try {
      const response = await axios.patch(
        `/v1/enrollments/${method}`,
        {},
        {
          params: {
            id: data.id,
            status,
          },
        }
      );

      // toast.success(response?.data?.message)
      if (method === "internship") {
        {
          status === 7 && toast.success("Internship Rejected");
        }
        {
          status === 4 && toast.success("Internship Shortlisted");
        }
        {
          status === 6 && toast.success("Hired");
        }
      } else {
        {
          status === 7 && toast.success("Student Job rejected");
        }
        {
          status === 4 && toast.success("Student Job Shortlisted");
        }
        {
          status === 6 && toast.success("Hired");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [click, setClick] = useState("");
  const handleNotInterested = (userId, role) => {
    changeStatus(7);
    sendNotification(userId, role, 7);
    setClick("notInterested");
    handleStatusChange({ id: data.id, status: 7, oldStatus: data.status });
  };

  const handleShortlisted = (userId, role) => {
    changeStatus(4);
    sendNotification(userId, role, 4);
    setClick("shortlisted");
    handleStatusChange({ id: data.id, status: 4, oldStatus: data.status });
  };

  const [showDate, setShowDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schedule_date, setScheduleDate] = useState("");
  const [schedule_time, setScheduleTime] = useState("");
  // // console.log(data?.id);
  // // console.log(method);

// code for schedule for interview

// console.log("Application post data",post)

// code for interview schedule

// // console.log("email", enrollments?.data[selectedStudent]?.student?.email_address )

  const gapi = window.gapi;
  const google = window.google;

  const CLIENT_ID =  process.env.REACT_APP_GOOGLE_CLIENT_ID

  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = "https://www.googleapis.com/auth/calendar";

  const accessToken = localStorage.getItem('access_token');
  const expiresIn = localStorage.getItem('expires_in');

  let tokenClient;
  let gapiInited = false, gisInited = false

  function gapiLoaded() {
    gapi.load('client', async () => {
      await gapi.client.init({
        // apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      gapiInited = true;
  
      if (accessToken && expiresIn) {
        gapi.client.setToken({
          access_token: accessToken,
          expires_in: expiresIn,
        });
       
      }
    });

    return true
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });

    // console.log("token", tokenClient)
    gisInited = true;
  }

  function addManualEvent() {
    // console.log("time", schedule_time)
    // console.log("date", schedule_date)

    const originalDate = new Date(`${schedule_date}T${schedule_time}`); // Ensure correct date format
    const updatedDate = new Date(originalDate.getTime() + 30 * 60 * 1000); // Adding half an hour (30 minutes)
    
    const hours = updatedDate.getHours();
    const minutes = updatedDate.getMinutes();
    
    // Formatting to ensure two digits for hours and minutes
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    
    // console.log(formattedTime);
   
    const event = {
      'summary': `Interview schedule-${user?.company?.company_name}, Dear,${data?.student?.first_name} ${data?.student?.last_name}`,
      'location': `${post?.branch?.address}`,
      'description': `Dear ${data?.student?.first_name} ${data?.student?.last_name},Interview for ${method=="job"?post.job_title:post.internship_title
      } `,
      'start': {
        'dateTime': `${schedule_date}T${schedule_time}:00`,
        'timeZone': 'Asia/Kolkata'
      },
      'end': {
        'dateTime':`${schedule_date}T${formattedTime}:00`,
        'timeZone': 'Asia/Kolkata'
      },
      'recurrence': [
        'RRULE:FREQ=DAILY;COUNT=1'
      ],
      'attendees': [
        // {'email':user.email_address},
        { 'email':  newData?.student?.email_address, 'responseStatus': 'needsAction' },
      ],
      'reminders': {
        'useDefault': true,
      },
      'reminders': {
        'useDefault': false, 
        'overrides': [
          { 'method': 'email', 'minutes': 30 }, // Add custom reminders
          { 'method': 'popup', 'minutes': 15 }
        ],
      },
      "guestsCanSeeOtherGuests": true,
    };
  
    const request = gapi.client.calendar.events.insert({
      'calendarId': 'primary',
      'resource': event,
      'sendUpdates': 'all'
    });
  
    request.execute((event) => {
      // console.log(event);
      window.open(event.htmlLink);

      toast.success("Interview scheduled successfully! Please check your email and Google Calendar to confirm!")
    }, (error) => {
      console.error(error);

      toast.error("Interview scheduling failed")
    });
  }
  
  async function handleAuthClick() {
    const res = gapiLoaded()
    gisLoaded()

    if(res && gisInited){
      // console.log("tokenclient", tokenClient)
      tokenClient.callback = async (resp) => {
        if (resp.error) {
          console.error(resp);
         
          toast.error("Authentication failed")
          return;
        }
    
        const { access_token, expires_in } = gapi.client.getToken();
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('expires_in', expires_in);
        // setinterview event and send mail 
        addManualEvent();
      };
    
      if (!(accessToken && expiresIn)) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: '' });
      }
    }
    else {
      toast.error("Authentication failed..interview was not scheduled.");
    }
 
  }

// end code of interview schedule and call handleauthclick in the hired funcition

// end of code of schedule of interview


 
  

  const handleHired = async () => {
    try {
      setLoading(true);
      if (!schedule_date || !schedule_time) {
        setLoading(false);
        toast.error("Please select date and time");
        return;
      }
      const { data } = await axios.patch(
        `/v1/enrollments/${method}/update-enrollment`,
        {
          student_id: newData?.student?.id,
          id: newData?.id,
          status: 6,
          schedule_interveiw: {
            date: schedule_date,
            time: schedule_time,
          },
          oldStatus: newData?.status,
        }
      );

      // changeStatus(6);

      setLoading(false);
    
      setShowDate(false);
       handleAuthClick();
      // interview schedule --handleauthclick function calling
      // console.log(data);
      // toast.success("Interview Scheduled Successfully");
    } catch (err) {
      // console.log(err, "hello world");
      setLoading(false);
      setShowDate(false);
      toast.error(err?.response?.data1?.message);
    }
  };

  const DateType = (value) => {
    return new Date(value)?.toDateString().split(" ").slice(1, 3).join(" ");
  };

  // console.log("dataaaa", data);
  return (
    <div className="job-description">
      {/* <div className="firsttab flex">
        <h2>Open in new tab</h2>
        <div>
          <img
            style={{ width: "16px", height: "20px", marginRight: "25px" }}
            src={downloadIcon}
            alt="download icon"
          />
          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        </div>
      </div> */}
      <div className="secondtab">
        <div className="flex">
          <div className="student_resume_data">
            <div style={{ width: "64px", height: "64px" }} className="img_div">
              {data?.student?.profile_photo ? (
                <img
                  src={data?.student?.profile_photo}
                  alt={data?.student?.first_name}
                  className="rounded"
                />
              ) : (
                <div style={{ width: "44px", height: "44px" }}>
                  <ProfileIcon />
                </div>
              )}
            </div>
            <div className="student_content w-100">
              <div className="heading_content">
                <span className=" h2">
                  {data?.student?.first_name} {data?.student?.last_name}
                </span>
                <span className="light">{date}</span>
              </div>

              <p className="add">
                {data?.student?.location && <p>{data?.student?.location}</p>}
              </p>
              {/* <p className="star">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <p>5.00</p>
              </p> */}
            </div>
            {/* <div className="New_student">
              <p>New</p>
            </div> */}
            {Date.now() - new Date(data?.createdAt).getTime() <
            1000 * 60 * 60 ? (
              <div className="New_student">
                <p>New</p>
              </div>
            ) : (
              <></>
            )}
            {/* left side button for view resume */}
            {/* <div className="download-resume-btn-contaier">
              {data?.student?.student_resume && (
                <div className="download-resume-btn">
                  <p>View Resume</p>
                  <Link
                    to={data?.student?.student_resume}
                    className="link-box"
                    target="_blank"
                  >
                    <span>View Resume</span>
                    <i className="fa-solid fa-download"></i>
                  </Link>
                </div>
              )}
            </div> */}
          </div>
          {/* <div className="student_resume_data">
           
              <div style={{width: '64px',height:"64px"}} className="img_div">
                <img src={rahulImg} alt="" />
              </div>
            <div className="student_content">
              <div className="student_content">
                <span className="h2">
                  {data?.student?.first_name} {data?.student?.last_name}
                </span></div>
                <span className="light">{date}</span>
                <button className="new">New</button>
                <p className="add">Noida, UP, India</p>
                <p className="star">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </p>
              </div>
          </div> */}
          {/* <button className="chatbtn interview">
            <i className="fa-regular fa-comment-dots"></i>
            Start Chat
          </button>
          <button className="interview">
            <i className="fa-regular fa-calendar"></i> Interview
          </button> */}
        </div>
        <div className="tab-btn">
          {/* <div className="tags">
            <span>UI Designer</span>
            <span>Figma</span>
            <span>Landing Page</span>
          </div> */}
          <div className="bio">
            {data?.student?.course_name && (
              <p>
                <i className="fa-solid fa-graduation-cap"></i>{" "}
                <span>{data?.student?.course_name}</span>
              </p>
            )}
            {data?.student?.date_of_birth && (
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                <i className="fa-solid fa-cake-candles"></i>
                <span>{DateType(data?.student?.date_of_birth)}</span>
              </p>
            )}
          </div>
          <p className="text">{data?.student?.bio || "No bio"}</p>
          {/* <button className="btn3 chatbtn">
            <i className="fa-regular fa-comment-dots"></i>
            Start Chat
          </button>
          <button className="btn3">
            <i className="fa-regular fa-calendar"></i> Interview
          </button> */}
        </div>
      </div>
      <div className="thirdtab">
        {data?.student?.student_resume && (
          <div className="col-12 mb-3 view-resume-container">
            <div className="view-resume py-2">
              <label className="w-100">Resume</label>
              <Link to={data?.student?.student_resume} target="_blank">
                <span className="view-box">View Resume</span>
              </Link>
            </div>
          </div>
        )}
        <div className="row py-2">
          <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
            {data?.student?.course_name && (
              <div className="education br">
                <h3>Education</h3>
                <div className="education-detail">
                  <h4>
                    <span>
                      <strong>{data?.student?.course_name}</strong>
                      {data?.student?.institution_name}
                    </span>{" "}
                  </h4>
                  <p>
                    {DateType(data?.student?.course_start_year)} -{" "}
                    {DateType(data?.student?.course_end_year)}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
            {data?.student?.experiences?.length ? (
              <div className="education br">
                <h3>Experience</h3>
                {data?.student?.experiences?.map((item) => {
                  return (
                    <div className="education-detail">
                      <h4>
                        <span className="text-wrap">
                          <strong>{item?.designation}</strong> -{" "}
                          {item?.company_name}
                        </span>{" "}
                      </h4>
                      <p>
                        Project |{DateType(item?.start_date)} -{" "}
                        {DateType(item?.end_date)}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="inquire-description">
        <h3>Candidate Responses</h3>
        {/* <div className="question">
          <ScreeningQuestions
            questions={post?.screening_questions}
            answers={data?.screening_questions}
          />
        </div> */}
        <div className="btn-box d-none d-sm-block p-2">
          <button
            className="intrested"
            type="button"
            onClick={() => handleNotInterested(data?.student?.id, data?.student?.role)}
            // style={{ display: click === "notInterested" && "none" }}
            style={{
              display: internshipStatus === 7 && "none",
            }}
            disabled={click === "notInterested"}
          >
            Rejected
          </button>
          <button
            className="short"
            // style={{ display: click === "shortlisted" && "none" }}
            style={{
              display: internshipStatus === 4 && "none",
            }}
            type="button"
            onClick={()=>  handleShortlisted(data?.student?.id, data?.student?.role)}
            disabled={click === "shortlisted"}
          >
            Shortlist
          </button>
          {showDate ? (
            // implement date and time picker here
            <InterviewSchedule
              setShowDate={setShowDate}
              handleHired={handleHired}
              setScheduleDate={setScheduleDate}
              setScheduleTime={setScheduleTime}
              loading={loading}
            />
          ) : (
            <button
              style={{ display: (click === "hire" || Rejected) && "none" }}
              // className="hire"
              type="button"
              className="short"
              onClick={() => setShowDate(true)}
              disabled={click === "hire"}
            >
              Schedule Interview
            </button>
          )}
          {/* <button
            style={{ display: click === "hire" && "none" }}
            className="short"
            type="button"
            onClick={handleHired}
            disabled={click === "hire"}
          >
            Schedule Interview
          </button> */}
          <Link
            style={{
              display: Rejected && "none",
            }}
            to={`/employer/chat`}
          >
            <button className="hire" type="button" onClick={handleChat}>
              Chat
            </button>
          </Link>

          <div className="float-end mt-2">
            <p>
              {selected ? (
                <i
                  className="fa-solid fa-arrow-left"
                  onClick={() => setSelected((p) => p - 1)}
                ></i>
              ) : (
                <></>
              )}
              {selected + 1}
              <span>/{max} </span>
              {selected !== max - 1 ? (
                <i
                  className="fa-solid fa-arrow-right"
                  onClick={() => setSelected((p) => p + 1)}
                ></i>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        <div className="btn-box d-flex d-sm-none flex-column">
          <div className="flex gap-2">
            <button
              className="intrested"
              type="button"
              onClick={handleNotInterested}
            >
              Reject
            </button>
            <button className="short" type="button" onClick={handleShortlisted}>
              Shortlist
            </button>
          </div>

          {/* onClick={handleHired} */}
          <button
            className="hire bg-primary"
            type="button"
            onClick={handleHired}
          >
            Schedule Interview
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentJobDescription;
