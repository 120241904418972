import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import manimage1 from "../../../../images/manimage.png";
import "./setting.scss";

const HelpDesk = () => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  // const [refresh,setRefresh] = useState(true)

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (query === '') {
      toast.error('Please enter a query before submitting the form');
      return;
    }
    const requestBody = JSON.stringify({ "query": query });

    axios.post('/v1/helpcenter/add-query', requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // console.log(response);
        setQuery("");
        // setRefresh(false)
      })
      .catch(error => {
        console.log(error);
      });
  };
  // // console.log(queryValue);

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const response = await axios.get('/v1/helpcenter/getMyQueries');
        // console.log(response?.data?.data,"res data");
        setData(response?.data?.data)
      } catch (error) {
        console.error(error);
      }
}
fetchData()
  },[query])
  return (
    <section className="employer_setting">
      <div className="setting">
        <div className="row wrap-uper">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <h2>Qollabb Helpdesk</h2>
            <p>
              Hi there! Let’s know how can we help you. Please checkout the FAQs
              and if it can’t resolve your issue, then chat with us or raise a
              support ticket.
            </p>
            <div className="row">
              <div className="col-12">
              <form onSubmit={handleSubmit} className="comment">
                <p className="comment-text">Your Concern*</p>
      <textarea type="text" value={query} onChange={handleQueryChange} />
      <button type="submit">Raise a Ticket</button>
    </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="man-image">
              <img src={manimage1} width={431} height={337} alt="Image" />
            </div>
          </div>
        </div>
      </div>

      <div className="previous-ticket">
        <h2>Previous Tickets</h2>

        <div className="row">
          {data?.map((item) => {
          // console.log(item,"item data");
            return (
              <div div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" key={item?.id}>
              <div className="ticket-info">
                <div className="row">
                  <div className="col-6">
                    <p className="ticket-id">Ticket ID.</p>
                    <h3>{item?.id}</h3>
                    </div>
                   { !item?.comment ? 
                  <div className="col-6">
                    <button className="op-btn">Open</button>
                  </div>
                  :
                  <div className="col-6">
                  <button>Closed</button>
                </div>
                  }
                  <p className="ticket-detail">
                   {item?.query}
                    </p>
                    <p className="ticket-detail text-success">{item?.comment }</p>
                </div>
              </div>
            </div>
          )
          })
         }
          {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="ticket-info">
              <div className="row">
                <div className="col-6">
                  <p className="ticket-id">Ticket ID.</p>
                  <h3>000002</h3>
                </div>
                <div className="col-6">
                  <button>Closed</button>
                </div>
                <p className="ticket-detail">
                  Facing issue in connecting with mentor kindly look into it as
                  i need guidance with my project works. Facing issue in
                  connecting with mentor kindly look into it as i need guidance
                  with my project works.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="ticket-info">
              <div className="row">
                <div className="col-6">
                  <p className="ticket-id">Ticket ID.</p>
                  <h3>000003</h3>
                </div>
                <div className="col-6">
                  <button>Closed</button>
                </div>
                <p className="ticket-detail">
                  Facing issue in connecting with mentor kindly look into it as
                  i need guidance with my project works. Facing issue in
                  connecting with mentor kindly look into it as i need guidance
                  with my project works.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="ticket-info">
              <div className="row">
                <div className="col-6">
                  <p className="ticket-id">Ticket ID.</p>
                  <h3>000004</h3>
                </div>
                <div className="col-6">
                  <button>Closed</button>
                </div>
                <p className="ticket-detail">
                  Facing issue in connecting with mentor kindly look into it as
                  i need guidance with my project works. Facing issue in
                  connecting with mentor kindly look into it as i need guidance
                  with my project works.{" "}
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default HelpDesk
