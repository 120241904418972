import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import sir_img from "../../images/our_team/vipendrasir-profile-pic.webp";
import rohan_img from "../../images/our_team/rohan-profile-pic.webp"
import pavan_img from "../../images/our_team/pavan-profile-pic.webp"
import amisha_img from "../../images/our_team/amisha-profile-pic.webp"
import prerna_img from "../../images/our_team/prerna-profile-pic.webp"
import ayush_img from "../../images/our_team/ayush-profile-pic.webp"
import pooja_img from "../../images/our_team/pooja-profile-pic.webp"
import './ourTeam.scss'
import 'swiper/css/bundle'


const OurTeam = () => {

  const handleCardClick = (linkedIn) => {
    window.open(linkedIn, '_blank');
  };


    const teamData = [
        {
          name: 'Vipendra Singh',
          title: '(Founder-CEO)',
          imgSrc: sir_img,
          linkedIn: 'https://www.linkedin.com/in/vipendra',
          // socialLinks: [
          //   { icon: 'fa fa-facebook', link: '#' },
          //   { icon: 'fa fa-instagram', link: '#' },
          //   { icon: 'fa fa-linkedin', link: '#' },
          // ],
        },
        {
          name: 'Ayush Singhal',
          title: '(Chief Technical Officer)',
          imgSrc: ayush_img,
          linkedIn: 'https://www.linkedin.com/in/imayushsinghal',
          
        },
        {
          name: 'Pooja Banerjee',
          title: '(HR & Operations)',
          imgSrc: pooja_img,
          linkedIn: 'https://www.linkedin.com/in/pooja-banerjee-sanyal',
          
        },
        {
          name: 'Amisha Sinha',
          title: '(Strategic Partnerships)',
          imgSrc:  amisha_img,
          linkedIn: 'https://www.linkedin.com/in/amisha-sinha-300753207',
         
        },
        {
          name: 'Prerna Singh',
          title: '(Digital Marketing)',
          imgSrc:  prerna_img,
          linkedIn: 'https://www.linkedin.com/in/prerna7',
         
        },
        {
          name: 'Pavan Gupta',
          title: '(Software Developer)',
          imgSrc: pavan_img ,
          linkedIn: 'https://www.linkedin.com/in/pavan-gupta31',
          
        },
        {
          name: 'Rohan Gupta',
          title: '(Software Developer)',
          imgSrc: rohan_img ,
          linkedIn: 'https://www.linkedin.com/in/rohan-gupta09/',
          
       
        },
        
       
        
      ];

    return (
        <div className="team-slider">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 pb-4">
                        <div className="section-title text-center mb-55">
                        <h2><span className="down-mark-line"> Meet Our Team</span></h2>
                        </div>
                    </div>
                </div>

                
                <div className="row">
          {teamData.map((member, index) => (
            <div key={index} className="col-lg-3 col-md-6 team-col-12 col-sm-6"onClick={() => handleCardClick(member.linkedIn)}>
              <div className="card">
                <div className="mt-2">
                  <img src={member.imgSrc} alt={member.name} /> 
                  <i className="fa fa-linkedin"></i>
                </div>
                <div className="team-content">
                  <h3 className="team-name">{member.name}</h3>
                  <span className="team-title">{member.title}</span>
                  {/* <div className="social-icons">
                    {member.socialLinks.map((social, i) => (
                      <a key={i} href={social.link}>
                        <i className={`${social.icon}`}></i>
                      </a>
                    ))}
                  </div> */}
                </div>
              </div>
              
            </div>
          ))}
        </div>


            </div>
        </div>
    );
};

export default OurTeam ;