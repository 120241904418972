import React from "react"
import { Link } from "react-router-dom"
import "../mobileDataTable.scss"

const JobMobileViewTable = ({ columns, data }) => {
  // console.log(columns, data,"intern")

  return (
    <>
      {data.map((item) => {
        return (
          <div className="mobileDataTable mt-4">
            <div className="data_container">
              <div className="table_data">
                <p>Date Posted</p>
                <span>{item?.date}</span>
              </div>
              <div className="table_data">
                <p>Job Title</p>
                <span className="project_name"><Link to={item?.title?.props?.to}>{item?.title?.props?.children[1]}</Link></span>


              </div>

              <div className="table_data">
                <p>Project Created</p>
                <span className="Total_enrolled">{item?.project_linked}</span>
              </div>

              <div className="table_data">
                <p>Total applied</p>
                <span className="Total_enrolled">{item?.enrolled}</span>
              </div>
              <div className="table_data">
                <p>Project submitted</p>
                <span className="Total_enrolled">{item?.submitted}</span>
              </div>
              <div className="table_data">
                <p>Total Shortlisted</p>
                <span className="Total_enrolled">{item?.rated}</span>
              </div>
              
             




             
              <div className="Status_data">
                <p>Status</p>
               {item?.status?.props?.status==="Active"? <small className="Active_btn">Active</small> : <small className="Closed_btn">Closed</small>}
               
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default JobMobileViewTable
