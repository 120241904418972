import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateUser } from 'services/actions/auth'
import AddExperience from 'view/dashboard/student/profile/AddExperience'

const MentorExperience = ({ experiences, setExperiences }) => {
  const [showAddExperience, setShowAddExperience] = useState(
    () => !experiences.length
  )
  const dispatch = useDispatch();
  const [editedValues, setEditedValues] = useState({})
  const [index, setIndex] = useState(null);

   const editExperience = async (item, index)=> {
    setEditedValues(item)
    setShowAddExperience(true)
    setIndex(index);
    
  }

  const deleteExperience = async (index) => {
    try {
      const id = experiences[index].id;
      let deleted = false;
      if (id) {
        const { data } = await axios.delete(`/v1/mentor/experience/${id}`);
        if (!data?.error) deleted = true;
      } else deleted = true;
      if (deleted) {
        const newExperiences = experiences.filter((_, i) => i !== index);
        setExperiences(newExperiences);
        dispatch(updateUser({ experience: newExperiences }));
      } else {
        toast.error("Error removing experience!");
      }
    } catch (error) {
      toast.error("Error removing experience!");
    }
  };




  return (
    <>
    <div className="row">
        {experiences?.map((experience, index) => (
          <div className="col-md-6 col-sm-12 mt-2 mb-2" key={index}>
            <div className="doc_card card">
              <div className="card-body docCard_body">
                <div className="w-100">
                  <h5 className="card-title">{experience?.designation?experience?.designation:''}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {experience?.company_name?experience?.company_name:''}
                  </h6>

                  <p className="mb-2 card-text">
                    {experience.start_date?new Date(experience.start_date).toLocaleDateString():''} {experience.start_date && experience.end_date?'-':''}{experience.end_date?new Date (experience.end_date).toLocaleDateString():''}
                  </p>
                  <p className="mb-2 card-text ">{experience?.description?experience?.description:''}</p>
                </div>

                <div className="docButton">
                  <button
                    className="btn text-danger doc_btn"
                    onClick={() => deleteExperience(index)}
                  >
                    Delete
                  </button>
                  <button className="btn text-primary" onClick={()=> editExperience(experience, index)}>Edit</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

    {showAddExperience ? (
      <AddExperience
        setExperiences={setExperiences}
        setShowAddExperience={setShowAddExperience}
           experiences={experiences}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
          index={index }
          setIndex={setIndex}
      />
    ) : (
      <span className="btn-link" onClick={() => setShowAddExperience(true)}>
        Add more
        <i className="fa fa-plus ml-2"></i>
      </span>
    )}
  </>
  )
}

export default MentorExperience