import axios from "axios"
import { downloadCSV } from "components/dataTable/Export"
import Datatable from "components/datatable-new/DatatableNew"
import StatusButton from "components/employer/StatusButton"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EditModal from "./modals/EditModal"



  


const AdminComplaints = () => {
  const [search, setSearch] = useState(() => "")
  const [allData, setAllData] = useState([]);
  const [open, setOpen] = useState(false)
  
  // ***************
  // const [search, setSearch] = useState(() => "")
  const [showModal, setShowModal] = useState(false)
  const [newStream, setNewStream] = useState("")
  const [editStream, setEditStream] = useState("")
  const [editId, setEditId] = useState(-1)
  const [showEditModal, setShowEditModal] = useState(false)
  // const [solution,setSolution] = useState('')



  useEffect(()=>{
    const fetchData = async() => {
      try {
        const response = await axios.get('/v1/helpcenter/getAllQueries');
        // console.log(response,"res data");
        setAllData(response?.data?.data)
      } catch (error) {
        console.error(error);
      }
}
fetchData()
  }, [showEditModal])

  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`/v1/helpcenter/updateQuery/${editId}`, {
        comment,
        status,
      });
      // console.log(response.data);
      // handle success case
    } catch (error) {
      console.log(error.response.data);
      // handle error case
    }
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setStatus(1);
  };

  // const handleStatusChange = (e) => {
  //   setStatus(e.target.value);
  // };

  

 
  // console.log(allData,"data");
  const roleName={
    2 : "Employer",
    3 : "Educator",
    4 : "Student",
    5 : "Mentor",
}

  const cardsData = [
    {
      imgUrl: "",
      primaryText: "Companies",
      secondaryText: "+50 this month",
      value: "128",
    },
    {
      imgUrl: "",
      primaryText: "Companies",
      secondaryText: "+50 this month",
      value: "128",
    },
    {
      imgUrl: "",
      primaryText: "Companies",
      secondaryText: "+50 this month",
      value: "128",
    },
    {
      imgUrl: "",
      primaryText: "Companies",
      secondaryText: "+50 this month",
      value: "128",
    },
  ]
  
  const columns = [
    {
      Header: "Date Raised",
      accessor: "createdAt",
      width: "12%",
      textalign: "start",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {new Date(data.row.original.createdAt).toLocaleDateString('en-GB')}
          </strong>
        )
      },
    },
    {
      Header: "Ticket ID",
      accessor: "id",
      width: "12%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong className="text-primary" onClick={() => {
            setShowEditModal(true)
            setEditStream(data.row.original.query)
            setEditId(data.row.original.id)
          }} style={{
            cursor: "pointer"
          }} >
            {data.row.original.id}
          </strong>
        )
      },
    },
    {
      Header: "User Name",
      accessor: "user_name",
      width: "12%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.user_role === 4 && data.row.original.UserAccount?.student_account?.first_name}
            {data.row.original.user_role ===3 && data.row.original.UserAccount?.educator_account?.first_name}
            {data.row.original.user_role ===2 && data.row.original.UserAccount?.employer_account?.first_name}
            {data.row.original.user_role ===5 && data.row.original.UserAccount?.mentor_account?.first_name}
          </strong>
        )
      },
    },
    {
      Header: "User Type",
      accessor: "user_type",
      width: "12%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {
              roleName[data.row.original.user_role]
            }
          </strong>
        )
      },
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return (
          <strong style={{ fontWeight: "700" }}>
            {data.row.original.due_date}
          </strong>
        )
      },
    },
    {
      Header: "",
      accessor: " ",
      width: "30%",
      textalign: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "10%",
      textalign: "center",
      Cell: (data) => {
        return <StatusButton status={data.row.original.status} />
      },
    },
  ]

  // const rows = data
  //   ?.filter((post) => {
  //     return post?.first_name?.toLowerCase()?.includes(search?.toLowerCase())
  //   })
  //   ?.map((post) => {
  //     return {
  //       id: post?.id,
  //       company_name: "Pinsout Innovation Pvt.Ltd.",
  //       company_location: "Noida, India",
  //       name: post?.first_name + " " + post?.last_name,
  //       signup_date: new Date(post?.createdAt).toLocaleDateString(),
  //       last_login: new Date(post?.updatedAt).toLocaleDateString(),
  //       projects: post?.projects || 0,
  //       internships: post?.internships || 0,
  //       jobs: post?.jobs || 0,
  //       subscription_validity: new Date(post?.updatedAt).toLocaleDateString(),
  //       sector: post?.designation,
  //       designation: post?.designation,
  //     }
  //   })

  // // console.log(rows)

  const calculateDueDate = (createdAt) => {
    const currentDate = new Date(createdAt);
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate.toLocaleDateString('en-GB'); // Assuming you want the result in ISO format
  };

  const rows = allData.map((item) => {
    return {
      ...item,
      due_date: calculateDueDate(item?.createdAt),
    };
  })

  // console.log("rows", rows)
  return (
    <div
      className="content-container py-5 pt-4 p-2"
      style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
    
      <div className="admin-dash-companies">
        <div className="row">
          {/* {cardsData.map((card, index) => {
            return (
              <div className="col-3" key={index}>
                <AdminDashCard
                  img={card.imgUrl}
                  data={cardsData}
                  primaryText={card.primaryText}
                  secondaryText={card.secondaryText}
                  value={card.value}
                />
              </div>
            )
          })} */}
        </div>

        <div className="datatable-container mt-4">
          <div className="table_head flex">
            <div className="mobo_upload">
           
              <h3>
                Tickets <br />{" "}
                <span className="light">{allData?.length || 0} records</span>
              </h3>

              <Link to="" className="upload_btn_icon_mobo btn btn-primary">
                <i
                  className="fa-solid fa-cloud-arrow-down post-icons"
                  onClick={() => downloadCSV(rows)}
                ></i>
              </Link>
            </div>

            <div className="add_icon non">
              <Link
                to=""
                className="upload_btn_icon btn btn-primary text-white px-3"
              >
                Add <i className="fa-solid fa-plus"></i>
              </Link>
              <button
                className="upload_btn_icon btn btn-primary"
                onClick={() => downloadCSV(rows)}
              >
                <i className="fa-solid fa-cloud-arrow-down post-icons"></i>
              </button>

              <label className="search_label">
                <input
                  type="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </label>
            </div>
          </div>
          <EditModal
          show={showEditModal}
          setShow={setShowEditModal}
          type="Query"
          >
            {/* onSubmit={handleUpdate} */}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="stream" className="mb-2">
              Query
                </label>
                <p>
                  {editStream}
                </p>
              <input
                type="text"
                className="form-control"
                id="stream"
                placeholder="Enter stream"
                value={comment}
                onChange={handleCommentChange}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-outline-primary me-3 text-primary px-4 px-2"
                onClick={() => {
                  setEditStream("")
                  setShowEditModal(false)
                }}
              >
                Close
              </button>
              <button
                type="submit"
                  className="btn btn-primary text-white px-4 py-2"
                  onClick={() => {
                    setShowEditModal(false)
                  }}
              >
                Save
              </button>
            </div>
          </form>
        </EditModal>

          <Datatable columns={columns} data={rows} />
          
          
        </div>
      </div>
      
    </div>
  )
}

export default AdminComplaints
