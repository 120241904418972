import React, { useEffect, useState } from "react";
import MDCourses from "./MDCourses";
import MDIndustries from "./MDIndustries";
import MDInterestAreas from "./MDInterestAreas";
import MDSkills from "./MDSkills";
import MDSpecialization from "./MDSpecialization";
import MDStreams from "./MDStreams";
import "./style.scss";
import axios, { get } from "axios";

const AdminMasterData = () => {
  const [data, setData] = useState(() => ({
    Industries: [],
    Streams: [],
    Courses: [],
    Specializations: [],
    Interests: [],
    Skills: [],
  }));

  useEffect(() => {
    const getAll = async () => {
      const industry = get("/v1/admin/industries");
      const stream = get("/v1/admin/streams");
      const course = get("/v1/admin/courses");
      const specialization = get("/v1/admin/specializations");
      const interest = get("/v1/admin/interests");
      const skill = get("/v1/admin/skills");

      await axios
        .all([industry, stream, course, specialization, interest, skill])
        .then(
          axios.spread((...responses) => {
            // console.log(responses)
            const industries = responses[0]?.data?.data || [];
            const streams = responses[1]?.data?.data || [];
            const courses = responses[2]?.data?.data || [];
            const specializations = responses[3]?.data?.data || [];
            const interests = responses[4]?.data?.data || [];
            const skills = responses[5]?.data?.data || [];
            setData((p) => ({
              ...p,
              Industries: industries,
              Streams: streams,
              Courses: courses,
              Specializations: specializations,
              Interests: interests,
              Skills: skills,
            }));
          })
        )
        .catch(() => {
          // setLoading(false)
        });
    };
    getAll();

    return () => {};
  }, []);

  // console.log(data);

  return (
    <div
      className={`content-container py-5 pt-2 admin-master-data p-1`}
      // style={{ paddingLeft: "2rem", paddingRight: "2rem", minHeight: "100vh" }}
    >
      <div className="row mx-0">
        <div className="col-md-6 col-sm-12 col-12">
          <MDIndustries data={data?.["Industries"]} />
        </div>
        <div className="col-md-6 col-sm-12 col-12">
          <MDStreams data={data?.["Streams"]} />
        </div>
      </div>
      <div className="row mx-0">
        <MDCourses data={data?.["Courses"]} />
      </div>
      <div className="row mx-0">
        <MDSpecialization data={data?.["Specializations"]} />
      </div>
      <div className="row mx-0">
        <div className="col-md-6 col-sm-12 col-12">
          <MDInterestAreas data={data?.["Interests"]} />
        </div>
        <div className="col-md-6 col-sm-12 col-12">
          <MDSkills data={data?.["Skills"]} />
        </div>
      </div>
    </div>
  );
};

export default AdminMasterData;
