export const calculateScore = (data) => {
  console.log(data)
  let score = 0;

  if (data?.is_email_verified) score += 10;
  if (data?.phone_number) score += 20;
  if (data?.first_name && data?.last_name) score += 20;
  if (data?.isOTPVerified) score += 20;
  // if (data?.profile_pic) score += 10;
  // if (data?.company?.company_name) score += 10; 
  if (data?.company_logo) score += 20;
  // if (data?.website_url && data?.designation) score += 10;
  // if (data?.company?.company_description) score += 10;
  // if (data?.company?.company_address) score += 10;
  if (data?.location) score += 10;

  return score;
};
