import React, { useState, useEffect } from "react";
import Chips from "components/formComponents/Chips";
import "./mentorSkills.scss";
import { toast } from "react-toastify";
import axios from "axios";
import Popup from "components/formComponents/Popup";
import { useSelector } from "react-redux";

const AddMentorSkills = ({ setSkills, skills,setPreviousSkills }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [focus, setFocus] = useState(0);
  

  const [prevSkills,setPrevSkills]=useState([])
  useEffect(() => {
    if (setPreviousSkills) {
 
      const skillNames = setPreviousSkills?.map(skill => skill.name);
      // Set the extracted skill names to prevSkills state
      setPrevSkills(skillNames);
    } else {
     
      setPrevSkills([]);
    }
  }, [setPreviousSkills]);

  // console.log("prevSkills",prevSkills)


  useEffect(() => {
    if (inputValue.trim() !== "") {
      axios
        .get("/v1/filter/skills", {
          params: { name: inputValue },
          validateStatus: (status) => status < 500,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setSuggestions(response.data.data);
          } else {
            setSuggestions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsPopupOpen(true); // Open popup when input changes
  };

  const updateChips = (value) => {
    const trimmedValue = value.trim();
    if (trimmedValue === "") {
      return; // Skip empty input
    }

    // Check for duplicates
    if (skills.some(existingSkill => existingSkill.name.toLowerCase() === trimmedValue.toLowerCase())) {
      // If a duplicate skill is found, display a toast error
      toast.error("Duplicate skills are not allowed!");
      return;
    }
  
    // Check if the trimmed input value matches any suggestion
    const matchedSuggestion = suggestions.find(suggestion => typeof suggestion.name === 'string' && suggestion.name.toLowerCase() === trimmedValue.toLowerCase());
    if (matchedSuggestion) {
      // If the input value matches a suggestion, add the suggestion as a skill
      setSkills(prevSkills => [...prevSkills, { name: matchedSuggestion.name, id: matchedSuggestion.id }]);
    } else {
      // If the input value doesn't match any suggestion, add the input value as a skill
      setSkills(prevSkills => [...prevSkills, { name: trimmedValue }]);
    }
  
    setInputValue(""); // Clear input field
  };
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isPopupOpen && suggestions.length > 0) {
        updateChips(suggestions[focus].name);
        setIsPopupOpen(false);
        setFocus(0);
      } else {
        updateChips(inputValue);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocus((prevFocus) =>
        prevFocus < suggestions.length - 1 ? prevFocus + 1 : prevFocus
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocus((prevFocus) => (prevFocus > 0 ? prevFocus - 1 : prevFocus));
    }
  };
  
  
  const handleChipDelete = (chipName) => {
    setSkills(skills.filter(chip => chip.name !== chipName));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSuggestionClick = (suggestion) => {
    updateChips(suggestion.name);
    setIsPopupOpen(false); // Close popup when suggestion is clicked
  };

  return (
    <div className="reviewer-chips-container">
      <label htmlFor="skills" className="reviewer-skilllabel">
        Add Skills
      </label>
    
      <div className="">
        <input
          id="skills"
          className="form-control mt-2"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Type to add skills..."
          style={{ border: "1px solid #d7d3d3" ,minHeight: "60px"}}
        />
        {isPopupOpen && suggestions.length > 0 && (
          <div className="reviewer-place_container">
            <div className="place_items">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className={index === focus ? "focus place_item" : "place_item"}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ backgroundColor: index === focus ? "#e6e6e6" : "transparent" }} 
                >
                  {suggestion.name}
                </div>
              ))}
            </div>
            <div
              className="reviewer-popup-overlay"
              onClick={handleClosePopup}
            ></div>
          </div>
        )}
      </div>

      <div className="row scrollview mt-0">
        <div className="reviewer-chips">
          <Chips items={skills} deleteItem={handleChipDelete} />
         {
          prevSkills &&(
            prevSkills.map((item)=>{
              return(
                <span className="chip">{item}</span>
              )
            })
          )
         }
        </div>
      </div>
      
    </div>
  );
};

export default AddMentorSkills;
