import React from "react";
import { Link } from "react-router-dom";
import "./educatorInternshipCard.scss";
import useRelativeTime from "hooks/useRelativeTime";

const EducatorInternshipCard = ({ item }) => {
  // console.log(item);
  const data = {
    company_logo:
      "https://www.thewowstyle.com/wp-content/uploads/2015/01/nature-images.jpg",
    title: "Pinsout Innovation",
    time: "12:00",
    address: "noida",
    incentive_to: "0",
    duration_to: "0",
    week: "3-4 weeks",
    skills: [
      {
        name: "full stack developer",
      },
    ],
    desc:
      " The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. ",
  };
  const time = useRelativeTime(item?.createdAt);
  const CTC_incentive_from = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(item?.stipend_from);
  const CTC_incentive_to = new Intl.NumberFormat("en", {
    notation: "compact",
  }).format(item?.stipend_to);

  // console.log(item,"hello bye")

  const captilize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <div>
      <div className="edu_container_main edu_main  w-100 col-12 col-sm-3 p-3 bg-white">
        <div className="d-flex align-items-center justify-content-between">
          <img
            className="card_img comp-img"
            width={50}
            height={50}
            src={
              item?.global?.employer_account?.company_logo ||
              item?.branch?.company_logo
            }
            alt="Company Logo"
          />
          {/* <p className="text-muted ">{time}</p> */}
          {/* <p>{item?.slice(20,25)?.createdAt}</p> */}
          {/* <FromTo register={register} /> */}
        </div>
        <div className="edu_container  card-content">
          <Link to={`/educator/internship/details/${item?.id}`}>
            <h5>{item?.title}</h5>
            <h6>
              <span>Company name: </span>
              {captilize(item?.global?.employer_account?.company?.company_name)}
            </h6>
            <p style={{ margin: "0" }}>
              <b>
              {item?.location ? `${item?.location?.city_name}, ${item?.location?.state?.state_name}`: "Remote"}
                {/* {item?.location?.state?.state_name} ,{item?.location?.city_name} */}
              </b>
            </p>
            <div className="flex  justify-content-start">
              <p className="edu_container_add mb-0">
                {/* ,{item?.location?.state?.state_name } ,{item?.location?.city_name } */}
                <b>
                  Stipend:{CTC_incentive_from}-{CTC_incentive_to}/Month
                </b>
              </p>
              <p style={{ margin: "0 4px" }}>|</p>
              <p className=" mb-0">
                Tenure : <b>{item?.duration_to}-Month</b>
              </p>
            </div>
            <p style={{ margin: "0" }}>
              Seeking Students from :{" "}
              {item?.internship_city?.length > 0
                ? item?.internship_city?.map((city) => city).join(", ")
                : "From anywhere"}
            </p>
          <div className="d-flex skills_style py-2">
            { item?.course_preferred.length > 0 ?  item.course_preferred?.map((skill) => (
              <span className="badge me-2 p-2" key={skill?.id}>
                {skill?.course_name}
              </span>
            )): <span className="p-2">Any course</span> }
          </div>
          <p className="edu_desc pb-2 mt-2">
            {item.description?.slice(0, 120)}
          </p>
        </Link>
          <Link
            to={`/educator/internship/details/${item?.id}`}
            type="button"
            className="assign_btn btn btn-purple bg-primary w-100"
          >
            <div style={{fontSize: "17px"}}>
              Assign
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EducatorInternshipCard;
