import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import template3 from "../../../../../images/ImageTemplates/17.png";
import template1 from "../../../../../images/ImageTemplates/5.png";
import template2 from "../../../../../images/ImageTemplates/7.png";
import scanme from "../../../../../images/ImageTemplates/scanme5.webp";
import "./InternshipTemplate.scss";

const templateConfigurations = {
    [template1]: {
        companyImgPosition: { top: "6%", right: "5%" },
        templateItemsPosition: { top: "22%", left: "21%", gap: "21px" },
        qrSectionPosition: {position: "absolute", bottom: "18%", right: "32%" },
        qollabbLinkPosition: {position: "absolute", bottom: "12%", right: "45%" },
        pHeadStyle: { marginBottom: "12%", marginLeft: "3%", color: "#000" }
    },
    [template2]: {
        companyImgPosition: { top: "10%", right: "5%" },
        templateItemsPosition: { top: "28%", left: "20%", gap: "30px" },
        qrSectionPosition: {position: "absolute", bottom: "13%", right: "32%" },
        qollabbLinkPosition: {position: "absolute", bottom: "8.5%", right: "43%" },
        pHeadStyle: { marginBottom: "6%", marginLeft: "1%", color: "#000" }
    },
    [template3]: {
        companyImgPosition: { top: "10%", right: "5%" },
        templateItemsPosition: { top: "25%", left: "16%", gap: "30px" },
        qrSectionPosition: {position: "absolute", bottom: "11%", right: "31%" },
        qollabbLinkPosition: {display: "none", position: "absolute", bottom: "10%", right: "43%" },
        pHeadStyle: { marginBottom: "6%", marginLeft: "1%", color: "#000" }
    },
};

const InternshipTemplate = () => {
    const targetElement = useRef(null);
    const location = useLocation();
    const data = location?.state?.data;
    const [selectedImage, setSelectedImage] = useState(template1);
    const [templateStyle, setTemplateStyle] = useState(templateConfigurations[template1]);

    const handleImageChange = (event) => {
        const selectedTemplate = event.target.value;
        setSelectedImage(selectedTemplate);
        setTemplateStyle(templateConfigurations[selectedTemplate]);
    };

    const handleGenerateImage = () => {
        const width = 1060;
        const height = 1065;
        html2canvas(targetElement.current, { width, height }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'generated_image.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const [img, setImg] = useState("");

      const convertImageToBase64 = async () => {
        try {
      
          const timestamp = new Date().getTime();
      const cacheClearedUrl = `${`${data?.global?.employer_account?.company_logo}`}?_=${timestamp}`;
      
      const response = await fetch(cacheClearedUrl);
      const blob = await response.blob();
  
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const newImageUrl = URL.createObjectURL(blob);
            setImg(newImageUrl);
          }, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
      });
        } catch (error) {
          console.error('Error converting image to base64:', error);
        }
      };
  
      useEffect(()=> {
          convertImageToBase64();
      }, [])




    return (
        <div className='template-img'>
            <div ref={targetElement} className='template-body'>
                <div className='template-content'>
                    <img src={selectedImage} alt="bellicon" />
                    <div className='company-img' style={templateStyle.companyImgPosition}>
                        <img src={img} alt="logo" />
                        {/* <img src={data?.global?.employer_account?.company_logo} alt="logo" /> */}
                    </div>
                    <div className='template-items' style={templateStyle.templateItemsPosition}>
                        <h2 className='p-head' style={templateStyle.pHeadStyle}>Internship opportunity for Freshers</h2>
                        <div className='template-item'>
                            <div>Company:</div>
                            <div>{data?.global?.employer_account?.company?.company_name}</div>
                        </div>
                        <div className='template-item'>
                            <div>Position:</div>
                            <div>{data?.internship_title}</div>
                        </div>
                        <div className='template-item'>
                            <div>Job Type:</div>
                            <div>{data?.location !== null ? "Office" : "Remote/virtual"}</div>
                        </div>
                        <div className='template-item'>
                            <div>Location:</div>
                            <div>{data?.location !== null ? data?.location?.city_name + ", " + data?.location?.state?.state_name : "Remote"}</div>
                        </div>
                        <div className='template-item'>
                            <div>Duration:</div>
                            <div>{data?.duration_from + " - " + data?.duration_to} weeks</div>
                        </div>
                    </div>
                    <div className='qr-section' style={templateStyle.qrSectionPosition}>
                        <div className='scanme-container'>
                            <img src={scanme} alt="scanme" width={120} style={{ filter: selectedImage === template1 ? "hue-rotate(66deg)" : "hue-rotate(30deg)" }} />
                            <div className='qrcode' style={{position: "absolute", top: "17.4%", left: "24.4%"}}>
                                <QRCode value={`https://qollabb.com/internship/${data?.id}`} size={62} />
                            </div>
                        </div>
                    </div>
                    <div className="qollabb-link" style={templateStyle.qollabbLinkPosition}>
                        <a href={"https://www.qollab.com/"} >https://qollabb.com</a>
                    </div>
                </div>
            </div>
            <div className='btn-section'>
                <select value={selectedImage} onChange={handleImageChange} className='select-img'>
                    <option value={template1}>Template 1</option>
                    <option value={template2}>Template 2</option>
                    <option value={template3}>Template 3</option>
                    {/* Add other template options */}
                </select>
                <button onClick={handleGenerateImage}>Download Template</button>
            </div>
        </div>
    );
};

export default InternshipTemplate;
