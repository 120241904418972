import React from "react";
import "./mentorMobileDataTable.scss";

const MentorMobileDataTable = ({ columns, data, todayData, selectedDate, handleDateChange, handleKeyClick, Reschedule, setReschedule, HandleReschedule, timeZones, selectedTime, type, joinSession }) => {
  // console.log(data, "datajhkdhf")
  const minDate = new Date().toISOString().split("T")[0]; // get current date
  return (
    <>
      {data.map((item) => {
        return (
          <div className="mobileDataTable">
            <div className="data_container">
              <div className="table_data flex-column align-items-start ">
                <div className="d-flex w-100 align-items-center py-2">
                  <p>Student Name</p>:{" "}
                  <p className="px-2">
                    {item?.student?.first_name} {item?.student?.last_name}
                  </p>{" "}
                </div>
                <div className="d-flex w-100 align-items-center py-2">
                  <p>Project Name</p>:{" "}
                  <p className="text-primary px-2">
                    {item?.enrollment?.project?.project_title}{" "}
                  </p>
                </div>
                {/* {!todayData && ( */}
                  <div className="d-flex w-100 align-items-center py-2">
                    <p>Date</p>:{" "}

                    { Reschedule?.id === item?.id ? (
                                    <input
                                    className="date_style form-control"
                                    style={{
                                      marginTop: "5px",
                                      maxWidth: "200px"
                                    }}
                                    id="dateInput"
                                    placeholder="dd-mm-yyyy"
                                    value={selectedDate}
                                    type="date"
                                    min={minDate}
                                    onChange={handleDateChange}
                                  />
                    ) : (
                      <p className="text-black px-2">
                      {" "}
                      {new Date(item?.startTime).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
)

}


                  </div>
                {/*  )} */}
                <div className="d-flex w-100 align-items-center py-2">
                  <p>Timing</p>:{"  "}

                    {
                      (Reschedule?.id === item?.id  && selectedDate) ? (
                        <div className="time_dropdown time-row py-2 px-2 my-1 mx-auto"
                                    style={{
                                      maxWidth: "250px"
                                    }} >
                      { !Object.keys(timeZones).length == 0 ? (
                          <>
                            {Object.keys(timeZones).map((key) => {
                              return (
                                <div key={key} className="col-4 gap-1">
                                  <span
                                    style={{
                                      backgroundColor:
                                        selectedTime === key ? "#6149cd" : "",
                                      color: selectedTime === key ? "#fff" : "",
                                      cursor: "pointer",
                                    }}
                                    className="duration w-100 my-6 p-1 gap-4 rounded fw-light"
                                    onClick={() => handleKeyClick(key)}
                                  >
                                    {key}
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <p>Mentor is not available on this day</p>
                        )}
                      
                      </div>)
                      : (
                              <p className="text-danger px-2">
                                      {" "}
                                      {new Date(item?.startTime).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })}
                              </p>
                      )      
                    }
                </div>


                <div className="d-flex w-100 align-items-center py-2">
                  <p>Actions</p>:{" "}
                  {/* {!todayData ? (
                    <span className="p-2 px-4 mx-2 bg-primary text-center text-white rounded">
                      Active
                    </span>
                  ) : (
                    <span className="p-2 px-4 mx-2 bg-success text-white rounded w-0">
                      Join
                    </span>
                  )} */}

{
    type === "todaySession" ?  (
      <>
      <button style={{background: "#47d065"}} onClick={(e) => { joinSession(e, item) }}>Join</button>
      </>
    ) : (
               Reschedule?.id === item?.id ? (
                <button onClick={() => HandleReschedule({id: item.id, student_id: item.student_id, mentor_id: item.mentor_id})} className="btnorg">Update</button>
               ) :(
                <button onClick={() => setReschedule({id: item?.id})} className="btnred">Reschedule</button>
               )
    )

}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MentorMobileDataTable
