    import React from "react"
    import "./MobileStudentListTable.scss"
    import { toast } from "react-toastify"
    import axios from "axios"
    import DeactivatePost from "components/employer/DeactivatePost"
    import { Link } from "react-router-dom"
import ActionDropDown from "../ActionDropDown"

    const MobileStudentListTable = ({ columns, data ,isStudentList = false, refresh, setRefresh}) => {
    // console.log(data,"Educatorstudenttable")
    
    return (
        <>
      {data.map((item) => (
        <div className="mobileDataTable mt-4" key={item.id}>
          {
            isStudentList ? (
            <div className="data_container">
              <div className="table_data">
                <p>Date Created: </p>
                <span>{item.date}</span>
              </div>
              <div className="table_data">
                <p>List Name: </p>
                <span className="project_name">
                <Link to={`/educator/student-list/${item?.id}`}>
                  {item.listName}
                </Link>
                </span>
              </div>
              <div className="table_data">
                <p>Students: </p>
                <span className="Total_enrolled">{item.students}</span>
              </div>
              {/* <div className="table_data">
                <p>Action</p>
                <span className="Total_enrolled">
                  <ActionDropDown data={data} refresh={refresh} setRefresh={setRefresh}/>  
                </span>
              </div> */}

            </div>
            ) : 
            (
          <div className="data_container">
            <div className="table_data">
              <p>Date Posted: </p>
              <span>{item.date}</span>
            </div>
            <div className="table_data">
              <p>Student Name: </p>
              <span className="project_name">{item.name}</span>
            </div>
            <div className="table_data">
              <p>Course: </p>
              <span className="Total_enrolled">{item.course}</span>
            </div>
            <div className="table_data">
              <p>Specialization: </p>
              <span className="Total_enrolled">{item.specialization}</span>
            </div>
            <div className="table_data">
              <p>Semester: </p>
              <span className="Total_enrolled">{item.semester}</span>
            </div>
            <div className="table_data">
              <p>Email Id: </p>
              <span className="Total_enrolled item_email_wrap">{item.email}</span>
            </div>
          </div>

            )
          }


        </div>
      ))}
    </>
    )
    }

    export default MobileStudentListTable
