import CTASuccess from "components/formComponents/CTA_Success";
import useRelativeTime from "hooks/useRelativeTime";
import React from "react";
import { Link } from "react-router-dom";
import "../../formComponents/post/card.scss";

const ShortlistProjectCard = ({
  item,
  data,
  post,
  clickHandler = () => {},
  method = "internship",
  status = "",
}) => {
  // console.log("method", method);
  const items = data?.internship;
  // console.log(items?.branch?.id);
  // console.log("stu data", data);
  // console.log("stu data...", item);
  const time = useRelativeTime(item?.createdAt);

  const skills = [];

  // const tab = {
  //   0: "Enrolled",
  //   2: "Project Submitted",
  //   4: "Shortlisted",
  //   6: "Hired",
  //   7: "Rejected",
  // };
  
  const statusMap = {
    0: ["Enrolled", "bg-warning"],
    1: ["Left", "bg-info"],
    2: ["Project Submitted", "bg-success"],
    3: ["Submit Project", "bg-warning"],
    7: ["Rejected", "bg-danger"],
    4: ["Shortlisted", "bg-success"],
    6: ["Hired", "bg-success"],
  };

  //  // console.log(data[0].status,"f1234")
  return (
    <>
      {/* {data?.map((item) => {
        return ( */}
          <div className="card project_card p-3 p-md-4">
            <div className="strip" style={{backgroundColor: " #5340a9"}}>
              {/* <p>Project</p> */}
            </div>
            <div className="card__header">
              <div className="card__header__image d-flex justify-content-between align-items-center">
                <img
                  src={
                    item?.[method]?.project_global_fkey?.employer_account
                      ?.company_logo || item?.[method]?.branch?.company_logo
                  }
                  alt="company logo"
                  loading="lazy"
                  className="bg-primary comp-logo-shortlist-project"
                />
                <h6 className="text-muted d-lg-none">{time}</h6>
              </div>
              <Link
                className="card__header__info"
                to={`${method}/${item?.project_id}`}
              >
                <h5 className="m-0 mb-1">{item?.[method]?.title}</h5>
                <h6 className="text-muted address mb-1 flex gap-2">
                { item?.[method]?.location !== null ?  item?.[method]?.location?.city_name + ","+ item?.[method]?.location?.state?.state_name : "Remote/virtual" }
                  {/* {item?.[method]?.location?.city_name},
                  {item?.[method]?.location?.state?.state_name} */}
                  <span className="divide"></span>
                  <p className="m-0">
                    {/* Duration:<b> 3-4/Weeks</b> */}
                    Duration:{" "}
                    <b>
                      {item?.[method]?.duration_from} -{" "}
                      {item?.[method]?.duration_to} /Weeks
                    </b>
                  </p>
                </h6>
              </Link>

              <div className="card__header__cta">
                {/* <div style={{color: "#6C758E", fontSize: "18px"}}>Status</div> */}
                <div
                  // className="shortlist_btn"
                  className={`shortlist_btn ${statusMap[item?.status]?.[1]}`}
                  onClick={() =>
                    clickHandler(post?.id, post?.screening_questions)
                  }
                >
                  {statusMap[item?.status]?.[0]}
                </div>
                {/* <CTASuccess
                  value={tab[item?.status]}
                  className="btn_style"
                  onClick={() =>
                    clickHandler(post?.id, post?.screening_questions)
                  }
                /> */}
                <h6 className="text-muted d-none d-lg-block">{time}</h6>
              </div>
            </div>
            <div className="card__body__info__skills d-flex gap-1 gap-lg-3">
              {skills?.map((skill) => (
                <span
                  className="py-1 px-3 p-lgy-2 px-lg-4 d-flex justify-content-center align-items-center"
                  key={skill?.id}
                >
                  {skill?.name}
                </span>
              ))}
            </div>
            <h6 className="text-muted description">
              {item?.[method]?.description.slice(0, 100)}
            </h6>

            <div className="card__body">
              <p>
                {item?.[method]?.goal?.length > 100
                  ? item?.[method]?.goal?.slice(0, 100) + "..."
                  : item?.[method]?.goal}
              </p>

              <CTASuccess
                value={statusMap[item?.status]?.[0]}
                onClick={() =>
                  clickHandler(post?.id, post?.screening_questions)
                }
                className="mobile-cta"
              />
            </div>
          </div>
        {/* );
      })} */}
    </>
  );
};

export default ShortlistProjectCard;
