import React from 'react'
import { RiShareBoxLine } from 'react-icons/ri'
import { Link, NavLink } from 'react-router-dom'
import "./rightcard.scss";

const LinkedProjectCard = ({project, type}) => {
    // console.log("projectt",project)

    const truncatedName = project?.project_title.length > 25 ? project.project_title.slice(0, 25) + '...' : project?.project_title;
  return (
    <div className='suggestion-card'>
        <div className="card-head">
            <h3> {"Project Linked With This "} <span>{type} !!</span></h3>
        </div>
        <div className="card-main d-flex flex-column gap-2">

                        {/* <NavLink to={user?.role === 4 ? `/student/explore/project/${item?.id}` : `/project/${item?.id}`}> */}
                            <div className='comp-items d-flex justify-content-between'>
                                <h4>{project?.project_title}</h4>
                                {/* <RiShareBoxLine size={20} color='#6149CD' className='link-icon' /> */}
                            </div>
                        {/* </NavLink> */}

        </div>
        <div className='start-now' >
            <Link to={`/project/${project?.project_title.replace(/[\s#]+/g, '-')}/${project?.id}`}>
                Start Now
            </Link>
        </div>
    </div>
  )
}

export default LinkedProjectCard