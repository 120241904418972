import axios from 'axios'
import Loader from 'components/employer/Loader'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import "../settings.scss"

const TransectionAndHistory = () => {

  const user = useSelector((state) => state.auth.user);
  // console.log("user", user)

  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(()=> {

    

    async function getTransactions() {

      try {
        setLoading(true);
        const transactions = await axios.post(`/v1/mentor/getAllTransactions`, {
          id: user?.id
        });
  
        // console.log("transactions", transactions)
        setAllTransactions(transactions?.data?.data)

        setLoading(false);

      } catch (error) {
        setLoading(false);
      }

      
    }

    getTransactions();

    

  }, []);

  // console.log("set transaction", allTransactions);

  allTransactions.sort((a, b) => {
    const dateA = new Date(a.transaction.createdAt);
    const dateB = new Date(b.transaction.createdAt);
  
    // Compare dates
    return dateB - dateA;
  });

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return dateObject.toLocaleDateString('en-US', options);
  };

  if(loading) return <Loader />;
  return (
    <div className="mentor-setting ">
    <div className="container-lg container-fluid">
         
          <div>
            <div className="tab-pane" id="pills-transactionHistory" role="tabpanel" aria-labelledby="pills-transactionHistory-tab">

            {
              (allTransactions?.length !== 0) ? (
                
                <div className="student-available-table">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Student Name</th>
                        <th scope="col">Date Purchased</th>
                        <th scope="col">Paid Amount</th>
                        <th scope="col">Pending Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                {
                  allTransactions && allTransactions.map((transaction, i)=> {
                    return (
                      <tr key={i}>
                        <td >{transaction?.student?.first_name + " " + transaction?.student?.last_name} </td>
                        <td>{formatDate(transaction?.transaction?.createdAt)}</td>
                        <td>{transaction?.transaction?.amount}</td>
                        <td>{transaction?.mentor_charges - transaction?.transaction?.amount}</td>
                      </tr>
                    );

                  })
                }
                  
                    </tbody>
                  </table>
                </div>
              </div>
              ) : (
              
                      <div className="transcation-historyEmpty">
                        <h3>No transaction so far.</h3>
                        <p>All your transactions will be visible here in this tab.</p>
                      </div>
              )

            }



              {/* <div className="transaction-entry">
                        <h3>Rahul Narang</h3>

                        <div className="table-accordian">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  October 2022
                                </button>
                              </h2>
                              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="mentor-table">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">TDS</th>
                                            <th scope="col">Credited Amount</th>
                                            <th scope="col">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button className='gr'>Not Credited</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  October 2022
                                </button>
                              </h2>
                              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="mentor-table">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">TDS</th>
                                            <th scope="col">Credited Amount</th>
                                            <th scope="col">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button className='gr'>Not Credited</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div></div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  October 2022
                                </button>
                              </h2>
                              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="mentor-table">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">TDS</th>
                                            <th scope="col">Credited Amount</th>
                                            <th scope="col">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button className='gr'>Not Credited</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                          <tr>
                                            <td >02 Oct 2022</td>
                                            <td className='des-color'>Plan 1 - Mentoring Session</td>
                                            <td>₹ 1000</td>
                                            <td>₹ 100</td>
                                            <td>₹ 900</td>
                                            <td><button>Completed</button></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div></div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div> */}
            </div >
          </div>
              </div>
      </div>
  )
}

export default TransectionAndHistory