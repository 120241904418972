import Modal from "./Modal";
import WeekModalBody from "./modals/WeekModalBody";
import WeekDetail from "./WeekDetail";
import FinalReportModalBody from "./modals/FinalReportModalBody";

const WeekDetails = ({
  data,
  enrollment_id,
  project_id,
  setFetchData,
  activeListItem = 0,
  setActiveListItem,
}) => {
  // console.log({ data });
  const handleClick = (index) => {
    setActiveListItem(index);
  };

  const handleCancel = () => {
    setActiveListItem(0);
  };

  const handleShowModal = (bool) => {
    if (!bool) setActiveListItem(0);
  };

  // console.log(
  //   data?.final_submission_report?.report,
  //   "data?.final_submission_report?.report"
  // );

  // console.log(data?.final_submission_report?.report);
  // console.log(data, "weekly dataaaaa");
  return (
    <div>
      {activeListItem && (data?.status === 3 || data?.status === 0) ? (
        <Modal
          show={!!activeListItem}
          setShow={handleShowModal}
          title={
            activeListItem < 0
              ? "Submit Final Report"
              : `Upload Report For Week ${activeListItem}`
          }
        >
          {activeListItem < 0 ? (
            <FinalReportModalBody
              onCancel={handleCancel}
              project_id={project_id}
              enrollment_id={enrollment_id}
              setFetchData={setFetchData}
              enrollment_createdAt={data?.createdAt}
              project_date={data?.project?.duration_to}
              project={data?.project}
            />
          ) : (
            <WeekModalBody
              onCancel={handleCancel}
              week={activeListItem}
              project_id={project_id}
              enrollment_id={enrollment_id}
              setFetchData={setFetchData}
              enrollment_createdAt={data?.createdAt}
              project_date={data?.project?.duration_to}
            />
          )}
        </Modal>
      ) : (
        <></>
      )}

      {data?.weekly_submissions?.map((item) => (
        <WeekDetail
          key={item?.week}
          report={item?.report}
          index={item?.week}
          handleClick={handleClick}
          title={`Week ${item?.week}`}
          description={item?.description}
          text="Upload Report"
          midTitle="submited"
        />
      ))}
      <WeekDetail
        report={data?.final_submission_report?.report}
        index={-1}
        handleClick={handleClick}
        description={data?.final_submission_report?.report ? "view report" : ""}
        title="Project Report"
        text="Upload"
      />
      <WeekDetail
        report={data?.final_submission_report?.presentation}
        index={-1}
        handleClick={handleClick}
        description={
          data?.final_submission_report?.presentation ? "view report" : ""
        }
        title="Project Presentation"
        text="Upload"
      />
      <WeekDetail
        report={data?.final_submission_report?.other}
        index={-1}
        handleClick={handleClick}
        description={data?.final_submission_report?.other ? "view report" : ""}
        title="Any Other Document"
        text="Upload"
      />
      {/* <WeekDetail
        report={data?.final_submission_report?.link}
        index={-1}
        handleClick={handleClick}
        title="Link"
      /> */}

      {/* <WeekDetail item={} handleClick={handleClick} /> */}
    </div>
  );
};
export default WeekDetails;
