import React from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "./style.css";
import { PageWithText } from "./Pagination";

const Datatable = ({
  columns,
  data,
  _pageSize = 10,
  _pageIndex = 0,
  getData = undefined,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: _pageSize },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      {data?.length !== 0 ? (
        <div className="datatable">
          <table
            {...getTableProps()}
            style={{
              width: "100%",
            }}
          >
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        width: column.width,
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <div
                        className={`d-flex align-items-center justify-content-${column.textalign}`}
                      >
                        <span>{column.render("Header")}</span>
                        <span className="ms-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fa-solid fa-arrow-down"></i>
                            ) : (
                              <i className="fa-solid fa-arrow-up"></i>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width,
                              // padding: "20px 0",
                              textAlign:
                                cell.column.textalign === "center"
                                  ? "center"
                                  : "left",
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-between my-4">
            <div className="d-flex flex-wrap align-items-center justify-content-start">
              {pageIndex !== 0 && (
                <PageWithText
                  onClick={() => {
                    if (getData) {
                      getData(0, 0, pageSize);
                    }
                    gotoPage(0);
                  }}
                  color="text-default"
                >
                  First
                </PageWithText>
              )}
              {canPreviousPage && (
                <PageWithText
                  onClick={() => {
                    {
                      if (getData) {
                        getData(pageIndex - 1, pageIndex - 1, pageSize);
                        gotoPage(pageIndex - 1);
                      } else {
                        previousPage();
                      }
                    }
                  }}
                  color="text-default"
                >
                  Previous
                </PageWithText>
              )}
              {canNextPage && (
                <PageWithText
                  onClick={() => {
                    {
                      if (getData) {
                        getData(pageIndex + 1, pageIndex + 1, pageSize);
                        gotoPage(pageIndex + 1);
                      } else {
                        nextPage();
                      }
                    }
                  }}
                  disabled={!canNextPage}
                  color="text-default"
                >
                  Next
                </PageWithText>
              )}
              {pageIndex !== pageCount - 1 && (
                <PageWithText
                  onClick={() => {
                    if (getData) {
                      getData(pageCount - 1, pageCount - 1, pageSize);
                    }
                    gotoPage(pageCount - 1);
                  }}
                  disabled={!canNextPage}
                  color="text-default"
                >
                  Last
                </PageWithText>
              )}
            </div>

            <span>
              Page{" "}
              <b>
                {pageIndex + 1} of {pageOptions.length}
              </b>
            </span>

            <select
              className="form-select form-select-sm text-sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                if (getData) {
                  getData(pageIndex, pageIndex, Number(e.target.value), true);
                }
              }}
              style={{
                width: "120px",
              }}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </>
  );
};

export default Datatable;
