import { Fragment } from "react";

const defaultValues = [
  {
    id: "duration_from",
    label: "From",
    required: true,
    options: {
      min: 1,
      max: 53,
    },
  },
  {
    id: "duration_to",
    label: "To",
    required: true,
    options: {
      min: 1,
      max: 53,
    },
  },
  {
    id: "duration",
    label: "To",
    required: true,
    options: {
      min: 1,
      max: 53,
    },
  },
];

const FromTo = ({
  items = defaultValues,
  register,
  scale = "weeks",
  label = "",
  secondaryLabel = "",
  isTo = true,
  max = 0,
  min = 0,
  component = <></>,
  isCounter = true,
  getValues,
  setValue,
  step = 1,
}) => {
  const Items = isTo ? items.slice(0, 2) : items.slice(-1);
  // console.log(getValues, "getValues");
  const increment = (id, step) => {
    const value = Number.parseInt(getValues(id) || 0);
    setValue(id, value + step);
  };

  const decrement = (id, step) => {
    const value = Number.parseInt(getValues(id) || 2 * step);
    if (value > step) {
      setValue(id, value - step);
    }
  };
  return (
    <div className="form-group mb-3">
      <label htmlFor={Items[0].id}>
        {label}
        {items[0].required && <span>*</span>}
        <small>{secondaryLabel}</small>
      </label>
      <div className="d-flex align-items-center text-center">
        {Items?.map((item) => (
          <Fragment key={item.id}>
            <label
              className={`${item.label === "To" ? "mx-2" : ""}`}
              htmlFor={item.id}
            >
              {isTo ? item.label : <></>}
              {isTo && item.required ? <span>*</span> : <></>}
            </label>
            {isCounter ? (
              <i
                className="mt-2 mx-2 fa fa-minus"
                onClick={() => decrement(item.id, step)}
              ></i>
            ) : (
              <></>
            )}
            <input
              className="form-control mt-2"
              style={{ maxWidth: "120px" }}
              type="number"
              name={item.id}
              id={item.id}
              placeholder={scale && `(${scale})`}
              {...register(item.id, {
                required: item.required,
                ...item.options,
                min: min || item?.min,
                max: max || item?.max,
              })}
              max={max || item.max}
              min={min || item.min}
              required={item?.required}
            />
            {isCounter ? (
              <i
                className="mt-2 mx-2 fa fa-plus"
                onClick={() => increment(item.id, step)}
              ></i>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
        {component}
      </div>
    </div>
  );
};

export default FromTo;
