import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup"
import NumberField from "components/formComponents/NumberField"
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
// import TextField from "components/formComponents/TextField"

const AdminMentorsFilter = ({ dispatch,state}) => {
  const [skills, setSkills] = useState(() => [])
  const [qualification, setQualification] = useState(() => [])
  const [industry_sector, setIndustrySector] = useState(() => [])
  const [location, setLocation] = useState(() => [])
  const[min, setMin]=useState("")
  const[max,setMax]=useState("")
  // console.log("value",min,max)
  // console.log("location//",location)
  const [isPopupOpen, updatePopup] = useState(() => false);


  const { handleSubmit, register, setValue } = useForm()

  const deleteSkill = (item) => {
    setSkills((prev) => prev.filter((skill) => skill.name !== item))
  }

  const deleteQualification = (item) => {
    setQualification((prev) =>
      prev.filter((skill) => skill.course_name !== item)
    )
  }

  const deleteIndustrySector = (item) => {
    setIndustrySector((prev) => prev.filter((skill) => skill.name !== item))
  }
  const deleteLocation = (item) => {
    setLocation((prev) => prev.filter((skill) => skill.name !== item))
  }

  const onSubmit = () => {
    // const required_skills = skills.map((item) => item?.id)
    // const course_preferred = qualification.map((item) => item?.id)
    const industry = industry_sector.map((item) => item?.id)
    // const location=location.map((item)=>item?.id)
    

    // console.log("industry filter",industry)
    // // console.log("location===",location)

    dispatch({
      type: "change_value",
      payload: {industry_sector: industry,charge1:parseInt(min),charge2:parseInt(max),

      },
    })
    
  }

  const updateLocation = (_name, value) => {
    // console.log("location1..",value)
    // console.log("location2..",_name)
    // const id = value?.id || ""; 
    // const id = value || ""; 

  const id = typeof value === 'object' ? value?.id : value;
  // console.log("iddd", id);
   
 
    dispatch({
      type: "change_value",
      payload: { location: value},
    });

  }


  const onReset = () => {
    setSkills(() => [])
    setQualification(() => [])
    setIndustrySector(() => [])
    dispatch({ type: "reset" })
  }

  return (
    <div className="explore_filterBox ">
      <form>
        {/* <ChipsFieldWithPopup
          register={register}
          label="Skills"
          id="required_skills"
          items={skills}
          setItems={setSkills}
          deleteItem={deleteSkill}
          setValue={setValue}
          path="/v1/filter/skills"
          placeholder="Search skills"
          reverse={true}
        /> */}
        <ChipsFieldWithPopup
          register={register}
          label="Industry Sector"
          id="industry_sector"
          items={industry_sector}
          setItems={setIndustrySector}
          deleteItem={deleteIndustrySector}
          setValue={setValue}
          path="/v1/filter/industry_sector"
          placeholder="Search Industry Sector"
          key_name="industry_name"
          reverse={true}
        />
        {/* <ChipsFieldWithPopup
          register={register}
          label="Highest Qualification"
          id="qualification"
          items={qualification}
          setItems={setQualification}
          deleteItem={deleteQualification}
          setValue={setValue}
          path="/v1/filter/courses"
          placeholder="Choose Qualification"
          key_name="course_name"
          reverse={true}
        /> */}
        {/* <ChipsFieldWithPopup
          register={register}
          label="Location"
          id="location"
          items={location}
          setItems={setLocation}
          deleteItem={deleteLocation}
          setValue={setValue}
          path="/v1/location"
          placeholder="Choose Location"
          key_name="location_name"
          reverse={true}
        /> */}
        <TextFieldWithPopup
            required={true}
            id="location"
            isPopupOpen={isPopupOpen}
            updatePopup={updatePopup}
            path="/v1/location/city"
            key_name="location"
            setValue={updateLocation}
            withId={state.city}
            placeholder="Noida, U.P, Iindia "
            className="custom-textfield"
            
          />
           <style>
    {`
      .custom-textfield {
        border-radius: 20px;
        /* Add other styles as needed */
      }
    `}
  </style>
        
        <div>
          <div className="form-group">
            <label>Per Session Price</label>
            <div className="d-flex align-items-center">
              <NumberField
                label=""
                required={false}
                id="start-price"
                placeholder={1000}
                register={register}
                min={0}
                max={10000}
                style={{
                  flex: ".45",
                }}
                onChange={(e)=>setMin(e.target.value)}
              />
              <span className="mb-3 d-inline-block px-3">{"to"}</span>
              <NumberField
                label=""
                required={false}
                id="end-price"
                placeholder={1000}
                register={register}
                min={0}
                max={10000}
                style={{
                  flex: ".45",
                }}
                onChange={(e)=>setMax(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="cta">
          <button onClick={onReset} type="reset" className="clear">
            Clear
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="apply"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  )
}

export default AdminMentorsFilter
