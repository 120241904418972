import React, { useEffect, useState } from 'react'
import "./mentorDetailpopup.scss"
import mentorImg from "../../../../../images/image 47.png";
import { MdCurrencyRupee } from "react-icons/md";
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from 'components/employer/Loader';
import NewLoader from 'components/loader/NewLoader';
import { bookSession } from 'services/factories/razorpay';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';


const MentorDetailPopup = ({selectedMentor, onClose }) => {

    const [enrolledProjects, setEnrolledProjects] = useState([]);
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState("");
    const [selectedDate, setSelectedDate] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [timeZones, setTimeZones] = useState();
    const [mentor, setMentor] = useState(() => []);
    const [coupon_name, setCoupon_name] = useState();
    const [loading, setLoading] = useState(false);
    const [applied, setApplied] = useState(false);
    const minDate = new Date().toISOString().split("T")[0]; // get current date
    const user = useSelector((state) => state?.auth?.user);
    const navigate = useNavigate()

    const [mentorCharges, setMentorCharges] = useState({
      basePrice: (selectedMentor?.mentor_charges - (selectedMentor?.mentor_charges * 0.18 ) ) || 0,
      discount: 0,
      gst: selectedMentor?.mentor_charges * 0.18 || 0,
      amountPayable: parseFloat(selectedMentor?.mentor_charges || 0)?.toFixed(2)
    })


    console.log("selectedmentor", selectedMentor);
    


      const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const dayOfWeek = daysOfWeek[date.getDay()]; // get the day of the week in string format
        setSelectedDate(date.toISOString().split("T")[0]);
        const selectedTimezones = selectedMentor.timings.find(
          (item) => item?.day === dayOfWeek.toLocaleUpperCase()
        );
        // // console.log(selectedTimezones);
        const timeslots = convertTimeSlotsToDateSlots(
          date,
          selectedTimezones?.duration
        );
        // // console.log(timeslots, "timeslots");
        setTimeZones(timeslots);
      };

      function convertTimeSlotsToDateSlots(date, timeSlots) {
        const dateObj = new Date(date);
        const dateSlots = {};
    
        timeSlots?.forEach((timeSlot) => {
          const [startTimeStr, endTimeStr] = timeSlot.split("-");
          const startTime = new Date(dateObj.toDateString() + " " + startTimeStr);
          const endTime = new Date(dateObj.toDateString() + " " + endTimeStr);
          const duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); // duration in minutes
    
          let slotStartTime = startTime;
          while (slotStartTime < endTime) {
            const slotEndTime = new Date(slotStartTime.getTime() + 30 * 60 * 1000); // add 30 minutes
            const slotKey = `${slotStartTime.getHours()}:${slotStartTime.getMinutes()}-${slotEndTime.getHours()}:${slotEndTime.getMinutes()}`;
            dateSlots[slotKey] = { startTime: slotStartTime, endTime: slotEndTime };
            slotStartTime = slotEndTime;
          }
        });
    
        return dateSlots;
      }

      const handleKeyClick = (key) => {
        setSelectedTime(key);
      };
    
      // useEffect(() => {
      //   fetchMentor();
      // }, []);

      useEffect(() => {
        fetch("/v1/project/enrolled")
          .then((response) => response.json())
          .then((data) => {
            // console.log(data, "enrolment");
            setEnrolledProjects(data?.data?.items);
          })
          .catch((error) =>  console.log(error));
      }, []);

      async function initiatePayment(e) {
        e.preventDefault();
        try {
          const session = await bookSession(
            {
              mentor_id: selectedMentor?.id,
              amount_rs: parseInt(mentorCharges?.amountPayable),
              start_time: timeZones[selectedTime]?.startTime,
              end_time: timeZones[selectedTime]?.endTime,
              enrollment_id: selectedEnrollmentId,
              coupon_name: applied ? coupon_name : null,
              user:  user
            },
            navigate("/student/mentor")
          );
          // console.log(session, "session");
          // if (session) {
          //   setShowModal(true);
          //   // navigate("/student/mentor");
          // }
    
          // console.log(session, "session");
        } catch (error) {
          console.log(error);
        }
      }


      const validateCoupon = async () => {
        try {
          if (!coupon_name) return toast.error("Please enter coupon code");
          setLoading(true);
          const { data } = await axios.get(
            `/v1/student/coupon-validate/${coupon_name}`
          );
    
          if (data?.error) toast.error(data?.message);
          else {

            const discountAmount = (selectedMentor.mentor_charges * data?.data?.discountPercentage) / 100;
            setMentorCharges((prev) => ({
              ...prev,
              discount: discountAmount,
              amountPayable: (selectedMentor?.mentor_charges - discountAmount)?.toFixed(2)
            }));
            setApplied(true);
            toast.success("Coupon code applied successfully")
          
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

      
  return (
    <div className='m_detail_wrapper'>
        <div className="m_detail_overlay" onClick={onClose}></div>
        <div className="m_detail_content">
            <div className="m_detail_header">
                <h4>Mentor Detail</h4>
                
                <div className="header_wrapper">
                    <img src={selectedMentor?.profile_photo || mentorImg} alt="mentor profile img"  />

                    <div className="m_details">
                        <h5>{selectedMentor?.first_name} {selectedMentor?.last_name}</h5>
                        <p> {selectedMentor?.industry_year_expreience ? parseInt(selectedMentor?.industry_year_expreience) : "-"} Yrs+ Experience in {selectedMentor?.industry?.industry_name || "-"}</p>

                        <Link to={`/student/mentor/${selectedMentor?.id}`} >
                          <div className="know_mentor_btn">
                              know About Your Mentor
                          </div>
                        </Link>
                    </div>
                </div>


                {/* Session details */}
                <div className="m_session_details">
                    <h4>Session Details</h4>

                    <div className="select_project">
                        <select
                            className="project_dropdown pointer custom_select"
                            value={selectedEnrollmentId}
                            onChange={(event) => setSelectedEnrollmentId(event.target.value)}
                        >
                            <option value="">Select a project</option>
                            {enrolledProjects?.map((project) => (
                            <option key={project.id} value={project.id} className="py-2">
                                {project?.project?.title?.length > 40 ? (
                                <span className="px-5">
                                    {project?.project?.title?.substring(0, 40)}...
                                </span>
                                ) : (
                                <span>{project?.project?.title}</span>
                                )}
                            </option>
                            ))}
                        </select>
                    </div>


                    <div className="select_date">
                        <h4>Choose Session Date & Slot</h4>
                        
                        <div className="date">
                        <input
                            className="m_date_input"
                            id="dateInput"
                            placeholder="dd-mm-yyyy"
                            value={selectedDate}
                            type="date"
                            min={minDate}
                            onChange={handleDateChange}
                            />
                            {selectedDate && (
                            <div className="time_slot_wrapper mt-2">
                                {!Object.keys(timeZones).length == 0 ? (
                                <>
                                    {Object.keys(timeZones).map((key) => {
                                    return (
                                        <div key={key} className={`time_slot ${selectedTime === key ? "selected_slot" : ""}`}>
                                            <span
                                                className="slot"
                                                onClick={() => handleKeyClick(key)}
                                            >
                                                {key}
                                            </span>
                                        </div>
                                    );
                                    })}
                                </>
                                ) : (
                                <p>Mentor is not available on this day</p>
                                )}
                            </div>
                            )}
                        </div>
                    </div>


                </div>

            </div>


            <div className="price_breakdown_wrapper">
              <h4>Price Breakdown:</h4>
              <div className="price_container">

                <div className="price_content_container">

                  <div className="discount_content">
                    <h5>Discount Coupon</h5>
                    <div className="discount_input">
                      <input type="text" placeholder='Enter Code' onChange={(e)=> setCoupon_name(e.target.value)} value={coupon_name}  /> 
                       {
                        applied ? 
                          <span onClick={() => {
                            setCoupon_name("");
                            setMentorCharges((prev)=> ({
                              ...prev,
                              discount: 0,
                              amountPayable: selectedMentor?.mentor_charges?.toFixed(2)
                            }));
                            setApplied(false);
                          }}>Cancel</span>
                        : 
                          <span onClick={validateCoupon}>Apply</span>

                         
                       } 
                    </div>
                  </div>

                  {
                    loading ?<NewLoader /> :
                      <div className="prices_">
                        
                        <div className="price">
                          <span>Base Price: </span>  <span> <MdCurrencyRupee /> {mentorCharges?.basePrice?.toFixed(2)}</span>
                        </div>

                        <div className="price">
                          <span>GST (18%): </span>  <span> <MdCurrencyRupee /> {mentorCharges?.gst?.toFixed(2)}</span>
                        </div>

                        <div className="price">
                          <span>Discount: </span>  <span>-<MdCurrencyRupee />{mentorCharges?.discount?.toFixed(2)}</span>
                        </div>

                        {/* <div className="price">
                          <span>CGST (9%): </span>  <span> <MdCurrencyRupee /> 450</span>
                        </div> */}
                      </div>
                  }

                  <div className="divider"></div>

                  <div className="final_amount">
                    <span>Amount Payable: </span> <span><MdCurrencyRupee />{mentorCharges?.amountPayable}</span>

                  </div>

                </div>

                <div className="payment_btn" >
                  {
                    selectedTime && selectedEnrollmentId &&
                     !Object.keys(timeZones).length == 0 ? (
                        <button onClick={(e)=> initiatePayment(e)} >
                          Continue to pay <span> <MdCurrencyRupee />{mentorCharges?.amountPayable}</span>
                        </button>

                     ) : (
                        <button >
                          please Select the Date, slot & Project
                        </button>
                     )
                  }

                </div>
              </div>

            </div>
        </div>

    </div>
  )
}

export default MentorDetailPopup