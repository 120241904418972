import React, { useState } from "react"
import Dropdown from "components/formComponents/post/Dropdown/Dropdown"
import { useForm } from "react-hook-form"
import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup"
// import TextField from "components/formComponents/TextField"
import "./exploreFilterBox.scss"

const ExploreFilterBox = () => {
  // console.log("state", state);

  const items =["Older First", "Newest First"] 
  // const [selected, setSelected] = useState(() => 0)

  // const updateSort = (index) => {
  //   if (Number.isNaN(index) || index < 0 || index > 2) return
  //   const order = ["id,asc", "id,desc", "stipend_to,desc"]
  //   dispatch({ type: "change_value", payload: { order: order[index] } })
  //   setSelected(index)
  // }
  // const [skills, setSkills] = useState(() => [])
  // const [qualification, setQualification] = useState(() => [])
  // const [industry_sector, setIndustrySector] = useState(() => [])
  

  // const { handleSubmit, register, setValue } = useForm()

  
  // const onSubmit = () => {
  //   const required_skills = skills.map((item) => item?.id)
  //   const course_preferred = qualification.map((item) => item?.id)
  //   const industry = industry_sector.map((item) => item?.id)

  //   dispatch({
  //     type: "change_value",
  //     payload: { required_skills, course_preferred, industry_sector: industry },
  //   })
  // }

  // const onReset = () => {
  //   setSkills(() => [])
  //   setQualification(() => [])
  //   setIndustrySector(() => [])
  //   dispatch({ type: "reset" })
  // }

  return (
    <div className="explore_filterBox h-100 ">
      <form className="moboFilter" style={{display: 'flex',
    /* align-items: center; */
    flexDirection: 'column',
    justifyContent: "space-between",
    height:" 95%"}}>
        <Dropdown
          items={items}
          // setItem={updateSort}
          label="Sort by"
          // checked={selected}
        />

        <div>
          <p className="bg-primary p-2 ">All</p>
        </div>
        
        <div className="cta">
          <button
            // onClick={onReset}
            type="reset" className="clear">
            Clear
          </button>
          <button
            // onClick={handleSubmit(onSubmit)}
            type="submit"
            className="apply"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  )
}

export default ExploreFilterBox
