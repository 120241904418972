import axios from "axios";
import { useEffect, useState, useMemo, useCallback } from "react";

const useFetchFilters = ()=> {

    const [industries, setIndustries] = useState([])
    const [courses, setCourses] = useState([])
    const [company, setCompanies] = useState([])
    const [locations, setLocations] = useState([])
    const [skills, setSkills] = useState([])
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [suggestCompanies, setSuggestCompanies] = useState([]);


    const [city, setCity] = useState("")
    // fetch filter columns
    useEffect(() => {


      const getUserCurrentLocation = async () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude)
              setLongitude(position.coords.longitude)
  
              getCity(position.coords.latitude, position.coords.longitude)
            },
            (error) => {
              // console.log('Error getting geolocation:', error);
            }
          )
        } else {
          // console.log('Geolocation is not supported by this browser.');
        }
      }
  
      const getCity = async (lati, longi) => {
        // console.log("position1", lati, longi);
        try {
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lati}&lon=${longi}`
          const response = await axios.get(apiUrl)
          // console.log("position result", response);
          // Extract city name from the response
          const cityName =
            response?.data?.address?.city || response?.data?.address?.town
          // console.log("rescccccc", cityName)
          setCity(cityName)
  
          const comp = await axios.post(
            "/v1/filter/company/company-by-location",
            {
              location: cityName,
            }
          )
  
          // console.log("comppppp", comp?.data?.data);
          setSuggestCompanies(comp?.data?.data)
        } catch (error) {
          // console.log("error", error)
        }
      }
  
      const fetchIndustries = async () => {
        const result = await axios.get(
          "/v1/filter/industry_sector/top-industries"
        )
  
        const data = result?.data?.data?.map((item) => ({
          id: item?.industry?.id,
          industry_name: item?.industry?.industry_name.toLowerCase(),
        }))
  
        setIndustries(data)
      }
  
      const fetchCourses = async () => {
        const result = await axios.get("/v1/filter/courses/top-courses")
  
        const data = result?.data?.data.map(course => ({
          ...course,
          course_name: course.course_name.toLowerCase()
        }))
  
        setCourses(data)
      }
  
      const fetchCompanies = async () => {
        const result = await axios.get("/v1/filter/company/top-companies")
  
        // console.log("company", result);
        const data = result?.data?.data?.map((item) => ({
          id: item?.id,
          company_name: item?.company_name.toLowerCase(),
        }))
  
        setCompanies(data)
      }
  
      const fetchLocations = async () => {
        const result = await axios.get("/v1/location/top-locations")
  
        // console.log("location", result);
        const data = [...new Set(result?.data?.data?.map((item) => item?.city))]
          .slice(0, 5)
          .map((item) => ({
            city: item.toLowerCase(),
          }))
  
        // console.log("afterslice", data)
  
        setLocations(data)
      }

      const fetchSkills = async () => {
        const result = await axios.get("/v1/filter/skills/top-skills")
        // console.log("result",result)
    
        const data = result?.data?.data?.map(skill => ({
          ...skill,
          skill_name: skill?.name.toLowerCase()
        }))
    
        setSkills(data)
      }
      
       getUserCurrentLocation()
      // getCity();
       fetchIndustries()
       fetchCourses()
       fetchCompanies()
       fetchLocations()
       fetchSkills()

      //  updateURL()
      
    }, [])
    

    return {
        industries, courses, company, locations,skills,
        setIndustries, setCourses, setCompanies, setLocations,
        suggestCompanies, setSuggestCompanies,
        city
    }

}

export default useFetchFilters;
