import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import img from "./Group.svg";
import styles from "./exitproject.module.scss";
const ExitProjectModal = ({ onCancel, id }) => {
  const navigate = useNavigate();
  const handleExit = () => {
    axios.delete(`/v1/enrollments/project/${id}`).then((res) => {
      // console.log(res);
      toast.success("Project exited successfully");
      navigate("/student/my-applications");
      onCancel();
    });
  };
  return (
    <div className={styles.modal_container}>
      <div className={styles.modal} role="dialog">
        <div className={styles.modal_content} role="document">
          <div className={styles.modal_header}>
            <img src={img} alt="Image" />
            <h5 className={styles.modal_title} id="confirmModalLabel">
              {/* {title} */}
              Are you sure you want to exit project?
            </h5>
          </div>

          <div className={styles.modal_footer}>
            <button
              type="button"
              className={styles.close_btn}
              data-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className={styles.delete_btn}
              // onClick={navigate("/student/my-applications")}
              onClick={handleExit}
            >
              Exit
            </button>
          </div>

          <p className={styles.text_title}>This action can't be undone.</p>
        </div>
      </div>
    </div>
  );
};

export default ExitProjectModal;
