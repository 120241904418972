import axios from "axios";
import Freelancerimg from "images/Freelancer.png";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CollegesCards from "view/dashboard/employer/components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "view/dashboard/employer/components/collegesCards/collegesDetailCards/CollegesDetailCards";
import ListView from "view/dashboard/employer/components/list/ListView";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";
import SectionSideFilter from "../../../id/sections/SectionSideFilter";
import "../suggestedCard.scss";
const Details = ({
  state,
  enrollments = [],
  post = {},
  handleStatusChange,
}) => {
  const [show, setShow] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [educators, setEducators] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [state1, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });

  const style1 = {
    display: "none",
  };
  const style2 = {
    display: "none",
  };
  const style_i = {
    display: "none",
  };
  useEffect(() => {
    const filters = Object.keys(state1).reduce((acc, key) => {
      if (state1[key]) {
        acc[key] = state1[key];
      }
      return acc;
    }, {});
    const fetchInstitutions = async () => {
      const res = await axios.get(
        `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`,
        {
          params: filters,
        }
      );
      // console.log(res, "res");
      setInstitutions(res?.data?.data);
    };
    fetchInstitutions();
  }, [state1]);

  const [educatorDataDetails, setEducatorDataDetails] = useState();
  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/institution/single/${
          institutions[educators].sender
            ? institutions[educators].sender_id
            : institutions[educators].receiver_id
        }`
      );
      // console.log(res, "educatordata");
      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // // console.log("function 2 working")
  }, [educators, institutions]);

  // // console.log(educatorDataDetails, "educatorDataDetails");

  const [search, setSearch] = useState("");

  return (
    <>
      {institutions?.length ? (
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="content-tab">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="searchbox">
                    <label>
                      {" "}
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="Search candidate"
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {/* {institutions?.map((institution, index) => {
                      // console.log(institution, "<CollegesCards  />");
                      const collegesFunction = () => {
                        setEducators(index);
                        setShow(true);
                        // console.log("function working");
                      };
                      return (
                        <div onClick={collegesFunction}>
                          <CollegesCards institution={institution?.sender} />
                        </div>
                      );
                    })} */}
                    {institutions
                      ?.filter((item) => {
                        return (
                          item?.sender?.first_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.receiver?.first_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.sender?.last_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.receiver?.last_name
                            .toLowerCase()
                            .includes(search) ||
                          item?.sender?.institute?.institution_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.receiver?.institute?.institution_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      })
                      ?.map((institution, index) => {
                        const collegesFunction = () => {
                          setEducators(index);
                          setShow(true);
                          // console.log("function working");
                        };
                        return (
                          <div onClick={collegesFunction}>
                            <CollegesCards
                              institution={
                                institution.sender || institution.receiver
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                  <CollegesDetailCards
                    educatorDataDetails={educatorDataDetails}
                    style1={style1}
                    style2={style2}
                    style_i={style_i}
                    setPage={setEducators}
                    currentPage={educators}
                    pageLength={institutions?.length}
                  />
                </div>
                {show && (
                  <div className="position-fixed w-100 mobo_main_college_datail d-flex d-md-none ">
                    <i
                      class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                      aria-hidden="true"
                      onClick={() => setShow(false)}
                    >
                      {" "}
                      Back
                    </i>
                    <CollegesDetailCards
                      educatorDataDetails={educatorDataDetails}
                      style1={style1}
                      style2={style2}
                      style_i={style_i}
                      setPage={setEducators}
                      currentPage={educators}
                      pageLength={institutions?.length}
                    />
                  </div>
                )}
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
                  <SectionSideFilter dispatch={dispatch} employer={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="new-post">
                <div className="flex">
                  <div>
                    <h3>No Data Found</h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            ></div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
          <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
            <SectionSideFilter dispatch={dispatch} employer={false} />
          </div>
        </>
      )}
    </>
  );
};

const InvitationAccepted = ({ state, ...props }) =>
  state ? <Details {...props} /> : <List {...props} />;
const style1 = {
  display: "none",
};
const style2 = {
  display: "none",
};
const style_i = {
  display: "none",
};

const List = ({ state, enrollments = [], post = {}, handleStatusChange }) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInstitutions = async () => {
      const res = await axios.get(
        `/v1/invitations/get_invites?status[]=1&receiver_id[]=${user.id}&sender_id=${user.id}`
      );

      setInstitutions(res?.data?.data);
    };
    fetchInstitutions();
  }, []);

  return (
    <>
      {institutions?.length ? (
        <div className="d-flex gap-4">
          <ListView
            style1={style1}
            style2={style2}
            style_i={style_i}
            data={institutions}
            type={"sender"}
          />
          <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-xl-flex">
            <div className="row ">
              <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="job-filter">
                  <label htmlFor="city">City</label>
                  <input
                    type="Search"
                    id="city"
                    list="search-data"
                    placeholder="Search City"
                  />
                  <datalist id="search-data">
                    <option value="Delhi"></option>
                    <option value="Noida"></option>
                  </datalist>
                  <label htmlFor="skill">Skills</label>
                  <input
                    type="Search"
                    id="skill"
                    list="skills-data"
                    placeholder="Search Skills"
                  />
                  <datalist id="skills-data">
                    <option value="html"></option>
                    <option value="css"></option>
                  </datalist>

                  <div className="spanbox">
                    <span>
                      IT <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      IT Students <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      React <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      B.Tech <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                  <label htmlFor="qualification">Qualification</label>
                  <input
                    type="text"
                    id="qualification"
                    placeholder="Search qualification"
                  />
                  <button className="clear">Clear</button>
                  <button>Apply</button>
                </div>
              </div>
              <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                <div
                  className="new-post"
                  onClick={() => navigate("/employer/internship/add")}
                >
                  <div className="flex">
                    <div>
                      <h3>Post New </h3>
                      <h4>Internship</h4>
                    </div>
                    <div>
                      {" "}
                      <i className="fa-solid fa-right-long"></i>
                    </div>
                  </div>
                  <img src={Freelancerimg} alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>No Available Institution Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvitationAccepted;
