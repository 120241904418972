
const NumberField = ({ label = "", id, placeholder = "", required = false, register, options = {}, onChange = () => { }, max = 859999, min = 110000, isPinCode = false, secondaryTitle = "", Component = <></>, isCounter = false, getValues, setValue }) => {

  const increment = () => {
    const value = Number.parseInt(getValues(id) || 0);
    setValue(id, value + 1);
  };

  const decrement = () => {
    const value = Number.parseInt(getValues(id) || 2);
    if (value > 1) {
      setValue(id, value - 1);
    }
  };

  return (
    <div className="form-group mb-3">
      {label ? <label htmlFor={id} className="">
        {label}
        {required && <span>*</span>}
        {secondaryTitle && <small className="form-text text-muted">{secondaryTitle}</small>}
      </label> : <></>}

      <div className="d-flex justify-content-start align-items-center counter">
        {isCounter ? <i className="mt-2 mx-2 fa fa-minus" onClick={decrement}></i> : <></>}
        <input className={`form-control ${isCounter ? "text-center" : ""} ${label ? "mt-2" : ""}`} type="number" name={id} id={id} placeholder={placeholder} {...register(id, { required, ...options })} onChange={onChange} max={max} min={min} onInvalidCapture={(e) => e?.target?.setCustomValidity(isPinCode ? 'Invalid Pincode' : 'Invalid Entry')}
          onInputCapture={(e) => e?.target?.setCustomValidity('')} required={required} />
        {isCounter ? <i className="mt-2 mx-2 fa fa-plus" onClick={increment}></i> : <></>}
        {Component}
      </div>
    </div>
  );
};

export default NumberField;
