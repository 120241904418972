import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import alertImg from "../../../../images/alert.png";
import "./notification.scss";

const Notification = ({data, toggleAlertBox}) => {

     // console.log("in notification", data)

     const user = useSelector((state)=> state?.auth?.user);
     
     // const [status, setStatus] = useState(1);


     const userMap = {
          2: "employer",
          3: "educator",
          4: "student",
     }

     const navigate = useNavigate();

     const convertUTCToIST = (utcDate) => {
          const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };
          return {
               
               date: new Date(utcDate).toLocaleString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
               }),
               time: new Date(utcDate).toLocaleString("en-IN", {
                    hour: "numeric",
                    minute: "numeric",
               })
          };
     };

     const handleRedirect = async (item)=> {
          // console.log("itememe", item);

          toggleAlertBox()

     switch (item?.notification_payload?.role || user?.role) {
          case 2:        // employer     

               if(item?.notification_type === "chat"){
                    navigate("/employer/chat")
               }

               
               if(item?.notification_type === "complete_profile-reminder"){
                    navigate("/employer/employer-profile")
               }

               
               if(item?.notification_type === "subscription-reminder"){
                    navigate("/employer/subscription")
               }



               break;
          case 3:        // educator
               if(item?.notification_type === "chat"){
                    navigate("/educator/chat")
               }

               if(item?.notification_type === "complete_profile-reminder"){
                    navigate("/educator/educator-profile")
               }
               
               if(item?.notification_type === "subscription-reminder" || "max_assign-reminder"){
                    navigate("/educator/subscriptions")
               }
               
               if(item?.notification_type === "eduStudent_project-reminder"){
                    navigate("/educator/student-list")
               }
               
               break;
          case 4:        // student
          // console.log("Student case")

               if(item?.notification_type === "complete_profile-reminder"){
                    navigate("/student/profile")
               }

               if(item?.notification_type === "chat"){
                    // console.log("chat")
                    navigate("/student/chat")
               }

               if(item?.notification_type === "project"){
                    navigate(`/student/my-applications/project/${item?.notification_payload?.post_id}`)
               }

               if(item?.notification_type === "internship"){
                    // console.log("intenshipiddd", item?.notification_payload?.post_id)
                    navigate(`/student/my-applications/internship/detail/${item?.notification_payload?.post_id}`)
               }

               if(item?.notification_type === "job"){
                    navigate(`/student/my-applications/job/detail/${item?.notification_payload?.post_id}`)
               }

               if(item?.notification_type === "project_report-reminder"){
                    navigate(`/student/my-applications/job/detail/${item?.notification_payload?.project_id}`)
               }

               if(item?.notification_type === "mentor_session-reminder"){
                    navigate(`/student/mentor`)
               }

               if(item?.notification_type === "subscription-reminder"){
                    navigate(`/student/subscriptions`)
               }
               
               break;
     
          default:
               break;
     }


     const changeStatus = async ()=> {

          try {
              const res = await axios.post("/v1/notification/update", {
                      id: item?.id,
                      is_seen: true,
                      status: 2,      // seen -> 2
   
              })
  
              // console.log("res", res);
          } catch (error) {
              console.log("err", error)
          }
      }
  
      if(!item?.is_seen && item?.status == 1 ){
          changeStatus();
      } 


     }

     const changeNavigation = async ()=> {
          toggleAlertBox()
          navigate(`/${userMap[user?.role]}/all-notifications`, {state: {data: data, role: user?.role}});
     }

     // console.log("userrnotii", user);

  return (
     <section className="notification">
          <div className="notification-alert">
               <h3>Notification</h3>
               <div className="alert-box">

          {
               data?.length !== 0 ? (
               <>      
                    <ul>
                       {
                         data?.slice(0, 3).map((item, i)=> (
                              // <a href={ handleRedirect(item)}>
                                   <li key={i} className={`${item?.is_seen ? "seen" : "seen"} noti_body`}  onClick={ ()=> handleRedirect(item)}> 
                                        <div className='noti_title'>
                                             {item?.notification_title} {!item?.is_seen && item?.status == 1 &&  <span className='seen-status'></span> }

                                        </div>

                                        <div className='noti_desc'>
                                             {item?.notification_description}
                                        </div>

                                        <div className='notification_time'>
                                             <span>{convertUTCToIST(item?.createdAt).date}</span> 
                                             <span>{convertUTCToIST(item?.createdAt).time}</span>
                                        </div>
                                   </li>
                              // </a>

                         ))
                       }
                    </ul>

                    <div className='noti_bottom'>
                         <div>
                              {/* <Link to={`/student/all-notifications`} state={{data: data, status: status}} > */}
                                   <button className=' see-all' onClick={changeNavigation}>
                                        See all notifications
                                   </button>
                              {/* </Link> */}
                         </div>
                    </div>
               </>
                    
               ): 
               (
                    <>
                    <img src={alertImg} alt="alert" />                    
                    <h4>No Notifications to show</h4>
                    </>
               )
          }     



               </div>
          </div>
     </section>
  )
}

export default Notification