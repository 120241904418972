import axios from "axios"
import DeactivatePost from "components/employer/DeactivatePost"
import React from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import "./mobileDataTable.scss"

const MobileDataTable = ({ columns, data }) => {
  // console.log(columns, data,"inf")
  const closePost = async (id, status) => {
    if (status < 1 || status > 2) return toast.error("Invalid status")
    const { error, message } = await axios.patch("/v1/project/status", {
      id,
      status: 3,
    })

    if (error) {
      toast.error(message)
    } else {
      toast.success("Project closed successfully")
    }
    // handleBlur()
  }
  return (
    <>
      {data?.map((item) => {
        return (
          <div className="mobileDataTable mt-4">
            <div className="data_container">
              <div className="table_data">
                <p>Date Posted</p>
                <span>{item?.date}</span>
              </div>
              <div className="table_data">
                <p>Project Name</p>
                
                <span className="project_name"><Link to={item?.title?.props?.to}>{item?.title?.props?.children[1]} </Link></span>
              </div>             
              <div className="table_data">
                <p>Total enrolled</p>
                <span className="Total_enrolled">{item?.enrolled}</span>
              </div>
              <div className="table_data">
                <p>Total submitted</p>
                <span className="Total_enrolled">{item?.submitted}</span>
              </div>
              <div className="table_data">
                <p>Total rated</p>
                <span className="Total_enrolled">{item?.rated}</span>
              </div>
              <div className="Status_data">
                <p>Status</p>
               {item?.status?.props?.status==="Active"? <small className="Active_btn">Active</small> : <small className="Closed_btn">Closed</small>}
               <DeactivatePost
            closePost={() =>
              closePost(item?.id, item?.status)
            }
          // handleToggle={() => handleToggle(data.row.original.id)}
          />
                       
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileDataTable
