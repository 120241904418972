import { useEffect, useState } from "react";

const useRelativeTime = (
  oldDate = new Date(),
  nowDate = Date.now(),
  rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })
) => {
  const [relativeTime, setRelativeTime] = useState("..");

  useEffect(() => {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;
    const intervals = [
      { ge: YEAR, divisor: YEAR, unit: "year" },
      { ge: MONTH, divisor: MONTH, unit: "month" },
      { ge: WEEK, divisor: WEEK, unit: "week" },
      { ge: DAY, divisor: DAY, unit: "day" },
      { ge: HOUR, divisor: HOUR, unit: "hour" },
      { ge: MINUTE, divisor: MINUTE, unit: "minute" },
      { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
      { ge: 0, divisor: 1, text: "just now" },
    ];
    const now = new Date(nowDate).getTime();
    const date = new Date(oldDate).getTime();
    const diff = now - date;

    const diffAbs = Math.abs(diff);
    for (const interval of intervals) {
      if (diffAbs >= interval.ge) {
        const x = Math.round(Math.abs(diff) / interval.divisor);
        const isFuture = diff < 0;
        if (interval.unit) {
          setRelativeTime(rft.format(x * (isFuture ? 1 : -1), interval.unit));
        } else {
          setRelativeTime(interval.text);
        }
        break;
      }
    }

  }, [oldDate, nowDate, rft]);

  return relativeTime;
};

export default useRelativeTime;
