import useOutsideClick from "hooks/useOutsideClick"
import useRelativeTime from "hooks/useRelativeTime"
import React, { useRef, useState } from "react"
import { BiRupee } from "react-icons/bi"
import { HiOutlineShare } from "react-icons/hi"
import NewChip from "./NewChip"
import ShareButtonModel from "./ShareButtonModel"
import "./topcard.scss"

const TopCard = ({ data, type, children }) => {
  const [show, setShow] = useState(false)

  const toggleDropdown = () => setShow((prev) => !prev)

  return (
    <>
      {type === "project" && data && (
        <ProjectTop
          data={data}
          type={type}
          show={show}
          setShow={setShow}
          toggleDropdown={toggleDropdown}
          children={children}
        />
      )}
      {type === "internship" && data && (
        <InternshipTop
          data={data}
          type={type}
          show={show}
          setShow={setShow}
          toggleDropdown={toggleDropdown}
          children={children}
        />
      )}
      {type === "job" && data && (
        <JobTop
          data={data}
          type={type}
          show={show}
          setShow={setShow}
          toggleDropdown={toggleDropdown}
          children={children}
        />
      )}
    </>
  )
}

export default TopCard

const ProjectTop = ({
  data,
  type,
  children,
  show,
  toggleDropdown,
  setShow,
}) => {
  const ref = useRef(null)
  useOutsideClick(ref, () => setShow(false), show)
  const handleShare = (platform) => {
    // Handle share functionality based on the selected platform
    // console.log('Sharing on:', platform);
    // handleCloseModal(); // Close the modal after sharing
  }

  return (
    <div className="top-card">
      <div className="card-wrapper">
        <div className="title-section flex ">
          <h4>{data?.project_title}</h4>

          <div className="buttons-section flex gap-3">
            <div className="enroll_btn">{children}</div>

            <div className="share-button" onClick={toggleDropdown} ref={ref}>
              <HiOutlineShare color="#6149CD" />
              {/* { show && */}
              {
                data !== null && 
                  <ShareButtonModel type={"project"} data={data} isActive={show} className={
                    show ? "share-button-model active" : "share-button-model"
                  } />
              }
              {/* } */}
            </div>
          </div>
        </div>
        {data?.post_industry && (
          <div>
            <h6 style={{ color: "#7A86A1", fontWeight: "bold" }}>
              {data?.post_industry}
            </h6>
          </div>
        )}

        <div className="comp-details d-flex gap-4 align-items-center p-3">
          <img
            src={data?.project_global_fkey?.employer_account?.company_logo}
            alt="logo"
            width="70px"
          />

          <div className="details flex-col gap-1">
            <h3>
              {data?.project_global_fkey?.employer_account?.company
                ?.company_name ||
                data?.project_global_fkey?.employer_account?.company
                  ?.company_na}{" "}
              <span className="new-location">
                {/* {data?.office_visit ? `${data?.location?.city_name}, ${data?.location?.state?.state_name}` : ""} */}

                {data?.office_visit
                  ? data?.location?.city_name ||
                    data?.location?.state?.state_name
                    ? `${data?.location?.city_name}, ${data?.location?.state?.state_name}`
                    : data?.branch?.address
                  : ""}
              </span>
            </h3>

            <div className="chip-section d-flex ">
              <div className="new-chip1">
                {
                  // data?.office_visit ? `${data?.location?.city_name}, ${data?.location?.state?.state_name} ` : "Remote"
                  data?.office_visit ? `Office` : "Remote"
                }
              </div>
              <div className="new-chip1">
                {`${data?.duration_from} - ${data?.duration_to} Weeks`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-section">
        <div className="chip-section d-flex gap-2">
          <NewChip text="#HiringActivily" />
          <NewChip text="#TopOpportunity" />
        </div>

        {/* <div className='time'>
          2 days ago
        </div> */}
      </div>
    </div>
  )
}
const InternshipTop = ({
  data,
  type,
  children,
  show,
  toggleDropdown,
  setShow,
}) => {
  const time = useRelativeTime(data?.createdAt)
  const ref = useRef(null)
  useOutsideClick(ref, () => setShow(false), show)

  // console.log("iiidataaaaaa", data)
  return (
    <div className="top-card">
      <div className="card-wrapper">
        <div className="title-section flex ">
          <h4>{data?.internship_title}</h4>

          <div className="buttons-section flex gap-3">
            <div className="enroll_btn">{children}</div>

            <div className="share-button" onClick={toggleDropdown} ref={ref}>
              <HiOutlineShare color="#6149CD" />
              {/* { show && */}
              {
                data !== null &&              
                
                <ShareButtonModel
                  type={"internship"}
                  data={data}
                  isActive={show}
                  className={
                    show ? "share-button-model active" : "share-button-model"
                  }
                />
              }
              {/* } */}
            </div>
          </div>
        </div>
        {data?.post_industry && (
          <div>
            <h6 style={{ color: "#7A86A1", fontWeight: "bold" }}>
              {data?.post_industry}
            </h6>
          </div>
        )}

        <div className="comp-details d-flex gap-4 align-items-center p-3">
          <img
            src={data?.global?.employer_account?.company_logo}
            alt="logo"
            width="70px"
          />

          <div className="details flex-col gap-1">
            <h3>
              {data?.global?.employer_account?.company?.company_name}{" "}
              <span className="new-location">
                {data?.office_visit
                  ? `${data?.location?.city_name}, ${data?.location?.state?.state_name}`
                  : ""}{" "}
              </span>
            </h3>

            <div className="chip-section d-flex flex-wrap">
              <div className="new-chip1">
                {
                  // data?.office_visit ? `${data?.location?.city_name}, ${data?.location?.state?.state_name} ` : "Remote"
                  data?.office_visit ? `Office` : "Remote"
                }
              </div>
              <div className="new-chip1">
                {`${data?.duration_from} - ${data?.duration_to} Weeks`}
              </div>
              <div className="new-chip1">
                <BiRupee size={15} />{" "}
                {`${data?.stipend_from} - ${data?.stipend_to} /month`}
              </div>
              <div className="new-chip1">{`${data?.vacancies} Openings`}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-section">
        <div className="chip-section d-flex gap-2">
          <NewChip text="#HiringActivily" />
          <NewChip text="#TopOpportunity" />
        </div>

        <div className="time">{time}</div>
      </div>
    </div>
  )
}
const JobTop = ({ data, type, children, show, toggleDropdown, setShow }) => {
  const time = useRelativeTime(data?.createdAt)

  const ref = useRef(null)
  useOutsideClick(ref, () => setShow(false), show)

  const CTC_incentive_from = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(data?.ctc_from)
  const CTC_incentive_to = new Intl.NumberFormat(undefined, {
    notation: "compact",
  }).format(data?.ctc_to)

  return (
    <div className="top-card">
      <div className="card-wrapper">
        <div className="title-section flex ">
          <h4>{data?.job_title}</h4>

          <div className="buttons-section flex gap-3">
            <div className="enroll_btn">{children}</div>

            <div className="share-button" onClick={toggleDropdown} ref={ref}>
              <HiOutlineShare color="#6149CD" />
              {/* { show && */}
              {
                data !== null && 
                  <ShareButtonModel
                    type={"job"}
                    data={data}
                    isActive={show}
                    className={
                      show ? "share-button-model active" : "share-button-model"
                    }
                  />

              }
              {/* } */}
            </div>
          </div>
        </div>
        {data?.post_industry && (
          <div>
            <h6 style={{ color: "#7A86A1", fontWeight: "bold" }}>
              {data?.post_industry}
            </h6>
          </div>
        )}

        <div className="comp-details d-flex gap-4 align-items-center p-3">
          <img
            src={data?.global_job?.employer_account?.company_logo}
            alt="logo"
            width="70px"
          />

          <div className="details flex-col gap-1">
            <h3>
              {data?.global_job?.employer_account?.company?.company_name}{" "}
              <span className="new-location">
                {data?.office_visit
                  ? `${data?.location?.city_name}, ${data?.location?.state?.state_name}`
                  : ""}{" "}
              </span>
            </h3>

            <div className="chip-section d-flex gap-3">
              <div className="new-chip1">
                {
                  // data?.office_visit ? `${data?.location?.city_name}, ${data?.location?.state?.state_name} ` : "Remote"
                  data?.office_visit ? `Office` : "Remote"
                }
              </div>
              <div className="new-chip1">
                <BiRupee size={15} />{" "}
                {`${CTC_incentive_from} - ${CTC_incentive_to} /year`}
              </div>
              <div className="new-chip1">{`${data?.vacancies} Openings`}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-section">
        <div className="chip-section d-flex gap-2">
          <NewChip text="#HiringActivily" />
          <NewChip text="#TopOpportunity" />
        </div>

        <div className="time">{time}</div>
      </div>
    </div>
  )
}
