import axios from "axios";
import Freelancerimg from "images/Freelancer.png";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CollegesCards from "view/dashboard/employer/components/collegesCards/collegesCards/CollegesCards";
import CollegesDetailCards from "view/dashboard/employer/components/collegesCards/collegesDetailCards/CollegesDetailCards";
import ListView from "view/dashboard/employer/components/list/ListView";
import {
    initialState,
    reducer,
} from "view/dashboard/student/explore/handleFilters";
import SectionSideFilter from "../../../id/sections/SectionSideFilter";
import "../suggestedCard.scss";
const Details = ({ setRefresh, refresh }) => {
  const [show, setShow] = useState(false);
  // const style1 = {
  //     backgroundColor: "#47D065",
  // }
  const user = useSelector((state) => state?.auth?.user);
  const [state1, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });

  const [educators, setEducators] = useState(0);
  const [educatorDataDetails, setEducatorDataDetails] = useState();
  const style2 = {
    backgroundColor: "#47D065",
  };
  const style_i = {
    display: "none",
  };

  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    const filters = Object.keys(state1).reduce((acc, key) => {
      if (state1[key]) {
        acc[key] = state1[key];
      }
      return acc;
    }, {});
    const fetchInstitutions = async () => {
      const res = await axios.get(
        `/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}`,
        {
          params: {
            ...filters,
          },
        }
      );
      // // console.log(res, "res");
      setInstitutions(res?.data?.data);
    };
    fetchInstitutions();
  }, [refresh, state1]);

  // // console.log(institutions, "institutions");

  useEffect(() => {
    const fetctEductatorData = async () => {
      const res = await axios.get(
        `/v1/institution/single/${institutions[educators].sender_id}`
      );
      // console.log(res, "educatordata");
      setEducatorDataDetails(res?.data?.data);
    };
    fetctEductatorData();
    // // console.log("function 2 working")
  }, [educators, institutions, refresh]);

  const acceptInvite = (e, id) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 1,
      })
      .then((res) => {
        // console.log(res, "res");
        toast.success("Invitation Accepted");
      })
      .catch((err) => {
        // console.log(err, "err");
        toast.error("Invitation Rejected");
      });
  };

  const rejectInvite = (e, id = []) => {
    e.preventDefault();
    axios
      .patch(`/v1/invitations/updateInvitation/${id}`, {
        status: 2,
      })
      .then((res) => {
        // console.log(res, "res");
        toast.success("Invitation Accepted");
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Invitation Rejected");
      });
  };

  // // console.log(educatorDataDetails, "educatorDataDetails");

  const [search, setSearch] = useState("");

  return (
    <div className="tab-content">
      <div className="tab-pane fade show active">
        <div className="content-tab">
          <div className="row">
            {institutions?.length > 0 ? (
              <>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="searchbox">
                    <label>
                      {" "}
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="Search candidate"
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {/* {["", "", ""].map((institution, index) => {
                  // console.log(institution, "<CollegesCards  />");
                  const collegesFunction = () => {
                    setShow(true);
                    // console.log("function working");
                  };
                  return (
                    <div onClick={collegesFunction}>
                      <CollegesCards institution={institution} />
                    </div>
                  );
                })} */}

                    {/* {institutions?.map((institution, index) => {
                      const collegesFunction = () => {
                        setShow(true);
                        setEducators(index);
                        // console.log("function working");
                      };
                      return (
                        <div onClick={collegesFunction}>
                          <CollegesCards institution={institution?.sender} />
                        </div>
                      );
                    })} */}

                    {institutions
                      ?.filter((item) => {
                        return (
                          item?.sender?.first_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.sender?.last_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item?.sender?.institute?.institution_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      })
                      ?.map((institution, index) => {
                        const collegesFunction = () => {
                          setEducators(index);
                          setShow(true);
                          // console.log("function working");
                        };
                        return (
                          <div onClick={collegesFunction}>
                            <CollegesCards institution={institution.sender} />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block">
                  <CollegesDetailCards
                    style2={style2}
                    style_i={style_i}
                    text2="Accept Invitation"
                    text1="Reject Invitation"
                    educatorDataDetails={educatorDataDetails}
                    pageLength={institutions?.length}
                    setPage={setEducators}
                    currentPage={educators}
                    changeStatus={acceptInvite}
                    cancelInvite={rejectInvite}
                    setRefresh={setRefresh}
                  />
                </div>
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
                  <SectionSideFilter dispatch={dispatch} employer={false} />
                </div>
              </>
            ) : (
              <>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="new-post">
                      <div className="flex">
                        <div>
                          <h3>No Data Found</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  ></div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-none d-md-block"></div>
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg">
                  <SectionSideFilter dispatch={dispatch} employer={false} />
                </div>
              </>
            )}
            {show && (
              <div className="position-fixed col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobo_main_college_datail d-flex d-md-none ">
                <i
                  class="fa fa-arrow-left text-capitalize position-absolute top-0 left-0 mt-3 mx-3 text-primary "
                  aria-hidden="true"
                  onClick={() => setShow(false)}
                >
                  {" "}
                  Back
                </i>
                <CollegesDetailCards
                  educatorDataDetails={educatorDataDetails}
                  style2={style2}
                  style_i={style_i}
                  text2="Accept Invitation"
                  text1="Reject Invitation"
                  pageLength={institutions?.length}
                  setPage={setEducators}
                  currentPage={educators}
                  changeStatus={acceptInvite}
                  cancelInvite={rejectInvite}
                  InvitationId={institutions[educators]?.id}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const InvitationRecieved = ({ state, setRefresh, refresh, ...props }) =>
  state ? (
    <Details {...props} setRefresh={setRefresh} refresh={refresh} />
  ) : (
    <List {...props} setRefresh={setRefresh} refresh={refresh} />
  );
const style2 = {
  backgroundColor: "#47D065",
};
const style_i = {
  display: "none",
};

const List = ({
  state,
  enrollments = [],
  post = {},
  handleStatusChange,
  setRefresh,
  refresh,
}) => {
  const [selectedStudent, setSelectedStudent] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  // get all the invite list where receiver is equal to 1 and status =0 with join on employer_table(receiver_id) and join on educator_table(sender_id)
  useEffect(() => {
    const fetchInstitutions = async () => {
      const res = await axios.get(`/v1/invitations/get_invites?status[]=0&receiver_id=${user?.id}
      `);
      setInstitutions(res?.data?.data);
    };
    fetchInstitutions();
  }, [refresh]);

  return (
    <>
      {institutions?.length > 0 ? (
        <div className="d-flex gap-4">
          <ListView
            style2={style2}
            style_i={style_i}
            text2="Accept Invitation"
            text1="Reject Invitation"
            data={institutions}
            type={"sender"}
            received={true}
            setRefresh={setRefresh}
          />
          <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mg d-none d-xl-flex">
            <div className="row ">
              <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="job-filter">
                  <label htmlFor="city">City</label>
                  <input
                    type="Search"
                    id="city"
                    list="search-data"
                    placeholder="Search City"
                  />
                  <datalist id="search-data">
                    <option value="Delhi"></option>
                    <option value="Noida"></option>
                  </datalist>
                  <label htmlFor="skill">Skills</label>
                  <input
                    type="Search"
                    id="skill"
                    list="skills-data"
                    placeholder="Search Skills"
                  />
                  <datalist id="skills-data">
                    <option value="html"></option>
                    <option value="css"></option>
                  </datalist>

                  <div className="spanbox">
                    <span>
                      IT <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      IT Students <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      React <i className="fa-solid fa-xmark"></i>
                    </span>
                    <span>
                      B.Tech <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                  <label htmlFor="qualification">Qualification</label>
                  <input
                    type="text"
                    id="qualification"
                    placeholder="Search qualification"
                  />
                  <button className="clear">Clear</button>
                  <button>Apply</button>
                </div>
              </div>
              <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                <div
                  className="new-post"
                  onClick={() => navigate("/employer/internship/add")}
                >
                  <div className="flex">
                    <div>
                      <h3>Post New </h3>
                      <h4>Internship</h4>
                    </div>
                    <div>
                      {" "}
                      <i className="fa-solid fa-right-long"></i>
                    </div>
                  </div>
                  <img src={Freelancerimg} alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="new-post">
            <div className="flex">
              <div>
                <h3>No Available Institution Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvitationRecieved;
