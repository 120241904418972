import React, { Suspense, lazy, useEffect, useState } from "react";
import axios from "axios";
// import "./notification.scss";
// import MentorProfileCard from "components/student/MentorProfileCard";
import { useParams } from "react-router-dom";
import MentorProfile from "./MentorProfile";
import Loader from "components/employer/Loader";

const MentorProfilePage = () => {
  const { id } = useParams();
  const [mentor, setMentor] = useState(() => []);
  const [loading, setLoading] = useState(false)
  const fetchMentor = async () => {
    try {
      setLoading(true)
      const { data: reqData } = await axios.get(`/v1/student/mentor/` + id, {
        validateStatus: () => true,
      });
      if (!reqData) throw new Error("Error fetching user");
      const { data, error, message } = reqData;
      setMentor(data);

      setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  };
  useEffect(() => {
    fetchMentor();
  }, []);

  if(loading) return  <Loader />

  return <MentorProfile data={mentor} />;
};

export default MentorProfilePage;
