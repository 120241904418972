import { EDUCATOR_INVITES_NUMBER } from "services/constants";

const initialState = {};

export default function educatorInvites(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case EDUCATOR_INVITES_NUMBER:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
