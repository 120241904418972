import React from "react";
import { Link } from "react-router-dom";
import "./sidebarButtons.scss";
import ToolTip from "./ToolTip";

const SidebarButtons = ({
  link,
  text,
  icon,
  locked = false,
  myClass,
  onClick = () => {},
  setShowSidebar = () => {},
}) => {
  // console.log(myClass)
  return (
    <>
      {link ? (
        <Link
          onClick={() => setShowSidebar(false)}
          className={"sidebar-btn mb-2 ps-3 me-4 " + myClass}
          to={!locked ? link : "/employer/employer-profile"}
        >
          <div>
            <i className={icon + " me-3"}></i>
            {text}
          </div>
          {locked && <i className="fa-solid fa-lock"></i>}
        </Link>
      ) : (
        <div
          className={"sidebar-btn mb-3 ps-3 me-4 " + myClass}
          onClick={onClick}
          style={{ cursor: "pointer" }}
        >
          <div>
            <i className={icon + " me-3"}></i>
            {text}
          </div>
          {locked && <i className="fa-solid fa-lock"></i>}
        </div>
      )}

      {!locked ? <></> : <ToolTip />}
    </>
  );
};

export default SidebarButtons;
