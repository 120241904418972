import axios from "axios"
import Loader from "components/employer/Loader"
import NewJobDetailsPage from "components/posts/NewJobDetailsPage"
import "components/posts/NewProjectDetails.scss"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import "styles/employer/preview.scss"


const Actions = ({ onApply,data }) => {
 
  return (
    <div className="actions">
  <button
    className="btn btn-primary py-2 w-100 text-white btn-width"
    onClick={onApply}
  >
    Apply Now
  </button>
</div>
  )
  
}

const FetchJobDetails = ({ id, onApply }) => {

  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({});

  const fetchJob = async() => {
    try {
      setLoading(true);
      const data = await axios.get(`/v1/filter/job/${id}`)
      // console.log("daaaaaaaaaate", data);

      setJob(data?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);

    }
  }

  useEffect(()=> {
    fetchJob();
  }, [id])


  // const { loading, data, error } = useFetch(`/v1/filter/job/${id}`)

  // if (loading || !data) return <Loader />
  // const { error: err, data: job } = data

  // if (err || error) return <h3>{err}</h3>

  if(loading)  return <Loader />;

  return <NewJobDetailsPage data={job}>
          <Helmet>
            {/* <meta
              charSet="utf-8"
              name="description"
              content={`${project?.project_title}`}
            /> */}
            <meta
              name="title"
              content={job?.job_title}
            />
            <link rel="canonical" href={`https://qollabb.com/job/${job?.job_title?.replace(/[\s#]+/g, '-')}/${job?.id}`} />
        <title>
          {job?.job_title}
        </title>
        {/* <link rel="canonical" href="https://qollabb.com/company-page" /> */}
      </Helmet>

    <Actions onApply={onApply} data={job}/>
  </NewJobDetailsPage>

}

export default FetchJobDetails
