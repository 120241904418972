import React from 'react';
import './socialButton.scss';
/**
 * It's a function that returns a button with an image and text
 * @returns Social Login Button with on click handler
 */

const SocialButton = ({ color, text, img, customClass, clickHandler, }) => {
  return (
    <button className={customClass}
      style={{
        color: `${ color }`
      }}
      onClick={clickHandler}>
      <img src={img} alt="Image" />
      <span className='d-sm-block d-none ' >{text}</span>
    </button>
  );
};

export default SocialButton;