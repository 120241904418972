import React, { useEffect, useState } from "react";
import TextField from "components/formComponents/TextField";
import TextArea from "components/formComponents/TextArea";
import FromTo from "components/formComponents/FromTo";
import CategoryField from "components/formComponents/CategoryField";
import ChipsField from "components/formComponents/ChipsFieldWithPopup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addApplicationData } from "services/actions/application";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import NumberField from "components/formComponents/NumberField";
import DateField from "components/formComponents/DateField";
import ChipsField2 from "components/formComponents/ChipsField";
import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import { useNavigate } from "react-router-dom";
import Submit from "components/employer/Submit";
import JobDetails3 from "./page3";
import { useParams } from "react-router-dom";

const defaultItems = [
  {
    id: "incentive_from",
    label: "From",
    required: true,
    options: {
      min: 0,
      max: 99999999,
    },
  },
  {
    id: "incentive_to",
    label: "To",
    required: false,
    options: {
      min: 0,
      max: 99999999,
    },
  },
];

const CreateJob = ({ shouldValidate, updateShouldValidate }) => {
  const defaultValues = useSelector((state) => state.application["job"]);
  const [isPopupOpen, updatePopup] = useState(() => false);
  const navigate = useNavigate();
  const [courses, setCourses] = useState(
    () => defaultValues?.course_preferred || []
  );

  const [jobCity, setJobCity] = useState(() => defaultValues?.job_city || []);

  const { id } = useParams();

  const deleteItem = (item, id) => {
    try {
      if (id === "course_preferred") {
        setCourses((prev) =>
          prev.filter((course) => course.course_name !== item)
        );
      } else if (id === "job_city") {
        setJobCity((prev) => prev.filter((city) => city !== item));
      } else {
        throw new Error("Invalid id");
      }
    } catch (err) {
      toast.error(err?.message || err, { toastId: "deleteItem" });
    }
  };

  const jobLocation = defaultValues?.city?.location;

  const map = {
    1: "office",
    2: "remote",
    3: "field",
  };

  let category_type = null;

  if (typeof defaultValues?.internship_category === "number") {
    category_type = map[defaultValues?.internship_category];
  } else {
    category_type = defaultValues?.internship_category;
  }

  const [defaultType, setDefaultType] = useState(() => {
    return category_type || "office";
  });

  const { handleSubmit, register, getValues, setValue, unregister } = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const [isRemote, setIsRemote] = useState(true);
  const [remoteOfficeVisit, setRemoteOfficeVisit] = useState(false);

  const dispatch = useDispatch();

  const [linkedProject, setLinkedProject] = useState(() => ({
    name: "",
    id: 0,
  }));

  useEffect(() => {
    const onSubmit = (data) => {
      dispatch(
        addApplicationData({
          method: "job",
          data: {
            ...data,
            course_preferred: courses,
            project_linked: linkedProject,
            job_city: jobCity,
            id: id || null,
          },
        })
      );
      navigate("/employer/job/preview");
      localStorage.setItem("step_job", 3);
    };

    if (shouldValidate) {
      // unregister("job_skills");
      unregister("location");
      unregister("course_preferred");
      unregister("office_visit_remote");
      const data = getValues();

      data.incentive_from = Number.parseInt(data.incentive_from);
      data.incentive_to = Number.parseInt(data.incentive_to);
      data.vacancies = Number.parseInt(data.vacancies);

        // console.log(data, "dataaaa");
      data.office_visit = false;

      if (!(data["job_category"] === "remote")) {
        data["office_visit"] = true;
      }

      if (isRemote && remoteOfficeVisit) {
        data["office_visit"] = true;
      }

      if (courses) {
        data["course_preferred"] = courses;
      }

      let isError = false;
      //   console.log(data, "fdata");
      const notRequired = [
        "faq",
        "additional_info",
        "office_visit",
        "job_city",
        "city",
        "course_preferred",
        "id",
      ];
      // console.log(data, "hello world");
      Object.keys(data).forEach((item, index) => {
        if (!data[item] && !notRequired.includes(item)) {
          toast.error(`${item?.split("_")?.join(" ")} is required`, {
            toastId: `${item}Error`,
            delay: 100 * index,
          });
          isError = true;
        }
      });
      if (data["joining_date"]) {
        if (new Date(data["joining_date"]) < new Date()) {
          toast.error("Joining date can't be in past", {
            toastId: "joining_dateError",
            delay: 100 * Object.keys(data).length,
          });
          isError = true;
        }
      }

      if (isRemote && !remoteOfficeVisit) {
        delete data["city"];
      } else {
        if (!data["city"]) {
          toast.error(`City is required`, {
            toastId: `cityError`,
            delay: 100 * Object.keys(data).length,
          });
          isError = true;
        }
      }

      if (data["incentive_from"] && data["incentive_to"]) {
        if (data["incentive_from"] > data["incentive_to"]) {
          toast.error("CTC should be greater than minimum CTC", {
            toastId: "incentive",
          });
          isError = true;
        }
      }

      if (!isError) {
        handleSubmit(onSubmit(data));
        updateShouldValidate(1, false, true);
      }

      return () => {
        updateShouldValidate(1, false, false);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldValidate,
    updateShouldValidate,
    handleSubmit,
    getValues,
    unregister,
    dispatch,
    setValue,
  ]);

  React.useEffect(() => {
    if (
      defaultValues?.job_category === "remote" &&
      defaultValues?.office_visit === true
    ) {
      setRemoteOfficeVisit(true);
      setIsRemote(true);
    } else if (
      defaultValues?.job_category === "remote" &&
      defaultValues?.office_visit === false
    ) {
      setRemoteOfficeVisit(false);
      setIsRemote(true);
    } else if (!defaultValues?.job_category) {
      setRemoteOfficeVisit(false);
      setIsRemote(false);
    } else {
      setRemoteOfficeVisit(false);
      setIsRemote(false);
    }
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div className="row mx-0">
        <div className="col-12">
          <TextField
            label="Job Title"
            required={true}
            id="job_title"
            placeholder="Full stack developement"
            register={register}
          />
        </div>

        <div className="col-12">
          <TextArea
            label="Roles and Responsibilities"
            required={true}
            id="roles_and_responsibilities"
            placeholder="What are the role/responsibilities of this Job"
            register={register}
          />
        </div>

        <div className="col-12">
          <ChipsField2
            register={register}
            required={false}
            label="Preferred City for the Candidates to work in"
            id="job_city"
            items={jobCity}
            setItems={setJobCity}
            deleteItem={deleteItem}
          />
        </div>

        <div className="col-12">
          <CategoryField
            label="How would you like to categorise this job?"
            required={true}
            id="job_category"
            register={register}
            getValues={getValues}
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            defaultCheck={defaultType}
            setDefaultType={setDefaultType}
            // setFlag={setFlag}
            // flag={flag}
            subLable="I would like to offer the candidates atleast one day visit to your office if it is a virtual or feild based job? "
            remoteOfficeVisit={remoteOfficeVisit}
            setRemoteOfficeVisit={setRemoteOfficeVisit}
          />
        </div>
        {(remoteOfficeVisit || !isRemote) && (
          <div className="col-12">
            <TextFieldWithPopup
              label="Location"
              required={true}
              id="location"
              register={register}
              isPopupOpen={isPopupOpen}
              updatePopup={updatePopup}
              path="/v1/location/city"
              key_name="location"
              setValue={setValue}
              withId="city"
              // defaultValue={jobLocation}
              value={jobLocation}
            />
          </div>
        )}
        <div className="col-12">
          <NumberField
            label="Number of candidates needed"
            min={1}
            max={10000}
            register={register}
            id="vacancies"
            placeholder="5"
            disabled={true}
            isCounter={true}
            getValues={getValues}
            setValue={setValue}
          />
        </div>

        <div className="col-12">
          <DateField
            label="Expected start date"
            required={true}
            id="joining_date"
            placeholder="Date"
            register={register}
          />
        </div>
        <div className="incentive col-12">
          <FromTo
            label="CTC"
            register={register}
            scale=""
            step={50000}
            items={defaultItems}
            component="Per Annum"
            getValues={getValues}
            setValue={setValue}
          />
        </div>
        <ChipsFieldWithPopup
          register={register}
          required={false}
          label="Name The Courses From Which You Would Prefer Students To Undertake This Job"
          id="course_preferred"
          items={courses}
          secondaryLabel="In the box below, please mention courses of which students are more suitable for your internship. You can mention multiple courses if required. E.g. MBA - Marketing, B.tech  (Mechanical Engineering) etc."
          setItems={setCourses}
          deleteItem={deleteItem}
          setValue={setValue}
          path="/v1/filter/courses"
          key_name="course_name"
        />
        <JobDetails3
          linkedProject={linkedProject}
          setLinkedProject={setLinkedProject}
        />
      </div>
    </form>
  );
};

export default CreateJob;
