import { Link, useNavigate, useOutletContext } from "react-router-dom";

const Card = ({ activeTab, setActiveTab }) => {
  const [enrolled] = useOutletContext();
  // console.log("enrolled",enrolled);
  const navigate = useNavigate();
  // // console.log("data enrolled", enrolled)

  const getProgress = (enrolledProject) => {
    // if (enrolledProject.status === 4) {
    //   return enrolledProject.project.duration_to;
    // } else {
      let progress = 0;
      const weekly = JSON.parse(enrolledProject.weekly_submissions);
      // console.log(weekly, "weekly");
      weekly.map((week) => (week.description ? progress++ : progress));
      return progress;
    // }
  };

  const map = {
    0: "projects",
    1: "internships",
    2: "jobs",

  }

  return (
    <div className="mb-4">
      <div className="card-content">
        <ul className="nav nav-tabs">
          {["Projects", "Internships", "Jobs"].map((tab, index) => (
            <li className="nav-item" role="presentation" key={tab}>
              <button
                className={`nav-link ${index === activeTab ? "active" : ""}`}
                type="button"
                role="tab"
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        {activeTab === 0 && enrolled?.project?.length !== 0 ? (
          <div>
            <div
              className="tab-panel fade show active col-12"
              role="tabpanel"
              aria-labelledby="project-tab"
            >
              <div className="cardpage">
                <div className="row gap-2 gap-md-0">
                  {enrolled.project?.slice(0, 3)?.map((post) => {
                    // console.log("post..", post);
                    const calculateFinalDate = () => {
                      const finalDate = new Date(post?.createdAt);
                      finalDate.setDate(
                        finalDate.getDate() + post?.project?.duration_to * 7
                      );
                      const finalDateString = finalDate
                        .toISOString()
                        .split("T")[0];
                      return finalDateString;
                    };
                    
                    const date23 = calculateFinalDate();
                    // const formatDate = (date23) => {
                    //   const parts = date23.split('-');
                    //   const year = parts[0];
                    //   const month = parseInt(parts[1], 10);
                    //   const day = parseInt(parts[2], 10);
                    //   const months = [
                    //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    //   ];
                    //   const formattedDate = `${day} ${months[month - 1]} ${year}`;
                    //   return formattedDate;
                    // };

                    // // console.log(date23,formatDate(),"calculateFinalDate");
                    return (
                      <Link
                        to={`/student/my-applications/project/${post?.project?.id}`}
                        className="col-12 col-md-5"
                        key={post.id}
                      >
                        <div className="card-cnt">
                          <div className="flex">
                            <div>
                              <img
                                src={
                                  post?.project?.project_global_fkey
                                    ?.employer_account?.company_logo ||
                                  post?.project?.branch?.company_logo ||
                                  ""
                                }
                                alt="logo"
                                width={45}
                                height={45}
                                className="rounded-circle"
                                style={{
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                }}
                              />
                            </div>
                            <div className="text">
                              <h4 className="text-black">
                                {post?.project?.title}
                              </h4>
                              <p className="">
                                <span>{post?.project?.branch?.address} </span>

                                <span>
                                  | Date of Submission: {calculateFinalDate()}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 rg">
                              <h3 className="text-black">
                                Total Weeks Completed
                              </h3>
                              <progress
                                id="file"
                                value={getProgress(post)}
                                max={post["project"]["duration_to"]}
                              >
                                {post["project"]["duration_to"]}
                              </progress>
                              <span>
                                {getProgress(post)}
                                <small>
                                  / {post["project"]["duration_to"]}
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          //  enrolled?.internship && enrolled?.job ? <></>:
          <>
            {enrolled?.[["project", "internship", "job"][activeTab]]?.length ===
            0 ? (
              <div className="empty_card">
                <div className="emptyCard_content">
                  <h4>
                    You are yet to {activeTab === 0 ? "enroll" : "apply"} in a{" "}
                    {["Projects", "Internships", "Jobs"][activeTab]}
                  </h4>
                  <button
                    onClick={() => {
                      navigate(`/student/explore/top-${map[activeTab]}`, {
                        state: {
                          activeTab,
                        },
                      });
                    }}
                  >
                    {activeTab === 0 ? "Explore" : "Apply"} Now
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
