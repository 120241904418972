import ChipsFieldWithPopup from "components/formComponents/ChipsFieldWithPopup";
import TextFieldWithPopup from "components/formComponents/TextFieldWithPopup";
import { useState } from "react";
import { useForm } from "react-hook-form";

const SectionSideFilter = ({ dispatch, employer = true }) => {
  const [isPopupOpen, updatePopup] = useState(() => false);
  const [qualification, setQualification] = useState(() => []);
  const deleteQualification = (item) => {
    setQualification((prev) =>
      prev.filter((skill) => skill.course_name !== item)
    );
  };

  const { register, setValue, getValues, reset } = useForm({
    defaultValues: {
      location: "",
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const course_preferred = qualification.map((item) => item?.course_name);
    const location = getValues("location");
    dispatch({
      type: "change_value",
      payload: {
        course_preferred,
        location: location,
      },
    });
  };

  const onReset = () => {
    setQualification(() => []);
    reset();
    dispatch({
      type: "change_value",
      payload: {
        course_preferred: [],
        location: "",
      },
    });
    // console.log(getValues());
  };

  return (
    <div className="job-filter">
      <div className="col-12">
        <TextFieldWithPopup
          label="Location"
          id="location"
          register={register}
          isPopupOpen={isPopupOpen}
          updatePopup={updatePopup}
          path="/v1/location/city"
          key_name="location"
          setValue={setValue}
          // withId="city"
          placeholder="Search Location"
        />
      </div>

      {employer && (
        <div className="col-12">
          <ChipsFieldWithPopup
            register={register}
            label="Qualification"
            id="qualification"
            items={qualification}
            setItems={setQualification}
            deleteItem={deleteQualification}
            setValue={setValue}
            path="/v1/filter/courses"
            placeholder="Choose Qualification"
            key_name="course_name"
          />
        </div>
      )}

      <div className="d-flex justify-content-center">
        <button onClick={onReset} type="reset" className="clear">
          Clear
        </button>
        <button onClick={(e) => onSubmit(e)} type="submit" className="apply">
          Apply
        </button>
      </div>
    </div>
  );
};

export default SectionSideFilter;
